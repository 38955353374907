import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import styles from 'components/baseErrorState/BaseErrorState.module.scss'

type Props = JSX.IntrinsicElements['div'] & {
  illustration: ReactNode
  mainTitle: string
  subTitle: string
  actions?: ReactNode
}

export const BaseErrorState = ({ className, mainTitle, subTitle, illustration, actions, ...rest }: Props) => (
  <div {...rest} className={clsx(styles.root, className)} data-testid="error-boundary-state">
    {illustration}

    <div className={styles.textWrapper}>
      <WppTypography type="5xl-display" data-testid="main-error-title">
        {mainTitle}
      </WppTypography>
      <WppTypography type="m-body" className={styles.subTitle} data-testid="sub-error-title">
        {subTitle}
      </WppTypography>
    </div>

    <div className={styles.actions}>{actions}</div>
  </div>
)
