import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/portfolioAllocationAndCommunication/PortfolioAllocationAndCommunication.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const PortfolioAllocationAndCommunication = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/big-idea'
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/campaign-brief'

  return (
    <ProcessCard
      title="Portfolio Allocation and Communication Strategy"
      subtitle="Determine which brands would participate in Big Brief Idea and the intended message of campaign"
      bepTitle="prerequisites to BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="category sufficiency and media principles"
      showMroiFooter
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Brand Specific Strategy" cardStyles={styles.inputsCard} />
        <RegularCard
          text="Perform the assessment of how to communicate and measure the impact of Comms strategy across the Portfolio, Country / Cluster allocation"
          teamTags={[{ text: 'Strategic Planning ', tags: ['R'] }]}
        />
        <InputsOutputsCard title="outputs" text="Creation of campaign brief" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default PortfolioAllocationAndCommunication
