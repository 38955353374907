// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oCUjm::part(body){height:3px;background-color:#fe7b03}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/easyjet/utils/ProcessNote.module.scss"],"names":[],"mappings":"AACE,mBACE,UAAA,CACA,wBAAA","sourcesContent":[".divider {\n  &::part(body) {\n    height: 3px;\n    background-color: #fe7b03;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `oCUjm`
};
export default ___CSS_LOADER_EXPORT___;
