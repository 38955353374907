import {
  WppActionButton,
  WppButton,
  WppCard,
  WppIconDirections,
  WppIconDocument,
  WppIconLogin,
  WppIconQa,
  WppIconTaskList,
  WppIconUnorderedList,
  WppIconWorkshop,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/google/components/steps/Steps.module.scss'
import { routesManager } from 'utils/routesManager'

interface ListItem {
  icon: ReactNode
  categories: {
    title: string
    steps: string[]
    link?: { title: string; url: string }
  }[]
}

export const Steps = () => {
  const listItems: ListItem[] = [
    {
      icon: <WppIconDocument height={96} width={96} color="#FBBD05" />,
      categories: [{ title: 'Activities', steps: ['Scoping approval', 'MRFID approval', 'PMM / ML Brief Alignment'] }],
    },
    {
      icon: <WppIconQa height={96} width={96} color="#FBBD05" />,
      categories: [
        {
          title: 'Key Questions',
          steps: [
            'What have we learned so far?',
            'What is helpful for creative development (i/a)?',
            'What channels am I likely to buy?',
            'Is audience viable for measurement?',
            'For KPI being discussed, what is the baseline? Is it too high? ',
          ],
        },
      ],
    },
    {
      icon: <WppIconLogin height={96} width={96} color="#FBBD05" />,
      categories: [
        { title: 'Inputs', steps: ['Testing priorities', 'Measurement requirements'] },
        { title: 'PMM', steps: ['Budget range', 'KPI (guidelines & baselines)', 'Audience (establish size)'] },
        {
          title: 'Media Lab',
          steps: [
            'Previous learnings (if applicable)',
            'Creative best practices',
            'SmartBrief* (channel allocations, T1 only)',
            'Additional necessary insights (fill in gaps) Testing priorities Measurement requirements (i.e. Pitch Shift)',
          ],
        },
      ],
    },
    {
      icon: <WppIconUnorderedList height={96} width={96} color="#FBBD05" />,
      categories: [
        {
          title: 'Responsibilities',
          steps: [
            'PMM responsible to get scoping/MRFID approval, preparing  brief',
            'Media Lab responsible for brief inputs where applicable',
          ],
        },
      ],
    },
    {
      icon: <WppIconTaskList height={96} width={96} color="#FBBD05" />,
      categories: [
        {
          title: 'Deliverables',
          steps: [],
          link: {
            title: 'Go to Media Brief template',
            url: '/p/ffc2ce2c-0eca-47e9-9df0-069f87a0340f/3abb6eb9-f4b7-4f95-8b46-e1317423eb8b/project-brief',
          },
        },
      ],
    },
    {
      icon: <WppIconWorkshop height={96} width={96} color="#FBBD05" />,
      categories: [
        {
          title: 'Tools',
          steps: [
            'LEGOs (pre BP)',
            'SmartBrief (channel allocations, T1 only)',
            '1P Data Tools identified (Riesling, Vitruvian, Rainbow)',
          ],
          link: {
            title: 'Go to SmartBrief ',
            url: routesManager.systemUrls.orchestration.project.getUrl('ffc2ce2c-0eca-47e9-9df0-069f87a0340f'),
          },
        },
      ],
    },
  ]
  return (
    <Flex direction="column" gap={24}>
      <Flex direction="column" className={styles.header}>
        <div className={styles.background} />
        <Flex className={styles.content} direction="column" gap={24}>
          <Flex gap={52} className={styles.stepDots}>
            <div className={styles.line} />
            {Array(7)
              .fill('')
              .map((_, index) => (
                <div key={index} className={clsx(styles.dot, { [styles.activeDot]: index === 0 })} />
              ))}
          </Flex>
          <Flex direction="column" gap={8}>
            <WppTypography type="5xl-display">01. Pre-discover</WppTypography>
            <WppTypography type="s-body">
              Media Lab pulls high-level channel mix and insights to inform PMM briefs{' '}
            </WppTypography>
          </Flex>
          <WppTypography type="s-strong">
            Duration: <WppTypography type="s-body">1 week</WppTypography>
          </WppTypography>
        </Flex>
      </Flex>
      <WppCard className={styles.container} size="l">
        <Flex className={styles.columns} gap={24}>
          {listItems.map((config, index) => {
            return (
              <Flex direction="column" key={`conf-${index}`} className={styles.column} gap={16}>
                <WppCard className={styles.icon}>
                  <Flex align="center" justify="center">
                    {config.icon}
                  </Flex>
                </WppCard>
                <Flex direction="column" gap={8}>
                  {config.categories.map(category => {
                    return (
                      <Flex key={category.title} direction="column">
                        <WppTypography type="m-strong">{category.title}</WppTypography>
                        <WppTypography type="s-body" className={styles.list}>
                          {category.steps.map(step => (
                            <li key={step}>{step}</li>
                          ))}
                        </WppTypography>
                        {category?.link && (
                          <Link to={category.link.url}>
                            <WppActionButton>
                              {category.link.title}
                              <WppIconDirections slot="icon-end" />
                            </WppActionButton>
                          </Link>
                        )}
                      </Flex>
                    )
                  })}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
        <Flex justify="end" align="end" className={styles.goToProject}>
          <Link to={routesManager.systemUrls.orchestration.project.getUrl('ffc2ce2c-0eca-47e9-9df0-069f87a0340f')}>
            <WppButton>Go to project details</WppButton>
          </Link>
        </Flex>
      </WppCard>
    </Flex>
  )
}
