// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/scheme-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UdydN{position:absolute;z-index:0;width:var(--process-scheme-width);height:var(--process-scheme-height);background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;background-position:34px;filter:blur(0);transition:filter .2s ease-in-out}.u9f92{position:absolute;top:50%;left:50%;z-index:0;width:calc(var(--process-scheme-width) - 55px);height:calc(var(--process-scheme-height) - 45px);background-color:#fff;border-radius:50%;transform:translate(-50%, -50%);opacity:.6}.IPB8z{filter:blur(8px)}.uptq0{position:relative;z-index:1}.ctbtc:hover{cursor:pointer;fill:#5ac5de}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processScheme/ProcessScheme.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,SAAA,CACA,iCAAA,CACA,mCAAA,CACA,4DAAA,CACA,wBAAA,CACA,cAAA,CACA,iCAAA,CAGF,OACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,SAAA,CACA,8CAAA,CACA,gDAAA,CACA,qBAAA,CACA,iBAAA,CACA,+BAAA,CACA,UAAA,CAGF,OACE,gBAAA,CAGF,OACE,iBAAA,CACA,SAAA,CAIA,aACE,cAAA,CACA,YAAA","sourcesContent":[".processSchemeBackround {\n  position: absolute;\n  z-index: 0;\n  width: var(--process-scheme-width);\n  height: var(--process-scheme-height);\n  background: url('/pages/customProcess/components/nestleHealth/processScheme/assets/scheme-background.png') no-repeat;\n  background-position: 34px;\n  filter: blur(0);\n  transition: filter 0.2s ease-in-out;\n}\n\n.processSchemeBackroundOverlay {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  z-index: 0;\n  width: calc(var(--process-scheme-width) - 55px);\n  height: calc(var(--process-scheme-height) - 45px);\n  background-color: #ffffff;\n  border-radius: 50%;\n  transform: translate(-50%, -50%);\n  opacity: 0.6;\n}\n\n.processSchemeBackroundBlur {\n  filter: blur(8px);\n}\n\n.processSchemeSvg {\n  position: relative;\n  z-index: 1;\n}\n\n.phaseCircleTrigger {\n  &:hover {\n    cursor: pointer;\n    fill: #5ac5de;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"processSchemeBackround": `UdydN`,
	"processSchemeBackroundOverlay": `u9f92`,
	"processSchemeBackroundBlur": `IPB8z`,
	"processSchemeSvg": `uptq0`,
	"phaseCircleTrigger": `ctbtc`
};
export default ___CSS_LOADER_EXPORT___;
