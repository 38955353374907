import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'pages/customProcess/components/ikeaNew/components/pill/Pill.module.scss'

interface Props {
  title: string
  variant?: 'white' | 'black'
}

export const Pill = ({ title, variant = 'black' }: Props) => {
  return (
    <WppButton className={clsx(styles.buttonWrapper, { [styles.buttonWrapperWhite]: variant === 'white' })}>
      <WppTypography
        className={clsx(styles.buttonTitle, { [styles.buttonTitleWhite]: variant === 'white' })}
        type="s-body"
        tag="span"
      >
        {title}
      </WppTypography>
    </WppButton>
  )
}
