import { Flex } from 'components/common/flex/Flex'
import copilotImgSrc from 'pages/customProcess/components/henkel/assets/copilot-logo.png'
import googleAdsImgSrc from 'pages/customProcess/components/henkel/assets/google-ads-logo.png'
import { PageLayout } from 'pages/customProcess/components/henkel/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/henkel/processPhases/pages/activate/Activate.module.scss'

const Activate = () => {
  return (
    <PageLayout
      title="Activate"
      description={'Translate to action and get set up for campaign\nand measurement success.'}
      previousLink="/custom/henkel/assets"
      inputsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Communications plan</li>
            <li>Budget setting per channel</li>
            <li>Measurement framework</li>
          </ul>
        </div>
      }
      activitiesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Implement and activate\ncampaign'}</li>
          </ul>
        </div>
      }
      toolsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>DSPs(TTD, DV360 etc.)</li>
            <li>Meta Business</li>
            <li>Manager</li>
          </ul>
          <Flex align="center" gap={4} className={styles.item}>
            <img src={googleAdsImgSrc} alt="Google Ads logo" className={styles.logo} />
            <span className={styles.itemText}>Google Ads</span>
          </Flex>
          <Flex align="center" gap={4} className={styles.item}>
            <img src={copilotImgSrc} alt="Co-Pilot logo" className={styles.logo} />
            <span className={styles.itemText}>Co-Pilot</span>
          </Flex>
          <ul className={styles.listHenkel}>
            <li>MAP</li>
            <li>HMC</li>
          </ul>
        </div>
      }
      outcomesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Final Media plan and\ncampaign is live!'}</li>
          </ul>
        </div>
      }
    />
  )
}

export default Activate
