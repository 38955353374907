import { processApi } from 'api'
import { UploadInfo } from 'types/attachments/attachments'

interface Params {
  names: string[]
  contentType: string
}

export const generateProcessUploadUrlsApi = ({ names, contentType }: Params) =>
  processApi.post<UploadInfo[]>('images/generate-temp-upload-urls', { names, contentType })
