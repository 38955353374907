import { Flex } from 'components/common/flex/Flex'
import { InputCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputCard/InputCard'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaignBrief/CampaignBrief.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const CampaignBrief = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/portfolio-allocation'
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/campaign-strategy'

  return (
    <ProcessCard
      title="Campaign Brief"
      subtitle="Receive and work on Campaign brief to help inform the campaign strategy"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="Media principles"
      showMroiFooter
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputCard title="From Campaign Analysis" text="Campaign Analysis is carried out, insights are generated" />
          <svg width="6" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 48 2.887-5H.113L3 48Zm.5-4.5V0h-1v43.5h1Z" fill="#307DE4" />
          </svg>
          <InputsOutputsCard
            title="inputs"
            text="Portfolio Country/Cluster allocation and communication Strategy agreement"
            cardStyles={styles.inputsCard}
          />
        </Flex>
        <div className={styles.regularCard2Wrapper}>
          <RegularCard
            text="Define business KPIs and performance metrics for core elements of the brief to measure against the campaign objectives"
            teamTags={[
              { text: 'Strategic Planning', tags: ['R'] },
              { text: 'DS&A', tags: ['I'] },
              { text: 'Investment', tags: ['I'] },
            ]}
            brandTags={[
              { text: 'Outcomes Framework', style: styles.synapseTagColor },
              { text: 'IDX', style: styles.synapseTagColor },
            ]}
          />
        </div>
        <InputsOutputsCard
          title="outputs"
          text="A fully aligned Brief with KPIs, CEF, SCT."
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignBrief
