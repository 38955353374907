import activateImgSrc from 'pages/customWidget/ikea/assets/activate.png'
import discoverImgSrc from 'pages/customWidget/ikea/assets/discover.png'
import measureImgSrc from 'pages/customWidget/ikea/assets/measure.png'
import planImgSrc from 'pages/customWidget/ikea/assets/plan.png'
import { PhaseData, Phases } from 'pages/customWidget/ikea/types'

interface IkeaProcessWidgetDataInterface {
  title: string
  phases: PhaseData[]
}

export const IkeaProcessWidgetData: IkeaProcessWidgetDataInterface = {
  title: 'Campaign Planning Process',
  phases: [
    {
      phaseId: Phases.Discover,
      title: 'Discover',
      bgColor: '#396321',
      color: '#FFFFFF',
      description: 'Identify opportunities and growth audiences',
      img: discoverImgSrc,
      moreInfo: {
        considerations: ['Brief Review', 'Opportunity Definition', 'Audience Insights'],
      },
      innerPageUrl: 'https://oppen-ikea.os.wpp.com/process/phase/c4a3580b-0f83-4ba9-a96d-75f9d0382ad7',
    },
    {
      phaseId: Phases.Plan,
      title: 'Plan',
      bgColor: '#0058AB',
      color: '#FFFFFF',
      description: 'Design strategic approach',
      img: planImgSrc,
      moreInfo: {
        considerations: ['Strategy Development', 'Scenario Planning', 'Campaign Planning'],
      },
      innerPageUrl: 'https://oppen-ikea.os.wpp.com/process/phase/c1fd7436-fe5d-4bea-b589-028ba61da700',
    },
    {
      phaseId: Phases.Activate,
      title: 'Activate',
      bgColor: '#DEBD9C',
      color: '#000000',
      description: 'Implement plan and optimize media together with creative',
      img: activateImgSrc,
      moreInfo: {
        considerations: ['Campaign Management', 'Campaign Governance', 'Campaign Optimization'],
      },
      innerPageUrl: 'https://oppen-ikea.os.wpp.com/process/phase/cac8b7b3-c90e-47f8-87ac-3a809337fca9',
    },
    {
      phaseId: Phases.Measure,
      title: 'Measure',
      bgColor: '#FBD915',
      color: '#000000',
      description: 'Analyze performance and adapt',
      img: measureImgSrc,
      moreInfo: {
        considerations: ['Reporting & Analytics'],
      },
      innerPageUrl: 'https://oppen-ikea.os.wpp.com/process/phase/41a5693b-b5a4-4ee8-873b-25ed71da0893',
    },
  ],
}
