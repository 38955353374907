import { processApi } from 'api'
import { Process } from 'types/process/process'

export const fetchProcessApi = ({ tenantId, processId }: { tenantId: string; processId?: string }) => {
  if (processId) {
    return processApi.get<Process>(`/processes/${processId}`)
  } else {
    return processApi.get<Process>(`tenants/${tenantId}/process`)
  }
}
