import { useMemo } from 'react'

export interface Props {
  id: string
  color: string
}

interface Fill {
  type: 'linear' | 'radial' | 'solid'
  colorStops: ColorStop[]
}

interface ColorStop {
  offset?: string | undefined
  stopColor: string
}

export const SvgGradient = ({ id, color }: Props) => {
  if (!color) color = '#EE3023' // TODO remove this line after default colors feature is implemented

  const fill: Fill = useMemo(() => {
    if (!(color.startsWith('linear-gradient') || color.startsWith('radial-gradient')))
      return { type: 'solid', colorStops: [{ stopColor: color }] }

    const colorStopRegex = /(rgba\([^)]+\)|RGB\([^)]+\))\s\d+%?/gi
    const colorStops = color.match(colorStopRegex)

    return {
      type: color.startsWith('linear-gradient') ? 'linear' : 'radial',
      colorStops: !colorStops
        ? []
        : colorStops.map((stop: string) => {
            const parts = stop.split(' ')
            const offset = parts.pop()
            const stopColor = parts.join(' ').trim()
            return { offset, stopColor }
          }),
    }
  }, [color])

  const GradientTag = (fill.type === 'radial' ? 'radialGradient' : 'linearGradient') as keyof JSX.IntrinsicElements

  return (
    <GradientTag id={id}>
      {fill.colorStops.map((stop, index) => (
        <stop key={index} offset={stop.offset} stopColor={stop.stopColor} />
      ))}
    </GradientTag>
  )
}
