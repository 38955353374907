import { SideModalFormConfig } from '@platform-ui-kit/components-library'
import { WppSideModal } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, ComponentProps } from 'react'

import { Portal } from 'components/common/portal/Portal'
import { useKeepUnmountedOnClose } from 'components/surface/utils'

export type SideModalProps = Omit<ComponentPropsWithoutRef<typeof WppSideModal>, 'formConfig'> & {
  /**
   * If you pass this prop wrapper of dialog will be rendered as form.
   */
  formConfig?: Pick<ComponentProps<'form'>, 'onSubmit' | 'onReset'>
}

export const SideModal = forwardRef<HTMLWppSideModalElement, SideModalProps>((props, ref) => {
  const [shouldRender, { formConfig, ...rest }] = useKeepUnmountedOnClose(props, 'onWppSideModalCloseComplete')

  return (
    <>
      {shouldRender && (
        <Portal>
          <WppSideModal ref={ref} {...rest} formConfig={formConfig as SideModalFormConfig} />
        </Portal>
      )}
    </>
  )
})
