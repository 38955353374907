import { WppTypography, WppIconDesign } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEventHandler, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/loreal/components/phaseCard/PhaseCard.module.scss'

interface Tool {
  name: string
  iconSrc: string
}

interface Props {
  phaseIndex: number
  onPhaseEnter: (phaseIndex: number) => MouseEventHandler
  onPhaseLeave: () => void
  isActive: boolean
  title: string
  steps: string[]
  tools: Tool[]
  link: string
}

export const PhaseCard = ({ phaseIndex, isActive, onPhaseEnter, onPhaseLeave, title, steps, tools, link }: Props) => {
  const cardBgStyle = useMemo(() => {
    switch (phaseIndex) {
      case 0:
        return styles.cardBg1
      case 1:
        return styles.cardBg2
      case 2:
        return styles.cardBg3
      case 3:
        return styles.cardBg4
      case 4:
        return styles.cardBg5
      default:
        return styles.cardBg1
    }
  }, [phaseIndex])

  return (
    <Link
      to={link}
      className={clsx(styles.phaseCard, !isActive && cardBgStyle, isActive && styles.phaseCardActive)}
      onMouseEnter={onPhaseEnter(phaseIndex)}
      onMouseLeave={onPhaseLeave}
    >
      {isActive ? (
        <div className={styles.content}>
          <WppTypography type="3xl-heading" tag="h3" className={styles.title}>
            {title}
          </WppTypography>
          <ul className={styles.stepsList}>
            {steps.map((step, index) => (
              <li key={index}>
                <WppTypography type="m-body" tag="p" className={styles.stepText}>
                  {step}
                </WppTypography>
              </li>
            ))}
          </ul>
          <div className={styles.toolsWrapper}>
            <Flex gap={8}>
              <WppIconDesign width={24} color="var(--wpp-grey-color-1000)" />
              <WppTypography type="xl-heading" className={styles.toolsTitle}>
                Tools
              </WppTypography>
            </Flex>
            <div>
              {tools.map((tool, index) => (
                <Flex align="center" gap={8} key={index} className={styles.toolItem}>
                  <div>
                    <img src={tool.iconSrc} alt={tool.name} className={styles.toolLogo} />
                  </div>
                  <WppTypography type="s-body">{tool.name}</WppTypography>
                </Flex>
              ))}
              {phaseIndex === 4 && (
                <ul className={styles.toolsUl}>
                  <li>
                    <WppTypography type="s-body">Measurement Framework</WppTypography>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      ) : (
        <WppTypography
          type="3xl-heading"
          tag="h3"
          className={clsx(styles.title, styles.whiteText, styles.inactiveTitlePosition)}
        >
          {title}
        </WppTypography>
      )}
    </Link>
  )
}
