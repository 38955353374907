import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (2).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Audience = () => {
  return (
    <ProcessCard
      type="strategy"
      title="Audience Planning"
      description="Where will growth come from and how will you be as relevant as possible to those people?"
      previousLink="/custom/fossil/define"
      nextLink="/custom/fossil/channels"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Audience
