import schemeImg from 'pages/customProcess/components/unilever/assets/SVG-Media_Ideation.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Media = () => {
  return (
    <ProcessCard
      type="solution"
      title="Media & Ideation"
      description="Finalising channels, their roles and inventive ideas/tactics to bring the strategy to life"
      previousLink="/custom/unilever/strategy"
      nextLink="/custom/unilever/consumer"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Media
