interface Props {
  className?: string
}

export const ExperienceAndInnovationSvg = ({ className }: Props) => (
  <svg
    width={732}
    height={732}
    viewBox="0 0 732 732"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M602.076 495.658C573.549 547.612 528.675 588.701 474.414 612.553C420.154 636.404 359.54 641.685 301.972 627.576L337.573 482.316C363.164 488.588 390.109 486.241 414.23 475.638C438.351 465.035 458.299 446.77 470.98 423.674L602.076 495.658Z"
      fill="#E08041"
    />
    <path
      d="M391.503 560.921C391.672 562.115 391.739 563.294 391.687 564.447C391.643 565.6 391.391 566.653 390.921 567.605C390.459 568.558 389.722 569.371 388.709 570.054C387.696 570.736 386.31 571.203 384.549 571.455C382.501 571.748 380.687 571.513 379.134 570.734C377.572 569.964 376.441 568.567 375.724 566.55L380.781 564.519C380.946 565.143 381.309 565.643 381.871 566.009C382.433 566.375 383.036 566.519 383.673 566.424C384.335 566.331 384.826 566.088 385.145 565.712C385.464 565.337 385.676 564.885 385.771 564.365C385.875 563.854 385.904 563.304 385.857 562.723C385.81 562.141 385.751 561.611 385.68 561.122L383.294 544.484L389.012 543.663L391.494 560.929L391.503 560.921Z"
      fill="white"
    />
    <path
      d="M402.253 540.848L406.887 539.703L424.26 562.415L417.931 563.98L414.401 559.132L404.517 561.578L403.728 567.497L397.547 569.03L402.244 540.856L402.253 540.848ZM406.241 547.721L405.109 556.397L411.325 554.858L406.241 547.721Z"
      fill="white"
    />
    <path
      d="M420.222 536.015L429.738 532.5C430.987 532.041 432.217 531.718 433.436 531.55C434.648 531.382 435.784 531.425 436.837 531.677C437.89 531.93 438.847 532.452 439.701 533.226C440.555 534.001 441.248 535.109 441.781 536.543C442.423 538.279 442.519 539.925 442.06 541.473C441.61 543.022 440.575 544.282 438.956 545.251L449.043 553.322L442.545 555.722L433.769 547.791L431.096 548.775L434.729 558.602L429.311 560.608L420.222 536.015ZM429.404 544.19L432.6 543.008C433.087 542.825 433.593 542.618 434.127 542.386C434.66 542.155 435.111 541.866 435.488 541.529C435.865 541.192 436.125 540.787 436.279 540.305C436.432 539.832 436.385 539.251 436.137 538.578C435.903 537.949 435.597 537.506 435.201 537.228C434.805 536.951 434.377 536.784 433.914 536.736C433.451 536.687 432.948 536.722 432.414 536.833C431.879 536.952 431.369 537.098 430.882 537.281L427.341 538.586L429.405 544.181L429.404 544.19Z"
      fill="white"
    />
    <path
      d="M440.199 528.685L446.094 525.745L459.559 538.231L459.622 538.201L457.858 519.887L463.396 517.129L465.407 545.42L461.263 547.487L440.189 528.693L440.199 528.685Z"
      fill="white"
    />
    <path
      d="M465.634 515.433L470.584 512.445L484.124 534.897L479.175 537.885L465.634 515.442L465.634 515.433Z"
      fill="white"
    />
    <path
      d="M489.553 507.386C488.825 507.173 488.056 507.181 487.238 507.41C486.42 507.64 485.716 507.963 485.128 508.372C484.783 508.617 484.461 508.905 484.154 509.239C483.847 509.572 483.597 509.943 483.389 510.343C483.189 510.744 483.084 511.151 483.08 511.583C483.078 512.005 483.222 512.421 483.505 512.828C483.962 513.472 484.553 513.797 485.278 513.803C486.002 513.809 486.823 513.666 487.731 513.391C488.638 513.116 489.601 512.801 490.617 512.447C491.634 512.093 492.667 511.878 493.716 511.802C494.766 511.727 495.799 511.892 496.807 512.296C497.816 512.701 498.76 513.534 499.642 514.784C500.477 515.972 500.991 517.172 501.169 518.375C501.346 519.578 501.267 520.738 500.924 521.854C500.581 522.97 500.009 524.027 499.209 525.009C498.408 525.999 497.451 526.875 496.345 527.663C494.949 528.647 493.512 529.344 492.026 529.753C490.539 530.17 488.944 530.26 487.242 530.042L488.006 523.982C488.943 524.288 489.905 524.361 490.892 524.195C491.878 524.029 492.765 523.675 493.554 523.115C493.935 522.848 494.293 522.527 494.62 522.16C494.947 521.794 495.206 521.406 495.389 521.004C495.571 520.602 495.659 520.194 495.654 519.771C495.647 519.356 495.511 518.95 495.234 518.568C494.777 517.925 494.17 517.59 493.412 517.565C492.654 517.539 491.809 517.655 490.877 517.919C489.944 518.184 488.956 518.488 487.897 518.84C486.846 519.191 485.787 519.404 484.728 519.488C483.67 519.572 482.635 519.424 481.634 519.045C480.624 518.666 479.711 517.879 478.867 516.692C478.056 515.541 477.575 514.369 477.413 513.184C477.261 512 477.348 510.84 477.699 509.725C478.051 508.609 478.606 507.551 479.389 506.568C480.173 505.577 481.086 504.715 482.137 503.975C483.351 503.125 484.638 502.47 486.015 502.019C487.384 501.568 488.809 501.423 490.291 501.593L489.542 507.42L489.553 507.386Z"
      fill="white"
    />
  </svg>
)
