export enum BGAlignmentOptions {
  LeftTop = 'left top',
  CenterTop = 'center top',
  RightTop = 'right top',
  LeftCenter = 'left center',
  CenterCenter = 'center center',
  RightCenter = 'right center',
  LeftBottom = 'left bottom',
  CenterBottom = 'center bottom',
  RightBottom = 'right bottom',
}

export enum DimensionUnits {
  auto = 'auto',
  pixel = 'px',
  percent = '%',
}

export interface Dimensions {
  width: {
    unit: DimensionUnits
    value?: number
  }
  height: {
    unit: DimensionUnits
    value?: number
  }
}
