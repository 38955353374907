import { Flex } from 'components/common/flex/Flex'
import brandAssetValuatorImgSrc from 'pages/customProcess/components/henkel/assets/brand-asset-valuator-logo.png'
import { PageLayout } from 'pages/customProcess/components/henkel/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/henkel/processPhases/pages/ambition/Ambition.module.scss'

const Ambition = () => {
  return (
    <PageLayout
      title="Ambition"
      description={'Source of growth. Setting success metrics.\nBudget analysis.'}
      previousLink="/custom/henkel/adapt"
      nextLink="/custom/henkel/audience"
      inputsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Campaign briefing</li>
            <li>Competitive data</li>
            <li>Objectives and KPIs</li>
          </ul>
        </div>
      }
      activitiesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Set goals</li>
            <li>{'Define media KPIs and\nbenchmarks'}</li>
          </ul>
        </div>
      }
      toolsChildren={
        <div>
          <Flex align="center" gap={4} className={styles.item}>
            <img src={brandAssetValuatorImgSrc} alt="Brand Asset Valuator logo" className={styles.logo} />
            <span className={styles.itemText}>Brand Asset Valuator</span>
          </Flex>
          <ul className={styles.listHenkel}>
            <li>Nielsen</li>
            <li>Atlas</li>
          </ul>
        </div>
      }
      outcomesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Measurement framework,\nincl. KPI setting and source of\ngrowth'}</li>
          </ul>
        </div>
      }
    />
  )
}

export default Ambition
