// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f94t6{position:relative;z-index:1;pointer-events:auto}.f94t6:hover{cursor:pointer}.SB_jw{opacity:0;transition:opacity .15s linear}.uqo9m{opacity:1}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/sein/components/PhaseSvg.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,SAAA,CACA,mBAAA,CAEA,aACE,cAAA,CAIJ,OACE,SAAA,CACA,8BAAA,CAGF,OACE,SAAA","sourcesContent":[".processPhase {\n  position: relative;\n  z-index: 1;\n  pointer-events: auto;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.phaseDetails {\n  opacity: 0;\n  transition: opacity 0.15s linear;\n}\n\n.phaseDetailsActive {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"processPhase": `f94t6`,
	"phaseDetails": `SB_jw`,
	"phaseDetailsActive": `uqo9m`
};
export default ___CSS_LOADER_EXPORT___;
