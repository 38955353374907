// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QCLdx{position:relative;height:calc(100vh - var(--wpp-os-header-height))}.tFQdF{width:100%;max-height:100%}.hHsQm{position:absolute;top:50%;left:50%;width:310px;height:310px;transform:translate(-50%, -53%);pointer-events:none}.Q1Bol{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/procterAndGamble/ProcterAndGambleProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,gDAAA,CAGF,OACE,UAAA,CACA,eAAA,CAGF,OACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,YAAA,CACA,+BAAA,CACA,mBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  position: relative;\n  height: calc(100vh - var(--wpp-os-header-height));\n}\n\n.svg {\n  width: 100%;\n  max-height: 100%;\n}\n\n.sphereGif {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 310px;\n  height: 310px;\n  transform: translate(-50%, -53%);\n  pointer-events: none;\n}\n\n.svgPoint {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `QCLdx`,
	"svg": `tFQdF`,
	"sphereGif": `hHsQm`,
	"svgPoint": `Q1Bol`
};
export default ___CSS_LOADER_EXPORT___;
