// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IA9V7{padding:16px 16px 64px}.bdGtl{padding:16px}.YVo7l{background:linear-gradient(79.87deg, #e733a0 10.42%, #fa426e 31.85%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleChina/processPhases/pages/portfolioAllocationAndCommunication/PortfolioAllocationAndCommunication.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,YAAA,CAGF,OACE,oEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 64px;\n}\n\n.outputsCard {\n  padding: 16px;\n}\n\n.synapseTagColor {\n  background: linear-gradient(79.87deg, #e733a0 10.42%, #fa426e 31.85%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `IA9V7`,
	"outputsCard": `bdGtl`,
	"synapseTagColor": `YVo7l`
};
export default ___CSS_LOADER_EXPORT___;
