import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Phases } from 'pages/customProcess/components/unilever/types'
import styles from 'pages/customProcess/components/unilever/UnileverProcess.module.scss'
import { UnileverSvg } from 'pages/customProcess/components/unilever/UnileverSvg'

const phaseLinks: Record<Phases, string> = {
  [Phases.Brief]: './unilever/integrated',
  [Phases.Interrogate]: './unilever/interrogate',
  [Phases.Insights]: './unilever/insights',
  [Phases.Strategy]: './unilever/strategy',
  [Phases.Media]: './unilever/media',
  [Phases.Consumer]: './unilever/consumer',
  [Phases.Measurement]: './unilever/measurement',
  [Phases.Learning]: './unilever/learning',
}

export const UnileverProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const navigate = useNavigate()

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex align="center" justify="center" className={styles.root}>
      <UnileverSvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
      />
    </Flex>
  )
}
