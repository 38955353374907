import audienceOriginLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/audience-origin-logo.svg'
import bavLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/bav-logo.svg'
import emarketerLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/emarketer-logo.svg'
import euromonitorLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/euromonitor-logo.png'
import statistaLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/statista-logo.svg'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/prerequisitesToBEP3/PrerequisitesToBEP3.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const PrerequisitesToBEP3 = () => {
  const previousLink = '/custom/big-brief-idea'
  const nextLink = '/custom/integrated-communications-brief'

  return (
    <ProcessCard
      title="Prerequisites to BEP3"
      bepTitle="prerequisites to BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="category sufficiency and media principles"
      showMroiFooter
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="Big Brief Idea is shared with Media Team"
          cardStyles={styles.inputsCardAsia}
        />
        <RegularCard
          text="Audience Segmentation and Journeys"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[
            { text: 'Audience Origin®', style: styles.audienceOriginTagColor, logoImgUrl: audienceOriginLogoImgUrl },
          ]}
        />
        <RegularCard
          text="Brand and Portfolio Planning"
          brandTags={[{ text: 'BAV®', style: styles.bavTagColor, logoImgUrl: bavLogoImgUrl }]}
        />
        <RegularCard
          text="Category Understanding"
          brandTags={[
            { text: 'Euromonitor®', style: styles.bavTagColor, logoImgUrl: euromonitorLogoImgUrl },
            { text: 'eMarketer®', style: styles.bavTagColor, logoImgUrl: emarketerLogoImgUrl },
            { text: 'Statista®', style: styles.bavTagColor, logoImgUrl: statistaLogoImgUrl },
          ]}
        />
        <InputsOutputsCard
          title="outputs"
          text="Portfolio Country/ Cluster allocation and communication Strategy agreement"
          cardStyles={styles.outputsCardAsia}
        />
      </>
    </ProcessCard>
  )
}

export default PrerequisitesToBEP3
