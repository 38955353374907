import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import headerIllustrationImgSrc from 'pages/customProcess/components/spotify/processPhases/processCard/assets/header-illustration.png'
import styles from 'pages/customProcess/components/spotify/processPhases/processCard/ProcessCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  type: 'discover' | 'decision' | 'design' | 'deliver' | 'adapt'
  title: string
  description: string
  previousLink?: string
  nextLink?: string
  classes?: {
    controls?: string
  }
}

export const ProcessCard = ({ children, type, title, description, previousLink, nextLink, classes }: Props) => {
  const headerBg = useMemo(() => {
    switch (type) {
      case 'discover':
        return styles.discoverHeaderBg
      case 'decision':
        return styles.decisionHeaderBg
      case 'design':
        return styles.designHeaderBg
      case 'deliver':
        return styles.deliverHeaderBg
      case 'adapt':
        return styles.adaptHeaderBg
      default:
        return styles.discoverHeaderBg
    }
  }, [type])

  const cardBg = useMemo(() => {
    switch (type) {
      case 'discover':
        return styles.discoverCardBg
      case 'decision':
        return styles.decisionCardBg
      case 'design':
        return styles.designCardBg
      case 'deliver':
        return styles.deliverCardBg
      case 'adapt':
        return styles.adaptCardBg
      default:
        return styles.discoverCardBg
    }
  }, [type])

  return (
    <>
      <div className={clsx([styles.cutomHeader, headerBg])}>
        <div className={styles.headerContent}>
          <Flex justify="between" gap={24}>
            <div className={styles.headerContentLeft}>
              <Link to="/custom">
                <WppActionButton variant="inverted" className={styles.backButton}>
                  <WppIconChevron direction="left" slot="icon-start" />
                  Back to Process overview
                </WppActionButton>
              </Link>
              <div>
                <WppTypography type="4xl-display" className={styles.headerTitle}>
                  {title}
                </WppTypography>
                <WppTypography type="s-body" className={styles.whiteText}>
                  {description}
                </WppTypography>
              </div>
            </div>
            <img src={headerIllustrationImgSrc} alt="" />
          </Flex>
        </div>
      </div>
      <div className={styles.mainContentWrapper}>
        <div className={styles.mainContent}>
          <div className={clsx([cardBg, styles.card])}>
            <Flex direction="column" justify="between" gap={24} className={styles.cardContent}>
              <div className={styles.flexGrow}>
                <Flex align="center" className={styles.descriptionCardsWrapper}>
                  <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInner}>
                    {children}
                  </Flex>
                </Flex>
              </div>
              <Flex align="center" justify="between" className={clsx(classes?.controls, styles.controls)}>
                <Flex gap={16}>
                  <Link to={previousLink ?? ''}>
                    <WppActionButton variant="inverted" disabled={!previousLink}>
                      Previous phase <WppIconChevron slot="icon-start" direction="left" />
                    </WppActionButton>
                  </Link>
                  <Link to={nextLink ?? ''}>
                    <WppActionButton variant="inverted" disabled={!nextLink}>
                      Next phase <WppIconChevron slot="icon-end" />
                    </WppActionButton>
                  </Link>
                </Flex>
                <Link to={routesManager.systemUrls.orchestration.root.url}>
                  <WppButton>Go to project details</WppButton>
                </Link>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </>
  )
}
