import { InputsOutputsCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleChina/processPhases/pages/mediaPlan/MediaPlan.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleChina/processPhases/processCard/ProcessCard'
import AudienceTranslatorIcon from 'pages/customProcess/components/nestleChina/processScheme/assets/AudienceTranslatorIcon.svg'

interface Props {
  isDemoTenant?: boolean
}

const MediaPlan = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/strategic-media-planning' : '/custom/ch/strategic-media-planning'
  const nextLink = isDemoTenant ? '/nestle/process/media-activation' : '/custom/ch/media-activation'

  return (
    <ProcessCard
      title="Media Plan"
      bepTitle="BEP 4"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Foundation for the Media Plan" cardStyles={styles.inputsCard} />
        <div className={styles.regularCard0Wrapper}>
          <RegularCard
            text="Create Strategic Media Plan (annual investment laydown)"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Audience Translator®', style: styles.redmillTagColor, icon: AudienceTranslatorIcon }]}
            bottomHighlightTextSpecial="Strategic Media Plan"
          />
        </div>
        <div className={styles.regularCard1Wrapper}>
          <RegularCard
            text="Devise the budget and Channel allocation"
            teamTags={[
              { text: 'Media Teams', tags: ['R', 'A'] },
              { text: 'Nestle Teams', tags: ['C'] },
            ]}
          />
        </div>
        <div className={styles.regularCard2Wrapper}>
          <RegularCard
            text="Approve Strategic Media Plan and Media Channel Plans for activation"
            teamTags={[
              { text: 'Nestle Teams', tags: ['R', 'A'] },
              { text: 'Media Teams', tags: ['I'] },
            ]}
          />
        </div>
        <InputsOutputsCard
          title="outputs"
          text="Media Plan sign-off Activation Briefing"
          cardStyles={styles.inputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default MediaPlan
