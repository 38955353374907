import { PhaseContent, Phases } from 'pages/customProcess/components/WBA/types'

export const PhasesDefaultContent: Record<Phases, PhaseContent> = {
  [Phases.Brief]: {
    title: 'OneBrief',
    description1: 'Initiate the flow with a process design for shared understanding of the business challenge.',
    description2:
      "A collaborative process that ensures we're all starting from one place of deep understanding of the business challenge and need.\n\nWe work together to define the heart of the opportunity, set crystal-clear goals, and lay the groundwork for an integrated solution that sparks and ignites impactful work.",
    output: ['Integrated Briefing Session'],
    buttons: [
      {
        title: 'Boots Process',
        url: 'https://wba.os.wpp.com/process/e9dd4e57-f522-4404-8ef4-6b23d3c94d7e/phase/5be33380-48d7-4b12-9ea1-d9a8b5804439?back-to-custom',
      },
      {
        title: 'No7 Process',
        url: 'https://wba.os.wpp.com/process/19364dd3-4ad5-48f4-a649-b50c0a130e02/phase/8f7d1cca-3e00-4a2b-881f-cb4b73353692?back-to-custom',
      },
    ],
  },
  [Phases.Direction]: {
    title: 'OneDirection',
    description1: 'Set the core customer opportunity and behaviour change we are uniquely positioned to solve.',
    description2:
      'A focused diagnostic of immediately available data, insights, brand health parameters and previous initiatives to uncover the distinctive strategic and creative opportunity for differentiation and growth, charting the course towards meaningful change that is designed for omnichannel impact.',
    output: ['Strategic Tissue [First Response]'],
    buttons: [
      {
        title: 'Boots Process',
        url: 'https://wba.os.wpp.com/process/e9dd4e57-f522-4404-8ef4-6b23d3c94d7e/phase/75f5e644-d77e-4338-9f81-a33ddb728773?back-to-custom',
      },
      {
        title: 'No7 Process',
        url: 'https://wba.os.wpp.com/process/19364dd3-4ad5-48f4-a649-b50c0a130e02/phase/228cd59c-fe4b-4e5d-8ae0-78699231e335?back-to-custom',
      },
    ],
  },
  [Phases.Strategy]: {
    title: 'OneStrategy',
    description1: 'One Thought. One Team. One powerful Outcome.',
    description2:
      'Galvanise the work around the single Integrated Strategic Proposition.\n\nWe rally around the single-minded integrated strategy proposition, a unifying strategic ambition that cuts through the noise and drives an integrated ideation process. The strategic proposition so potent, so undeniably compelling, that it becomes the driving force behind everything we make.',
    output: ['Strategic Response'],
    buttons: [
      {
        title: 'Boots Process',
        url: 'https://wba.os.wpp.com/process/e9dd4e57-f522-4404-8ef4-6b23d3c94d7e/phase/561ed0eb-8a70-4c60-9cbe-b629ffa0feb7?back-to-custom',
      },
      {
        title: 'No7 Process',
        url: 'https://wba.os.wpp.com/process/19364dd3-4ad5-48f4-a649-b50c0a130e02/phase/23b6a6cb-ada8-49e0-81f7-62c712515b8c?back-to-custom',
      },
    ],
  },
  [Phases.Idea]: {
    title: 'OneIdea',
    description1:
      'Focused on finding the bold, breakthrough big idea before jumping into channel-specific deliverables.',
    description2:
      "We'll uncover a central concept, a powerful creative hook, that serves as the foundation for all workstreams.\nWhile we'll explore and develop individual activation ideas along the way, each one will be a unique expression of this central theme, amplifying its impact and creating a unified brand narrative.",
    output: ['Integrated Creative Response'],
    buttons: [
      {
        title: 'Boots Process',
        url: 'https://wba.os.wpp.com/process/e9dd4e57-f522-4404-8ef4-6b23d3c94d7e/phase/06905182-c3c1-4fcd-8e9a-b942b48e24e7?back-to-custom',
      },
      {
        title: 'No7 Process',
        url: 'https://wba.os.wpp.com/process/19364dd3-4ad5-48f4-a649-b50c0a130e02/phase/d9ee1c52-0ecd-4c41-ac4b-0e35a7160836?back-to-custom',
      },
    ],
  },
  [Phases.Experience]: {
    title: 'OneExperience',
    description1: 'Develop connected activation plans for more seamless and effective brand experience.',
    description2:
      "A creative integration process, turning that big idea into tactical activation plans. We'll craft a seamless journey for the audience, where every touchpoint – from social media to events, from CRM to paid media – connects to our customers in a meaningfully different way.\n\nThis is the point where touchpoints planning, activation ideas and creative converge to design impact through consistency.",
    output: ['Creative Experience Journey'],
    buttons: [
      {
        title: 'Boots Process',
        url: 'https://wba.os.wpp.com/process/e9dd4e57-f522-4404-8ef4-6b23d3c94d7e/phase/2b12e208-fd06-4d86-823f-e88c99cfb0a8?back-to-custom',
      },
      {
        title: 'No7 Process',
        url: 'https://wba.os.wpp.com/process/19364dd3-4ad5-48f4-a649-b50c0a130e02/phase/74848a95-6315-4450-ba67-9033df58c884?back-to-custom',
      },
    ],
  },
  [Phases.Delivery]: {
    title: 'OneDelivery',
    description1:
      'Implementation through unified creative delivery. Shifting the mindset from producing ads to designing holistic experiences.',
    description2:
      'Connected experience implementation at its finest: from designing creative assets, experiences or events to smart media implementation systems  -  we’ll  orchestrate a seamlessly efficient delivery and optimisation process.\n\nGuided by a unified WoW that ensures consistency and impact across every touchpoint yet allows for creative flexibility, personalisation and optimisation.',
    output: ['Experience Implementation'],
    buttons: [
      {
        title: 'Boots Process',
        url: 'https://wba.os.wpp.com/process/e9dd4e57-f522-4404-8ef4-6b23d3c94d7e/phase/2300ed12-d75e-435a-bb60-072aeda42e13?back-to-custom',
      },
      {
        title: 'No7 Process',
        url: 'https://wba.os.wpp.com/process/19364dd3-4ad5-48f4-a649-b50c0a130e02/phase/868e049b-0442-4d35-aa5f-b2aec2986bdb?back-to-custom',
      },
    ],
  },
  [Phases.Impact]: {
    title: 'OneImpact',
    description1: 'Evaluate results and plan for optimisation.',
    description2:
      "We're not just delivering results, but constantly improving them. We'll track the metrics that matter, provide instant reporting, and use real-time insights to optimise our strategy, creative, and production throughout the experience. Consider it your brand's very own growth engine, constantly fine-tuning and informing upcoming initiatives and briefs.",
    output: ['Reporting & Optimisation'],
    buttons: [
      {
        title: 'Boots Process',
        url: 'https://wba.os.wpp.com/process/e9dd4e57-f522-4404-8ef4-6b23d3c94d7e/phase/f47a1fc0-0f22-4e10-85e5-ee9b6eef31bf?back-to-custom',
      },
      {
        title: 'No7 Process',
        url: 'https://wba.os.wpp.com/process/19364dd3-4ad5-48f4-a649-b50c0a130e02/phase/3e67acf5-cbdd-46d6-83be-9309e140c5bd?back-to-custom',
      },
    ],
  },
}
