import { WppTypography, WppIconGear } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useMemo, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as DocumentIcon } from 'pages/customProcess/components/shell/assets/icons/document.svg'
import { ReactComponent as GearsIcon } from 'pages/customProcess/components/shell/assets/icons/gears.svg'
import { ReactComponent as LightBulbIcon } from 'pages/customProcess/components/shell/assets/icons/light-bulb.svg'
import { ReactComponent as PieChartIcon } from 'pages/customProcess/components/shell/assets/icons/pie-chart.svg'
import styles from 'pages/customProcess/components/shell/components/phaseCard/PhaseCard.module.scss'
import { Phase, Phases } from 'pages/customProcess/components/shell/types'

interface Props {
  phase: Phase
  activePhase: MayBeNull<Phases>
  onPhaseEnter: (phase: Phases) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
}

export const PhaseCard = ({ phase, activePhase, onPhaseEnter, onPhaseLeave }: Props) => {
  const { type, color, title, description, tools } = phase

  const icon = useMemo(() => {
    switch (type) {
      case Phases.Discover:
        return <LightBulbIcon width={36} />
      case Phases.Plan:
        return <DocumentIcon width={36} />
      case Phases.Optimise:
        return <GearsIcon width={36} />
      case Phases.Measure:
        return <PieChartIcon width={36} />
      default:
        return <LightBulbIcon width={36} />
    }
  }, [type])

  const headerBg = useMemo(() => {
    switch (type) {
      case Phases.Discover:
        return styles.headerBgDiscover
      case Phases.Plan:
        return styles.headerBgPlan
      case Phases.Optimise:
        return styles.headerBgOptimise
      case Phases.Measure:
        return styles.headerBgMeasure
      default:
        return styles.headerBgDiscover
    }
  }, [type])

  return (
    <Link to={phase.link ?? ''} className={styles.phaseWrapper}>
      <Flex
        direction="column"
        className={clsx(styles.phase, !!activePhase && activePhase !== type && styles.phaseInactive)}
        style={{ borderColor: color }}
        onMouseEnter={onPhaseEnter(type)}
        onMouseLeave={onPhaseLeave}
      >
        <div className={clsx(styles.header, headerBg, activePhase === type && styles.headerMinimized)}>
          <div className={clsx(styles.shellMask, activePhase === type && styles.shellMaskHidden)} />
        </div>
        <div
          className={clsx(styles.mainContent, activePhase === type && styles.mainContentActive)}
          style={{ backgroundColor: color }}
        >
          <Flex justify={activePhase === type ? 'start' : 'center'} align="center" gap={16}>
            {icon}
            <WppTypography type="4xl-display" className={styles.phaseTitle}>
              {title}
            </WppTypography>
          </Flex>
          {activePhase === type && (
            <WppTypography
              type="2xl-heading"
              className={clsx(styles.phaseDescription, activePhase === type && styles.phaseDescriptionActive)}
            >
              {description}
            </WppTypography>
          )}
        </div>
        {activePhase === type && (
          <div className={styles.toolsContainer}>
            <Flex align="center" gap={8}>
              <WppIconGear color="#000000" width={20} />
              <WppTypography type="xl-heading" className={styles.toolsTitle}>
                Hero Tools:
              </WppTypography>
            </Flex>
            <ul className={styles.toolsList}>
              {tools.map((tool, index) => (
                <li key={index} className={styles.tool}>
                  <WppTypography type="xl-heading" className={styles.toolText}>
                    {tool}
                  </WppTypography>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Flex>
      <div className={clsx(styles.blurOverlay, !!activePhase && activePhase !== type && styles.blurOverlayActive)} />
    </Link>
  )
}
