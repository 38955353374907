import { WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customWidget/cocaCola/components/phaseDetails/PhaseDetails.module.scss'

interface Props {
  title: string
  children: ReactNode
}

export const PhaseDetails = ({ title, children }: Props) => {
  return (
    <>
      <WppTypography type="3xl-heading" className={styles.discActiveTitle}>
        {title}
      </WppTypography>
      <Flex direction="column" gap={2}>
        {children}
      </Flex>
    </>
  )
}
