import { Routes, Route } from 'react-router-dom'

import Action from 'pages/customProcess/components/ferreroNew/processPhases/pages/action/Action'
import Analysis from 'pages/customProcess/components/ferreroNew/processPhases/pages/analysis/Analysis'
import Audience from 'pages/customProcess/components/ferreroNew/processPhases/pages/audience/Audience'
import Challenge from 'pages/customProcess/components/ferreroNew/processPhases/pages/challenge/Challenge'
import Context from 'pages/customProcess/components/ferreroNew/processPhases/pages/context/Context'
import Engagement from 'pages/customProcess/components/ferreroNew/processPhases/pages/engagement/Engagement'
import Insight from 'pages/customProcess/components/ferreroNew/processPhases/pages/insight/Insight'
import Occasion from 'pages/customProcess/components/ferreroNew/processPhases/pages/occasion/Occasion'

export const FerreroRoutes = () => (
  <Routes>
    <Route path="challenge" element={<Challenge />} />
    <Route path="audience" element={<Audience />} />
    <Route path="insight" element={<Insight />} />
    <Route path="context" element={<Context />} />
    <Route path="occasion" element={<Occasion />} />
    <Route path="engagement" element={<Engagement />} />
    <Route path="analysis" element={<Analysis />} />
    <Route path="action" element={<Action />} />
  </Routes>
)
