import schemeImg from 'pages/customProcess/components/procterAndGamble/assets/attribution.png'
import { ProcessPandGCard } from 'pages/customProcess/components/procterAndGamble/processPhases/processPandGCard/ProcessPandGCard'

const Attribution = () => {
  return (
    <ProcessPandGCard
      title="Attribution"
      description="To fill the gaps in MMMs (extra brand /country combinations)  and feed back into the next annual NOS-planning cycle"
      previousLink="/custom/p&g/activation"
    >
      <img src={schemeImg} alt="" />
    </ProcessPandGCard>
  )
}

export default Attribution
