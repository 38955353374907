import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Arrow } from 'pages/customProcess/components/ibmNew/assets/arrow-in-output.svg'
import { ReactComponent as ArrowDown } from 'pages/customProcess/components/ibmNew/assets/input-arrow-down.svg'
import styles from 'pages/customProcess/components/ibmNew/components/inputOutputCard/InputOutputCard.module.scss'

interface Props {
  arrowPosition?: 'left' | 'right' | 'bottom'
  title: string
  content: string
  contentStyles?: string
}

export const InputOutputCard = ({ arrowPosition, title, content, contentStyles }: Props) => {
  return (
    <Flex>
      {arrowPosition === 'left' && <Arrow className={styles.arrowLeftRight} />}
      <Flex direction="column" align="center">
        <Flex direction="column" align="start">
          <Flex justify="center" align="center" className={styles.titleContainer}>
            <WppTypography tag="h6" type="2xs-strong" className={styles.title}>
              {title}
            </WppTypography>
          </Flex>
          <div className={clsx(styles.contentBox, contentStyles)}>
            <WppTypography tag="p" type="xs-body" className={styles.content}>
              {content}
            </WppTypography>
          </div>
        </Flex>
        {arrowPosition === 'bottom' && <ArrowDown />}
      </Flex>
      {arrowPosition === 'right' && <Arrow className={styles.arrowLeftRight} />}
    </Flex>
  )
}
