// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y6pmw{padding:6px 0 16px;color:#f40000;font-weight:700;font-size:24px;font-family:TCCC-UnityHeadline,sans-serif;line-height:32px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/cocaCola/components/phaseDetails/PhaseDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,yCAAA,CACA,gBAAA","sourcesContent":[".discActiveTitle {\n  padding: 6px 0 16px;\n  color: #f40000;\n  font-weight: 700;\n  font-size: 24px;\n  font-family: TCCC-UnityHeadline, sans-serif;\n  line-height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"discActiveTitle": `Y6pmw`
};
export default ___CSS_LOADER_EXPORT___;
