import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ferreroNew/processPhases/pages/analysis/Analysis.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/ProcessCard'

const Analysis = () => {
  return (
    <ProcessCard
      title="Analysis"
      description={
        'Define and execute measurement strategy based on core KPI’s identified in\nchallenge definition. Baseline. Measure.'
      }
      previousLink="/custom/ferrero/engagement"
      nextLink="/custom/ferrero/action"
    >
      <DescriptionCard icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Google Analytics,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Social metrics/tracking,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Brand health,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Cultural narrative/perception,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Top to mid funnel indicators.
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Activitites
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Baseline setting,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Social engagement\nreporting,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Site reporting,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Brand health and cultural\nperception tracking'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="tools">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Client data (sales etc.)
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Brandwatch,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                BAV,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Quid,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Google analytics
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                AYTM
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="people">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Teams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Led by strategy.\nPrimary = data\nstrategy.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Input from engagement and\nplanning.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Acct and creative\ninvolvement/review'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Results dashboard
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Analysis
