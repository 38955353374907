import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as HeaderIllustrationWhite } from 'pages/customProcess/components/ibmNew/assets/header-illustration-white.svg'
import { ReactComponent as HeaderIllustration } from 'pages/customProcess/components/ibmNew/assets/header-illustration.svg'
import styles from 'pages/customProcess/components/ibmNew/processPhases/pageLayout/PageLayout.module.scss'
import { Phases } from 'pages/customProcess/components/ibmNew/types'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  type: Phases
  title: string
  description: string
  prevLink?: string
  nextLink?: string
}

export const PageLayout = ({ children, title, description, type, prevLink, nextLink }: Props) => {
  const headerStyles = useMemo(() => {
    switch (type) {
      case Phases.CentralStrategy:
        return {
          headerBg: styles.headerBgStrategy,
        }
      case Phases.ChannelActivity:
        return {
          headerBg: styles.headerBgActivity,
        }
      case Phases.Execution:
        return {
          headerBg: styles.headerBgExecution,
          headerText: styles.headerTextWhite,
        }
      case Phases.Performance:
        return {
          headerBg: styles.headerBgPerformance,
        }
      case Phases.Reconciliation:
        return {
          headerBg: styles.headerBgReconciliation,
        }
      default:
        return {
          headerBg: styles.headerBgStrategy,
        }
    }
  }, [type])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className={styles.page}>
      <div className={clsx(styles.header, headerStyles.headerBg)}>
        <Flex justify="between" align="end" gap={8} className={styles.headerContent}>
          <div>
            {type === Phases.CentralStrategy ? (
              <Flex gap={8} className={styles.headerBtnWrapper}>
                <Link to="/custom">
                  <WppButton className={clsx(styles.backToProcessBtnStrategy, headerStyles?.headerText)}>
                    <WppIconChevron
                      direction="left"
                      slot="icon-start"
                      className={clsx(styles.backToProcessBtnChevronStrategy, headerStyles?.headerText)}
                    />
                    Back to process overview
                  </WppButton>
                </Link>
                <Link to={routesManager.systemUrls.orchestration.root.url}>
                  <WppButton className={styles.projectsButton}>Go to project</WppButton>
                </Link>
              </Flex>
            ) : (
              <Link to="/custom">
                <WppActionButton className={clsx(styles.backToProcessBtn, headerStyles?.headerText)}>
                  <WppIconChevron
                    direction="left"
                    slot="icon-start"
                    className={clsx(styles.backToProcessBtnChevron, headerStyles?.headerText)}
                  />
                  Back to process overview
                </WppActionButton>
              </Link>
            )}
            <div>
              <WppTypography type="4xl-display" className={clsx(styles.title, headerStyles?.headerText)}>
                {title}
              </WppTypography>
              <WppTypography type="s-body" className={clsx(styles.headerDescription, headerStyles?.headerText)}>
                {description}
              </WppTypography>
            </div>
          </div>
          {type === Phases.Execution ? (
            <HeaderIllustrationWhite className={styles.headerIllustration} />
          ) : (
            <HeaderIllustration className={styles.headerIllustration} />
          )}
        </Flex>
      </div>
      <Flex direction="column" justify="between" className={styles.mainContent}>
        <div>{children}</div>
        <Flex align="center" justify="between" className={styles.navigation}>
          <Flex gap={16}>
            <Link to={prevLink ?? ''}>
              <WppActionButton
                className={clsx(styles.navButton, !prevLink && styles.navButtonDisabled)}
                disabled={!prevLink}
                variant="secondary"
              >
                Previous phase{' '}
                <WppIconChevron
                  slot="icon-start"
                  direction="left"
                  className={clsx(styles.navIcon, !prevLink && styles.navIconDisabled)}
                />
              </WppActionButton>
            </Link>
            <Link to={nextLink ?? ''}>
              <WppActionButton
                className={clsx(styles.navButton, !nextLink && styles.navButtonDisabled)}
                disabled={!nextLink}
                variant="secondary"
              >
                Next phase{' '}
                <WppIconChevron slot="icon-end" className={clsx(styles.navIcon, !nextLink && styles.navIconDisabled)} />
              </WppActionButton>
            </Link>
          </Flex>
          <Link to={routesManager.systemUrls.orchestration.root.url}>
            <WppButton className={styles.projectsButton}>Go to project</WppButton>
          </Link>
        </Flex>
      </Flex>
    </div>
  )
}
