import activateSchemeImgSrc from 'pages/customProcess/components/mediaMarktSaturn/assets/phaseSchemes/activate-scheme.png'
import adaptSchemeImgSrc from 'pages/customProcess/components/mediaMarktSaturn/assets/phaseSchemes/adapt-scheme.png'
import ambitionSchemeImgSrc from 'pages/customProcess/components/mediaMarktSaturn/assets/phaseSchemes/ambition-scheme.png'
import approachSchemeImgSrc from 'pages/customProcess/components/mediaMarktSaturn/assets/phaseSchemes/approach-scheme.png'
import assetsAndIdeasSchemeImgSrc from 'pages/customProcess/components/mediaMarktSaturn/assets/phaseSchemes/assets-and-ideas-scheme.png'
import audienceSchemeImgSrc from 'pages/customProcess/components/mediaMarktSaturn/assets/phaseSchemes/audience-scheme.png'
import { Phases } from 'pages/customProcess/components/mediaMarktSaturn/types'

export const phasesData = {
  [Phases.Adapt]: {
    title: 'Adapt',
    description: 'We look at past learnings and wider business context.\nWe think about answering the brief.',
    schemeSrc: adaptSchemeImgSrc,
    nextLink: '/custom/media-markt-saturn/ambition',
  },
  [Phases.Ambition]: {
    title: 'Ambition',
    description:
      'We interrogate the client brief. Agree business, marketing and communications objectives/targets.\nStart the measurement framework. We set the budget.',
    schemeSrc: ambitionSchemeImgSrc,
    previousLink: '/custom/media-markt-saturn/adapt',
    nextLink: '/custom/media-markt-saturn/audience',
  },
  [Phases.Audience]: {
    title: 'Audience',
    description: 'Sources of growth, audience analysis for insights, best data sets for activation.',
    schemeSrc: audienceSchemeImgSrc,
    previousLink: '/custom/media-markt-saturn/ambition',
    nextLink: '/custom/media-markt-saturn/approach',
  },
  [Phases.Approach]: {
    title: 'Approach',
    description:
      'Create a full funnel integrated strategy, with clear roles for comms, direction for channels\n(including budget allocation).',
    schemeSrc: approachSchemeImgSrc,
    previousLink: '/custom/media-markt-saturn/audience',
    nextLink: '/custom/media-markt-saturn/assets-and-ideas',
  },
  [Phases.AssetsAndIdeas]: {
    title: 'Assets and Ideas',
    description: 'Bring innovation and invention.',
    schemeSrc: assetsAndIdeasSchemeImgSrc,
    previousLink: '/custom/media-markt-saturn/approach',
    nextLink: '/custom/media-markt-saturn/activate',
  },
  [Phases.Activate]: {
    title: 'Activate',
    description: 'Translate to action, getting set up for campaign delivery and measurement,\ntracking and reporting',
    schemeSrc: activateSchemeImgSrc,
    previousLink: '/custom/media-markt-saturn/assets-and-ideas',
  },
}

export const phaseLinks = {
  [Phases.Adapt]: '/custom/media-markt-saturn/adapt',
  [Phases.Ambition]: '/custom/media-markt-saturn/ambition',
  [Phases.Audience]: '/custom/media-markt-saturn/audience',
  [Phases.Approach]: '/custom/media-markt-saturn/approach',
  [Phases.AssetsAndIdeas]: '/custom/media-markt-saturn/assets-and-ideas',
  [Phases.Activate]: '/custom/media-markt-saturn/activate',
}
