import { InputsOutputsCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestle/processPhases/pages/portfolioAllocationAndCommunication/PortfolioAllocationAndCommunication.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestle/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const PortfolioAllocationAndCommunication = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/big-idea-development' : '/custom/big-idea-development'
  const nextLink = isDemoTenant ? '/nestle/process/campaign-brief' : '/custom/campaign-brief'

  return (
    <ProcessCard
      title="Portfolio Allocation and Communication Strategy"
      bepTitle="prerequisites to BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="category sufficiency and media principles"
      showMroiFooter
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Big Idea is shared with Media Team" cardStyles={styles.inputsCard} />
        <RegularCard
          text="Perform the assessment of how to communicate and measure the impact of Comms strategy across the Portfolio, Country / Cluster allocation"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[{ text: 'Synapse®', style: styles.synapseTagColor }]}
        />
        <InputsOutputsCard
          title="outputs"
          text="Portfolio Country/Cluster allocation and communication Strategy agreement"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default PortfolioAllocationAndCommunication
