import {
  WppButton,
  WppActionButton,
  WppIconDirections,
  WppIconChevron,
  WppTag,
} from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import Scheme from 'pages/customProcess/components/allianz/assetsIdeas/assets/assetsIdeas.png'
import styles from 'pages/customProcess/components/allianz/assetsIdeas/AssetsIdeas.module.scss'
import { ProcessCard } from 'pages/customProcess/utils/ProcessCard'
import { routesManager } from 'utils/routesManager'

const AssetsIdeas = () => {
  return (
    <ProcessCard
      title={
        <div className={styles.title}>
          Assets and Ideas
          <WppTag label="Deliver Phase" className={styles.titleTag} />
        </div>
      }
      description="Define our detailed media plans"
      footerLabel={false}
      classes={{
        className: styles.processCard,
        header: styles.header,
        contentWrapper: styles.contentWrapper,
        content: styles.content,
      }}
      headerLogoSlot={
        <Link to="/custom">
          <WppActionButton variant="inverted">
            <WppIconChevron slot="icon-start" direction="left" /> Back to Process overview
          </WppActionButton>
        </Link>
      }
    >
      <img src={Scheme} className={styles.scheme} alt="" />
      <Flex align="center" justify="between">
        <div>
          <Link to="/custom/approach">
            <WppActionButton className={styles.navButton} variant="secondary">
              Previous phase <WppIconDirections slot="icon-start" direction="left" />
            </WppActionButton>
          </Link>
          <WppActionButton className={styles.navButton} variant="secondary" disabled>
            Next phase <WppIconDirections slot="icon-end" />
          </WppActionButton>
        </div>
        <Link to={routesManager.systemUrls.orchestration.root.url}>
          <WppButton className={styles.projectsButton}>Go to project details</WppButton>
        </Link>
      </Flex>
    </ProcessCard>
  )
}

export default AssetsIdeas
