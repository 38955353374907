export enum Groups {
  Media = 'Media',
}

export enum Phases {
  Discover = 'Discover',
  Plan = 'Plan',
  Optimise = 'Optimise',
  Measure = 'Measure',
}

export interface Phase {
  type: Phases
  color: string
  title: string
  description: string
  detailsDescription: string
  schemeImgSrc: string
  tools: string[]
  link?: string
  previousLink?: string
  nextLink?: string
}
