// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WiYo5{margin-bottom:8px;color:#5c3327}.K2uTU{color:#5c3327;white-space:pre-wrap}.DR86K li{margin-left:22px;color:#5c3327 !important;line-height:22px;list-style-type:disc}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ferreroNew/processPhases/pages/occasion/Occasion.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,aAAA,CAGF,OACE,aAAA,CACA,oBAAA,CAIA,UACE,gBAAA,CACA,wBAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".descriptionCardTitle {\n  margin-bottom: 8px;\n  color: #5c3327;\n}\n\n.text {\n  color: #5c3327;\n  white-space: pre-wrap;\n}\n\n.list {\n  li {\n    margin-left: 22px;\n    color: #5c3327 !important;\n    line-height: 22px;\n    list-style-type: disc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionCardTitle": `WiYo5`,
	"text": `K2uTU`,
	"list": `DR86K`
};
export default ___CSS_LOADER_EXPORT___;
