import { useEffect } from 'react'

import discoverHeaderIllustrationImgSrc from 'pages/customProcess/components/lvmh/assets/discover-header-illustration.png'
import schemeImgSrc from 'pages/customProcess/components/lvmh/assets/discover-scheme.png'
import { ProcessPhasePageLayout } from 'pages/customProcess/components/lvmh/processPhases/components/processPhasePageLayout/ProcessPhasePageLayout'

const DiscoverLvmh = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <ProcessPhasePageLayout
      title="Discover"
      description="Proprietary data survey and research, globally consistent ecosystem to deliver audience insights to feed
    planning at a global and local level to aid activation, designed to meet global, local, and client
    requirements."
      headerImgSrc={discoverHeaderIllustrationImgSrc}
      tag="Governance"
      nextLink="/custom/lvmh/design"
    >
      <img alt="" src={schemeImgSrc} style={{ width: '100%', pointerEvents: 'none', userSelect: 'none' }} />
    </ProcessPhasePageLayout>
  )
}

export default DiscoverLvmh
