import { useEffect } from 'react'

import schemeImgSrc from 'pages/customProcess/components/sein/assets/discover-scheme.png'
import { ProcessPhasePageLayout } from 'pages/customProcess/components/sein/processPhases/components/processPhasePageLayout/ProcessPhasePageLayout'

const DiscoverSein = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <ProcessPhasePageLayout
      title="Discover"
      description="Discovering audience segments, insights, trends and brand health."
      nextLink="/custom/sein/design"
    >
      <img alt="" src={schemeImgSrc} style={{ width: '100%', pointerEvents: 'none', userSelect: 'none' }} />
    </ProcessPhasePageLayout>
  )
}

export default DiscoverSein
