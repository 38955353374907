import schemeImg from 'pages/customProcess/components/unilever/assets/SVG-Interrogate_the_brief.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Interrogate = () => {
  return (
    <ProcessCard
      type="challenge"
      title="Interrogate the Brief"
      description="Getting to the brief behind the brief, analysing if there are different, better or unique angles for growth & mapping project timelines"
      previousLink="/custom/unilever/integrated"
      nextLink="/custom/unilever/insights"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Interrogate
