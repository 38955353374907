import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/CK/CKProcess.module.scss'
import { CKSvg } from 'pages/customProcess/components/CK/CKSvg'
import { Phases } from 'pages/customProcess/components/CK/types'

const phaseLinks: Record<Phases, string> = {
  [Phases.Brief]: '/phase/81f78240-dd9f-4f34-a923-52009384b9a9?back-to-custom&name=PVH_CK',
  [Phases.Strategy_Channels]: '/phase/8264a889-257c-479f-8610-f56b4ae282be?back-to-custom&name=PVH_CK',
  [Phases.Media_Tactical_Plan]: '/phase/fcc14f97-e393-423c-ab22-43205d850e55?back-to-custom&name=PVH_CK',
  [Phases.Activation]: '/phase/20903481-aae4-455a-9fc2-172216e9ffb7?back-to-custom&name=PVH_CK',
  [Phases.Measurement]: '/phase/6805dc13-2ef4-44e0-8a1d-e0bba280e902?back-to-custom&name=PVH_CK',
}

export const CKProcess = () => {
  const navigate = useNavigate()
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex align="center" justify="center" className={styles.root}>
      <CKSvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
      />
    </Flex>
  )
}
