interface Props {
  className?: string
}

export const ReachAndEngagementSvg = ({ className }: Props) => (
  <svg
    width={759}
    height={759}
    viewBox="0 0 759 759"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M550.562 586.633C504.87 624.385 447.997 646.006 388.764 648.141C329.531 650.276 271.249 632.806 222.957 598.441L285.4 510.691C314.375 531.31 349.345 541.792 384.884 540.51C420.424 539.229 454.548 526.257 481.963 503.606L550.562 586.633Z"
      fill="#E69F71"
    />
    <path
      d="M504.519 531.279C471.088 558.901 429.476 574.72 386.138 576.282C342.799 577.845 300.157 565.063 264.823 539.919L310.51 475.716C331.711 490.802 357.296 498.471 383.299 497.533C409.302 496.596 434.269 487.105 454.328 470.532L504.519 531.279Z"
      fill="#E08041"
    />
    <path
      d="M341.121 538.702C340.862 539.637 340.534 540.527 340.125 541.36C339.723 542.196 339.199 542.891 338.547 543.442C337.901 543.996 337.096 544.359 336.131 544.537C335.165 544.715 333.993 544.614 332.615 544.235C331.011 543.793 329.75 543.038 328.855 541.966C327.951 540.897 327.566 539.504 327.684 537.788L332.063 537.914C331.985 538.426 332.092 538.911 332.389 539.361C332.686 539.811 333.084 540.111 333.584 540.246C334.102 540.389 334.542 540.368 334.897 540.194C335.253 540.019 335.554 539.754 335.791 539.402C336.032 539.058 336.229 538.662 336.381 538.218C336.533 537.775 336.66 537.365 336.765 536.982L340.346 523.953L344.823 525.183L341.111 538.705L341.121 538.702Z"
      fill="white"
    />
    <path
      d="M355.486 527.357L359.27 528.001L364.786 550.316L359.619 549.438L358.573 544.732L350.503 543.363L348.021 547.473L342.974 546.619L355.477 527.36L355.486 527.357ZM356.22 533.703L352.602 539.734L357.677 540.595L356.22 533.703Z"
      fill="white"
    />
    <path
      d="M370.282 529.562L378.424 530.025C379.491 530.087 380.501 530.244 381.454 530.512C382.401 530.776 383.225 531.172 383.92 531.697C384.615 532.221 385.153 532.913 385.534 533.757C385.915 534.602 386.07 535.642 386.002 536.87C385.919 538.355 385.461 539.599 384.626 540.593C383.797 541.59 382.63 542.186 381.126 542.381L385.97 551.567L380.411 551.25L376.488 542.589L374.202 542.456L373.726 550.865L369.088 550.605L370.282 529.562ZM374.426 538.533L377.161 538.688C377.579 538.71 378.018 538.719 378.486 538.72C378.953 538.72 379.378 538.652 379.764 538.525C380.15 538.397 380.472 538.182 380.74 537.877C381.004 537.577 381.156 537.133 381.189 536.558C381.219 536.02 381.135 535.594 380.933 535.263C380.73 534.932 380.468 534.671 380.142 534.487C379.817 534.302 379.435 534.167 379.006 534.077C378.573 533.993 378.15 533.937 377.732 533.916L374.704 533.741L374.429 538.527L374.426 538.533Z"
      fill="white"
    />
    <path
      d="M387.358 530.57L392.647 530.295L398.564 543.819L398.62 543.817L403.198 529.753L408.165 529.497L400.568 550.994L396.849 551.188L387.348 530.573L387.358 530.57Z"
      fill="white"
    />
    <path
      d="M410.359 528.966L414.966 528.352L417.741 549.245L413.134 549.859L410.356 528.972L410.359 528.966Z"
      fill="white"
    />
    <path
      d="M430.57 530.71C430.102 530.32 429.533 530.079 428.856 529.986C428.18 529.892 427.557 529.905 426.992 530.017C426.66 530.087 426.33 530.196 425.996 530.343C425.663 530.49 425.36 530.684 425.078 530.912C424.802 531.143 424.594 531.409 424.453 531.726C424.315 532.037 424.288 532.389 424.366 532.781C424.496 533.402 424.828 533.831 425.36 534.068C425.892 534.305 426.543 534.463 427.3 534.551C428.058 534.64 428.868 534.717 429.73 534.782C430.593 534.847 431.424 535.02 432.222 535.301C433.019 535.582 433.728 536.036 434.341 536.658C434.954 537.28 435.384 538.196 435.632 539.401C435.866 540.544 435.86 541.594 435.605 542.537C435.35 543.481 434.92 544.311 434.309 545.023C433.697 545.736 432.936 546.331 432.032 546.798C431.124 547.271 430.137 547.609 429.069 547.835C427.724 548.113 426.442 548.165 425.215 547.989C423.985 547.82 422.781 547.375 421.596 546.667L424.104 542.446C424.697 542.972 425.382 543.335 426.163 543.529C426.943 543.723 427.711 543.747 428.471 543.588C428.838 543.513 429.205 543.391 429.564 543.226C429.922 543.061 430.238 542.858 430.501 542.621C430.765 542.383 430.961 542.11 431.092 541.796C431.221 541.489 431.25 541.145 431.169 540.776C431.039 540.154 430.699 539.713 430.148 539.451C429.598 539.189 428.938 539.003 428.165 538.899C427.393 538.795 426.568 538.702 425.674 538.621C424.787 538.543 423.938 538.36 423.131 538.082C422.323 537.804 421.608 537.363 420.992 536.763C420.369 536.159 419.948 535.286 419.708 534.14C419.48 533.031 419.501 532.013 419.762 531.088C420.029 530.166 420.466 529.339 421.083 528.63C421.7 527.921 422.449 527.319 423.341 526.846C424.237 526.367 425.186 526.024 426.199 525.816C427.366 525.579 428.525 525.51 429.685 525.62C430.838 525.726 431.935 526.077 432.973 526.677L430.551 530.732L430.57 530.71Z"
      fill="white"
    />
    <path
      d="M382.329 619.236L369.579 618.499C367.936 618.406 366.429 618.072 365.063 617.506C363.697 616.941 362.53 616.165 361.574 615.171C360.615 614.183 359.889 613.009 359.389 611.648C358.89 610.287 358.695 608.754 358.788 607.049C358.881 605.467 359.252 604.026 359.913 602.731C360.569 601.433 361.437 600.344 362.502 599.447C363.564 598.557 364.796 597.883 366.2 597.437C367.595 596.993 369.082 596.813 370.642 596.903C372.208 596.996 373.659 597.348 374.994 597.96C376.329 598.572 377.477 599.385 378.433 600.395C379.389 601.405 380.121 602.581 380.63 603.939C381.141 605.291 381.346 606.759 381.253 608.34C381.207 609.093 381.087 609.823 380.873 610.522C380.66 611.22 380.373 611.882 379.999 612.5C379.625 613.117 379.158 613.657 378.593 614.132C378.028 614.606 377.379 614.985 376.651 615.26L376.644 615.319L382.528 615.661L382.325 619.227L382.329 619.236ZM363.601 607.331C363.551 608.244 363.669 609.102 363.957 609.914C364.244 610.726 364.666 611.443 365.224 612.058C365.772 612.676 366.427 613.177 367.181 613.558C367.935 613.939 368.757 614.152 369.646 614.205C370.534 614.258 371.38 614.135 372.17 613.844C372.961 613.553 373.671 613.128 374.283 612.576C374.902 612.028 375.4 611.364 375.783 610.588C376.167 609.813 376.381 608.975 376.437 608.065C376.487 607.152 376.37 606.294 376.082 605.481C375.794 604.669 375.37 603.958 374.821 603.34C374.272 602.722 373.618 602.221 372.864 601.84C372.11 601.46 371.287 601.246 370.399 601.193C369.511 601.14 368.665 601.264 367.874 601.555C367.084 601.846 366.373 602.27 365.761 602.822C365.143 603.371 364.645 604.034 364.261 604.81C363.871 605.582 363.664 606.424 363.607 607.334L363.601 607.331Z"
      fill="white"
    />
    <path
      d="M392.848 597.894L396.685 597.791L406.411 618.615L401.176 618.755L399.242 614.336L391.059 614.555L389.419 619.063L384.301 619.2L392.848 597.894ZM394.797 603.972L392.412 610.585L397.561 610.447L394.8 603.966L394.797 603.972Z"
      fill="white"
    />
  </svg>
)
