import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as DecorativeArrow } from 'pages/customProcess/components/cocaCola/assets/decorative-arrow.svg'
import { ReactComponent as AiIcon } from 'pages/customProcess/components/cocaCola/assets/icons/ai.svg'
import styles from 'pages/customProcess/components/cocaCola/CocaColaProcess.module.scss'
import { PhaseDetails } from 'pages/customProcess/components/cocaCola/components/phaseDetails/PhaseDetails'

enum Phases {
  Assemble = 'Assemble',
  Explore = 'Explore',
  Create = 'Create',
  Shape = 'Shape',
  Produce = 'Produce',
  Learn = 'Learn',
}

export const CocaColaProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <div className={styles.root}>
      <Flex direction="column" className={styles.pageContent}>
        <div className={styles.headerWrapper}>
          <div>
            <WppTypography type="3xl-heading" className={styles.title}>
              The Experience Loop Process
            </WppTypography>
          </div>
          <WppTypography type="s-body" className={styles.description}>
            {
              'Enhance your marketing prowess and strength through the Experience Loop process. Reimagined based on your feedback to provide greater guidance, transparency, and collaboration fueled with next-generation data, tools, and communication.\nExplore the Experience Loop, phases, and activities, and share your thoughts with us!'
            }
          </WppTypography>
        </div>
        <Flex direction="column" justify="center" className={styles.schemeContainer}>
          <Flex justify="center" className={styles.schemeWrapper} gap={16}>
            <div className={clsx(styles.discWrapper, styles.assembleOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.assembleDisc, activePhase === Phases.Assemble && styles.assembleDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Assemble)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Assemble ? (
                  <div className={styles.assembleDetailsRescale}>
                    <Flex direction="column" justify="center" align="center" className={styles.assembleDetails}>
                      <PhaseDetails title="Assemble">
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            The right team
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            The learnings from the past
                          </WppTypography>
                        </Flex>
                        <ul className={styles.stepsList}>
                          <li>
                            <WppTypography type="s-body" className={clsx(styles.redTypography, styles.liText)}>
                              The project blueprint
                            </WppTypography>
                          </li>
                        </ul>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography type="3xl-heading" className={styles.discInactiveTitle}>
                    Assemble
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.assembleDecorativeDisc1} />
              <div className={styles.assembleDecorativeDisc2} />
              <div className={styles.assembleDecorativeDisc3} />
              <div className={styles.assembleDecorativeDisc4} />
              <div
                className={clsx(
                  styles.assembleDecorativeActiveDisc,
                  activePhase === Phases.Assemble && styles.noOpacity,
                )}
              />
            </div>
            <div className={styles.discWrapper}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.exploreDisc, activePhase === Phases.Explore && styles.exploreDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Explore)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Explore ? (
                  <div className={styles.exploreDetailsRescale}>
                    <Flex direction="column" justify="center" align="center" className={styles.exploreDetails}>
                      <PhaseDetails title="Explore">
                        <ul className={styles.stepsList}>
                          <li>
                            <WppTypography type="s-body" className={clsx(styles.redTypography, styles.liText)}>
                              Marketing Opportunity Brief
                            </WppTypography>
                          </li>
                        </ul>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Insights
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Experience Architecture
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            The Idea Brief
                          </WppTypography>
                        </Flex>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography type="3xl-heading" className={styles.discInactiveTitle}>
                    Explore
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.exploreDecorativeDisc1} />
              <div className={styles.exploreDecorativeDisc2} />
              <div className={styles.exploreDecorativeDisc3} />
              <div className={styles.exploreDecorativeDisc4} />
              <div className={styles.exploreDecorativeDisc5} />
              <div
                className={clsx(
                  styles.exploreDecorativeActiveDisc1,
                  activePhase === Phases.Explore && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.exploreDecorativeActiveDisc2,
                  activePhase === Phases.Explore && styles.noOpacity,
                )}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.createOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.createDisc, activePhase === Phases.Create && styles.createDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Create)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Create ? (
                  <div className={styles.createDetailsRescale}>
                    <Flex direction="column" justify="center" align="center" className={styles.createDetails}>
                      <PhaseDetails title="Create">
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Experience Idea
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Moments Engine
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Experience Evaluator
                          </WppTypography>
                        </Flex>
                        <ul className={styles.stepsList}>
                          <li>
                            <WppTypography type="s-body" className={clsx(styles.redTypography, styles.liText)}>
                              Plan
                            </WppTypography>
                          </li>
                        </ul>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography type="3xl-heading" className={styles.discInactiveTitle}>
                    Create
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.createDecorativeDisc1} />
              <div className={styles.createDecorativeDisc2} />
              <div className={styles.createDecorativeDisc3} />
              <div className={styles.createDecorativeDisc4} />
              <div className={styles.createDecorativeDisc5} />
              <div
                className={clsx(styles.createDecorativeActiveDisc1, activePhase === Phases.Create && styles.noOpacity)}
              />
              <div
                className={clsx(styles.createDecorativeActiveDisc2, activePhase === Phases.Create && styles.noOpacity)}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.shapeOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.shapeDisc, activePhase === Phases.Shape && styles.shapeDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Shape)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Shape ? (
                  <div className={styles.shapeDetailsRescale}>
                    <Flex direction="column" justify="center" align="center" className={styles.shapeDetails}>
                      <PhaseDetails title="Shape">
                        <ul className={styles.stepsList}>
                          <li>
                            <WppTypography type="s-body" className={clsx(styles.redTypography, styles.liText)}>
                              OU Plan
                            </WppTypography>
                          </li>
                        </ul>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Creative Studio
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Architect
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Experiment Design
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Pre-flight Test
                          </WppTypography>
                        </Flex>
                        <ul className={styles.stepsList}>
                          <li>
                            <WppTypography type="s-body" className={clsx(styles.redTypography, styles.liText)}>
                              Content Plan
                            </WppTypography>
                          </li>
                        </ul>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography type="3xl-heading" className={styles.discInactiveTitle}>
                    Shape
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.shapeDecorativeDisc1} />
              <div
                className={clsx(styles.shapeDecorativeActiveDisc1, activePhase === Phases.Shape && styles.noOpacity)}
              />
              <div
                className={clsx(styles.shapeDecorativeActiveDisc2, activePhase === Phases.Shape && styles.noOpacity)}
              />
              <div
                className={clsx(styles.shapeDecorativeActiveDisc3, activePhase === Phases.Shape && styles.noOpacity)}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.produceOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.produceDisc, activePhase === Phases.Produce && styles.produceDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Produce)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Produce ? (
                  <div className={styles.produceDetailsRescale}>
                    <Flex direction="column" justify="center" align="center" className={styles.produceDetails}>
                      <PhaseDetails title="Produce">
                        <ul className={styles.stepsList}>
                          <li>
                            <WppTypography type="s-body" className={clsx(styles.redTypography, styles.liText)}>
                              Delivery Plan
                            </WppTypography>
                          </li>
                        </ul>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Content Engine
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Localization
                          </WppTypography>
                        </Flex>
                        <ul className={styles.stepsList}>
                          <li>
                            <WppTypography type="s-body" className={clsx(styles.redTypography, styles.liText)}>
                              Media Activation
                            </WppTypography>
                          </li>
                        </ul>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography type="3xl-heading" className={styles.discInactiveTitle}>
                    Produce
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.produceDecorativeDisc1} />
              <div className={styles.produceDecorativeDisc2} />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc1,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc2,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc3,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
              <div
                className={clsx(
                  styles.produceDecorativeActiveDisc4,
                  activePhase === Phases.Produce && styles.noOpacity,
                )}
              />
            </div>
            <div className={clsx(styles.discWrapper, styles.learnOffset)}>
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.learnDisc, activePhase === Phases.Learn && styles.learnDiscActive)}
                onMouseEnter={handlePhaseEnter(Phases.Learn)}
                onMouseLeave={handlePhaseLeave}
              >
                {activePhase === Phases.Learn ? (
                  <div className={styles.learnDetailsRescale}>
                    <Flex direction="column" justify="center" align="center" className={styles.learnDetails}>
                      <PhaseDetails title="Learn">
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Brains Jury
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Activation
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Optimization
                          </WppTypography>
                        </Flex>
                        <Flex gap={8}>
                          <AiIcon width={20} />
                          <WppTypography type="s-body" className={styles.redTypography}>
                            Weekly+ Analytics
                          </WppTypography>
                        </Flex>
                      </PhaseDetails>
                    </Flex>
                  </div>
                ) : (
                  <WppTypography type="3xl-heading" className={styles.discInactiveTitle}>
                    Learn
                  </WppTypography>
                )}
              </Flex>
              <div className={styles.learnDecorativeDisc1} />
              <div className={styles.learnDecorativeDisc2} />
              <div className={styles.learnDecorativeDisc3} />
              <div
                className={clsx(styles.learnDecorativeActiveDisc1, activePhase === Phases.Learn && styles.noOpacity)}
              />
              <div
                className={clsx(styles.learnDecorativeActiveDisc2, activePhase === Phases.Learn && styles.noOpacity)}
              />
              <div
                className={clsx(styles.learnDecorativeActiveDisc3, activePhase === Phases.Learn && styles.noOpacity)}
              />
            </div>
          </Flex>
          <div className={styles.decorativeArrowContainer}>
            <DecorativeArrow />
          </div>
        </Flex>
      </Flex>
      <div className={clsx(styles.blurOverlay, { [styles.blurOverlayActive]: activePhase })} />
    </div>
  )
}
