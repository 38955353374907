import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ibm/processPhases/pages/deliver/Deliver.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ibm/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/ibm/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'deliver'

const Deliver = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Deliver"
      description="Closing the gap between strategy and execution with a media plan that delivers on client objectives and channel priorities"
      previousLink="/custom/ibm/design"
      nextLink="/custom/ibm/adapt"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Campaign Architecture</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Budget by Channel</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Reach & Frequency Projections</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Outcome Projections</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Implementation Teams (regional)',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Implementation Teams (local)',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Regional Client Lead',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Regional Client Team',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Addressable Delivery</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Platform Audience Translator</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Inventory strategy</WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Choreograph Create</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Nexus Growth Platform</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Magic!</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Media Plan</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Asset Resize</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Asset Delivery</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Closed Platform site test</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Creative Adaptation signoff</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Deliver
