// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XnT5T{padding:16px 16px 40px}.zrv4n{padding:16px 16px 64px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/bigIdeaDevelopment/BigIdeaDevelopment.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.outputsCard {\n  padding: 16px 16px 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `XnT5T`,
	"outputsCard": `zrv4n`
};
export default ___CSS_LOADER_EXPORT___;
