import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState, useRef, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Group } from 'pages/processBuilder/templates/linear1/group/Group'
import styles from 'pages/processBuilder/templates/linear1/LinearProcess1.module.scss'
import { ProcessFormConfig, FormPhaseWithIndex } from 'types/process/process'
import { getPreviewImageUrl, normalizeDimensions } from 'utils/builder'

export const LinearProcess1 = ({ config }: { config: ProcessFormConfig }) => {
  const pageRef = useRef<HTMLDivElement>(null)

  const [pageWidth, setPageWidth] = useState<number | undefined>(undefined)
  const [activePhaseIndex, setActivePhaseIndex] = useState(0)
  const logo = useMemo(() => getPreviewImageUrl(config?.logoImage), [config?.logoImage])
  const { width: logoWidth, height: logoHeight } = useMemo(
    () => normalizeDimensions(config.logoDimensions),
    [config.logoDimensions, config.logoDimensions.width, config.logoDimensions.height], //eslint-disable-line
  )
  const headerBackground = useMemo(() => getPreviewImageUrl(config?.headerBackground), [config?.headerBackground])

  const handlePhaseChange = (index: number) => {
    if (index === activePhaseIndex) {
      return
    }
    setActivePhaseIndex(index)
  }

  useEffect(() => {
    if (!pageRef.current) {
      return
    }

    const pageResizeObserver = new ResizeObserver(() => {
      if (pageRef.current?.offsetWidth !== pageWidth) {
        setPageWidth(pageRef.current?.offsetWidth)
      }
    })

    pageResizeObserver.observe(pageRef.current)

    return () => {
      pageResizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRef.current])

  const phasesAreUngrouped = useMemo(() => !config.groups || config.groups.length === 0, [config])

  const phasesByGroups = useMemo(() => {
    if (phasesAreUngrouped) {
      const ungroupedPhases = config.phases.map((phase, index) => {
        return { ...phase, phaseIndex: index + 1 }
      })
      return { data: [ungroupedPhases], numberOfPhases: config.phases.length }
    }

    let phaseIndex = 1
    const phasesByGroups = config.groups.map(group =>
      group.phasesLocalIds.map(phaseId => {
        const phase = config.phases.find(phase => phase.localId === phaseId)
        const phaseWithIndex = { ...phase, phaseIndex }
        phaseIndex++
        return phaseWithIndex as FormPhaseWithIndex
      }),
    )
    return { data: phasesByGroups, numberOfPhases: phaseIndex - 1 }
  }, [config, phasesAreUngrouped])

  return (
    <div className={styles.root} onClick={() => setActivePhaseIndex(0)}>
      <Flex
        className={styles.header}
        justify="center"
        style={{
          background: `url(${headerBackground}) no-repeat center / cover, ${config.colors[3]}`,
        }}
      >
        <Flex justify="between" align="center" gap={24} className={styles.headerContainer}>
          <div className={styles.headerTextWrapper}>
            <WppTypography type="4xl-display" className={styles.title} style={{ color: config?.colors?.[1] }}>
              {config.title}
            </WppTypography>
            <WppTypography type="s-body" className={styles.description} style={{ color: config?.colors?.[2] }}>
              {config.description}
            </WppTypography>
          </div>

          {logo && (
            <Flex justify="end" align="center" className={styles.logoWrap}>
              <img src={logo} alt="" className={styles.logo} style={{ width: logoWidth, height: logoHeight }} />
            </Flex>
          )}
        </Flex>
      </Flex>

      <div className={styles.pageWrapper}>
        <div className={styles.page} ref={pageRef}>
          <Flex gap={4}>
            {phasesAreUngrouped ? (
              <Group
                pageWidth={pageWidth}
                phases={phasesByGroups.data[0]}
                numberOfAllPhases={phasesByGroups.numberOfPhases}
                isVerticalPhaseNumber={config.templateOptions?.verticalPhaseNumbers || false}
                activePhaseIndex={activePhaseIndex}
                handlePhaseChange={handlePhaseChange}
                withBlur={config.templateOptions?.isInactivePhaseBlured}
              />
            ) : (
              <>
                {config.groups.map((group, index) => (
                  <Group
                    key={index}
                    pageWidth={pageWidth}
                    group={group}
                    phases={phasesByGroups.data[index]}
                    numberOfAllPhases={phasesByGroups.numberOfPhases}
                    isVerticalPhaseNumber={config.templateOptions?.verticalPhaseNumbers || false}
                    activePhaseIndex={activePhaseIndex}
                    handlePhaseChange={handlePhaseChange}
                    withBlur={config.templateOptions?.isInactivePhaseBlured}
                  />
                ))}
              </>
            )}
          </Flex>
        </div>

        <div className={styles.footer}>
          <Link to={config?.button?.url || ''} style={{ visibility: config?.button?.isVisible ? 'visible' : 'hidden' }}>
            <WppButton
              className={styles.projectButton}
              style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
            >
              {config?.button?.title}
            </WppButton>
          </Link>
        </div>
      </div>
    </div>
  )
}
