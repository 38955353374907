import deploySchemeImgUrl from 'pages/customProcess/components/tigerBrands/assets/phaseDetails/deploy.png'
import { PageLayout } from 'pages/customProcess/components/tigerBrands/processPhases/pageLayout/PageLayout'
import { processPhasesData } from 'pages/customProcess/components/tigerBrands/TigerBrandsConfig'

const Deploy = () => {
  const { title, description, color, previousLink, nextLink } = processPhasesData[3]

  return (
    <PageLayout
      title={title}
      description={description}
      color={color}
      schemeSrc={deploySchemeImgUrl}
      previousLink={previousLink}
      nextLink={nextLink}
    />
  )
}

export default Deploy
