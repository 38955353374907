import { Routes, Route } from 'react-router-dom'

import Activate from 'pages/customProcess/components/henkel/processPhases/pages/activate/Activate'
import Adapt from 'pages/customProcess/components/henkel/processPhases/pages/adapt/Adapt'
import Ambition from 'pages/customProcess/components/henkel/processPhases/pages/ambition/Ambition'
import Approach from 'pages/customProcess/components/henkel/processPhases/pages/approach/Approach'
import Assets from 'pages/customProcess/components/henkel/processPhases/pages/assets/Assets'
import Audience from 'pages/customProcess/components/henkel/processPhases/pages/audience/Audience'

export const HenkelRoutes = () => (
  <Routes>
    <Route path="adapt" element={<Adapt />} />
    <Route path="ambition" element={<Ambition />} />
    <Route path="audience" element={<Audience />} />
    <Route path="approach" element={<Approach />} />
    <Route path="assets" element={<Assets />} />
    <Route path="activate" element={<Activate />} />
  </Routes>
)
