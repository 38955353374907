import { Route, Routes } from 'react-router-dom'

import Activate from 'pages/customProcess/components/audible/processPhases/pages/activate/Activate'
import Learn from 'pages/customProcess/components/audible/processPhases/pages/learn/Learn'
import Plan from 'pages/customProcess/components/audible/processPhases/pages/plan/Plan'
import Provoke from 'pages/customProcess/components/audible/processPhases/pages/provoke/Provoke'
import Strategize from 'pages/customProcess/components/audible/processPhases/pages/strategize/Strategize'

export const AudibleRoutes = () => {
  return (
    <Routes>
      <Route path="provoke" element={<Provoke />} />
      <Route path="strategize" element={<Strategize />} />
      <Route path="plan" element={<Plan />} />
      <Route path="activate" element={<Activate />} />
      <Route path="learn" element={<Learn />} />
    </Routes>
  )
}
