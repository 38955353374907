import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { get } from 'lodash'
import { useRef, forwardRef, Ref, useCallback, useMemo } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { Select, SelectProps, SelectOption } from 'components/common/select/Select'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'

export type FormSelectOption = SelectOption

export interface FormSelectProps<T extends Record<string, any>> extends Omit<SelectProps<T>, 'onChange' | 'value'> {
  name: string
  'data-testid'?: string
  selectKey?: string
  inputKey?: string
  normalizeInputValue?: (inputValue: string) => unknown
}

export const FormSelect = forwardRef(function FormSelect<T extends Record<string, any>>(
  {
    name,
    message,
    messageType,
    'data-testid': dataTestId,
    onWppChange,
    dropdownConfig,
    type = 'single',
    inputValue,
    selectKey = 'select',
    inputKey = 'input',
    normalizeInputValue,
    ...rest
  }: FormSelectProps<T>,
  ref: Ref<HTMLWppSelectElement>,
) {
  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })

  const innerRef = useRef<HTMLWppSelectElement>(null)

  useProvideFieldFocus({
    fieldRef,
    setFocus: () => innerRef.current?.setFocus(),
  })

  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText
  const isCombined = useMemo(() => type === 'combined', [type])
  const selectValue = useMemo(() => (isCombined ? value[selectKey] : value), [isCombined, value, selectKey])
  const selectInputValue = useMemo(
    () => (isCombined && !inputValue ? value[inputKey] : inputValue),
    [inputKey, inputValue, isCombined, value],
  )

  const handleChange = useCallback(
    (e: WppSelectCustomEvent<any>) =>
      onChange(
        isCombined
          ? {
              [selectKey]: get(e.detail.value, selectKey, e.detail.value),
              [inputKey]: normalizeInputValue ? normalizeInputValue(e.detail?.inputValue) : e.detail?.inputValue,
            }
          : e.detail.value,
      ),
    [isCombined, onChange, selectKey, inputKey, normalizeInputValue],
  )

  return (
    <Select
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      name={name}
      value={selectValue}
      type={type}
      dropdownConfig={{
        ...dropdownConfig,
        onHidden: instance => {
          dropdownConfig?.onHidden?.(instance)
          onBlur()
        },
      }}
      messageType={shouldShowError ? 'error' : messageType}
      message={shouldShowError ? errorText : message}
      onWppChange={(e: WppSelectCustomEvent<any>) => {
        handleChange(e)
        onWppChange?.(e)
      }}
      data-testid={dataTestId}
      inputValue={selectInputValue}
    />
  )
}) as <T extends Record<string, any>>(props: FormSelectProps<T>) => JSX.Element

export const FormSelectSkeleton = () => (
  <Flex gap={8} direction="column">
    <WppSkeleton width="30%" height={22} />
    <WppSkeleton height={40} />
  </Flex>
)
