import styles from 'pages/customProcess/components/mattrass/MattrassProcess.module.scss'

export const SvgProcess = ({ activeEl, setActiveEl }: any) => {
  return (
    <svg
      width="770"
      height="770"
      viewBox="0 0 770 770"
      fill="none"
      style={{ zIndex: 99 }}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={() => setActiveEl('')}
    >
      <g filter="url(#filter0_d_32_2741)" className={styles.shadow}>
        <circle cx="385" cy="381" r="345" fill="url(#paint0_radial_32_2741)" shapeRendering="crispEdges" />
      </g>
      <g style={{ mixBlendMode: 'darken' }} opacity="0.75">
        <mask
          id="mask0_32_2741"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="40"
          y="36"
          width="690"
          height="690"
        >
          <circle cx="385" cy="381" r="345" fill="url(#paint1_linear_32_2741)" />
        </mask>
        <g mask="url(#mask0_32_2741)">
          <g style={{ mixBlendMode: 'overlay' }} opacity="0.5">
            <path
              d="M186.171 -183.71L174.368 -163.268L960.628 290.68L972.431 270.237L186.171 -183.71Z"
              fill="url(#paint2_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M174.369 -163.268L162.566 -142.825L948.826 311.122L960.629 290.68L174.369 -163.268Z"
              fill="url(#paint3_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M162.566 -142.825L150.763 -122.382L937.023 331.565L948.826 311.122L162.566 -142.825Z"
              fill="url(#paint4_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M150.763 -122.382L138.96 -101.939L925.22 352.008L937.023 331.565L150.763 -122.382Z"
              fill="url(#paint5_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M138.96 -101.94L127.158 -81.4968L913.418 372.451L925.22 352.008L138.96 -101.94Z"
              fill="url(#paint6_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M127.158 -81.497L115.355 -61.0542L901.615 392.893L913.418 372.451L127.158 -81.497Z"
              fill="url(#paint7_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M115.355 -61.0538L103.552 -40.6111L889.812 413.336L901.615 392.894L115.355 -61.0538Z"
              fill="url(#paint8_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M103.553 -40.6112L91.75 -20.1685L878.01 433.779L889.813 413.336L103.553 -40.6112Z"
              fill="url(#paint9_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M91.7499 -20.1686L79.9473 0.27417L866.207 454.222L878.01 433.779L91.7499 -20.1686Z"
              fill="url(#paint10_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M79.9474 0.274283L68.1448 20.717L854.405 474.665L866.207 454.222L79.9474 0.274283Z"
              fill="url(#paint11_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M68.1449 20.7172L56.3423 41.1599L842.602 495.107L854.405 474.665L68.1449 20.7172Z"
              fill="url(#paint12_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M56.3422 41.16L44.5396 61.6028L830.8 515.55L842.602 495.107L56.3422 41.16Z"
              fill="url(#paint13_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M44.5395 61.6027L32.7368 82.0454L818.997 535.993L830.799 515.55L44.5395 61.6027Z"
              fill="url(#paint14_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M32.7367 82.045L20.9341 102.488L807.194 556.435L818.997 535.993L32.7367 82.045Z"
              fill="url(#paint15_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M20.9342 102.488L9.13159 122.931L795.392 576.878L807.194 556.436L20.9342 102.488Z"
              fill="url(#paint16_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M9.13149 122.931L-2.67114 143.374L783.589 597.321L795.391 576.878L9.13149 122.931Z"
              fill="url(#paint17_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-2.671 143.374L-14.4736 163.816L771.786 617.764L783.589 597.321L-2.671 143.374Z"
              fill="url(#paint18_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-14.4737 163.816L-26.2764 184.259L759.984 638.207L771.786 617.764L-14.4737 163.816Z"
              fill="url(#paint19_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-26.2762 184.259L-38.0789 204.702L748.181 658.649L759.984 638.207L-26.2762 184.259Z"
              fill="url(#paint20_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-38.079 204.702L-49.8816 225.145L736.378 679.092L748.181 658.649L-38.079 204.702Z"
              fill="url(#paint21_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-49.8814 225.145L-61.6841 245.587L724.576 699.535L736.379 679.092L-49.8814 225.145Z"
              fill="url(#paint22_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-61.6842 245.588L-73.4868 266.03L712.773 719.978L724.576 699.535L-61.6842 245.588Z"
              fill="url(#paint23_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-73.4869 266.03L-85.2896 286.473L700.97 740.42L712.773 719.977L-73.4869 266.03Z"
              fill="url(#paint24_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-85.2897 286.473L-97.0923 306.916L689.168 760.863L700.97 740.42L-85.2897 286.473Z"
              fill="url(#paint25_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-97.0921 306.916L-108.895 327.358L677.365 781.306L689.168 760.863L-97.0921 306.916Z"
              fill="url(#paint26_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-108.895 327.358L-120.698 347.801L665.562 801.748L677.365 781.306L-108.895 327.358Z"
              fill="url(#paint27_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-120.697 347.801L-132.5 368.244L653.76 822.191L665.563 801.749L-120.697 347.801Z"
              fill="url(#paint28_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-132.5 368.244L-144.302 388.687L641.957 842.634L653.76 822.191L-132.5 368.244Z"
              fill="url(#paint29_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-144.303 388.687L-156.105 409.13L630.155 863.077L641.957 842.634L-144.303 388.687Z"
              fill="url(#paint30_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-156.105 409.13L-167.908 429.572L618.352 883.52L630.155 863.077L-156.105 409.13Z"
              fill="url(#paint31_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-167.908 429.572L-179.71 450.015L606.55 903.962L618.352 883.519L-167.908 429.572Z"
              fill="url(#paint32_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-179.711 450.015L-191.513 470.458L594.747 924.405L606.549 903.962L-179.711 450.015Z"
              fill="url(#paint33_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-191.513 470.458L-203.316 490.9L582.944 944.848L594.747 924.405L-191.513 470.458Z"
              fill="url(#paint34_linear_32_2741)"
              stroke="#F4F5F7"
            />
          </g>
          <g style={{ mixBlendMode: 'overlay' }} opacity="0.5">
            <path
              d="M-203.316 270.237L-191.513 290.679L594.747 -163.268L582.944 -183.711L-203.316 270.237Z"
              fill="url(#paint35_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-191.513 290.679L-179.71 311.122L606.549 -142.825L594.747 -163.268L-191.513 290.679Z"
              fill="url(#paint36_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-179.71 311.122L-167.908 331.565L618.352 -122.382L606.55 -142.825L-179.71 311.122Z"
              fill="url(#paint37_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-167.908 331.565L-156.105 352.008L630.155 -101.94L618.352 -122.382L-167.908 331.565Z"
              fill="url(#paint38_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-156.105 352.008L-144.302 372.451L641.957 -81.4966L630.155 -101.939L-156.105 352.008Z"
              fill="url(#paint39_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-144.303 372.451L-132.5 392.893L653.76 -61.054L641.957 -81.4968L-144.303 372.451Z"
              fill="url(#paint40_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-132.5 392.893L-120.698 413.336L665.562 -40.6111L653.76 -61.0539L-132.5 392.893Z"
              fill="url(#paint41_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-120.697 413.336L-108.895 433.779L677.365 -20.1683L665.562 -40.611L-120.697 413.336Z"
              fill="url(#paint42_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-108.895 433.779L-97.0923 454.222L689.168 0.27435L677.365 -20.1684L-108.895 433.779Z"
              fill="url(#paint43_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-97.0922 454.222L-85.2896 474.664L700.97 20.717L689.168 0.27422L-97.0922 454.222Z"
              fill="url(#paint44_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-85.2894 474.664L-73.4868 495.107L712.773 41.1598L700.97 20.7171L-85.2894 474.664Z"
              fill="url(#paint45_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-73.4867 495.107L-61.6841 515.55L724.576 61.6025L712.773 41.1597L-73.4867 495.107Z"
              fill="url(#paint46_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-61.6842 515.55L-49.8816 535.993L736.378 82.0453L724.576 61.6026L-61.6842 515.55Z"
              fill="url(#paint47_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-49.8815 535.993L-38.0789 556.435L748.181 102.488L736.378 82.0452L-49.8815 535.993Z"
              fill="url(#paint48_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-38.079 556.436L-26.2764 576.878L759.984 122.931L748.181 102.488L-38.079 556.436Z"
              fill="url(#paint49_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-26.2763 576.878L-14.4736 597.321L771.786 143.374L759.984 122.931L-26.2763 576.878Z"
              fill="url(#paint50_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-14.4738 597.321L-2.67114 617.764L783.589 163.816L771.786 143.374L-14.4738 597.321Z"
              fill="url(#paint51_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M-2.67104 617.764L9.13159 638.207L795.391 184.259L783.589 163.816L-2.67104 617.764Z"
              fill="url(#paint52_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M9.13145 638.206L20.9341 658.649L807.194 204.702L795.391 184.259L9.13145 638.206Z"
              fill="url(#paint53_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M20.9342 658.649L32.7368 679.092L818.997 225.145L807.194 204.702L20.9342 658.649Z"
              fill="url(#paint54_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M32.7369 679.092L44.5396 699.535L830.799 245.587L818.997 225.145L32.7369 679.092Z"
              fill="url(#paint55_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M44.5397 699.535L56.3423 719.978L842.602 266.03L830.8 245.587L44.5397 699.535Z"
              fill="url(#paint56_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M56.3421 719.977L68.1448 740.42L854.405 286.473L842.602 266.03L56.3421 719.977Z"
              fill="url(#paint57_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M68.1449 740.42L79.9475 760.863L866.207 306.916L854.405 286.473L68.1449 740.42Z"
              fill="url(#paint58_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M79.9474 760.863L91.75 781.306L878.01 327.359L866.207 306.916L79.9474 760.863Z"
              fill="url(#paint59_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M91.7499 781.306L103.552 801.749L889.812 347.801L878.01 327.358L91.7499 781.306Z"
              fill="url(#paint60_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M103.553 801.748L115.355 822.191L901.615 368.244L889.812 347.801L103.553 801.748Z"
              fill="url(#paint61_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M115.355 822.191L127.158 842.634L913.418 388.687L901.615 368.244L115.355 822.191Z"
              fill="url(#paint62_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M127.158 842.634L138.96 863.077L925.22 409.129L913.418 388.687L127.158 842.634Z"
              fill="url(#paint63_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M138.961 863.077L150.763 883.52L937.023 429.572L925.22 409.129L138.961 863.077Z"
              fill="url(#paint64_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M150.763 883.519L162.566 903.962L948.826 450.015L937.023 429.572L150.763 883.519Z"
              fill="url(#paint65_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M162.566 903.963L174.368 924.405L960.628 470.458L948.826 450.015L162.566 903.963Z"
              fill="url(#paint66_linear_32_2741)"
              stroke="#F4F5F7"
            />
            <path
              d="M174.369 924.405L186.171 944.848L972.431 490.901L960.628 470.458L174.369 924.405Z"
              fill="url(#paint67_linear_32_2741)"
              stroke="#F4F5F7"
            />
          </g>
        </g>
      </g>
      <circle cx="385" cy="381" r="331.382" fill="url(#paint68_linear_32_2741)" fillOpacity="0.4" />
      <g style={{ mixBlendMode: 'multiply' }}>
        <circle cx="385" cy="381" r="330" stroke="#C2C6CC" strokeLinecap="round" strokeDasharray="10 10" />
      </g>
      <path
        d="M506.6 221.63L508.57 219.25L521.08 229.62L519.11 232L514.26 227.98L503.24 241.28L500.42 238.94L511.44 225.64L506.59 221.62L506.6 221.63Z"
        fill="#131618"
      />
      <path
        d="M510.76 247.96L521.48 237.08L523.97 239.53L522.18 241.34L522.29 241.45C523.11 241.02 523.93 240.87 524.75 241C525.57 241.13 526.29 241.51 526.92 242.12C527.06 242.26 527.21 242.42 527.38 242.61C527.55 242.8 527.67 242.96 527.77 243.1L525.45 245.46C525.38 245.31 525.24 245.11 525.04 244.85C524.84 244.59 524.63 244.35 524.41 244.14C523.94 243.68 523.42 243.37 522.86 243.2C522.29 243.04 521.73 243.02 521.17 243.17C520.61 243.31 520.1 243.62 519.64 244.08L513.34 250.48L510.78 247.95L510.76 247.96Z"
        fill="#131618"
      />
      <path
        d="M522.69 260.88C522.06 260.14 521.63 259.37 521.39 258.55C521.15 257.74 521.15 256.94 521.38 256.16C521.61 255.38 522.1 254.66 522.86 254.01C523.52 253.45 524.16 253.11 524.8 252.99C525.43 252.87 526.06 252.9 526.67 253.1C527.28 253.3 527.88 253.6 528.47 254.02C529.05 254.43 529.62 254.9 530.16 255.41C530.81 256.03 531.35 256.53 531.77 256.9C532.19 257.27 532.54 257.51 532.83 257.59C533.12 257.68 533.4 257.61 533.66 257.39L533.7 257.35C534.27 256.86 534.57 256.32 534.59 255.71C534.61 255.1 534.36 254.49 533.81 253.85C533.24 253.18 532.64 252.77 532.01 252.63C531.38 252.49 530.81 252.52 530.3 252.72L528.48 249.85C529.36 249.45 530.23 249.28 531.11 249.33C531.98 249.39 532.82 249.65 533.64 250.12C534.45 250.59 535.2 251.23 535.9 252.04C536.38 252.6 536.79 253.21 537.13 253.88C537.47 254.55 537.69 255.24 537.78 255.95C537.87 256.66 537.78 257.38 537.5 258.1C537.22 258.82 536.7 259.51 535.93 260.17L528.16 266.8L525.91 264.17L527.51 262.81L527.43 262.72C526.97 262.83 526.47 262.85 525.93 262.79C525.39 262.73 524.85 262.55 524.3 262.24C523.75 261.93 523.21 261.48 522.69 260.87V260.88ZM525.31 259.87C525.78 260.42 526.3 260.8 526.86 261.02C527.42 261.24 527.98 261.3 528.53 261.21C529.08 261.12 529.57 260.89 530 260.53L531.37 259.36C531.23 259.34 531.04 259.25 530.8 259.09C530.57 258.94 530.32 258.75 530.06 258.54C529.8 258.33 529.55 258.11 529.3 257.89C529.05 257.67 528.84 257.49 528.66 257.33C528.26 256.97 527.85 256.68 527.45 256.47C527.05 256.25 526.66 256.15 526.27 256.15C525.89 256.15 525.52 256.31 525.17 256.61C524.67 257.04 524.44 257.55 524.5 258.14C524.56 258.73 524.83 259.31 525.31 259.88V259.87Z"
        fill="#131618"
      />
      <path
        d="M541.22 269.42L533.85 274.5L531.81 271.53L544.39 262.87L546.34 265.7L544.2 267.17L544.3 267.32C545.2 267.12 546.08 267.2 546.93 267.55C547.78 267.9 548.53 268.55 549.18 269.48C549.78 270.35 550.11 271.23 550.19 272.14C550.26 273.04 550.07 273.91 549.61 274.76C549.15 275.6 548.42 276.37 547.41 277.05L539.4 282.57L537.36 279.61L544.91 274.41C545.75 273.83 546.26 273.16 546.44 272.4C546.62 271.64 546.44 270.88 545.92 270.11C545.56 269.59 545.13 269.21 544.63 268.96C544.13 268.71 543.59 268.63 543 268.69C542.42 268.76 541.82 269 541.22 269.42Z"
        fill="#131618"
      />
      <path
        d="M558.19 292.76L556.27 290.08C556.51 289.84 556.71 289.54 556.85 289.2C556.99 288.86 557.05 288.47 557.01 288.05C556.98 287.63 556.82 287.17 556.55 286.69C556.18 286.04 555.73 285.57 555.2 285.29C554.67 285.01 554.18 284.99 553.73 285.26C553.34 285.47 553.1 285.79 553.03 286.22C552.95 286.65 553.03 287.24 553.25 288.01L554.04 290.55C554.48 291.96 554.58 293.14 554.33 294.09C554.09 295.04 553.49 295.78 552.54 296.32C551.7 296.79 550.82 296.96 549.91 296.83C549 296.71 548.13 296.32 547.29 295.66C546.45 295.01 545.72 294.13 545.09 293.02C544.17 291.39 543.78 289.89 543.9 288.51C544.03 287.13 544.65 286 545.76 285.12L547.78 288.01C547.26 288.49 546.97 289.04 546.92 289.66C546.87 290.28 547.04 290.95 547.45 291.68C547.87 292.42 548.36 292.94 548.92 293.22C549.48 293.5 549.99 293.51 550.44 293.26C550.82 293.05 551.05 292.72 551.14 292.29C551.23 291.86 551.16 291.3 550.95 290.61L550.15 288.07C549.7 286.64 549.61 285.45 549.9 284.48C550.19 283.51 550.82 282.76 551.79 282.22C552.61 281.75 553.45 281.58 554.3 281.69C555.16 281.8 555.98 282.17 556.77 282.8C557.56 283.42 558.25 284.27 558.85 285.33C559.73 286.89 560.09 288.31 559.93 289.58C559.77 290.85 559.19 291.91 558.17 292.77L558.19 292.76Z"
        fill="#131618"
      />
      <path
        d="M551.16 304.29L566.42 297.47C567.36 297.05 568.22 296.9 569.02 297.01C569.82 297.12 570.52 297.44 571.12 297.96C571.72 298.48 572.2 299.14 572.56 299.95C572.81 300.52 572.99 301.04 573.1 301.53C573.21 302.01 573.27 302.37 573.29 302.62L570.46 303.1C570.44 302.94 570.41 302.75 570.36 302.52C570.31 302.29 570.23 302.04 570.11 301.78C569.84 301.17 569.5 300.8 569.09 300.68C568.68 300.56 568.21 300.62 567.66 300.87L552.62 307.59L551.15 304.29H551.16ZM567.87 304.24L565.33 305.37L561.65 297.13L564.19 295.99L567.87 304.23V304.24Z"
        fill="#131618"
      />
      <path
        d="M557.63 321.14C557.14 319.73 557.02 318.4 557.28 317.15C557.54 315.9 558.13 314.8 559.05 313.85C559.97 312.89 561.17 312.16 562.66 311.64C564.15 311.12 565.55 310.95 566.87 311.13C568.19 311.31 569.34 311.8 570.32 312.62C571.3 313.44 572.03 314.55 572.53 315.96C573.02 317.37 573.14 318.7 572.87 319.95C572.61 321.2 572.02 322.3 571.09 323.26C570.16 324.22 568.96 324.95 567.47 325.47C565.98 325.99 564.58 326.16 563.27 325.98C561.96 325.8 560.81 325.3 559.83 324.49C558.85 323.67 558.12 322.56 557.63 321.15V321.14ZM560.36 320.21C560.63 320.97 561.06 321.54 561.66 321.9C562.26 322.27 562.96 322.45 563.76 322.46C564.56 322.46 565.4 322.31 566.28 322.01C567.16 321.7 567.92 321.3 568.55 320.79C569.18 320.29 569.62 319.7 569.86 319.04C570.11 318.38 570.1 317.67 569.83 316.9C569.56 316.12 569.12 315.54 568.51 315.17C567.9 314.8 567.2 314.62 566.39 314.61C565.59 314.61 564.74 314.76 563.86 315.06C562.98 315.37 562.23 315.77 561.61 316.27C560.99 316.77 560.55 317.36 560.31 318.02C560.07 318.68 560.08 319.41 560.35 320.19L560.36 320.21Z"
        fill="#131618"
      />
      <path
        d="M561.71 333.21L576.53 329.51L577.37 332.9L574.9 333.52L574.94 333.67C575.86 333.73 576.64 334.03 577.27 334.57C577.9 335.11 578.32 335.81 578.53 336.66C578.58 336.85 578.62 337.07 578.67 337.32C578.71 337.57 578.74 337.77 578.75 337.94L575.54 338.74C575.55 338.58 575.54 338.34 575.5 338.01C575.46 337.68 575.41 337.37 575.33 337.07C575.17 336.43 574.89 335.9 574.49 335.46C574.09 335.02 573.61 334.72 573.06 334.55C572.51 334.38 571.91 334.37 571.28 334.53L562.57 336.7L561.7 333.21H561.71Z"
        fill="#131618"
      />
      <path
        d="M565.01 346.18L580.15 344.16L580.6 347.57L578.03 347.91L578.05 348.09C578.96 348.29 579.71 348.72 580.29 349.39C580.88 350.05 581.24 350.89 581.37 351.91C581.51 352.93 581.37 353.83 580.97 354.61C580.57 355.39 579.96 355.97 579.14 356.37L579.16 356.53C580.05 356.75 580.79 357.23 581.4 357.96C582.01 358.69 582.39 359.61 582.53 360.7C582.72 362.09 582.43 363.29 581.66 364.29C580.9 365.29 579.67 365.9 577.98 366.12L567.82 367.47L567.34 363.89L576.95 362.61C577.89 362.49 578.54 362.14 578.91 361.59C579.28 361.03 579.42 360.39 579.32 359.67C579.2 358.81 578.85 358.17 578.25 357.76C577.65 357.35 576.92 357.2 576.07 357.31L566.63 358.57L566.16 355.07L575.92 353.77C576.7 353.67 577.3 353.35 577.7 352.82C578.1 352.29 578.26 351.64 578.16 350.89C578.09 350.38 577.9 349.93 577.58 349.54C577.27 349.15 576.86 348.87 576.35 348.68C575.85 348.49 575.28 348.44 574.66 348.52L565.45 349.75L564.97 346.18H565.01Z"
        fill="#131618"
      />
      <path
        d="M191.24 319.02L192.29 315.48L205.05 319.26C206.45 319.67 207.58 320.37 208.45 321.34C209.32 322.31 209.87 323.48 210.11 324.84C210.35 326.2 210.24 327.68 209.77 329.27C209.3 330.86 208.59 332.17 207.64 333.18C206.69 334.19 205.59 334.87 204.34 335.21C203.09 335.55 201.76 335.51 200.36 335.1L187.6 331.32L188.65 327.78L201.11 331.47C201.92 331.71 202.7 331.75 203.44 331.57C204.18 331.4 204.83 331.04 205.38 330.49C205.93 329.94 206.34 329.22 206.61 328.32C206.88 327.42 206.93 326.59 206.76 325.83C206.6 325.07 206.25 324.41 205.72 323.87C205.19 323.33 204.52 322.93 203.7 322.69L191.24 319V319.02Z"
        fill="#131618"
      />
      <path
        d="M207.16 308.7L215.39 312.21L213.98 315.52L199.93 309.52L201.28 306.36L203.67 307.38L203.74 307.22C203.09 306.56 202.69 305.78 202.53 304.87C202.37 303.96 202.51 302.98 202.96 301.93C203.37 300.96 203.94 300.2 204.67 299.66C205.39 299.11 206.23 298.81 207.19 298.75C208.15 298.69 209.18 298.9 210.3 299.38L219.24 303.2L217.83 306.51L209.4 302.91C208.46 302.51 207.62 302.44 206.88 302.7C206.14 302.96 205.59 303.51 205.23 304.37C204.98 304.95 204.89 305.52 204.95 306.07C205.01 306.63 205.23 307.13 205.59 307.59C205.96 308.05 206.48 308.42 207.16 308.71V308.7Z"
        fill="#131618"
      />
      <path d="M205.65 285.14L223.69 294.59L222.02 297.78L203.98 288.33L205.65 285.14Z" fill="#131618" />
      <path
        d="M230.38 283.7C229.59 284.96 228.62 285.88 227.48 286.46C226.34 287.04 225.11 287.27 223.8 287.15C222.48 287.03 221.15 286.55 219.82 285.71C218.48 284.87 217.47 283.88 216.79 282.74C216.1 281.6 215.77 280.39 215.8 279.12C215.83 277.84 216.24 276.58 217.03 275.31C217.82 274.05 218.79 273.13 219.93 272.55C221.07 271.97 222.3 271.75 223.62 271.87C224.94 271.99 226.27 272.47 227.61 273.31C228.95 274.15 229.95 275.14 230.63 276.27C231.31 277.41 231.64 278.61 231.61 279.89C231.58 281.17 231.17 282.43 230.38 283.7ZM227.95 282.15C228.38 281.47 228.55 280.77 228.46 280.08C228.37 279.38 228.08 278.72 227.58 278.09C227.08 277.46 226.44 276.9 225.65 276.41C224.86 275.91 224.07 275.58 223.28 275.4C222.5 275.22 221.77 275.25 221.1 275.47C220.43 275.69 219.88 276.14 219.45 276.83C219.01 277.53 218.83 278.23 218.92 278.94C219.01 279.64 219.31 280.31 219.81 280.94C220.31 281.57 220.96 282.14 221.75 282.63C222.53 283.12 223.32 283.46 224.1 283.63C224.88 283.8 225.61 283.78 226.28 283.55C226.95 283.32 227.51 282.86 227.95 282.16V282.15Z"
        fill="#131618"
      />
      <path
        d="M241.49 267.92C240.55 269.12 239.48 269.94 238.28 270.39C237.08 270.84 235.83 270.93 234.54 270.66C233.25 270.39 231.99 269.78 230.78 268.83C229.56 267.87 228.66 266.79 228.09 265.59C227.52 264.39 227.31 263.15 227.46 261.88C227.61 260.61 228.14 259.39 229.06 258.21C229.82 257.23 230.68 256.51 231.63 256.03C232.58 255.55 233.55 255.35 234.55 255.41C235.55 255.47 236.51 255.81 237.43 256.42L235.31 259.13C234.64 258.79 233.96 258.67 233.25 258.77C232.54 258.88 231.92 259.27 231.38 259.96C230.93 260.54 230.68 261.17 230.65 261.85C230.62 262.53 230.8 263.22 231.19 263.92C231.58 264.62 232.18 265.28 232.99 265.91C233.81 266.55 234.61 266.98 235.38 267.2C236.16 267.42 236.87 267.43 237.53 267.24C238.19 267.05 238.75 266.66 239.21 266.07C239.54 265.65 239.75 265.22 239.85 264.76C239.95 264.3 239.94 263.84 239.81 263.38C239.68 262.92 239.44 262.48 239.07 262.05L241.19 259.34C241.99 260.07 242.55 260.91 242.86 261.86C243.18 262.81 243.23 263.8 243.01 264.83C242.79 265.86 242.29 266.89 241.5 267.89L241.49 267.92Z"
        fill="#131618"
      />
      <path
        d="M248.76 258.64L234.06 244.55L236.55 241.95L251.25 256.04L248.76 258.64ZM247.57 252.95L244.43 249.95L244.83 249.53L244.2 241.32L247.11 238.28L247.86 248.36L247.34 248.9L247.57 252.95ZM255.39 251.72L246.93 250.5L246.78 246.99L258.37 248.61L255.39 251.72Z"
        fill="#131618"
      />
      <path
        d="M318.36 554.46L322.66 555.84L323.89 571.74L324.12 571.81L334.4 559.62L338.7 561L332.46 580.38L329.09 579.29L333.38 565.97L333.2 565.91L323.57 577.44L321.05 576.63L319.97 561.62L319.79 561.56L315.49 574.9L312.12 573.81L318.36 554.43V554.46Z"
        fill="#131618"
      />
      <path
        d="M344.68 583.28C343.73 583.1 342.91 582.76 342.21 582.28C341.52 581.79 341.02 581.17 340.7 580.42C340.39 579.67 340.33 578.8 340.52 577.81C340.68 576.96 340.97 576.29 341.39 575.8C341.81 575.31 342.32 574.95 342.92 574.72C343.52 574.49 344.18 574.36 344.9 574.31C345.61 574.27 346.34 574.28 347.09 574.34C347.99 574.42 348.71 574.47 349.28 574.5C349.84 574.53 350.26 574.49 350.54 574.38C350.82 574.27 350.99 574.04 351.06 573.7V573.64C351.21 572.9 351.1 572.29 350.74 571.81C350.38 571.33 349.79 571 348.97 570.84C348.1 570.67 347.38 570.73 346.81 571.01C346.23 571.29 345.81 571.67 345.53 572.15L342.32 571.05C342.76 570.19 343.33 569.51 344.05 569.01C344.77 568.51 345.59 568.19 346.51 568.04C347.43 567.9 348.42 567.93 349.47 568.13C350.19 568.27 350.9 568.49 351.58 568.8C352.26 569.11 352.86 569.51 353.38 570.01C353.9 570.51 354.27 571.13 354.51 571.86C354.74 572.59 354.77 573.46 354.57 574.45L352.65 584.49L349.25 583.84L349.64 581.78L349.52 581.76C349.23 582.14 348.85 582.47 348.39 582.75C347.93 583.03 347.39 583.23 346.77 583.34C346.15 583.45 345.44 583.42 344.66 583.28H344.68ZM346.1 580.86C346.81 581 347.45 580.97 348.03 580.79C348.61 580.61 349.08 580.31 349.45 579.89C349.82 579.47 350.06 578.99 350.17 578.44L350.51 576.67C350.38 576.74 350.18 576.79 349.9 576.82C349.62 576.85 349.31 576.86 348.98 576.86C348.64 576.86 348.31 576.85 347.98 576.83C347.65 576.81 347.37 576.8 347.13 576.79C346.59 576.76 346.09 576.79 345.65 576.87C345.2 576.95 344.83 577.12 344.53 577.36C344.23 577.6 344.04 577.95 343.95 578.41C343.83 579.06 343.97 579.6 344.38 580.02C344.79 580.44 345.37 580.73 346.1 580.87V580.86Z"
        fill="#131618"
      />
      <path
        d="M364.53 570.61L367.08 576.51L370.72 571.19L374.51 571.54L369.22 578.72L373.18 586.76L369.41 586.41L366.7 580.64L363 585.82L359.2 585.47L364.54 578.3L360.73 570.28L364.53 570.63V570.61Z"
        fill="#131618"
      />
      <path
        d="M379.56 586.92L379.86 571.65L383.46 571.72L383.16 586.99L379.56 586.92ZM381.7 569.52C381.13 569.51 380.65 569.31 380.24 568.91C379.84 568.52 379.65 568.05 379.66 567.52C379.67 566.98 379.89 566.53 380.3 566.16C380.71 565.79 381.21 565.61 381.78 565.62C382.35 565.63 382.84 565.83 383.24 566.23C383.64 566.62 383.83 567.09 383.82 567.62C383.81 568.16 383.6 568.61 383.18 568.98C382.76 569.35 382.27 569.53 381.69 569.52H381.7Z"
        fill="#131618"
      />
      <path
        d="M390.8 587.21L389.63 571.98L393.06 571.72L393.26 574.31H393.44C393.69 573.4 394.16 572.68 394.86 572.13C395.56 571.58 396.41 571.27 397.43 571.19C398.46 571.11 399.35 571.29 400.1 571.74C400.85 572.19 401.4 572.82 401.76 573.66H401.92C402.19 572.78 402.71 572.06 403.47 571.49C404.23 570.92 405.17 570.6 406.27 570.51C407.67 570.4 408.85 570.76 409.8 571.57C410.75 572.39 411.3 573.65 411.43 575.34L412.21 585.56L408.61 585.84L407.87 576.17C407.8 575.23 407.49 574.55 406.96 574.15C406.43 573.75 405.79 573.58 405.07 573.63C404.2 573.7 403.55 574.02 403.11 574.59C402.67 575.17 402.48 575.88 402.54 576.74L403.27 586.24L399.75 586.51L399 576.7C398.94 575.91 398.65 575.3 398.15 574.87C397.64 574.44 397.01 574.25 396.25 574.31C395.73 574.35 395.28 574.52 394.87 574.81C394.47 575.1 394.16 575.5 393.94 575.99C393.72 576.48 393.64 577.04 393.69 577.67L394.4 586.93L390.81 587.21H390.8Z"
        fill="#131618"
      />
      <path
        d="M418.5 566.69C417.94 566.79 417.42 566.69 416.96 566.38C416.49 566.07 416.21 565.65 416.12 565.13C416.03 564.6 416.15 564.11 416.49 563.67C416.83 563.23 417.28 562.95 417.84 562.85C418.4 562.75 418.92 562.85 419.38 563.16C419.85 563.47 420.13 563.89 420.22 564.41C420.31 564.94 420.19 565.43 419.86 565.87C419.53 566.31 419.07 566.59 418.51 566.69H418.5ZM419.76 584.18L417.11 569.14L420.65 568.51L423.3 583.55L419.76 584.18Z"
        fill="#131618"
      />
      <path
        d="M430.38 582.19L429.82 579.97L435.02 568.46L434.99 568.33L427.61 570.21L426.87 567.32L438.52 564.36L439.12 566.74L434.24 577.99L434.27 578.12L441.79 576.21L442.53 579.1L430.38 582.19Z"
        fill="#131618"
      />
      <path
        d="M455.62 575.08C454.18 575.6 452.83 575.74 451.57 575.52C450.31 575.29 449.2 574.73 448.24 573.82C447.28 572.91 446.53 571.71 445.99 570.21C445.46 568.73 445.27 567.33 445.44 566.01C445.6 564.69 446.08 563.53 446.87 562.54C447.66 561.55 448.74 560.8 450.12 560.31C451.01 559.99 451.89 559.83 452.79 559.83C453.69 559.83 454.54 560.02 455.36 560.39C456.18 560.76 456.93 561.35 457.61 562.13C458.29 562.92 458.85 563.94 459.31 565.2L459.68 566.24L447.9 570.48L447.08 568.2L455.61 565.13C455.37 564.48 455.02 563.96 454.56 563.55C454.1 563.14 453.58 562.88 452.98 562.76C452.39 562.64 451.76 562.7 451.11 562.93C450.42 563.18 449.87 563.56 449.47 564.09C449.07 564.61 448.82 565.2 448.72 565.84C448.62 566.48 448.69 567.13 448.91 567.77L449.63 569.76C449.93 570.6 450.34 571.26 450.86 571.74C451.38 572.23 451.97 572.53 452.63 572.65C453.29 572.77 453.99 572.69 454.73 572.43C455.22 572.25 455.64 572.02 455.99 571.74C456.34 571.46 456.61 571.12 456.81 570.74C457 570.36 457.1 569.94 457.11 569.48L460.4 568.7C460.5 569.61 460.38 570.47 460.05 571.29C459.72 572.11 459.17 572.85 458.43 573.5C457.68 574.15 456.75 574.68 455.64 575.08H455.62Z"
        fill="#131618"
      />
      {activeEl !== 'transform' && (
        <path
          d="M397.037 93.1616C397.243 88.245 401.397 84.4121 406.305 84.7651C453.157 88.1345 498.588 102.574 538.841 126.949C581.636 152.864 617.192 189.169 642.208 232.497C667.223 275.824 680.889 324.769 681.935 374.788C682.919 421.835 672.71 468.4 652.203 510.66C650.055 515.087 644.658 516.768 640.297 514.488C635.937 512.208 634.264 506.83 636.404 502.399C655.524 462.802 665.04 419.207 664.119 375.161C663.135 328.143 650.29 282.135 626.775 241.407C603.26 200.679 569.838 166.552 529.61 142.192C491.925 119.372 449.412 105.816 405.56 102.578C400.652 102.216 396.832 98.0782 397.037 93.1616Z"
          fill="url(#paint69_linear_32_2741)"
        />
      )}
      {activeEl !== 'maximize' && (
        <path
          d="M628.289 535.293C632.445 537.928 633.688 543.442 630.929 547.517C604.249 586.922 568.461 619.378 526.549 642.099C482.023 666.238 432.064 678.589 381.42 677.978C330.776 677.368 281.129 663.816 237.198 638.611C195.846 614.886 160.851 581.577 135.128 541.54C132.468 537.4 133.844 531.918 138.062 529.383C142.28 526.849 147.742 528.222 150.41 532.357C174.562 569.792 207.35 600.941 246.066 623.155C287.361 646.847 334.029 659.586 381.635 660.16C429.24 660.734 476.202 649.124 518.056 626.433C557.297 605.16 590.826 574.809 615.873 537.968C618.64 533.898 624.133 532.657 628.289 535.293Z"
          fill="url(#paint70_linear_32_2741)"
        />
      )}
      {activeEl !== 'unlock' && (
        <path
          d="M129.705 514.492C125.344 516.772 119.948 515.091 117.799 510.664C97.2901 468.401 87.0799 421.833 88.0651 374.782C89.1126 324.759 102.781 275.811 127.801 232.482C152.821 189.153 188.381 152.848 231.183 126.935C271.441 102.561 316.876 88.1261 363.732 84.7624C368.641 84.4101 372.794 88.2435 372.999 93.1601C373.204 98.0767 369.383 102.214 364.475 102.575C320.619 105.808 278.101 119.36 240.412 142.179C200.179 166.537 166.751 200.664 143.233 241.393C119.714 282.123 106.866 328.134 105.881 375.155C104.959 419.205 114.475 462.804 133.598 502.403C135.738 506.834 134.065 512.212 129.705 514.492Z"
          fill="url(#paint71_linear_32_2741)"
        />
      )}
      <foreignObject onMouseEnter={() => setActiveEl('unlock')} height={485} width={325} x="55" y="45">
        <div className={activeEl === 'unlock' ? styles.unlock : undefined} />
      </foreignObject>
      <foreignObject onMouseEnter={() => setActiveEl('transform')} height={485} width={325} x="390" y="45">
        <div className={activeEl === 'transform' ? styles.transform : undefined} />
      </foreignObject>
      <foreignObject onMouseEnter={() => setActiveEl('maximize')} height={280} width={570} x="100" y="451">
        <div className={activeEl === 'maximize' ? styles.maximize : undefined} />
      </foreignObject>
      <foreignObject onMouseEnter={() => setActiveEl('center')} x="270" y="270" width={247} height={247}>
        <div className={activeEl === 'center' ? styles.centerActive : styles.center} />
      </foreignObject>
      <defs>
        <filter
          id="filter0_d_32_2741"
          x="0"
          y="0"
          width="770"
          height="770"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="20" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.106094 0 0 0 0 0.143353 0 0 0 0 0.404167 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_2741" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_2741" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial_32_2741"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(385 381) rotate(90) scale(345)"
        >
          <stop stopColor="white" />
          <stop offset="0.953125" stopColor="white" />
          <stop offset="1" stopColor="#C2C6CC" stopOpacity="0.85" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_32_2741"
          x1="41.6916"
          y1="387.124"
          x2="731.88"
          y2="387.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_32_2741"
          x1="574.506"
          y1="67.6851"
          x2="586.312"
          y2="47.2367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_32_2741"
          x1="562.703"
          y1="88.1275"
          x2="574.509"
          y2="67.6791"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_32_2741"
          x1="550.901"
          y1="108.57"
          x2="562.706"
          y2="88.122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_32_2741"
          x1="539.098"
          y1="129.013"
          x2="550.904"
          y2="108.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_32_2741"
          x1="527.295"
          y1="149.456"
          x2="539.101"
          y2="129.007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_32_2741"
          x1="515.493"
          y1="169.898"
          x2="527.298"
          y2="149.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_32_2741"
          x1="503.69"
          y1="190.342"
          x2="515.496"
          y2="169.893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_32_2741"
          x1="491.887"
          y1="210.784"
          x2="503.693"
          y2="190.336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_32_2741"
          x1="480.085"
          y1="231.227"
          x2="491.891"
          y2="210.778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_32_2741"
          x1="468.282"
          y1="251.67"
          x2="480.088"
          y2="231.221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_32_2741"
          x1="456.48"
          y1="272.113"
          x2="468.286"
          y2="251.664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_32_2741"
          x1="444.677"
          y1="292.555"
          x2="456.483"
          y2="272.107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_32_2741"
          x1="432.874"
          y1="312.998"
          x2="444.68"
          y2="292.55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_32_2741"
          x1="421.071"
          y1="333.44"
          x2="432.877"
          y2="312.992"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_32_2741"
          x1="409.269"
          y1="353.884"
          x2="421.075"
          y2="333.435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_32_2741"
          x1="397.466"
          y1="374.326"
          x2="409.272"
          y2="353.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_32_2741"
          x1="385.664"
          y1="394.769"
          x2="397.47"
          y2="374.321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_32_2741"
          x1="373.861"
          y1="415.212"
          x2="385.667"
          y2="394.763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_32_2741"
          x1="362.059"
          y1="435.655"
          x2="373.864"
          y2="415.206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_32_2741"
          x1="350.256"
          y1="456.097"
          x2="362.062"
          y2="435.649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_32_2741"
          x1="338.453"
          y1="476.54"
          x2="350.259"
          y2="456.092"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_32_2741"
          x1="326.651"
          y1="496.983"
          x2="338.456"
          y2="476.535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_32_2741"
          x1="314.848"
          y1="517.425"
          x2="326.654"
          y2="496.977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_32_2741"
          x1="303.045"
          y1="537.868"
          x2="314.851"
          y2="517.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_32_2741"
          x1="291.243"
          y1="558.311"
          x2="303.048"
          y2="537.863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_32_2741"
          x1="279.44"
          y1="578.754"
          x2="291.246"
          y2="558.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_32_2741"
          x1="267.637"
          y1="599.197"
          x2="279.443"
          y2="578.748"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_32_2741"
          x1="255.835"
          y1="619.639"
          x2="267.641"
          y2="599.191"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_32_2741"
          x1="244.032"
          y1="640.082"
          x2="255.838"
          y2="619.634"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_32_2741"
          x1="232.23"
          y1="660.525"
          x2="244.036"
          y2="640.077"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_32_2741"
          x1="220.427"
          y1="680.967"
          x2="232.233"
          y2="660.519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_32_2741"
          x1="208.624"
          y1="701.41"
          x2="220.43"
          y2="680.962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_32_2741"
          x1="196.821"
          y1="721.853"
          x2="208.627"
          y2="701.405"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_32_2741"
          x1="208.566"
          y1="59.6266"
          x2="196.76"
          y2="39.1783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_32_2741"
          x1="220.369"
          y1="80.0693"
          x2="208.563"
          y2="59.6209"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_32_2741"
          x1="232.172"
          y1="100.512"
          x2="220.366"
          y2="80.064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_32_2741"
          x1="243.974"
          y1="120.955"
          x2="232.168"
          y2="100.507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_32_2741"
          x1="255.777"
          y1="141.398"
          x2="243.971"
          y2="120.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_32_2741"
          x1="267.579"
          y1="161.84"
          x2="255.774"
          y2="141.392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_32_2741"
          x1="279.382"
          y1="182.283"
          x2="267.576"
          y2="161.835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_32_2741"
          x1="291.185"
          y1="202.726"
          x2="279.379"
          y2="182.278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_32_2741"
          x1="302.987"
          y1="223.169"
          x2="291.181"
          y2="202.721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_32_2741"
          x1="314.79"
          y1="243.611"
          x2="302.984"
          y2="223.163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_32_2741"
          x1="326.593"
          y1="264.054"
          x2="314.787"
          y2="243.606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_32_2741"
          x1="338.395"
          y1="284.497"
          x2="326.59"
          y2="264.049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_32_2741"
          x1="350.198"
          y1="304.94"
          x2="338.392"
          y2="284.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_32_2741"
          x1="362.001"
          y1="325.382"
          x2="350.195"
          y2="304.934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_32_2741"
          x1="373.803"
          y1="345.826"
          x2="361.997"
          y2="325.377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_32_2741"
          x1="385.606"
          y1="366.268"
          x2="373.8"
          y2="345.82"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_32_2741"
          x1="397.408"
          y1="386.711"
          x2="385.603"
          y2="366.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_32_2741"
          x1="409.211"
          y1="407.154"
          x2="397.405"
          y2="386.705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_32_2741"
          x1="421.014"
          y1="427.596"
          x2="409.208"
          y2="407.148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_32_2741"
          x1="432.816"
          y1="448.039"
          x2="421.01"
          y2="427.591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_32_2741"
          x1="444.619"
          y1="468.482"
          x2="432.813"
          y2="448.034"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_32_2741"
          x1="456.422"
          y1="488.925"
          x2="444.616"
          y2="468.476"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_32_2741"
          x1="468.224"
          y1="509.367"
          x2="456.418"
          y2="488.919"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_32_2741"
          x1="480.027"
          y1="529.81"
          x2="468.221"
          y2="509.362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_32_2741"
          x1="491.829"
          y1="550.253"
          x2="480.024"
          y2="529.805"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_32_2741"
          x1="503.632"
          y1="570.696"
          x2="491.826"
          y2="550.247"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_32_2741"
          x1="515.435"
          y1="591.138"
          x2="503.629"
          y2="570.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_32_2741"
          x1="527.237"
          y1="611.581"
          x2="515.431"
          y2="591.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_32_2741"
          x1="539.04"
          y1="632.024"
          x2="527.234"
          y2="611.576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_32_2741"
          x1="550.843"
          y1="652.467"
          x2="539.037"
          y2="632.018"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_32_2741"
          x1="562.645"
          y1="672.909"
          x2="550.84"
          y2="652.461"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_32_2741"
          x1="574.448"
          y1="693.352"
          x2="562.642"
          y2="672.904"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_32_2741"
          x1="586.251"
          y1="713.795"
          x2="574.445"
          y2="693.347"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F9FB" />
          <stop offset="1" stopColor="#C2C6CC" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_32_2741"
          x1="632.401"
          y1="133.599"
          x2="145.77"
          y2="620.23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopColor="#D9D9D9" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_32_2741"
          x1="154.664"
          y1="570.418"
          x2="578.096"
          y2="146.986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F4A72" />
          <stop offset="1" stopColor="#646E90" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_32_2741"
          x1="154.664"
          y1="570.418"
          x2="578.096"
          y2="146.986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F4A72" />
          <stop offset="1" stopColor="#646E90" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_32_2741"
          x1="154.664"
          y1="570.418"
          x2="578.096"
          y2="146.986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F4A72" />
          <stop offset="1" stopColor="#646E90" />
        </linearGradient>
      </defs>
    </svg>
  )
}
