export const ProcessSchemeSvgDefs = () => (
  <defs>
    <linearGradient
      id="paint24_linear_295_35042"
      x1="74.25"
      y1="512"
      x2="102"
      y2="556.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <filter
      id="filter12_d_295_32794"
      x="444.941"
      y="188.821"
      width="278.059"
      height="220.298"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.905882 0 0 0 0 0.2 0 0 0 0 0.627451 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_32794" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_32794" result="shape" />
    </filter>
    <linearGradient
      id="paint24_linear_295_32794"
      x1="687.25"
      y1="280"
      x2="715"
      y2="324.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint26_linear_295_33363"
      x1="647.25"
      y1="503"
      x2="675"
      y2="547.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint33_linear_295_33899"
      x1="473.25"
      y1="649"
      x2="501"
      y2="693.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <filter
      id="filter22_d_295_33899"
      x="356.823"
      y="412.71"
      width="228.658"
      height="291.401"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.905882 0 0 0 0 0.2 0 0 0 0 0.627451 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_33899" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_33899" result="shape" />
    </filter>
    <linearGradient
      id="paint35_linear_295_34492"
      x1="255.25"
      y1="655"
      x2="283"
      y2="699.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint24_linear_317_37283"
      x1="30.25"
      y1="280"
      x2="58"
      y2="324.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint38_linear_317_37283"
      x1="143.102"
      y1="89"
      x2="170.852"
      y2="133.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    {/* Start Outside Circle Arrows gradients */}
    <linearGradient
      id="paint22_linear_292_32254"
      x1="483.116"
      y1="32.0571"
      x2="494.326"
      y2="47.7793"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint23_linear_292_32254"
      x1="660.28"
      y1="178.128"
      x2="660.226"
      y2="197.437"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint24_linear_292_32254"
      x1="687.564"
      y1="406.191"
      x2="706.561"
      y2="409.65"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint25_linear_292_32254"
      x1="586.28"
      y1="592.604"
      x2="600.104"
      y2="606.084"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint26_linear_292_32254"
      x1="372.849"
      y1="678.709"
      x2="372.48"
      y2="698.015"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint27_linear_292_32254"
      x1="157.649"
      y1="604.015"
      x2="143.054"
      y2="616.657"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint28_linear_292_32254"
      x1="49.0636"
      y1="417.793"
      x2="29.8643"
      y2="419.849"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint29_linear_292_32254"
      x1="81.5247"
      y1="202.834"
      x2="65.1982"
      y2="192.525"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint30_linear_292_32254"
      x1="251.005"
      y1="53.9911"
      x2="244.17"
      y2="35.9321"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    {/* End Outside Circle Arrows gradients */}
    <linearGradient id="k" x1={4.5} x2={547} y1={868.5} y2={81.5} gradientUnits="userSpaceOnUse">
      <stop stopColor="#B1E6FC" />
      <stop offset={1} stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient id="B" x1={435} x2={651.458} y1={258.5} y2={209.564} gradientUnits="userSpaceOnUse">
      <stop stopColor="#E733A0" />
      <stop offset={1} stopColor="#FA426E" />
    </linearGradient>
    <linearGradient id="D" x1={578.25} x2={606} y1={87} y2={131.25} gradientUnits="userSpaceOnUse">
      <stop offset={0} stopColor="#3F8EF7" />
      <stop offset={1} stopColor="#74FBC4" />
    </linearGradient>
    <filter id="C" width={72} height={72} x={560} y={76} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dx={8} dy={7} />
      <feGaussianBlur stdDeviation={9} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.142083 0 0 0 0 0.317222 0 0 0 0 0.55 0 0 0 0.12 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="F"
      width={64.005}
      height={64.004}
      x={664.747}
      y={276}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="G"
      width={64.004}
      height={64.004}
      x={625.077}
      y={500.998}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="H"
      width={64.007}
      height={64.007}
      x={450.057}
      y={647.854}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="I"
      width={64.006}
      height={64.007}
      x={221.59}
      y={647.854}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="J"
      width={64.004}
      height={64.004}
      x={46.573}
      y={500.998}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="K"
      width={64.004}
      height={64.004}
      x={6.899}
      y={275.999}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="L"
      width={64.001}
      height={64.001}
      x={121.136}
      y={78.141}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <radialGradient
      id="j"
      cx={0}
      cy={0}
      r={1}
      gradientTransform="matrix(-260.0574 358.26695 -379.95871 -275.80294 349.059 354.234)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#D9E1F7" />
      <stop offset={1} stopColor="#fff" stopOpacity={0.5} />
    </radialGradient>
    <radialGradient
      id="y"
      cx={0}
      cy={0}
      r={1}
      gradientTransform="matrix(-194.39315 279.0918 -295.98983 -206.16297 349.169 354.242)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#fff" />
      <stop offset={1} stopColor="#fff" stopOpacity={0.4} />
    </radialGradient>
    {/* phase 1 */}
    <filter
      xmlns="http://www.w3.org/2000/svg"
      id="filter0_d_196_1302"
      x="301"
      y="0.365234"
      width="72"
      height="70.8623"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="8" dy="7" />
      <feGaussianBlur stdDeviation="9" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.142083 0 0 0 0 0.317222 0 0 0 0 0.55 0 0 0 0.34 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_196_1302" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_196_1302" result="shape" />
    </filter>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint1_linear_196_1302"
      x1="319.25"
      y1="11.3652"
      x2="345.739"
      y2="54.9826"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    {/* end test */}
    <filter
      id="filter0_d_195_763"
      x="333"
      y="0.516602"
      width="72"
      height="71.1084"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="8" dy="7" />
      <feGaussianBlur stdDeviation="9" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.142083 0 0 0 0 0.317222 0 0 0 0 0.55 0 0 0 0.34 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_763" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_763" result="shape" />
    </filter>
    <linearGradient id="paint0_linear_195_763" x1="365.5" y1="28" x2="365.5" y2="361" gradientUnits="userSpaceOnUse">
      <stop stopColor="#04CEAA" />
      <stop offset="1" stopColor="#81E7D5" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_195_763"
      x1="351.25"
      y1="11.5166"
      x2="378.012"
      y2="55.275"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    {/* phase 2 */}
    <filter
      id="filter0_d_195_820"
      x="388"
      y="19"
      width="291.979"
      height="299.484"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.905882 0 0 0 0 0.2 0 0 0 0 0.627451 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_820" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_820" result="shape" />
    </filter>
    <linearGradient
      id="paint0_linear_195_820"
      x1="349.687"
      y1="349.106"
      x2="-713.302"
      y2="1392.22"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_195_820"
      x1="367.5"
      y1="24.8618"
      x2="532.971"
      y2="157.697"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    {/* phase 3 */}
    <filter
      id="filter0_b_195_817"
      x="410.113"
      y="150.402"
      width="274.887"
      height="244.749"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_195_817" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_195_817" result="shape" />
    </filter>
    <radialGradient
      id="paint0_radial_195_817"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(315.169 332.241) rotate(125.975) scale(440.063 466.707)"
    >
      <stop stopColor="#D9E1F7" />
      <stop offset="1" stopColor="white" stopOpacity="0.5" />
    </radialGradient>
    <linearGradient
      id="paint1_linear_195_817"
      x1="-27.336"
      y1="843.442"
      x2="511.93"
      y2="61.1334"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    {/* phase 4 */}
    <filter
      id="filter0_d_195_795"
      x="417.5"
      y="369.516"
      width="299.732"
      height="279.518"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="2" dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.729412 0 0 0 0 0.847059 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_795" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_795" result="shape" />
    </filter>
    <linearGradient id="paint0_linear_195_795" x1="425" y1="461" x2="772" y2="461" gradientUnits="userSpaceOnUse">
      <stop stopColor="#33BAD8" />
      <stop offset="1" stopColor="#78E820" />
    </linearGradient>
    {/* phase 5 */}
    <linearGradient id="paint0_linear_195_867" x1="554" y1="586.5" x2="291" y2="489.5" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FF5656" />
      <stop offset="1" stopColor="#FDC46E" />
    </linearGradient>
    {/* phase 6 */}
    <filter
      id="filter0_d_195_884"
      x="0.144531"
      y="0.493164"
      width="268.236"
      height="288.507"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.32549 0 0 0 0 0.878431 0 0 0 0 1 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_884" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_884" result="shape" />
    </filter>
    <linearGradient
      id="paint0_linear_195_884"
      x1="230"
      y1="98.0002"
      x2="97.0004"
      y2="128.5"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#53E0FF" />
      <stop offset="1" stopColor="#04CEAA" />
    </linearGradient>
    {/* phase 7 */}
    <filter
      id="filter0_d_195_897"
      x="23.0996"
      y="372.78"
      width="298.766"
      height="279.278"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="8" dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.278431 0 0 0 0 0.909804 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_897" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_897" result="shape" />
    </filter>
    <linearGradient
      id="paint0_linear_195_897"
      x1="258"
      y1="554.5"
      x2="93.4127"
      y2="361.921"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#BE47E8" />
      <stop offset="1" stopColor="#6D39DA" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_195_897"
      x1="11.5"
      y1="464.5"
      x2="-70.3313"
      y2="388.406"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#BE47E8" />
      <stop offset="1" stopColor="#6D39DA" />
    </linearGradient>
    {/* phase 8 */}
    <filter
      id="filter0_d_195_775"
      x="15"
      y="192.752"
      width="277.256"
      height="259.076"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="8" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.305882 0 0 0 0 0.470588 0 0 0 0 1 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_775" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_775" result="shape" />
    </filter>
    <linearGradient
      id="paint0_linear_195_775"
      x1="31"
      y1="688.685"
      x2="436.376"
      y2="35.1194"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#6E7BFF" />
      <stop offset="1" stopColor="#4AA3FF" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_195_775"
      x1="364.5"
      y1="38"
      x2="217.397"
      y2="729.917"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#459AFF" />
      <stop offset="1" stopColor="#4E78FF" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_195_775"
      x1="31"
      y1="688.685"
      x2="25.9994"
      y2="309.731"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#6E7BFF" />
      <stop offset="1" stopColor="#4AA3FF" />
    </linearGradient>
    {/* phase 9 */}
    <filter
      id="filter0_d_195_931"
      x="24.4902"
      y="1.22656"
      width="293.611"
      height="300.591"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.807843 0 0 0 0 0.909804 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_195_931" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_195_931" result="shape" />
    </filter>
    <linearGradient
      id="paint0_linear_195_931"
      x1="56"
      y1="176.5"
      x2="126.329"
      y2="-6.44104"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#11CEE8" />
      <stop offset="1" stopColor="#079FE0" />
    </linearGradient>
  </defs>
)
