// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nGIpk{position:relative;z-index:1;min-width:86px;margin-left:8px;padding:4px 8px;background-color:#054ada}.h2Omh::part(typography){color:#fff;font-family:"IBM Plex Sans",sans-serif;line-height:13px;letter-spacing:5%}.c_H3m{width:102px;margin-top:-7px;padding:12px 8px 8px;background-color:#f6f2ff}.QfX7F::part(typography){color:#012d9c;font-family:"IBM Plex Sans",sans-serif;line-height:16px}.za_OY{margin-top:46px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibmNew/components/inputOutputCard/InputOutputCard.module.scss"],"names":[],"mappings":"AAEA,OACE,iBAAA,CACA,SAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,wBAAA,CAIA,yBACE,UAAA,CACA,sCAAA,CACA,gBAAA,CACA,iBAAA,CAIJ,OACE,WAAA,CACA,eAAA,CACA,oBAAA,CACA,wBAAA,CAIA,yBACE,aAAA,CACA,sCAAA,CACA,gBAAA,CAIJ,OACE,eAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600;700&display=swap');\n\n.titleContainer {\n  position: relative;\n  z-index: 1;\n  min-width: 86px;\n  margin-left: 8px;\n  padding: 4px 8px;\n  background-color: #054ada;\n}\n\n.title {\n  &::part(typography) {\n    color: #ffffff;\n    font-family: 'IBM Plex Sans', sans-serif;\n    line-height: 13px;\n    letter-spacing: 5%;\n  }\n}\n\n.contentBox {\n  width: 102px;\n  margin-top: -7px;\n  padding: 12px 8px 8px;\n  background-color: #f6f2ff;\n}\n\n.content {\n  &::part(typography) {\n    color: #012d9c;\n    font-family: 'IBM Plex Sans', sans-serif;\n    line-height: 16px;\n  }\n}\n\n.arrowLeftRight {\n  margin-top: 46px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": `nGIpk`,
	"title": `h2Omh`,
	"contentBox": `c_H3m`,
	"content": `QfX7F`,
	"arrowLeftRight": `za_OY`
};
export default ___CSS_LOADER_EXPORT___;
