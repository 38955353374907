export enum PhaseGroups {
  Discovery = 'Discovery',
  Design = 'Design',
  Delivery = 'Delivery',
}

export enum Phases {
  Adapt = 'Adapt',
  Ambition = 'Ambition',
  Audience = 'Audience',
  Approach = 'Approach',
  AssetsAndIdeas = 'Assets and Ideas',
  Activate = 'Activate',
}
