import {
  WppActionButton,
  WppIconChevron,
  WppTypography,
  WppButton,
  WppIconLogin,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import illustrationImgSrc from 'pages/customProcess/components/asml/assets/detail-page-illustration.png'
import { ReactComponent as ChartIcon } from 'pages/customProcess/components/asml/assets/icons/chart-icon.svg'
import { ReactComponent as MagnifyIcon } from 'pages/customProcess/components/asml/assets/icons/magnify-icon.svg'
import { ReactComponent as RocketIcon } from 'pages/customProcess/components/asml/assets/icons/rocket-icon.svg'
import { ReactComponent as StarsIcon } from 'pages/customProcess/components/asml/assets/icons/stars-icon.svg'
import { ReactComponent as TargetIcon } from 'pages/customProcess/components/asml/assets/icons/target-icon.svg'
import wppLogoImgSrc from 'pages/customProcess/components/asml/assets/wpp-asml-logo-white.png'
import styles from 'pages/customProcess/components/asml/processPhases/pageLayout/PageLayout.module.scss'
import { routesManager } from 'utils/routesManager'

export interface PageLayoutProps {
  type: 'discover' | 'define' | 'pilot' | 'scale'
  title: string
  description: string
  activities: string[]
  deliverables: string[]
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({
  type,
  title,
  description,
  activities,
  deliverables,
  previousLink,
  nextLink,
}: PageLayoutProps) => {
  const phaseIcon = useMemo(() => {
    switch (type) {
      case 'discover':
        return <MagnifyIcon stroke="#f8d247" />
      case 'define':
        return <TargetIcon stroke="#f8d247" />
      case 'pilot':
        return <RocketIcon stroke="#f8d247" />
      case 'scale':
        return <ChartIcon stroke="#f8d247" />
      default:
        return null
    }
  }, [type])

  return (
    <div className={styles.root}>
      <Flex className={styles.decorativeBorders}>
        <div className={styles.quarterBorderBox} />
        <div className={styles.quarterBorderBox} />
        <div className={styles.quarterBorderBox} />
        <div className={clsx(styles.quarterBorderBox, styles.quarterBorderBoxLast)} />
      </Flex>
      <Flex direction="column" className={styles.pageContent}>
        <Flex justify="between" className={styles.header}>
          <div>
            <Link to="/custom">
              <WppActionButton variant="inverted" className={styles.backToProcessBtnASML}>
                <WppIconChevron direction="left" slot="icon-start" />
                <WppTypography className={styles.backToProcessBtnText}>Back to Process overview</WppTypography>
              </WppActionButton>
            </Link>
            <Flex gap={28.5} align="center">
              {phaseIcon}
              <div>
                <WppTypography tag="h1" className={styles.title}>
                  {title}
                </WppTypography>
                <div className={styles.titleUnderline} />
                <WppTypography tag="p" className={styles.descriptionASML}>
                  {description}
                </WppTypography>
              </div>
            </Flex>
          </div>
          <Flex align="center">
            <img src={wppLogoImgSrc} alt="" className={styles.logo} />
          </Flex>
        </Flex>
        <div className={styles.mainContentWrapper}>
          <img src={illustrationImgSrc} alt="" className={styles.mainContentIllustration} />
          <Flex direction="column" justify="between" className={styles.mainContentASML}>
            <Flex gap={24}>
              <div className={styles.contentColumn}>
                <Flex className={styles.contentColumnIconContainer} justify="center" align="center">
                  <WppIconLogin color="var(--wpp-grey-color-000)" width={48} className={styles.activitiesIcon} />
                </Flex>
                <WppTypography className={styles.contentColumnTitle}>Activities:</WppTypography>
                <ul className={styles.list}>
                  {activities.map((activity, index) => (
                    <li key={index}>{activity}</li>
                  ))}
                </ul>
              </div>
              <div className={styles.contentColumn}>
                <Flex className={styles.contentColumnIconContainer} justify="center" align="center">
                  <StarsIcon width={54} />
                </Flex>
                <WppTypography className={styles.contentColumnTitle}>Deliverables:</WppTypography>
                <ul className={styles.list}>
                  {deliverables.map((deliverable, index) => (
                    <li key={index}>{deliverable}</li>
                  ))}
                </ul>
              </div>
            </Flex>
            <Flex align="center" justify="between" className={styles.asmlFooterNav}>
              <Flex gap={16}>
                <Link to={previousLink ?? ''}>
                  <WppActionButton
                    className={clsx(styles.asmlNavButton, !previousLink && styles.asmlNavButtonDisabled)}
                    variant="secondary"
                    disabled={!previousLink}
                  >
                    <Flex align="center" gap={4}>
                      <WppIconChevron
                        slot="icon-end"
                        direction="left"
                        color={!previousLink ? 'var(--wpp-grey-color-500)' : '#000000'}
                        className={styles.navButtonIcon}
                      />
                      Previous phase
                    </Flex>
                  </WppActionButton>
                </Link>
                <Link to={nextLink ?? ''}>
                  <WppActionButton
                    className={clsx(styles.asmlNavButton, !nextLink && styles.asmlNavButtonDisabled)}
                    variant="secondary"
                    disabled={!nextLink}
                  >
                    <Flex align="center" gap={4}>
                      Next phase
                      <WppIconChevron
                        slot="icon-end"
                        color={!nextLink ? 'var(--wpp-grey-color-500)' : '#000000'}
                        className={styles.navButtonIcon}
                      />
                    </Flex>
                  </WppActionButton>
                </Link>
              </Flex>
              <Link to={routesManager.systemUrls.orchestration.root.url}>
                <WppButton className={styles.asmlProjectButton}>Go to project details</WppButton>
              </Link>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
