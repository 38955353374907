export enum Phases {
  Brief = 'Brief',
  Direction = 'Direction',
  Strategy = 'Strategy',
  Idea = 'Idea',
  Experience = 'Experience',
  Delivery = 'Delivery',
  Impact = 'Impact',
}

export interface PhaseContent {
  title: string
  description1: string
  description2: string
  output: string[]
  buttons: { title: string; url: string }[]
}
export interface HubContent {
  colors: Record<string, string>
  logo: string
  animation: string
  phases: Record<Phases, PhaseContent>
}

export interface Content {
  boots: HubContent
  no7Beauty: HubContent
  pharm: HubContent
}

export interface WBAProps {
  hub?: 'boots' | 'no7Beauty' | 'pharm'
}
