// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sByVY{margin-bottom:8px;color:var(--wpp-grey-color-000)}.Z4GVj{color:var(--wpp-grey-color-000)}.srB5L li{margin-left:22px;color:var(--wpp-grey-color-000);line-height:22px;list-style-type:disc}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/spotify/processPhases/pages/decision/Decision.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,+BAAA,CAGF,OACE,+BAAA,CAIA,UACE,gBAAA,CACA,+BAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".descriptionCardTitle {\n  margin-bottom: 8px;\n  color: var(--wpp-grey-color-000);\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n\n.list {\n  li {\n    margin-left: 22px;\n    color: var(--wpp-grey-color-000);\n    line-height: 22px;\n    list-style-type: disc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionCardTitle": `sByVY`,
	"whiteText": `Z4GVj`,
	"list": `srB5L`
};
export default ___CSS_LOADER_EXPORT___;
