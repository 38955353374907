import imagineLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/imagine-logo.svg'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/bigBriefIdea/bigBriefIdea.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const BigBriefIdea = () => {
  const nextLink = '/custom/prerequisites-to-bep-3'

  return (
    <ProcessCard
      title="BEP1-2: The Big Brief Idea"
      subtitle="When necessary, can be skipped, if it has been already prepared earlier"
      bepTitle="BEP 1-2"
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="Brief is given by Nestle: core insights and OBP are received"
          cardStyles={styles.inputsCard}
        />
        <RegularCard
          text={'Big Brief Idea Development:\nCreative Ideation'}
          teamTags={[
            { text: 'Creative Teams', tags: ['R'] },
            { text: 'Media Teams', tags: ['I'] },
            { text: 'Nestle Teams', tags: ['I'] },
          ]}
          brandTags={[{ text: 'Imagine®', style: styles.imagineTagColor, logoImgUrl: imagineLogoImgUrl }]}
        />
        <InputsOutputsCard title="outputs" text="Big Idea is shared with Media Team" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default BigBriefIdea
