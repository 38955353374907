interface Props {
  className?: string
}

export const OutcomeMonitoringSvg = ({ className }: Props) => (
  <svg
    width={661}
    height={661}
    viewBox="0 0 661 661"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M70.1808 259.943C85.63 202.72 119.529 152.195 166.621 116.202C213.712 80.2096 271.364 60.7614 330.635 60.8739L330.431 168.574C294.868 168.506 260.277 180.175 232.022 201.771C203.767 223.366 183.427 253.682 174.158 288.015L70.1808 259.943Z"
      fill="#8986C7"
    />
    <path
      d="M139.753 278.481C151.056 236.613 175.859 199.646 210.314 173.311C244.769 146.977 286.951 132.747 330.317 132.83L330.168 211.629C304.148 211.58 278.839 220.118 258.166 235.918C237.493 251.719 222.611 273.9 215.829 299.02L139.753 278.481Z"
      fill="#58569A"
    />
    <g clipPath="url(#clip0_54_117)">
      <path
        d="M135.733 194.78L138.805 190.094C139.212 189.476 139.654 188.929 140.134 188.462C140.612 187.986 141.133 187.636 141.687 187.404C142.24 187.172 142.829 187.085 143.464 187.153C144.099 187.22 144.765 187.48 145.474 187.951C146.325 188.517 146.91 189.208 147.213 190.052C147.515 190.888 147.47 191.792 147.065 192.746L154.2 193.029L152.098 196.228L145.595 195.582L144.726 196.899L149.565 200.075L147.813 202.738L135.733 194.78ZM142.496 195.405L143.531 193.836C143.691 193.593 143.849 193.341 144.004 193.061C144.159 192.781 144.267 192.506 144.33 192.237C144.383 191.969 144.371 191.699 144.285 191.438C144.198 191.176 143.984 190.939 143.659 190.714C143.355 190.515 143.063 190.418 142.801 190.42C142.54 190.422 142.295 190.497 142.067 190.626C141.839 190.755 141.635 190.938 141.437 191.167C141.239 191.395 141.06 191.631 140.9 191.865L139.753 193.606L142.506 195.414L142.496 195.405Z"
        fill="white"
      />
      <path
        d="M143.941 182.597L149.92 174.787L152.26 176.577L148.216 181.852L150.361 183.495L154.176 178.508L156.516 180.298L152.701 185.285L155.04 187.074L159.302 181.502L161.642 183.292L155.445 191.398L143.941 182.597Z"
        fill="white"
      />
      <path
        d="M152.587 171.542L156.147 167.511C156.644 166.948 157.168 166.467 157.71 166.049C158.251 165.631 158.819 165.36 159.4 165.208C159.982 165.057 160.582 165.063 161.198 165.217C161.815 165.37 162.446 165.737 163.091 166.299C163.727 166.863 164.167 167.43 164.412 168.019C164.657 168.599 164.753 169.187 164.697 169.764C164.634 170.352 164.439 170.935 164.113 171.516C163.787 172.096 163.367 172.678 162.862 173.251L161.314 175.001L165.544 178.744L163.432 181.13L152.587 171.542ZM159.302 173.211L160.709 171.617C160.899 171.399 161.06 171.175 161.193 170.944C161.326 170.714 161.419 170.469 161.456 170.221C161.502 169.973 161.475 169.733 161.4 169.489C161.316 169.246 161.147 169.003 160.897 168.779C160.617 168.54 160.339 168.404 160.065 168.379C159.781 168.355 159.507 168.406 159.239 168.521C158.972 168.636 158.72 168.805 158.474 169.03C158.229 169.255 158.011 169.476 157.813 169.705L156.728 170.934L159.302 173.211Z"
        fill="white"
      />
      <path
        d="M166.586 166.655C165.788 165.821 165.198 164.925 164.827 163.976C164.456 163.026 164.29 162.072 164.338 161.112C164.387 160.152 164.626 159.217 165.072 158.295C165.519 157.383 166.14 156.525 166.959 155.748C167.776 154.962 168.653 154.374 169.589 153.967C170.525 153.56 171.465 153.358 172.428 153.35C173.391 153.342 174.332 153.552 175.268 153.959C176.204 154.366 177.071 154.986 177.87 155.83C178.669 156.674 179.257 157.561 179.629 158.51C180 159.459 180.166 160.413 180.117 161.373C180.078 162.332 179.83 163.268 179.384 164.19C178.938 165.112 178.315 165.96 177.497 166.737C176.68 167.524 175.804 168.121 174.867 168.519C173.931 168.926 172.991 169.128 172.028 169.136C171.065 169.144 170.124 168.934 169.188 168.527C168.252 168.12 167.385 167.499 166.586 166.655ZM168.97 164.36C169.438 164.848 169.95 165.229 170.495 165.494C171.04 165.759 171.599 165.9 172.173 165.928C172.747 165.955 173.316 165.862 173.872 165.648C174.428 165.434 174.959 165.093 175.447 164.615C175.935 164.138 176.307 163.637 176.543 163.085C176.779 162.534 176.902 161.977 176.892 161.398C176.892 160.827 176.761 160.262 176.529 159.709C176.288 159.156 175.938 158.635 175.459 158.138C174.992 157.659 174.491 157.286 173.945 157.012C173.398 156.738 172.83 156.597 172.265 156.569C171.701 156.54 171.122 156.635 170.566 156.849C170.011 157.062 169.479 157.404 168.992 157.882C168.503 158.35 168.132 158.869 167.895 159.411C167.659 159.963 167.536 160.52 167.547 161.099C167.547 161.669 167.677 162.234 167.92 162.796C168.162 163.358 168.521 163.878 168.979 164.359L168.97 164.36Z"
        fill="white"
      />
      <path
        d="M175.61 148.314L179.957 144.782C180.53 144.313 181.114 143.937 181.723 143.624C182.333 143.31 182.931 143.139 183.523 143.08C184.116 143.03 184.708 143.13 185.298 143.381C185.879 143.632 186.437 144.092 186.972 144.751C187.616 145.547 187.956 146.387 187.993 147.281C188.03 148.175 187.715 149.017 187.039 149.806L193.747 152.234L190.778 154.644L184.781 152.059L183.557 153.051L187.209 157.544L184.736 159.55L175.609 148.305L175.61 148.314ZM181.865 150.953L183.329 149.765C183.551 149.58 183.781 149.385 184.018 149.171C184.256 148.956 184.444 148.719 184.575 148.48C184.716 148.239 184.779 147.979 184.775 147.699C184.771 147.418 184.645 147.134 184.394 146.826C184.165 146.543 183.919 146.356 183.667 146.282C183.415 146.208 183.162 146.2 182.906 146.258C182.651 146.315 182.401 146.419 182.139 146.58C181.876 146.741 181.643 146.908 181.42 147.084L179.803 148.402L181.876 150.961L181.865 150.953Z"
        fill="white"
      />
      <path
        d="M190.586 140.217L187.211 142.599L185.58 140.297L194.935 133.69L196.566 135.991L193.191 138.373L199.911 147.893L197.306 149.737L190.586 140.217Z"
        fill="white"
      />
      <path d="M197.207 132.296L199.902 130.592L207.646 142.829L204.951 144.534L197.207 132.296Z" fill="white" />
      <path
        d="M203.343 128.371L207.146 126.286L217.376 132.155L217.411 132.132L212.576 123.303L215.376 121.774L222.331 134.476L218.669 136.479L208.171 130.473L208.136 130.496L213.088 139.536L210.288 141.065L203.333 128.363L203.343 128.371Z"
        fill="white"
      />
      <path
        d="M236.781 126.927C236.118 127.752 235.358 128.466 234.502 129.079C233.655 129.691 232.741 130.208 231.761 130.63C230.728 131.086 229.699 131.335 228.677 131.397C227.664 131.457 226.702 131.315 225.804 130.988C224.905 130.661 224.087 130.138 223.351 129.437C222.615 128.735 222.017 127.85 221.557 126.78C221.098 125.719 220.857 124.68 220.834 123.663C220.81 122.645 220.991 121.698 221.366 120.813C221.742 119.927 222.296 119.133 223.028 118.422C223.762 117.72 224.649 117.141 225.682 116.685C226.743 116.226 227.775 115.92 228.78 115.795C229.785 115.661 230.76 115.764 231.706 116.094L230.627 119.241C230.047 118.999 229.464 118.889 228.865 118.892C228.276 118.894 227.603 119.065 226.854 119.385C226.23 119.654 225.71 120.013 225.301 120.444C224.883 120.876 224.583 121.359 224.384 121.906C224.184 122.444 224.11 123.023 224.142 123.628C224.175 124.242 224.33 124.851 224.596 125.457C224.864 126.081 225.215 126.611 225.631 127.05C226.056 127.488 226.524 127.817 227.055 128.037C227.585 128.257 228.15 128.369 228.748 128.357C229.345 128.344 229.958 128.217 230.581 127.94C231.222 127.66 231.738 127.348 232.138 127.003C232.539 126.657 232.84 126.351 233.053 126.083L232.112 123.927L229.733 124.968L228.552 122.269L233.747 120.005L236.773 126.946L236.781 126.927Z"
        fill="white"
      />
    </g>
    <path
      d="M187.369 257.529C186.909 257.704 186.506 257.977 186.168 258.346C185.83 258.714 185.566 259.179 185.369 259.749C185.181 260.318 185.114 260.884 185.167 261.44C185.221 261.995 185.393 262.52 185.664 263.008C185.935 263.495 186.313 263.935 186.788 264.31C187.264 264.693 187.815 264.986 188.453 265.205C189.1 265.423 189.725 265.521 190.33 265.5C190.936 265.479 191.494 265.36 192.005 265.143C192.517 264.926 192.964 264.621 193.336 264.221C193.718 263.82 193.99 263.355 194.171 262.805C194.382 262.187 194.437 261.593 194.336 261.024C194.235 260.464 194.008 259.944 193.645 259.475L196.368 257.588C196.992 258.443 197.344 259.381 197.432 260.399C197.521 261.418 197.41 262.4 197.09 263.346C196.726 264.418 196.228 265.353 195.594 266.142C194.951 266.933 194.208 267.555 193.364 268.001C192.519 268.446 191.583 268.714 190.572 268.783C189.56 268.853 188.5 268.702 187.4 268.332C186.299 267.962 185.362 267.445 184.598 266.781C183.834 266.116 183.245 265.342 182.835 264.475C182.425 263.609 182.212 262.658 182.18 261.643C182.147 260.628 182.308 259.576 182.671 258.505C182.802 258.118 182.97 257.729 183.184 257.325C183.398 256.931 183.651 256.552 183.934 256.198C184.227 255.843 184.56 255.521 184.953 255.231C185.336 254.942 185.762 254.714 186.24 254.537L187.366 257.502L187.369 257.529Z"
      fill="white"
    />
    <path
      d="M188.123 246.063L189.258 243.684L205.038 244.23L203.492 247.479L200.186 247.284L197.77 252.363L200.019 254.771L198.503 257.943L188.123 246.063ZM192.45 246.767L195.759 250.287L197.282 247.096L192.459 246.766L192.45 246.767Z"
      fill="white"
    />
    <path
      d="M193.68 235.199L196.548 230.645C196.945 230.01 197.385 229.444 197.851 228.951C198.327 228.457 198.833 228.091 199.38 227.851C199.927 227.612 200.515 227.518 201.155 227.568C201.786 227.62 202.474 227.88 203.199 228.334C203.916 228.788 204.439 229.279 204.78 229.816C205.122 230.353 205.306 230.914 205.344 231.499C205.382 232.083 205.281 232.691 205.052 233.32C204.823 233.949 204.511 234.586 204.097 235.233L202.858 237.201L207.63 240.207L205.929 242.911L193.68 235.19L193.68 235.199ZM200.572 235.768L201.706 233.969C201.86 233.73 201.985 233.475 202.082 233.223C202.18 232.971 202.23 232.714 202.234 232.47C202.239 232.227 202.178 231.981 202.053 231.75C201.928 231.519 201.731 231.314 201.443 231.136C201.134 230.942 200.837 230.858 200.549 230.877C200.261 230.895 200.009 230.994 199.763 231.149C199.516 231.303 199.294 231.521 199.085 231.774C198.886 232.036 198.696 232.288 198.543 232.546L197.667 233.928L200.571 235.759L200.572 235.768Z"
      fill="white"
    />
    <path
      d="M205.59 222.602L203.035 225.856L200.813 224.108L207.884 215.097L210.106 216.835L207.552 220.088L216.724 227.284L214.752 229.79L205.579 222.594L205.59 222.602Z"
      fill="white"
    />
    <path d="M209.92 212.959L212.075 210.603L222.748 220.391L220.593 222.746L209.92 212.959Z" fill="white" />
    <path
      d="M215.083 207.409L222.187 200.786L224.197 202.936L219.423 207.384L221.432 209.535L225.846 205.421L227.856 207.571L223.442 211.685L227.295 215.816L224.964 217.992L215.091 207.399L215.083 207.409Z"
      fill="white"
    />
    <path
      d="M233.078 202.745L223.749 199.356L226.929 196.977L232.633 199.472L231.99 193.183L235.022 190.912L235.648 200.816L239.346 205.765L236.794 207.673L233.095 202.724L233.078 202.745Z"
      fill="white"
    />
    <path
      d="M251.464 185.631C251.083 185.462 250.658 185.409 250.197 185.473C249.736 185.536 249.329 185.668 248.973 185.852C248.768 185.956 248.567 186.096 248.377 186.255C248.186 186.413 248.017 186.598 247.879 186.807C247.739 187.007 247.658 187.23 247.624 187.457C247.591 187.694 247.641 187.923 247.767 188.172C247.972 188.554 248.274 188.777 248.671 188.832C249.069 188.887 249.525 188.871 250.04 188.784C250.554 188.698 251.105 188.598 251.691 188.476C252.277 188.354 252.852 188.308 253.434 188.346C254.017 188.383 254.565 188.545 255.088 188.84C255.612 189.135 256.073 189.661 256.463 190.408C256.839 191.119 257.027 191.81 257.045 192.481C257.054 193.153 256.927 193.781 256.657 194.368C256.387 194.954 255.999 195.486 255.485 195.975C254.972 196.463 254.386 196.874 253.728 197.218C252.892 197.653 252.059 197.929 251.212 198.058C250.365 198.177 249.49 198.111 248.57 197.873L249.423 194.606C249.913 194.839 250.437 194.948 250.986 194.932C251.536 194.916 252.046 194.783 252.517 194.533C252.748 194.408 252.967 194.265 253.174 194.086C253.381 193.908 253.549 193.714 253.679 193.505C253.808 193.297 253.89 193.083 253.914 192.848C253.939 192.621 253.889 192.392 253.774 192.161C253.569 191.779 253.266 191.547 252.848 191.475C252.43 191.403 251.964 191.41 251.439 191.489C250.915 191.567 250.344 191.66 249.74 191.783C249.135 191.898 248.541 191.945 247.959 191.908C247.377 191.88 246.82 191.719 246.298 191.442C245.777 191.165 245.327 190.667 244.951 189.956C244.586 189.272 244.408 188.589 244.41 187.935C244.412 187.271 244.548 186.651 244.809 186.057C245.079 185.47 245.466 184.929 245.962 184.451C246.457 183.974 247.024 183.555 247.656 183.233C248.376 182.855 249.135 182.586 249.914 182.436C250.694 182.287 251.49 182.313 252.284 182.507L251.456 185.641L251.464 185.631Z"
      fill="white"
    />
    <path
      d="M255.548 179.746L264.686 176.102L265.781 178.836L259.611 181.298L260.609 183.807L266.437 181.481L267.531 184.215L261.703 186.541L262.798 189.275L269.309 186.678L270.404 189.411L260.925 193.2L255.559 179.754L255.548 179.746Z"
      fill="white"
    />
    <path
      d="M269.192 174.482L273.381 173.379L281.9 181.543L281.937 181.54L279.375 171.802L282.463 170.992L286.155 184.991L282.119 186.052L273.386 177.693L273.349 177.697L275.98 187.67L272.892 188.481L269.201 174.481L269.192 174.482Z"
      fill="white"
    />
    <path
      d="M294.76 172.712C294.462 172.424 294.079 172.236 293.612 172.141C293.155 172.045 292.724 172.031 292.336 172.077C292.106 172.109 291.878 172.168 291.634 172.257C291.4 172.345 291.178 172.46 290.977 172.61C290.776 172.75 290.625 172.933 290.515 173.14C290.404 173.347 290.37 173.583 290.406 173.86C290.467 174.293 290.674 174.6 291.027 174.781C291.381 174.962 291.815 175.107 292.338 175.196C292.86 175.286 293.401 175.374 293.997 175.457C294.584 175.541 295.15 175.692 295.685 175.92C296.221 176.149 296.683 176.487 297.073 176.945C297.462 177.402 297.721 178.05 297.838 178.879C297.952 179.671 297.9 180.395 297.68 181.024C297.461 181.661 297.131 182.206 296.68 182.67C296.23 183.134 295.677 183.504 295.042 183.79C294.397 184.076 293.705 184.264 292.968 184.363C292.037 184.49 291.148 184.473 290.319 184.3C289.48 184.129 288.68 183.776 287.9 183.235L289.807 180.446C290.19 180.829 290.647 181.112 291.176 181.276C291.706 181.44 292.224 181.493 292.758 181.413C293.017 181.379 293.271 181.308 293.524 181.218C293.776 181.119 293.998 180.995 294.198 180.845C294.389 180.696 294.54 180.513 294.641 180.307C294.743 180.101 294.776 179.865 294.743 179.616C294.682 179.183 294.475 178.867 294.1 178.66C293.734 178.452 293.29 178.299 292.766 178.19C292.242 178.082 291.672 177.987 291.074 177.886C290.468 177.795 289.891 177.627 289.356 177.407C288.811 177.179 288.349 176.841 287.943 176.404C287.537 175.967 287.29 175.346 287.176 174.544C287.065 173.78 287.128 173.083 287.348 172.455C287.569 171.836 287.908 171.289 288.359 170.826C288.809 170.362 289.352 169.992 289.987 169.698C290.622 169.403 291.286 169.218 291.996 169.121C292.806 169.006 293.601 169.014 294.398 169.142C295.185 169.263 295.923 169.556 296.602 170.014L294.767 172.684L294.76 172.712Z"
      fill="white"
    />
    <path
      d="M300.597 168.546L310.428 168.216L310.524 171.158L303.877 171.377L303.969 174.077L310.243 173.865L310.34 176.807L304.065 177.019L304.162 179.961L311.172 179.725L311.268 182.667L301.065 183.014L300.578 168.538L300.597 168.546Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_54_117">
        <rect width={139.789} height={159.981} fill="white" transform="translate(143.962 266.262) rotate(-96.5668)" />
      </clipPath>
    </defs>
  </svg>
)
