import { Flex } from 'components/common/flex/Flex'
import { InputOutputInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/inputOutputInfo/InputOutputInfo'
import { ResponsibilitiesInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/responsibilitiesInfo/ResponsibilitiesInfo'
import { PageLayout } from 'pages/customProcess/components/ibmNew/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/ibmNew/processPhases/pages/centralStrategy/CentralStrategy.module.scss'
import { Scheme } from 'pages/customProcess/components/ibmNew/processPhases/pages/centralStrategy/scheme/Scheme'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

const CentralStrategy = () => {
  return (
    <PageLayout
      type={Phases.CentralStrategy}
      title="01. Planning: Central Strategy"
      description="Build a comprehensive and cohesive Central approach to how paid media aims to deliver to each one of the 3 business objectives: Relevancy, Expansion, Acquisition. Against each objective we will build our strategic pillars, define audiences and develop a global media mix with clear KPIs."
      nextLink="/custom/ibm/channel-activity"
    >
      <Scheme />
      <Flex gap={16} className={styles.infoWrapper}>
        <ResponsibilitiesInfo />
        <InputOutputInfo />
      </Flex>
    </PageLayout>
  )
}

export default CentralStrategy
