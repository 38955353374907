import { WppCard, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/utils/ProcessCard.module.scss'

export interface ProcessCardProps {
  children: ReactNode
  title: ReactNode
  description: string
  projectLink?: string
  previousLink?: string
  nextLink?: string
  headerVariant?: 'idea' | 'media' | 'optimisation' | 'default'
  footerLabel?: string | false
  classes?: {
    className?: string
    header?: string
    contentWrapper?: string
    content?: string
  }
  headerLogoSlot?: ReactNode
}

export const ProcessCard = ({
  children,
  title,
  description,
  projectLink,
  previousLink,
  nextLink,
  headerVariant = 'default',
  footerLabel = 'Choreograph',
  headerLogoSlot,
  classes,
}: ProcessCardProps) => {
  return (
    <WppCard size="xl" className={clsx(styles.container, classes?.className)}>
      <div className={clsx(styles.header, styles[headerVariant], classes?.header)}>
        <Flex justify="between">
          {headerLogoSlot || <div className={styles.headerLogo} />}
          {!!projectLink && (
            <Link to={projectLink}>
              <WppButton className={styles.goToProjectButton}>Go to project</WppButton>
            </Link>
          )}
        </Flex>
        <WppTypography type="5xl-display" className={styles.headerTitle}>
          {title}
        </WppTypography>
        <div>
          <WppTypography type="s-body" className={styles.headerDescription}>
            {description}
          </WppTypography>
        </div>
      </div>
      <div className={clsx(styles.contentWrapper, classes?.contentWrapper)}>
        {previousLink && (
          <Link to={previousLink ?? ' '} className={clsx(styles.linkBackground, styles.previousLink)}>
            <WppIconChevron className={styles.linkArrow} />
          </Link>
        )}

        <div className={clsx(styles.content, classes?.content)}>{children}</div>

        {nextLink && (
          <Link to={nextLink ?? ' '} className={clsx(styles.linkBackground, styles.nextLink)}>
            <WppIconChevron className={styles.linkArrow} />
          </Link>
        )}
      </div>
      {footerLabel && (
        <Flex align="center" justify="center" className={styles.footer}>
          <WppTypography type="m-strong" className={styles.footerLabel}>
            {footerLabel}
          </WppTypography>
        </Flex>
      )}
    </WppCard>
  )
}
