import clsx from 'clsx'
import { useRef } from 'react'

import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick'
import { ProcessSchemePhase } from 'pages/customProcess/components/nestleHealth/constants'
import styles from 'pages/customProcess/components/nestleHealth/processScheme/ProcessScheme.module.scss'
import { ProcessSchemeSvgDefs } from 'pages/customProcess/components/nestleHealth/processScheme/processSchemeSvgDefs/ProcessSchemeSvgDefs'

interface Props {
  selectedPhase: ProcessSchemePhase
  onSelectPhase: (section: ProcessSchemePhase) => void
  processDetailsLinkRef: React.MutableRefObject<HTMLElement | null>
}

export const ProcessScheme = ({ selectedPhase, onSelectPhase, processDetailsLinkRef }: Props) => {
  const whenNecessaryPhaseWrapperRef = useRef(null)
  const synapsePhaseWrapperRef = useRef(null)
  const synapsePhase2WrapperRef = useRef(null)
  const bavAudienceDBWrapperRef = useRef(null)
  const synapsePhase3WrapperRef = useRef(null)
  const choreographSynapsePhaseWrapperRef = useRef(null)
  const mediaActivityPlanPhaseWrapperRef = useRef(null)
  const nexusPhaseWrapperRef = useRef(null)
  const adverityPhaseWrapperRef = useRef(null)

  useDetectOutsideClick({
    refs: [
      whenNecessaryPhaseWrapperRef,
      synapsePhaseWrapperRef,
      synapsePhase2WrapperRef,
      bavAudienceDBWrapperRef,
      synapsePhase3WrapperRef,
      choreographSynapsePhaseWrapperRef,
      mediaActivityPlanPhaseWrapperRef,
      nexusPhaseWrapperRef,
      adverityPhaseWrapperRef,
    ],
    excludedRefs: [processDetailsLinkRef],
    onOutsideClick: () => onSelectPhase(ProcessSchemePhase.EMPTY),
  })

  return (
    <div>
      <div
        className={clsx(
          styles.processSchemeBackround,
          selectedPhase !== ProcessSchemePhase.EMPTY && styles.processSchemeBackroundBlur,
        )}
      />
      {selectedPhase !== ProcessSchemePhase.EMPTY && <div className={styles.processSchemeBackroundOverlay} />}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={729}
        height={712}
        viewBox="0 0 729 712"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        className={styles.processSchemeSvg}
      >
        {/* Start Outside Circle Stroke */}
        <g filter="url(#i)">
          <circle cx={367.5} cy={355.5} r={334.5} stroke="url(#k)" strokeWidth={2} />
        </g>
        {/* End Outside Circle Stroke */}
        {/* Star Outside Circle Arrows */}
        <path
          d="M493.89 44.6904C494.346 45.3106 493.96 46.1924 493.194 46.2772L481.721 47.5488C480.956 47.6336 480.386 46.8586 480.695 46.1536L485.325 35.586C485.633 34.881 486.589 34.7746 487.046 35.3944L493.89 44.6904Z"
          fill="url(#paint22_linear_292_32254)"
        />
        <path
          d="M661.672 194.673C661.681 195.443 660.853 195.935 660.182 195.557L650.118 189.904C649.447 189.527 649.435 188.565 650.097 188.172L660.018 182.283C660.68 181.89 661.519 182.361 661.529 183.13L661.672 194.673Z"
          fill="url(#paint23_linear_292_32254)"
        />
        <path
          d="M704.095 407.739C704.854 407.865 705.192 408.767 704.702 409.361L697.362 418.271C696.873 418.865 695.924 418.706 695.654 417.985L691.607 407.181C691.337 406.461 691.949 405.718 692.708 405.844L704.095 407.739Z"
          fill="url(#paint24_linear_292_32254)"
        />
        <path
          d="M599.126 603.122C599.686 603.651 599.462 604.588 598.724 604.807L587.659 608.097C586.922 608.316 586.223 607.655 586.402 606.906L589.08 595.684C589.259 594.935 590.181 594.661 590.74 595.19L599.126 603.122Z"
          fill="url(#paint25_linear_292_32254)"
        />
        <path
          d="M373.97 695.275C373.967 696.045 373.132 696.523 372.467 696.135L362.496 690.318C361.831 689.93 361.835 688.968 362.503 688.586L372.519 682.859C373.187 682.477 374.018 682.962 374.015 683.731L373.97 695.275Z"
          fill="url(#paint26_linear_292_32254)"
        />
        <path
          d="M146.088 615.932C145.513 616.445 144.599 616.143 144.442 615.389L142.094 604.087C141.938 603.333 142.656 602.693 143.387 602.934L154.344 606.546C155.075 606.787 155.271 607.729 154.697 608.242L146.088 615.932Z"
          fill="url(#paint27_linear_292_32254)"
        />
        <path
          d="M32.7698 420.985C32.0054 421.078 31.4265 420.309 31.728 419.601L36.2477 408.979C36.549 408.27 37.504 408.154 37.9668 408.769L44.905 417.987C45.3678 418.601 44.9916 419.487 44.2277 419.58L32.7698 420.985Z"
          fill="url(#paint28_linear_292_32254)"
        />
        <path
          d="M66.7708 195.219C66.1135 194.818 66.1373 193.855 66.8136 193.487L76.952 187.967C77.628 187.599 78.4489 188.101 78.4299 188.87L78.1448 200.404C78.1257 201.173 77.2811 201.634 76.6241 201.233L66.7708 195.219Z"
          fill="url(#paint29_linear_292_32254)"
        />
        <path
          d="M243.804 39.0301C243.521 38.3141 244.119 37.5598 244.881 37.6727L256.3 39.3657C257.061 39.4786 257.415 40.3732 256.936 40.9763L249.768 50.016C249.29 50.619 248.338 50.4784 248.055 49.7628L243.804 39.0301Z"
          fill="url(#paint30_linear_292_32254)"
        />
        {/* End Outside Circle Arrows */}
        {/* Start SYNAPSE Phase */}
        {selectedPhase === ProcessSchemePhase.SYNAPSE && (
          <path
            ref={synapsePhaseWrapperRef}
            d="M635.847 170.527C601.484 118.788 553.355 77.6729 496.883 51.8143L408.139 245.62C428.517 254.951 445.884 269.787 458.284 288.457L635.847 170.527Z"
            fill="url(#paint34_radial_15_30103)"
            stroke="url(#paint35_linear_15_30103)"
            strokeLinecap="square"
            transform="translate(10 0)"
          />
        )}
        <g filter="url(#C)" onClick={() => onSelectPhase(ProcessSchemePhase.SYNAPSE)}>
          <circle
            cx={588}
            cy={105}
            r={selectedPhase === ProcessSchemePhase.SYNAPSE ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.SYNAPSE ? 'url(#D)' : '#fff'}
            transform="rotate(-90 588 105)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End SYNAPSE Phase */}
        {/* Start SYNAPSE_2 Phase */}
        {selectedPhase === ProcessSchemePhase.SYNAPSE_2 && (
          <g filter="url(#filter11_d_15_20661)" transform="translate(-102 -116)" ref={synapsePhase2WrapperRef}>
            <path
              d="M800.935 506.558C807.836 444.832 797.363 382.405 770.696 326.31L588.59 412.884C599.134 435.063 603.275 459.746 600.546 484.152L800.935 506.558Z"
              fill="url(#paint34_linear_15_20661)"
            />
          </g>
        )}
        <g filter="url(#F)" onClick={() => onSelectPhase(ProcessSchemePhase.SYNAPSE_2)}>
          <circle
            cx={696.75}
            cy={298.002}
            r={selectedPhase === ProcessSchemePhase.SYNAPSE_2 ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.SYNAPSE_2 ? 'url(#paint24_linear_295_32794)' : '#fff'}
            transform="rotate(80 696.75 298.002)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {selectedPhase === ProcessSchemePhase.SYNAPSE_2 && (
          <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_15_20661)" transform="translate(-102 -116)">
            <path
              d="M710.136 384L713.142 391.479L711.599 392.074L709.242 386.206L705.438 387.677L707.634 393.139L706.091 393.734L703.895 388.272L698.648 390.305L698 388.69L710.136 384Z"
              fill="white"
            />
            <path
              d="M710.163 395.917L710.704 397.452L709.434 397.885L709.445 397.919C709.703 397.945 709.952 398.017 710.19 398.126C710.428 398.235 710.651 398.375 710.854 398.54C711.057 398.706 711.238 398.905 711.403 399.127C711.568 399.353 711.695 399.59 711.783 399.839C711.871 400.087 711.917 400.328 711.921 400.558L710.209 401.062C710.202 400.926 710.186 400.791 710.163 400.655C710.14 400.52 710.109 400.392 710.067 400.268C709.802 399.515 709.384 399.014 708.816 398.762C708.244 398.51 707.507 398.536 706.601 398.845L702.456 400.253L701.915 398.717L710.167 395.917H710.163Z"
              fill="white"
            />
            <path
              d="M711.71 403.637C712.255 403.953 712.719 404.37 713.103 404.886C713.487 405.402 713.767 405.948 713.944 406.523C714.128 407.122 714.212 407.66 714.189 408.138C714.17 408.616 714.074 409.03 713.902 409.384C713.729 409.738 713.495 410.032 713.199 410.261C712.904 410.494 712.573 410.664 712.209 410.769L707.941 412.034C707.645 412.121 707.377 412.207 707.135 412.29C706.893 412.373 706.663 412.463 706.444 412.55L706.018 411.164C706.432 411.006 706.847 410.863 707.273 410.735L707.261 410.701C706.613 410.517 706.106 410.22 705.738 409.813C705.369 409.407 705.089 408.891 704.897 408.27C704.778 407.89 704.72 407.51 704.72 407.133C704.72 406.757 704.786 406.407 704.916 406.083C705.047 405.759 705.246 405.477 705.511 405.236C705.776 404.995 706.114 404.811 706.528 404.69C707.069 404.532 707.557 404.514 707.994 404.641C708.432 404.769 708.827 405.007 709.184 405.345C709.541 405.684 709.852 406.117 710.121 406.636C710.39 407.156 710.62 407.724 710.812 408.349L711.165 409.489L711.518 409.384C711.729 409.32 711.929 409.218 712.117 409.075C712.305 408.932 712.458 408.756 712.577 408.541C712.696 408.33 712.769 408.086 712.796 407.815C712.823 407.544 712.785 407.239 712.681 406.904C712.589 406.603 712.478 406.35 712.351 406.143C712.224 405.936 712.086 405.752 711.94 405.594C711.794 405.436 711.641 405.3 711.476 405.184C711.311 405.067 711.157 404.958 711.007 404.849L711.706 403.621L711.71 403.637ZM709.745 409.011C709.63 408.643 709.495 408.27 709.334 407.897C709.173 407.525 708.985 407.193 708.77 406.911C708.555 406.629 708.309 406.418 708.037 406.279C707.764 406.14 707.461 406.121 707.131 406.215C706.647 406.358 706.352 406.618 706.237 406.998C706.121 407.378 706.148 407.837 706.317 408.379C706.448 408.804 706.632 409.147 706.874 409.403C707.112 409.659 707.377 409.843 707.672 409.96C707.964 410.073 708.267 410.129 708.578 410.126C708.889 410.122 709.188 410.077 709.472 409.994L710.002 409.836L709.745 409.004V409.011Z"
              fill="white"
            />
            <path
              d="M715.828 413.46L716.185 414.936L714.857 415.245L714.865 415.278C714.992 415.286 715.164 415.342 715.379 415.448C715.594 415.553 715.809 415.7 716.028 415.892C716.243 416.084 716.446 416.321 716.634 416.603C716.822 416.886 716.964 417.217 717.053 417.593C717.202 418.214 717.199 418.768 717.037 419.249C716.876 419.731 716.565 420.172 716.105 420.563C716.696 420.71 717.183 421 717.563 421.429C717.947 421.858 718.196 422.328 718.319 422.829C718.477 423.473 718.496 424.026 718.381 424.485C718.266 424.944 718.058 425.332 717.763 425.652C717.467 425.968 717.106 426.224 716.68 426.416C716.254 426.608 715.801 426.762 715.322 426.871L710.374 428.027L709.99 426.446L714.723 425.343C715.045 425.268 715.352 425.174 715.64 425.065C715.928 424.956 716.17 424.809 716.366 424.624C716.561 424.44 716.7 424.218 716.78 423.951C716.861 423.683 716.853 423.356 716.761 422.972C716.577 422.212 716.204 421.722 715.648 421.504C715.091 421.286 714.435 421.267 713.683 421.444L709.042 422.528L708.658 420.947L713.103 419.908C713.51 419.814 713.875 419.705 714.201 419.588C714.523 419.468 714.796 419.321 715.011 419.14C715.226 418.96 715.375 418.741 715.456 418.478C715.537 418.218 715.533 417.894 715.437 417.507C715.368 417.224 715.245 416.965 715.068 416.728C714.892 416.49 714.665 416.298 714.389 416.152C714.113 416.005 713.786 415.915 713.406 415.881C713.026 415.847 712.596 415.881 712.117 415.997L707.707 417.025L707.323 415.444L715.817 413.46H715.828Z"
              fill="white"
            />
            <path
              d="M714.876 431.735C714.5 431.799 714.174 431.938 713.894 432.149C713.614 432.359 713.387 432.615 713.218 432.916C713.046 433.214 712.93 433.545 712.873 433.91C712.811 434.275 712.815 434.64 712.881 435.009C712.969 435.506 713.165 435.92 713.464 436.248C713.767 436.575 714.136 436.861 714.581 437.102L713.852 438.472C712.52 437.783 711.71 436.639 711.426 435.036C711.307 434.369 711.315 433.748 711.449 433.169C711.583 432.589 711.817 432.077 712.159 431.637C712.501 431.196 712.923 430.824 713.441 430.526C713.955 430.229 714.535 430.029 715.176 429.917C715.817 429.807 716.431 429.815 717.022 429.939C717.613 430.063 718.143 430.285 718.619 430.602C719.091 430.918 719.494 431.324 719.816 431.817C720.142 432.31 720.361 432.875 720.473 433.504C720.607 434.253 720.584 434.908 720.411 435.468C720.238 436.029 719.966 436.515 719.594 436.921C719.221 437.328 718.78 437.652 718.269 437.889C717.759 438.13 717.222 438.295 716.665 438.393L716.085 438.491L714.884 431.727L714.876 431.735ZM717.08 436.567C717.44 436.492 717.763 436.379 718.047 436.229C718.331 436.074 718.569 435.879 718.757 435.642C718.945 435.401 719.075 435.118 719.148 434.795C719.221 434.471 719.221 434.106 719.148 433.703C719.079 433.312 718.937 432.965 718.73 432.664C718.519 432.363 718.273 432.122 717.993 431.938C717.709 431.753 717.41 431.625 717.091 431.55C716.773 431.475 716.469 431.464 716.178 431.512L717.076 436.567H717.08Z"
              fill="white"
            />
            <path
              d="M721.359 439.771L721.574 441.585L715.322 444.235V444.272L722.046 445.571L722.25 447.295L716.028 450.186V450.223L722.726 451.296L722.929 453.02L713.928 451.262L713.737 449.628L719.947 446.636V446.598L713.222 445.285L713.019 443.561L721.359 439.771Z"
              fill="white"
            />
            <path
              d="M718.707 455.478C719.344 455.44 719.947 455.527 720.511 455.73C721.075 455.933 721.582 456.227 722.019 456.611C722.461 456.995 722.818 457.458 723.09 458C723.363 458.538 723.516 459.129 723.555 459.765C723.593 460.401 723.509 461.003 723.301 461.572C723.094 462.14 722.795 462.637 722.403 463.07C722.012 463.502 721.543 463.853 721.006 464.116C720.469 464.379 719.882 464.534 719.244 464.568C718.607 464.605 718.001 464.519 717.429 464.319C716.857 464.116 716.354 463.822 715.92 463.439C715.487 463.055 715.134 462.592 714.861 462.05C714.589 461.511 714.435 460.92 714.397 460.284C714.358 459.648 714.443 459.046 714.65 458.478C714.857 457.909 715.153 457.412 715.54 456.98C715.928 456.547 716.393 456.197 716.938 455.933C717.483 455.67 718.074 455.515 718.711 455.481L718.707 455.478ZM718.807 457.209C718.365 457.235 717.962 457.322 717.594 457.476C717.225 457.631 716.918 457.834 716.669 458.09C716.419 458.346 716.228 458.651 716.097 459.008C715.963 459.366 715.909 459.761 715.936 460.194C715.963 460.627 716.062 461.015 716.235 461.353C716.408 461.692 716.634 461.974 716.911 462.2C717.187 462.426 717.517 462.595 717.901 462.705C718.281 462.814 718.696 462.859 719.137 462.833C719.578 462.806 719.981 462.72 720.35 462.565C720.714 462.411 721.025 462.208 721.275 461.952C721.524 461.696 721.716 461.391 721.847 461.033C721.981 460.676 722.035 460.281 722.008 459.848C721.981 459.415 721.881 459.027 721.709 458.688C721.536 458.35 721.309 458.064 721.033 457.841C720.757 457.616 720.427 457.446 720.043 457.337C719.659 457.224 719.248 457.183 718.807 457.209Z"
              fill="white"
            />
            <path
              d="M723.666 467.496L723.693 469.122L722.35 469.145V469.182C722.584 469.288 722.799 469.427 722.994 469.608C723.186 469.785 723.351 469.984 723.493 470.206C723.632 470.428 723.743 470.669 723.827 470.933C723.912 471.196 723.954 471.46 723.958 471.727C723.962 471.99 723.927 472.235 723.858 472.449L722.069 472.404C722.104 472.273 722.131 472.137 722.154 472.005C722.177 471.874 722.185 471.738 722.185 471.606C722.173 470.812 721.935 470.206 721.474 469.792C721.014 469.378 720.304 469.179 719.344 469.194L714.961 469.261L714.934 467.635L723.666 467.5V467.496Z"
              fill="white"
            />
            <path
              d="M728.94 475.408L728.901 477.034L719.912 476.831L723.601 480.602L723.547 482.861L719.682 478.777L714.827 483.004L714.88 480.693L719.505 476.823L714.976 476.722L715.015 475.096L728.94 475.412V475.408Z"
              fill="white"
            />
            <path
              d="M716.423 394.528C716.086 393.609 715.94 392.706 715.986 391.821C716.032 390.933 716.243 390.113 716.619 389.36C716.995 388.607 717.517 387.933 718.189 387.346C718.861 386.759 719.651 386.3 720.565 385.98C721.478 385.656 722.384 385.513 723.286 385.547C724.188 385.581 725.025 385.769 725.808 386.115C726.587 386.461 727.282 386.962 727.896 387.613C728.51 388.268 728.982 389.055 729.32 389.973C729.658 390.892 729.803 391.795 729.757 392.68C729.711 393.568 729.5 394.388 729.124 395.141C728.748 395.894 728.226 396.568 727.554 397.155C726.883 397.746 726.092 398.201 725.178 398.521C724.265 398.845 723.359 398.988 722.457 398.954C721.555 398.92 720.718 398.732 719.935 398.386C719.156 398.039 718.461 397.539 717.847 396.888C717.233 396.233 716.761 395.446 716.423 394.528ZM717.986 393.978C718.239 394.671 718.6 395.247 719.064 395.71C719.529 396.173 720.051 396.526 720.638 396.767C721.225 397.008 721.851 397.132 722.526 397.136C723.202 397.14 723.873 397.027 724.541 396.79C725.209 396.553 725.804 396.221 726.322 395.796C726.837 395.371 727.243 394.882 727.535 394.332C727.827 393.779 728.003 393.18 728.061 392.533C728.118 391.885 728.019 391.215 727.765 390.527C727.512 389.834 727.151 389.258 726.687 388.795C726.222 388.332 725.697 387.978 725.113 387.737C724.53 387.497 723.9 387.372 723.225 387.369C722.549 387.365 721.878 387.478 721.21 387.715C720.538 387.952 719.947 388.283 719.429 388.709C718.911 389.134 718.508 389.623 718.216 390.173C717.92 390.726 717.748 391.325 717.69 391.972C717.632 392.619 717.732 393.289 717.986 393.978Z"
              fill="white"
            />
            <path
              d="M722.019 410.656L721.551 409.094L722.841 408.722L722.829 408.688C722.323 408.62 721.858 408.383 721.436 407.976C721.014 407.57 720.707 407.05 720.519 406.414C720.396 406.008 720.346 405.609 720.361 405.217C720.377 404.822 720.476 404.453 720.653 404.107C720.83 403.761 721.098 403.448 721.459 403.17C721.816 402.891 722.273 402.673 722.825 402.511L728.218 400.96L728.686 402.522L723.735 403.945C723.347 404.058 723.029 404.205 722.783 404.389C722.538 404.574 722.353 404.777 722.234 404.999C722.115 405.221 722.05 405.458 722.042 405.703C722.035 405.951 722.066 406.196 722.138 406.437C722.234 406.761 722.376 407.047 722.568 407.291C722.756 407.54 722.998 407.732 723.29 407.875C723.582 408.018 723.923 408.093 724.311 408.108C724.699 408.123 725.136 408.059 725.62 407.92L729.934 406.678L730.402 408.24L722.023 410.653L722.019 410.656Z"
              fill="white"
            />
            <path
              d="M731.059 416.517L730.483 414.255L726.641 415.192C726.403 415.248 726.169 415.312 725.938 415.38C725.708 415.448 725.512 415.542 725.347 415.666C725.182 415.787 725.063 415.941 724.99 416.125C724.917 416.31 724.917 416.555 724.994 416.859C725.04 417.048 725.109 417.236 725.194 417.424C725.278 417.612 725.389 417.778 725.528 417.921L724.061 418.278C723.908 418.117 723.77 417.894 723.655 417.605C723.539 417.315 723.459 417.089 723.417 416.927C723.263 416.321 723.229 415.828 723.317 415.452C723.405 415.075 723.566 414.778 723.8 414.552C724.035 414.326 724.319 414.161 724.649 414.048C724.983 413.935 725.32 413.837 725.666 413.754L730.08 412.678L729.615 410.856L731.009 410.517L731.473 412.339L733.853 411.759L734.252 413.336L731.872 413.916L732.448 416.178L731.055 416.517H731.059Z"
              fill="white"
            />
            <path
              d="M732.198 425.765C732.448 425.407 732.621 425.05 732.713 424.692C732.805 424.335 732.805 423.932 732.713 423.484C732.621 423.047 732.459 422.682 732.225 422.392C731.991 422.103 731.715 421.877 731.385 421.715C731.055 421.553 730.698 421.455 730.306 421.418C729.915 421.38 729.523 421.402 729.124 421.482C728.729 421.564 728.364 421.704 728.038 421.907C727.711 422.11 727.439 422.355 727.224 422.645C727.009 422.934 726.863 423.258 726.783 423.619C726.702 423.981 726.706 424.368 726.794 424.779C726.89 425.227 727.051 425.592 727.282 425.878C727.512 426.164 727.811 426.409 728.18 426.616L727.266 427.982C726.71 427.666 726.272 427.252 725.95 426.747C725.627 426.243 725.405 425.682 725.274 425.069C725.136 424.421 725.117 423.804 725.22 423.224C725.324 422.645 725.527 422.118 725.835 421.651C726.142 421.18 726.548 420.785 727.051 420.461C727.554 420.138 728.134 419.908 728.798 419.773C729.458 419.637 730.087 419.618 730.686 419.72C731.285 419.818 731.822 420.021 732.302 420.322C732.782 420.623 733.189 421.022 733.523 421.512C733.856 422.001 734.098 422.577 734.236 423.239C734.367 423.853 734.378 424.463 734.279 425.069C734.175 425.675 733.937 426.228 733.569 426.736L732.194 425.761L732.198 425.765Z"
              fill="white"
            />
            <path
              d="M730.809 429.243C731.438 429.145 732.045 429.168 732.632 429.314C733.219 429.461 733.745 429.706 734.225 430.045C734.701 430.383 735.104 430.809 735.43 431.321C735.756 431.829 735.971 432.401 736.071 433.029C736.175 433.658 736.152 434.268 736.002 434.851C735.856 435.435 735.607 435.962 735.261 436.432C734.916 436.903 734.486 437.294 733.979 437.61C733.473 437.926 732.901 438.137 732.271 438.235C731.642 438.333 731.032 438.31 730.441 438.163C729.849 438.017 729.32 437.776 728.848 437.433C728.376 437.091 727.976 436.665 727.65 436.157C727.324 435.645 727.109 435.077 727.009 434.448C726.906 433.816 726.929 433.21 727.078 432.627C727.224 432.043 727.47 431.516 727.811 431.05C728.153 430.583 728.579 430.188 729.093 429.871C729.608 429.552 730.18 429.344 730.809 429.247V429.243ZM731.085 430.955C730.648 431.023 730.256 431.151 729.907 431.339C729.558 431.528 729.274 431.761 729.051 432.039C728.828 432.318 728.671 432.642 728.575 433.011C728.479 433.379 728.468 433.778 728.537 434.208C728.606 434.637 728.744 435.009 728.951 435.333C729.159 435.653 729.412 435.913 729.711 436.112C730.011 436.308 730.356 436.443 730.748 436.519C731.139 436.59 731.554 436.594 731.991 436.526C732.429 436.458 732.82 436.33 733.17 436.142C733.519 435.954 733.803 435.721 734.025 435.442C734.248 435.164 734.405 434.84 734.501 434.471C734.597 434.102 734.609 433.703 734.54 433.274C734.471 432.845 734.332 432.472 734.125 432.149C733.918 431.829 733.665 431.569 733.365 431.37C733.066 431.174 732.72 431.038 732.329 430.963C731.937 430.888 731.523 430.888 731.085 430.955Z"
              fill="white"
            />
            <path
              d="M737.065 440.817L737.215 442.326L735.856 442.454V442.492C735.987 442.515 736.148 442.594 736.347 442.725C736.547 442.857 736.739 443.034 736.927 443.252C737.115 443.471 737.284 443.734 737.43 444.039C737.576 444.344 737.668 444.69 737.706 445.074C737.768 445.71 737.687 446.256 737.461 446.711C737.234 447.167 736.866 447.558 736.355 447.886C736.919 448.112 737.361 448.462 737.679 448.94C737.998 449.418 738.182 449.915 738.232 450.43C738.297 451.089 738.24 451.639 738.059 452.079C737.879 452.519 737.622 452.877 737.284 453.152C736.946 453.426 736.555 453.63 736.106 453.761C735.657 453.893 735.188 453.983 734.697 454.029L729.634 454.507L729.477 452.888L734.317 452.433C734.647 452.403 734.962 452.35 735.265 452.278C735.568 452.207 735.825 452.094 736.048 451.94C736.267 451.785 736.436 451.582 736.551 451.33C736.666 451.078 736.708 450.754 736.67 450.359C736.593 449.58 736.294 449.045 735.772 448.755C735.25 448.465 734.605 448.356 733.833 448.432L729.082 448.88L728.924 447.261L733.473 446.832C733.887 446.794 734.263 446.734 734.605 446.663C734.943 446.587 735.231 446.478 735.469 446.328C735.707 446.177 735.883 445.981 736.002 445.733C736.121 445.484 736.159 445.164 736.121 444.769C736.094 444.483 736.006 444.208 735.864 443.949C735.722 443.689 735.522 443.471 735.273 443.286C735.023 443.102 734.709 442.97 734.336 442.884C733.964 442.797 733.534 442.778 733.043 442.823L728.533 443.249L728.375 441.63L737.069 440.81L737.065 440.817Z"
              fill="white"
            />
            <path
              d="M733.569 458.779C733.189 458.794 732.843 458.884 732.536 459.061C732.229 459.238 731.972 459.456 731.757 459.731C731.546 460.002 731.385 460.314 731.277 460.668C731.166 461.022 731.12 461.383 731.131 461.76C731.151 462.264 731.285 462.701 731.538 463.066C731.792 463.431 732.118 463.762 732.525 464.06L731.611 465.321C730.387 464.459 729.746 463.216 729.684 461.59C729.661 460.917 729.753 460.299 729.968 459.746C730.179 459.189 730.487 458.715 730.886 458.323C731.285 457.932 731.757 457.619 732.31 457.397C732.862 457.172 733.461 457.051 734.114 457.025C734.762 457.002 735.373 457.093 735.941 457.296C736.509 457.499 737.004 457.789 737.43 458.165C737.856 458.542 738.194 458.997 738.447 459.532C738.7 460.066 738.839 460.649 738.862 461.289C738.888 462.046 738.777 462.693 738.528 463.228C738.278 463.762 737.94 464.203 737.518 464.556C737.096 464.91 736.612 465.17 736.071 465.339C735.53 465.509 734.977 465.603 734.413 465.622L733.822 465.644L733.569 458.783V458.779ZM735.081 463.86C735.449 463.834 735.783 463.766 736.087 463.653C736.39 463.54 736.651 463.378 736.87 463.164C737.088 462.949 737.257 462.69 737.376 462.377C737.495 462.065 737.545 461.703 737.53 461.297C737.514 460.898 737.426 460.537 737.261 460.213C737.096 459.885 736.885 459.614 736.632 459.396C736.378 459.178 736.098 459.008 735.791 458.892C735.484 458.775 735.185 458.722 734.893 458.734L735.081 463.86Z"
              fill="white"
            />
            <path
              d="M736.574 473.097C736.82 472.905 737.027 472.668 737.196 472.389C737.365 472.107 737.449 471.761 737.453 471.354C737.453 470.97 737.376 470.635 737.211 470.349C737.046 470.067 736.804 469.92 736.486 469.92C736.229 469.92 736.017 469.999 735.856 470.161C735.695 470.323 735.565 470.515 735.465 470.737C735.365 470.959 735.288 471.196 735.238 471.448C735.188 471.7 735.142 471.919 735.104 472.099C735.016 472.449 734.908 472.777 734.785 473.082C734.659 473.387 734.498 473.65 734.302 473.872C734.102 474.094 733.864 474.267 733.58 474.392C733.296 474.516 732.951 474.576 732.548 474.572C732.056 474.572 731.638 474.463 731.289 474.256C730.939 474.049 730.655 473.782 730.437 473.454C730.218 473.127 730.061 472.754 729.965 472.34C729.869 471.923 729.823 471.505 729.826 471.083C729.83 470.372 729.945 469.751 730.172 469.216C730.398 468.682 730.805 468.207 731.4 467.79L732.421 469.024C732.149 469.288 731.907 469.581 731.696 469.905C731.485 470.229 731.377 470.624 731.373 471.094C731.373 471.298 731.392 471.508 731.435 471.719C731.477 471.93 731.542 472.118 731.634 472.28C731.726 472.442 731.841 472.577 731.98 472.679C732.122 472.781 732.283 472.833 732.467 472.837C732.713 472.837 732.916 472.766 733.077 472.615C733.239 472.465 733.365 472.284 733.457 472.077C733.549 471.866 733.626 471.644 733.684 471.411C733.741 471.177 733.787 470.97 733.826 470.79C733.914 470.44 734.014 470.112 734.129 469.8C734.24 469.487 734.39 469.212 734.578 468.972C734.766 468.731 735 468.542 735.284 468.399C735.568 468.256 735.918 468.189 736.336 468.189C736.789 468.189 737.184 468.287 737.522 468.478C737.86 468.667 738.136 468.915 738.355 469.224C738.574 469.532 738.739 469.882 738.846 470.274C738.954 470.665 739.007 471.061 739.004 471.46C739 472.039 738.885 472.593 738.658 473.119C738.432 473.646 738.063 474.068 737.541 474.377L736.574 473.104V473.097Z"
              fill="white"
            />
          </g>
        )}
        {/* End SYNAPSE_2 Phase */}
        {/* Start BAV_AUDIENCE_DB Phase */}
        {selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB && (
          <g ref={bavAudienceDBWrapperRef}>
            <path
              d="M577.488 612.955C639.533 561.868 681.041 490.093 694.377 410.837L622.654 398.769C612.227 460.741 579.77 516.862 531.257 556.808L577.488 612.955Z"
              fill="url(#paint25_linear_295_33363)"
            />
            <path
              d="M532.841 558.892C581.318 517.595 615.5 462.031 630.501 400.141L471.213 361.532C464.353 389.833 448.722 415.241 426.555 434.126L532.841 558.892Z"
              fill="url(#paint24_linear_295_33363)"
            />
          </g>
        )}
        <g filter="url(#G)" onClick={() => onSelectPhase(ProcessSchemePhase.BAV_AUDIENCE_DB)}>
          <circle
            cx={657.079}
            cy={523}
            r={selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB ? 'url(#paint26_linear_295_33363)' : '#fff'}
            transform="rotate(120 657.079 523)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB && (
          <g>
            <path
              d="M530.736 473.251L523.551 466.036L526.312 463.286C526.834 462.767 527.349 462.417 527.858 462.238C528.367 462.054 528.847 462.017 529.299 462.128C529.754 462.237 530.159 462.47 530.514 462.827C530.807 463.12 531.001 463.428 531.096 463.749C531.189 464.068 531.205 464.387 531.143 464.705C531.082 465.024 530.962 465.328 530.783 465.619L530.853 465.689C531.123 465.448 531.447 465.283 531.824 465.192C532.202 465.097 532.594 465.103 533.002 465.212C533.409 465.321 533.796 465.558 534.161 465.924C534.532 466.298 534.776 466.724 534.893 467.202C535.007 467.678 534.967 468.183 534.774 468.717C534.58 469.251 534.208 469.793 533.656 470.342L530.736 473.251ZM530.955 470.857L532.36 469.457C532.835 468.985 533.086 468.554 533.115 468.165C533.144 467.771 533.016 467.431 532.731 467.145C532.518 466.931 532.273 466.791 531.997 466.725C531.718 466.657 531.432 466.67 531.139 466.765C530.844 466.859 530.561 467.04 530.291 467.309L528.85 468.743L530.955 470.857ZM527.913 467.803L529.206 466.515C529.432 466.291 529.594 466.047 529.693 465.785C529.789 465.521 529.813 465.26 529.765 465.005C529.716 464.745 529.586 464.509 529.376 464.298C529.098 464.018 528.77 463.886 528.392 463.902C528.017 463.915 527.637 464.113 527.252 464.497L525.931 465.813L527.913 467.803Z"
              fill="white"
            />
            <path
              d="M541.789 460.611L540.765 462.293L533.932 453.936L535.117 451.991L545.68 454.22L544.656 455.902L536.329 454.015L536.287 454.083L541.789 460.611ZM538.412 458.48L541.205 453.894L542.47 454.664L539.678 459.25L538.412 458.48Z"
              fill="white"
            />
            <path
              d="M539.555 442.859L547.994 442.685L548.025 442.585L541.144 437.699L541.741 435.76L550.416 442.187L549.746 444.363L538.96 444.793L539.555 442.859Z"
              fill="white"
            />
            <g clipPath="url(#clip0_15_26200)">
              <path
                d="M577.339 545.899L578.446 544.54L587.921 548.641L587.944 548.612L582.051 540.123L583.269 538.628L592.854 542.593L592.877 542.564L586.873 534.212L587.981 532.852L595.377 543.44L594.247 544.824L584.446 540.789L584.424 540.817L590.44 549.488L589.31 550.872L577.339 545.899Z"
                fill="white"
              />
              <path
                d="M588.645 530.398L589.572 529.122L594.645 532.747L594.664 532.718C594.417 532.299 594.328 531.81 594.393 531.255C594.457 530.699 594.678 530.157 595.057 529.64C595.292 529.32 595.564 529.046 595.883 528.817C596.198 528.589 596.544 528.439 596.913 528.368C597.283 528.296 597.68 528.311 598.105 528.412C598.529 528.514 598.967 528.726 599.413 529.045L603.771 532.159L602.844 533.435L598.846 530.576C598.532 530.351 598.233 530.2 597.945 530.125C597.662 530.05 597.397 530.033 597.154 530.079C596.912 530.121 596.695 530.214 596.497 530.354C596.303 530.493 596.13 530.664 595.986 530.861C595.793 531.127 595.657 531.404 595.581 531.689C595.501 531.979 595.494 532.273 595.555 532.577C595.616 532.881 595.757 533.181 595.974 533.478C596.19 533.775 596.495 534.064 596.884 534.345L600.37 536.837L599.443 538.113L588.641 530.399L588.645 530.398Z"
                fill="white"
              />
              <path
                d="M596.381 522.923C596.117 522.747 595.954 522.504 595.893 522.188C595.831 521.873 595.887 521.584 596.064 521.322C596.241 521.06 596.486 520.899 596.804 520.837C597.122 520.776 597.413 520.831 597.677 521.007C597.94 521.183 598.103 521.426 598.164 521.742C598.226 522.057 598.17 522.346 597.993 522.608C597.816 522.87 597.571 523.031 597.253 523.093C596.935 523.154 596.644 523.099 596.381 522.923ZM599.07 524.276L599.955 522.97L606.877 527.593L605.991 528.899L599.07 524.276Z"
                fill="white"
              />
              <path
                d="M605.521 516.943L604.301 518.844L607.495 520.863C607.692 520.989 607.89 521.108 608.091 521.219C608.292 521.33 608.487 521.405 608.681 521.436C608.874 521.468 609.062 521.448 609.243 521.376C609.424 521.305 609.593 521.139 609.758 520.881C609.86 520.726 609.949 520.551 610.025 520.365C610.101 520.179 610.145 519.994 610.153 519.803L611.371 520.574C611.361 520.789 611.291 521.031 611.166 521.302C611.042 521.574 610.934 521.782 610.844 521.917C610.517 522.428 610.192 522.779 609.867 522.968C609.543 523.157 609.226 523.246 608.914 523.236C608.601 523.225 608.297 523.143 607.998 522.988C607.699 522.834 607.408 522.668 607.119 522.485L603.452 520.168L602.468 521.701L601.312 520.971L602.296 519.438L600.32 518.19L601.172 516.862L603.148 518.109L604.369 516.208L605.525 516.938L605.521 516.943Z"
                fill="white"
              />
              <path
                d="M610.79 514.825C611.102 515.007 611.431 515.108 611.768 515.121C612.105 515.135 612.431 515.085 612.747 514.972C613.058 514.856 613.351 514.684 613.625 514.45C613.9 514.215 614.128 513.943 614.311 513.633C614.562 513.209 614.68 512.786 614.666 512.362C614.652 511.937 614.561 511.495 614.393 511.045L615.787 510.481C616.324 511.806 616.189 513.148 615.39 514.506C615.058 515.069 614.66 515.523 614.199 515.869C613.737 516.215 613.242 516.446 612.714 516.564C612.187 516.682 611.642 516.693 611.082 516.598C610.521 516.503 609.971 516.296 609.433 515.984C608.896 515.673 608.455 515.291 608.103 514.836C607.751 514.382 607.506 513.892 607.359 513.369C607.213 512.846 607.178 512.299 607.254 511.735C607.33 511.171 607.523 510.622 607.84 510.088C608.214 509.456 608.642 508.984 609.122 508.677C609.601 508.371 610.106 508.178 610.632 508.108C611.157 508.038 611.683 508.067 612.206 508.204C612.732 508.34 613.225 508.546 613.69 508.817L614.174 509.098L610.786 514.829L610.79 514.825ZM612.226 509.905C611.915 509.739 611.609 509.625 611.306 509.562C611.003 509.5 610.707 509.5 610.417 509.561C610.127 509.621 609.857 509.751 609.596 509.945C609.34 510.139 609.108 510.408 608.906 510.75C608.712 511.081 608.594 511.424 608.557 511.771C608.52 512.123 608.546 512.452 608.635 512.758C608.724 513.068 608.86 513.345 609.047 513.595C609.234 513.846 609.447 514.039 609.689 514.184L612.222 509.901L612.226 509.905Z"
                fill="white"
              />
              <path
                d="M607.244 501.636L607.985 500.244L613.434 503.098L613.448 503.065C613.197 502.619 613.083 502.103 613.106 501.521C613.129 500.94 613.282 500.388 613.56 499.86C613.86 499.294 614.233 498.833 614.675 498.476C615.116 498.119 615.598 497.872 616.117 497.731C616.635 497.589 617.178 497.554 617.746 497.625C618.31 497.697 618.87 497.876 619.419 498.166C619.968 498.453 620.432 498.807 620.806 499.229C621.181 499.65 621.457 500.111 621.639 500.621C621.821 501.13 621.888 501.668 621.839 502.226C621.791 502.789 621.619 503.353 621.319 503.919C621.055 504.415 620.689 504.84 620.224 505.194C619.754 505.548 619.254 505.756 618.716 505.819L618.702 505.851L619.759 506.407L619.017 507.798L607.248 501.636L607.244 501.636ZM619.915 503.406C620.112 503.036 620.224 502.665 620.256 502.298C620.285 501.931 620.242 501.587 620.128 501.261C620.014 500.94 619.829 500.638 619.573 500.359C619.317 500.079 619.002 499.842 618.629 499.647C618.257 499.453 617.879 499.329 617.504 499.274C617.125 499.222 616.774 499.241 616.441 499.331C616.109 499.421 615.799 499.581 615.508 499.812C615.218 500.043 614.978 500.344 614.778 500.714C614.577 501.085 614.469 501.455 614.437 501.822C614.408 502.189 614.451 502.533 614.565 502.859C614.679 503.184 614.864 503.482 615.12 503.761C615.376 504.041 615.691 504.278 616.064 504.473C616.436 504.668 616.814 504.791 617.189 504.846C617.568 504.898 617.919 504.879 618.252 504.789C618.584 504.699 618.894 504.539 619.185 504.308C619.475 504.077 619.715 503.776 619.915 503.406Z"
                fill="white"
              />
              <path
                d="M620.792 495.416C620.24 495.157 619.773 494.815 619.39 494.386C619.011 493.957 618.723 493.48 618.532 492.951C618.341 492.423 618.258 491.87 618.277 491.288C618.3 490.707 618.444 490.136 618.709 489.576C618.973 489.016 619.325 488.544 619.761 488.155C620.197 487.767 620.682 487.476 621.214 487.286C621.747 487.096 622.3 487.009 622.873 487.025C623.446 487.04 624.013 487.179 624.565 487.438C625.117 487.696 625.588 488.038 625.971 488.471C626.358 488.904 626.642 489.381 626.829 489.906C627.015 490.431 627.095 490.984 627.076 491.565C627.053 492.147 626.909 492.718 626.644 493.278C626.38 493.838 626.027 494.31 625.591 494.698C625.155 495.087 624.675 495.377 624.147 495.571C623.622 495.764 623.069 495.852 622.488 495.833C621.906 495.814 621.34 495.675 620.788 495.416L620.792 495.416ZM621.512 493.898C621.896 494.076 622.277 494.183 622.655 494.219C623.033 494.255 623.388 494.22 623.716 494.114C624.044 494.009 624.349 493.837 624.623 493.59C624.901 493.348 625.128 493.036 625.308 492.655C625.488 492.273 625.584 491.899 625.597 491.533C625.609 491.167 625.55 490.823 625.421 490.507C625.291 490.19 625.094 489.896 624.823 489.629C624.555 489.362 624.229 489.142 623.845 488.963C623.461 488.785 623.08 488.678 622.702 488.642C622.324 488.607 621.969 488.642 621.641 488.747C621.313 488.853 621.008 489.025 620.734 489.271C620.456 489.513 620.229 489.825 620.049 490.207C619.869 490.588 619.773 490.962 619.76 491.328C619.748 491.695 619.807 492.038 619.936 492.355C620.066 492.671 620.263 492.965 620.535 493.232C620.802 493.499 621.128 493.723 621.512 493.898Z"
                fill="white"
              />
              <path
                d="M623.052 483.056C622.844 482.493 622.759 481.9 622.796 481.282C622.834 480.664 622.966 480.09 623.191 479.551C623.428 478.993 623.701 478.54 624.011 478.196C624.32 477.853 624.65 477.6 624.996 477.439C625.341 477.281 625.694 477.202 626.055 477.206C626.416 477.211 626.763 477.284 627.101 477.42L631.032 479.05C631.301 479.162 631.553 479.258 631.785 479.344C632.017 479.43 632.24 479.5 632.456 479.566L631.914 480.857C631.512 480.727 631.113 480.581 630.726 480.419L630.711 480.451C631.074 480.981 631.265 481.509 631.283 482.033C631.306 482.561 631.189 483.115 630.945 483.694C630.796 484.05 630.606 484.369 630.369 484.649C630.137 484.933 629.87 485.152 629.573 485.315C629.275 485.479 628.955 485.568 628.607 485.587C628.26 485.61 627.9 485.542 627.521 485.383C627.024 485.177 626.651 484.895 626.408 484.536C626.164 484.176 626.018 483.761 625.966 483.294C625.914 482.823 625.953 482.316 626.073 481.767C626.198 481.216 626.378 480.652 626.626 480.073L627.073 479.008L626.747 478.875C626.551 478.796 626.341 478.753 626.117 478.747C625.892 478.74 625.666 478.782 625.445 478.867C625.224 478.952 625.022 479.088 624.834 479.275C624.645 479.462 624.488 479.711 624.357 480.023C624.241 480.302 624.163 480.56 624.129 480.792C624.095 481.024 624.08 481.243 624.089 481.45C624.098 481.656 624.126 481.85 624.178 482.034C624.226 482.219 624.274 482.396 624.317 482.565L623.041 483.061L623.052 483.056ZM627.834 480.224C627.688 480.568 627.559 480.928 627.447 481.302C627.335 481.677 627.27 482.038 627.253 482.376C627.235 482.719 627.288 483.023 627.404 483.292C627.52 483.562 627.728 483.76 628.034 483.886C628.478 484.07 628.858 484.053 629.177 483.837C629.497 483.621 629.762 483.263 629.976 482.757C630.144 482.36 630.22 481.991 630.202 481.658C630.187 481.321 630.103 481.022 629.959 480.762C629.816 480.502 629.626 480.275 629.394 480.09C629.162 479.905 628.915 479.757 628.654 479.652L628.165 479.451L627.838 480.224L627.834 480.224Z"
                fill="white"
              />
              <path
                d="M626.011 473.176L626.569 471.705L627.774 472.155L627.785 472.122C627.613 471.947 627.472 471.746 627.366 471.516C627.257 471.29 627.182 471.051 627.14 470.806C627.097 470.557 627.082 470.303 627.107 470.036C627.128 469.769 627.181 469.516 627.275 469.277C627.365 469.039 627.48 468.831 627.621 468.658L629.195 469.323C629.116 469.429 629.044 469.54 628.977 469.65C628.91 469.76 628.855 469.878 628.808 469.999C628.534 470.719 628.529 471.347 628.788 471.881C629.051 472.414 629.61 472.84 630.464 473.161L634.384 474.629L633.826 476.1L626.016 473.18L626.011 473.176Z"
                fill="white"
              />
              <path
                d="M639.182 461.024L638.677 462.516L637.544 462.139L637.533 462.171C637.86 462.599 638.053 463.104 638.118 463.685C638.183 464.267 638.127 464.822 637.945 465.351C637.739 465.96 637.448 466.473 637.07 466.895C636.691 467.317 636.256 467.638 635.761 467.862C635.266 468.085 634.735 468.208 634.171 468.228C633.607 468.252 633.03 468.161 632.442 467.967C631.852 467.77 631.336 467.498 630.892 467.139C630.449 466.784 630.098 466.369 629.846 465.895C629.59 465.425 629.439 464.906 629.395 464.344C629.351 463.781 629.431 463.197 629.633 462.588C629.821 462.023 630.121 461.533 630.527 461.114C630.934 460.695 631.387 460.422 631.89 460.293L631.901 460.26L626.059 458.323L626.564 456.83L639.186 461.019L639.182 461.024ZM636.604 464.7C636.739 464.301 636.791 463.921 636.759 463.552C636.727 463.184 636.629 462.854 636.464 462.551C636.299 462.252 636.067 461.983 635.769 461.75C635.471 461.516 635.122 461.332 634.72 461.198C634.318 461.064 633.931 461.005 633.547 461.013C633.167 461.022 632.817 461.096 632.507 461.241C632.193 461.381 631.913 461.592 631.665 461.865C631.416 462.138 631.226 462.476 631.091 462.872C630.956 463.271 630.904 463.651 630.935 464.019C630.967 464.388 631.066 464.722 631.23 465.021C631.395 465.32 631.627 465.588 631.926 465.822C632.224 466.056 632.572 466.24 632.974 466.374C633.377 466.508 633.768 466.571 634.148 466.559C634.528 466.55 634.877 466.476 635.187 466.331C635.502 466.191 635.781 465.984 636.03 465.707C636.278 465.434 636.468 465.096 636.604 464.7Z"
                fill="white"
              />
              <path
                d="M635.574 451.269C635.295 451.384 635.042 451.55 634.815 451.77C634.588 451.991 634.416 452.288 634.304 452.671C634.2 453.029 634.186 453.36 634.263 453.667C634.34 453.973 634.524 454.168 634.816 454.251C635.052 454.321 635.267 454.3 635.459 454.188C635.65 454.081 635.82 453.934 635.969 453.753C636.121 453.571 636.253 453.371 636.363 453.148C636.478 452.924 636.574 452.737 636.659 452.578C636.835 452.277 637.019 451.998 637.213 451.744C637.41 451.489 637.626 451.288 637.867 451.131C638.109 450.978 638.377 450.875 638.668 450.834C638.962 450.79 639.296 450.824 639.665 450.931C640.115 451.059 640.476 451.266 640.741 451.549C641.005 451.832 641.195 452.154 641.311 452.515C641.426 452.876 641.471 453.264 641.448 453.674C641.426 454.085 641.359 454.489 641.243 454.88C641.051 455.54 640.778 456.093 640.432 456.529C640.083 456.969 639.581 457.309 638.926 457.543L638.313 456.13C638.634 455.953 638.933 455.742 639.211 455.495C639.489 455.249 639.694 454.906 639.82 454.471C639.876 454.282 639.912 454.082 639.931 453.874C639.95 453.667 639.938 453.476 639.898 453.299C639.859 453.122 639.788 452.97 639.686 452.839C639.585 452.708 639.449 452.614 639.278 452.566C639.051 452.5 638.848 452.517 638.66 452.616C638.472 452.716 638.309 452.85 638.164 453.019C638.023 453.192 637.895 453.38 637.78 453.584C637.664 453.788 637.568 453.967 637.482 454.126C637.307 454.427 637.131 454.709 636.942 454.972C636.757 455.234 636.546 455.454 636.309 455.627C636.076 455.804 635.809 455.919 635.511 455.979C635.213 456.04 634.872 456.014 634.486 455.904C634.069 455.782 633.732 455.594 633.473 455.331C633.213 455.067 633.024 454.765 632.905 454.42C632.786 454.075 632.727 453.708 632.734 453.314C632.737 452.92 632.793 452.54 632.901 452.17C633.059 451.634 633.31 451.146 633.656 450.714C634.002 450.278 634.454 449.984 635.012 449.829L635.566 451.261L635.574 451.269Z"
                fill="white"
              />
            </g>
          </g>
        )}
        {/* End BAV_AUDIENCE_DB Phase */}
        {/* Start SYNAPSE_3 Phase */}
        {selectedPhase === ProcessSchemePhase.SYNAPSE_3 && (
          <g ref={synapsePhase3WrapperRef}>
            <g filter="url(#filter11_d_15_26815)" transform="translate(-102 -114)">
              <path
                d="M473.893 732.848C537.539 730.696 598.875 708.479 649.136 669.373L548.489 540.015C525.505 557.898 497.457 568.057 468.353 569.041L473.893 732.848Z"
                fill="url(#paint34_linear_15_26815)"
              />
            </g>
            <path
              transform="translate(-102 -114)"
              d="M475.191 802.952C555.55 801.58 632.71 771.235 692.476 717.5L643.848 663.415C597.117 705.432 536.784 729.159 473.95 730.231L475.191 802.952Z"
              fill="url(#paint35_linear_15_26815)"
            />
          </g>
        )}
        <g filter="url(#H)" onClick={() => onSelectPhase(ProcessSchemePhase.SYNAPSE_3)}>
          <circle
            cx={482.06}
            cy={669.857}
            r={selectedPhase === ProcessSchemePhase.SYNAPSE_3 ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.SYNAPSE_3 ? 'url(#paint33_linear_295_33899)' : '#fff'}
            transform="rotate(160 482.06 669.857)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {selectedPhase === ProcessSchemePhase.SYNAPSE_3 && (
          <g>
            <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_15_26815)" transform="translate(-102 -114)">
              <path
                d="M518.353 664.764L522.917 663.449C523.486 663.282 524.033 663.198 524.562 663.195C525.09 663.191 525.566 663.268 525.998 663.433C526.429 663.597 526.803 663.859 527.129 664.212C527.452 664.57 527.697 665.023 527.854 665.578C528.072 666.331 528.041 667.007 527.755 667.603C527.474 668.202 527.03 668.707 526.425 669.121L526.438 669.157C526.843 669.069 527.241 669.062 527.64 669.143C528.04 669.224 528.404 669.372 528.744 669.589C529.084 669.806 529.379 670.083 529.638 670.427C529.898 670.771 530.091 671.16 530.221 671.608C530.391 672.2 530.423 672.763 530.309 673.278C530.2 673.797 529.977 674.272 529.644 674.706C529.315 675.136 528.892 675.511 528.381 675.834C527.871 676.157 527.295 676.406 526.652 676.592L522.144 677.895L518.353 664.764ZM521.711 669.699L524.14 668.996C524.47 668.9 524.781 668.776 525.056 668.619C525.336 668.466 525.561 668.278 525.733 668.052C525.911 667.83 526.025 667.575 526.085 667.286C526.141 667.001 526.119 666.678 526.018 666.318C525.867 665.8 525.571 665.396 525.125 665.107C524.682 664.822 524.101 664.78 523.381 664.989L520.581 665.798L521.703 669.693L521.711 669.699ZM523.478 675.82L526.09 675.067C526.362 674.987 526.652 674.874 526.967 674.726C527.277 674.575 527.55 674.377 527.788 674.126C528.023 673.879 528.2 673.584 528.313 673.24C528.43 672.901 528.418 672.492 528.282 672.026C528.056 671.247 527.633 670.736 527.013 670.493C526.393 670.249 525.65 670.255 524.787 670.505L522.156 671.265L523.471 675.829L523.478 675.82Z"
                fill="white"
              />
              <path
                d="M531.798 665.485L533.454 664.953L533.885 666.295L533.921 666.282C533.957 666.016 534.037 665.755 534.16 665.506C534.283 665.257 534.437 665.03 534.619 664.818C534.803 664.614 535.016 664.424 535.259 664.257C535.502 664.09 535.759 663.966 536.03 663.879C536.3 663.792 536.558 663.749 536.801 663.754L537.302 665.561C537.157 665.568 537.009 665.579 536.867 665.601C536.724 665.622 536.583 665.651 536.451 665.694C535.643 665.951 535.099 666.383 534.816 666.975C534.534 667.566 534.544 668.339 534.854 669.293L536.263 673.666L534.611 674.201L531.807 665.491L531.798 665.485Z"
                fill="white"
              />
              <path
                d="M539.941 664.002C540.255 663.414 540.673 662.909 541.206 662.483C541.74 662.056 542.308 661.736 542.912 661.521C543.542 661.299 544.112 661.186 544.625 661.184C545.135 661.18 545.585 661.265 545.97 661.429C546.356 661.592 546.679 661.823 546.939 662.121C547.199 662.419 547.399 662.753 547.529 663.128L549.085 667.536C549.193 667.842 549.296 668.118 549.399 668.367C549.502 668.616 549.607 668.853 549.71 669.075L548.254 669.588C548.062 669.161 547.89 668.734 547.739 668.297L547.703 668.31C547.535 668.999 547.246 669.546 546.829 669.951C546.411 670.355 545.876 670.674 545.221 670.904C544.821 671.05 544.418 671.126 544.019 671.145C543.619 671.163 543.239 671.11 542.887 670.991C542.538 670.867 542.225 670.677 541.955 670.411C541.684 670.146 541.474 669.798 541.326 669.376C541.13 668.819 541.087 668.307 541.204 667.841C541.318 667.379 541.552 666.952 541.899 666.562C542.246 666.171 542.688 665.824 543.232 665.519C543.772 665.211 544.368 664.944 545.024 664.714L546.226 664.293L546.094 663.93C546.014 663.712 545.899 663.507 545.736 663.32C545.574 663.134 545.374 662.98 545.146 662.866C544.913 662.748 544.654 662.683 544.361 662.674C544.071 662.661 543.75 662.717 543.397 662.844C543.081 662.957 542.816 663.082 542.601 663.229C542.385 663.377 542.197 663.525 542.033 663.684C541.869 663.843 541.733 664.009 541.615 664.189C541.5 664.363 541.387 664.534 541.282 664.696L539.94 664.021L539.941 664.002ZM545.775 665.792C545.385 665.932 544.995 666.091 544.606 666.277C544.216 666.463 543.872 666.676 543.583 666.915C543.293 667.155 543.077 667.422 542.941 667.715C542.805 668.008 542.796 668.327 542.921 668.665C543.096 669.16 543.387 669.461 543.801 669.559C544.211 669.662 544.702 669.61 545.27 669.409C545.718 669.253 546.073 669.041 546.335 668.775C546.595 668.513 546.781 668.224 546.891 667.912C547.001 667.6 547.044 667.279 547.025 666.952C547.006 666.625 546.943 666.313 546.842 666.025L546.651 665.479L545.778 665.788L545.775 665.792Z"
                fill="white"
              />
              <path
                d="M549.895 659.244L551.513 658.611L552.027 659.921L552.063 659.908C552.09 659.375 552.296 658.868 552.691 658.386C553.083 657.908 553.613 657.54 554.269 657.283C554.678 657.125 555.093 657.031 555.52 657.012C555.947 656.993 556.351 657.058 556.739 657.21C557.123 657.367 557.479 657.616 557.809 657.965C558.138 658.313 558.414 658.77 558.634 659.331L560.775 664.816L559.157 665.449L557.191 660.413C557.035 660.019 556.849 659.702 556.633 659.463C556.416 659.223 556.183 659.052 555.933 658.948C555.683 658.844 555.431 658.798 555.165 658.815C554.902 658.828 554.642 658.883 554.389 658.983C554.052 659.115 553.764 659.289 553.517 659.507C553.27 659.724 553.085 659.994 552.962 660.316C552.838 660.638 552.785 660.999 552.808 661.402C552.828 661.809 552.935 662.261 553.128 662.749L554.838 667.137L553.22 667.771L549.892 659.248L549.895 659.244Z"
                fill="white"
              />
              <path
                d="M572.859 659.688L571.265 660.379L570.745 659.177L570.709 659.191C570.594 659.772 570.322 660.304 569.896 660.783C569.463 661.263 568.97 661.626 568.401 661.873C567.75 662.153 567.112 662.296 566.491 662.298C565.87 662.3 565.284 662.182 564.737 661.939C564.19 661.696 563.701 661.357 563.273 660.908C562.841 660.463 562.49 659.926 562.219 659.3C561.947 658.674 561.795 658.049 561.769 657.424C561.735 656.8 561.822 656.211 562.021 655.652C562.224 655.096 562.535 654.592 562.968 654.139C563.399 653.69 563.937 653.32 564.589 653.041C565.193 652.78 565.812 652.663 566.453 652.689C567.093 652.714 567.648 652.883 568.122 653.198L568.158 653.185L565.478 646.984L567.072 646.293L572.859 659.688ZM567.946 660.293C568.375 660.109 568.722 659.872 569.003 659.573C569.28 659.277 569.487 658.951 569.61 658.594C569.736 658.242 569.789 657.854 569.763 657.436C569.738 657.018 569.632 656.6 569.448 656.172C569.264 655.744 569.03 655.384 568.742 655.079C568.458 654.777 568.139 654.549 567.794 654.395C567.448 654.24 567.069 654.167 566.662 654.168C566.256 654.168 565.841 654.262 565.415 654.442C564.987 654.626 564.636 654.867 564.359 655.162C564.082 655.458 563.874 655.784 563.752 656.141C563.63 656.497 563.578 656.884 563.598 657.299C563.624 657.717 563.729 658.134 563.913 658.563C564.097 658.991 564.332 659.351 564.617 659.66C564.905 659.965 565.219 660.19 565.565 660.345C565.91 660.499 566.29 660.572 566.696 660.571C567.103 660.571 567.518 660.477 567.943 660.298L567.946 660.293Z"
                fill="white"
              />
              <path
                d="M502.666 702.682C502.532 702.024 502.527 701.388 502.66 700.773C502.788 700.155 503.025 699.59 503.364 699.074C503.704 698.559 504.14 698.12 504.671 697.752C505.198 697.388 505.797 697.136 506.466 696.996C507.131 696.86 507.782 696.853 508.415 696.98C509.048 697.106 509.621 697.341 510.136 697.681C510.652 698.02 511.094 698.452 511.455 698.964C511.818 699.485 512.068 700.068 512.198 700.723C512.333 701.381 512.337 702.017 512.21 702.643C512.083 703.268 511.846 703.833 511.502 704.346C511.159 704.858 510.726 705.292 510.195 705.661C509.667 706.025 509.069 706.277 508.4 706.417C507.731 706.557 507.083 706.559 506.45 706.433C505.818 706.307 505.249 706.075 504.73 705.74C504.216 705.407 503.774 704.976 503.411 704.456C503.049 703.936 502.798 703.345 502.663 702.686L502.666 702.682ZM504.482 702.314C504.574 702.768 504.728 703.174 504.946 703.528C505.165 703.882 505.424 704.173 505.737 704.39C506.047 704.611 506.396 704.762 506.795 704.843C507.195 704.924 507.617 704.921 508.074 704.825C508.528 704.733 508.921 704.569 509.253 704.335C509.586 704.1 509.85 703.822 510.047 703.501C510.244 703.179 510.371 702.807 510.432 702.399C510.491 701.984 510.476 701.553 510.384 701.099C510.292 700.645 510.135 700.243 509.919 699.884C509.7 699.53 509.438 699.244 509.129 699.023C508.823 698.805 508.47 698.651 508.07 698.57C507.671 698.489 507.249 698.492 506.792 698.588C506.338 698.68 505.945 698.843 505.612 699.078C505.28 699.312 505.015 699.59 504.819 699.912C504.622 700.233 504.494 700.605 504.434 701.014C504.374 701.429 504.39 701.86 504.482 702.314Z"
                fill="white"
              />
              <path
                d="M514.371 695.537L516.058 695.128L516.388 696.496L516.426 696.491C516.522 695.962 516.803 695.49 517.253 695.069C517.707 694.651 518.279 694.353 518.967 694.187C519.389 694.084 519.821 694.049 520.245 694.088C520.672 694.123 521.063 694.244 521.424 694.45C521.785 694.656 522.102 694.949 522.387 695.339C522.665 695.73 522.878 696.219 523.02 696.804L524.408 702.526L522.721 702.936L521.447 697.683C521.346 697.269 521.207 696.934 521.02 696.663C520.837 696.395 520.626 696.193 520.399 696.059C520.167 695.922 519.918 695.845 519.657 695.819C519.393 695.798 519.131 695.819 518.865 695.882C518.517 695.966 518.204 696.101 517.929 696.285C517.654 696.469 517.434 696.713 517.268 697.011C517.102 697.308 517.003 697.661 516.969 698.069C516.935 698.477 516.978 698.934 517.106 699.448L518.216 704.027L516.529 704.437L514.38 695.544L514.371 695.537Z"
                fill="white"
              />
              <path
                d="M530.707 686.695L535.268 685.311C536.192 685.033 537.03 684.89 537.792 684.886C538.552 684.885 539.241 684.98 539.857 685.174C540.474 685.368 541.022 685.645 541.501 685.998C541.981 686.351 542.388 686.737 542.732 687.157C543.076 687.576 543.361 688.012 543.595 688.471C543.826 688.927 544.002 689.357 544.129 689.763C544.384 690.603 544.475 691.456 544.404 692.33C544.334 693.203 544.089 694.042 543.669 694.831C543.252 695.623 542.651 696.339 541.871 696.983C541.094 697.623 540.115 698.121 538.946 698.479L534.678 699.772L530.707 686.695ZM535.946 697.569L538.183 696.892C538.935 696.666 539.615 696.348 540.224 695.937C540.834 695.534 541.331 695.048 541.71 694.488C542.089 693.928 542.339 693.299 542.447 692.6C542.556 691.901 542.487 691.144 542.245 690.334C542.12 689.915 541.899 689.447 541.597 688.925C541.292 688.407 540.874 687.953 540.346 687.558C539.815 687.167 539.168 686.897 538.398 686.749C537.627 686.602 536.712 686.687 535.652 687.006L532.994 687.812L535.957 697.564L535.946 697.569Z"
                fill="white"
              />
              <path
                d="M549.465 691C549.594 691.376 549.796 691.69 550.068 691.944C550.341 692.198 550.649 692.385 550.99 692.51C551.336 692.638 551.702 692.701 552.097 692.698C552.491 692.696 552.877 692.633 553.255 692.499C553.765 692.322 554.168 692.046 554.462 691.683C554.759 691.316 554.992 690.881 555.178 690.385L556.748 690.902C556.247 692.397 555.173 693.431 553.529 693.999C552.848 694.237 552.192 694.333 551.561 694.295C550.93 694.256 550.346 694.1 549.824 693.822C549.3 693.549 548.839 693.17 548.44 692.69C548.041 692.21 547.73 691.648 547.505 691.003C547.281 690.358 547.184 689.721 547.222 689.09C547.253 688.46 547.401 687.87 547.652 687.329C547.907 686.784 548.267 686.304 548.732 685.88C549.202 685.46 549.754 685.135 550.4 684.917C551.169 684.651 551.861 684.561 552.484 684.647C553.106 684.732 553.662 684.928 554.15 685.241C554.639 685.554 555.056 685.955 555.395 686.444C555.734 686.933 556.002 687.456 556.195 688.018L556.398 688.6L549.469 691.004L549.465 691ZM554.189 687.893C554.05 687.531 553.875 687.216 553.67 686.952C553.461 686.684 553.217 686.472 552.93 686.32C552.642 686.168 552.326 686.082 551.97 686.06C551.618 686.04 551.232 686.103 550.821 686.246C550.42 686.383 550.075 686.589 549.797 686.858C549.516 687.123 549.304 687.42 549.158 687.745C549.011 688.07 548.928 688.401 548.903 688.743C548.878 689.084 548.918 689.4 549.016 689.692L554.193 687.896L554.189 687.893Z"
                fill="white"
              />
              <path
                d="M557.599 682.646L559.108 682.052L559.632 683.384L559.669 683.37C559.656 683.234 559.689 683.053 559.768 682.811C559.846 682.569 559.97 682.327 560.138 682.072C560.307 681.816 560.527 681.572 560.801 681.334C561.076 681.097 561.399 680.902 561.786 680.747C562.423 680.497 563.005 680.42 563.537 680.512C564.073 680.607 564.58 680.86 565.065 681.277C565.133 680.641 565.364 680.092 565.765 679.629C566.164 679.169 566.623 678.835 567.137 678.634C567.795 678.373 568.378 678.269 568.88 678.319C569.382 678.369 569.828 678.524 570.204 678.781C570.581 679.038 570.905 679.377 571.174 679.788C571.441 680.204 571.669 680.645 571.861 681.126L573.819 686.083L572.205 686.719L570.334 681.979C570.204 681.658 570.062 681.353 569.904 681.07C569.746 680.788 569.554 680.56 569.332 680.383C569.109 680.206 568.855 680.098 568.561 680.055C568.267 680.012 567.923 680.071 567.529 680.227C566.751 680.533 566.287 680.991 566.142 681.604C565.998 682.217 566.07 682.896 566.369 683.648L568.204 688.301L566.59 688.938L564.835 684.484C564.674 684.078 564.508 683.716 564.332 683.394C564.161 683.075 563.957 682.818 563.735 682.622C563.514 682.425 563.257 682.303 562.97 682.259C562.684 682.214 562.343 682.27 561.944 682.422C561.655 682.535 561.4 682.701 561.177 682.923C560.954 683.144 560.782 683.404 560.668 683.713C560.554 684.022 560.508 684.374 560.526 684.774C560.545 685.174 560.654 685.614 560.842 686.092L562.583 690.51L560.969 691.146L557.612 682.636L557.599 682.646Z"
                fill="white"
              />
              <path
                d="M574.266 677.117C574.53 676.505 574.912 675.968 575.409 675.501C575.906 675.034 576.448 674.668 577.038 674.41C577.649 674.14 578.212 673.982 578.723 673.939C579.234 673.896 579.686 673.942 580.082 674.073C580.478 674.205 580.82 674.41 581.103 674.685C581.387 674.961 581.611 675.279 581.77 675.642L583.662 679.911C583.791 680.206 583.92 680.474 584.041 680.717C584.162 680.959 584.281 681.186 584.405 681.398L582.993 682.023C582.77 681.612 582.565 681.202 582.378 680.778L582.342 680.791C582.229 681.487 581.982 682.057 581.596 682.492C581.213 682.93 580.704 683.287 580.068 683.572C579.681 683.746 579.287 683.855 578.884 683.905C578.481 683.955 578.106 683.931 577.747 683.84C577.391 683.745 577.062 683.576 576.776 683.332C576.482 683.089 576.252 682.76 576.062 682.347C575.823 681.805 575.742 681.299 575.823 680.827C575.905 680.354 576.103 679.914 576.415 679.498C576.732 679.085 577.146 678.704 577.661 678.357C578.176 678.011 578.749 677.693 579.386 677.416L580.55 676.902L580.397 676.549C580.3 676.338 580.167 676.147 579.991 675.971C579.814 675.794 579.606 675.661 579.365 675.563C579.124 675.466 578.856 675.422 578.57 675.431C578.276 675.441 577.963 675.523 577.621 675.671C577.315 675.806 577.058 675.956 576.856 676.113C576.65 676.275 576.476 676.44 576.329 676.611C576.183 676.783 576.057 676.963 575.955 677.148C575.852 677.332 575.756 677.508 575.663 677.679L574.274 677.116L574.266 677.117ZM580.218 678.448C579.841 678.617 579.467 678.808 579.089 679.022C578.711 679.237 578.388 679.478 578.116 679.738C577.845 679.998 577.65 680.28 577.539 680.585C577.429 680.889 577.442 681.206 577.592 681.536C577.803 682.018 578.121 682.291 578.542 682.362C578.959 682.429 579.444 682.34 579.997 682.094C580.432 681.902 580.772 681.666 581.009 681.381C581.25 681.099 581.411 680.798 581.493 680.479C581.575 680.159 581.599 679.837 581.55 679.515C581.504 679.188 581.42 678.887 581.293 678.607L581.058 678.075L580.21 678.449L580.218 678.448Z"
                fill="white"
              />
              <path
                d="M583.832 671.594L585.393 670.838L586.004 672.107L586.036 672.091C586.019 671.553 586.189 671.032 586.542 670.526C586.895 670.02 587.396 669.61 588.028 669.303C588.421 669.113 588.832 668.989 589.257 668.936C589.683 668.882 590.091 668.916 590.485 669.04C590.88 669.164 591.255 669.387 591.611 669.709C591.967 670.031 592.274 670.464 592.537 671.01L595.1 676.312L593.539 677.068L591.188 672.201C591.004 671.819 590.793 671.517 590.556 671.296C590.319 671.075 590.073 670.921 589.822 670.836C589.564 670.753 589.311 670.726 589.047 670.759C588.784 670.791 588.535 670.867 588.288 670.985C587.967 671.141 587.686 671.341 587.458 671.579C587.229 671.817 587.069 672.099 586.966 672.429C586.866 672.755 586.841 673.123 586.898 673.524C586.948 673.927 587.091 674.366 587.321 674.841L589.373 679.081L587.812 679.837L583.829 671.598L583.832 671.594Z"
                fill="white"
              />
              <path
                d="M606.754 670.249L605.217 671.062L604.607 669.9L604.572 669.921C604.504 670.511 604.273 671.06 603.883 671.571C603.493 672.083 603.025 672.484 602.478 672.775C601.854 673.107 601.23 673.294 600.605 673.347C599.984 673.395 599.39 673.325 598.83 673.126C598.268 672.931 597.753 672.626 597.292 672.213C596.827 671.804 596.435 671.296 596.118 670.696C595.796 670.093 595.6 669.482 595.517 668.862C595.434 668.242 595.474 667.645 595.633 667.077C595.792 666.508 596.068 665.979 596.458 665.494C596.849 665.009 597.359 664.605 597.983 664.272C598.562 663.966 599.177 663.799 599.814 663.775C600.458 663.75 601.026 663.875 601.52 664.153L601.552 664.136L598.4 658.161L599.938 657.348L606.751 670.253L606.754 670.249ZM601.907 671.235C602.319 671.019 602.647 670.755 602.901 670.436C603.156 670.117 603.331 669.78 603.433 669.415C603.534 669.051 603.552 668.665 603.493 668.248C603.438 667.835 603.295 667.423 603.083 667.014C602.867 666.602 602.604 666.262 602.296 665.975C601.989 665.696 601.655 665.49 601.299 665.368C600.942 665.245 600.559 665.196 600.154 665.231C599.749 665.266 599.341 665.385 598.934 665.604C598.522 665.82 598.19 666.089 597.939 666.404C597.685 666.722 597.509 667.06 597.408 667.424C597.306 667.789 597.292 668.178 597.347 668.592C597.403 669.005 597.546 669.417 597.757 669.826C597.973 670.238 598.236 670.577 598.544 670.864C598.852 671.151 599.186 671.35 599.542 671.472C599.898 671.594 600.281 671.643 600.686 671.609C601.091 671.574 601.499 671.454 601.907 671.235Z"
                fill="white"
              />
            </g>
            <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip1_15_26815)" transform="translate(-102 -114)">
              <path
                d="M537.641 750.983L539.36 750.44L549.776 762.553L547.562 763.25L545.063 760.234L538.396 762.338L538.108 766.236L535.893 766.933L537.641 750.983ZM543.85 758.784L539.21 753.18L539.171 753.195L538.55 760.46L543.85 758.784Z"
                fill="white"
              />
              <path
                d="M549.167 752.305L550.928 751.685L551.432 753.114L551.471 753.099C551.503 752.809 551.577 752.529 551.702 752.257C551.827 751.985 551.985 751.736 552.175 751.512C552.369 751.282 552.588 751.077 552.846 750.887C553.101 750.701 553.374 750.558 553.66 750.458C553.945 750.358 554.22 750.301 554.484 750.299L555.077 752.226C554.918 752.239 554.764 752.256 554.611 752.28C554.458 752.305 554.312 752.341 554.167 752.394C553.309 752.698 552.733 753.171 552.446 753.816C552.158 754.461 552.194 755.289 552.553 756.308L554.195 760.965L552.434 761.585L549.169 752.313L549.167 752.305Z"
                fill="white"
              />
              <path
                d="M564.554 749.434C564.103 749.223 563.67 749.095 563.243 749.057C562.823 749.018 562.365 749.092 561.875 749.279C561.397 749.459 561.012 749.708 560.724 750.018C560.436 750.329 560.23 750.685 560.103 751.076C559.982 751.472 559.93 751.889 559.96 752.33C559.99 752.77 560.081 753.206 560.245 753.629C560.405 754.057 560.631 754.433 560.919 754.762C561.208 755.091 561.534 755.347 561.899 755.532C562.267 755.719 562.66 755.825 563.085 755.847C563.509 755.869 563.947 755.794 564.398 755.622C564.887 755.435 565.272 755.187 565.557 754.881C565.842 754.575 566.065 754.196 566.235 753.743L567.948 754.509C567.687 755.187 567.299 755.746 566.782 756.197C566.265 756.648 565.67 757 564.997 757.254C564.285 757.522 563.592 757.655 562.915 757.649C562.237 757.644 561.609 757.51 561.022 757.25C560.435 756.991 559.916 756.617 559.456 756.122C558.999 755.622 558.633 755.017 558.365 754.305C558.097 753.593 557.959 752.897 557.968 752.215C557.977 751.533 558.109 750.896 558.361 750.311C558.621 749.724 558.998 749.2 559.493 748.74C559.992 748.276 560.6 747.905 561.323 747.631C561.996 747.377 562.685 747.249 563.387 747.251C564.094 747.256 564.764 747.419 565.402 747.737L564.543 749.44L564.554 749.434Z"
                fill="white"
              />
              <path
                d="M565.11 740.112L566.83 739.391L569.669 746.183L569.708 746.168C569.722 745.596 569.934 745.046 570.344 744.517C570.755 743.997 571.307 743.585 572.01 743.289C572.447 743.107 572.892 743.002 573.349 742.969C573.806 742.936 574.245 742.997 574.662 743.148C575.08 743.308 575.468 743.567 575.832 743.929C576.193 744.295 576.502 744.773 576.752 745.373L579.192 751.209L577.472 751.929L575.233 746.572C575.054 746.151 574.85 745.813 574.612 745.563C574.373 745.312 574.117 745.135 573.848 745.026C573.583 744.921 573.3 744.88 573.023 744.9C572.737 744.921 572.463 744.987 572.197 745.1C571.841 745.248 571.53 745.443 571.273 745.691C571.015 745.931 570.823 746.226 570.694 746.573C570.568 746.916 570.52 747.307 570.553 747.744C570.587 748.18 570.71 748.659 570.931 749.181L572.885 753.849L571.165 754.57L565.117 740.102L565.11 740.112Z"
                fill="white"
              />
              <path
                d="M577.253 737.466C577.099 737.128 577.091 736.774 577.227 736.418C577.363 736.062 577.603 735.808 577.941 735.654C578.279 735.5 578.633 735.493 578.989 735.629C579.346 735.765 579.599 736.005 579.753 736.343C579.907 736.681 579.915 737.035 579.779 737.391C579.642 737.747 579.403 738.001 579.065 738.155C578.726 738.309 578.373 738.316 578.016 738.18C577.66 738.044 577.407 737.805 577.253 737.466ZM579.09 740.503L580.791 739.74L584.813 748.713L583.112 749.475L579.09 740.503Z"
                fill="white"
              />
              <path
                d="M589.915 737.291L587.496 738.431L589.396 742.464C589.515 742.713 589.637 742.957 589.763 743.197C589.888 743.437 590.039 743.631 590.213 743.784C590.386 743.938 590.584 744.038 590.81 744.08C591.035 744.122 591.311 744.065 591.636 743.909C591.839 743.814 592.036 743.699 592.228 743.561C592.42 743.422 592.584 743.264 592.711 743.078L593.435 744.616C593.286 744.821 593.063 745.023 592.768 745.215C592.47 745.412 592.232 745.545 592.056 745.632C591.408 745.939 590.862 746.085 590.418 746.071C589.979 746.059 589.606 745.946 589.3 745.74C588.994 745.534 588.743 745.261 588.543 744.918C588.343 744.575 588.158 744.221 587.989 743.864L585.802 739.233L583.851 740.156L583.159 738.692L585.11 737.769L583.934 735.273L585.621 734.479L586.798 736.975L589.217 735.836L589.908 737.301L589.915 737.291Z"
                fill="white"
              />
              <path
                d="M596.155 738.665C596.349 739.046 596.61 739.355 596.936 739.582C597.258 739.815 597.617 739.967 598.002 740.053C598.388 740.139 598.79 740.153 599.21 740.093C599.629 740.033 600.03 739.91 600.412 739.716C600.93 739.451 601.32 739.107 601.579 738.669C601.838 738.23 602.031 737.737 602.147 737.182L603.895 737.505C603.575 739.17 602.586 740.424 600.924 741.272C600.233 741.619 599.548 741.821 598.871 741.873C598.19 741.921 597.553 741.839 596.953 741.627C596.357 741.41 595.808 741.078 595.313 740.621C594.818 740.165 594.406 739.613 594.074 738.961C593.741 738.309 593.546 737.643 593.49 736.963C593.433 736.283 593.498 735.641 593.691 735.02C593.88 734.403 594.196 733.836 594.632 733.319C595.069 732.803 595.615 732.38 596.267 732.047C597.041 731.654 597.768 731.453 598.441 731.456C599.114 731.458 599.733 731.584 600.304 731.847C600.875 732.109 601.371 732.474 601.807 732.945C602.242 733.415 602.598 733.935 602.888 734.498L603.186 735.09L596.159 738.66L596.155 738.665ZM600.733 734.67C600.533 734.307 600.302 733.997 600.038 733.743C599.773 733.489 599.483 733.301 599.158 733.18C598.833 733.059 598.483 733.013 598.1 733.042C597.721 733.075 597.32 733.197 596.9 733.406C596.496 733.612 596.16 733.881 595.898 734.204C595.637 734.527 595.45 734.875 595.344 735.239C595.235 735.607 595.191 735.973 595.217 736.341C595.244 736.708 595.331 737.04 595.478 737.338L600.733 734.67Z"
                fill="white"
              />
              <path
                d="M610.986 727.453C610.512 727.303 610.06 727.24 609.634 727.259C609.208 727.279 608.768 727.416 608.31 727.668C607.863 727.914 607.514 728.21 607.27 728.559C607.02 728.905 606.871 729.28 606.801 729.692C606.735 730.098 606.742 730.522 606.828 730.954C606.915 731.386 607.069 731.802 607.291 732.204C607.513 732.605 607.787 732.945 608.114 733.23C608.441 733.516 608.801 733.726 609.189 733.857C609.578 733.988 609.982 734.038 610.406 734.002C610.829 733.966 611.253 733.831 611.674 733.602C612.132 733.35 612.484 733.05 612.722 732.71C612.96 732.37 613.133 731.962 613.238 731.491L615.04 732.016C614.874 732.72 614.568 733.331 614.116 733.85C613.667 734.365 613.122 734.795 612.497 735.14C611.83 735.504 611.161 735.733 610.489 735.817C609.818 735.901 609.175 735.856 608.556 735.68C607.942 735.507 607.373 735.203 606.853 734.772C606.333 734.34 605.89 733.793 605.526 733.126C605.161 732.458 604.933 731.79 604.848 731.11C604.759 730.435 604.802 729.785 604.975 729.17C605.148 728.556 605.447 727.984 605.877 727.456C606.308 726.928 606.858 726.48 607.54 726.105C608.173 725.759 608.835 725.54 609.534 725.447C610.233 725.355 610.922 725.425 611.598 725.649L610.978 727.454L610.986 727.453Z"
                fill="white"
              />
              <path
                d="M618.59 721.911L616.283 723.26L618.529 727.109C618.67 727.346 618.812 727.583 618.959 727.803C619.107 728.031 619.273 728.214 619.46 728.349C619.647 728.484 619.855 728.57 620.077 728.587C620.303 728.609 620.575 728.527 620.883 728.345C621.076 728.236 621.26 728.098 621.442 727.945C621.623 727.792 621.772 727.615 621.881 727.424L622.739 728.895C622.609 729.114 622.409 729.332 622.128 729.555C621.85 729.773 621.623 729.93 621.458 730.031C620.836 730.392 620.306 730.585 619.865 730.615C619.423 730.637 619.043 730.563 618.72 730.38C618.398 730.197 618.122 729.948 617.896 729.622C617.666 729.3 617.455 728.963 617.25 728.616L614.667 724.19L612.804 725.276L611.987 723.878L613.85 722.791L612.457 720.409L614.066 719.467L615.46 721.849L617.767 720.501L618.584 721.9L618.59 721.911Z"
                fill="white"
              />
            </g>
          </g>
        )}
        {/* End SYNAPSE_3 Phase */}
        {/* Start CHOREOGRAPH_SYNAPSE Phase */}
        {selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && (
          <g ref={choreographSynapsePhaseWrapperRef}>
            <g filter="url(#filter11_d_15_27363)" transform="translate(-16 -30)">
              <path
                d="M214.242 587.903C263.217 628.609 323.806 652.79 387.349 656.993L398.165 493.449C369.108 491.528 341.402 480.47 319.006 461.856L214.242 587.903Z"
                fill="url(#paint34_linear_15_27363)"
              />
            </g>
            <g filter="url(#filter12_d_15_27363)" transform="translate(-16 -30)">
              <path
                d="M210.662 592.212C260.545 633.672 322.258 658.302 386.98 662.582L390.641 607.228C337.591 603.72 287.008 583.531 246.121 549.549L210.662 592.212Z"
                fill="url(#paint35_linear_15_27363)"
              />
            </g>
            <path
              transform="translate(-16 -30)"
              d="M168.606 641.134C229.546 693.534 307.36 722.164 387.729 721.757L387.392 655.115C323.082 655.441 260.818 632.532 212.055 590.603L168.606 641.134Z"
              fill="url(#paint36_linear_15_27363)"
            />
          </g>
        )}
        <g filter="url(#I)" onClick={() => onSelectPhase(ProcessSchemePhase.CHOREOGRAPH_SYNAPSE)}>
          <circle
            cx={253.593}
            cy={669.857}
            r={selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE ? 'url(#paint35_linear_295_34492)' : '#fff'}
            transform="rotate(-160 253.593 669.857)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && (
          <g>
            <g clipPath="url(#clip1_15_27363)" transform="translate(-16 -30)">
              <path
                d="M251.206 645.705L252.813 646.523L251.745 662.463L249.677 661.407L250.011 657.504L243.782 654.33L240.843 656.906L238.775 655.85L251.206 645.705ZM250.162 655.62L250.784 648.372L250.746 648.355L245.209 653.099L250.162 655.62Z"
                fill="white"
              />
              <path
                d="M258.497 654.73L260.187 655.523L259.544 656.895L259.582 656.912C259.808 656.727 260.057 656.579 260.337 656.473C260.617 656.367 260.904 656.3 261.198 656.274C261.497 656.246 261.796 656.253 262.114 656.298C262.425 656.345 262.721 656.434 262.994 656.563C263.268 656.692 263.504 656.844 263.693 657.028L262.765 658.817C262.643 658.716 262.521 658.619 262.395 658.53C262.269 658.44 262.139 658.363 261.999 658.299C261.173 657.914 260.432 657.848 259.774 658.106C259.117 658.364 258.561 658.979 258.103 659.958L256.008 664.43L254.318 663.637L258.492 654.736L258.497 654.73Z"
                fill="white"
              />
              <path
                d="M271.479 663.474C271.306 663.006 271.087 662.611 270.809 662.285C270.538 661.963 270.159 661.694 269.679 661.484C269.211 661.277 268.763 661.184 268.339 661.204C267.916 661.224 267.52 661.332 267.155 661.523C266.791 661.719 266.461 661.98 266.174 662.316C265.886 662.651 265.646 663.025 265.466 663.442C265.28 663.859 265.177 664.286 265.152 664.723C265.128 665.159 265.18 665.571 265.311 665.958C265.442 666.351 265.648 666.701 265.935 667.015C266.222 667.328 266.587 667.581 267.029 667.775C267.509 667.985 267.958 668.078 268.375 668.059C268.793 668.041 269.218 667.927 269.656 667.724L270.34 669.472C269.678 669.772 269.01 669.897 268.325 669.856C267.64 669.815 266.969 669.649 266.312 669.358C265.616 669.05 265.029 668.66 264.55 668.18C264.071 667.701 263.716 667.165 263.48 666.568C263.244 665.972 263.136 665.341 263.155 664.666C263.18 663.989 263.343 663.301 263.651 662.606C263.959 661.91 264.349 661.318 264.834 660.838C265.319 660.357 265.86 659.996 266.45 659.755C267.047 659.519 267.683 659.411 268.358 659.43C269.039 659.448 269.733 659.61 270.441 659.922C271.098 660.213 271.68 660.605 272.179 661.098C272.679 661.597 273.042 662.184 273.274 662.858L271.467 663.47L271.479 663.474Z"
                fill="white"
              />
              <path
                d="M278.413 657.217L280.145 657.909L277.406 664.742L277.444 664.758C277.855 664.361 278.392 664.117 279.055 664.028C279.713 663.945 280.395 664.039 281.104 664.321C281.543 664.497 281.934 664.735 282.283 665.032C282.632 665.329 282.902 665.68 283.093 666.08C283.28 666.486 283.375 666.944 283.381 667.457C283.381 667.971 283.266 668.529 283.024 669.131L280.671 675.003L278.939 674.311L281.1 668.921C281.267 668.496 281.359 668.112 281.365 667.766C281.37 667.42 281.312 667.114 281.196 666.848C281.082 666.587 280.908 666.359 280.696 666.179C280.478 665.994 280.237 665.848 279.967 665.742C279.61 665.599 279.251 665.52 278.894 665.516C278.542 665.506 278.198 665.582 277.863 665.739C277.533 665.895 277.224 666.14 276.942 666.475C276.66 666.809 276.412 667.237 276.203 667.764L274.322 672.462L272.591 671.77L278.425 657.215L278.413 657.217Z"
                fill="white"
              />
              <path
                d="M288.925 663.846C289.052 663.497 289.295 663.239 289.642 663.081C289.989 662.922 290.337 662.909 290.686 663.037C291.035 663.164 291.293 663.407 291.452 663.754C291.61 664.101 291.623 664.449 291.495 664.798C291.368 665.147 291.125 665.405 290.778 665.564C290.431 665.722 290.083 665.735 289.734 665.607C289.385 665.48 289.127 665.237 288.969 664.89C288.81 664.543 288.797 664.195 288.925 663.846ZM288.106 667.3L289.853 667.948L286.428 677.165L284.681 676.516L288.106 667.3Z"
                fill="white"
              />
              <path
                d="M298.075 672.601L295.551 671.716L294.077 675.924C293.988 676.185 293.903 676.445 293.825 676.704C293.746 676.963 293.718 677.207 293.734 677.438C293.75 677.669 293.821 677.879 293.952 678.067C294.084 678.255 294.32 678.408 294.661 678.525C294.873 678.6 295.093 678.656 295.327 678.692C295.561 678.728 295.789 678.73 296.01 678.686L295.448 680.29C295.198 680.333 294.898 680.32 294.552 680.25C294.202 680.181 293.938 680.11 293.752 680.048C293.075 679.813 292.583 679.534 292.277 679.212C291.972 678.896 291.785 678.554 291.712 678.192C291.638 677.831 291.651 677.461 291.749 677.076C291.847 676.691 291.963 676.309 292.092 675.936L293.78 671.101L291.742 670.39L292.276 668.862L294.314 669.572L295.226 666.967L296.985 667.585L296.074 670.189L298.598 671.074L298.064 672.602L298.075 672.601Z"
                fill="white"
              />
              <path
                d="M301.56 677.956C301.431 678.364 301.401 678.766 301.473 679.157C301.54 679.549 301.689 679.909 301.904 680.241C302.118 680.573 302.396 680.864 302.737 681.116C303.078 681.367 303.45 681.561 303.857 681.69C304.413 681.864 304.932 681.893 305.424 681.762C305.916 681.631 306.4 681.414 306.872 681.1L307.892 682.557C306.496 683.52 304.911 683.72 303.132 683.158C302.396 682.921 301.766 682.585 301.247 682.147C300.727 681.704 300.331 681.199 300.052 680.627C299.779 680.054 299.621 679.432 299.588 678.76C299.555 678.088 299.649 677.405 299.869 676.707C300.089 676.009 300.417 675.398 300.853 674.874C301.29 674.349 301.787 673.937 302.359 673.629C302.926 673.322 303.55 673.139 304.223 673.077C304.896 673.015 305.582 673.097 306.28 673.316C307.108 673.578 307.767 673.945 308.245 674.419C308.724 674.893 309.076 675.417 309.299 676.004C309.522 676.592 309.62 677.2 309.601 677.841C309.581 678.481 309.471 679.102 309.282 679.707L309.08 680.338L301.566 677.955L301.56 677.956ZM307.625 678.318C307.738 677.919 307.789 677.536 307.779 677.169C307.769 676.803 307.694 676.465 307.547 676.151C307.4 675.837 307.183 675.559 306.89 675.311C306.597 675.068 306.225 674.875 305.779 674.729C305.347 674.592 304.918 674.548 304.506 674.595C304.093 674.642 303.715 674.759 303.384 674.944C303.048 675.13 302.76 675.361 302.521 675.641C302.283 675.921 302.112 676.219 302.008 676.535L307.625 678.318Z"
                fill="white"
              />
              <path
                d="M319.996 680.363C319.763 679.924 319.485 679.562 319.168 679.277C318.85 678.992 318.44 678.782 317.937 678.64C317.446 678.501 316.99 678.468 316.571 678.545C316.15 678.617 315.781 678.779 315.443 679.024C315.11 679.268 314.818 679.575 314.577 679.943C314.336 680.312 314.153 680.717 314.03 681.159C313.907 681.601 313.864 682.035 313.897 682.467C313.93 682.9 314.039 683.302 314.224 683.668C314.41 684.034 314.663 684.353 314.99 684.625C315.316 684.896 315.713 685.097 316.174 685.229C316.677 685.371 317.139 685.404 317.547 685.328C317.955 685.253 318.364 685.083 318.77 684.821L319.686 686.459C319.074 686.844 318.428 687.066 317.741 687.118C317.06 687.17 316.37 687.095 315.682 686.903C314.951 686.694 314.314 686.388 313.777 685.977C313.239 685.566 312.812 685.084 312.494 684.524C312.178 683.971 311.985 683.355 311.917 682.682C311.849 682.01 311.917 681.309 312.125 680.578C312.333 679.847 312.64 679.21 313.055 678.666C313.465 678.123 313.952 677.689 314.506 677.372C315.06 677.056 315.675 676.857 316.352 676.783C317.029 676.708 317.736 676.774 318.485 676.986C319.179 677.183 319.804 677.491 320.367 677.915C320.931 678.339 321.372 678.872 321.697 679.506L319.989 680.358L319.996 680.363Z"
                fill="white"
              />
              <path
                d="M329.303 681.744L326.712 681.088L325.614 685.407C325.549 685.675 325.484 685.944 325.434 686.203C325.38 686.47 325.37 686.717 325.409 686.944C325.448 687.171 325.535 687.378 325.681 687.547C325.828 687.72 326.078 687.853 326.426 687.94C326.64 687.997 326.868 688.028 327.105 688.046C327.342 688.064 327.572 688.042 327.784 687.983L327.364 689.632C327.118 689.698 326.822 689.713 326.465 689.675C326.113 689.635 325.842 689.588 325.654 689.544C324.957 689.365 324.443 689.131 324.108 688.844C323.777 688.549 323.559 688.23 323.457 687.873C323.356 687.517 323.333 687.146 323.401 686.755C323.463 686.364 323.549 685.976 323.646 685.585L324.908 680.618L322.818 680.086L323.217 678.516L325.306 679.048L325.984 676.372L327.792 676.83L327.114 679.505L329.705 680.162L329.306 681.732L329.303 681.744Z"
                fill="white"
              />
            </g>
            <g clipPath="url(#clip0_15_27363)" transform="translate(-16 -30)">
              <path
                d="M292.013 590.38L295.48 592.183C295.914 592.406 296.287 592.667 296.605 592.964C296.922 593.262 297.162 593.578 297.326 593.921C297.49 594.264 297.565 594.631 297.559 595.027C297.549 595.423 297.438 595.833 297.217 596.253C296.92 596.826 296.518 597.211 296.01 597.404C295.503 597.602 294.951 597.651 294.356 597.555L294.343 597.584C294.634 597.761 294.875 597.982 295.068 598.258C295.26 598.533 295.393 598.827 295.473 599.149C295.553 599.471 295.571 599.804 295.531 600.156C295.491 600.508 295.386 600.85 295.209 601.191C294.975 601.64 294.675 601.994 294.315 602.237C293.955 602.485 293.553 602.641 293.108 602.711C292.668 602.781 292.203 602.765 291.716 602.668C291.228 602.571 290.743 602.393 290.255 602.139L286.828 600.36L292.013 590.38ZM291.217 595.227L293.063 596.185C293.315 596.315 293.571 596.417 293.824 596.48C294.078 596.547 294.318 596.562 294.549 596.526C294.781 596.493 294.994 596.406 295.193 596.268C295.389 596.13 295.558 595.925 295.703 595.653C295.906 595.258 295.959 594.85 295.856 594.424C295.754 594.003 295.431 593.648 294.883 593.365L292.755 592.26L291.215 595.218L291.217 595.227ZM288.799 599.879L290.785 600.911C290.992 601.017 291.229 601.114 291.5 601.205C291.771 601.291 292.046 601.327 292.331 601.313C292.61 601.299 292.884 601.223 293.146 601.082C293.408 600.946 293.633 600.695 293.816 600.34C294.123 599.748 294.16 599.203 293.929 598.707C293.697 598.21 293.251 597.792 292.594 597.452L290.594 596.413L288.791 599.881L288.799 599.879Z"
                fill="white"
              />
              <path
                d="M299.621 598.433L300.911 599.056L300.406 600.1L300.435 600.113C300.608 599.974 300.803 599.864 301.018 599.785C301.233 599.706 301.453 599.658 301.682 599.636C301.907 599.618 302.142 599.626 302.381 599.664C302.621 599.702 302.845 599.774 303.055 599.875C303.266 599.977 303.445 600.097 303.586 600.238L302.86 601.6C302.77 601.522 302.676 601.444 302.578 601.376C302.481 601.309 302.38 601.246 302.278 601.197C301.65 600.892 301.08 600.841 300.577 601.033C300.073 601.226 299.641 601.693 299.285 602.437L297.645 605.844L296.357 605.226L299.623 598.442L299.621 598.433Z"
                fill="white"
              />
              <path
                d="M305.318 602.167C305.839 601.993 306.374 601.929 306.934 601.978C307.494 602.026 308.015 602.158 308.497 602.372C308.998 602.597 309.402 602.852 309.709 603.142C310.016 603.429 310.236 603.734 310.373 604.051C310.51 604.367 310.571 604.688 310.558 605.013C310.544 605.338 310.474 605.651 310.339 605.948L308.767 609.459C308.658 609.703 308.563 609.926 308.483 610.133C308.404 610.34 308.332 610.541 308.267 610.731L307.108 610.211C307.236 609.848 307.375 609.496 307.533 609.15L307.504 609.137C307.013 609.453 306.531 609.615 306.052 609.619C305.574 609.624 305.074 609.511 304.553 609.277C304.232 609.136 303.949 608.954 303.7 608.738C303.451 608.522 303.254 608.275 303.112 608.005C302.974 607.733 302.895 607.442 302.885 607.131C302.874 606.819 302.946 606.492 303.097 606.156C303.296 605.713 303.56 605.384 303.894 605.172C304.224 604.961 304.606 604.839 305.034 604.803C305.463 604.767 305.923 604.811 306.421 604.937C306.918 605.06 307.425 605.239 307.946 605.473L308.901 605.904L309.028 605.612C309.105 605.437 309.152 605.249 309.161 605.046C309.169 604.843 309.137 604.638 309.066 604.44C308.994 604.238 308.876 604.053 308.707 603.881C308.542 603.709 308.318 603.56 308.036 603.436C307.783 603.324 307.555 603.248 307.342 603.214C307.13 603.18 306.934 603.161 306.746 603.163C306.558 603.165 306.383 603.187 306.211 603.227C306.043 603.266 305.879 603.304 305.724 603.34L305.307 602.178L305.318 602.167ZM307.782 606.542C307.471 606.405 307.148 606.278 306.81 606.168C306.472 606.058 306.146 605.99 305.838 605.969C305.529 605.948 305.249 605.984 305.002 606.082C304.755 606.179 304.568 606.365 304.451 606.637C304.274 607.031 304.278 607.376 304.469 607.669C304.655 607.963 304.977 608.211 305.43 608.412C305.786 608.573 306.117 608.649 306.425 608.639C306.728 608.63 307.003 608.563 307.245 608.439C307.488 608.315 307.696 608.148 307.87 607.943C308.044 607.737 308.184 607.515 308.286 607.286L308.482 606.852L307.786 606.541L307.782 606.542Z"
                fill="white"
              />
              <path
                d="M313.952 604.973L315.276 605.513L314.84 606.586L314.869 606.599C315.187 606.296 315.598 606.111 316.106 606.047C316.611 605.984 317.136 606.065 317.672 606.284C318.006 606.422 318.307 606.601 318.572 606.832C318.837 607.063 319.042 607.331 319.187 607.641C319.327 607.953 319.398 608.303 319.397 608.698C319.395 609.092 319.301 609.521 319.114 609.98L317.281 614.466L315.957 613.926L317.64 609.808C317.771 609.484 317.84 609.19 317.846 608.924C317.853 608.658 317.811 608.424 317.721 608.22C317.631 608.017 317.506 607.846 317.338 607.706C317.174 607.565 316.988 607.45 316.78 607.366C316.505 607.253 316.234 607.194 315.963 607.184C315.693 607.174 315.429 607.23 315.173 607.351C314.917 607.473 314.681 607.658 314.466 607.912C314.247 608.166 314.054 608.496 313.893 608.897L312.424 612.483L311.1 611.944L313.948 604.974L313.952 604.973Z"
                fill="white"
              />
              <path
                d="M327.395 618.26L326.053 617.768L326.424 616.756L326.395 616.744C325.997 617.025 325.533 617.188 325.007 617.233C324.477 617.273 323.977 617.21 323.497 617.035C322.95 616.832 322.489 616.556 322.117 616.205C321.746 615.854 321.463 615.452 321.275 614.997C321.086 614.542 320.988 614.062 320.986 613.551C320.981 613.041 321.077 612.522 321.27 611.995C321.463 611.467 321.726 611.008 322.065 610.621C322.399 610.23 322.785 609.928 323.22 609.707C323.657 609.491 324.128 609.366 324.643 609.342C325.155 609.318 325.685 609.403 326.232 609.606C326.741 609.794 327.176 610.075 327.544 610.453C327.911 610.831 328.146 611.247 328.25 611.703L328.279 611.716L330.197 606.499L331.54 606.99L327.395 618.26ZM324.122 615.835C324.482 615.968 324.823 616.024 325.161 616.004C325.493 615.985 325.802 615.908 326.077 615.765C326.353 615.626 326.604 615.425 326.826 615.161C327.048 614.898 327.222 614.589 327.355 614.229C327.488 613.869 327.552 613.522 327.553 613.176C327.555 612.835 327.494 612.519 327.376 612.231C327.257 611.943 327.072 611.684 326.83 611.454C326.587 611.224 326.286 611.044 325.931 610.91C325.571 610.777 325.225 610.722 324.892 610.741C324.559 610.76 324.251 610.837 323.976 610.98C323.701 611.123 323.45 611.325 323.227 611.584C323.005 611.847 322.831 612.156 322.698 612.516C322.565 612.876 322.501 613.223 322.496 613.569C322.494 613.915 322.554 614.227 322.673 614.515C322.791 614.803 322.976 615.062 323.219 615.292C323.461 615.522 323.762 615.702 324.118 615.836L324.122 615.835Z"
                fill="white"
              />
              <path
                d="M261.157 604.097C261.45 603.628 261.808 603.246 262.235 602.955C262.662 602.659 263.124 602.456 263.619 602.341C264.114 602.226 264.622 602.212 265.148 602.293C265.669 602.375 266.168 602.564 266.647 602.86C267.121 603.157 267.513 603.522 267.819 603.956C268.124 604.39 268.333 604.855 268.448 605.35C268.563 605.845 268.581 606.353 268.506 606.863C268.428 607.379 268.246 607.869 267.952 608.333C267.659 608.802 267.301 609.184 266.87 609.485C266.44 609.786 265.979 609.989 265.483 610.099C264.987 610.21 264.483 610.223 263.958 610.142C263.437 610.06 262.937 609.871 262.458 609.575C261.98 609.279 261.592 608.913 261.286 608.479C260.981 608.045 260.773 607.585 260.654 607.09C260.535 606.601 260.517 606.093 260.595 605.577C260.674 605.061 260.859 604.566 261.152 604.097L261.157 604.097ZM262.448 604.907C262.246 605.23 262.107 605.559 262.037 605.894C261.966 606.229 261.956 606.549 262.02 606.856C262.079 607.164 262.202 607.451 262.394 607.727C262.586 608.002 262.839 608.239 263.166 608.441C263.489 608.643 263.816 608.769 264.148 608.817C264.479 608.866 264.795 608.85 265.094 608.77C265.393 608.69 265.681 608.54 265.948 608.331C266.219 608.117 266.454 607.851 266.657 607.529C266.859 607.206 266.994 606.877 267.068 606.541C267.139 606.206 267.145 605.887 267.085 605.579C267.027 605.276 266.903 604.984 266.711 604.709C266.519 604.434 266.266 604.196 265.939 603.995C265.616 603.792 265.289 603.666 264.957 603.618C264.626 603.569 264.311 603.585 264.011 603.665C263.712 603.745 263.425 603.895 263.157 604.104C262.886 604.318 262.651 604.584 262.448 604.907Z"
                fill="white"
              />
              <path
                d="M272.188 606.473L273.426 607.185L272.847 608.189L272.873 608.207C273.23 607.946 273.665 607.824 274.173 607.828C274.68 607.836 275.191 607.983 275.695 608.274C276.005 608.452 276.282 608.676 276.513 608.94C276.748 609.203 276.912 609.496 277.011 609.824C277.109 610.151 277.132 610.506 277.081 610.9C277.025 611.291 276.874 611.703 276.628 612.133L274.211 616.332L272.973 615.62L275.191 611.765C275.366 611.461 275.473 611.182 275.515 610.914C275.558 610.651 275.546 610.411 275.487 610.202C275.426 609.989 275.321 609.801 275.18 609.638C275.035 609.476 274.867 609.34 274.672 609.227C274.418 609.079 274.154 608.983 273.886 608.936C273.617 608.89 273.348 608.911 273.08 608.994C272.813 609.078 272.553 609.232 272.302 609.456C272.05 609.68 271.816 609.977 271.601 610.356L269.667 613.716L268.429 613.004L272.19 606.482L272.188 606.473Z"
                fill="white"
              />
              <path
                d="M286.944 610.463L290.449 612.225C291.158 612.582 291.738 612.972 292.195 613.402C292.648 613.832 293.006 614.28 293.263 614.745C293.521 615.21 293.691 615.686 293.776 616.168C293.862 616.65 293.886 617.112 293.853 617.557C293.82 618.002 293.743 618.424 293.623 618.831C293.501 619.233 293.363 619.59 293.208 619.904C292.884 620.549 292.455 621.109 291.917 621.59C291.38 622.071 290.758 622.433 290.06 622.665C289.363 622.901 288.598 622.987 287.768 622.929C286.941 622.87 286.075 622.612 285.175 622.162L281.897 620.513L286.944 610.463ZM283.902 619.919L285.62 620.783C286.196 621.075 286.783 621.271 287.378 621.371C287.971 621.477 288.543 621.469 289.087 621.35C289.63 621.231 290.136 620.997 290.597 620.642C291.058 620.287 291.446 619.796 291.761 619.175C291.924 618.855 292.058 618.45 292.174 617.968C292.285 617.487 292.294 616.979 292.203 616.444C292.108 615.909 291.875 615.381 291.499 614.857C291.124 614.332 290.529 613.864 289.717 613.453L287.674 612.427L283.912 619.922L283.902 619.919Z"
                fill="white"
              />
              <path
                d="M295.688 623.667C295.553 623.964 295.495 624.266 295.513 624.572C295.532 624.878 295.61 625.164 295.742 625.432C295.876 625.704 296.058 625.949 296.295 626.172C296.532 626.394 296.797 626.575 297.099 626.71C297.504 626.893 297.9 626.957 298.282 626.907C298.667 626.857 299.052 626.73 299.444 626.539L300.088 627.738C298.941 628.345 297.714 628.353 296.412 627.76C295.871 627.515 295.425 627.2 295.07 626.82C294.716 626.439 294.457 626.015 294.303 625.553C294.145 625.093 294.085 624.606 294.119 624.094C294.154 623.581 294.287 623.069 294.518 622.557C294.75 622.045 295.054 621.61 295.434 621.256C295.81 620.898 296.233 620.63 296.689 620.45C297.15 620.269 297.637 620.187 298.155 620.198C298.673 620.214 299.187 620.333 299.695 620.57C300.305 620.847 300.769 621.185 301.091 621.59C301.414 621.994 301.634 622.425 301.748 622.889C301.862 623.353 301.884 623.828 301.809 624.312C301.734 624.796 301.596 625.26 301.393 625.704L301.184 626.167L295.689 623.671L295.688 623.667ZM300.268 624.492C300.39 624.197 300.465 623.911 300.492 623.637C300.519 623.359 300.494 623.094 300.409 622.84C300.323 622.587 300.184 622.356 299.984 622.141C299.785 621.93 299.52 621.748 299.193 621.6C298.876 621.455 298.554 621.382 298.236 621.385C297.917 621.383 297.623 621.44 297.351 621.551C297.08 621.662 296.842 621.812 296.634 622.002C296.425 622.191 296.27 622.402 296.163 622.632L300.268 624.497L300.268 624.492Z"
                fill="white"
              />
              <path
                d="M305.276 623.297L306.513 623.798L306.071 624.89L306.1 624.903C306.169 624.814 306.292 624.725 306.476 624.625C306.66 624.525 306.871 624.452 307.117 624.395C307.362 624.338 307.632 624.316 307.93 624.331C308.228 624.345 308.531 624.412 308.85 624.539C309.372 624.751 309.763 625.035 310.028 625.392C310.294 625.752 310.452 626.19 310.505 626.714C310.906 626.374 311.355 626.177 311.858 626.128C312.355 626.08 312.819 626.141 313.239 626.313C313.78 626.53 314.186 626.799 314.458 627.114C314.729 627.428 314.907 627.773 314.985 628.14C315.064 628.507 315.065 628.892 314.993 629.291C314.916 629.69 314.802 630.082 314.644 630.478L313 634.544L311.677 634.008L313.249 630.121C313.354 629.856 313.442 629.593 313.508 629.335C313.574 629.077 313.589 628.832 313.557 628.6C313.524 628.369 313.434 628.161 313.283 627.968C313.132 627.775 312.893 627.616 312.57 627.485C311.932 627.227 311.396 627.237 310.962 627.52C310.529 627.803 310.187 628.25 309.938 628.868L308.394 632.683L307.07 632.148L308.55 628.496C308.683 628.163 308.789 627.853 308.868 627.561C308.946 627.274 308.97 627.005 308.95 626.762C308.929 626.519 308.845 626.301 308.699 626.112C308.554 625.923 308.319 625.763 307.995 625.628C307.758 625.531 307.512 625.485 307.253 625.491C306.994 625.496 306.745 625.554 306.502 625.674C306.258 625.793 306.031 625.977 305.815 626.226C305.6 626.475 305.415 626.799 305.256 627.191L303.79 630.813L302.467 630.277L305.29 623.299L305.276 623.297Z"
                fill="white"
              />
              <path
                d="M318.351 629.451C318.855 629.236 319.388 629.132 319.949 629.135C320.51 629.139 321.041 629.228 321.539 629.408C322.056 629.594 322.481 629.819 322.811 630.083C323.14 630.347 323.383 630.631 323.545 630.934C323.706 631.237 323.794 631.553 323.807 631.878C323.82 632.203 323.773 632.52 323.662 632.827L322.369 636.445C322.279 636.694 322.204 636.927 322.139 637.14C322.074 637.354 322.016 637.557 321.97 637.753L320.773 637.325C320.873 636.953 320.984 636.593 321.113 636.234L321.084 636.221C320.622 636.572 320.151 636.772 319.674 636.812C319.198 636.857 318.691 636.781 318.151 636.59C317.821 636.474 317.524 636.316 317.256 636.118C316.987 635.919 316.776 635.692 316.614 635.434C316.456 635.175 316.356 634.888 316.323 634.58C316.286 634.269 316.335 633.942 316.456 633.589C316.621 633.129 316.859 632.782 317.176 632.546C317.493 632.311 317.86 632.16 318.283 632.09C318.705 632.023 319.168 632.031 319.672 632.116C320.176 632.201 320.697 632.337 321.234 632.533L322.22 632.886L322.329 632.589C322.391 632.408 322.42 632.219 322.415 632.013C322.41 631.808 322.361 631.611 322.273 631.416C322.184 631.221 322.049 631.043 321.874 630.886C321.693 630.726 321.459 630.597 321.171 630.491C320.913 630.398 320.674 630.342 320.464 630.321C320.25 630.301 320.053 630.3 319.868 630.32C319.683 630.339 319.506 630.375 319.34 630.427C319.175 630.479 319.018 630.529 318.865 630.579L318.356 629.455L318.351 629.451ZM321.145 633.62C320.825 633.506 320.494 633.408 320.147 633.322C319.8 633.236 319.47 633.196 319.161 633.197C318.852 633.198 318.575 633.257 318.336 633.375C318.098 633.494 317.926 633.69 317.829 633.972C317.681 634.379 317.716 634.723 317.927 635.003C318.137 635.28 318.477 635.502 318.946 635.669C319.315 635.801 319.651 635.853 319.954 635.817C320.258 635.785 320.524 635.698 320.754 635.554C320.984 635.41 321.181 635.231 321.334 635.011C321.492 634.79 321.613 634.563 321.696 634.324L321.858 633.874L321.14 633.616L321.145 633.62Z"
                fill="white"
              />
              <path
                d="M327.187 631.581L328.546 632.016L328.191 633.119L328.22 633.128C328.515 632.797 328.912 632.583 329.409 632.481C329.906 632.379 330.438 632.419 330.989 632.595C331.331 632.704 331.646 632.863 331.93 633.073C332.214 633.282 332.438 633.534 332.603 633.832C332.768 634.129 332.865 634.475 332.895 634.869C332.924 635.262 332.862 635.695 332.709 636.17L331.231 640.785L329.872 640.35L331.23 636.115C331.336 635.782 331.382 635.483 331.366 635.217C331.35 634.951 331.291 634.719 331.189 634.527C331.082 634.331 330.947 634.171 330.771 634.041C330.595 633.911 330.404 633.815 330.19 633.745C329.909 633.656 329.629 633.617 329.358 633.629C329.086 633.641 328.831 633.718 328.582 633.856C328.338 633.994 328.115 634.2 327.921 634.472C327.722 634.74 327.559 635.083 327.426 635.496L326.246 639.189L324.887 638.754L327.183 631.582L327.187 631.581Z"
                fill="white"
              />
              <path
                d="M341.619 643.778L340.241 643.391L340.536 642.352L340.503 642.345C340.129 642.658 339.68 642.854 339.157 642.938C338.634 643.022 338.127 642.996 337.637 642.859C337.076 642.703 336.598 642.461 336.196 642.138C335.797 641.815 335.484 641.436 335.263 641C335.037 640.565 334.904 640.091 334.863 639.583C334.817 639.076 334.872 638.551 335.023 638.013C335.173 637.471 335.402 636.996 335.704 636.579C336.006 636.162 336.368 635.829 336.786 635.58C337.203 635.331 337.668 635.172 338.176 635.104C338.684 635.036 339.217 635.084 339.778 635.24C340.296 635.385 340.758 635.635 341.151 635.982C341.549 636.332 341.817 636.728 341.955 637.174L341.983 637.183L343.492 631.832L344.869 632.219L341.615 643.779L341.619 643.778ZM338.169 641.617C338.537 641.722 338.883 641.75 339.215 641.704C339.548 641.659 339.843 641.557 340.111 641.397C340.378 641.238 340.608 641.017 340.809 640.735C341.01 640.457 341.158 640.131 341.264 639.767C341.369 639.399 341.405 639.047 341.384 638.701C341.359 638.361 341.277 638.048 341.134 637.773C340.991 637.498 340.79 637.252 340.529 637.043C340.267 636.834 339.956 636.674 339.589 636.573C339.221 636.469 338.871 636.441 338.543 636.486C338.21 636.532 337.915 636.634 337.647 636.793C337.38 636.953 337.151 637.177 336.949 637.455C336.748 637.733 336.6 638.06 336.494 638.424C336.389 638.792 336.353 639.143 336.374 639.489C336.395 639.835 336.481 640.142 336.624 640.417C336.767 640.692 336.968 640.939 337.229 641.148C337.491 641.357 337.802 641.517 338.169 641.617Z"
                fill="white"
              />
            </g>
            <g clipPath="url(#clip2_15_27363)" transform="translate(-16 -30)">
              <path
                d="M299.867 553.197C299.92 553.631 300.075 554.011 300.335 554.334C300.595 554.656 300.904 554.909 301.262 555.091C301.461 555.193 301.682 555.264 301.92 555.306C302.158 555.348 302.397 555.35 302.633 555.312C302.868 555.274 303.082 555.186 303.285 555.06C303.487 554.931 303.644 554.745 303.767 554.505C303.937 554.162 303.962 553.848 303.837 553.56C303.711 553.273 303.518 552.988 303.257 552.706C302.997 552.423 302.708 552.129 302.385 551.828C302.061 551.523 301.79 551.191 301.572 550.829C301.353 550.466 301.219 550.064 301.171 549.617C301.122 549.171 301.245 548.66 301.536 548.085C301.665 547.827 301.863 547.582 302.119 547.344C302.375 547.106 302.689 546.93 303.052 546.812C303.414 546.694 303.829 546.646 304.289 546.674C304.749 546.702 305.247 546.849 305.779 547.12C306.263 547.363 306.691 547.663 307.065 548.018C307.439 548.374 307.691 548.84 307.818 549.418L306.395 549.818C306.361 549.508 306.246 549.213 306.042 548.932C305.837 548.65 305.56 548.425 305.212 548.248C304.878 548.079 304.581 547.985 304.315 547.959C304.05 547.936 303.818 547.957 303.612 548.031C303.41 548.1 303.241 548.204 303.108 548.334C302.975 548.464 302.875 548.597 302.807 548.73C302.618 549.106 302.581 549.447 302.7 549.757C302.815 550.068 303.003 550.362 303.261 550.649C303.52 550.936 303.811 551.226 304.143 551.516C304.475 551.806 304.75 552.125 304.982 552.463C305.211 552.803 305.361 553.181 305.425 553.595C305.489 554.009 305.387 554.482 305.117 555.014C304.901 555.438 304.63 555.781 304.295 556.04C303.96 556.3 303.598 556.478 303.205 556.572C302.811 556.667 302.396 556.682 301.958 556.62C301.521 556.557 301.089 556.415 300.662 556.204C300.094 555.92 299.61 555.547 299.2 555.093C298.793 554.638 298.544 554.126 298.454 553.558L299.869 553.205L299.867 553.197Z"
                fill="white"
              />
              <path
                d="M312.547 556.222C312.438 555.905 312.293 555.635 312.113 555.411C311.933 555.187 311.683 555.003 311.363 554.854C311.051 554.708 310.75 554.639 310.465 554.646C310.18 554.654 309.912 554.721 309.664 554.843C309.413 554.97 309.191 555.141 308.991 555.363C308.79 555.585 308.626 555.833 308.494 556.112C308.363 556.392 308.29 556.678 308.267 556.972C308.244 557.265 308.274 557.543 308.356 557.807C308.438 558.07 308.575 558.311 308.761 558.529C308.947 558.747 309.19 558.921 309.487 559.06C309.808 559.208 310.111 559.28 310.391 559.27C310.671 559.264 310.963 559.194 311.257 559.063L311.692 560.253C311.241 560.445 310.787 560.524 310.327 560.483C309.868 560.443 309.418 560.323 308.978 560.119C308.513 559.903 308.121 559.629 307.806 559.298C307.491 558.966 307.259 558.599 307.108 558.193C306.958 557.787 306.894 557.357 306.919 556.902C306.945 556.447 307.065 555.985 307.282 555.52C307.499 555.055 307.772 554.664 308.107 554.344C308.441 554.024 308.808 553.791 309.212 553.637C309.618 553.487 310.049 553.423 310.507 553.443C310.965 553.464 311.431 553.588 311.905 553.806C312.345 554.009 312.73 554.285 313.06 554.628C313.391 554.971 313.629 555.373 313.774 555.833L312.546 556.218L312.547 556.222Z"
                fill="white"
              />
              <path
                d="M315.168 559.589C315.053 559.855 315.01 560.121 315.029 560.391C315.052 560.659 315.126 560.908 315.249 561.144C315.372 561.381 315.54 561.592 315.753 561.786C315.965 561.981 316.205 562.132 316.47 562.243C316.832 562.396 317.182 562.447 317.518 562.393C317.854 562.339 318.193 562.224 318.534 562.043L319.125 563.084C318.127 563.643 317.05 563.675 315.89 563.181C315.409 562.977 315.007 562.711 314.688 562.38C314.365 562.051 314.133 561.684 313.984 561.282C313.834 560.88 313.771 560.453 313.793 559.999C313.815 559.545 313.921 559.091 314.113 558.637C314.308 558.182 314.567 557.795 314.892 557.47C315.217 557.148 315.58 556.901 315.98 556.732C316.381 556.563 316.81 556.479 317.266 556.48C317.723 556.481 318.178 556.579 318.633 556.774C319.175 557.006 319.59 557.293 319.882 557.643C320.174 557.993 320.377 558.368 320.488 558.777C320.597 559.182 320.626 559.602 320.569 560.03C320.512 560.458 320.401 560.869 320.233 561.263L320.058 561.676L315.164 559.59L315.168 559.589ZM319.217 560.22C319.317 559.958 319.38 559.706 319.395 559.459C319.414 559.212 319.382 558.982 319.303 558.758C319.225 558.538 319.096 558.336 318.915 558.153C318.734 557.969 318.497 557.814 318.208 557.689C317.923 557.567 317.641 557.513 317.36 557.519C317.08 557.525 316.821 557.582 316.582 557.681C316.345 557.784 316.136 557.919 315.958 558.091C315.776 558.263 315.644 558.453 315.556 558.656L319.211 560.214L319.217 560.22Z"
                fill="white"
              />
              <path
                d="M323.621 559.036L324.794 559.49L324.424 560.442L324.45 560.45C324.724 560.176 325.086 560.009 325.532 559.945C325.978 559.88 326.439 559.94 326.916 560.129C327.211 560.244 327.48 560.399 327.718 560.599C327.956 560.798 328.142 561.029 328.27 561.3C328.403 561.569 328.469 561.878 328.474 562.227C328.479 562.574 328.404 562.954 328.244 563.362L326.705 567.344L325.532 566.89L326.944 563.235C327.054 562.949 327.11 562.687 327.112 562.453C327.115 562.218 327.073 562.011 326.992 561.836C326.91 561.661 326.796 561.512 326.648 561.388C326.501 561.268 326.336 561.169 326.151 561.099C325.907 561.006 325.667 560.957 325.428 560.955C325.189 560.954 324.958 561.006 324.735 561.118C324.511 561.229 324.308 561.395 324.121 561.621C323.934 561.847 323.77 562.139 323.632 562.497L322.399 565.682L321.225 565.228L323.618 559.041L323.621 559.036Z"
                fill="white"
              />
              <path
                d="M331.325 562.832C331.768 562.639 332.237 562.544 332.73 562.543C333.222 562.542 333.692 562.62 334.132 562.775C334.588 562.933 334.962 563.131 335.253 563.36C335.543 563.59 335.759 563.84 335.903 564.107C336.046 564.373 336.124 564.65 336.136 564.937C336.148 565.225 336.109 565.502 336.012 565.776L334.895 568.972C334.819 569.192 334.751 569.396 334.696 569.586C334.64 569.775 334.589 569.955 334.549 570.127L333.492 569.758C333.574 569.432 333.674 569.109 333.786 568.791L333.759 568.783C333.354 569.094 332.94 569.271 332.523 569.311C332.103 569.353 331.656 569.288 331.178 569.124C330.888 569.024 330.622 568.884 330.389 568.715C330.156 568.547 329.962 568.343 329.821 568.116C329.677 567.89 329.589 567.636 329.557 567.362C329.525 567.089 329.561 566.8 329.668 566.491C329.809 566.088 330.017 565.78 330.293 565.569C330.569 565.358 330.894 565.222 331.264 565.159C331.637 565.094 332.047 565.1 332.49 565.17C332.935 565.243 333.396 565.36 333.87 565.525L334.743 565.829L334.834 565.565C334.891 565.408 334.915 565.236 334.909 565.056C334.903 564.876 334.862 564.701 334.782 564.53C334.702 564.359 334.586 564.206 334.425 564.065C334.265 563.925 334.06 563.813 333.804 563.723C333.575 563.642 333.368 563.595 333.178 563.579C332.992 563.563 332.815 563.564 332.654 563.581C332.49 563.599 332.337 563.63 332.189 563.68C332.045 563.728 331.904 563.772 331.771 563.817L331.316 562.827L331.325 562.832ZM333.813 566.486C333.531 566.388 333.236 566.301 332.933 566.228C332.626 566.157 332.338 566.121 332.064 566.125C331.791 566.129 331.551 566.181 331.339 566.289C331.13 566.392 330.982 566.57 330.898 566.816C330.773 567.178 330.802 567.481 330.987 567.724C331.172 567.967 331.473 568.161 331.889 568.306C332.216 568.42 332.511 568.463 332.781 568.432C333.05 568.4 333.283 568.323 333.486 568.197C333.689 568.071 333.858 567.911 333.997 567.715C334.135 567.518 334.241 567.319 334.314 567.105L334.452 566.706L333.817 566.485L333.813 566.486Z"
                fill="white"
              />
              <path
                d="M339.121 564.64L340.322 565.021L340.014 565.992L340.04 566.001C340.174 565.858 340.33 565.738 340.505 565.644C340.685 565.549 340.87 565.477 341.064 565.435C341.261 565.387 341.466 565.37 341.678 565.375C341.893 565.38 342.096 565.412 342.291 565.474C342.486 565.536 342.654 565.618 342.797 565.723L342.332 567.001C342.242 566.941 342.151 566.89 342.06 566.839C341.969 566.788 341.874 566.75 341.776 566.717C341.19 566.53 340.687 566.55 340.271 566.78C339.855 567.01 339.536 567.471 339.316 568.167L338.306 571.341L337.104 570.96L339.117 564.641L339.121 564.64Z"
                fill="white"
              />
              <path
                d="M345.228 564.055C345.301 563.813 345.448 563.63 345.676 563.509C345.904 563.385 346.135 563.36 346.378 563.434C346.62 563.507 346.803 563.653 346.923 563.882C347.048 564.109 347.072 564.341 346.999 564.583C346.93 564.825 346.779 565.009 346.551 565.129C346.323 565.254 346.092 565.278 345.849 565.205C345.607 565.132 345.424 564.985 345.304 564.757C345.183 564.528 345.155 564.298 345.228 564.055ZM344.834 566.419L346.041 566.779L344.153 573.139L342.946 572.78L344.834 566.419Z"
                fill="white"
              />
              <path
                d="M347.482 570.674C347.609 570.206 347.812 569.794 348.099 569.435C348.382 569.077 348.72 568.784 349.11 568.561C349.499 568.339 349.922 568.192 350.385 568.127C350.847 568.062 351.317 568.095 351.793 568.224C352.269 568.353 352.695 568.56 353.061 568.845C353.429 569.133 353.725 569.47 353.948 569.86C354.171 570.249 354.317 570.669 354.383 571.122C354.45 571.576 354.422 572.036 354.295 572.504C354.168 572.972 353.962 573.389 353.676 573.752C353.391 574.115 353.053 574.407 352.666 574.625C352.279 574.843 351.855 574.986 351.392 575.051C350.93 575.116 350.46 575.083 349.984 574.954C349.508 574.825 349.082 574.618 348.716 574.333C348.348 574.045 348.053 573.712 347.827 573.327C347.602 572.942 347.456 572.523 347.392 572.064C347.328 571.606 347.356 571.146 347.482 570.674ZM348.782 571.02C348.695 571.343 348.663 571.659 348.691 571.958C348.714 572.258 348.791 572.531 348.92 572.774C349.048 573.016 349.229 573.228 349.458 573.41C349.69 573.591 349.967 573.727 350.29 573.814C350.613 573.901 350.922 573.923 351.213 573.882C351.505 573.841 351.765 573.747 351.999 573.601C352.232 573.455 352.432 573.261 352.608 573.01C352.781 572.763 352.91 572.477 352.996 572.15C353.082 571.823 353.112 571.512 353.088 571.211C353.064 570.911 352.988 570.638 352.859 570.396C352.73 570.153 352.55 569.941 352.321 569.759C352.092 569.577 351.812 569.442 351.489 569.355C351.165 569.268 350.856 569.246 350.565 569.287C350.274 569.329 350.01 569.423 349.78 569.568C349.547 569.715 349.344 569.913 349.171 570.159C348.999 570.41 348.868 570.693 348.782 571.02Z"
                fill="white"
              />
              <path
                d="M300.72 564.619L303.856 566.137C304.426 566.413 304.859 566.719 305.154 567.052C305.45 567.385 305.646 567.725 305.742 568.066C305.839 568.41 305.862 568.751 305.799 569.088C305.741 569.427 305.641 569.734 305.509 570.013C305.378 570.292 305.194 570.558 304.968 570.815C304.741 571.072 304.461 571.268 304.132 571.405C303.803 571.542 303.415 571.598 302.968 571.574C302.521 571.55 302.015 571.402 301.445 571.126L299.516 570.194L297.615 574.123L296.405 573.539L300.72 564.619ZM300.026 569.134L301.613 569.9C301.848 570.015 302.092 570.108 302.338 570.181C302.59 570.255 302.828 570.285 303.054 570.27C303.285 570.258 303.498 570.182 303.697 570.045C303.897 569.908 304.07 569.69 304.219 569.386C304.364 569.083 304.429 568.81 304.41 568.569C304.39 568.328 304.317 568.111 304.184 567.926C304.051 567.737 303.882 567.567 303.667 567.421C303.455 567.271 303.229 567.14 302.996 567.028L301.407 566.259L300.017 569.133L300.026 569.134Z"
                fill="white"
              />
              <path d="M308.863 567.735L310.01 568.253L305.655 577.894L304.508 577.376L308.863 567.735Z" fill="white" />
              <path
                d="M310.572 573.719C311.027 573.555 311.497 573.492 311.991 573.523C312.484 573.554 312.946 573.662 313.376 573.84C313.821 574.03 314.185 574.247 314.457 574.498C314.729 574.748 314.931 575.007 315.058 575.286C315.184 575.562 315.246 575.843 315.239 576.132C315.235 576.419 315.175 576.695 315.064 576.96L313.743 580.081C313.651 580.296 313.573 580.496 313.504 580.681C313.435 580.866 313.376 581.044 313.326 581.211L312.295 580.774C312.399 580.454 312.516 580.139 312.648 579.831L312.62 579.819C312.195 580.103 311.773 580.254 311.349 580.268C310.929 580.281 310.484 580.192 310.021 579.995C309.737 579.877 309.484 579.722 309.258 579.535C309.032 579.349 308.856 579.139 308.728 578.901C308.601 578.662 308.529 578.407 308.513 578.133C308.496 577.859 308.552 577.569 308.682 577.27C308.85 576.876 309.078 576.582 309.368 576.391C309.658 576.201 309.988 576.084 310.365 576.047C310.743 576.009 311.147 576.041 311.587 576.14C312.026 576.238 312.476 576.387 312.943 576.583L313.794 576.941L313.904 576.684C313.969 576.528 314.003 576.361 314.009 576.182C314.015 576.003 313.982 575.826 313.916 575.647C313.846 575.473 313.74 575.309 313.589 575.161C313.439 575.014 313.241 574.884 312.994 574.78C312.769 574.685 312.567 574.625 312.381 574.596C312.195 574.568 312.018 574.557 311.855 574.562C311.691 574.568 311.532 574.592 311.385 574.63C311.239 574.671 311.095 574.703 310.959 574.737L310.568 573.72L310.572 573.719ZM312.817 577.53C312.542 577.414 312.254 577.309 311.955 577.219C311.654 577.126 311.368 577.073 311.095 577.061C310.821 577.049 310.576 577.086 310.364 577.177C310.148 577.27 309.989 577.436 309.886 577.675C309.735 578.028 309.747 578.332 309.92 578.586C310.092 578.841 310.379 579.055 310.781 579.225C311.098 579.358 311.394 579.42 311.66 579.406C311.927 579.391 312.168 579.332 312.378 579.216C312.588 579.101 312.769 578.953 312.919 578.77C313.069 578.586 313.187 578.387 313.275 578.181L313.437 577.796L312.818 577.534L312.817 577.53Z"
                fill="white"
              />
              <path
                d="M318.234 576.041L319.411 576.494L319.045 577.449L319.071 577.458C319.346 577.183 319.707 577.017 320.153 576.952C320.595 576.888 321.06 576.948 321.536 577.133C321.831 577.248 322.1 577.402 322.337 577.598C322.574 577.794 322.761 578.029 322.893 578.298C323.025 578.568 323.092 578.876 323.097 579.226C323.101 579.572 323.027 579.953 322.87 580.36L321.336 584.344L320.159 583.891L321.567 580.233C321.677 579.948 321.732 579.686 321.735 579.451C321.734 579.217 321.696 579.01 321.614 578.835C321.533 578.66 321.418 578.511 321.27 578.387C321.124 578.266 320.959 578.167 320.774 578.098C320.529 578.005 320.289 577.955 320.051 577.954C319.812 577.952 319.58 578.005 319.357 578.116C319.134 578.227 318.93 578.394 318.744 578.62C318.557 578.846 318.393 579.138 318.255 579.496L317.03 582.682L315.853 582.23L318.237 576.04L318.234 576.041Z"
                fill="white"
              />
              <path
                d="M326.191 579.056L327.381 579.469L327.046 580.435L327.073 580.444C327.34 580.159 327.693 579.979 328.135 579.899C328.577 579.819 329.042 579.862 329.526 580.033C329.826 580.139 330.097 580.285 330.344 580.474C330.591 580.663 330.784 580.891 330.923 581.155C331.062 581.419 331.14 581.728 331.161 582.074C331.181 582.419 331.115 582.801 330.973 583.217L329.574 587.251L328.384 586.839L329.666 583.135C329.766 582.845 329.813 582.581 329.805 582.349C329.795 582.114 329.751 581.912 329.661 581.736C329.571 581.559 329.454 581.418 329.3 581.3C329.146 581.182 328.979 581.091 328.796 581.03C328.55 580.945 328.305 580.905 328.068 580.911C327.828 580.918 327.599 580.978 327.381 581.096C327.164 581.213 326.962 581.387 326.786 581.622C326.605 581.855 326.455 582.155 326.33 582.514L325.213 585.739L324.023 585.326L326.195 579.059L326.191 579.056Z"
                fill="white"
              />
              <path
                d="M334.043 585.694C333.959 585.971 333.938 586.24 333.988 586.504C334.038 586.769 334.135 587.012 334.282 587.234C334.428 587.455 334.616 587.648 334.849 587.817C335.079 587.987 335.332 588.113 335.61 588.197C335.986 588.313 336.337 588.328 336.668 588.239C336.998 588.15 337.323 587.999 337.643 587.788L338.337 588.764C337.403 589.421 336.333 589.565 335.127 589.193C334.627 589.038 334.201 588.815 333.847 588.518C333.493 588.222 333.224 587.882 333.036 587.499C332.846 587.112 332.741 586.693 332.715 586.24C332.688 585.787 332.749 585.326 332.894 584.853C333.04 584.38 333.259 583.967 333.547 583.612C333.835 583.257 334.172 582.976 334.554 582.768C334.935 582.56 335.351 582.431 335.808 582.388C336.259 582.342 336.726 582.392 337.195 582.539C337.756 582.713 338.2 582.955 338.527 583.271C338.854 583.587 339.095 583.944 339.246 584.337C339.397 584.731 339.469 585.143 339.456 585.574C339.444 586.006 339.376 586.425 339.249 586.836L339.119 587.264L334.039 585.695L334.043 585.694ZM338.136 585.905C338.209 585.634 338.242 585.378 338.237 585.129C338.228 584.882 338.176 584.654 338.077 584.444C337.977 584.233 337.828 584.045 337.628 583.879C337.429 583.717 337.178 583.586 336.877 583.492C336.581 583.402 336.295 583.373 336.018 583.411C335.737 583.445 335.486 583.528 335.262 583.651C335.039 583.778 334.845 583.934 334.684 584.124C334.524 584.315 334.411 584.516 334.345 584.728L338.143 585.903L338.136 585.905Z"
                fill="white"
              />
              <path
                d="M342.376 584.254L343.59 584.591L343.317 585.576L343.344 585.585C343.471 585.436 343.623 585.313 343.796 585.212C343.969 585.11 344.153 585.031 344.349 584.98C344.543 584.925 344.746 584.9 344.959 584.897C345.172 584.894 345.378 584.921 345.578 584.973C345.775 585.027 345.95 585.103 346.095 585.204L345.679 586.496C345.587 586.441 345.496 586.39 345.403 586.347C345.309 586.301 345.214 586.263 345.114 586.239C344.523 586.074 344.021 586.113 343.613 586.357C343.205 586.601 342.907 587.077 342.712 587.777L341.82 590.987L340.607 590.65L342.382 584.26L342.376 584.254Z"
                fill="white"
              />
            </g>
          </g>
        )}
        {/* End CHOREOGRAPH_SYNAPSE Phase */}
        {/* Start MEDIA_ACTIVITY_PLAN Phase */}
        {selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN && (
          <g filter="url(#filter11_d_15_27929)" transform="translate(8 -3)" ref={mediaActivityPlanPhaseWrapperRef}>
            <path
              d="M30.7918 419.384C45.6696 498.17 88.4336 568.96 151.251 618.786L263.807 476.883C235.106 454.117 215.567 421.773 208.769 385.775L30.7918 419.384Z"
              fill="url(#paint34_linear_15_27929)"
            />
          </g>
        )}
        <g filter="url(#J)" onClick={() => onSelectPhase(ProcessSchemePhase.MEDIA_ACTIVITY_PLAN)}>
          <circle
            cx={78.575}
            cy={523}
            r={selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN ? 'url(#paint24_linear_295_35042)' : '#fff'}
            transform="rotate(-120 78.575 523)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN && (
          <g clipPath="url(#clip0_15_27929)" transform="translate(8 -3)">
            <path
              d="M140.354 457.613L141.906 460.479L133.495 476.502L133.522 476.546L146.736 469.379L147.92 471.563L131.849 480.279L130.347 477.509L138.711 461.4L138.685 461.356L125.47 468.523L124.284 466.345L140.354 457.613Z"
              fill="white"
            />
            <path
              d="M151.95 494.055L144.24 498.716C143.775 498.474 143.303 498.164 142.832 497.783C142.361 497.402 141.905 496.989 141.465 496.544C141.024 496.098 140.609 495.617 140.225 495.118C139.844 494.614 139.509 494.13 139.222 493.662C138.421 492.333 137.929 491.022 137.745 489.713C137.56 488.405 137.628 487.168 137.948 486.002C138.267 484.836 138.807 483.758 139.577 482.78C140.348 481.803 141.282 480.982 142.383 480.312C143.548 479.608 144.754 479.153 145.998 478.954C147.241 478.755 148.455 478.788 149.62 479.06C150.785 479.331 151.888 479.835 152.913 480.588C153.941 481.335 154.814 482.31 155.538 483.5C156.412 484.943 156.94 486.23 157.136 487.362C157.326 488.491 157.289 489.56 157.03 490.564L154.511 490.035C154.738 489.016 154.758 488.065 154.565 487.197C154.371 486.328 154.034 485.495 153.554 484.698C153.01 483.799 152.355 483.085 151.585 482.564C150.816 482.043 149.996 481.696 149.128 481.517C148.261 481.338 147.356 481.339 146.429 481.521C145.503 481.703 144.609 482.051 143.754 482.568C142.868 483.102 142.142 483.751 141.572 484.522C141.003 485.293 140.598 486.117 140.371 487.002C140.138 487.883 140.092 488.789 140.216 489.72C140.343 490.645 140.672 491.546 141.196 492.419C141.65 493.173 142.153 493.838 142.693 494.426C143.24 495.017 143.786 495.457 144.335 495.771L148.687 493.135L146.588 489.663L148.576 488.458L151.96 494.051L151.95 494.055Z"
              fill="white"
            />
            <path
              d="M161.896 494.611L165.445 499.972C166.093 500.952 166.492 501.838 166.643 502.647C166.793 503.455 166.787 504.174 166.622 504.81C166.457 505.446 166.162 506.005 165.752 506.487C165.337 506.965 164.893 507.366 164.422 507.676C163.948 507.992 163.408 508.245 162.803 508.435C162.198 508.626 161.57 508.679 160.916 508.585C160.262 508.491 159.598 508.217 158.91 507.758C158.229 507.302 157.561 506.583 156.916 505.612L154.734 502.315L148.014 506.762L146.641 504.694L161.886 494.598L161.896 494.611ZM156.549 501.124L158.348 503.837C158.611 504.238 158.908 504.616 159.234 504.968C159.56 505.32 159.913 505.598 160.29 505.791C160.667 505.985 161.079 506.068 161.528 506.042C161.977 506.015 162.454 505.828 162.972 505.485C163.491 505.143 163.848 504.772 164.044 504.372C164.24 503.973 164.324 503.56 164.295 503.134C164.263 502.715 164.15 502.281 163.951 501.846C163.754 501.404 163.523 500.986 163.26 500.584L161.461 497.872L156.549 501.124Z"
              fill="white"
            />
          </g>
        )}
        {/* End MEDIA_ACTIVITY_PLAN Phase */}
        {/* Start NEXUS Phase */}
        {selectedPhase === ProcessSchemePhase.NEXUS && (
          <path
            transform="translate(-92 -104)"
            ref={nexusPhaseWrapperRef}
            d="M157.8 315.933C131.149 372.035 120.694 434.465 127.613 496.189L339.444 472.443C336.947 450.17 340.72 427.643 350.337 407.398L157.8 315.933Z"
            fill="url(#paint34_radial_15_31140)"
            stroke="url(#paint35_linear_15_31140)"
            strokeLinecap="square"
          />
        )}
        <g filter="url(#K)" onClick={() => onSelectPhase(ProcessSchemePhase.NEXUS)}>
          <circle
            cx={38.901}
            cy={298.001}
            r={selectedPhase === ProcessSchemePhase.NEXUS ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.NEXUS ? 'url(#paint24_linear_317_37283)' : '#fff'}
            transform="rotate(-80 38.901 298.001)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End NEXUS Phase */}
        {/* Start ADVERITY Phase */}
        {selectedPhase === ProcessSchemePhase.ADVERITY && (
          <g ref={adverityPhaseWrapperRef} transform="translate(-55 -72)">
            <path
              d="M307.865 112.061C232.57 140.169 169.958 194.522 131.549 265.121L195.437 299.879C225.469 244.677 274.427 202.177 333.301 180.199L307.865 112.061Z"
              fill="url(#paint35_linear_15_29027)"
            />
            <g filter="url(#filter11_d_15_29027)">
              <path
                d="M332.482 177.713C273.202 200.981 222.795 242.391 188.464 296.027L326.508 384.386C342.207 359.859 365.258 340.922 392.365 330.283L332.482 177.713Z"
                fill="url(#paint34_linear_15_29027)"
              />
            </g>
          </g>
        )}
        <g filter="url(#L)" onClick={() => onSelectPhase(ProcessSchemePhase.ADVERITY)}>
          <circle
            cx={153.136}
            cy={100.141}
            r={selectedPhase === ProcessSchemePhase.ADVERITY ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.ADVERITY ? 'url(#paint38_linear_317_37283)' : '#fff'}
            transform="rotate(-40 153.136 100.141)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {selectedPhase === ProcessSchemePhase.ADVERITY && (
          <g>
            <g clipPath="url(#clip0_15_29027)" transform="translate(-55 -72)">
              <path
                d="M191.655 222.913L195.535 219.054C196.021 218.574 196.526 218.186 197.057 217.888C197.588 217.589 198.115 217.408 198.641 217.338C199.167 217.268 199.687 217.321 200.214 217.501C200.74 217.681 201.232 218.007 201.703 218.483C202.342 219.126 202.684 219.824 202.727 220.584C202.771 221.344 202.605 222.099 202.222 222.854L202.251 222.884C202.61 222.569 203.008 222.346 203.456 222.201C203.902 222.063 204.357 222.006 204.817 222.043C205.28 222.075 205.735 222.197 206.183 222.394C206.63 222.591 207.05 222.884 207.423 223.264C207.922 223.77 208.261 224.316 208.441 224.902C208.62 225.488 208.655 226.093 208.56 226.71C208.465 227.327 208.247 227.938 207.909 228.551C207.574 229.159 207.132 229.734 206.584 230.276L202.748 234.086L191.647 222.917L191.655 222.913ZM202.954 231.252L205.181 229.043C205.411 228.811 205.644 228.535 205.875 228.212C206.106 227.888 206.275 227.536 206.376 227.152C206.478 226.768 206.492 226.371 206.418 225.963C206.345 225.554 206.111 225.152 205.713 224.749C205.054 224.086 204.342 223.804 203.582 223.9C202.823 223.996 202.071 224.411 201.336 225.141L199.096 227.366L202.956 231.246L202.954 231.252ZM197.784 226.048L199.848 223.995C200.13 223.711 200.37 223.417 200.569 223.108C200.762 222.798 200.891 222.483 200.944 222.16C200.996 221.837 200.977 221.514 200.877 221.194C200.777 220.875 200.576 220.564 200.274 220.255C199.838 219.812 199.314 219.573 198.705 219.527C198.097 219.481 197.486 219.764 196.881 220.371L194.497 222.74L197.789 226.05L197.784 226.048Z"
                fill="white"
              />
              <path
                d="M206.836 217.124C206.898 216.364 207.108 215.631 207.475 214.937C207.843 214.242 208.299 213.646 208.84 213.141C209.407 212.618 209.956 212.234 210.497 211.999C211.038 211.763 211.55 211.642 212.031 211.641C212.512 211.64 212.964 211.734 213.373 211.93C213.781 212.126 214.144 212.391 214.463 212.725L218.119 216.676C218.369 216.945 218.608 217.191 218.83 217.411C219.052 217.631 219.268 217.829 219.482 218.019L218.174 219.23C217.78 218.868 217.399 218.491 217.036 218.101L217.005 218.129C217.145 218.931 217.08 219.645 216.827 220.262C216.574 220.88 216.149 221.462 215.566 222.011C215.206 222.346 214.818 222.612 214.402 222.814C213.985 223.016 213.565 223.138 213.14 223.166C212.715 223.195 212.297 223.133 211.887 222.976C211.477 222.819 211.097 222.547 210.743 222.167C210.281 221.668 210 221.143 209.917 220.599C209.828 220.053 209.88 219.5 210.069 218.931C210.258 218.362 210.567 217.791 211.001 217.219C211.435 216.648 211.942 216.091 212.529 215.55L213.608 214.55L213.307 214.223C213.123 214.024 212.905 213.864 212.647 213.743C212.391 213.616 212.113 213.547 211.811 213.528C211.514 213.511 211.206 213.563 210.891 213.684C210.577 213.805 210.266 214.007 209.948 214.298C209.666 214.563 209.442 214.817 209.284 215.068C209.12 215.317 208.989 215.565 208.894 215.807C208.798 216.048 208.728 216.292 208.686 216.533C208.643 216.774 208.605 217.004 208.565 217.22L206.836 217.124ZM213.828 216.35C213.48 216.669 213.141 217.019 212.811 217.398C212.483 217.771 212.217 218.154 212.022 218.543C211.821 218.929 211.721 219.308 211.71 219.679C211.698 220.05 211.837 220.39 212.121 220.692C212.535 221.14 212.983 221.318 213.461 221.237C213.938 221.156 214.435 220.878 214.946 220.401C215.347 220.028 215.626 219.643 215.787 219.242C215.946 218.846 216.01 218.455 215.979 218.075C215.953 217.697 215.855 217.338 215.681 217.004C215.51 216.665 215.304 216.365 215.063 216.106L214.611 215.617L213.831 216.344L213.828 216.35Z"
                fill="white"
              />
              <path
                d="M221.381 205.829C221.004 205.762 220.621 205.766 220.229 205.846C219.832 205.924 219.447 206.131 219.069 206.46C218.712 206.77 218.464 207.12 218.338 207.501C218.205 207.88 218.265 208.218 218.52 208.509C218.724 208.742 218.966 208.871 219.246 208.881C219.523 208.898 219.801 208.855 220.092 208.764C220.376 208.672 220.657 208.551 220.928 208.387C221.199 208.223 221.437 208.094 221.633 207.975C222.024 207.77 222.416 207.598 222.796 207.461C223.175 207.324 223.549 207.257 223.912 207.252C224.274 207.254 224.629 207.325 224.967 207.482C225.312 207.635 225.642 207.901 225.961 208.269C226.35 208.715 226.586 209.183 226.679 209.665C226.764 210.15 226.748 210.625 226.624 211.086C226.498 211.553 226.284 211.995 225.976 212.421C225.667 212.848 225.316 213.232 224.928 213.57C224.269 214.144 223.6 214.55 222.928 214.784C222.257 215.017 221.492 215.039 220.63 214.841L220.953 212.905C221.415 212.937 221.877 212.916 222.344 212.844C222.812 212.772 223.264 212.544 223.7 212.164C223.893 211.998 224.067 211.812 224.229 211.602C224.392 211.392 224.51 211.178 224.589 210.964C224.668 210.749 224.694 210.535 224.679 210.325C224.664 210.116 224.581 209.927 224.434 209.755C224.24 209.532 224.009 209.408 223.742 209.382C223.475 209.356 223.206 209.389 222.938 209.475C222.669 209.56 222.404 209.673 222.141 209.814C221.879 209.955 221.649 210.081 221.456 210.194C221.064 210.399 220.676 210.579 220.298 210.729C219.92 210.88 219.544 210.972 219.177 211.002C218.805 211.03 218.443 210.976 218.088 210.832C217.732 210.688 217.384 210.435 217.052 210.05C216.691 209.634 216.462 209.201 216.366 208.745C216.271 208.289 216.281 207.831 216.386 207.383C216.498 206.937 216.691 206.501 216.964 206.081C217.236 205.661 217.562 205.294 217.93 204.975C218.464 204.506 219.065 204.161 219.734 203.933C220.404 203.705 221.084 203.699 221.782 203.915L221.381 205.829Z"
                fill="white"
              />
              <path
                d="M228.857 203.882C229.147 204.239 229.493 204.498 229.887 204.662C230.28 204.827 230.69 204.912 231.113 204.909C231.535 204.906 231.957 204.831 232.385 204.672C232.812 204.513 233.203 204.288 233.56 203.998C234.041 203.602 234.366 203.149 234.542 202.634C234.712 202.118 234.8 201.557 234.795 200.943L236.695 200.878C236.745 202.694 236 204.237 234.447 205.509C233.805 206.037 233.131 206.401 232.435 206.613C231.733 206.822 231.045 206.885 230.367 206.794C229.69 206.703 229.04 206.484 228.417 206.117C227.793 205.756 227.235 205.268 226.734 204.662C226.234 204.051 225.881 203.401 225.661 202.708C225.444 202.01 225.367 201.323 225.423 200.633C225.48 199.942 225.678 199.278 226.018 198.639C226.358 198.001 226.825 197.429 227.436 196.929C228.16 196.339 228.871 195.962 229.58 195.808C230.288 195.654 230.963 195.645 231.621 195.788C232.279 195.931 232.885 196.2 233.446 196.59C234.007 196.981 234.503 197.439 234.94 197.969L235.391 198.516L228.853 203.875L228.857 203.882ZM232.73 198.644C232.437 198.313 232.123 198.039 231.791 197.832C231.459 197.624 231.106 197.494 230.736 197.444C230.366 197.394 229.986 197.425 229.593 197.544C229.201 197.663 228.811 197.882 228.424 198.201C228.047 198.51 227.758 198.864 227.559 199.265C227.359 199.665 227.249 200.073 227.217 200.482C227.185 200.892 227.226 201.282 227.335 201.664C227.445 202.047 227.615 202.373 227.837 202.645L232.724 198.642L232.73 198.644Z"
                fill="white"
              />
              <path
                d="M230.768 186.575L232.345 185.344L242.675 198.613L241.097 199.843L230.768 186.575Z"
                fill="white"
              />
              <path
                d="M239.81 187.528L241.411 186.326L247.756 194.746L246.155 195.949L239.81 187.528ZM237.163 184.791C236.924 184.473 236.828 184.108 236.885 183.701C236.943 183.295 237.132 182.976 237.452 182.732C237.773 182.487 238.135 182.397 238.542 182.454C238.949 182.511 239.267 182.701 239.512 183.021C239.75 183.339 239.847 183.704 239.789 184.11C239.732 184.517 239.543 184.836 239.222 185.08C238.902 185.325 238.54 185.415 238.133 185.358C237.726 185.301 237.407 185.111 237.163 184.791Z"
                fill="white"
              />
              <path
                d="M244.981 183.649L246.608 182.483L247.551 183.803L247.587 183.777C247.467 183.173 247.558 182.549 247.861 181.903C248.164 181.257 248.647 180.697 249.314 180.225C249.723 179.935 250.166 179.716 250.636 179.566C251.103 179.422 251.58 179.379 252.051 179.441C252.522 179.502 252.995 179.682 253.46 179.978C253.924 180.274 254.364 180.705 254.767 181.275L258.71 186.792L257.083 187.958L253.463 182.889C253.182 182.489 252.878 182.187 252.572 181.982C252.259 181.775 251.95 181.648 251.645 181.602C251.339 181.555 251.042 181.578 250.752 181.669C250.461 181.759 250.188 181.896 249.938 182.074C249.598 182.318 249.326 182.593 249.111 182.909C248.896 183.225 248.766 183.579 248.721 183.97C248.676 184.362 248.714 184.777 248.856 185.231C248.995 185.677 249.243 186.15 249.593 186.647L252.749 191.065L251.122 192.232L244.993 183.653L244.981 183.649Z"
                fill="white"
              />
              <path
                d="M260.771 179.894C261.025 180.277 261.339 180.57 261.718 180.775C262.096 180.98 262.494 181.099 262.913 181.141C263.332 181.184 263.761 181.144 264.204 181.03C264.642 180.915 265.058 180.732 265.441 180.478C265.961 180.136 266.328 179.711 266.556 179.215C266.779 178.718 266.92 178.171 266.98 177.561L268.882 177.687C268.749 179.501 267.856 180.963 266.186 182.068C265.498 182.526 264.789 182.825 264.073 182.963C263.357 183.102 262.661 183.096 262.002 182.939C261.334 182.786 260.715 182.5 260.128 182.073C259.547 181.649 259.033 181.111 258.602 180.459C258.164 179.804 257.876 179.119 257.73 178.407C257.583 177.695 257.577 176.995 257.701 176.317C257.826 175.638 258.09 174.992 258.489 174.388C258.889 173.784 259.415 173.267 260.069 172.829C260.845 172.311 261.595 172.008 262.312 171.922C263.028 171.836 263.708 171.902 264.343 172.109C264.978 172.316 265.56 172.648 266.082 173.091C266.602 173.538 267.048 174.045 267.425 174.611L267.82 175.204L260.781 179.884L260.771 179.894ZM265.146 175.064C264.886 174.698 264.605 174.404 264.292 174.164C263.984 173.926 263.643 173.761 263.281 173.674C262.918 173.586 262.539 173.579 262.134 173.66C261.734 173.744 261.32 173.921 260.901 174.201C260.492 174.472 260.17 174.795 259.937 175.177C259.699 175.557 259.548 175.949 259.479 176.351C259.41 176.754 259.41 177.149 259.484 177.538C259.558 177.927 259.689 178.271 259.889 178.569L265.15 175.072L265.146 175.064Z"
                fill="white"
              />
            </g>
            <g clipPath="url(#clip1_15_29027)" transform="translate(-55 -72)">
              <path
                d="M262.618 285.653C261.893 285.567 261.182 285.665 260.482 285.938C259.782 286.211 259.183 286.607 258.672 287.129C258.053 287.769 257.611 288.463 257.349 289.205C257.084 289.953 256.983 290.712 257.045 291.484C257.101 292.254 257.313 293.014 257.677 293.758C258.04 294.501 258.541 295.185 259.176 295.803C259.768 296.384 260.428 296.836 261.148 297.157C261.866 297.483 262.599 297.661 263.358 297.691C264.112 297.721 264.863 297.589 265.601 297.294C266.345 297.001 267.029 296.526 267.672 295.867C268.301 295.216 268.723 294.523 268.93 293.773C269.138 293.023 269.172 292.251 269.024 291.444L271.252 291.101C271.288 291.302 271.308 291.608 271.322 292.034C271.336 292.46 271.275 292.949 271.154 293.518C271.03 294.08 270.814 294.693 270.499 295.345C270.182 296.002 269.708 296.649 269.079 297.299C268.213 298.193 267.255 298.832 266.218 299.219C265.18 299.606 264.141 299.782 263.094 299.751C262.047 299.72 261.051 299.498 260.093 299.087C259.135 298.677 258.305 298.122 257.594 297.433C256.721 296.585 256.055 295.658 255.595 294.652C255.135 293.646 254.884 292.625 254.849 291.586C254.814 290.547 254.999 289.522 255.404 288.511C255.81 287.5 256.434 286.555 257.282 285.682C258.006 284.936 258.862 284.346 259.843 283.905C260.831 283.466 261.839 283.308 262.882 283.427L262.614 285.645L262.618 285.653Z"
                fill="white"
              />
              <path
                d="M271.12 287.176C270.592 286.619 270.196 285.994 269.95 285.314C269.697 284.633 269.583 283.937 269.603 283.219C269.621 282.508 269.776 281.807 270.072 281.132C270.37 280.451 270.801 279.843 271.37 279.298C271.936 278.759 272.57 278.353 273.265 278.098C273.956 277.836 274.659 277.717 275.377 277.737C276.088 277.755 276.782 277.909 277.449 278.19C278.122 278.472 278.719 278.894 279.253 279.453C279.782 280.011 280.178 280.635 280.434 281.324C280.691 282.014 280.805 282.709 280.781 283.419C280.757 284.129 280.597 284.822 280.301 285.498C280.004 286.179 279.572 286.786 279.004 287.331C278.437 287.87 277.804 288.276 277.109 288.531C276.412 288.792 275.713 288.918 275.007 288.902C274.302 288.886 273.606 288.738 272.935 288.449C272.258 288.159 271.655 287.735 271.126 287.178L271.12 287.176ZM272.666 285.703C273.036 286.09 273.434 286.395 273.864 286.606C274.291 286.823 274.726 286.946 275.161 286.967C275.597 286.988 276.036 286.926 276.47 286.761C276.911 286.598 277.32 286.336 277.707 285.966C278.093 285.597 278.379 285.2 278.564 284.77C278.75 284.34 278.84 283.91 278.835 283.474C278.83 283.037 278.73 282.601 278.541 282.16C278.346 281.718 278.067 281.304 277.697 280.917C277.327 280.53 276.928 280.231 276.5 280.014C276.072 279.797 275.636 279.68 275.202 279.654C274.768 279.627 274.327 279.694 273.893 279.859C273.453 280.023 273.044 280.284 272.657 280.654C272.27 281.024 271.984 281.42 271.799 281.85C271.613 282.28 271.524 282.711 271.528 283.147C271.533 283.583 271.633 284.019 271.828 284.462C272.023 284.904 272.303 285.318 272.672 285.705L272.666 285.703Z"
                fill="white"
              />
              <path
                d="M275.403 268.782L279.553 265.11C280.308 264.442 281.023 263.994 281.691 263.77C282.36 263.54 282.976 263.462 283.54 263.53C284.103 263.598 284.611 263.779 285.076 264.076C285.534 264.371 285.925 264.7 286.249 265.068C286.572 265.436 286.854 265.864 287.093 266.356C287.332 266.849 287.446 267.378 287.447 267.948C287.442 268.516 287.287 269.12 286.982 269.755C286.676 270.396 286.144 271.046 285.389 271.714L282.841 273.967L287.442 279.166L285.841 280.579L275.409 268.784L275.403 268.782ZM281.604 272.568L283.7 270.712C284.011 270.437 284.296 270.136 284.561 269.816C284.82 269.495 285.011 269.163 285.135 268.819C285.253 268.473 285.279 268.115 285.201 267.736C285.124 267.357 284.909 266.967 284.554 266.571C284.201 266.17 283.841 265.913 283.478 265.79C283.114 265.667 282.749 265.646 282.395 265.724C282.042 265.802 281.688 265.951 281.335 266.17C280.989 266.39 280.653 266.639 280.343 266.914L278.246 268.77L281.604 272.568Z"
                fill="white"
              />
              <path
                d="M289.51 263.403L291.043 262.118L297.821 270.197L296.287 271.481L289.51 263.403ZM286.725 260.808C286.468 260.502 286.359 260.147 286.392 259.734C286.426 259.321 286.597 258.995 286.908 258.74C287.214 258.483 287.569 258.373 287.982 258.407C288.388 258.445 288.721 258.611 288.976 258.923C289.234 259.228 289.343 259.584 289.309 259.997C289.275 260.41 289.105 260.735 288.793 260.991C288.488 261.248 288.132 261.358 287.725 261.325C287.32 261.287 286.987 261.121 286.731 260.809L286.725 260.808Z"
                fill="white"
              />
              <path
                d="M290.599 254.349L292.151 253.089L302.751 266.144L301.198 267.404L290.599 254.349Z"
                fill="white"
              />
              <path
                d="M302.386 259.631C301.916 259.019 301.593 258.363 301.412 257.656C301.236 256.95 301.188 256.246 301.283 255.536C301.375 254.831 301.603 254.15 301.967 253.506C302.332 252.862 302.828 252.298 303.449 251.818C304.07 251.338 304.741 251.006 305.456 250.821C306.172 250.635 306.889 250.584 307.593 250.677C308.297 250.769 308.974 250.989 309.608 251.344C310.244 251.694 310.798 252.181 311.269 252.787C311.738 253.399 312.065 254.063 312.247 254.77C312.428 255.477 312.475 256.187 312.376 256.89C312.277 257.593 312.046 258.266 311.687 258.912C311.322 259.556 310.826 260.121 310.205 260.6C309.584 261.08 308.913 261.412 308.198 261.598C307.483 261.783 306.771 261.836 306.065 261.749C305.366 261.664 304.69 261.438 304.05 261.081C303.41 260.724 302.857 260.237 302.386 259.631ZM304.077 258.331C304.406 258.752 304.767 259.098 305.172 259.354C305.575 259.616 305.992 259.778 306.421 259.849C306.85 259.919 307.291 259.896 307.744 259.781C308.197 259.666 308.634 259.444 309.061 259.117C309.487 258.79 309.81 258.422 310.034 258.015C310.264 257.61 310.397 257.185 310.436 256.754C310.475 256.323 310.422 255.874 310.276 255.419C310.129 254.964 309.895 254.524 309.568 254.097C309.239 253.676 308.876 253.336 308.473 253.074C308.068 252.819 307.653 252.65 307.224 252.58C306.795 252.51 306.354 252.532 305.901 252.647C305.448 252.763 305.011 252.985 304.586 253.306C304.159 253.633 303.837 254.001 303.613 254.407C303.381 254.819 303.249 255.238 303.209 255.675C303.169 256.112 303.223 256.555 303.37 257.01C303.515 257.471 303.75 257.905 304.077 258.331Z"
                fill="white"
              />
              <path
                d="M316.75 244.456L314.426 246.136L317.232 250.01C317.405 250.248 317.586 250.482 317.768 250.71C317.95 250.938 318.146 251.112 318.36 251.246C318.575 251.374 318.8 251.441 319.046 251.43C319.29 251.425 319.569 251.314 319.88 251.084C320.072 250.943 320.256 250.782 320.439 250.6C320.616 250.417 320.752 250.217 320.856 249.996L321.926 251.472C321.815 251.718 321.619 251.972 321.343 252.237C321.068 252.502 320.844 252.692 320.677 252.814C320.051 253.266 319.507 253.53 319.039 253.603C318.572 253.676 318.155 253.634 317.795 253.474C317.435 253.314 317.115 253.074 316.835 252.756C316.556 252.438 316.295 252.1 316.043 251.751L312.822 247.299L310.95 248.653L309.932 247.248L311.804 245.894L310.073 243.499L311.692 242.327L313.429 244.724L315.753 243.044L316.771 244.449L316.75 244.456Z"
                fill="white"
              />
            </g>
          </g>
        )}
        {/* End ADVERITY Phase */}
        {/* Small circle (when necessary phase) */}
        {selectedPhase === ProcessSchemePhase.WHEN_NECESSARY && (
          <path
            ref={whenNecessaryPhaseWrapperRef}
            transform="translate(-102 -116)"
            d="M565.685 151.249C506.128 133.622 442.832 132.977 382.928 149.386L439.243 354.97C460.859 349.049 483.699 349.282 505.19 355.643L565.685 151.249Z"
            fill="url(#paint34_radial_15_30635)"
            stroke="url(#paint35_linear_15_30635)"
            strokeLinecap="square"
          />
        )}
        <g filter="url(#E)" onClick={() => onSelectPhase(ProcessSchemePhase.WHEN_NECESSARY)}>
          <circle
            cx={367}
            cy={22}
            r={selectedPhase === ProcessSchemePhase.WHEN_NECESSARY ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.WHEN_NECESSARY ? '#5ac5de' : '#fff'}
            className={styles.phaseCircleTrigger}
          />
        </g>
        <ProcessSchemeSvgDefs />
      </svg>
    </div>
  )
}
