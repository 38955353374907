import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState, useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import processBgImgSrc from 'pages/customProcess/components/sein/assets/process-framework-bg.png'
import samsungLogoImgSrc from 'pages/customProcess/components/sein/assets/samsung-logo.png'
import { DeliverPhaseSvg } from 'pages/customProcess/components/sein/components/DeliverPhaseSvg'
import { DesignPhaseSvg } from 'pages/customProcess/components/sein/components/DesignPhaseSvg'
import { DiscoverPhaseSvg } from 'pages/customProcess/components/sein/components/DiscoverPhaseSvg'
import styles from 'pages/customProcess/components/sein/SEINProcess.module.scss'
import { Phases } from 'pages/customProcess/components/sein/types'

export const SEINProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const activePhaseStyles = useMemo(() => {
    switch (activePhase) {
      case Phases.Discover:
        return { bgRotation: styles.bgDiscover, overlayRotation: styles.darkOverlayDiscover }
      case Phases.Design:
        return { bgRotation: styles.bgDesign, overlayRotation: styles.darkOverlayDesign }
      case Phases.Deliver:
        return { bgRotation: styles.bgDeliver, overlayRotation: styles.darkOverlayDeliver }
    }
  }, [activePhase])

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <Flex direction="column" align="center" justify="center" className={styles.root}>
      <Flex align="center" justify="center">
        <div className={styles.processScheme}>
          <img alt="" src={processBgImgSrc} className={clsx(styles.processSchemeBg, activePhaseStyles?.bgRotation)} />
          <img alt="" src={samsungLogoImgSrc} className={clsx(styles.logo, activePhase && styles.logoHidden)} />
          <div className={clsx(styles.phaseWrapper, activePhase === Phases.Discover && styles.phaseActive)}>
            <DiscoverPhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
            />
          </div>
          <div className={clsx(styles.phaseWrapper, activePhase === Phases.Design && styles.phaseActive)}>
            <DesignPhaseSvg activePhase={activePhase} onPhaseEnter={handlePhaseEnter} onPhaseLeave={handlePhaseLeave} />
          </div>
          <div className={clsx(styles.phaseWrapper, activePhase === Phases.Deliver && styles.phaseActive)}>
            <DeliverPhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
            />
          </div>
        </div>
        <div
          className={clsx(
            styles.darkOverlay,
            activePhaseStyles?.overlayRotation,
            activePhase && styles.darkOverlayActive,
          )}
        />
        <div className={clsx(styles.blurOverlay, activePhase && styles.blurOverlayActive)} />
      </Flex>
    </Flex>
  )
}
