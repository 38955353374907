// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RB__4{padding:16px 16px 64px}.e3yxb{background:linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.I3Gau{background:linear-gradient(93.89deg, #33bad8 4.05%, #78e820 81.02%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.lW4Oy{padding:16px 16px 40px}.kW3rM{width:330px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/campaignStrategy/CampaignStrategy.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,mEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,sBAAA,CAGF,OACE,WAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 64px;\n}\n\n.audienceDBTagColor {\n  background: linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.commerceDBTagColor {\n  background: linear-gradient(93.89deg, #33bad8 4.05%, #78e820 81.02%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.outputsCard {\n  padding: 16px 16px 40px;\n}\n\n.mainCard {\n  width: 330px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `RB__4`,
	"audienceDBTagColor": `e3yxb`,
	"commerceDBTagColor": `I3Gau`,
	"outputsCard": `lW4Oy`,
	"mainCard": `kW3rM`
};
export default ___CSS_LOADER_EXPORT___;
