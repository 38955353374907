import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (3).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Channels = () => {
  return (
    <ProcessCard
      type="planning"
      title="Channels & Signals"
      description="Balancing expertise in communications vehicles like TV, Radio, OOH with human data signals"
      previousLink="/custom/fossil/audience"
      nextLink="/custom/fossil/reach"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Channels
