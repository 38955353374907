import { Flex } from 'components/common/flex/Flex'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/mediaActivation/MediaActivation.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const MediaActivation = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/media-plan'
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/campaign-analysis'

  return (
    <ProcessCard
      title="Media Activation"
      subtitle="Activation of campaign into market"
      showRepeatIcon
      footerTitle="Nestle framework"
      footerSubtitle="brilliant basics: Golden Rules, Hygiene BPIs and Ad-Tech standards"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputsOutputsCard title="inputs" text="Media Plan" cardStyles={styles.inputsCard} />
        </Flex>
        <div className={styles.regularCard3Wrapper}>
          <RegularCard
            text="Activate Media"
            teamTags={[
              { text: 'Investment', tags: ['R'] },
              { text: 'Performance', tags: ['R'] },
              { text: 'Strategic Planning', tags: ['I'] },
              { text: 'DS&A', tags: ['I'] },
            ]}
            brandTags={[
              { text: 'CoPilot', style: styles.brand },
              { text: 'Programmatic DSP Setup', style: styles.brand },
              { text: 'Guide', style: styles.brand },
              { text: 'Programmatic QA Document', style: styles.brand },
            ]}
          />
        </div>
        <InputsOutputsCard title="outputs" text="Campaign goes live!" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default MediaActivation
