import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import logo1 from 'pages/customWidget/cocaColaChina/brandHub/assets/01.png'
import logo2 from 'pages/customWidget/cocaColaChina/brandHub/assets/02-Sprite Logo.png'
import logo3 from 'pages/customWidget/cocaColaChina/brandHub/assets/03-Minute Maid-logo.png'
import logo4 from 'pages/customWidget/cocaColaChina/brandHub/assets/04-Fanta Logo.png'
import logo5 from 'pages/customWidget/cocaColaChina/brandHub/assets/05-Chunyue-logo.png'
import logo6 from 'pages/customWidget/cocaColaChina/brandHub/assets/06-Yangguang-logo.png'
import logo7 from 'pages/customWidget/cocaColaChina/brandHub/assets/07-ATH-logo.png'
import styles from 'pages/customWidget/cocaColaChina/brandHub/BrandHubWidget.module.scss'

export const BrandHubWidget = () => (
  <div className={styles.root}>
    <div className={clsx(styles.item, styles.item1)}>
      <img src={logo1} alt="" className={styles.logo} />
      <Link
        to="https://thehub.essencemediacom.com/campaigns/33904/modules/node_1683893280212/maximize/brands/39e77bca-ac71-49a2-92e9-f8f07204cf5e/campaigns/results/18926/media-plans/overall-results"
        target="_blank"
      >
        <WppTypography className={styles.title} type="s-strong">
          Coke Summer Wave1+2 <br /> (adjust AET)
        </WppTypography>
      </Link>
      <Link
        to="https://thehub.essencemediacom.com/campaigns/33904/modules/node_1683893280212/maximize/brands/39e77bca-ac71-49a2-92e9-f8f07204cf5e/campaigns/results/9722/media-plans/overall-results"
        target="_blank"
      >
        <WppTypography className={styles.title} type="s-strong">
          Coke Summer Wave 1+2 <br /> (fixed OOH/TV-C R)
        </WppTypography>
      </Link>
      <Link
        to="https://thehub.essencemediacom.com/campaigns/33909/modules/node_1683893280212/maximize/brands/92683467-0caa-4710-9b7b-3bcb82d6f927/campaigns/results/21163/media-plans/overall-results"
        target="_blank"
      >
        <WppTypography className={styles.title} type="s-strong">
          Coke CNY
        </WppTypography>
      </Link>
    </div>
    <div className={clsx(styles.item, styles.item2)}>
      <img src={logo2} alt="" className={styles.logo} />
      <Link
        to="https://thehub.essencemediacom.com/campaigns/35421/modules/node_1683893280212/maximize/brands/42e6e423-5fe6-4725-9dc2-af3eabd60b5c/campaigns/results/18536/media-plans/overall-results"
        target="_blank"
      >
        <WppTypography className={styles.title} type="s-strong">
          Sprite Summer
        </WppTypography>
      </Link>
      <Link
        to="https://thehub.essencemediacom.com/campaigns/33905/modules/node_1683893280212/maximize/brands/3bf323a6-2fc1-4326-93f3-eef4e89b5104/campaigns/results/12506/media-plans/overall-results"
        target="_blank"
      >
        <WppTypography className={styles.title} type="s-strong">
          Sprite CNY
        </WppTypography>
      </Link>
    </div>
    <div className={clsx(styles.item, styles.item3)}>
      <img src={logo3} alt="" className={styles.logo} />
      <Link
        to="https://thehub.essencemediacom.com/campaigns/33910/modules/node_1683893280212/maximize/brands/979cf253-2963-446f-8e4c-2bd7315aa2fe/campaigns/results/21607/media-plans/overall-results"
        target="_blank"
      >
        <WppTypography className={styles.title} type="s-strong">
          MMJ Thematic
        </WppTypography>
      </Link>
      <Link
        to="https://thehub.essencemediacom.com/campaigns/33910/modules/node_1683893280212/maximize/brands/979cf253-2963-446f-8e4c-2bd7315aa2fe/campaigns/results/5904/media-plans/overall-results"
        target="_blank"
      >
        <WppTypography className={styles.title} type="s-strong">
          MMJ Sparkling
        </WppTypography>
      </Link>
    </div>

    <Link
      to="https://thehub.essencemediacom.com/campaigns/33914/modules/node_1683893280212/maximize/brands/b640f0b8-18e2-4944-81c3-9c974b951293/campaigns/results/12492/media-plans/overall-results"
      target="_blank"
      className={clsx(styles.item, styles.item4)}
    >
      <img src={logo4} alt="" className={styles.logo} />
    </Link>
    <Link
      to="https://thehub.essencemediacom.com/campaigns/33903/modules/node_1683893280212/maximize/brands/13f54733-1edf-4fee-b8c4-6fce83d9f330/campaigns/results/20829/media-plans/overall-results"
      target="_blank"
      className={clsx(styles.item, styles.item5)}
    >
      <img src={logo5} alt="" className={styles.logo} />
    </Link>
    <Link
      to="https://thehub.essencemediacom.com/campaigns/33912/modules/node_1683893280212/maximize/brands/a192e04f-c8f9-4a6d-ab87-e19728018b61/campaigns/results/11833/media-plans/overall-results"
      target="_blank"
      className={clsx(styles.item, styles.item6)}
    >
      <img src={logo6} alt="" className={styles.logo} />
    </Link>
    <Link
      to="https://thehub.essencemediacom.com/campaigns/33906/modules/node_1683893280212/maximize/brands/57afcc56-f047-4015-94e3-76790112ba5d/campaigns/results/18604/media-plans/overall-results"
      target="_blank"
      className={clsx(styles.item, styles.item7)}
    >
      <img src={logo7} alt="" className={styles.logo} />
    </Link>
  </div>
)
