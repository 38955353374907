import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Logo } from 'pages/customProcess/components/boots/assets/logo.svg'
import { FooterNavigation } from 'pages/customProcess/components/boots/processPhases/footerNavigation/FooterNavigation'
import Scheme from 'pages/customProcess/components/boots/processPhases/projectKickOff/assets/project-kick-off.png'
import styles from 'pages/customProcess/components/boots/processPhases/projectKickOff/ProjectKickOff.module.scss'

const ProjectKickOff = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Flex direction="column" className={styles.root}>
      <div className={styles.header}>
        <Flex justify="between" className={styles.headerContent}>
          <div>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn}>
                <WppIconChevron direction="left" slot="icon-start" color="#05054b" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <h1 className={styles.title}>Project Kick-Off</h1>
            <h3 className={styles.subtitle}>Moving from Briefing into Project Initiation</h3>
          </div>
          <Flex align="center" className={styles.logoWrapper}>
            <Logo />
          </Flex>
        </Flex>
      </div>
      <div className={styles.mainContentWrapper}>
        <Flex justify="center" className={styles.mainContent}>
          <img src={Scheme} alt="" />
        </Flex>
      </div>
      <FooterNavigation nextLink="/custom/strategic-response" />
    </Flex>
  )
}

export default ProjectKickOff
