export enum ProcessSchemePhase {
  EMPTY = 'empty',
  WHEN_NECESSARY = 'whenNecessary',
  SYNAPSE = 'synapse',
  SYNAPSE_2 = 'synapse2',
  MEDIA_ACTIVITY_PLAN = 'mediaActivityPlan',
  BAV_AUDIENCE_DB = 'bavAudienceDb',
  SYNAPSE_3 = 'synapse3',
  CHOREOGRAPH_SYNAPSE = 'choreographSynapse',
  NEXUS = 'nexus',
  ADVERITY = 'adverity',
}
