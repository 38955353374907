import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaignStrategy/CampaignStrategy.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const CampaignStrategy = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/campaign-brief'
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/campaign-budget-settings'

  return (
    <ProcessCard
      title="Campaign Strategy"
      subtitle="Set the campaign strategy"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="Media principles"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Details from campaign brief" cardStyles={styles.inputsCard} />
        <RegularCard
          text="Articulate success metrics to inform media planning"
          teamTags={[
            { text: 'Strategic Planning', tags: ['R'] },
            { text: 'DS&A', tags: ['I'] },
            { text: 'Investment', tags: ['I'] },
          ]}
          brandTags={[
            { text: 'Audience Origin', style: styles.audienceDBTagColor },
            { text: 'Audience Finder', style: styles.audienceDBTagColor },
            { text: 'BAV', style: styles.audienceDBTagColor },
            { text: 'Empower (CMI)', style: styles.audienceDBTagColor },
            { text: 'Geograph', style: styles.audienceDBTagColor },
            { text: 'Metrics That Matter', style: styles.audienceDBTagColor },
            { text: 'Scenario Planner', style: styles.audienceDBTagColor },
            { text: 'Whiteboard Brainstorm', style: styles.audienceDBTagColor },
            { text: 'Amazon Marketing Cloud*', style: styles.audienceDBTagColor },
            { text: 'Numerator*', style: styles.audienceDBTagColor },
          ]}
          className={styles.mainCard}
        />
        <InputsOutputsCard
          title="outputs"
          text="Guidelines to determine budgets and tactical media plan"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignStrategy
