import { PhasePoints } from 'pages/customProcess/components/ferreroNew/types'

export const links = {
  [PhasePoints.Challenge]: '/custom/ferrero/challenge',
  [PhasePoints.Audience]: '/custom/ferrero/audience',
  [PhasePoints.Insight]: '/custom/ferrero/insight',
  [PhasePoints.Context]: '/custom/ferrero/context',
  [PhasePoints.Occasion]: '/custom/ferrero/occasion',
  [PhasePoints.Engagement]: '/custom/ferrero/engagement',
  [PhasePoints.Analysis]: '/custom/ferrero/analysis',
  [PhasePoints.Action]: '/custom/ferrero/action',
}
