import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import Logo from 'pages/customProcess/components/no7Beauty/assets/logo.png'
import { FooterNavigation } from 'pages/customProcess/components/no7Beauty/processPhases/footerNavigation/FooterNavigation'
import DiscoveryScheme from 'pages/customProcess/components/no7Beauty/processPhases/strategicResponse/assets/discovery.png'
import StrategyAndStorytellingScheme from 'pages/customProcess/components/no7Beauty/processPhases/strategicResponse/assets/strategy-and-storytelling.png'
import styles from 'pages/customProcess/components/no7Beauty/processPhases/strategicResponse/StrategicResponse.module.scss'

export enum Tabs {
  Discovery = 'Discovery',
  StrategyAndStorytelling = 'StrategyAndStorytelling',
}

const StrategicResponse = () => {
  const [activeTab, setActiveTab] = useState(Tabs.Discovery)

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Flex direction="column" className={styles.root}>
      <div className={styles.header}>
        <Flex justify="between" className={styles.headerContent}>
          <div>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn}>
                <WppIconChevron direction="left" slot="icon-start" color="#05054b" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <Flex className={styles.titleWrapper}>
              <h1 className={styles.title}>Strategic Response:</h1>
              <Flex align="center" className={styles.tabs}>
                <h1
                  className={clsx(
                    styles.title,
                    styles.tabTitle,
                    activeTab !== Tabs.Discovery && styles.inactiveTabTitle,
                  )}
                  onClick={() => setActiveTab(Tabs.Discovery)}
                >
                  Discovery
                </h1>
                <span className={styles.tabSepparator} />
                <h1
                  className={clsx(
                    styles.title,
                    styles.tabTitle,
                    activeTab !== Tabs.StrategyAndStorytelling && styles.inactiveTabTitle,
                  )}
                  onClick={() => setActiveTab(Tabs.StrategyAndStorytelling)}
                >
                  Strategy and Storytelling
                </h1>
              </Flex>
            </Flex>
            <h3 className={styles.subtitle}>
              {
                'This translates into an integrated Strategic approach that gives every\ndiscipline the best possible opportunity to successfully crack the brief together'
              }
            </h3>
          </div>
          <Flex align="center" className={styles.logoWrapper}>
            <img src={Logo} alt="" />
          </Flex>
        </Flex>
      </div>
      <div className={styles.mainContentWrapper}>
        <Flex justify="center" className={styles.mainContent}>
          {activeTab === Tabs.Discovery ? (
            <img src={DiscoveryScheme} alt="" />
          ) : (
            <img src={StrategyAndStorytellingScheme} alt="" />
          )}
        </Flex>
      </div>
      <FooterNavigation previousLink="/custom/n7/project-kick-off" nextLink="/custom/n7/creative-development" />
    </Flex>
  )
}

export default StrategicResponse
