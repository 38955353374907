export enum Phases {
  Discover = 'Discover',
  Plan = 'Plan',
  Activate = 'Activate',
  Measure = 'Measure',
}

export interface PhaseData {
  phaseId: Phases
  title: string
  description: string
  img: string
  color: string
  bgColor: string
  moreInfo: {
    considerations: string[]
    details?: string[]
  }
}
