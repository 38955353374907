import {
  WppActionButton,
  WppButton,
  WppCard,
  WppIconDirections,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import MFGsrc from 'pages/customProcess/components/google/assets/by.png'
import styles from 'pages/customProcess/components/google/GoogleProcess.module.scss'

export const GoogleProcess = () => {
  const phases = [
    {
      title: '01. Pre-discover',
      description: 'ML & PMM establish parameters of brief to set the campaign up for success',
      duration: 1,
      logo: styles.preDiscoverPhase,
      learnMore: 'pre-discover',
    },
    { title: '02. Discover', description: 'Brief agencies', duration: 1, logo: styles.discoverPhase, learnMore: '' },
    {
      title: '03. Devise',
      description: 'Create media strategy & high level plans',
      duration: 1,
      logo: styles.devicePhase,
      learnMore: '',
    },
    {
      title: '04. Design',
      description: 'Create tactical plan and measurement plan',
      duration: 1,
      logo: styles.designPhase,
      learnMore: '',
    },
    {
      title: '05. Deploy',
      description: 'Book media and prepare for launch',
      duration: 1,
      logo: styles.deployPhase,
      learnMore: '',
    },
    {
      title: '06. Drumbeat',
      description: 'Launch and provide quick real-time results',
      duration: 1,
      logo: styles.drumbeatPhase,
      learnMore: '',
    },
    {
      title: '07. Determine',
      description: 'Comprehensive analysis of post-campaign perform...',
      duration: 1,
      logo: styles.determinePhase,
      learnMore: '',
    },
  ]
  const [activePhase, setPhase] = useState(phases[0])

  const getIdx = () => {
    return phases.findIndex(p => p.title === activePhase.title)
  }

  const nextPhase = () => {
    setPhase(phases[getIdx() + 1])
  }

  const previousPhase = () => {
    setPhase(phases[getIdx() - 1])
  }

  return (
    <Flex direction="column">
      <Flex direction="column" className={styles.headerBlock}>
        <div className={styles.background} />
        <Flex className={styles.contentWrapper} direction="column" gap={24}>
          <Flex direction="column" gap={8}>
            <WppTypography type="5xl-display">BluePrint process</WppTypography>
            <WppTypography type="s-body" className={styles.headerDescription}>
              A 7 phase approach from pre-brief to post campaign analysis. All phases of Blueprint may not be used
              depending on the scale and scope of campaign.
            </WppTypography>
          </Flex>
          <Flex gap={12}>
            <WppTypography type="m-strong">by:</WppTypography>
            <img alt="" src={MFGsrc} />
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={24} className={styles.phases}>
        <WppCard className={styles.card}>
          <div className={styles.placeholder}>
            <div className={clsx(styles.logo, activePhase.logo)} />
          </div>
          <Flex className={styles.phasesSteps}>
            <div className={styles.line} />
            <Flex justify="between" className={styles.dots}>
              {phases.map((phase, index) => (
                <div
                  key={index}
                  className={clsx(styles.dot, { [styles.activeDot]: phase.title === activePhase.title })}
                />
              ))}
            </Flex>
          </Flex>
          <Flex className={styles.phaseContent} direction="column" justify="between">
            <Flex direction="column" gap={8}>
              <WppTypography type="xl-heading">{activePhase.title}</WppTypography>
              <WppTypography type="s-body">{activePhase.description}</WppTypography>
              <WppTypography type="s-strong">
                Duration: <WppTypography type="s-body">{activePhase.duration} week</WppTypography>
              </WppTypography>
            </Flex>
            <Flex align="center" justify="between" className={styles.actions}>
              <Flex>
                <WppActionButton disabled={getIdx() === 0} onClick={previousPhase}>
                  <WppIconDirections slot="icon-start" className={styles.previousPhaseIcon} />
                  Previous phase
                </WppActionButton>
                <WppActionButton disabled={getIdx() === phases.length - 1} onClick={nextPhase}>
                  Next phase
                  <WppIconDirections slot="icon-end" />
                </WppActionButton>
              </Flex>
              {!!activePhase.learnMore && (
                <Link to={activePhase.learnMore}>
                  <WppButton>Learn more</WppButton>
                </Link>
              )}
            </Flex>
          </Flex>
        </WppCard>
        <Flex wrap="wrap" gap={24} className={styles.anotherPhases}>
          {phases
            .filter(phase => phase.title !== activePhase.title)
            .map(phase => {
              return (
                <WppCard
                  className={styles.cardPhase}
                  key={phase.title}
                  onClick={() => {
                    setPhase(phase)
                  }}
                >
                  <div className={styles.placeholder}>
                    <div className={clsx(styles.logo, phase.logo)} />
                  </div>
                  <Flex className={styles.phaseContent} direction="column" gap={8}>
                    <WppTypography type="xl-heading">{phase.title}</WppTypography>
                    <WppTypography type="s-body" className={styles.description}>
                      {phase.description}
                    </WppTypography>
                  </Flex>
                </WppCard>
              )
            })}
        </Flex>
      </Flex>
    </Flex>
  )
}
