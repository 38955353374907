// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CvZ5g{position:absolute;top:0;right:0;z-index:1;transform:translateX(70%);transition:transform .5s ease-in-out;pointer-events:none}.BuDqr{transform:translateX(0)}.JtU_m{padding:0 24px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibmNew/processPhases/pages/executionalDetails/ExecutionalDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,SAAA,CACA,yBAAA,CACA,oCAAA,CACA,mBAAA,CAGF,OACE,uBAAA,CAGF,OACE,cAAA","sourcesContent":[".tooltip {\n  position: absolute;\n  top: 0;\n  right: 0;\n  z-index: 1;\n  transform: translateX(70%);\n  transition: transform 0.5s ease-in-out;\n  pointer-events: none;\n}\n\n.tooltipVisible {\n  transform: translateX(0);\n}\n\n.infoWrapper {\n  padding: 0 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `CvZ5g`,
	"tooltipVisible": `BuDqr`,
	"infoWrapper": `JtU_m`
};
export default ___CSS_LOADER_EXPORT___;
