import decodeSchemeImgUrl from 'pages/customProcess/components/tigerBrands/assets/phaseDetails/decode.png'
import { PageLayout } from 'pages/customProcess/components/tigerBrands/processPhases/pageLayout/PageLayout'
import { processPhasesData } from 'pages/customProcess/components/tigerBrands/TigerBrandsConfig'

const Decode = () => {
  const { title, description, color, previousLink } = processPhasesData[5]

  return (
    <PageLayout
      title={title}
      description={description}
      color={color}
      schemeSrc={decodeSchemeImgUrl}
      previousLink={previousLink}
    />
  )
}

export default Decode
