import { WppButton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { BaseErrorState } from 'components/baseErrorState/BaseErrorState'
import styles from 'pages/notFound/NotFound.module.scss'
import { SvgNotFound } from 'pages/notFound/SvgNotFound'
import { routesManager } from 'utils/routesManager'

export const NotFound = () => {
  const { t } = useTranslation()

  return (
    <BaseErrorState
      illustration={<SvgNotFound />}
      mainTitle={t('not_found.title')}
      subTitle={t('not_found.description')}
      className={styles.root}
      actions={
        <Link to={routesManager.systemUrls.core.root.url}>
          <WppButton>{t('not_found.button_text')}</WppButton>
        </Link>
      }
    />
  )
}
