// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nBGUU{padding:16px 16px 40px}.oQipQ{margin-top:100px}.eF2d1{width:200px;padding:16px;background:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m);box-shadow:0 8px 20px rgba(3,70,119,.1)}.ZzqsX{background:linear-gradient(79.87deg, #e733a0 10.42%, #fa426e 31.85%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.Vp8Vp{background:linear-gradient(258.94deg, #53e0ff 14.17%, #04ceaa 86.74%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.I4Kt7{margin-top:24px;padding:16px 16px 64px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestle/processPhases/pages/strategicMediaPlanning/StrategicMediaPlanning.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,gBAAA,CAGF,OACE,WAAA,CACA,YAAA,CACA,oCAAA,CACA,wCAAA,CACA,uCAAA,CAGF,OACE,oEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,qEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,eAAA,CACA,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.inputCardsWrapper {\n  margin-top: 100px;\n}\n\n.inputBottomExtraCard {\n  width: 200px;\n  padding: 16px;\n  background: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: 0 8px 20px rgba(#034677, 10%);\n}\n\n.synapseTagColor {\n  background: linear-gradient(79.87deg, #e733a0 10.42%, #fa426e 31.85%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.architectTagColor {\n  background: linear-gradient(258.94deg, #53e0ff 14.17%, #04ceaa 86.74%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.outputsCard {\n  margin-top: 24px;\n  padding: 16px 16px 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `nBGUU`,
	"inputCardsWrapper": `oQipQ`,
	"inputBottomExtraCard": `eF2d1`,
	"synapseTagColor": `ZzqsX`,
	"architectTagColor": `Vp8Vp`,
	"outputsCard": `I4Kt7`
};
export default ___CSS_LOADER_EXPORT___;
