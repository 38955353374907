import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import actionDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/action-description.svg'
import analysisDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/analysis-description.svg'
import audienceDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/audience-description.svg'
import challengeDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/challenge-description.svg'
import contextDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/context-description.svg'
import engagementDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/engagement-description.svg'
import logoCenterImgSrc from 'pages/customProcess/components/ferreroNew/assets/ferrero-center-logo.png'
import insightDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/insight-description.svg'
import occasionDescriptionImgSrc from 'pages/customProcess/components/ferreroNew/assets/occasion-description.svg'
import processBgImgSrc from 'pages/customProcess/components/ferreroNew/assets/process-bg.png'
import { EngageSvg } from 'pages/customProcess/components/ferreroNew/components/EngageSvg'
import { EvaluateSvg } from 'pages/customProcess/components/ferreroNew/components/EvaluateSvg'
import { InspireSvg } from 'pages/customProcess/components/ferreroNew/components/InspireSvg'
import { PhasePointsChevronsSvg } from 'pages/customProcess/components/ferreroNew/components/PhasePointsChevronsSvg'
import { PhaseTitlesSvg } from 'pages/customProcess/components/ferreroNew/components/PhasesTitlesSvg'
import { links } from 'pages/customProcess/components/ferreroNew/ferreroConfig'
import styles from 'pages/customProcess/components/ferreroNew/FerreroProcess.module.scss'
import { Phases, PhasePoints } from 'pages/customProcess/components/ferreroNew/types'

export const FerreroProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const [activePoint, setActivePoint] = useState<MayBeNull<PhasePoints>>(null)
  const navigate = useNavigate()

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointEnter = useCallback(
    (point: PhasePoints) => () => {
      setActivePoint(point)
    },
    [],
  )

  const handlePointLeave = useCallback(() => {
    setActivePoint(null)
  }, [])

  const handlePointClick = useCallback(
    (point: PhasePoints) => () => {
      navigate(links[point])
    },
    [navigate],
  )

  return (
    <div className={styles.root}>
      <Flex align="center" justify="center" className={styles.pageContent}>
        <div
          className={clsx(
            styles.topLeftDescription,
            activePhase === Phases.Inspire && !activePoint && styles.fullOpacity,
          )}
        >
          <WppTypography tag="h1" className={styles.phaseTitle}>
            INSPIRE
          </WppTypography>
          <div>
            <WppTypography tag="p" type="m-body" className={styles.phaseDescription}>
              {
                'Understand all the driving forces and their impact.\nDefine the core challenge and the growth strategy that addresses it. Identify and validate the key insights that will be the foundation of a clear and inspiring creative brief.'
              }
            </WppTypography>
          </div>
        </div>
        <div
          className={clsx(
            styles.topLeftDescription,
            activePhase === Phases.Engage && !activePoint && styles.fullOpacity,
          )}
        >
          <WppTypography tag="h1" className={styles.phaseTitle}>
            ENGAGE
          </WppTypography>
          <div>
            <WppTypography tag="p" type="m-body" className={styles.phaseDescription}>
              Understanding the cultural context and conversations that surround the challenge, underlying problem and
              solution. Define the “who, what, when, where and how” that is required for the brand and platform idea to
              be distinctive and out positively in culture.
            </WppTypography>
          </div>
        </div>
        <div
          className={clsx(
            styles.topRightDescription,
            activePhase === Phases.Evaluate && !activePoint && styles.fullOpacity,
          )}
        >
          <WppTypography tag="h1" className={styles.phaseTitle}>
            EVALUATE
          </WppTypography>
          <div>
            <WppTypography tag="p" type="m-body" className={styles.phaseDescription}>
              Learn what is working and what is not and set strategies, revised inputs, and actions required to address
              them
            </WppTypography>
          </div>
        </div>
        <div className={styles.scaleDown}>
          <img src={processBgImgSrc} alt="" className={styles.processBg} />
          <div className={clsx(styles.centered, styles.phaseTitles, !!activePhase && styles.phaseTitlesConcealed)}>
            <PhaseTitlesSvg activePhase={activePhase} />
          </div>
          <div className={clsx(styles.centered, styles.phasePointsChevrons)}>
            <PhasePointsChevronsSvg activePoint={activePoint} />
          </div>
          <div className={clsx(styles.centered, styles.inspirePhase)}>
            <InspireSvg
              activePhase={activePhase}
              activePoint={activePoint}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPointClick={handlePointClick}
              onPointEnter={handlePointEnter}
              onPointLeave={handlePointLeave}
            />
          </div>
          <div className={clsx(styles.centered, styles.engagePhase)}>
            <EngageSvg
              activePhase={activePhase}
              activePoint={activePoint}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPointClick={handlePointClick}
              onPointEnter={handlePointEnter}
              onPointLeave={handlePointLeave}
            />
          </div>
          <div className={clsx(styles.centered, styles.evaluatePhase)}>
            <EvaluateSvg
              activePhase={activePhase}
              activePoint={activePoint}
              onPhaseEnter={handlePhaseEnter}
              onPhaseLeave={handlePhaseLeave}
              onPointClick={handlePointClick}
              onPointEnter={handlePointEnter}
              onPointLeave={handlePointLeave}
            />
          </div>
          <img src={logoCenterImgSrc} alt="" className={clsx(styles.centered, styles.logoCenterImg)} />
          <img
            src={challengeDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.challengeDescription,
              activePoint === PhasePoints.Challenge && styles.fullOpacity,
            )}
          />
          <img
            src={audienceDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.audienceDescription,
              activePoint === PhasePoints.Audience && styles.fullOpacity,
            )}
          />
          <img
            src={insightDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.insightDescription,
              activePoint === PhasePoints.Insight && styles.fullOpacity,
            )}
          />
          <img
            src={contextDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.contextDescription,
              activePoint === PhasePoints.Context && styles.fullOpacity,
            )}
          />
          <img
            src={occasionDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.occasionDescription,
              activePoint === PhasePoints.Occasion && styles.fullOpacity,
            )}
          />
          <img
            src={engagementDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.engagementDescription,
              activePoint === PhasePoints.Engagement && styles.fullOpacity,
            )}
          />
          <img
            src={analysisDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.analysisDescription,
              activePoint === PhasePoints.Analysis && styles.fullOpacity,
            )}
          />
          <img
            src={actionDescriptionImgSrc}
            alt=""
            className={clsx(
              styles.centered,
              styles.actionDescription,
              activePoint === PhasePoints.Action && styles.fullOpacity,
            )}
          />
        </div>
      </Flex>
    </div>
  )
}
