// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q9YEk{margin-left:22px;color:#505050;font-weight:400;font-size:14px;font-family:"Noto Sans",sans-serif;line-height:22px;white-space:pre-wrap;list-style-type:disc}.q9YEk li{margin-top:6px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/henkel/processPhases/pages/assets/Assets.module.scss"],"names":[],"mappings":"AAEA,OACE,gBAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,kCAAA,CACA,gBAAA,CACA,oBAAA,CACA,oBAAA,CAEA,UACE,cAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;800&display=swap');\n\n.listHenkel {\n  margin-left: 22px;\n  color: #505050;\n  font-weight: 400;\n  font-size: 14px;\n  font-family: 'Noto Sans', sans-serif;\n  line-height: 22px;\n  white-space: pre-wrap;\n  list-style-type: disc;\n\n  li {\n    margin-top: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listHenkel": `q9YEk`
};
export default ___CSS_LOADER_EXPORT___;
