// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p0rB4{max-width:2157px;max-height:1932px;margin-top:18px;margin-left:-60px}.iZOlF:hover{cursor:pointer}.F6L_I{opacity:1;transition:opacity .35s ease}.ynTi7{opacity:0;transition:opacity .35s ease}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/yumChina/processSvg/ProcessSvg.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CAIA,aACE,cAAA,CAIJ,OACE,SAAA,CACA,4BAAA,CAGF,OACE,SAAA,CACA,4BAAA","sourcesContent":[".svg {\n  max-width: 2157px;\n  max-height: 1932px;\n  margin-top: 18px;\n  margin-left: -60px;\n}\n\n.phaseTrigger {\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.visible {\n  opacity: 1;\n  transition: opacity 0.35s ease;\n}\n\n.hidden {\n  opacity: 0;\n  transition: opacity 0.35s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg": `p0rB4`,
	"phaseTrigger": `iZOlF`,
	"visible": `F6L_I`,
	"hidden": `ynTi7`
};
export default ___CSS_LOADER_EXPORT___;
