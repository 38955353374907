const ORIGIN = window.location.origin

export const routesManager = Object.freeze({
  process: {
    url: '/:processId?',
    getUrl: (id: string = '') => `/${id}`,
  },
  phase: {
    url: '/phase/:phaseId',
    path: 'phase/:phaseId',
    getUrl: (id: string, processId?: string) => `${processId ? `/${processId}` : ''}/phase/${id}`,
  },
  customProcess: {
    url: '/custom',
  },
  customWidget: {
    url: '/custom-widget',
  },
  notFound: {
    url: '/404',
  },
  systemUrls: {
    core: {
      root: {
        url: ORIGIN,
      },
    },
    orchestration: {
      root: {
        url: `${ORIGIN}/orchestration`,
      },
      project: {
        getUrl: (id: string) => `${ORIGIN}/orchestration/project/${id}`,
      },
      projects: {
        url: `${ORIGIN}/orchestration/projects`,
      },
    },
  },
})
