import { Routes, Route } from 'react-router-dom'

import CampaignImplementation from 'pages/customProcess/components/loreal/processPhases/pages/CampaignImplementation'
import CampaignTracking from 'pages/customProcess/components/loreal/processPhases/pages/CampaignTracking'
import MediaBrief from 'pages/customProcess/components/loreal/processPhases/pages/MediaBrief'
import MediaPlanning from 'pages/customProcess/components/loreal/processPhases/pages/MediaPlanning'
import StrategicPlanning from 'pages/customProcess/components/loreal/processPhases/pages/StrategicPlanning'

export const LorealRoutes = () => (
  <Routes>
    <Route path="media-brief" element={<MediaBrief />} />
    <Route path="strategic-planning" element={<StrategicPlanning />} />
    <Route path="media-planning" element={<MediaPlanning />} />
    <Route path="campaign-implementation" element={<CampaignImplementation />} />
    <Route path="campaign-tracking" element={<CampaignTracking />} />
  </Routes>
)
