import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/DescriptionCard.module.scss'
import bulletListIconSrc from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/icons/bullet-list.svg'
import inputIconSrc from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/icons/input.svg'
import peopleIconSrc from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/icons/people.svg'
import taskListIconSrc from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/icons/task-list.svg'

interface Props {
  children: ReactNode
  type: 'discover' | 'decision' | 'design' | 'deliver' | 'adapt'
  icon: 'input' | 'people' | 'bullet-list' | 'task-list'
}

export const DescriptionCard = ({ children, type, icon }: Props) => {
  const iconBg = useMemo(() => {
    switch (type) {
      case 'discover':
        return styles.discoverCardIconBg
      case 'decision':
        return styles.decisionCardIconBg
      case 'design':
        return styles.designCardIconBg
      case 'deliver':
        return styles.deliverCardIconBg
      case 'adapt':
        return styles.adaptCardIconBg
      default:
        return styles.discoverCardIconBg
    }
  }, [type])

  const iconSrc = useMemo(() => {
    switch (icon) {
      case 'input':
        return inputIconSrc
      case 'people':
        return peopleIconSrc
      case 'bullet-list':
        return bulletListIconSrc
      case 'task-list':
        return taskListIconSrc
    }
  }, [icon])

  return (
    <Flex direction="column" gap={16} className={styles.minWidth}>
      <Flex justify="center" align="center" className={clsx([styles.iconContainer, iconBg])}>
        <img src={iconSrc} alt="" />
      </Flex>
      <>{children}</>
    </Flex>
  )
}
