interface Props {
  className?: string
}

export const AttributionMeasurementSvg = ({ className }: Props) => (
  <svg
    width={737}
    height={737}
    viewBox="0 0 737 737"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M135.954 504.314C106.026 453.153 93.8152 393.547 101.216 334.74C108.617 275.933 135.215 221.211 176.886 179.061L253.475 254.78C228.473 280.07 212.514 312.903 208.073 348.188C203.633 383.472 210.959 419.236 228.916 449.932L135.954 504.314Z"
      fill="#8986C7"
    />
    <path
      d="M197.981 467.753C176.083 430.321 167.149 386.71 172.564 343.683C177.979 300.655 197.44 260.617 227.929 229.778L283.967 285.179C265.673 303.683 253.996 327.706 250.747 353.522C247.499 379.338 252.859 405.505 265.997 427.964L197.981 467.753Z"
      fill="#58569A"
    />
    <g clipPath="url(#clip0_60_151)">
      <path
        d="M141.344 409.613C141.26 409.426 141.183 409.231 141.114 409.044C141.046 408.856 140.992 408.65 140.962 408.434C140.819 407.613 140.835 406.831 141.012 406.106C141.188 405.382 141.494 404.732 141.914 404.161C142.342 403.597 142.885 403.11 143.535 402.725C144.185 402.341 144.913 402.078 145.703 401.939C146.612 401.78 147.459 401.805 148.251 402.007C149.044 402.209 149.755 402.565 150.383 403.06C151.011 403.555 151.539 404.174 151.974 404.925C152.41 405.675 152.698 406.498 152.858 407.407C153.019 408.333 153.029 409.209 152.882 410.06C152.735 410.912 152.448 411.665 152.024 412.351C151.6 413.036 151.05 413.605 150.376 414.073C149.702 414.541 148.915 414.859 148.006 415.018C147.375 415.124 146.795 415.169 146.256 415.143C145.718 415.118 145.199 415.05 144.7 414.94C144.202 414.829 143.724 414.692 143.258 414.505C142.792 414.318 142.31 414.133 141.817 413.924L135.154 411.15L134.357 406.62L141.337 409.621L141.344 409.613ZM146.203 405.655C145.413 405.794 144.803 406.167 144.372 406.788C143.948 407.401 143.804 408.122 143.957 408.967C144.11 409.812 144.484 410.439 145.098 410.879C145.711 411.302 146.416 411.448 147.206 411.309C147.995 411.17 148.606 410.798 149.037 410.176C149.46 409.564 149.604 408.842 149.451 407.997C149.307 407.16 148.925 406.525 148.311 406.086C147.698 405.662 146.993 405.516 146.203 405.655Z"
        fill="white"
      />
      <path
        d="M136.411 396.076L136.968 401.118L133.524 401.501L131.987 387.514L135.431 387.131L135.988 392.173L150.232 390.61L150.662 394.504L136.418 396.067L136.411 396.076Z"
        fill="white"
      />
      <path
        d="M131.699 383.882L131.567 379.965L138.195 379.738L137.93 372.124L131.303 372.35L131.171 368.434L148.957 367.817L149.089 371.734L141.401 371.998L141.666 379.612L149.353 379.348L149.485 383.265L131.699 383.882Z"
        fill="white"
      />
      <path
        d="M136.567 346.229C136.142 346.517 135.811 346.925 135.573 347.453C135.336 347.982 135.202 348.491 135.163 348.974C135.136 349.261 135.15 349.552 135.198 349.856C135.246 350.159 135.326 350.46 135.444 350.732C135.57 351.011 135.74 351.237 135.954 351.426C136.176 351.606 136.455 351.715 136.791 351.736C137.331 351.778 137.751 351.604 138.061 351.223C138.37 350.841 138.661 350.356 138.908 349.762C139.155 349.168 139.405 348.533 139.658 347.84C139.911 347.148 140.243 346.521 140.661 345.933C141.072 345.354 141.6 344.885 142.254 344.533C142.907 344.181 143.745 344.037 144.785 344.115C145.767 344.192 146.613 344.437 147.323 344.866C148.025 345.289 148.601 345.83 149.044 346.498C149.479 347.159 149.793 347.915 149.962 348.758C150.131 349.601 150.182 350.481 150.115 351.397C150.032 352.551 149.776 353.609 149.349 354.571C148.921 355.534 148.289 356.403 147.437 357.198L144.59 354.181C145.161 353.821 145.607 353.345 145.945 352.765C146.289 352.177 146.478 351.565 146.523 350.91C146.547 350.591 146.529 350.268 146.487 349.94C146.445 349.611 146.349 349.312 146.215 349.042C146.081 348.772 145.912 348.546 145.683 348.375C145.461 348.195 145.19 348.093 144.871 348.069C144.331 348.028 143.894 348.204 143.553 348.588C143.211 348.973 142.913 349.467 142.643 350.072C142.38 350.667 142.108 351.329 141.839 352.023C141.563 352.726 141.225 353.378 140.807 353.966C140.389 354.554 139.87 355.03 139.234 355.397C138.598 355.763 137.793 355.904 136.81 355.827C135.853 355.757 135.039 355.508 134.345 355.077C133.65 354.646 133.09 354.103 132.656 353.442C132.222 352.78 131.908 352.025 131.731 351.191C131.555 350.357 131.499 349.502 131.555 348.635C131.629 347.636 131.847 346.68 132.197 345.758C132.557 344.844 133.091 344.041 133.813 343.332L136.544 346.24L136.567 346.229Z"
        fill="white"
      />
      <path
        d="M132.939 338.608L134.658 326.645L138.237 327.166L137.073 335.254L140.354 335.724L141.46 328.089L145.039 328.61L143.933 336.245L147.512 336.766L148.743 328.233L152.322 328.754L150.535 341.162L132.925 338.625L132.939 338.608Z"
        fill="white"
      />
      <path
        d="M135.548 321.379L136.736 316.18L150.476 311.558L150.487 311.508L138.433 308.759L139.311 304.94L156.656 308.909L155.512 313.909L141.436 318.655L141.425 318.705L153.771 321.529L152.893 325.348L135.548 321.379Z"
        fill="white"
      />
      <path
        d="M147.592 292.742C147.116 292.929 146.694 293.249 146.352 293.707C146.003 294.174 145.759 294.638 145.611 295.092C145.525 295.36 145.466 295.651 145.45 295.961C145.424 296.264 145.44 296.571 145.495 296.866C145.55 297.161 145.668 297.433 145.838 297.659C146.016 297.892 146.252 298.054 146.58 298.158C147.092 298.324 147.548 298.252 147.939 297.952C148.33 297.651 148.718 297.245 149.086 296.72C149.462 296.201 149.85 295.633 150.248 295.015C150.656 294.404 151.12 293.869 151.655 293.391C152.19 292.913 152.813 292.58 153.523 292.376C154.232 292.172 155.083 292.23 156.074 292.549C157.014 292.841 157.791 293.28 158.377 293.844C158.973 294.416 159.407 295.077 159.687 295.819C159.968 296.562 160.092 297.369 160.069 298.232C160.045 299.096 159.9 299.963 159.626 300.845C159.277 301.945 158.794 302.921 158.159 303.758C157.523 304.596 156.712 305.306 155.7 305.882L153.605 302.3C154.24 302.079 154.787 301.714 155.247 301.219C155.707 300.725 156.039 300.17 156.233 299.533C156.331 299.23 156.387 298.908 156.418 298.58C156.44 298.245 156.424 297.938 156.36 297.636C156.296 297.334 156.173 297.087 155.996 296.869C155.819 296.652 155.583 296.49 155.28 296.392C154.768 296.226 154.305 296.306 153.889 296.601C153.473 296.897 153.062 297.313 152.678 297.84C152.286 298.36 151.875 298.939 151.453 299.567C151.031 300.196 150.544 300.742 150.009 301.22C149.474 301.698 148.852 302.047 148.152 302.258C147.451 302.469 146.634 302.424 145.694 302.132C144.779 301.846 144.036 301.419 143.464 300.837C142.892 300.255 142.466 299.601 142.186 298.859C141.906 298.117 141.781 297.309 141.798 296.455C141.815 295.6 141.945 294.75 142.208 293.919C142.514 292.961 142.935 292.081 143.491 291.268C144.048 290.455 144.751 289.789 145.614 289.268L147.613 292.715L147.592 292.742Z"
        fill="white"
      />
      <path
        d="M145.777 284.517L150.176 273.258L153.546 274.58L150.578 282.19L153.669 283.403L156.47 276.217L159.847 277.531L157.046 284.717L160.422 286.03L163.558 277.997L166.935 279.31L162.368 290.993L145.786 284.524L145.777 284.517Z"
        fill="white"
      />
    </g>
    <path
      d="M206.781 375.87L206.619 368.985C206.591 368.082 206.665 367.214 206.822 366.406C206.979 365.599 207.254 364.864 207.652 364.249C208.05 363.634 208.595 363.128 209.274 362.749C209.961 362.377 210.821 362.175 211.863 362.15C213.124 362.117 214.191 362.424 215.092 363.049C215.994 363.673 216.569 364.625 216.828 365.868L224.235 361.161L224.349 365.865L217.335 369.754L217.379 371.691L224.483 371.521L224.578 375.443L206.79 375.877L206.781 375.87ZM214.05 371.771L213.999 369.459C213.988 369.11 213.965 368.738 213.931 368.343C213.896 367.948 213.812 367.601 213.682 367.277C213.545 366.963 213.342 366.699 213.076 366.502C212.801 366.298 212.428 366.207 211.939 366.214C211.491 366.224 211.131 366.327 210.865 366.513C210.598 366.7 210.406 366.941 210.261 367.232C210.133 367.521 210.035 367.846 210 368.219C209.957 368.585 209.945 368.946 209.95 369.304L210.014 371.867L214.066 371.769L214.05 371.771Z"
      fill="white"
    />
    <path
      d="M206.536 357.98L206.863 351.357C206.904 350.591 206.992 349.818 207.138 349.045C207.284 348.272 207.54 347.581 207.9 346.98C208.259 346.379 208.743 345.898 209.335 345.541C209.943 345.18 210.721 345.032 211.699 345.073C212.702 345.12 213.527 345.446 214.154 346.038C214.782 346.63 215.223 347.398 215.459 348.326L215.51 348.335C215.627 347.754 215.811 347.236 216.076 346.764C216.34 346.292 216.673 345.899 217.067 345.579C217.46 345.259 217.913 345.012 218.428 344.853C218.944 344.694 219.48 344.622 220.049 344.66C220.986 344.7 221.758 344.944 222.361 345.377C222.965 345.809 223.446 346.35 223.797 347.008C224.148 347.666 224.395 348.389 224.524 349.195C224.652 350 224.696 350.786 224.66 351.583L224.301 358.865L206.523 357.998L206.536 357.98ZM221.191 354.772L221.361 351.259C221.373 350.955 221.359 350.647 221.305 350.336C221.25 350.026 221.15 349.739 220.989 349.477C220.836 349.222 220.632 349 220.361 348.828C220.106 348.653 219.781 348.555 219.395 348.539C218.984 348.519 218.637 348.603 218.364 348.799C218.091 348.995 217.885 349.254 217.733 349.555C217.585 349.871 217.479 350.205 217.411 350.567C217.342 350.928 217.296 351.278 217.287 351.598L217.141 354.56L221.188 354.756L221.191 354.772ZM213.837 354.416L213.976 351.577C213.988 351.273 213.968 350.974 213.916 350.68C213.864 350.385 213.766 350.114 213.632 349.873C213.498 349.631 213.305 349.432 213.062 349.28C212.819 349.129 212.519 349.034 212.174 349.021C211.803 349.002 211.495 349.073 211.247 349.216C210.999 349.359 210.799 349.552 210.657 349.802C210.505 350.045 210.4 350.323 210.333 350.643C210.267 350.963 210.214 351.265 210.202 351.569L210.068 354.227L213.852 354.413L213.837 354.416Z"
      fill="white"
    />
    <path
      d="M208.323 334.217L208.732 330.995L227.36 325.569L226.798 329.957L222.861 330.993L221.981 337.846L225.53 339.791L224.985 344.078L208.311 334.194L208.323 334.217ZM213.646 333.382L218.857 336.23L219.405 331.918L213.646 333.382Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_60_151">
        <rect width={119.773} height={122.377} fill="white" transform="translate(169.745 439.206) rotate(-140.941)" />
      </clipPath>
    </defs>
  </svg>
)
