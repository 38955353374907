import { InputsOutputsCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestle/processPhases/pages/campaignStrategy/CampaignStrategy.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestle/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const CampaignStrategy = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/campaign-brief' : '/custom/campaign-brief'
  const nextLink = isDemoTenant ? '/nestle/process/campaign-budget-setting' : '/custom/campaign-budget-setting'

  return (
    <ProcessCard
      title="Campaign Strategy"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="Media principles"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="A fully aligned Brief with KPIs, CEF, SCT."
          cardStyles={styles.inputsCard}
        />
        <RegularCard
          text="Translation to actionable audiences"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[{ text: 'Audience Database®', style: styles.audienceDBTagColor }]}
        />
        <RegularCard
          text="Enrich Consumer Experience framework"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[{ text: 'Commerce Database®', style: styles.commerceDBTagColor }]}
        />
        <InputsOutputsCard
          title="outputs"
          text="Strategic confirmation and alignment"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignStrategy
