import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { PhaseCard } from 'pages/customProcess/components/loreal/components/phaseCard/PhaseCard'
import { processPhasesData } from 'pages/customProcess/components/loreal/LorealConfig'
import styles from 'pages/customProcess/components/loreal/LorealProcess.module.scss'

export const LorealProcess = () => {
  const [activePhaseIndex, setActivePhaseIndex] = useState<MayBeNull<number>>(null)

  const handlePhaseEnter = useCallback(
    (phaseIndex: number) => () => {
      setActivePhaseIndex(phaseIndex)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhaseIndex(null)
  }, [])

  return (
    <div className={styles.root}>
      <Flex justify="between" align="center" className={styles.header}>
        <div>
          <WppTypography type="4xl-display" tag="h1" className={styles.title1}>
            Accelerated Growth Planning Process
          </WppTypography>
          <WppTypography type="s-body" tag="p" className={styles.description}>
            Building desire through multi-platform, co-ordinated, integrated and distinctive comms experiences
          </WppTypography>
        </div>
        <div>
          <WppTypography type="4xl-display" tag="h1" className={styles.title2}>
            Beauty Tech Lab
          </WppTypography>
        </div>
      </Flex>
      <Flex gap={8}>
        {processPhasesData.map((phase, index) => (
          <PhaseCard
            key={index}
            phaseIndex={index}
            title={phase.title}
            isActive={index === activePhaseIndex}
            onPhaseEnter={handlePhaseEnter}
            onPhaseLeave={handlePhaseLeave}
            steps={phase.steps}
            tools={phase.tools}
            link={phase.link}
          />
        ))}
      </Flex>
    </div>
  )
}
