// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tuz7s{margin-top:8px}.rKFZk{left:auto !important;max-width:100%}.stxaD::part(input)::-webkit-contacts-auto-fill-button{visibility:hidden;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/components/common/autocomplete/Autocomplete.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAGF,OACE,oBAAA,CACA,cAAA,CAIA,uDACE,iBAAA,CACA,mBAAA","sourcesContent":[".value {\n  margin-top: 8px;\n}\n\n.pill {\n  left: auto !important;\n  max-width: 100%;\n}\n\n.autocomplete {\n  &::part(input)::-webkit-contacts-auto-fill-button {\n    visibility: hidden;\n    pointer-events: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value": `Tuz7s`,
	"pill": `rKFZk`,
	"autocomplete": `stxaD`
};
export default ___CSS_LOADER_EXPORT___;
