// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xnyJd{width:200px;height:154px;padding:16px 16px 64px}.roLjV{padding:16px}.r0tvg{background:linear-gradient(90deg, #33bad8 0%, #78e820 111.09%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.iuBf7{background:linear-gradient(321deg, #e733a0 -67.23%, #fa426e 35.64%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/pages/prerequisitesToBEP3/PrerequisitesToBEP3.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,YAAA,CACA,sBAAA,CAGF,OACE,YAAA,CAGF,OACE,8DAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,mEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA","sourcesContent":[".inputsCardAsia {\n  width: 200px;\n  height: 154px;\n  padding: 16px 16px 64px;\n}\n\n.outputsCardAsia {\n  padding: 16px;\n}\n\n.audienceOriginTagColor {\n  background: linear-gradient(90deg, #33bad8 0%, #78e820 111.09%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.bavTagColor {\n  background: linear-gradient(321deg, #e733a0 -67.23%, #fa426e 35.64%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCardAsia": `xnyJd`,
	"outputsCardAsia": `roLjV`,
	"audienceOriginTagColor": `r0tvg`,
	"bavTagColor": `iuBf7`
};
export default ___CSS_LOADER_EXPORT___;
