import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/spotify/processPhases/pages/design/Design.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/spotify/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/spotify/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/spotify/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'design'

const Design = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Design"
      description="Building an actionable campaign architecture, aligned to local insights and global objectives"
      previousLink="/custom/decision"
      nextLink="/custom/deliver"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Optimized Budgets
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Audience Cohorts
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Aligned Brand & Performance outcomes
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Analytics Team',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Consumer Sciences',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Strategy Lead',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Client Teams',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: true },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Channel Optimization
              </WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Choreograph
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Campaign Architecture
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Budget by channel
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Reach & Frequency Sufficiency
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Design
