import audienceOriginLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/audience-origin-logo.svg'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/campaignDiscovery/CampaignDiscovery.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const CampaignDiscovery = () => {
  const previousLink = '/custom/integrated-communications-brief'
  const nextLink = '/custom/campaign-strategic-approach'

  return (
    <ProcessCard
      title="Campaign Discovery"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="Media principles"
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="A fully aligned Brief with KPIs, CEF, SCT."
          cardStyles={styles.inputsCard}
        />
        <RegularCard
          text="Audience Profiling"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[
            { text: 'Audience Origin®', style: styles.audienceOriginTagColor, logoImgUrl: audienceOriginLogoImgUrl },
          ]}
        />
        <RegularCard text="Enrich Consumer Experience framework" teamTags={[{ text: 'Media Teams', tags: ['R'] }]} />
        <InputsOutputsCard
          title="outputs"
          text="Strategic confirmation and alignment"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignDiscovery
