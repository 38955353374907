import { PageLayoutProps } from 'pages/customProcess/components/audible/processPhases/pageLayout/PageLayout'
import { Phases } from 'pages/customProcess/components/audible/types'

export const links = {
  [Phases.Provoke]: '/custom/audible/provoke',
  [Phases.Strategize]: '/custom/audible/strategize',
  [Phases.Plan]: '/custom/audible/plan',
  [Phases.Activate]: '/custom/audible/activate',
  [Phases.Learn]: '/custom/audible/learn',
}

export const provokeContent: PageLayoutProps = {
  type: Phases.Provoke,
  title: 'Provoke',
  description: 'Develop a point of view on Audible’s key challenge',
  previousLink: undefined,
  nextLink: links[Phases.Strategize],
}

export const strategizeContent: PageLayoutProps = {
  type: Phases.Strategize,
  title: 'Strategize',
  description: 'Decision on how Audible can address audience pain points through media',
  previousLink: links[Phases.Provoke],
  nextLink: links[Phases.Plan],
}

export const planContent: PageLayoutProps = {
  type: Phases.Plan,
  title: 'Plan',
  description: 'Determine the most appropriate channel mix and tactics to deliver on the strategy',
  previousLink: links[Phases.Strategize],
  nextLink: links[Phases.Activate],
}

export const activateContent: PageLayoutProps = {
  type: Phases.Activate,
  title: 'Activate',
  description: 'Build the appropriate assets and build an infrastructure to bring our plan to life',
  previousLink: links[Phases.Plan],
  nextLink: links[Phases.Learn],
}

export const learnContent: PageLayoutProps = {
  type: Phases.Learn,
  title: 'Learn',
  description: 'Extraction of lessons from our media activity to inform future campaigns',
  previousLink: links[Phases.Activate],
  nextLink: undefined,
}
