import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ferreroNew/processPhases/pages/context/Context.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/ProcessCard'

const Context = () => {
  return (
    <ProcessCard
      title="Context"
      description={
        'Context :understand and map the cultural context, conversations and behaviors that surround\nthe brand and category. Conversation Landscape. Cultural Energy. Platform Requirements.'
      }
      previousLink="/custom/ferrero/insight"
      nextLink="/custom/ferrero/occasion"
    >
      <DescriptionCard icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Cultural narrative,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Audience behaviors,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Media and platform\nbehaviors/consumption,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Social conversation,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Life-stages/ life events
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Activitites
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Cultural energy audit,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Cultural context audit/\nwhitespace analysis,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Conversation mapping,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Insight identification and\nvalidations'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="tools">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                GWI,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Quid,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Brandwatch,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                AYTM
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="people">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Teams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {
                  'Led by strategy. Primary\n= Engagement Strategy\n+ Data Strategy.\nPlanning supporting.\nAcct and creative\ninvolvement/review'
                }
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Cultural Conversation\nOpportunity,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Cultural Energy Definition
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Context
