import { Phases } from 'pages/customProcess/components/ibmNew/types'

export const phaseLinks = {
  [Phases.CentralStrategy]:
    // '/3ccfc23a-0d66-4718-97cd-89dd1818f057/phase/0e427b0a-e34c-4069-9118-5eeaafc6c4df?back-to-custom',
    '/custom/ibm/central-strategy',
  [Phases.ChannelActivity]:
    '/3ccfc23a-0d66-4718-97cd-89dd1818f057/phase/5338ce86-b85b-46b1-981c-65ffb942096d?back-to-custom',
  [Phases.Execution]: '/3ccfc23a-0d66-4718-97cd-89dd1818f057/phase/3d7be6df-f6b5-4432-b6dd-4a9441b4ef2f?back-to-custom',
  [Phases.Performance]:
    '/3ccfc23a-0d66-4718-97cd-89dd1818f057/phase/752b1135-cac0-45b2-a32a-b257d74e9d09?back-to-custom',
  [Phases.Reconciliation]:
    '/3ccfc23a-0d66-4718-97cd-89dd1818f057/phase/a10d7912-c409-41e8-8526-99f755fbc169?back-to-custom',
}
