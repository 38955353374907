import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { TflSVG } from 'pages/customProcess/components/tfl/TflSVG'
import { Phases } from 'pages/customProcess/components/tfl/types'

const phaseLinks: Record<Phases, string> = {
  [Phases.Brief]: '/phase/a16b46bf-daf9-467c-871b-5151d116dc29?back-to-custom',
  [Phases.Strategy]: '/phase/df7f2e76-928f-4185-8aba-edc11214ae1e?back-to-custom',
  [Phases.Planning]: '/phase/9b1680d0-5770-4f77-8f12-ab903cf446b4?back-to-custom',
  [Phases.Booking_Activation]: '/phase/2871c2c1-a51f-48a9-9eb0-e56fdff4373d?back-to-custom',
  [Phases.Reporting]: '/phase/a1a49a32-c40c-4c03-88e2-d616d7615b02?back-to-custom',
}

export const TflProcess = () => {
  const navigate = useNavigate()
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex align="center" justify="center">
      <TflSVG
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
      />
    </Flex>
  )
}
