// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wc2wp{padding:16px 16px 64px}.HNdeE{background:linear-gradient(261.72deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.TFyGN{background:linear-gradient(93.89deg, #33bad8 4.05%, #78e820 81.02%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}._6gjLu{padding:16px 16px 40px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestle/processPhases/pages/campaignStrategy/CampaignStrategy.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,oEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,mEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,QACE,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 64px;\n}\n\n.audienceDBTagColor {\n  background: linear-gradient(261.72deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.commerceDBTagColor {\n  background: linear-gradient(93.89deg, #33bad8 4.05%, #78e820 81.02%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.outputsCard {\n  padding: 16px 16px 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `wc2wp`,
	"audienceDBTagColor": `HNdeE`,
	"commerceDBTagColor": `TFyGN`,
	"outputsCard": `_6gjLu`
};
export default ___CSS_LOADER_EXPORT___;
