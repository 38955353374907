import { FormPhase, FormGroup, FirstLinearPhaseImageSize } from 'types/process/process'

export const mockedPhases: FormPhase[] = [
  {
    title: 'Phase title',
    description: 'Description',
    overlayDescriptions: [
      { label: 'Overlay 1', id: 0 },
      { label: 'Overlay 2', id: 1 },
      { label: 'Overlay 3', id: 2 },
    ],
    image: [],
    colors: [],
    groupTitle: '',
    url: '',
    innerPageEnabled: false,
    templateOptions: {
      phaseImageSize: FirstLinearPhaseImageSize.FULL_WIDTH,
      expandedPhaseImageVisible: true,
      minimizedPhaseImageVisible: false,
    },
    localId: '0f999bb1-80db-426b-82b3-3beab80a35dd',
  },
  {
    title: 'Phase title',
    description: 'Description',
    overlayDescriptions: [
      { label: 'Overlay 1', id: 0 },
      { label: 'Overlay 2', id: 1 },
      { label: 'Overlay 3', id: 2 },
    ],
    image: [],
    colors: [],
    groupTitle: '',
    url: '',
    innerPageEnabled: false,
    templateOptions: {
      phaseImageSize: FirstLinearPhaseImageSize.FULL_WIDTH,
      expandedPhaseImageVisible: true,
      minimizedPhaseImageVisible: false,
    },
    localId: '48c41385-48e0-4056-bb67-6d97523ef2d1',
  },
  {
    title: 'Phase title',
    description: 'Description',
    overlayDescriptions: [
      { label: 'Overlay 1', id: 0 },
      { label: 'Overlay 2', id: 1 },
      { label: 'Overlay 3', id: 2 },
    ],
    image: [],
    colors: [],
    groupTitle: '',
    url: '',
    innerPageEnabled: false,
    templateOptions: {
      phaseImageSize: FirstLinearPhaseImageSize.FULL_WIDTH,
      expandedPhaseImageVisible: true,
      minimizedPhaseImageVisible: false,
    },
    localId: '8095e155-29f6-4131-98f9-3c605ecca7b1',
  },
]

export const mockedGroups: FormGroup[] = [
  {
    phasesLocalIds: [mockedPhases[0].localId!],
    title: 'Group title',
    description: '',
    colors: [],
  },
  {
    phasesLocalIds: [mockedPhases[1].localId!],
    title: 'Group title',
    description: '',
    colors: [],
  },
  {
    phasesLocalIds: [mockedPhases[2].localId!],
    title: 'Group title',
    description: '',
    colors: [],
  },
]
