import discoverHeaderIllustrationImgSrc from 'pages/customProcess/components/lego/assets/headerIllustrations/discover.png'
import measureHeaderIllustrationImgSrc from 'pages/customProcess/components/lego/assets/headerIllustrations/measure.png'
import optimiseHeaderIllustrationImgSrc from 'pages/customProcess/components/lego/assets/headerIllustrations/optimise.png'
import planHeaderIllustrationImgSrc from 'pages/customProcess/components/lego/assets/headerIllustrations/plan.png'
import discoverSchemeImgSrc from 'pages/customProcess/components/lego/assets/phaseSchemes/discover.png'
import measureSchemeImgSrc from 'pages/customProcess/components/lego/assets/phaseSchemes/measure.png'
import optimiseSchemeImgSrc from 'pages/customProcess/components/lego/assets/phaseSchemes/optimise.png'
import planSchemeImgSrc from 'pages/customProcess/components/lego/assets/phaseSchemes/plan.png'
import { PageLayoutProps } from 'pages/customProcess/components/lego/processPhases/pageLayout/PageLayout'
import { Phases } from 'pages/customProcess/components/lego/types'

export const links = {
  [Phases.Discover]: '/custom/lego/discover',
  [Phases.Plan]: '/custom/lego/plan',
  [Phases.Optimise]: '/custom/lego/optimise',
  [Phases.Measure]: '/custom/lego/measure',
}

export const discoverContent: PageLayoutProps = {
  type: Phases.Discover,
  title: 'Discover',
  description: 'Identify market and audience opportunity for growth',
  headerIllustrationImgSrc: discoverHeaderIllustrationImgSrc,
  schemeImgSrc: discoverSchemeImgSrc,
  previousLink: undefined,
  nextLink: links[Phases.Plan],
}

export const planContent: PageLayoutProps = {
  type: Phases.Plan,
  title: 'Plan',
  description: 'Scenario plan against audiences, channels, and platforms',
  headerIllustrationImgSrc: planHeaderIllustrationImgSrc,
  schemeImgSrc: planSchemeImgSrc,
  previousLink: links[Phases.Discover],
  nextLink: links[Phases.Optimise],
}

export const optimiseContent: PageLayoutProps = {
  type: Phases.Optimise,
  title: 'Optimise',
  description: 'Implement, monitor, and maximize in-market activities',
  headerIllustrationImgSrc: optimiseHeaderIllustrationImgSrc,
  schemeImgSrc: optimiseSchemeImgSrc,
  previousLink: links[Phases.Plan],
  nextLink: links[Phases.Measure],
}

export const measureContent: PageLayoutProps = {
  type: Phases.Measure,
  title: 'Measure',
  description: 'Quantify and analyse campaign effectiveness',
  headerIllustrationImgSrc: measureHeaderIllustrationImgSrc,
  schemeImgSrc: measureSchemeImgSrc,
  previousLink: links[Phases.Optimise],
  nextLink: undefined,
}
