// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rfjjb{width:100%;margin-top:25px}.mLJlv{width:100%;height:11px;border-bottom:4px solid var(--wpp-primary-color-400);border-left:4px solid var(--wpp-primary-color-400)}.ENC4Z{width:100%;height:11px;border-right:4px solid var(--wpp-primary-color-400);border-bottom:4px solid var(--wpp-primary-color-400)}.Jhg5y{margin-top:-2px}.Jhg5y::part(typography){text-align:center}.MpGV0{display:-webkit-box;max-width:176px;padding-top:10px;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:12;-webkit-box-orient:vertical}.MpGV0::part(typography){text-align:center}`, "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/linear1/group/Group.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,eAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,oDAAA,CACA,kDAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,mDAAA,CACA,oDAAA,CAGF,OACE,eAAA,CAEA,yBACE,iBAAA,CAIJ,OACE,mBAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CACA,sBAAA,CACA,qBAAA,CACA,2BAAA,CAEA,yBACE,iBAAA","sourcesContent":[".footer {\n  width: 100%;\n  margin-top: 25px;\n}\n\n.leftBorder {\n  width: 100%;\n  height: 11px;\n  border-bottom: 4px solid var(--wpp-primary-color-400);\n  border-left: 4px solid var(--wpp-primary-color-400);\n}\n\n.rightBorder {\n  width: 100%;\n  height: 11px;\n  border-right: 4px solid var(--wpp-primary-color-400);\n  border-bottom: 4px solid var(--wpp-primary-color-400);\n}\n\n.groupTitle {\n  margin-top: -2px;\n\n  &::part(typography) {\n    text-align: center;\n  }\n}\n\n.groupDescription {\n  display: -webkit-box;\n  max-width: 176px;\n  padding-top: 10px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  -webkit-line-clamp: 12;\n  -webkit-box-orient: vertical;\n\n  &::part(typography) {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `rfjjb`,
	"leftBorder": `mLJlv`,
	"rightBorder": `ENC4Z`,
	"groupTitle": `Jhg5y`,
	"groupDescription": `MpGV0`
};
export default ___CSS_LOADER_EXPORT___;
