import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/spotify/processPhases/pages/discover/Discover.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/spotify/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/spotify/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/spotify/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'discover'

const Discover = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Discover"
      description="Activating and modeling proprietary audience data to identify high quality markets, audiences, and
  local insights to achieve client objectives"
      nextLink="/custom/decision"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Client Briefing
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Client Audience Data
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Client Creative
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Brand Strategy',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Data & Analytics',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Consumer Sciences',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Client teams',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: true },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Source of Growth
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Audience Segmentation
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Comms Strategy
              </WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Choreograph
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Synapse
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Scout Network
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                High Value Markets
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                High Value Audiences
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Hero Insights & Trends
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Discover
