// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JRA6U{max-width:var(--wpp-os-content-max-width);margin:0 auto;padding:32px 38px}.D72ag{margin-bottom:45px}._90L9{display:block;max-width:353px;margin-bottom:16px}._90L9::part(typography){font-weight:700}.OZZSa{max-width:353px}.FeaqR::part(typography){font-weight:700}.sGNZn{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/loreal/LorealProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,yCAAA,CACA,aAAA,CACA,iBAAA,CAGF,OACE,kBAAA,CAGF,OACE,aAAA,CACA,eAAA,CACA,kBAAA,CAEA,yBACE,eAAA,CAIJ,OACE,eAAA,CAIA,yBACE,eAAA,CAIJ,OACE,UAAA","sourcesContent":[".root {\n  max-width: var(--wpp-os-content-max-width);\n  margin: 0 auto;\n  padding: 32px 38px;\n}\n\n.header {\n  margin-bottom: 45px;\n}\n\n.title1 {\n  display: block;\n  max-width: 353px;\n  margin-bottom: 16px;\n\n  &::part(typography) {\n    font-weight: 700;\n  }\n}\n\n.description {\n  max-width: 353px;\n}\n\n.title2 {\n  &::part(typography) {\n    font-weight: 700;\n  }\n}\n\n.schemeImg {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `JRA6U`,
	"header": `D72ag`,
	"title1": `_90L9`,
	"description": `OZZSa`,
	"title2": `FeaqR`,
	"schemeImg": `sGNZn`
};
export default ___CSS_LOADER_EXPORT___;
