import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/orange/OrangeProcess.module.scss'

export const OrangeProcess = () => {
  const [hoverEl, setHover] = useState('none')

  const checkIsHover = useCallback(
    (process: string) => {
      if (hoverEl === 'none') {
        return null
      }
      return hoverEl === process ? styles.hovered : styles.shadow
    },
    [hoverEl],
  )

  return (
    <Flex align="center" justify="center" className={hoverEl}>
      <div className={styles.mainWrapper}>
        <div className={styles.mainContainer} onMouseLeave={() => setHover('none')}>
          {Array.from({ length: 6 }).map((item, idx) => (
            <div
              key={idx}
              className={clsx(styles.sector, styles[`section0${idx + 1}`], checkIsHover(`section0${idx + 1}`))}
              onMouseOver={() => setHover(`section0${idx + 1}`)}
            >
              <div className={styles.descActive} />
              <div className={styles.desc} />
              <div className={styles.def} />
              <div className={styles.emp} />
            </div>
          ))}
        </div>
      </div>
    </Flex>
  )
}
