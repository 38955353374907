import {
  WppActionButton,
  WppIconChevron,
  WppTypography,
  WppButton,
  WppIconArrow,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as HeaderLogoSvg } from 'pages/customProcess/components/shell/assets/header-logo.svg'
import { ReactComponent as DocumentIcon } from 'pages/customProcess/components/shell/assets/icons/document.svg'
import { ReactComponent as GearsIcon } from 'pages/customProcess/components/shell/assets/icons/gears.svg'
import { ReactComponent as LightBulbIcon } from 'pages/customProcess/components/shell/assets/icons/light-bulb.svg'
import { ReactComponent as PieChartIcon } from 'pages/customProcess/components/shell/assets/icons/pie-chart.svg'
import styles from 'pages/customProcess/components/shell/processPhases/pageLayout/PageLayout.module.scss'
import { Phases } from 'pages/customProcess/components/shell/types'
import { routesManager } from 'utils/routesManager'

interface Props {
  type: Phases
  color: string
  title: string
  description: string
  schemeImgSrc: string
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({ type, color, title, description, schemeImgSrc, previousLink, nextLink }: Props) => {
  const headerBg = useMemo(() => {
    switch (type) {
      case Phases.Discover:
        return styles.discoverHeader
      case Phases.Plan:
        return styles.planHeader
      case Phases.Optimise:
        return styles.optimiseHeader
      case Phases.Measure:
        return styles.measureHeader
      default:
        return styles.discoverHeader
    }
  }, [type])

  const icon = useMemo(() => {
    switch (type) {
      case Phases.Discover:
        return <LightBulbIcon width={42} />
      case Phases.Plan:
        return <DocumentIcon width={42} />
      case Phases.Optimise:
        return <GearsIcon width={42} />
      case Phases.Measure:
        return <PieChartIcon width={42} />
      default:
        return <LightBulbIcon width={42} />
    }
  }, [type])

  return (
    <div className={styles.root}>
      <Flex direction="column" className={styles.page}>
        <Flex className={clsx(styles.headerWrapper, headerBg)}>
          <div className={styles.colorBlock} style={{ backgroundColor: color }} />
          <Flex className={styles.header}>
            <div className={styles.headerContent}>
              <Link to="/custom">
                <WppActionButton variant="inverted">
                  <WppIconChevron slot="icon-start" direction="left" /> Back to Process overview
                </WppActionButton>
              </Link>
              <Flex gap={22} className={styles.titleWrapper}>
                {icon}
                <WppTypography type="5xl-display" className={styles.whiteText}>
                  {title}
                </WppTypography>
              </Flex>
              <WppTypography type="2xl-heading" className={clsx(styles.whiteText, styles.preWrap, styles.description)}>
                {description}
              </WppTypography>
            </div>
            <HeaderLogoSvg className={styles.logo} />
          </Flex>
        </Flex>
        <Flex direction="column" justify="between" className={styles.contentCard}>
          <img src={schemeImgSrc} alt="" className={styles.schemeImg} />
          <Flex justify="between" align="center" gap={24}>
            <Flex gap={16}>
              <Link to={previousLink ?? ''}>
                <WppActionButton disabled={!previousLink} className={styles.navButton}>
                  <Flex gap={4} align="center">
                    <WppIconArrow direction="left" color="#000000" />
                    <WppTypography type="s-strong" className={styles.navButtonText}>
                      Previous phase
                    </WppTypography>
                  </Flex>
                </WppActionButton>
              </Link>
              <Link to={nextLink ?? ''}>
                <WppActionButton disabled={!nextLink} className={styles.navButton}>
                  <Flex gap={4} align="center">
                    <WppTypography type="s-strong" className={styles.navButtonText}>
                      Next phase
                    </WppTypography>
                    <WppIconArrow color="#000000" />
                  </Flex>
                </WppActionButton>
              </Link>
            </Flex>
            <Link to={routesManager.systemUrls.orchestration.root.url}>
              <WppButton className={styles.goToBtn}>Go to project details</WppButton>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
