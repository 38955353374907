export const ProcessSchemeSvgDefs = () => (
  <defs>
    <linearGradient
      id="paint24_linear_295_35042"
      x1="74.25"
      y1="512"
      x2="102"
      y2="556.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint23_linear_295_32794"
      x1="486.5"
      y1="341"
      x2="697.758"
      y2="395.878"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    <filter
      id="filter12_d_295_32794"
      x="444.941"
      y="188.821"
      width="278.059"
      height="220.298"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.905882 0 0 0 0 0.2 0 0 0 0 0.627451 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_32794" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_32794" result="shape" />
    </filter>
    <linearGradient
      id="paint24_linear_295_32794"
      x1="687.25"
      y1="280"
      x2="715"
      y2="324.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint23_linear_295_35042"
      x1="259"
      y1="538.5"
      x2="94.4127"
      y2="345.921"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#BE47E8" />
      <stop offset="1" stopColor="#6D39DA" />
    </linearGradient>
    <linearGradient
      id="paint26_linear_295_33363"
      x1="647.25"
      y1="503"
      x2="675"
      y2="547.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint25_linear_295_33363"
      x1="711"
      y1="514.5"
      x2="519.5"
      y2="554.5"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#FF5656" />
      <stop offset="1" stopColor="#FDC46E" />
    </linearGradient>
    <linearGradient
      id="paint24_linear_295_33363"
      x1="431.5"
      y1="434.5"
      x2="750"
      y2="453"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#33BAD8" />
      <stop offset="1" stopColor="#78E820" />
    </linearGradient>
    <linearGradient
      id="paint33_linear_295_33899"
      x1="473.25"
      y1="649"
      x2="501"
      y2="693.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <filter
      id="filter22_d_295_33899"
      x="356.823"
      y="412.71"
      width="228.658"
      height="291.401"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.905882 0 0 0 0 0.2 0 0 0 0 0.627451 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_33899" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_33899" result="shape" />
    </filter>
    <linearGradient
      id="paint32_linear_295_33899"
      x1="366"
      y1="501"
      x2="543.204"
      y2="662.384"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    <linearGradient
      id="paint35_linear_295_34492"
      x1="255.25"
      y1="655"
      x2="283"
      y2="699.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <filter
      id="filter18_d_317_37283"
      x="136.144"
      y="422.878"
      width="268.236"
      height="289.122"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.32549 0 0 0 0 0.878431 0 0 0 0 1 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_317_37283" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_317_37283" result="shape" />
    </filter>
    <linearGradient id="paint34_linear_317_37283" x1="366" y1="521" x2="233" y2="551.5" gradientUnits="userSpaceOnUse">
      <stop stopColor="#53E0FF" />
      <stop offset="1" stopColor="#04CEAA" />
    </linearGradient>
    <filter
      id="filter19_d_317_37283"
      x="135.654"
      y="519.079"
      width="268.78"
      height="194.922"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-2" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_317_37283" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_317_37283" result="shape" />
    </filter>
    <linearGradient
      id="paint35_linear_317_37283"
      x1="166.5"
      y1="633.5"
      x2="384.53"
      y2="633.272"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    <linearGradient
      id="paint24_linear_317_37283"
      x1="30.25"
      y1="280"
      x2="58"
      y2="324.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint38_linear_317_37283"
      x1="143.102"
      y1="89"
      x2="170.852"
      y2="133.25"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0001" stopColor="#3F8EF7" />
      <stop offset="1" stopColor="#74FBC4" />
    </linearGradient>
    <linearGradient
      id="paint21_linear_317_37283"
      x1="32"
      y1="679.685"
      x2="437.376"
      y2="26.1194"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#6E7BFF" />
      <stop offset="1" stopColor="#4AA3FF" />
    </linearGradient>
    <filter
      id="filter21_d_317_37283"
      x="56.4907"
      y="30.2266"
      width="293.61"
      height="300.591"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.0666667 0 0 0 0 0.807843 0 0 0 0 0.909804 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_317_37283" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_317_37283" result="shape" />
    </filter>
    <linearGradient
      id="paint37_linear_317_37283"
      x1="88"
      y1="205.5"
      x2="158.329"
      y2="22.559"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#11CEE8" />
      <stop offset="1" stopColor="#079FE0" />
    </linearGradient>
    {/* Start Outside Circle Arrows gradients */}
    <linearGradient
      id="paint22_linear_292_32254"
      x1="483.116"
      y1="32.0571"
      x2="494.326"
      y2="47.7793"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint23_linear_292_32254"
      x1="660.28"
      y1="178.128"
      x2="660.226"
      y2="197.437"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint24_linear_292_32254"
      x1="687.564"
      y1="406.191"
      x2="706.561"
      y2="409.65"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint25_linear_292_32254"
      x1="586.28"
      y1="592.604"
      x2="600.104"
      y2="606.084"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint26_linear_292_32254"
      x1="372.849"
      y1="678.709"
      x2="372.48"
      y2="698.015"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint27_linear_292_32254"
      x1="157.649"
      y1="604.015"
      x2="143.054"
      y2="616.657"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint28_linear_292_32254"
      x1="49.0636"
      y1="417.793"
      x2="29.8643"
      y2="419.849"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint29_linear_292_32254"
      x1="81.5247"
      y1="202.834"
      x2="65.1982"
      y2="192.525"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      id="paint30_linear_292_32254"
      x1="251.005"
      y1="53.9911"
      x2="244.17"
      y2="35.9321"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    {/* End Outside Circle Arrows gradients */}
    <linearGradient id="k" x1={4.5} x2={547} y1={868.5} y2={81.5} gradientUnits="userSpaceOnUse">
      <stop stopColor="#B1E6FC" />
      <stop offset={1} stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient id="B" x1={435} x2={651.458} y1={258.5} y2={209.564} gradientUnits="userSpaceOnUse">
      <stop stopColor="#E733A0" />
      <stop offset={1} stopColor="#FA426E" />
    </linearGradient>
    <linearGradient id="D" x1={578.25} x2={606} y1={87} y2={131.25} gradientUnits="userSpaceOnUse">
      <stop offset={0} stopColor="#3F8EF7" />
      <stop offset={1} stopColor="#74FBC4" />
    </linearGradient>
    <filter
      id="A"
      width={273.293}
      height={282.348}
      x={395.969}
      y={29.775}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dx={4} dy={-4} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.905882 0 0 0 0 0.2 0 0 0 0 0.627451 0 0 0 0.32 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter id="C" width={72} height={72} x={560} y={76} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dx={8} dy={7} />
      <feGaussianBlur stdDeviation={9} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.142083 0 0 0 0 0.317222 0 0 0 0 0.55 0 0 0 0.12 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="F"
      width={64.005}
      height={64.004}
      x={664.747}
      y={276}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="G"
      width={64.004}
      height={64.004}
      x={625.077}
      y={500.998}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="H"
      width={64.007}
      height={64.007}
      x={450.057}
      y={647.854}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="I"
      width={64.006}
      height={64.007}
      x={221.59}
      y={647.854}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="J"
      width={64.004}
      height={64.004}
      x={46.573}
      y={500.998}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="K"
      width={64.004}
      height={64.004}
      x={6.899}
      y={275.999}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <filter
      id="L"
      width={64.001}
      height={64.001}
      x={121.136}
      y={78.141}
      colorInterpolationFilters="sRGB"
      filterUnits="userSpaceOnUse"
    >
      <feFlood floodOpacity={0} result="BackgroundImageFix" />
      <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
      <feOffset dy={10} />
      <feGaussianBlur stdDeviation={10} />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix values="0 0 0 0 0.338333 0 0 0 0 0.493614 0 0 0 0 0.7 0 0 0 0.2 0" />
      <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_292_32254" />
      <feBlend in="SourceGraphic" in2="effect1_dropShadow_292_32254" result="shape" />
    </filter>
    <radialGradient
      id="j"
      cx={0}
      cy={0}
      r={1}
      gradientTransform="matrix(-260.0574 358.26695 -379.95871 -275.80294 349.059 354.234)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#D9E1F7" />
      <stop offset={1} stopColor="#fff" stopOpacity={0.5} />
    </radialGradient>
    <radialGradient
      id="y"
      cx={0}
      cy={0}
      r={1}
      gradientTransform="matrix(-194.39315 279.0918 -295.98983 -206.16297 349.169 354.242)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#fff" />
      <stop offset={1} stopColor="#fff" stopOpacity={0.4} />
    </radialGradient>
    <radialGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_radial_15_30103"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(339.694 353.974) rotate(125.364) scale(440.063 466.707)"
    >
      <stop stopColor="#D9E1F7" />
      <stop offset="1" stopColor="white" stopOpacity="0.5" />
    </radialGradient>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint35_linear_15_30103"
      x1="420.68"
      y1="264.68"
      x2="556.18"
      y2="83.1804"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <filter
      xmlns="http://www.w3.org/2000/svg"
      id="filter11_d_15_20661"
      x="572.59"
      y="302.31"
      width="254.41"
      height="220.247"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="4" dy="-4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.905882 0 0 0 0 0.2 0 0 0 0 0.627451 0 0 0 0.32 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_20661" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_20661" result="shape" />
    </filter>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_linear_15_20661"
      x1="584.752"
      y1="438.577"
      x2="783.148"
      y2="538.014"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip0_15_20661">
      <rect width="41" height="99" fill="white" transform="translate(698 384)" />
    </clipPath>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip0_15_26200">
      <rect width="68" height="99" fill="white" transform="translate(573.355 452.466) rotate(-2.44169)" />
    </clipPath>
    <filter
      xmlns="http://www.w3.org/2000/svg"
      id="filter11_d_15_26815"
      x="450.353"
      y="524.015"
      width="220.783"
      height="232.833"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="2" dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.729412 0 0 0 0 0.847059 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_26815" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_26815" result="shape" />
    </filter>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_linear_15_26815"
      x1="464"
      y1="699"
      x2="419.132"
      y2="593.536"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#0CB9D0" />
      <stop offset="1" stopColor="#258EBB" />
    </linearGradient>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint35_linear_15_26815"
      x1="442.5"
      y1="686"
      x2="698.428"
      y2="781.272"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip0_15_26815">
      <rect width="74.4449" height="95.4223" fill="white" transform="translate(550.567 621) rotate(36.3567)" />
    </clipPath>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip1_15_26815">
      <rect width="56.1219" height="85.5396" fill="white" transform="translate(579.502 692.921) rotate(35.7904)" />
    </clipPath>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint36_linear_15_27363"
      x1="221.938"
      y1="532.037"
      x2="318.94"
      y2="740.467"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#E733A0" />
      <stop offset="1" stopColor="#FA426E" />
    </linearGradient>
    <filter
      xmlns="http://www.w3.org/2000/svg"
      id="filter12_d_15_27363"
      x="192.662"
      y="533.548"
      width="219.979"
      height="153.034"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="2" dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.729412 0 0 0 0 0.847059 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_27363" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_27363" result="shape" />
    </filter>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint35_linear_15_27363"
      x1="229.711"
      y1="590.442"
      x2="288.217"
      y2="508.185"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#0CB9D0" />
      <stop offset="1" stopColor="#258EBB" />
    </linearGradient>
    <filter
      xmlns="http://www.w3.org/2000/svg"
      id="filter11_d_15_27363"
      x="196.242"
      y="445.856"
      width="223.923"
      height="235.137"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="2" dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.729412 0 0 0 0 0.847059 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_27363" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_27363" result="shape" />
    </filter>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_linear_15_27363"
      x1="247.945"
      y1="524.666"
      x2="390.383"
      y2="615.882"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#C547E8" />
      <stop offset="1" stopColor="#6D39DA" />
    </linearGradient>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip1_15_27363">
      <rect width="56.1219" height="85.5396" fill="white" transform="translate(321.766 633.666) rotate(80.3177)" />
    </clipPath>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip0_15_27363">
      <rect width="61.2602" height="78.5223" fill="white" transform="translate(336.039 582.548) rotate(79.9142)" />
    </clipPath>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip2_15_27363">
      <rect width="53.0126" height="56.5035" fill="white" transform="translate(344.332 535.215) rotate(74.3136)" />
    </clipPath>
    <filter
      xmlns="http://www.w3.org/2000/svg"
      id="filter11_d_15_27929"
      x="18.7917"
      y="369.775"
      width="273.015"
      height="273.011"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="8" dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.745098 0 0 0 0 0.278431 0 0 0 0 0.909804 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_27929" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_27929" result="shape" />
    </filter>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_linear_15_27929"
      x1="53.5"
      y1="547"
      x2="278"
      y2="506.5"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#33BAD8" />
      <stop offset="1" stopColor="#78E820" />
    </linearGradient>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip0_15_27929">
      <rect width="41.196" height="54.7845" fill="white" transform="translate(180.121 476.362) rotate(115.241)" />
    </clipPath>
    <radialGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_radial_15_31140"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(467.573 475.307) rotate(4.36509) scale(440.063 466.707)"
    >
      <stop stopColor="#D9E1F7" />
      <stop offset="1" stopColor="white" stopOpacity="0.5" />
    </radialGradient>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint35_linear_15_31140"
      x1="349.323"
      y1="451.877"
      x2="123.96"
      y2="429.207"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint35_linear_15_29027"
      x1="149.576"
      y1="161.693"
      x2="343.666"
      y2="186.209"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#FF5656" />
      <stop offset="1" stopColor="#FDC46E" />
    </linearGradient>
    <filter
      xmlns="http://www.w3.org/2000/svg"
      id="filter11_d_15_29027"
      x="170.464"
      y="161.713"
      width="243.901"
      height="246.672"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dx="2" dy="4" />
      <feGaussianBlur stdDeviation="10" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.729412 0 0 0 0 0.847059 0 0 0 0.4 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15_29027" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15_29027" result="shape" />
    </filter>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_linear_15_29027"
      x1="353.102"
      y1="206.32"
      x2="430.591"
      y2="290.767"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#0CB9D0" />
      <stop offset="1" stopColor="#258EBB" />
    </linearGradient>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip0_15_29027">
      <rect width="57.1026" height="79.4073" fill="white" transform="translate(242.958 248.856) rotate(-159.822)" />
    </clipPath>
    <clipPath xmlns="http://www.w3.org/2000/svg" id="clip1_15_29027">
      <rect width="54.7176" height="68.6071" fill="white" transform="translate(305.853 311.432) rotate(-165)" />
    </clipPath>
    <radialGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint34_radial_15_30635"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(456.287 481.993) rotate(85.4421) scale(440.063 466.707)"
    >
      <stop stopColor="#D9E1F7" />
      <stop offset="1" stopColor="white" stopOpacity="0.5" />
    </radialGradient>
    <linearGradient
      xmlns="http://www.w3.org/2000/svg"
      id="paint35_linear_15_30635"
      x1="461.093"
      y1="361.54"
      x2="448.533"
      y2="135.388"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#B1E6FC" />
      <stop offset="1" stopColor="#DFE0FC" />
    </linearGradient>
  </defs>
)
