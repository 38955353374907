export enum Phases {
  Strategy = 'Strategy',
  Planning = 'Planning',
  Activation = 'Activation',
}

export enum PhaseDetails {
  UncoverChallenge = 'Uncover Challenge',
  DefineSuccess = 'Define Success',
  AudiencePlanning = 'Audience Planning',
  OutcomeMonitoring = 'Outcome Monitoring',
  AutomationOpportunity = 'Automation Opportunity',
  AttributionMeasurement = 'Attribution Measurement',
  ChannelsAndSignals = 'Channels & Signals',
  ReachAndEngagement = 'Reach & Engagement',
  ExperienceAndInnovation = 'Experience & Innovation',
}
