export enum ProcessPhase {
  Grow = 'grow',
  Plan = 'plan',
  Translate = 'translate',
  Activate = 'activate',
  Optimize = 'optimize',
  Report = 'report',
  Learn = 'learn',
  default = 'all',
}
