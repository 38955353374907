import { Flex } from 'components/common/flex/Flex'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/strategicMediaPlanning/StrategicMediaPlanning.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const StrategicMediaPlanning = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/campaign-budget-settings'
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/media-plan'

  return (
    <ProcessCard
      title="Strategic Media Planning"
      subtitle="Set tactical plan including target audience, flighting and potential partners"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputsOutputsCard
            title="inputs"
            text="Campaign strategy and budget setting"
            cardStyles={styles.inputsCard}
          />
        </Flex>
        <RegularCard
          text="Define channel mix, effective reach, optimal audience archetypes reach weighted by comms tasks or sales and sufficiency of spend"
          teamTags={[
            { text: 'Strategic Planning', tags: ['R'] },
            { text: 'Investment', tags: ['C'] },
            { text: 'DS&A', tags: ['I'] },
          ]}
          brandTags={[
            { text: 'Outcomes Framework', style: styles.synapseTagColor },
            { text: 'Architect', style: styles.synapseTagColor },
            { text: 'Brand to Demand', style: styles.synapseTagColor },
            { text: 'Scenario Planner', style: styles.synapseTagColor },
            { text: 'Vivvix*', style: styles.synapseTagColor },
            { text: 'Numerator*', style: styles.synapseTagColor },
            { text: 'IRI*', style: styles.synapseTagColor },
            { text: 'Kroger*', style: styles.synapseTagColor },
            { text: 'Amazon*', style: styles.synapseTagColor },
            { text: 'MRI*', style: styles.synapseTagColor },
            { text: 'NMI*', style: styles.synapseTagColor },
          ]}
          className={styles.mainCard}
        />
        <InputsOutputsCard title="outputs" text="Foundation for the Media Plan" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default StrategicMediaPlanning
