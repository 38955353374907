export enum Phases {
  Discover = 'Discover',
  Define = 'Define',
  Design = 'Design',
  Develop = 'Develop',
  Deliver = 'Deliver',
  Distil = 'Distil',
}

export interface IPointPopupConfig {
  description?: string
  title?: string
  placement:
    | 'left-top'
    | 'left-bottom'
    | 'left-bottom-long'
    | 'left-top-long'
    | 'right-top'
    | 'right-top-short'
    | 'right-bottom'
    | 'right-bottom-short'
    | 'left-top-short'
}
