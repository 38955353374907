// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._v1Lk{padding:16px}.zdnZu{background:linear-gradient(90.42deg, #11cee8 -1.01%, #079fe0 18.98%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.He84P{margin-top:-120px}.miMIQ{margin-top:175px}.xKaLJ{margin-top:140px}.jCWGR{margin-top:24px;padding:16px 16px 40px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestle/processPhases/pages/campaingAnalysis/CampaingAnalysis.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,oEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iBAAA,CAGF,OACE,gBAAA,CAGF,OACE,gBAAA,CAGF,OACE,eAAA,CACA,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px;\n}\n\n.adverityTagColor {\n  background: linear-gradient(90.42deg, #11cee8 -1.01%, #079fe0 18.98%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard1Wrapper {\n  margin-top: -120px;\n}\n\n.regularCard2Wrapper {\n  margin-top: 175px;\n}\n\n.regularCard3Wrapper {\n  margin-top: 140px;\n}\n\n.outputsCard {\n  margin-top: 24px;\n  padding: 16px 16px 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `_v1Lk`,
	"adverityTagColor": `zdnZu`,
	"regularCard1Wrapper": `He84P`,
	"regularCard2Wrapper": `miMIQ`,
	"regularCard3Wrapper": `xKaLJ`,
	"outputsCard": `jCWGR`
};
export default ___CSS_LOADER_EXPORT___;
