import schemeImg from 'pages/customProcess/components/procterAndGamble/assets/audience.png'
import { ProcessPandGCard } from 'pages/customProcess/components/procterAndGamble/processPhases/processPandGCard/ProcessPandGCard'

const Audience = () => {
  return (
    <ProcessPandGCard
      title="Audience"
      description="Cover more markets and cross-market budget allocation to maximize NOS & fast forecasting"
      previousLink="/custom/p&g/ambition"
      nextLink="/custom/p&g/activation"
    >
      <img src={schemeImg} alt="" />
    </ProcessPandGCard>
  )
}

export default Audience
