import { WppButton, WppIconGear } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { BaseErrorState } from 'components/baseErrorState/BaseErrorState'
import { SvgNoRecentWork } from 'components/svg/SvgNoRecentWork'
import styles from 'pages/processBuilder/emptyState/EmptyState.module.scss'

export const EmptyState = ({ isAdmin, onConfigureClick }: { isAdmin: boolean; onConfigureClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <BaseErrorState
      illustration={<SvgNoRecentWork className={styles.img} />}
      mainTitle={t('process.empty_state.title')}
      subTitle={t('process.empty_state.description')}
      className={styles.root}
      actions={
        isAdmin ? (
          <WppButton onClick={onConfigureClick}>
            {t('process.empty_state.button_text')} <WppIconGear slot="icon-start" />
          </WppButton>
        ) : null
      }
    />
  )
}
