interface Props {
  className?: string
}

export const AutomationOpportunitySvg = ({ className }: Props) => (
  <svg
    width={602}
    height={602}
    viewBox="0 0 602 602"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M33.6356 334.413C26.2312 275.606 38.4381 215.999 68.3632 164.837C98.2882 113.675 144.259 73.8165 199.146 51.4441L239.798 151.177C206.866 164.601 179.284 188.516 161.329 219.213C143.373 249.91 136.049 285.674 140.492 320.958L33.6356 334.413Z"
      fill="#8986C7"
    />
    <path
      d="M105.042 325.183C99.6247 282.156 108.556 238.544 130.451 201.111C152.346 163.677 185.981 134.515 226.14 118.146L255.883 191.116C231.788 200.938 211.607 218.436 198.47 240.896C185.333 263.356 179.974 289.523 183.225 315.339L105.042 325.183Z"
      fill="#58569A"
    />
    <path
      d="M157.236 248.322C157.854 248.524 158.441 248.776 158.986 249.069C159.533 249.366 159.983 249.736 160.334 250.187C160.685 250.637 160.905 251.189 160.992 251.839C161.081 252.492 160.979 253.272 160.685 254.184C160.341 255.249 159.791 256.064 159.048 256.635C158.306 257.205 157.355 257.422 156.21 257.287L156.426 254.354C156.768 254.42 157.096 254.365 157.407 254.181C157.718 253.997 157.93 253.738 158.036 253.411C158.149 253.068 158.15 252.777 158.038 252.529C157.929 252.285 157.761 252.075 157.537 251.904C157.313 251.733 157.056 251.591 156.762 251.474C156.471 251.361 156.2 251.258 155.945 251.179L147.325 248.379L148.289 245.415L157.236 248.322Z"
      fill="white"
    />
    <path
      d="M151.45 237.251L152.455 234.88L167.872 234.73L166.503 237.965L163.269 237.921L161.126 242.978L163.427 245.232L162.087 248.395L151.449 237.257L151.45 237.251ZM155.707 237.75L159.085 241.043L160.432 237.863L155.707 237.75Z"
      fill="white"
    />
    <path
      d="M156.237 226.602L158.845 221.792C159.19 221.159 159.575 220.593 160.006 220.095C160.435 219.593 160.909 219.207 161.432 218.936C161.948 218.664 162.522 218.535 163.144 218.545C163.765 218.555 164.438 218.76 165.164 219.153C166.042 219.63 166.665 220.26 167.031 221.049C167.396 221.838 167.427 222.716 167.111 223.684L174.075 223.373L172.295 226.655L165.919 226.556L165.186 227.905L170.151 230.602L168.665 233.339L156.238 226.596L156.237 226.602ZM162.864 226.653L163.74 225.04C163.873 224.795 164.006 224.532 164.139 224.245C164.269 223.961 164.355 223.682 164.388 223.412C164.42 223.141 164.384 222.882 164.275 222.638C164.17 222.391 163.944 222.174 163.604 221.989C163.289 221.815 163 221.747 162.744 221.77C162.486 221.8 162.254 221.883 162.048 222.03C161.839 222.174 161.649 222.37 161.475 222.608C161.302 222.845 161.145 223.089 161.012 223.334L160.04 225.127L162.863 226.66L162.864 226.653Z"
      fill="white"
    />
    <path
      d="M162.515 215.14L164.499 212.19L174.138 214.461L174.161 214.427L168.457 206.309L170.317 203.54L178.798 216.268L177.403 218.339L162.517 215.144L162.515 215.14Z"
      fill="white"
    />
    <path d="M172.057 201.462L173.974 199.007L185.118 207.71L183.201 210.164L172.057 201.462Z" fill="white" />
    <path
      d="M183.686 192.939C183.28 192.917 182.876 193.017 182.474 193.241C182.072 193.464 181.745 193.719 181.489 194.008C181.338 194.176 181.205 194.369 181.085 194.581C180.965 194.794 180.878 195.019 180.821 195.254C180.763 195.488 180.76 195.715 180.813 195.939C180.866 196.162 180.991 196.36 181.189 196.537C181.508 196.82 181.858 196.916 182.239 196.826C182.62 196.735 183.034 196.563 183.473 196.308C183.916 196.05 184.382 195.765 184.871 195.452C185.359 195.14 185.88 194.9 186.419 194.73C186.959 194.56 187.523 194.52 188.102 194.606C188.682 194.692 189.281 195.013 189.898 195.558C190.488 196.08 190.899 196.643 191.147 197.253C191.392 197.858 191.495 198.48 191.448 199.108C191.409 199.739 191.235 200.361 190.938 200.979C190.642 201.597 190.251 202.179 189.764 202.727C189.154 203.413 188.486 203.961 187.757 204.363C187.028 204.764 186.2 205.014 185.283 205.107L184.928 201.833C185.46 201.875 185.972 201.795 186.47 201.582C186.964 201.372 187.387 201.076 187.732 200.684C187.9 200.498 188.048 200.284 188.175 200.048C188.303 199.813 188.391 199.58 188.438 199.347C188.485 199.114 188.481 198.886 188.427 198.669C188.372 198.453 188.25 198.258 188.057 198.09C187.739 197.807 187.379 197.705 186.979 197.785C186.579 197.866 186.152 198.035 185.692 198.287C185.235 198.542 184.756 198.825 184.243 199.136C183.733 199.452 183.208 199.694 182.661 199.87C182.114 200.046 181.555 200.093 180.98 200.022C180.405 199.95 179.826 199.651 179.236 199.129C178.671 198.625 178.268 198.074 178.04 197.468C177.809 196.865 177.716 196.249 177.759 195.616C177.799 194.986 177.966 194.362 178.255 193.75C178.54 193.134 178.918 192.566 179.378 192.049C179.908 191.453 180.506 190.948 181.166 190.541C181.83 190.131 182.559 189.884 183.354 189.786L183.687 192.933L183.686 192.939Z"
      fill="white"
    />
    <path
      d="M79.4451 225.863C78.9912 226.029 78.5943 226.285 78.257 226.637C77.9197 226.988 77.6516 227.44 77.4528 227.992C77.2539 228.543 77.178 229.093 77.2249 229.641C77.2719 230.189 77.4236 230.699 77.6817 231.184C77.9437 231.666 78.2994 232.095 78.7543 232.479C79.2133 232.861 79.7465 233.161 80.3609 233.382C80.9888 233.605 81.5958 233.713 82.1848 233.708C82.775 233.697 83.319 233.594 83.825 233.392C84.331 233.19 84.7692 232.899 85.1438 232.516C85.5212 232.137 85.804 231.681 85.9912 231.155C86.2067 230.55 86.2728 229.978 86.1852 229.423C86.1003 228.871 85.8851 228.363 85.5424 227.901L88.2401 226.108C88.8271 226.957 89.1559 227.879 89.2225 228.875C89.2919 229.875 89.1592 230.831 88.8341 231.748C88.4623 232.786 87.9545 233.688 87.3147 234.45C86.6749 235.211 85.9363 235.805 85.1003 236.223C84.2642 236.641 83.3482 236.883 82.356 236.929C81.3638 236.975 80.333 236.811 79.2687 236.428C78.2005 236.047 77.3015 235.526 76.5662 234.856C75.8269 234.189 75.2724 233.429 74.8934 232.572C74.5143 231.715 74.3192 230.79 74.3053 229.794C74.2941 228.801 74.473 227.783 74.842 226.741C74.9776 226.363 75.1483 225.985 75.3648 225.604C75.5786 225.219 75.8334 224.86 76.1235 224.517C76.4137 224.175 76.7531 223.869 77.1322 223.595C77.5113 223.322 77.936 223.105 78.4022 222.949L79.4383 225.862L79.4451 225.863Z"
      fill="white"
    />
    <path
      d="M84.1566 222.838C83.1195 222.382 82.2572 221.794 81.5725 221.077C80.8877 220.361 80.3826 219.561 80.064 218.68C79.7453 217.799 79.6189 216.862 79.678 215.868C79.737 214.874 79.9865 213.873 80.4371 212.863C80.8809 211.852 81.4515 210.99 82.1421 210.276C82.8327 209.562 83.61 209.025 84.4781 208.662C85.3421 208.302 86.2728 208.132 87.2662 208.156C88.2596 208.18 89.2768 208.418 90.3099 208.877C91.3469 209.332 92.2092 209.92 92.894 210.637C93.5787 211.354 94.0838 212.153 94.4025 213.034C94.7212 213.915 94.8476 214.852 94.7885 215.846C94.7294 216.84 94.48 217.841 94.0294 218.851C93.5856 219.862 93.015 220.724 92.3243 221.438C91.6337 222.152 90.8564 222.69 89.9884 223.053C89.1243 223.413 88.1936 223.582 87.2002 223.558C86.2069 223.534 85.1896 223.296 84.1566 222.838ZM85.4609 219.876C86.0706 220.142 86.6689 220.297 87.2585 220.327C87.848 220.358 88.4047 220.281 88.9325 220.094C89.4604 219.907 89.935 219.608 90.362 219.207C90.7889 218.805 91.1331 218.301 91.4026 217.688C91.668 217.079 91.8121 216.482 91.8212 215.895C91.8303 215.309 91.7279 214.758 91.514 214.243C91.3 213.728 90.9788 213.263 90.5532 212.853C90.1315 212.439 89.6153 212.104 89.0068 211.831C88.4107 211.568 87.8151 211.418 87.216 211.382C86.6209 211.343 86.0602 211.423 85.5324 211.61C85.0074 211.801 84.53 212.095 84.103 212.497C83.676 212.898 83.3318 213.403 83.0624 214.015C82.7969 214.625 82.6529 215.222 82.6437 215.808C82.6346 216.395 82.737 216.945 82.951 217.461C83.1677 217.98 83.4889 218.445 83.9173 218.859C84.3457 219.273 84.858 219.612 85.4541 219.875L85.4609 219.876Z"
      fill="white"
    />
    <path
      d="M85.3799 203.036L87.8219 198.388C88.161 197.74 88.5432 197.162 88.9669 196.644C89.3865 196.129 89.8582 195.732 90.371 195.455C90.8838 195.177 91.4516 195.04 92.0745 195.041C92.6973 195.043 93.3788 195.241 94.123 195.633C94.8548 196.015 95.404 196.458 95.7757 196.953C96.1473 197.448 96.3724 197.98 96.4507 198.547C96.5291 199.115 96.4796 199.711 96.3078 200.345C96.136 200.979 95.8751 201.621 95.5268 202.282L94.4688 204.298L99.3495 206.863L97.9 209.623L85.3854 203.044L85.3799 203.036ZM92.1341 203.069L93.1004 201.229C93.23 200.98 93.3312 200.732 93.4066 200.473C93.4808 200.221 93.509 199.97 93.4901 199.729C93.4712 199.487 93.3972 199.26 93.2612 199.046C93.1253 198.832 92.9101 198.649 92.617 198.491C92.3008 198.325 92.0039 198.263 91.7331 198.308C91.4623 198.353 91.2115 198.462 90.9834 198.637C90.7553 198.812 90.5536 199.035 90.3757 199.3C90.1977 199.565 90.0401 199.833 89.9025 200.088L89.1574 201.503L92.1245 203.064L92.1341 203.069Z"
      fill="white"
    />
    <path d="M91.8176 191.14L93.3872 188.451L105.594 195.586L104.024 198.275L91.8176 191.14Z" fill="white" />
    <path
      d="M95.3811 185.108L97.0612 182.485L106.545 188.558L109.657 183.697L112.079 185.249L107.288 192.734L95.3798 185.115L95.3811 185.108Z"
      fill="white"
    />
    <path
      d="M107.019 179.991C106.105 179.32 105.397 178.56 104.88 177.709C104.363 176.859 104.05 175.968 103.929 175.04C103.812 174.108 103.889 173.166 104.165 172.212C104.441 171.258 104.904 170.334 105.557 169.445C106.213 168.553 106.957 167.838 107.788 167.293C108.619 166.749 109.497 166.391 110.415 166.23C111.338 166.066 112.28 166.101 113.245 166.34C114.209 166.58 115.145 167.034 116.059 167.705C116.972 168.376 117.681 169.136 118.198 169.987C118.714 170.837 119.028 171.728 119.145 172.659C119.261 173.591 119.184 174.532 118.908 175.487C118.632 176.441 118.169 177.365 117.517 178.254C116.86 179.146 116.117 179.861 115.286 180.405C114.454 180.95 113.577 181.308 112.654 181.472C111.731 181.636 110.789 181.6 109.825 181.361C108.86 181.122 107.924 180.667 107.011 179.996L107.019 179.991ZM108.937 177.386C109.47 177.781 110.025 178.061 110.591 178.221C111.16 178.384 111.719 178.43 112.278 178.357C112.833 178.287 113.364 178.104 113.864 177.8C114.367 177.5 114.814 177.084 115.21 176.544C115.605 176.011 115.872 175.454 116.007 174.887C116.146 174.317 116.164 173.76 116.065 173.206C115.967 172.653 115.757 172.135 115.438 171.639C115.115 171.146 114.689 170.7 114.151 170.308C113.623 169.921 113.078 169.646 112.507 169.479C111.935 169.311 111.37 169.264 110.814 169.334C110.259 169.404 109.731 169.591 109.228 169.892C108.725 170.192 108.278 170.607 107.882 171.148C107.487 171.681 107.22 172.238 107.085 172.805C106.946 173.375 106.928 173.932 107.027 174.485C107.125 175.039 107.338 175.561 107.664 176.058C107.99 176.555 108.42 176.998 108.941 177.384L108.937 177.386Z"
      fill="white"
    />
    <path
      d="M116.398 160.65L113.795 163.727L111.69 161.95L118.908 153.41L121.013 155.188L118.409 158.265L127.1 165.616L125.09 167.993L116.4 160.643L116.398 160.65Z"
      fill="white"
    />
  </svg>
)
