import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import { DescriptionCard } from 'pages/customProcess/components/spotify/processPhases/processCard/descriptionCard/DescriptionCard'
import styles from 'pages/customProcess/components/spotify/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard.module.scss'

interface Role {
  name: string
  responsibilities: { label: string; active: boolean }[]
}

interface Props {
  type: 'discover' | 'decision' | 'design' | 'deliver' | 'adapt'
  roles: Role[]
}

export const RolesResponsibilitiesCard = ({ type, roles }: Props) => {
  return (
    <DescriptionCard type={type} icon="people">
      <div>
        <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
          Roles & Responsibilities
        </WppTypography>
        {roles.map((role, index) => (
          <Flex gap={8} className={styles.roleWrapper} key={index}>
            <WppTypography type="s-body" className={clsx([styles.whiteText, styles.roleName])}>
              {role.name}
            </WppTypography>
            {role.responsibilities.map((responsibility, index) => (
              <Flex
                justify="center"
                align="center"
                className={clsx(styles.letterDisc, responsibility.active && styles.greenBg)}
                key={index}
              >
                <WppTypography type="2xs-strong" className={styles.whiteText}>
                  {responsibility.label}
                </WppTypography>
              </Flex>
            ))}
          </Flex>
        ))}
      </div>
    </DescriptionCard>
  )
}
