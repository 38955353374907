import { Flex } from 'components/common/flex/Flex'
import { InputOutputInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/inputOutputInfo/InputOutputInfo'
import { ResponsibilitiesInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/responsibilitiesInfo/ResponsibilitiesInfo'
import { PageLayout } from 'pages/customProcess/components/ibmNew/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/ibmNew/processPhases/pages/centralStrategy/CentralStrategy.module.scss'
import { ReactComponent as Scheme } from 'pages/customProcess/components/ibmNew/processPhases/pages/channelActivity/scheme/scheme.svg'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

const ChannelActivity = () => {
  return (
    <PageLayout
      type={Phases.ChannelActivity}
      title="02. Planning: Channel Activity"
      description="The Annual Strategy is taken by objective and been evolved into an applicable plan, considering GEO insights, adding channel, inventory and tactic specifics and turning audiences into buying groups"
      prevLink="/custom/ibm/central-strategy"
      nextLink="/custom/ibm/executional-details"
    >
      <Scheme />
      <Flex gap={16} className={styles.infoWrapper}>
        <ResponsibilitiesInfo />
        <InputOutputInfo />
      </Flex>
    </PageLayout>
  )
}

export default ChannelActivity
