import { Routes, Route } from 'react-router-dom'

import Attribution from 'pages/customProcess/components/fossilNew/processPhases/pages/attribution/Attribution'
import Audience from 'pages/customProcess/components/fossilNew/processPhases/pages/audience/Audience'
import Automation from 'pages/customProcess/components/fossilNew/processPhases/pages/automation/Automation'
import Channels from 'pages/customProcess/components/fossilNew/processPhases/pages/channels/Channels'
import Define from 'pages/customProcess/components/fossilNew/processPhases/pages/define/Define'
import Experience from 'pages/customProcess/components/fossilNew/processPhases/pages/experience/Experience'
import Outcome from 'pages/customProcess/components/fossilNew/processPhases/pages/outcome/Outcome'
import Reach from 'pages/customProcess/components/fossilNew/processPhases/pages/reach/Reach'
import Uncover from 'pages/customProcess/components/fossilNew/processPhases/pages/uncover/Uncover'

export const FossilRoutes = () => (
  <Routes>
    <Route path="uncover" element={<Uncover />} />
    <Route path="define" element={<Define />} />
    <Route path="audience" element={<Audience />} />
    <Route path="channels" element={<Channels />} />
    <Route path="reach" element={<Reach />} />
    <Route path="experience" element={<Experience />} />
    <Route path="outcome" element={<Outcome />} />
    <Route path="automation" element={<Automation />} />
    <Route path="attribution" element={<Attribution />} />
  </Routes>
)
