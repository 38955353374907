import { Flex } from 'components/common/flex/Flex'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/mediaPlan/MediaPlan.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const MediaPlan = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/strategic-media-planning'
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/media-activation'

  return (
    <ProcessCard
      title="Media Plan"
      subtitle="The tactical blueprint to launch a campaign"
      bepTitle="BEP 4"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper} gap={10}>
          <InputsOutputsCard title="input" text="Campaign strategy" cardStyles={styles.inputsCard} />
          <InputsOutputsCard title="input" text="Campaign budget setting" cardStyles={styles.inputsCard} />
          <InputsOutputsCard title="input" text="Strategic media planning guidance" cardStyles={styles.inputsCard} />
        </Flex>
        <div className={styles.regularCard1Wrapper}>
          <RegularCard
            text="Create Detailed Media Channel Plans aligning to earlier strategic planning phases"
            teamTags={[
              { text: 'Strategic Planning', tags: ['R'] },
              { text: 'Investment', tags: ['A'] },
              { text: 'Performance', tags: ['A'] },
              { text: 'DS&A', tags: ['I'] },
            ]}
            brandTags={[
              { text: 'NGP', style: styles.brand },
              { text: 'NMI*', style: styles.brand },
            ]}
          />
        </div>
        <Flex direction="column" align="center" className={styles.outputCardsWrapper} gap={10}>
          <InputsOutputsCard title="outputs" text="Media Plan sign-off" cardStyles={styles.inputsCard} />
          <InputsOutputsCard title="outputs" text="Activation Briefing" cardStyles={styles.inputsCard} />
        </Flex>
      </>
    </ProcessCard>
  )
}

export default MediaPlan
