import { Flex } from 'components/common/flex/Flex'
import { InputCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/inputCard/InputCard'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleChina/processPhases/pages/campaingAnalysis/CampaingAnalysis.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleChina/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const CampaignAnalysis = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/media-activation' : '/custom/ch/media-activation'

  return (
    <ProcessCard
      title="Campaign Analysis"
      bepTitle="BEP 4"
      footerTitle="Nestle framework"
      showRepeatIcon
      footerSubtitle="measurement framework mtm"
      showMroiFooter
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Campaign Performance and Optimisation" cardStyles={styles.inputsCard} />
        <Flex direction="column" align="center" gap={52} className={styles.regularCard1Wrapper}>
          <RegularCard
            text="Analyse campaign performance"
            teamTags={[
              { text: 'Media Teams', tags: ['R', 'A'] },
              { text: 'Nestle Teams', tags: ['I'] },
              { text: 'GDS', tags: ['R'] },
            ]}
            brandTags={[{ text: 'Adverity®', style: styles.adverityTagColor }]}
            bottomHighlightTextSpecial="Analysis and insights"
          />
          <RegularCard
            text="Generate campaign inisghts"
            teamTags={[
              { text: 'Media Teams', tags: ['R', 'A'] },
              { text: 'Nestle Teams', tags: ['I'] },
            ]}
            brandTags={[
              { text: 'Adverity®', style: styles.adverityTagColor },
              { text: 'Meta®', style: styles.adverityTagColor },
            ]}
            brandsDirection="row"
          />
        </Flex>
        <div className={styles.regularCard2Wrapper}>
          <RegularCard
            text="Collect creative and RDM insights to inform further strategic decisioning"
            teamTags={[
              { text: 'Media Teams', tags: ['R'] },
              { text: 'Creative Teams', tags: ['R'] },
              { text: 'Nestle Teams', tags: ['I'] },
            ]}
            brandTags={[{ text: 'Adverity®', style: styles.adverityTagColor }]}
            bottomHighlightText="RDM Outputs"
          />
        </div>
        <Flex direction="column" align="center" className={styles.regularCard3Wrapper}>
          <RegularCard
            text="Optimisation and insights enrichment"
            teamTags={[
              { text: 'Media Teams', tags: ['R'] },
              { text: 'Nestle Teams', tags: ['A'] },
            ]}
          />
          <svg width="6" height="34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 34 2.887-5H.113L3 34Zm.5-4.5V0h-1v29.5h1Z" fill="#307DE4" />
          </svg>
          <InputCard title="input to" text="Media Activation Stage" width={240} />
        </Flex>
        <InputsOutputsCard title="outputs" text="Analysis and insights generation" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default CampaignAnalysis
