// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jhZeQ{margin-top:-180px}.eHDxo{padding:16px 16px 40px}.n9U74{background:linear-gradient(31.81deg, #6e7bff 1.51%, #4aa3ff 85.25%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.Magcl{margin-top:-100px}.c_Uzd{margin-top:56px}.g2gkx{padding:16px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestle/processPhases/pages/mediaActivation/MediaActivation.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAGF,OACE,sBAAA,CAGF,OACE,mEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iBAAA,CAGF,OACE,eAAA,CAGF,OACE,YAAA","sourcesContent":[".inputCardsWrapper {\n  margin-top: -180px;\n}\n\n.inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.redmillTagColor {\n  background: linear-gradient(31.81deg, #6e7bff 1.51%, #4aa3ff 85.25%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard3Wrapper {\n  margin-top: -100px;\n}\n\n.regularCard4Wrapper {\n  margin-top: 56px;\n}\n\n.outputsCard {\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputCardsWrapper": `jhZeQ`,
	"inputsCard": `eHDxo`,
	"redmillTagColor": `n9U74`,
	"regularCard3Wrapper": `Magcl`,
	"regularCard4Wrapper": `c_Uzd`,
	"outputsCard": `g2gkx`
};
export default ___CSS_LOADER_EXPORT___;
