import { WppCard, WppIconTick, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/pfizer/businessCaseProcess/BusinessCaseProcess.module.scss'
import { ProcessCard } from 'pages/customProcess/utils/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const BusinessCaseProcess = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/pfizer/process/optimisation' : '/custom/optimisation'
  const nextLink = isDemoTenant ? '/pfizer/process/ideas' : '/custom/ideas'

  const processConfigs: { [key: string]: any; cards: any[] }[] = [
    {
      align: 'center',
      justify: 'start',
      cards: [
        {
          title: 'Brief input parameters',
          content: 'Select a brief out of 5 types, from big strategic brief to fast tactical adaptation',
        },
      ],
    },
    {
      align: 'center',
      justify: 'center',
      direction: 'column',
      cards: [
        { title: 'Brand', content: 'Select a brief out of 5 types' },
        { title: 'Markets', content: 'Define target markets. E.g. US, Brazil, UK, Japan' },
        {
          title: 'Objectives',
          content:
            '“Grow share of prescriptions for Ritlecitenib among healthcare professionals treating alopecia areata”',
        },
        { title: 'Timings', content: 'Activity duration period: 1 October 2023 to 31 March 2024' },
      ],
    },
    {
      align: 'center',
      justify: 'around',
      direction: 'column',
      className: styles.shadowWrapper,
      cards: [
        {
          title: 'Source of growth analysis',
          content: (
            <Flex direction="row" gap={8}>
              <div className={clsx(styles.miro, styles.appIcon)} />
              <div className={clsx(styles.adobe, styles.appIcon)} />
              <div className={clsx(styles.apps, styles.appIcon)} />
              <div className={clsx(styles.office, styles.appIcon)} />
            </Flex>
          ),
        },
        {
          title: 'Scientific platform',
          content: (
            <Flex direction="row" gap={8}>
              <div className={clsx(styles.office, styles.appIcon)} />
              <div className={clsx(styles.gpt, styles.appIcon)} />
              <div className={clsx(styles.starfish, styles.appIcon)} />
            </Flex>
          ),
        },
      ],
    },
  ]

  return (
    <ProcessCard
      title="Business Case"
      description="Sets up each individual use case - following a global criteria for success"
      projectLink={isDemoTenant ? '/pfizer/project-example' : ''}
      nextLink={nextLink}
      previousLink={previousLink}
    >
      <div className={styles.wrapper}>
        <Flex justify="around">
          {processConfigs.map(({ align, justify, direction, cards, className }, i) => (
            <Flex align={align} key={i} justify={justify} direction={direction} className={className}>
              {cards.map(card => (
                <WppCard key={card.title} className={clsx(styles.card, card?.className)}>
                  <div slot="header">
                    <WppTypography type="m-strong">{card.title}</WppTypography>
                  </div>
                  <WppTypography type="s-body">{card.content}</WppTypography>
                </WppCard>
              ))}
            </Flex>
          ))}
        </Flex>
      </div>
      <Flex justify="end" align="end">
        <WppIconTick className={styles.marketplaceTick} />
        <WppTypography type="s-strong" className={styles.marketplaceAnalisis}>
          Marketplace prediction analysis
        </WppTypography>
      </Flex>
    </ProcessCard>
  )
}

export default BusinessCaseProcess
