import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (4).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Reach = () => {
  return (
    <ProcessCard
      type="planning"
      title="Reach & Engagement"
      description="Balancing brand building, awareness, above the line with response / below the line"
      previousLink="/custom/fossil/channels"
      nextLink="/custom/fossil/experience"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Reach
