import { WppTypography, WppTooltip, WppIconRepeatOn } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/nestle/processScheme/processPhaseInfo/ProcessPhaseInfo.module.scss'

interface Props {
  minimized?: boolean
  active: boolean
  tag?: string
  mroiTooltipText?: string
  title: string
  titleTooltipText?: string
  justifyTags?: 'start' | 'end' | 'center'
  showRepeatIcon?: boolean
  onSelectPhase: () => void
}

export const ProcessPhaseInfo = ({
  minimized,
  active,
  tag,
  mroiTooltipText,
  title,
  titleTooltipText,
  justifyTags = 'start',
  showRepeatIcon,
  onSelectPhase,
}: Props) => (
  <div>
    <Flex
      className={clsx(styles.phaseTagsContainer, minimized && styles.minimizedPhaseTagsContainer)}
      gap={8}
      justify={justifyTags}
    >
      {tag && (
        <WppTypography type="2xs-strong" className={styles.tag}>
          {tag}
        </WppTypography>
      )}
      {mroiTooltipText && (
        <WppTooltip text={mroiTooltipText}>
          <WppTypography type="2xs-strong" className={clsx(styles.tag, styles.mroiTag)}>
            mroi
          </WppTypography>
        </WppTooltip>
      )}
    </Flex>
    <button onClick={onSelectPhase} className={styles.titleButton}>
      {titleTooltipText ? (
        <WppTooltip text={titleTooltipText}>
          <Flex align="center" gap={6}>
            {showRepeatIcon && <WppIconRepeatOn color="#3F8EF7" />}
            <pre className={clsx(styles.title, active && styles.activeTitle, minimized && styles.minimizedTitle)}>
              {title}
            </pre>
          </Flex>
        </WppTooltip>
      ) : (
        <Flex align="center" gap={6}>
          {showRepeatIcon && <WppIconRepeatOn color="#3F8EF7" />}
          <pre className={clsx(styles.title, active && styles.activeTitle, minimized && styles.minimizedTitle)}>
            {title}
          </pre>
        </Flex>
      )}
    </button>
  </div>
)
