import { InputsOutputsCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestle/processPhases/pages/bigIdeaDevelopment/BigIdeaDevelopment.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestle/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const BigIdeaDevelopment = ({ isDemoTenant = false }: Props) => {
  const nextLink = isDemoTenant
    ? '/nestle/process/portfolio-allocation-communication-strategy'
    : '/custom/portfolio-allocation-communication-strategy'

  return (
    <ProcessCard
      title="Big Idea Development"
      subtitle="When necessary, can be skipped, if it has been already prepared earlier"
      bepTitle="BEP 1-2"
      isDemoTenant={isDemoTenant}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="Brief is given by Nestle: core insights and OBP are received"
          cardStyles={styles.inputsCard}
        />
        <RegularCard
          text="Big Idea Development"
          teamTags={[
            { text: 'Creative Teams', tags: ['R'] },
            { text: 'Media Teams', tags: ['I'] },
            { text: 'Nestle Teams', tags: ['R'] },
          ]}
        />
        <InputsOutputsCard title="outputs" text="Big Idea is shared with Media Team" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default BigIdeaDevelopment
