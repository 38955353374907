import schemeImg from 'pages/customProcess/components/unilever/assets/SVG-Integrated_Brand_Engagement_Brief.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Integrated = () => {
  return (
    <ProcessCard
      type="challenge"
      title="Integrated Brand Engagement Brief"
      description="Understanding the business climate, business challenge and product we are building a strategy for"
      nextLink="/custom/unilever/interrogate"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Integrated
