import { WppTypography, WppActionButton, WppIconChevron, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import animatedBgWaveImgSrc from 'pages/customProcess/components/audible/assets/animated-bg-wave.gif'
import schemeInfoImgSrc from 'pages/customProcess/components/audible/assets/detail-page-scheme-info.png'
import logoGif from 'pages/customProcess/components/audible/assets/process-center-logo.gif'
import styles from 'pages/customProcess/components/audible/processPhases/pageLayout/PageLayout.module.scss'
import { Phases } from 'pages/customProcess/components/audible/types'
import { routesManager } from 'utils/routesManager'

export interface PageLayoutProps {
  type: Phases
  title: string
  description: string
  children?: React.ReactNode
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({ type, title, description, previousLink, nextLink, children }: PageLayoutProps) => {
  const headerLogoStyles = useMemo(() => {
    switch (type) {
      case Phases.Provoke:
        return { innerCircle: styles.provokeInnerCircle, outerCircle: styles.provokeOuterCircle }
      case Phases.Strategize:
        return { innerCircle: styles.strategizeInnerCircle, outerCircle: styles.strategizeOuterCircle }
      case Phases.Plan:
        return { innerCircle: styles.planInnerCircle, outerCircle: styles.planOuterCircle }
      case Phases.Activate:
        return { innerCircle: styles.activateInnerCircle, outerCircle: styles.activateOuterCircle }
      case Phases.Learn:
        return { innerCircle: styles.learnInnerCircle, outerCircle: styles.learnOuterCircle }
      default:
        return { innerCircle: styles.provokeInnerCircle, outerCircle: styles.provokeOuterCircle }
    }
  }, [type])

  return (
    <div className={styles.rootAudible}>
      <Flex direction="column" className={styles.pageContent}>
        <div className={styles.headerAudible}>
          <div className={styles.headerContent}>
            <img src={animatedBgWaveImgSrc} alt="" className={styles.headerBgWaves} />
            <Flex className={styles.headerTextContent} align="center">
              <div>
                <WppTypography tag="h1" className={styles.titleAudible}>
                  {title}
                </WppTypography>
                <div>
                  <WppTypography tag="p" className={styles.descriptionAudible}>
                    {description}
                  </WppTypography>
                </div>
              </div>
            </Flex>
            <div className={styles.headerLogoWrapper}>
              <div className={clsx(styles.headerCircle1, headerLogoStyles.outerCircle, styles.centered)} />
              <div className={clsx(styles.headerCircle2, headerLogoStyles.innerCircle, styles.centered)} />
              <img src={logoGif} alt="" className={clsx(styles.headerLogo, styles.centered)} />
            </div>
          </div>
        </div>
        <div className={styles.mainContentWrapperAudible}>
          <Flex direction="column" justify="between" className={styles.mainContentAudible}>
            <div>
              <Flex justify="end">
                <img src={schemeInfoImgSrc} alt="" className={styles.schemeInfoImg} />
              </Flex>
              <div>{children}</div>
            </div>
            <Flex align="center" justify="between" className={styles.audibleFooterNav}>
              <Flex gap={16}>
                <Link to={previousLink ?? ''}>
                  <WppActionButton
                    className={clsx(styles.audibleNavButton, !previousLink && styles.audibleNavButtonDisabled)}
                    variant="secondary"
                    disabled={!previousLink}
                  >
                    <Flex align="center" gap={4}>
                      <WppIconChevron
                        slot="icon-end"
                        direction="left"
                        color={!previousLink ? 'var(--wpp-grey-color-500)' : '#f58020'}
                      />
                      Previous phase
                    </Flex>
                  </WppActionButton>
                </Link>
                <Link to={nextLink ?? ''}>
                  <WppActionButton
                    className={clsx(styles.audibleNavButton, !nextLink && styles.audibleNavButtonDisabled)}
                    variant="secondary"
                    disabled={!nextLink}
                  >
                    <Flex align="center" gap={4}>
                      Next phase
                      <WppIconChevron slot="icon-end" color={!nextLink ? 'var(--wpp-grey-color-500)' : '#f58020'} />
                    </Flex>
                  </WppActionButton>
                </Link>
              </Flex>
              <Link to={routesManager.systemUrls.orchestration.root.url}>
                <WppButton className={styles.audibleProjectButton}>Go to project</WppButton>
              </Link>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
