// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k9XEo::part(button){padding:4px 16px;background-color:rgba(0,0,0,0);border:1px solid #000;border-radius:24px}.k9XEo:hover::part(button){cursor:default}.AU9cp::part(button){border:1px solid #fff}.DoaEK::part(typography){color:#000;font-weight:400;font-size:12px;line-height:16px}.Cnq0Q::part(typography){color:#fff}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ikeaNew/components/pill/Pill.module.scss"],"names":[],"mappings":"AACE,qBACE,gBAAA,CACA,8BAAA,CACA,qBAAA,CACA,kBAAA,CAIA,2BACE,cAAA,CAMJ,qBACE,qBAAA,CAKF,yBACE,UAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAKF,yBACE,UAAA","sourcesContent":[".buttonWrapper {\n  &::part(button) {\n    padding: 4px 16px;\n    background-color: transparent;\n    border: 1px solid #000000;\n    border-radius: 24px;\n  }\n\n  &:hover {\n    &::part(button) {\n      cursor: default;\n    }\n  }\n}\n\n.buttonWrapperWhite {\n  &::part(button) {\n    border: 1px solid #ffffff;\n  }\n}\n\n.buttonTitle {\n  &::part(typography) {\n    color: #000000;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n  }\n}\n\n.buttonTitleWhite {\n  &::part(typography) {\n    color: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `k9XEo`,
	"buttonWrapperWhite": `AU9cp`,
	"buttonTitle": `DoaEK`,
	"buttonTitleWhite": `Cnq0Q`
};
export default ___CSS_LOADER_EXPORT___;
