import { ReactNode, useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/DescriptionCard.module.scss'
import bulletListIconSrc from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/icons/bullet-list.svg'
import inputIconSrc from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/icons/input.svg'
import peopleIconSrc from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/icons/people.svg'
import taskListIconSrc from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/icons/task-list.svg'
import toolsIconSrc from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/icons/Tools.svg'

interface Props {
  children: ReactNode
  icon: 'input' | 'people' | 'bullet-list' | 'task-list' | 'tools'
}

export const DescriptionCard = ({ children, icon }: Props) => {
  const iconSrc = useMemo(() => {
    switch (icon) {
      case 'input':
        return inputIconSrc
      case 'people':
        return peopleIconSrc
      case 'bullet-list':
        return bulletListIconSrc
      case 'task-list':
        return taskListIconSrc
      case 'tools':
        return toolsIconSrc
    }
  }, [icon])

  return (
    <Flex direction="column" gap={16} className={styles.descriptionMinWidth}>
      <Flex justify="center" align="center" className={styles.iconContainer}>
        <img src={iconSrc} alt="" className={styles.icon} />
      </Flex>

      <>{children}</>
    </Flex>
  )
}
