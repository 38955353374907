import clsx from 'clsx'
import { MouseEventHandler } from 'react'

import styles from 'pages/customProcess/components/WBA/scheme/Scheme.module.scss'
import { SchemeSVG } from 'pages/customProcess/components/WBA/scheme/SchemeSVG'
import { Phases } from 'pages/customProcess/components/WBA/types'

interface Props {
  className?: string
  activePhase: Phases | null
  hoveredPhase: Phases | null
  onPhaseMouseEnter: (phase: Phases) => MouseEventHandler
  onPhaseMouseLeave: MouseEventHandler
  onPhaseClick: (phase: Phases) => MouseEventHandler
  arrowAnimation: any
}

export const Scheme = ({
  className,
  activePhase,
  hoveredPhase,
  onPhaseMouseEnter,
  onPhaseMouseLeave,
  onPhaseClick,
  arrowAnimation,
}: Props) => (
  <SchemeSVG className={className}>
    {/* shape with arrow animation - gif */}
    <image id="image-arrow-animation" width={560} x={955} y={79} xlinkHref={arrowAnimation} />

    {/* path with strokes in each extrimety to cover the pixelization from gif */}
    <path
      transform="translate(622, 0)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M450.566 345.665L581.096 345.646V345.625L611.866 398.415L550.106 504.466C546.946 511.256 543.136 517.715 538.776 523.665C524.816 542.736 504.786 557.335 482.376 564.745C459.066 572.455 434.156 572.325 411.036 564.365C382.456 554.525 359.456 534.135 346.296 506.926C333.136 479.726 331.416 449.045 341.446 420.535C349.016 399.055 362.826 380.596 381.376 367.186C397.856 355.276 417.056 348.066 437.306 346.156V345.686L446.956 345.646C447.588 345.639 448.23 345.641 448.874 345.643C449.197 345.644 449.522 345.646 449.846 345.646C449.953 345.646 450.056 345.651 450.155 345.656C450.25 345.661 450.343 345.665 450.436 345.665H450.566ZM499.046 495.996L558.406 394.066H558.416V394.036L457.376 394.066L457.266 394.406L451.446 394.136C451.263 394.129 451.081 394.121 450.898 394.113C450.542 394.098 450.187 394.082 449.836 394.076H446.586C419.736 394.756 395.846 411.836 387.136 436.626C381.406 452.896 382.396 470.386 389.896 485.896C397.396 501.396 410.506 513.046 426.806 518.646C453.016 527.656 481.986 518.566 499.046 495.996Z"
      stroke="var(--idea-border-active-color)"
      strokeWidth="8"
      strokeLinejoin="round"
    />
    <path
      transform="translate(603, 40)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M718.476 208.57L663.426 303.12V303.13H601.886L545.456 206.23C542.436 201.77 539.756 197.12 537.506 192.41C517.976 151.66 525.976 103 557.436 71.35C577.526 51.13 604.286 40 632.786 40C661.286 40 688.046 51.13 708.146 71.34C739.616 103.02 747.606 151.7 728.036 192.47C725.346 198.05 722.136 203.46 718.476 208.57ZM632.666 259.74L685.576 168.88C694.706 147.21 690.006 121.78 673.786 105.46C662.856 94.4702 648.296 88.4102 632.786 88.4102C617.276 88.4102 602.716 94.4602 591.786 105.46C574.976 122.39 570.586 148.6 580.846 170.76L632.666 259.74Z"
      stroke="var(--brief-border-active-color)"
      strokeWidth="8"
      strokeLinejoin="round"
    />
    <path
      transform="translate(569, 0)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M731.075 507.73L667.445 398.46L698.215 345.65L838.725 345.62V345.9C860.215 347.26 880.635 354.58 898.175 367.26C917.865 381.5 932.095 401.19 939.345 424.19V424.17C948.415 453.01 945.655 483.61 931.585 510.36C917.515 537.1 893.845 556.71 864.945 565.59C854.265 568.87 843.275 570.53 832.265 570.53C819.155 570.53 806.155 568.19 793.635 563.58C771.475 555.42 751.965 540.16 738.645 520.62C735.905 516.58 733.355 512.25 731.075 507.73ZM825.655 394.25L825.385 394.03L720.895 394.05L779.095 494L779.715 494.87C796.035 517.55 824.594 527.38 850.734 519.36C867.204 514.29 880.694 503.11 888.724 487.86C896.754 472.61 898.315 455.16 893.145 438.72C884.505 411.25 857.925 392.91 828.505 394.13L825.655 394.25Z"
      stroke="var(--design-border-active-color)"
      strokeWidth="8"
      strokeLinejoin="round"
    />

    {/* circle1 shadow - start */}
    <g
      filter="url(#brief_shadow)"
      className={clsx(
        styles.hiddenOpacity,
        (hoveredPhase === Phases.Brief || activePhase === Phases.Brief) && styles.visibleOpacity,
      )}
    >
      <rect x="1115" y="139" width="72" height="72" rx="36" stroke="#F6E302" strokeWidth="6" strokeMiterlimit="10" />
    </g>
    {/* circle1 shadow - end */}
    <g
      className={styles.cursorPointer}
      onMouseEnter={onPhaseMouseEnter(Phases.Brief)}
      onMouseLeave={onPhaseMouseLeave}
      onClick={onPhaseClick(Phases.Brief)}
    >
      <rect
        x={1115}
        y={139}
        width={72}
        height={72}
        rx={36}
        className={clsx(
          styles.briefBg,
          (hoveredPhase === Phases.Brief || activePhase === Phases.Brief) && styles.briefBgActive,
        )}
      />
      <rect
        x={1115}
        y={139}
        width={72}
        height={72}
        rx={36}
        strokeWidth={6}
        strokeMiterlimit={10}
        className={clsx(
          styles.briefBorder,
          (hoveredPhase === Phases.Brief || activePhase === Phases.Brief) && styles.briefBorderActive,
        )}
      />
      <path
        d="M1155.09 162.273V187H1150.61V166.632H1150.46L1144.68 170.326V166.221L1150.82 162.273H1155.09Z"
        className={clsx(
          styles.phaseIndex,
          (hoveredPhase === Phases.Brief || activePhase === Phases.Brief) && styles.phaseIndexActive,
        )}
      />
    </g>
    {/* circle2 shadow - start */}
    <g
      filter="url(#direction_shadow)"
      className={clsx(
        styles.hiddenOpacity,
        (hoveredPhase === Phases.Direction || activePhase === Phases.Direction) && styles.visibleOpacity,
      )}
    >
      <rect x={1278} y={139} width={72} height={72} rx={36} strokeWidth={6} strokeMiterlimit={10} stroke="#F6E302" />
    </g>
    {/* circle2 shadow - end */}
    <g
      className={styles.cursorPointer}
      onMouseEnter={onPhaseMouseEnter(Phases.Direction)}
      onMouseLeave={onPhaseMouseLeave}
      onClick={onPhaseClick(Phases.Direction)}
    >
      <rect
        x={1278}
        y={139}
        width={72}
        height={72}
        rx={36}
        className={clsx(
          styles.briefBg,
          (hoveredPhase === Phases.Direction || activePhase === Phases.Direction) && styles.briefBgActive,
        )}
      />
      <rect
        x={1278}
        y={139}
        width={72}
        height={72}
        rx={36}
        strokeWidth={6}
        strokeMiterlimit={10}
        className={clsx(
          styles.briefBorder,
          (hoveredPhase === Phases.Direction || activePhase === Phases.Direction) && styles.briefBorderActive,
        )}
      />
      <path
        d="M1305.64 187V183.764L1314.22 175.349C1315.04 174.52 1315.73 173.783 1316.28 173.139C1316.82 172.495 1317.23 171.872 1317.51 171.268C1317.78 170.664 1317.92 170.02 1317.92 169.336C1317.92 168.555 1317.74 167.887 1317.39 167.332C1317.03 166.768 1316.55 166.334 1315.93 166.028C1315.31 165.722 1314.6 165.569 1313.81 165.569C1313 165.569 1312.29 165.738 1311.68 166.076C1311.06 166.406 1310.59 166.877 1310.25 167.489C1309.92 168.1 1309.76 168.829 1309.76 169.674H1305.49C1305.49 168.105 1305.85 166.74 1306.57 165.581C1307.28 164.422 1308.27 163.525 1309.53 162.889C1310.79 162.253 1312.24 161.935 1313.87 161.935C1315.53 161.935 1316.99 162.245 1318.24 162.864C1319.5 163.484 1320.47 164.333 1321.17 165.412C1321.87 166.491 1322.22 167.722 1322.22 169.107C1322.22 170.032 1322.04 170.942 1321.69 171.835C1321.33 172.729 1320.71 173.719 1319.81 174.806C1318.93 175.892 1317.68 177.208 1316.08 178.754L1311.82 183.088V183.257H1322.59V187H1305.64Z"
        className={clsx(
          styles.phaseIndex,
          (hoveredPhase === Phases.Direction || activePhase === Phases.Direction) && styles.phaseIndexActive,
        )}
      />
    </g>
    {/* circle6 shadow - start */}
    <g
      filter="url(#design_shadow)"
      className={clsx(
        styles.hiddenOpacity,
        (hoveredPhase === Phases.Delivery || activePhase === Phases.Delivery) && styles.visibleOpacity,
      )}
    >
      <rect
        x={1431.83}
        y={366.685}
        width={71}
        height={71}
        rx={36}
        strokeWidth={6}
        strokeMiterlimit={10}
        stroke="#e5283d"
      />
    </g>
    {/* circle6 shadow - end */}
    <g
      className={styles.cursorPointer}
      onMouseEnter={onPhaseMouseEnter(Phases.Delivery)}
      onMouseLeave={onPhaseMouseLeave}
      onClick={onPhaseClick(Phases.Delivery)}
    >
      <rect
        x={1431.83}
        y={366.685}
        width={71}
        height={71}
        rx={35.5}
        className={clsx(
          styles.designBg,
          (hoveredPhase === Phases.Delivery || activePhase === Phases.Delivery) && styles.designBgActive,
        )}
      />
      <rect
        x={1431.83}
        y={366.685}
        width={71}
        height={71}
        rx={35.5}
        strokeWidth={6}
        strokeMiterlimit={10}
        className={clsx(
          styles.designBorder,
          (hoveredPhase === Phases.Delivery || activePhase === Phases.Delivery) && styles.designBorderActive,
        )}
      />
      <path
        d="M1467.56 415.023C1466.38 415.015 1465.22 414.81 1464.1 414.407C1462.97 413.997 1461.96 413.333 1461.05 412.415C1460.15 411.49 1459.44 410.262 1458.9 408.733C1458.37 407.195 1458.11 405.292 1458.12 403.022C1458.12 400.905 1458.35 399.017 1458.8 397.359C1459.25 395.701 1459.89 394.3 1460.74 393.157C1461.58 392.006 1462.6 391.129 1463.79 390.525C1464.99 389.922 1466.33 389.62 1467.81 389.62C1469.37 389.62 1470.74 389.926 1471.94 390.537C1473.15 391.149 1474.13 391.986 1474.87 393.049C1475.61 394.103 1476.07 395.295 1476.24 396.623H1471.84C1471.61 395.673 1471.15 394.916 1470.45 394.353C1469.75 393.781 1468.88 393.496 1467.81 393.496C1466.1 393.496 1464.78 394.24 1463.85 395.729C1462.94 397.218 1462.47 399.263 1462.47 401.863H1462.64C1463.03 401.154 1463.54 400.547 1464.17 400.04C1464.8 399.532 1465.5 399.142 1466.29 398.868C1467.09 398.587 1467.93 398.446 1468.82 398.446C1470.27 398.446 1471.57 398.792 1472.72 399.484C1473.88 400.176 1474.79 401.13 1475.47 402.346C1476.15 403.553 1476.48 404.938 1476.47 406.499C1476.48 408.125 1476.11 409.586 1475.36 410.882C1474.61 412.17 1473.57 413.184 1472.23 413.925C1470.9 414.665 1469.34 415.031 1467.56 415.023ZM1467.54 411.401C1468.41 411.401 1469.2 411.188 1469.89 410.761C1470.58 410.335 1471.13 409.759 1471.53 409.035C1471.94 408.31 1472.13 407.497 1472.13 406.596C1472.13 405.71 1471.94 404.909 1471.55 404.193C1471.16 403.477 1470.62 402.909 1469.94 402.491C1469.26 402.072 1468.48 401.863 1467.6 401.863C1466.95 401.863 1466.34 401.987 1465.77 402.237C1465.21 402.487 1464.72 402.833 1464.3 403.275C1463.88 403.71 1463.55 404.217 1463.31 404.797C1463.08 405.368 1462.96 405.98 1462.95 406.632C1462.96 407.493 1463.16 408.286 1463.55 409.01C1463.95 409.735 1464.49 410.314 1465.18 410.749C1465.88 411.184 1466.66 411.401 1467.54 411.401Z"
        className={clsx(
          styles.phaseIndex,
          (hoveredPhase === Phases.Experience || activePhase === Phases.Experience) && styles.phaseIndexActive,
        )}
      />
    </g>
    {/* circle7 shadow - start */}
    <g
      filter="url(#effectiveness_shadow)"
      className={clsx(
        styles.hiddenOpacity,
        (hoveredPhase === Phases.Impact || activePhase === Phases.Impact) && styles.visibleOpacity,
      )}
    >
      <rect
        x={1296.83}
        y={477.685}
        width={71}
        height={72}
        rx={36}
        strokeWidth={6}
        strokeMiterlimit={10}
        stroke="#e5283d"
      />
    </g>
    {/* circle7 shadow - end */}
    <g
      className={styles.cursorPointer}
      onMouseEnter={onPhaseMouseEnter(Phases.Impact)}
      onMouseLeave={onPhaseMouseLeave}
      onClick={onPhaseClick(Phases.Impact)}
    >
      <rect
        x={1296.83}
        y={477.685}
        width={71}
        height={72}
        rx={35.5}
        className={clsx(
          styles.designBg,
          (hoveredPhase === Phases.Impact || activePhase === Phases.Impact) && styles.designBgActive,
        )}
      />
      <rect
        x={1296.83}
        y={477.685}
        width={71}
        height={72}
        rx={35.5}
        strokeWidth={6}
        strokeMiterlimit={10}
        className={clsx(
          styles.designBorder,
          (hoveredPhase === Phases.Impact || activePhase === Phases.Impact) && styles.designBorderActive,
        )}
      />
      <path
        d="M1325.46 525.685L1335.97 504.87V504.701H1323.77V500.958H1340.61V504.785L1330.11 525.685H1325.46Z"
        className={clsx(
          styles.phaseIndex,
          (hoveredPhase === Phases.Experience || activePhase === Phases.Experience) && styles.phaseIndexActive,
        )}
      />
    </g>
    {/* circle4 shadow - start */}
    <g
      filter="url(#idea_shadow)"
      className={clsx(
        styles.hiddenOpacity,
        (hoveredPhase === Phases.Idea || activePhase === Phases.Idea) && styles.visibleOpacity,
      )}
    >
      <rect
        x={1100.83}
        y={477.685}
        width={71}
        height={72}
        rx={36}
        strokeWidth={6}
        strokeMiterlimit={10}
        stroke="#013fc2"
      />
    </g>
    {/* circle4 shadow - end */}
    <g
      className={styles.cursorPointer}
      onMouseEnter={onPhaseMouseEnter(Phases.Idea)}
      onMouseLeave={onPhaseMouseLeave}
      onClick={onPhaseClick(Phases.Idea)}
    >
      <rect
        x={1100.83}
        y={477.685}
        width={71}
        height={72}
        rx={35.5}
        className={clsx(
          styles.ideaBg,
          (hoveredPhase === Phases.Idea || activePhase === Phases.Idea) && styles.ideaBgActive,
        )}
      />
      <rect
        x={1100.83}
        y={477.685}
        width={71}
        height={72}
        rx={35.5}
        strokeWidth={6}
        strokeMiterlimit={10}
        className={clsx(
          styles.ideaBorder,
          (hoveredPhase === Phases.Idea || activePhase === Phases.Idea) && styles.ideaBorderActive,
        )}
      />
      <path
        d="M1126.76 521.097V517.535L1137.25 500.958H1140.22V506.029H1138.41L1131.35 517.221V517.415H1145.99V521.097H1126.76ZM1138.56 525.685V520.01L1138.6 518.417V500.958H1142.83V525.685H1138.56Z"
        className={clsx(
          styles.phaseIndex,
          (hoveredPhase === Phases.Idea || activePhase === Phases.Idea) && styles.phaseIndexActive,
        )}
      />
    </g>
    <g
      className={styles.cursorPointer}
      onMouseEnter={onPhaseMouseEnter(Phases.Strategy)}
      onMouseLeave={onPhaseMouseLeave}
      onClick={onPhaseClick(Phases.Strategy)}
    >
      <rect
        x={1198.83}
        y={327.685}
        width={72}
        height={72}
        rx={36}
        className={clsx(
          styles.strategyBg,
          (hoveredPhase === Phases.Strategy || activePhase === Phases.Strategy) && styles.strategyBgActive,
        )}
      />
      <rect
        x={1198.83}
        y={327.685}
        width={72}
        height={72}
        rx={36}
        strokeWidth={6}
        strokeMiterlimit={10}
        className={clsx(
          styles.strategyBorder,
          (hoveredPhase === Phases.Strategy || activePhase === Phases.Strategy) && styles.strategyBorderActive,
        )}
      />
      <path
        d="M1234.83 376.023C1233.09 376.023 1231.55 375.725 1230.19 375.13C1228.85 374.534 1227.79 373.705 1227.01 372.643C1226.23 371.58 1225.81 370.353 1225.76 368.96H1230.3C1230.34 369.628 1230.56 370.212 1230.97 370.711C1231.37 371.202 1231.9 371.584 1232.57 371.858C1233.24 372.131 1233.99 372.268 1234.82 372.268C1235.7 372.268 1236.49 372.115 1237.17 371.809C1237.86 371.496 1238.39 371.061 1238.78 370.505C1239.17 369.95 1239.35 369.31 1239.35 368.586C1239.35 367.837 1239.16 367.177 1238.77 366.606C1238.37 366.034 1237.8 365.587 1237.05 365.265C1236.31 364.943 1235.42 364.782 1234.37 364.782H1232.19V361.329H1234.37C1235.23 361.329 1235.99 361.18 1236.63 360.883C1237.28 360.585 1237.79 360.166 1238.16 359.627C1238.53 359.08 1238.71 358.448 1238.71 357.731C1238.71 357.031 1238.56 356.423 1238.24 355.908C1237.92 355.385 1237.47 354.979 1236.9 354.689C1236.32 354.399 1235.65 354.254 1234.88 354.254C1234.12 354.254 1233.42 354.391 1232.78 354.665C1232.13 354.938 1231.61 355.329 1231.22 355.836C1230.83 356.335 1230.62 356.93 1230.59 357.623H1226.28C1226.31 356.238 1226.71 355.023 1227.48 353.976C1228.25 352.922 1229.28 352.101 1230.57 351.513C1231.86 350.918 1233.3 350.62 1234.9 350.62C1236.55 350.62 1237.99 350.93 1239.2 351.55C1240.42 352.161 1241.37 352.986 1242.04 354.025C1242.71 355.063 1243.04 356.21 1243.04 357.466C1243.05 358.858 1242.64 360.025 1241.81 360.967C1240.99 361.909 1239.91 362.525 1238.57 362.814V363.008C1240.31 363.249 1241.64 363.893 1242.57 364.939C1243.5 365.978 1243.97 367.27 1243.96 368.815C1243.96 370.2 1243.56 371.439 1242.78 372.534C1241.99 373.621 1240.92 374.474 1239.54 375.094C1238.17 375.713 1236.6 376.023 1234.83 376.023Z"
        fill="#000000"
        className={clsx(
          styles.phaseIndex,
          (hoveredPhase === Phases.Strategy || activePhase === Phases.Strategy) && styles.phaseIndexActive,
        )}
      />
    </g>
    {/* circle5 shadow - start */}
    <g
      filter="url(#experience_shadow)"
      className={clsx(
        styles.hiddenOpacity,
        (hoveredPhase === Phases.Experience || activePhase === Phases.Experience) && styles.visibleOpacity,
      )}
    >
      <rect
        x={963.832}
        y={366.685}
        width={72}
        height={71}
        rx={36}
        strokeWidth={6}
        strokeMiterlimit={10}
        stroke="#013fc2"
      />
    </g>
    {/* circle5 shadow - end */}
    <g
      className={styles.cursorPointer}
      onMouseEnter={onPhaseMouseEnter(Phases.Experience)}
      onMouseLeave={onPhaseMouseLeave}
      onClick={onPhaseClick(Phases.Experience)}
    >
      <rect
        x={963.832}
        y={366.685}
        width={72}
        height={71}
        rx={35.5}
        className={clsx(
          styles.ideaBg,
          (hoveredPhase === Phases.Experience || activePhase === Phases.Experience) && styles.ideaBgActive,
        )}
      />
      <rect
        x={963.832}
        y={366.685}
        width={72}
        height={71}
        rx={35.5}
        strokeWidth={6}
        strokeMiterlimit={10}
        className={clsx(
          styles.ideaBorder,
          (hoveredPhase === Phases.Experience || activePhase === Phases.Experience) && styles.ideaBorderActive,
        )}
      />
      <path
        d="M999.868 415.023C998.258 415.023 996.818 414.721 995.546 414.118C994.274 413.506 993.264 412.669 992.515 411.606C991.775 410.544 991.38 409.328 991.332 407.96H995.679C995.759 408.974 996.198 409.803 996.995 410.447C997.792 411.083 998.749 411.401 999.868 411.401C1000.75 411.401 1001.53 411.2 1002.21 410.797C1002.89 410.395 1003.43 409.836 1003.83 409.119C1004.22 408.403 1004.42 407.586 1004.41 406.668C1004.42 405.734 1004.22 404.905 1003.82 404.181C1003.41 403.456 1002.86 402.889 1002.16 402.479C1001.46 402.06 1000.66 401.851 999.748 401.851C999.007 401.843 998.279 401.979 997.562 402.261C996.846 402.543 996.278 402.913 995.86 403.372L991.815 402.708L993.107 389.958H1007.45V393.701H996.814L996.101 400.257H996.246C996.705 399.718 997.353 399.271 998.19 398.917C999.027 398.554 999.945 398.373 1000.94 398.373C1002.44 398.373 1003.78 398.728 1004.95 399.436C1006.13 400.136 1007.05 401.102 1007.73 402.334C1008.4 403.565 1008.74 404.974 1008.74 406.559C1008.74 408.193 1008.36 409.65 1007.61 410.93C1006.86 412.202 1005.82 413.204 1004.48 413.937C1003.15 414.661 1001.61 415.023 999.868 415.023Z"
        className={clsx(
          styles.phaseIndex,
          (hoveredPhase === Phases.Experience || activePhase === Phases.Experience) && styles.phaseIndexActive,
        )}
      />
    </g>
  </SchemeSVG>
)
