// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ddNlU{position:relative;width:32px;min-width:32px;height:32px;margin-bottom:8px;padding:6.5px;background-color:var(--wpp-primary-color-500);border-radius:50%;cursor:help}.VXhi1{cursor:pointer}.lVxnh{max-width:calc(100% - 32px - 9px);height:auto;margin-right:9px;object-fit:contain;opacity:0;transition:opacity .5s}.VmV0K{opacity:1}.QgRrX{width:16px}.SPNPc{max-width:340px;padding:10px;background-color:var(--wpp-grey-color-800);border-radius:var(--wpp-border-radius-m)}.hDYTG{color:var(--wpp-grey-color-000)}`, "",{"version":3,"sources":["webpack://./src/pages/phaseBuilder/additionalInformation/AdditionalInformation.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,UAAA,CACA,cAAA,CACA,WAAA,CACA,iBAAA,CACA,aAAA,CACA,6CAAA,CACA,iBAAA,CACA,WAAA,CAGF,OACE,cAAA,CAGF,OACE,iCAAA,CACA,WAAA,CACA,gBAAA,CACA,kBAAA,CACA,SAAA,CACA,sBAAA,CAGF,OACE,SAAA,CAGF,OACE,UAAA,CAGF,OACE,eAAA,CACA,YAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,+BAAA","sourcesContent":[".infoTrigger {\n  position: relative;\n  width: 32px;\n  min-width: 32px;\n  height: 32px;\n  margin-bottom: 8px;\n  padding: 6.5px;\n  background-color: var(--wpp-primary-color-500);\n  border-radius: 50%;\n  cursor: help;\n}\n\n.sideModalTrigger {\n  cursor: pointer;\n}\n\n.tooltipImg {\n  max-width: calc(100% - 32px - 9px);\n  height: auto;\n  margin-right: 9px;\n  object-fit: contain;\n  opacity: 0;\n  transition: opacity 0.5s;\n}\n\n.tooltipVisible {\n  opacity: 1;\n}\n\n.icon {\n  width: 16px;\n}\n\n.iconTooltip {\n  max-width: 340px;\n  padding: 10px;\n  background-color: var(--wpp-grey-color-800);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.iconTooltipText {\n  color: var(--wpp-grey-color-000);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoTrigger": `ddNlU`,
	"sideModalTrigger": `VXhi1`,
	"tooltipImg": `lVxnh`,
	"tooltipVisible": `VmV0K`,
	"icon": `QgRrX`,
	"iconTooltip": `SPNPc`,
	"iconTooltipText": `hDYTG`
};
export default ___CSS_LOADER_EXPORT___;
