import { phasesData } from 'pages/customProcess/components/mediaMarktSaturn/MediaMarktSaturnConfig'
import { PageLayout } from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pageLayout/PageLayout'
import { Phases } from 'pages/customProcess/components/mediaMarktSaturn/types'

const Adapt = () => {
  const { title, description, schemeSrc, nextLink } = phasesData[Phases.Adapt]
  return <PageLayout title={title} description={description} schemeSrc={schemeSrc} nextLink={nextLink} />
}

export default Adapt
