import { WppCard, WppPopover, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEventHandler, useMemo } from 'react'
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/form/formPalette/colorInput/ColorInput.module.scss'

export const ColorInput = ({
  color,
  title,
  onClick,
  onChange,
}: {
  color: string
  title: string
  onClick: MouseEventHandler
  onChange: (value: string) => void
}) => {
  const { isGradient, gradientType, valueToHex, rgbaArr } = useColorPicker(color, onChange)

  const opacity = useMemo(() => (!isGradient ? (rgbaArr.at(-1) ?? 1) * 100 : 100), [isGradient, rgbaArr])
  const colorCode = useMemo(
    () => (isGradient ? gradientType?.replace('-gradient', '') : valueToHex().replace('#', '').toUpperCase()),
    [gradientType, isGradient, valueToHex],
  )

  return (
    <WppPopover className={styles.root}>
      <WppCard onClick={onClick} slot="trigger-element" className={styles.card}>
        <Flex justify="between" align="center" gap={10}>
          <div
            style={{ background: color }}
            className={clsx(styles.colorBox, {
              [styles.transparent]: !opacity,
            })}
          />

          <Flex direction="column" className={styles.colorInfo}>
            <WppTypography type="s-body" className={styles.colorCode}>
              {colorCode}
            </WppTypography>
            <WppTypography type="s-body" className={styles.colorOpacity}>{`${opacity}%`}</WppTypography>
          </Flex>

          <WppTypography type="s-body" className={styles.colorTitle}>
            {title}
          </WppTypography>
        </Flex>
      </WppCard>

      <ColorPicker
        onChange={onChange}
        value={color}
        className=""
        hideColorTypeBtns={false}
        hideAdvancedSliders
        hideColorGuide
        hideInputType
        hidePresets
      />
    </WppPopover>
  )
}
