import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useMemo } from 'react'

import { ReactComponent as ArrowHead } from 'pages/customProcess/components/ibmNew/assets/arrow-head.svg'
import styles from 'pages/customProcess/components/ibmNew/components/phaseConnectionArrows/PhaseConnectionArrows.module.scss'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

interface Props {
  activePhase: MayBeNull<Phases>
}

export const PhaseConnectionArrows = ({ activePhase }: Props) => {
  const arrowsVisibility = useMemo(() => {
    switch (activePhase) {
      case Phases.CentralStrategy:
        return {
          planRevisions: false,
          optimisations: false,
        }
      case Phases.ChannelActivity:
        return {
          planRevisions: true,
          optimisations: false,
        }
      case Phases.Execution:
        return {
          planRevisions: false,
          optimisations: true,
        }
      case Phases.Performance:
        return {
          planRevisions: true,
          optimisations: true,
        }
      case Phases.Reconciliation:
        return {
          planRevisions: false,
          optimisations: false,
        }
      default:
        return {
          planRevisions: true,
          optimisations: true,
        }
    }
  }, [activePhase])

  return (
    <div
      className={clsx(
        styles.plansRevisionArrow,
        activePhase === Phases.CentralStrategy && styles.arrowOffset1,
        activePhase === Phases.ChannelActivity && styles.arrowOffset2,
        activePhase === Phases.Execution && styles.arrowOffset3,
        activePhase === Phases.Performance && styles.arrowOffset4,
        activePhase === Phases.Reconciliation && styles.arrowOffset5,
        !arrowsVisibility.planRevisions && styles.lowerOpacityBorder,
      )}
    >
      <ArrowHead
        className={clsx(styles.planRevisionsArrowHead, !arrowsVisibility.planRevisions && styles.lowerOpacity)}
      />
      <WppTypography
        type="s-body"
        className={clsx(styles.arrowText, !arrowsVisibility.planRevisions && styles.lowerOpacityText)}
      >
        Plan Revisions
      </WppTypography>
      <div
        className={clsx(
          styles.optimisationsArrow,
          activePhase === Phases.ChannelActivity && styles.optimisationsArrow2,
          !arrowsVisibility.optimisations && styles.lowerOpacity,
        )}
      >
        <ArrowHead className={styles.optimisationsArrowHead1} />
        <WppTypography type="s-body" className={styles.arrowText}>
          Performance Optimisations
        </WppTypography>
        <ArrowHead className={styles.optimisationsArrowHead2} />
      </div>
    </div>
  )
}
