// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TAO1c{margin-bottom:8px}.fRE59{color:var(--wpp-grey-color-000)}.qi1kV li{margin-left:22px;line-height:22px;list-style-type:disc}@media(max-height: 840px){.fdW6i{margin-top:-55px}}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibm/processPhases/pages/adapt/Adapt.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAGF,OACE,+BAAA,CAIA,UACE,gBAAA,CACA,gBAAA,CACA,oBAAA,CAKF,0BADF,OAEI,gBAAA,CAAA","sourcesContent":[".descriptionProcessTitle {\n  margin-bottom: 8px;\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n\n.processList {\n  li {\n    margin-left: 22px;\n    line-height: 22px;\n    list-style-type: disc;\n  }\n}\n\n.controls {\n  @media (height <= 840px) {\n    margin-top: -55px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionProcessTitle": `TAO1c`,
	"whiteText": `fRE59`,
	"processList": `qi1kV`,
	"controls": `fdW6i`
};
export default ___CSS_LOADER_EXPORT___;
