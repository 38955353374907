import { PhaseDetails } from 'pages/customProcess/components/henkel/types'

export const links = {
  [PhaseDetails.Adapt]: '/custom/henkel/adapt',
  [PhaseDetails.Ambition]: '/custom/henkel/ambition',
  [PhaseDetails.Audience]: '/custom/henkel/audience',
  [PhaseDetails.Approach]: '/custom/henkel/approach',
  [PhaseDetails.Assets]: '/custom/henkel/assets',
  [PhaseDetails.Activation]: '/custom/henkel/activate',
}
