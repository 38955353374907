import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import Logo from 'pages/customProcess/components/no7Beauty/assets/logo.png'
import styles from 'pages/customProcess/components/no7Beauty/processPhases/activation/Activation.module.scss'
import Scheme from 'pages/customProcess/components/no7Beauty/processPhases/activation/assets/activation.png'
import { FooterNavigation } from 'pages/customProcess/components/no7Beauty/processPhases/footerNavigation/FooterNavigation'

const Activation = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Flex direction="column" className={styles.root}>
      <div className={styles.header}>
        <Flex justify="between" className={styles.headerContent}>
          <div>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn}>
                <WppIconChevron direction="left" slot="icon-start" color="#05054b" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <h1 className={styles.title}>Activation</h1>
            <ul className={styles.subtitleList}>
              <li className={styles.subtitle}>
                {
                  'Allow cross discipline review of the work so that we can remain hot on the detail, and remain hot on tracking\ndelivery against our collective ambition and objectives'
                }
              </li>
              <li className={styles.subtitle}>
                {
                  'Enabling a strong support system so that any changes in delivery timings or output needs are managed\nacross functions with maximum efficiency and minimum impact'
                }
              </li>
              <li className={styles.subtitle}>
                An energized team with regular open dialogue to keep development progressing against timelines
              </li>
            </ul>
          </div>
          <Flex align="center" className={styles.logoWrapper}>
            <img src={Logo} alt="" />
          </Flex>
        </Flex>
      </div>
      <div className={styles.mainContentWrapper}>
        <Flex justify="center" className={styles.mainContent}>
          <img src={Scheme} alt="" />
        </Flex>
      </div>
      <FooterNavigation previousLink="/custom/n7/creative-development" nextLink="/custom/n7/evaluation" />
    </Flex>
  )
}

export default Activation
