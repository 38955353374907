import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'pages/customProcess/components/nestle/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard.module.scss'

interface Props {
  title: string
  text: string
  cardStyles?: string
}

export const InputsOutputsCard = ({ title, text, cardStyles }: Props) => (
  <div className={clsx(styles.card, cardStyles)}>
    <WppTypography className={styles.title}>{title}</WppTypography>
    <WppTypography type="m-midi" className={styles.text}>
      {text}
    </WppTypography>
  </div>
)
