export enum Phases {
  Brief = 'Brief',
  Interrogate = 'Interrogate',
  Insights = 'Insights',
  Strategy = 'Strategy',
  Media = 'Media',
  Consumer = 'Consuner',
  Measurement = 'Measurement',
  Learning = 'Learning',
}
