import { Flex } from 'components/common/flex/Flex'
import audienceOriginLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/audience-origin-logo.svg'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/activationPlanning/ActivationPlanning.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const ActivationPlanning = () => {
  const previousLink = '/custom/contact-points-and-investment-planning'
  const nextLink = '/custom/implementation'

  return (
    <ProcessCard
      title="Activation Planning"
      bepTitle="BEP 4"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Foundation for Activation Planning" cardStyles={styles.inputsCard} />
        <Flex direction="column" align="center" gap={24} className={styles.regularCard0Wrapper}>
          <RegularCard
            text="Signal Planning"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[
              { text: 'Audience Translator®', style: styles.redmillTagColor, logoImgUrl: audienceOriginLogoImgUrl },
            ]}
          />
          <RegularCard
            text="Cross-Screen Planning"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'mScreen®', style: styles.redmillTagColor }]}
          />
          <RegularCard
            text={'Retail Digital Media\nPlanning'}
            brandTags={[{ text: 'Fusion Portfolio Planner®', style: styles.redmillTagColor }]}
          />
        </Flex>
        <div className={styles.regularCard1Wrapper}>
          <RegularCard
            text="Holistic Search Planning"
            teamTags={[
              { text: 'Media Teams', tags: ['R', 'A'] },
              { text: 'Nestle Teams', tags: ['C'] },
            ]}
            brandTags={[
              { text: 'CIM®', style: styles.redmillTagColor },
              { text: 'SEO Simulator®', style: styles.redmillTagColor },
              { text: 'Radar®', style: styles.redmillTagColor },
              { text: 'Content Cast®', style: styles.redmillTagColor },
            ]}
          />
        </div>
        <div className={styles.regularCard2Wrapper}>
          <RegularCard
            text="First Party Data Enrichment"
            teamTags={[
              { text: 'Nestle Teams', tags: ['R', 'A'] },
              { text: 'Media Teams', tags: ['I'] },
            ]}
            brandTags={[{ text: 'Clean House®', style: styles.redmillTagColor }]}
          />
        </div>
        <InputsOutputsCard title="outputs" text="Activation Planning sign-off" cardStyles={styles.inputsCard} />
      </>
    </ProcessCard>
  )
}

export default ActivationPlanning
