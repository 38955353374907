import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'

import styles from 'pages/customProcess/components/ferreroNew/FerreroProcess.module.scss'
import { Phases } from 'pages/customProcess/components/ferreroNew/types'

interface Props {
  activePhase: MayBeNull<Phases>
}

export const PhaseTitlesSvg = ({ activePhase }: Props) => (
  <svg width={583.8} height={583.8} viewBox="0 0 556 556" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M465.966 169.249C480.247 193.871 489.516 221.051 493.244 249.239C496.972 277.427 495.086 306.069 487.694 333.531C480.302 360.993 467.547 386.737 450.16 409.293C432.772 431.849 411.092 450.775 386.356 464.99C361.621 479.205 334.314 488.432 305.997 492.143C277.679 495.854 248.904 493.977 221.315 486.618C193.726 479.26 167.863 466.564 145.203 449.257C122.543 431.949 103.53 410.368 89.249 385.746C74.9679 361.125 65.6987 333.944 61.9706 305.756C58.2425 277.569 60.1285 248.926 67.5209 221.464C74.9134 194.002 87.6675 168.258 105.055 145.702C122.443 123.146 144.123 104.221 168.859 90.0051C193.594 75.7897 220.901 66.5631 249.218 62.8522C277.536 59.1412 306.311 61.0185 333.9 68.377C361.489 75.7355 387.352 88.4309 410.012 105.739C432.672 123.046 451.685 144.627 465.966 169.249L465.966 169.249Z"
      stroke="url(#paint0_linear_368_7294)"
    />
    <rect
      width={11.1281}
      height={11.1281}
      transform="matrix(0.708734 -0.705476 0.708734 0.705476 269.721 493.995)"
      fill="#55260D"
    />
    <rect
      width={11.1059}
      height={11.1503}
      transform="matrix(0.259934 0.965626 -0.966223 0.257708 95.1929 159.45)"
      fill="#331003"
    />
    <rect
      width={11.1503}
      height={11.1059}
      transform="matrix(-0.966223 -0.257708 0.259934 -0.965626 465.909 170.048)"
      fill="#612E10"
    />
    <g className={clsx(styles.phaseSvgTitle, activePhase === Phases.Evaluate && styles.phaseSvgTitleHidden)}>
      <path
        d="M43.6169 209.396C44.8943 209.878 46.8145 210.521 48.3372 210.884C48.7094 210.977 48.8194 211.104 48.7263 211.51L45.1396 226.446C45.1058 226.582 44.6997 226.48 44.7336 226.353C45.2242 224.291 44.9366 223.868 42.974 223.395L27.3161 219.642C25.3874 219.177 24.9052 219.422 24.4061 221.484C24.3723 221.62 23.9662 221.518 24 221.391L27.4345 207.063C27.5022 206.793 27.6206 206.751 27.8236 206.801L32.3408 207.807C32.4423 207.833 32.4254 208.188 32.2731 208.221C29.4562 208.331 27.8405 209.701 27.1722 212.499L26.9269 213.513C26.6055 214.866 27.0284 215.609 28.2466 215.905L35.1493 217.554L35.826 214.747C36.4689 212.076 35.826 210.453 34.2272 210.073C34.0919 210.039 34.1849 209.667 34.3203 209.701C35.8091 210.056 36.4435 210.242 37.3571 210.462C38.5076 210.74 39.5481 210.876 40.8339 211.188C40.9354 211.214 40.8339 211.62 40.7324 211.594C38.9052 211.155 37.4163 212.304 36.765 215.001L36.0967 217.773L43.7354 219.608C45.055 219.92 45.774 219.599 46.0363 218.517L46.307 217.401C47.1529 213.885 46.2562 211.493 43.4478 209.785C43.3209 209.718 43.5154 209.371 43.6169 209.396Z"
        fill="#612E10"
      />
      <path
        d="M36.1812 180.96C35.6398 182.575 36.6549 184.595 38.7274 186.277L52.9812 197.816C53.125 197.934 52.9219 198.534 52.7358 198.543L32.0785 200.986C30.2766 201.222 29.2446 201.687 28.8978 202.71C28.8555 202.845 28.4579 202.71 28.5002 202.575C28.754 201.814 28.8555 201.848 29.4053 200.2C30.6319 196.539 30.9365 195.288 31.2918 194.232C31.334 194.096 31.7316 194.232 31.6893 194.367C31.0634 196.243 31.9854 197.325 34.0495 197.063L48.3709 195.364L38.3129 187.173C36.4349 185.669 34.557 185.804 33.838 187.951C33.7957 188.086 33.3981 187.951 33.4404 187.816C33.8634 186.565 34.2779 185.643 34.9038 183.758C35.3099 182.541 35.4114 181.916 35.7751 180.825C35.8174 180.69 36.215 180.825 36.1727 180.96H36.1812Z"
        fill="#612E10"
      />
      <path
        d="M64.7986 168.627C64.2488 169.904 63.4959 171.281 62.946 172.558C62.3116 174.029 62.0578 174.975 61.4911 176.286C61.4065 176.48 61.0258 176.311 61.1104 176.117C61.8717 174.358 61.4234 173.902 58.8941 174.062L50.917 174.553L48.4639 180.233L52.4989 184.663C54.5545 186.911 56.7285 186.26 57.769 183.868C57.8536 183.674 58.2343 183.843 58.1497 184.029C57.5998 185.305 57.1007 186.108 56.3986 187.74C55.6796 189.396 55.4935 190.191 54.876 191.62C54.8083 191.78 54.4276 191.62 54.4953 191.45C55.2397 189.726 54.7237 188.56 51.8137 185.373L39.5817 171.882C39.4717 171.797 39.6409 171.298 39.827 171.307L58.9787 170.106C62.6584 169.912 63.9781 169.498 64.4264 168.45C64.511 168.255 64.8917 168.424 64.8071 168.61L64.7986 168.627ZM49.8258 174.612L43.676 174.984L47.7364 179.422L49.8174 174.603L49.8258 174.612Z"
        fill="#612E10"
      />
      <path
        d="M65.6361 161.239C67.1334 162.051 67.5056 161.941 68.3261 160.445L68.8929 159.405C70.6186 156.235 70.1533 152.735 67.7002 150.411C67.5986 150.318 67.8186 150.039 67.9455 150.106C69.2228 150.96 71.2446 152.262 72.8349 153.124C73.1733 153.31 73.224 153.496 73.0464 153.834L65.1962 168.238C65.1455 168.331 64.7817 168.128 64.8325 168.035C65.8476 166.176 65.6953 165.652 63.9189 164.688L49.7836 156.996C48.041 156.049 47.4911 156.184 46.476 158.044C46.4253 158.137 46.0615 157.934 46.1123 157.841C46.6452 156.86 47.5165 155.483 48.3117 154.012C49.1407 152.482 49.7666 151.121 50.3334 150.089C50.4011 149.971 50.7648 150.166 50.6972 150.292C49.682 152.152 49.9104 152.676 51.653 153.623L65.6361 161.231V161.239Z"
        fill="#612E10"
      />
      <path
        d="M87.4269 131.932C86.8601 132.769 86.3103 133.53 85.6674 134.477C85.0837 135.339 84.8383 135.762 84.3223 136.539C84.0093 137.004 83.8994 137.047 83.4087 136.717L81.4462 135.398C82.013 138.67 81.6662 141.239 79.9912 143.716C77.4704 147.461 73.2577 148.991 69.7387 146.624L59.41 139.684C57.7689 138.577 57.2275 138.636 56.0432 140.394C55.9671 140.512 55.6203 140.276 55.6964 140.166C56.3731 139.16 57.2952 137.976 58.2257 136.59C59.1985 135.145 59.9598 133.826 60.6197 132.837C60.6789 132.752 61.0257 132.981 60.9665 133.074C59.7822 134.832 59.9768 135.424 61.6178 136.522L70.8807 142.744C74.5435 145.204 77.7495 144.553 79.6275 141.755C80.8879 139.878 81.1248 137.782 80.6257 134.849L68.3091 126.573C66.668 125.466 66.0843 125.457 64.8831 127.241C64.8239 127.326 64.477 127.097 64.5363 127.013C65.213 126.007 66.1181 124.849 67.0064 123.522C67.9369 122.135 68.7067 120.808 69.3834 119.802C69.4595 119.684 69.8063 119.921 69.7302 120.039C68.5459 121.797 68.6813 122.347 70.3224 123.454L83.6794 132.431C85.3544 133.555 85.8873 133.454 87.0631 131.696C87.1393 131.577 87.4861 131.814 87.4099 131.932H87.4269Z"
        fill="#612E10"
      />
      <path
        d="M103.745 112.389C102.848 113.454 101.74 114.561 100.852 115.626C99.8282 116.852 99.3037 117.689 98.3901 118.779C98.2548 118.94 97.9333 118.669 98.0687 118.509C99.2953 117.047 98.9992 116.48 96.5291 115.905L88.7466 114.105L84.7708 118.847L87.3678 124.24C88.6959 126.987 90.9629 126.979 92.6379 124.984C92.7732 124.823 93.0947 125.094 92.9593 125.254C92.0626 126.32 91.3605 126.954 90.2185 128.306C89.0596 129.692 88.6536 130.394 87.6469 131.594C87.537 131.73 87.2155 131.459 87.3255 131.324C88.5267 129.887 88.366 128.619 86.4965 124.739L78.621 108.323C78.5364 108.205 78.8409 107.782 79.027 107.841L97.7219 112.152C101.3 113.023 102.679 112.997 103.423 112.118C103.559 111.958 103.88 112.228 103.745 112.389ZM87.6808 113.86L81.6832 112.465L84.314 117.883L87.6808 113.86Z"
        fill="#612E10"
      />
      <path
        d="M92.0546 102.372C92.1307 102.499 91.8601 102.719 91.7585 102.617C90.9465 101.611 88.4087 98.5934 87.2075 97.3931C87.0637 97.2494 87.309 96.9535 87.4782 97.1226C87.9688 97.6128 88.9501 96.7253 89.4153 96.2604C90.8619 94.9079 92.5791 93.2342 94.4232 91.3914C96.7072 89.1006 97.8069 87.8495 99.0842 86.5731C100.26 85.3897 100.725 84.7303 100.387 84.0372C100.285 83.8935 100.556 83.623 100.683 83.7413C101.639 84.9417 104.574 88.1116 105.412 88.94C105.513 89.0414 105.268 89.2866 105.141 89.2105C102.062 87.1226 99.4141 87.3762 97.4939 89.295C96.5126 90.2756 96.6564 90.8166 97.79 91.9493L109.142 103.26C110.572 104.68 111.113 104.68 112.881 102.913C112.983 102.811 113.279 103.107 113.177 103.209C112.247 104.147 111.062 105.229 109.692 106.607C108.389 107.909 107.264 109.143 106.325 110.073C106.224 110.174 105.936 109.878 106.029 109.777C107.746 108.052 107.823 107.444 106.393 106.015L94.9984 94.6543C93.9156 93.5723 93.3743 93.4286 92.4437 94.3669C90.4304 96.3872 90.4558 99.1598 92.0631 102.372H92.0546Z"
        fill="#612E10"
      />
      <path
        d="M127.261 83.5722C128.006 84.7219 129.173 86.3702 130.18 87.5621C130.425 87.858 130.434 88.027 130.121 88.2975L118.388 98.213C118.278 98.306 118.016 97.9848 118.117 97.8918C119.733 96.5224 119.758 96.0067 118.455 94.4683L108.059 82.1859C106.782 80.6728 106.249 80.5799 104.625 81.9493C104.523 82.0422 104.252 81.721 104.354 81.628L115.613 72.1183C115.825 71.9408 115.943 71.9746 116.078 72.1352L119.115 75.6179C119.183 75.694 118.963 75.9729 118.819 75.9138C116.484 74.3415 114.378 74.4852 112.17 76.3449L111.375 77.0211C110.309 77.9171 110.216 78.7709 111.028 79.7261L115.605 85.1361L117.813 83.2764C119.91 81.5012 120.35 79.8191 119.293 78.568C119.2 78.4581 119.496 78.213 119.581 78.3229C120.57 79.4894 120.968 80.0135 121.577 80.732C122.338 81.6365 123.091 82.3635 123.945 83.3694C124.013 83.4455 123.692 83.716 123.624 83.6399C122.414 82.2113 120.536 82.2451 118.405 84.0456L116.231 85.8884L121.306 91.8816C122.177 92.9129 122.956 93.082 123.81 92.3635L124.69 91.6196C127.448 89.2865 128.15 86.8267 126.898 83.792C126.839 83.6652 127.194 83.4962 127.261 83.5722Z"
        fill="#612E10"
      />
    </g>
    <g className={clsx(styles.phaseSvgTitle, activePhase === Phases.Engage && styles.phaseSvgTitleHidden)}>
      <path
        d="M225.735 521.07C225.244 522.347 224.601 524.265 224.238 525.787C224.145 526.159 224.018 526.269 223.612 526.167L208.673 522.558C208.537 522.524 208.639 522.118 208.774 522.152C210.838 522.651 211.261 522.363 211.735 520.402L215.516 504.764C215.982 502.837 215.745 502.355 213.681 501.856C213.545 501.822 213.647 501.417 213.782 501.45L228.112 504.916C228.383 504.984 228.425 505.102 228.374 505.305L227.351 509.811C227.325 509.912 226.97 509.895 226.936 509.743C226.826 506.928 225.464 505.314 222.664 504.637L221.649 504.392C220.296 504.062 219.551 504.494 219.255 505.711L217.589 512.6L220.397 513.276C223.07 513.919 224.686 513.276 225.075 511.687C225.109 511.552 225.481 511.645 225.447 511.78C225.084 513.268 224.898 513.902 224.678 514.815C224.398 515.964 224.255 517.004 223.942 518.281C223.916 518.382 223.51 518.281 223.536 518.179C223.976 516.353 222.834 514.866 220.135 514.215L217.36 513.547L215.516 521.18C215.195 522.499 215.516 523.217 216.608 523.479L217.724 523.75C221.235 524.595 223.637 523.716 225.346 520.909C225.414 520.783 225.76 520.977 225.735 521.079V521.07Z"
        fill="#612E10"
      />
      <path
        d="M254.446 509.625C252.627 509.354 251.257 510.732 250.893 513.167L248.262 530.757C248.245 530.893 247.924 530.884 247.864 530.808L234.482 510.056L232.486 523.42C232.122 525.863 232.959 527.528 234.753 527.799C234.854 527.816 234.795 528.23 234.693 528.213C233.695 528.061 232.638 527.833 231.369 527.647C229.99 527.444 228.645 527.309 227.545 527.148C227.41 527.131 227.469 526.717 227.605 526.734C229.601 527.03 231.098 525.711 231.462 523.268L233.636 508.737C232.342 506.818 231.454 506.201 230.345 506.032C230.244 506.015 230.303 505.601 230.405 505.618C231.301 505.753 232.316 505.973 233.145 506.1C234.795 506.345 236.216 506.489 236.935 506.599C237.519 506.683 237.485 507.173 238.39 508.644L248.237 524.13L249.895 513.031C250.258 510.588 249.421 508.889 247.636 508.619C247.534 508.602 247.594 508.188 247.695 508.205C248.693 508.357 249.742 508.619 251.011 508.805C252.246 508.991 253.507 509.067 254.496 509.227C254.598 509.244 254.539 509.659 254.437 509.642L254.446 509.625Z"
        fill="#612E10"
      />
      <path
        d="M254.903 520.284C255.224 513.479 261.103 509.101 268.607 509.447C270.687 509.549 273.174 510.014 274.536 510.664C274.942 510.825 275.01 511.003 275.019 511.484L275.239 516.328C275.239 516.463 274.917 516.522 274.824 516.345C273.394 512.761 270.544 510.47 266.855 510.301C262.025 510.073 259.42 513.606 259.166 519.16C258.836 526.167 262.499 531.112 267.778 531.358C270.417 531.484 271.457 530.901 271.626 527.325C271.787 523.995 271.559 523.598 268.361 523.445C268.082 523.429 268.107 522.879 268.361 522.888C271.55 523.243 273.91 523.361 276.795 523.352C277.007 523.361 277.015 523.851 276.77 523.834C275.594 523.741 275.501 524.156 275.323 527.833C275.213 530.157 275.45 530.343 275.425 530.901C275.408 531.18 275.306 531.205 274.985 531.298C272.388 531.729 269.452 532.008 266.881 531.89C258.997 531.518 254.607 526.404 254.894 520.267L254.903 520.284Z"
        fill="#612E10"
      />
      <path
        d="M303.433 530.715C302.045 530.783 300.472 530.732 299.085 530.8C297.486 530.884 296.522 531.07 295.101 531.146C294.889 531.155 294.872 530.741 295.075 530.732C296.987 530.631 297.198 530.03 295.938 527.833L291.962 520.91L285.778 521.231L283.596 526.81C282.488 529.65 284.036 531.307 286.641 531.172C286.853 531.163 286.87 531.577 286.667 531.586C285.279 531.653 284.332 531.569 282.564 531.662C280.762 531.755 279.967 531.932 278.402 532.017C278.224 532.025 278.207 531.611 278.377 531.603C280.255 531.51 281.075 530.521 282.64 526.514L289.323 509.574C289.348 509.43 289.873 509.371 289.949 509.54L299.516 526.159C301.318 529.371 302.274 530.369 303.424 530.309C303.636 530.301 303.653 530.715 303.45 530.724L303.433 530.715ZM291.421 519.954L288.359 514.612L286.176 520.225L291.421 519.954Z"
        fill="#612E10"
      />
      <path
        d="M303.932 519.912C302.917 513.175 307.832 507.731 315.259 506.615C317.323 506.303 319.852 506.277 321.316 506.649C321.747 506.725 321.84 506.886 321.95 507.359L323.117 512.068C323.134 512.203 322.838 512.321 322.711 512.161C320.605 508.932 317.365 507.241 313.719 507.782C308.94 508.5 307.079 512.473 307.908 517.968C308.957 524.908 313.516 529.033 318.744 528.247C321.358 527.858 322.263 527.089 321.73 523.547C321.231 520.25 320.935 519.904 317.771 520.385C317.492 520.428 317.416 519.878 317.653 519.844C320.85 519.574 323.185 519.219 326.019 518.653C326.222 518.619 326.332 519.101 326.087 519.134C324.911 519.278 324.902 519.701 325.452 523.344C325.799 525.643 326.061 525.778 326.146 526.328C326.188 526.607 326.087 526.649 325.79 526.801C323.329 527.731 320.503 528.576 317.957 528.965C310.149 530.14 304.845 525.981 303.932 519.904V519.912Z"
        fill="#612E10"
      />
      <path
        d="M344.9 517.292C345.052 518.653 345.357 520.648 345.72 522.169C345.813 522.541 345.746 522.702 345.34 522.795L330.401 526.404C330.265 526.438 330.164 526.032 330.299 525.998C332.363 525.5 332.609 525.052 332.135 523.091L328.354 507.452C327.888 505.525 327.449 505.204 325.393 505.703C325.258 505.736 325.156 505.331 325.291 505.297L339.621 501.831C339.892 501.763 339.985 501.848 340.036 502.051L341.186 506.522C341.212 506.624 340.89 506.776 340.789 506.658C339.41 504.206 337.456 503.386 334.656 504.063L333.641 504.308C332.287 504.637 331.822 505.356 332.11 506.573L333.776 513.462L336.584 512.786C339.258 512.144 340.4 510.825 340.019 509.244C339.985 509.109 340.357 509.016 340.391 509.151C340.755 510.639 340.873 511.29 341.093 512.195C341.372 513.344 341.719 514.333 342.024 515.618C342.049 515.719 341.643 515.821 341.618 515.719C341.178 513.894 339.478 513.091 336.779 513.741L334.004 514.409L335.849 522.042C336.17 523.361 336.788 523.851 337.87 523.589L338.987 523.319C342.497 522.473 344.232 520.588 344.468 517.309C344.468 517.165 344.866 517.173 344.891 517.275L344.9 517.292Z"
        fill="#612E10"
      />
    </g>
    <g
      clipPath="url(#clip0_368_7294)"
      className={clsx(styles.phaseSvgTitle, activePhase === Phases.Inspire && styles.phaseSvgTitleHidden)}
    >
      <path
        d="M444.296 106.637C444.407 106.749 444.083 107.074 443.982 106.963C443.029 106.032 441.938 104.849 440.551 103.49C439.247 102.205 437.971 101.06 437.018 100.12C436.935 100.036 437.259 99.7105 437.333 99.7943C438.988 101.423 439.608 101.442 441.152 99.8595L453.451 87.1892C454.967 85.6252 454.958 84.9828 453.294 83.3536C453.21 83.2699 453.534 82.944 453.608 83.0278C454.561 83.9588 455.707 85.2528 457.011 86.5282C458.398 87.8874 459.647 88.958 460.571 89.8703C460.682 89.982 460.359 90.3079 460.257 90.1962C458.602 88.567 457.954 88.6787 456.438 90.2427L444.194 102.866C442.65 104.458 442.604 104.998 444.296 106.655V106.637Z"
        fill="#612E10"
      />
      <path
        d="M480.703 112.93C479.371 111.404 477.263 111.59 475.228 113.386L460.609 126.308C460.498 126.411 460.239 126.178 460.248 126.066L463.743 99.1056L452.637 108.927C450.612 110.715 450.131 112.725 451.435 114.215C451.509 114.299 451.167 114.606 451.093 114.522C450.363 113.684 449.641 112.744 448.716 111.683C447.718 110.528 446.682 109.458 445.878 108.545C445.776 108.434 446.118 108.127 446.22 108.238C447.672 109.905 449.854 109.858 451.888 108.071L463.965 97.3927C464.233 94.8605 463.938 93.7061 463.133 92.7844C463.059 92.7007 463.401 92.3934 463.475 92.4772C464.122 93.222 464.825 94.1343 465.426 94.8325C466.628 96.2104 467.71 97.3368 468.238 97.9419C468.663 98.426 468.293 98.817 468.006 100.688L465.288 120.704L474.516 112.549C476.542 110.752 477.05 108.732 475.746 107.233C475.672 107.149 476.014 106.842 476.088 106.935C476.819 107.773 477.503 108.732 478.437 109.802C479.344 110.836 480.324 111.795 481.054 112.632C481.128 112.716 480.786 113.023 480.712 112.94L480.703 112.93Z"
        fill="#612E10"
      />
      <path
        d="M478.761 139.379C475.506 141.771 471.187 140.486 468.431 136.688C467.109 134.864 465.999 132.033 466.073 130.842C466.064 130.563 466.11 130.432 466.452 130.134L471.243 126.42C471.4 126.252 471.677 126.569 471.575 126.746C469.911 129.911 467.645 133.988 469.781 136.921C470.836 138.373 472.482 139.341 474.784 137.656C480.555 133.411 474.183 124.688 479.713 120.629C483.153 118.106 487.12 120.396 489.358 123.487C490.431 124.967 491.318 126.839 491.577 127.974C491.605 128.477 491.54 128.71 491.3 128.887L486.787 132.201C486.667 132.294 486.371 132.08 486.427 131.894C487.102 130.684 490.477 126.448 488.082 123.143C486.963 121.597 485.15 120.983 483.283 122.361C478.4 125.945 484.448 135.199 478.77 139.369L478.761 139.379Z"
        fill="#612E10"
      />
      <path
        d="M481.174 154.209C481.258 154.339 480.869 154.581 480.786 154.451C479.926 153.054 479.011 151.416 477.836 149.498C476.874 147.934 475.931 146.547 475.238 145.402C475.154 145.272 475.543 145.03 475.626 145.16C476.847 147.143 477.402 147.255 479.279 146.082L494.25 136.772C496.09 135.627 496.285 135.059 495.064 133.076C494.981 132.946 495.369 132.704 495.452 132.834C496.146 133.97 496.913 135.431 497.875 136.995C498.513 138.038 499.882 139.835 500.788 141.306C503.303 145.411 503.562 149.247 499.872 151.76C495.563 154.711 491.06 152.105 488.988 148.725C488.711 148.269 488.406 147.692 488.212 147.096C488.165 146.947 488.628 146.752 488.693 146.929C488.813 147.348 489.081 147.86 489.238 148.12C490.376 149.973 493.122 150.522 496.71 148.288C500.104 146.175 501.149 143.503 499.429 140.71C498.689 139.509 498.078 139.388 496.405 140.431L481.628 149.619C479.686 150.829 479.528 151.509 481.184 154.209H481.174Z"
        fill="#612E10"
      />
      <path
        d="M491.023 171.878C491.097 172.018 490.69 172.232 490.616 172.092C489.987 170.91 489.312 169.458 488.396 167.736C487.536 166.116 486.667 164.626 486.048 163.444C485.992 163.341 486.399 163.127 486.455 163.23C487.546 165.287 488.128 165.501 490.07 164.459L505.606 156.136C507.52 155.112 507.705 154.488 506.614 152.431C506.558 152.328 506.965 152.114 507.021 152.216C507.64 153.399 508.343 154.972 509.203 156.592C510.109 158.314 510.978 159.711 511.589 160.865C511.663 161.005 511.256 161.219 511.182 161.079C510.091 159.022 509.443 158.929 507.529 159.953L492.058 168.238C490.116 169.281 489.904 169.784 491.013 171.878H491.023Z"
        fill="#612E10"
      />
      <path
        d="M502.277 197.88L499.604 191.754C499.16 190.739 501.999 185.926 507.058 178.963C506.882 178.665 506.725 178.395 506.586 178.088C506.299 177.417 505.994 176.719 505.781 176.142L497.709 179.717C495.693 180.611 495.443 181.095 496.395 183.264C496.442 183.366 496.026 183.552 495.979 183.45C495.443 182.221 494.87 180.722 494.093 178.935C493.362 177.25 492.613 175.742 492.077 174.522C492.012 174.383 492.438 174.196 492.493 174.336C493.427 176.477 493.973 176.691 495.998 175.798L512.107 168.666C514.086 167.791 514.354 167.251 513.42 165.12C513.374 165.017 513.79 164.831 513.836 164.933C514.372 166.162 514.927 167.708 515.658 169.393C516.268 170.798 517.239 172.455 517.914 173.992C519.791 178.302 519.227 181.476 516.444 182.705C514.252 183.673 511.543 183.078 509.471 181.57C503.349 190.758 501.463 194.891 502.684 197.694C502.73 197.796 502.314 197.982 502.268 197.88H502.277ZM507.538 177.79C508.611 180.238 509.961 180.769 513.374 179.261C517.479 177.445 517.775 175.639 516.961 173.787C516.259 172.176 515.352 171.906 513.753 172.614L506.789 175.695C506.956 176.375 507.289 177.231 507.538 177.79Z"
        fill="#612E10"
      />
      <path
        d="M513.614 213.566C512.143 213.864 509.998 214.386 508.37 214.935C507.973 215.065 507.797 215.009 507.649 214.572L502.295 198.531C502.249 198.383 502.683 198.243 502.73 198.383C503.469 200.598 503.987 200.822 506.077 200.114L522.759 194.473C524.812 193.774 525.127 193.272 524.387 191.056C524.34 190.907 524.775 190.767 524.821 190.907L529.954 206.286C530.055 206.575 529.963 206.687 529.75 206.761L524.979 208.455C524.868 208.493 524.673 208.158 524.794 208.037C527.337 206.286 528.039 204.071 527.041 201.064L526.68 199.974C526.199 198.522 525.367 198.075 524.072 198.513L516.721 200.999L517.729 204.005C518.681 206.873 520.225 208.009 521.918 207.431C522.066 207.385 522.195 207.785 522.047 207.832C520.466 208.372 519.763 208.567 518.792 208.893C517.562 209.312 516.517 209.787 515.149 210.243C515.038 210.28 514.899 209.843 515.001 209.805C516.943 209.144 517.664 207.208 516.693 204.303L515.704 201.324L507.557 204.08C506.151 204.555 505.67 205.281 506.059 206.445L506.456 207.646C507.714 211.416 509.933 213.129 513.53 213.092C513.688 213.082 513.715 213.511 513.604 213.548L513.614 213.566Z"
        fill="#612E10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_368_7294"
        x1={80.5584}
        y1={473.641}
        x2={472.84}
        y2={79.5467}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2A0900" />
        <stop offset={1} stopColor="#6F3714" />
      </linearGradient>
      <clipPath id="clip0_368_7294">
        <rect width={93} height={132} fill="white" transform="translate(437 83)" />
      </clipPath>
    </defs>
  </svg>
)
