import { InputsOutputsCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleChina/processPhases/pages/campaignStrategy/CampaignStrategy.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleChina/processPhases/processCard/ProcessCard'
import AudienceIcon from 'pages/customProcess/components/nestleChina/processScheme/assets/AudienceIcon.svg'
import BavIcon from 'pages/customProcess/components/nestleChina/processScheme/assets/BavIcon.svg'
import ChoreographIcon from 'pages/customProcess/components/nestleChina/processScheme/assets/ChoreographIcon.svg'

interface Props {
  isDemoTenant?: boolean
}

const CampaignStrategy = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/campaign-brief' : '/custom/ch/campaign-brief'
  const nextLink = isDemoTenant ? '/nestle/process/campaign-budget-setting' : '/custom/ch/campaign-budget-setting'

  return (
    <ProcessCard
      title="Campaign Strategy"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="Media principles"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="A fully aligned Brief with KPIs, CEF, SCT."
          cardStyles={styles.inputsCard}
        />
        <RegularCard
          text="Translation to actionable audiences"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[{ text: 'Audience Explorer®', style: styles.audienceDBTagColor, icon: AudienceIcon }]}
        />
        <RegularCard
          text="Enrich Consumer Experience framework"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[
            { text: 'BAV®', style: styles.commerceDBTagColor, icon: BavIcon },
            { text: 'Choreograph Landscape®', style: styles.choreographTagColor, icon: ChoreographIcon },
          ]}
        />
        <InputsOutputsCard
          title="outputs"
          text="Strategic confirmation and alignment"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignStrategy
