import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse } from '@wpp-open/core'

import { useUploadProcessFilesApi } from 'hooks/attachments/useUploadProcessFilesApi'
import { BGAlignmentOptions, DimensionUnits } from 'types/common/comon'
import { PhasePage, Templates, PhaseFormConfig, PhaseAdditionalInformationVariant } from 'types/phase/phase'
import { TemplateColorConfig } from 'types/process/template'
import { isFile } from 'utils/files'

export const getTemplateColors = () => {}

export const getInitialValues = (data: MayBeNull<PhasePage>): PhaseFormConfig => {
  const getFieldValue = ({ name, size, url }: AttachmentMetadataResponse) => ({
    name,
    size,
    url: url || '',
    type: '',
  })
  const logoDimensions = data?.header?.logoDimensions || {
    width: {
      unit: DimensionUnits.auto,
      value: 0,
    },
    height: {
      unit: DimensionUnits.percent,
      value: 100,
    },
  }

  return {
    template: data?.template || Templates.InnerFirst,
    background: (data?.background ? [getFieldValue(data?.background)] : []) as [FileItemType?],
    colors: data?.colors || [],
    header: {
      title: data?.header?.title || '',
      description: data?.header?.description || '',
      groupTitle: data?.header?.groupTitle || '',
      logoImage: (data?.header?.logoImage ? [getFieldValue(data?.header?.logoImage)] : []) as [FileItemType?],
      logoDimensions,
      background: (data?.header?.background ? [getFieldValue(data?.header?.background)] : []) as [FileItemType?],
      positionBackground: data?.header?.positionBackground || BGAlignmentOptions.CenterCenter,
      colors: data?.header?.colors || [],
      isFullWidth: Boolean(data?.header?.isFullWidth),
      height: data?.header?.height || 184,
    },
    card: {
      background: (data?.card?.background ? [getFieldValue(data?.card?.background)] : []) as [FileItemType?],
      contentImage: (data?.card?.contentImage ? [getFieldValue(data?.card?.contentImage)] : []) as [FileItemType?],
      colors: data?.card?.colors || [],
      columns:
        data?.card?.columns
          .map(({ icon, ...column }) => ({
            icon: (icon ? [getFieldValue(icon)] : []) as [FileItemType?],
            ...column,
          }))
          .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || [],
      isEnabled: Boolean(data?.card?.isEnabled),
      isScrollable: Boolean(data?.card?.isScrollable),
    },
    additionalInformations:
      data?.additionalInformations?.map(
        ({ isEnabled, iconImage, colors, variant, image, title, iconTooltip, ...additionalInformation }) => ({
          ...additionalInformation,
          isEnabled: Boolean(isEnabled),
          iconImage: (iconImage ? [getFieldValue(iconImage)] : []) as [FileItemType?],
          colors: colors || [],
          variant: variant || PhaseAdditionalInformationVariant.TOOLTIP,
          image: (image ? [getFieldValue(image)] : []) as [FileItemType?],
          title: title || '',
          iconTooltip: iconTooltip || '',
          sections:
            additionalInformation.sections?.map(({ image, ...section }) => ({
              ...section,
              image: (image ? [getFieldValue(image)] : []) as [FileItemType?],
            })) || [],
        }),
      ) || [],
    controls: {
      colors: data?.controls?.colors || [],
      nextUrl: data?.controls?.nextUrl,
      previousUrl: data?.controls?.previousUrl,
      button: {
        title: data?.controls?.button?.title,
        url: data?.controls?.button?.url,
        isVisible: data?.controls?.button?.isVisible,
      },
    },
  }
}

export const useMapFormValuesToPayload = () => {
  const handleUploadProcessFiles = useUploadProcessFilesApi()

  return async ({
    background,
    header: { logoImage: headerLogo, background: headerBackground, ...header },
    card: { background: cardBackground, contentImage: cardContent, ...card },
    additionalInformations,
    ...values
  }: PhaseFormConfig) => {
    const imagesMetadata = Object.fromEntries(
      await Promise.all(
        Object.entries({
          background,
          headerLogo,
          headerBackground,
          cardBackground,
          cardContent,
        })
          .filter(([_, [value]]) => !value || isFile(value))
          .map(async ([key, [value]]) => [key, value ? (await handleUploadProcessFiles([value as File]))[0] : null]),
      ),
    )

    const getAdditionalInformationsImageMetadata = async (image: [(FileItemType | undefined)?]) => {
      if (!image) return null
      if (isFile(image[0])) {
        return (await handleUploadProcessFiles([image[0]]))[0]
      }
      return undefined
    }

    const additionalInformationsWithMetadata = await Promise.all(
      additionalInformations.map(async ({ image, iconImage, sections, ...info }) => {
        const imageMetadata = await getAdditionalInformationsImageMetadata(image)
        const iconImageMetadata = await getAdditionalInformationsImageMetadata(iconImage)

        const sectionsWithMetadata = await Promise.all(
          sections.map(async ({ image: sectionImage, colors, ...section }) => {
            const sectionImageMetadata = await getAdditionalInformationsImageMetadata(sectionImage)

            return {
              ...section,
              ...(sectionImageMetadata !== undefined ? { image: sectionImageMetadata } : {}),
              colors: colors || [],
            }
          }),
        )

        return {
          ...info,
          ...(imageMetadata !== undefined ? { image: imageMetadata } : {}),
          ...(iconImageMetadata !== undefined ? { iconImage: iconImageMetadata } : {}),
          sections: sectionsWithMetadata,
        }
      }),
    )

    return {
      ...values,
      background: imagesMetadata.background,
      header: {
        ...header,
        logoImage: imagesMetadata.headerLogo,
        background: imagesMetadata.headerBackground,
      },
      card: {
        ...card,
        contentImage: imagesMetadata.cardContent,
        background: imagesMetadata.cardBackground,
        columns: await Promise.all(
          card.columns.map(async ({ icon, ...column }, index) => {
            const iconMetadata = isFile(icon?.[0]) ? (await handleUploadProcessFiles([icon?.[0]]))[0] : null

            return {
              ...column,
              ...(!icon?.[0] || isFile(icon?.[0]) ? { icon: iconMetadata } : {}),
              order: index + 1,
            }
          }),
        ),
      },
      additionalInformations: additionalInformationsWithMetadata || [],
    }
  }
}

const colors = {
  page: [{ title: 'Page background', defaultValue: '#fafafa' }],
  header: [
    { title: 'Header text', defaultValue: '#fff' },
    { title: 'Header background', defaultValue: '#0014CC' },
  ],
  card: [
    { title: 'Card background', defaultValue: '#fff' },
    { title: 'Column circle background', defaultValue: '#0014CC' },
    { title: 'Column text', defaultValue: '#606060' },
  ],
  additionalInformation: [
    { title: 'Icon background', defaultValue: '#0014CC' },
    { title: 'Icon color', defaultValue: '#fff' },
    { title: 'Additional inf. title', defaultValue: '#121619' },
    { title: 'Additional inf. background', defaultValue: '#fff' },
    { title: 'Additional inf. close icon button', defaultValue: '#121619' },
    { title: 'Additional inf. close button', defaultValue: '#0014CC' },
    { title: 'Additional inf. close button hover', defaultValue: '#fff' },
    { title: 'Additional inf. tooltip background', defaultValue: '#4D5358' },
    { title: 'Additional inf. tooltip text', defaultValue: '#fff' },
  ],
  additionalInformationSection: [
    { title: 'Section title', defaultValue: '#4D5358' },
    { title: 'Section separator', defaultValue: '#4D5358' },
  ],
  controls: [
    { title: 'Navigation text', defaultValue: '#0014CC' },
    { title: 'Button background', defaultValue: '#0014CC' },
    { title: 'Button text', defaultValue: '#fff' },
  ],
}

export const colorsConfig: Record<
  Templates,
  {
    page: TemplateColorConfig[]
    header: TemplateColorConfig[]
    card: TemplateColorConfig[]
    additionalInformation: TemplateColorConfig[]
    additionalInformationSection: TemplateColorConfig[]
    controls: TemplateColorConfig[]
  }
> = {
  [Templates.InnerFirst]: colors,
  [Templates.InnerSecond]: colors,
}
