import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Logo } from 'pages/customProcess/components/boots/assets/logo.svg'
import Scheme from 'pages/customProcess/components/boots/processPhases/connectionPlanning/assets/connection-planning.png'
import styles from 'pages/customProcess/components/boots/processPhases/connectionPlanning/ConnectionPlanning.module.scss'
import { FooterNavigation } from 'pages/customProcess/components/boots/processPhases/footerNavigation/FooterNavigation'

const ConnectionPlanning = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Flex direction="column" className={styles.root}>
      <div className={styles.header}>
        <Flex justify="between" className={styles.headerContent}>
          <div>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn}>
                <WppIconChevron direction="left" slot="icon-start" color="#05054b" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <h1 className={styles.title}>BIG idea development & Connection Planning</h1>
            <ul className={styles.subtitleList}>
              <li className={styles.subtitle}>
                Collaborate to create industry leading solutions by fusing incredible creative thinking with innovative
                CX, media and PR ideas
              </li>
              <li className={styles.subtitle}>
                Integrate big idea, CX, media and PR plan development, to create innovative, deliverable, effective
                connected plan
              </li>
            </ul>
          </div>
          <Flex align="center" className={styles.logoWrapper}>
            <Logo />
          </Flex>
        </Flex>
      </div>
      <div className={styles.mainContentWrapper}>
        <Flex justify="center" className={styles.mainContent}>
          <img src={Scheme} alt="" />
        </Flex>
      </div>
      <FooterNavigation previousLink="/custom/strategic-response" nextLink="/custom/activation" />
    </Flex>
  )
}

export default ConnectionPlanning
