import { useEffect } from 'react'

import designHeaderIllustrationImgSrc from 'pages/customProcess/components/lvmh/assets/design-header-illustration.png'
import schemeImgSrc from 'pages/customProcess/components/lvmh/assets/design-scheme.png'
import { ProcessPhasePageLayout } from 'pages/customProcess/components/lvmh/processPhases/components/processPhasePageLayout/ProcessPhasePageLayout'

const DesignLvmh = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <ProcessPhasePageLayout
      title="Design"
      description="Planning of Omni-Channel Journeys and Experience Plan"
      headerImgSrc={designHeaderIllustrationImgSrc}
      tag="Governance"
      previousLink="/custom/lvmh/discover"
      nextLink="/custom/lvmh/deliver"
    >
      <img alt="" src={schemeImgSrc} style={{ width: '100%', pointerEvents: 'none', userSelect: 'none' }} />
    </ProcessPhasePageLayout>
  )
}

export default DesignLvmh
