// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BRWAm{min-width:calc(16.6666666667% - 24px)}.kR0m2{width:80px;height:80px;padding:15px;border-radius:50%}.D93Io{max-width:100%}.HE_Di ul,.HE_Di ol{padding-left:0}`, "",{"version":3,"sources":["webpack://./src/pages/phaseBuilder/templates/simpleCard/processCard/descriptionCard/DescriptionCard.module.scss"],"names":[],"mappings":"AAAA,OACE,qCAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CAGF,OACE,cAAA,CAIA,oBAEE,cAAA","sourcesContent":[".minWidth {\n  min-width: calc(100% / 6 - 24px);\n}\n\n.iconContainer {\n  width: 80px;\n  height: 80px;\n  padding: 15px;\n  border-radius: 50%;\n}\n\n.icon {\n  max-width: 100%;\n}\n\n.richTextContainer {\n  & ul,\n  ol {\n    padding-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"minWidth": `BRWAm`,
	"iconContainer": `kR0m2`,
	"icon": `D93Io`,
	"richTextContainer": `HE_Di`
};
export default ___CSS_LOADER_EXPORT___;
