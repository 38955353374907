import { WppCard, WppTypography, WppIconTick } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/pfizer/optimisationCaseProcess/OptimisationCaseProcess.module.scss'
import { ProcessCard } from 'pages/customProcess/utils/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const OptimisationCaseProcess = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/pfizer/process/media' : '/custom/media'
  const nextLink = isDemoTenant ? '/pfizer/process/business' : '/custom/business'

  const processConfigs: { [key: string]: any; cards: any[]; tools: string[] }[] = [
    {
      align: 'start',
      justify: 'center',
      direction: 'column',
      className: styles.shadowWrapper,
      gap: 45,
      cards: [
        {
          title: 'Agile Production',
          content: (
            <>
              <WppTypography type="s-body">
                Pod teams, focusing on automation, working off a task backlog, prioritizing talent toward innovation
              </WppTypography>
              <Flex align="center" gap={8}>
                <div className={clsx(styles.cross, styles.appIcon)} />
                <WppTypography className={styles.tool} type="s-strong">
                  Brand Guardian®
                </WppTypography>
              </Flex>
              <WppTypography className={styles.tool} type="s-strong">
                Create Measure®
              </WppTypography>
            </>
          ),
        },
        {
          title: 'Optimization',
          content: (
            <>
              <WppTypography type="s-body">
                Building of predictive model to identify contribution of different campaign elements to performance
              </WppTypography>
              <Flex align="center" gap={8}>
                <div className={clsx(styles.chart, styles.appIcon)} />
                <div className={clsx(styles.chartSecond, styles.appIcon)} />
                <WppTypography className={styles.tool} type="s-strong">
                  Rapid Modelling®
                </WppTypography>
              </Flex>
            </>
          ),
        },
      ],
      tools: ['Custom algorithms', 'Performance analysis'],
    },
    {
      align: 'start',
      justify: 'center',
      gap: 45,
      cards: [
        {
          title: 'Measurement & Learning agenda',
          content: (
            <>
              <WppTypography type="s-body">
                Reviewing of reporting dashboard to track a campaign performance
              </WppTypography>
              <Flex gap={8}>
                <div className={clsx(styles.redIcon, styles.appIcon)} />
                <div className={clsx(styles.greenIcon, styles.appIcon)} />
              </Flex>
              <WppTypography className={styles.tool} type="s-strong">
                Reporting Capabilities (EMPD)®
              </WppTypography>
            </>
          ),
        },
      ],
      tools: ['Attribution', 'Reporting'],
    },
  ]
  return (
    <ProcessCard
      title="Optimisation and Measurement"
      headerVariant="optimisation"
      description="A rigorous measurement and optimzation strategy that capitalizes on measureable KPIs from each phase in a CLM environment"
      projectLink={isDemoTenant ? '/pfizer/project-example' : ''}
      nextLink={nextLink}
      previousLink={previousLink}
    >
      <div className={styles.wrapper}>
        <Flex key="main" justify="between" className={styles.content}>
          {processConfigs.map(({ align, justify, direction, cards, className, gap, tools }, i) => (
            <Flex
              align={align}
              justify={justify}
              direction={direction ?? 'column'}
              gap={gap}
              key={`cards-${i}`}
              className={className}
            >
              <Flex key={`cards-with-shadow-${i}`} direction="column" gap={gap} align="center">
                {cards.map(card => (
                  <WppCard key={card.title + i} className={clsx(styles.card, card?.className)}>
                    <div slot="header" className={styles.header}>
                      <WppTypography type="m-strong">{card.title}</WppTypography>
                    </div>
                    {card.content}
                  </WppCard>
                ))}
                <div
                  key={`shadow-${i}`}
                  className={clsx(styles.shadow, cards.length > 1 ? styles.multiShadow : styles.singleShadow)}
                />
              </Flex>
              <Flex direction="column" key={`tools-${i}`} className={styles.toolsWapper}>
                {tools.map((tool, toolIdx) => (
                  <Flex key={`tool-${i}-${toolIdx}`} gap={5}>
                    <WppIconTick className={styles.toolsIcon} />
                    <WppTypography type="s-strong" className={styles.tools}>
                      {tool}
                    </WppTypography>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </div>
    </ProcessCard>
  )
}

export default OptimisationCaseProcess
