import { WppCard, WppTypography, WppIconTick } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/pfizer/mediaCaseProcess/MediaCaseProcess.module.scss'
import { ProcessCard } from 'pages/customProcess/utils/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const MediaCaseProcess = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/pfizer/process/ideas' : '/custom/ideas'
  const nextLink = isDemoTenant ? '/pfizer/process/optimisation' : '/custom/optimisation'

  const processConfigs: { [key: string]: any; cards: any[]; tools: string[] }[] = [
    {
      align: 'start',
      justify: 'center',
      direction: 'column',
      className: styles.shadowWrapper,
      gap: 45,
      cards: [
        {
          title: 'Setup & IMP of modular content development',
          content: (
            <>
              <WppTypography type="s-body">
                Tagged, searchable array of modular content pieces, both written and visual for optimized for review,
                and productization
              </WppTypography>
              <Flex>
                <div className={clsx(styles.chart, styles.appIcon)} />
              </Flex>
            </>
          ),
        },
        {
          title: 'Campaign Development',
          content: (
            <>
              <WppTypography type="s-body">Content production, optimization and measurement</WppTypography>
              <Flex>
                <div className={clsx(styles.cross, styles.appIcon)} />
              </Flex>
            </>
          ),
        },
      ],
      tools: ['QA automation', 'Content assembly automation'],
    },
    {
      align: 'start',
      justify: 'center',
      gap: 45,
      cards: [
        {
          title: 'Production',
          content: (
            <>
              <WppTypography type="s-body">
                Leverages all phase approaches, key insights to efficiently scale our global GTM across all channels and
                markets
              </WppTypography>
              <Flex>
                <div className={clsx(styles.lift, styles.appIcon)} />
              </Flex>
            </>
          ),
        },
      ],
      tools: ['QA automation', 'Content assembly automation'],
    },
    {
      align: 'start',
      justify: 'center',
      direction: 'column',
      className: styles.shadowWrapper,
      gap: 45,
      cards: [
        {
          title: 'Media',
          content: (
            <>
              <WppTypography type="s-body">
                Data driven planning, buying, A/B testing and in flight, signal level optimization
              </WppTypography>
              <Flex>
                <div className={clsx(styles.dots, styles.appIcon)} />
              </Flex>
            </>
          ),
        },
        {
          title: 'Activation',
          content: (
            <>
              <WppTypography type="s-body">
                Pulling through from our comms strategy and creative platform to data driven tactical plans
              </WppTypography>
              <Flex>
                <div className={clsx(styles.em, styles.appIcon)} />
                <div className={clsx(styles.sLogo, styles.appIcon)} />
                <div className={clsx(styles.circle, styles.appIcon)} />
                <WppTypography className={styles.tool} type="s-strong">
                  Promethius®
                </WppTypography>
              </Flex>
            </>
          ),
        },
      ],
      tools: ['JTBD for each stage', 'Addressable activation', 'Non-addressable activation'],
    },
  ]
  return (
    <ProcessCard
      headerVariant="media"
      title="Connect, Production, Media and Activation"
      description="One unified, accessible, modular content enablement platform that promotes faster, more efficient decisioning & automation through to activation"
      projectLink={isDemoTenant ? '/pfizer/project-example' : ''}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <div className={styles.wrapper}>
        <Flex key="main" justify="between" className={styles.content}>
          {processConfigs.map(({ align, justify, direction, cards, className, gap, tools }, i) => (
            <Flex
              align={align}
              justify={justify}
              direction={direction ?? 'column'}
              gap={gap}
              key={`cards-${i}`}
              className={className}
            >
              <Flex key={`cards-with-shadow-${i}`} direction="column" gap={gap} align="center">
                {cards.map(card => (
                  <WppCard key={card.title + i} className={clsx(styles.card, card?.className)}>
                    <div slot="header" className={styles.header}>
                      <WppTypography type="m-strong">{card.title}</WppTypography>
                    </div>
                    {card.content}
                  </WppCard>
                ))}
                <div
                  key={`shadow-${i}`}
                  className={clsx(styles.shadow, cards.length > 1 ? styles.multiShadow : styles.singleShadow)}
                />
              </Flex>
              <Flex direction="column" key={`tools-${i}`} className={styles.toolsWapper}>
                {tools.map((tool, toolIdx) => (
                  <Flex key={`tool-${i}-${toolIdx}`} gap={5}>
                    <WppIconTick className={styles.toolsIcon} />
                    <WppTypography type="s-strong" className={styles.tools}>
                      {tool}
                    </WppTypography>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </div>
    </ProcessCard>
  )
}

export default MediaCaseProcess
