import provokeSchemeImgSrc from 'pages/customProcess/components/audible/assets/processPhasesSchemes/provoke.png'
import { provokeContent } from 'pages/customProcess/components/audible/audibleConfig'
import { PageLayout } from 'pages/customProcess/components/audible/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/audible/processPhases/pages/provoke/Provoke.module.scss'

const Provoke = () => {
  const { type, title, description, previousLink, nextLink } = provokeContent

  return (
    <PageLayout type={type} title={title} description={description} previousLink={previousLink} nextLink={nextLink}>
      <img src={provokeSchemeImgSrc} alt="" className={styles.schemeImg} />
    </PageLayout>
  )
}

export default Provoke
