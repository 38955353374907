import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from 'pages/customProcess/components/sein/components/PhaseSvg.module.scss'
import { Phases } from 'pages/customProcess/components/sein/types'

interface Props {
  activePhase: MayBeNull<Phases>
  onPhaseEnter: (phase: Phases) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
}

export const DeliverPhaseSvg = ({ activePhase, onPhaseEnter, onPhaseLeave }: Props) => {
  const navigate = useNavigate()

  return (
    <svg width={875} height={875} viewBox="0 0 875 875" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g
        onMouseEnter={onPhaseEnter(Phases.Deliver)}
        onMouseLeave={onPhaseLeave}
        className={styles.processPhase}
        onClick={() => navigate('/custom/sein/deliver')}
      >
        <g>
          <g filter="url(#filter4_dd_134_72)">
            <path
              d="M159.872 597C131.884 548.523 117.101 493.553 117.001 437.577C116.9 381.601 131.484 326.578 159.297 278.001C187.11 229.423 227.178 188.991 275.503 160.741C323.828 132.492 378.717 117.412 434.692 117.008L435.615 245.005C402.03 245.247 369.097 254.295 340.102 271.245C311.107 288.195 287.066 312.454 270.378 341.6C253.69 370.747 244.94 403.761 245 437.346C245.061 470.932 253.93 503.914 270.723 533L159.872 597Z"
              fill="#50BCE3"
            />
          </g>
          <g>
            <g filter="url(#filter5_d_134_72)">
              <path
                d="M198.054 371.021C199.5 371.37 200.687 371.944 201.613 372.743C202.528 373.523 203.22 374.439 203.691 375.491C204.15 376.523 204.409 377.631 204.469 378.814C204.514 379.993 204.4 381.151 204.126 382.286L202.641 388.446L186.122 384.462L187.562 378.49C187.844 377.323 188.261 376.206 188.815 375.138C189.353 374.066 190.048 373.164 190.9 372.431C191.736 371.695 192.748 371.182 193.937 370.892C195.126 370.603 196.498 370.646 198.054 371.021ZM197.052 375.174C196.119 374.949 195.311 374.919 194.63 375.084C193.936 375.229 193.349 375.515 192.867 375.942C192.374 376.35 191.971 376.862 191.658 377.478C191.348 378.078 191.111 378.72 190.946 379.404L190.468 381.388L200.127 383.717L200.583 381.827C200.755 381.111 200.843 380.408 200.845 379.718C200.835 379.007 200.703 378.359 200.45 377.771C200.2 377.167 199.8 376.643 199.249 376.197C198.702 375.736 197.97 375.395 197.052 375.174Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter6_d_134_72)">
              <path
                d="M208.677 365.298L192.555 359.933L196.162 349.093L199.464 350.192L197.107 357.274L200.136 358.282L202.364 351.587L205.483 352.625L203.256 359.32L206.603 360.434L209.096 352.942L212.421 354.048L208.677 365.298Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter7_d_134_72)">
              <path
                d="M215.296 346.496L199.608 339.968L201.194 336.157L213.581 341.311L216.107 335.239L219.409 336.613L215.296 346.496Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter8_d_134_72)">
              <path d="M222.491 330.057L207.22 322.606L209.03 318.896L224.301 326.347L222.491 330.057Z" fill="white" />
            </g>
            <g filter="url(#filter9_d_134_72)">
              <path
                d="M232.48 311.179L230.436 314.738L212.498 311.854L214.793 307.858L227.201 310.444L227.248 310.361L218.748 300.969L221.007 297.036L232.48 311.179Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter10_d_134_72)">
              <path
                d="M239.533 299.662L225.528 290.039L231.998 280.624L234.866 282.594L230.639 288.746L233.27 290.554L237.266 284.738L239.976 286.6L235.98 292.416L238.888 294.414L243.359 287.906L246.247 289.89L239.533 299.662Z"
                fill="white"
              />
            </g>
            <g filter="url(#filter11_d_134_72)">
              <path
                d="M256.8 276.347L249.248 274.955L248.372 276.037L253.612 280.282L251.074 283.415L237.871 272.719L241.95 267.684C242.464 267.05 243.031 266.49 243.651 266.004C244.269 265.495 244.924 265.131 245.618 264.91C246.321 264.677 247.044 264.624 247.785 264.751C248.527 264.878 249.276 265.249 250.035 265.863C250.93 266.588 251.486 267.44 251.702 268.418C251.919 269.397 251.81 270.421 251.376 271.49L259.821 272.617L256.8 276.347ZM247.538 269.06C247.227 268.808 246.923 268.675 246.626 268.661C246.326 268.624 246.041 268.671 245.77 268.802C245.497 268.91 245.236 269.08 244.987 269.311C244.749 269.529 244.534 269.756 244.342 269.993L242.967 271.69L246.045 274.182L247.268 272.672C247.48 272.411 247.678 272.128 247.862 271.824C248.034 271.511 248.154 271.197 248.224 270.882C248.293 270.568 248.278 270.256 248.177 269.949C248.086 269.628 247.873 269.332 247.538 269.06Z"
                fill="white"
              />
            </g>
            <path
              d="M265.159 264.376C264.613 264.455 264.105 264.076 264.026 263.53L262.951 256.122L255.543 257.197C254.997 257.277 254.489 256.898 254.41 256.351C254.33 255.805 254.709 255.297 255.256 255.218L263.653 253.999C264.2 253.92 264.707 254.299 264.786 254.845L266.005 263.242C266.085 263.789 265.706 264.296 265.159 264.376Z"
              fill="white"
            />
          </g>
        </g>
        <g className={clsx(styles.phaseDetails, activePhase === Phases.Deliver && styles.phaseDetailsActive)}>
          <g opacity={0.4} filter="url(#filter0_b_134_72)">
            <path
              d="M270.852 533.128C254.08 504.079 245.211 471.142 245.129 437.599C245.047 404.056 253.754 371.077 270.382 341.945C287.011 312.814 310.982 288.547 339.907 271.562C368.832 254.577 401.702 245.467 435.244 245.137L436.646 387.958C428.056 388.042 419.637 390.376 412.229 394.726C404.821 399.076 398.682 405.29 394.424 412.751C390.165 420.212 387.935 428.658 387.956 437.249C387.977 445.839 390.248 454.275 394.544 461.714L270.852 533.128Z"
              fill="#50BCE3"
            />
          </g>
          <g opacity={0.4} filter="url(#filter1_b_134_72)">
            <path
              d="M303.727 514.147C290.268 490.836 283.153 464.404 283.09 437.486C283.027 410.569 290.02 384.104 303.37 360.73C316.72 337.356 335.963 317.889 359.18 304.268C382.397 290.646 408.778 283.347 435.695 283.096L436.667 387.532C428 387.613 419.505 389.963 412.03 394.349C404.554 398.735 398.359 405.003 394.06 412.529C389.761 420.055 387.51 428.576 387.53 437.243C387.55 445.911 389.841 454.421 394.175 461.927L303.727 514.147Z"
              fill="#50BCE3"
            />
          </g>
          <g opacity={0.4} filter="url(#filter2_b_134_72)">
            <path
              d="M336.743 495.085C326.608 477.53 321.253 457.624 321.214 437.352C321.175 417.081 326.452 397.154 336.52 379.56C346.587 361.966 361.093 347.319 378.589 337.082C396.085 326.844 415.96 321.374 436.231 321.217L436.747 387.907C428.139 387.973 419.699 390.296 412.269 394.643C404.84 398.991 398.68 405.21 394.405 412.682C390.129 420.153 387.888 428.615 387.905 437.223C387.922 445.831 390.195 454.285 394.499 461.74L336.743 495.085Z"
              fill="#50BCE3"
            />
          </g>
          <g opacity={0.4} filter="url(#filter3_b_134_72)">
            <path
              d="M364.462 479.082C357.125 466.374 353.249 451.964 353.221 437.291C353.192 422.617 357.013 408.192 364.3 395.456C371.588 382.72 382.088 372.117 394.753 364.707C407.419 357.296 421.806 353.337 436.479 353.223L436.748 388.014C428.159 388.08 419.738 390.398 412.324 394.736C404.91 399.074 398.764 405.28 394.498 412.735C390.232 420.19 387.996 428.634 388.013 437.223C388.029 445.813 390.298 454.248 394.593 461.686L364.462 479.082Z"
              fill="#50BCE3"
            />
          </g>
          <g clipPath="url(#clip0_134_72)">
            <path
              d="M369.096 428.604C369.212 427.822 369.339 427.043 369.478 426.267C369.802 426.338 370.129 426.412 370.453 426.483C370.455 426.472 370.457 426.461 370.458 426.453C370.335 426.35 370.215 426.211 370.101 426.042C369.986 425.873 369.897 425.677 369.826 425.459C369.754 425.241 369.709 425.002 369.689 424.736C369.67 424.475 369.689 424.196 369.751 423.91C369.865 423.364 370.078 422.91 370.38 422.545C370.682 422.184 371.08 421.959 371.568 421.876C371.177 421.477 370.935 421.052 370.84 420.609C370.748 420.162 370.777 419.655 370.937 419.092C371.082 418.581 371.287 418.178 371.55 417.884C371.812 417.589 372.114 417.39 372.448 417.285C372.782 417.18 373.15 417.149 373.544 417.193C373.938 417.236 374.347 417.335 374.767 417.481C376.139 417.963 377.509 418.449 378.88 418.931C378.654 419.633 378.439 420.338 378.233 421.049C376.869 420.613 375.505 420.177 374.141 419.741C373.814 419.638 373.51 419.621 373.234 419.69C372.955 419.762 372.762 419.986 372.661 420.365C372.587 420.632 372.569 420.867 372.605 421.071C372.64 421.275 372.719 421.452 372.836 421.602C372.953 421.752 373.103 421.876 373.288 421.98C373.47 422.082 373.67 422.161 373.884 422.224C375.132 422.582 376.38 422.939 377.631 423.299C377.45 424.02 377.283 424.742 377.125 425.469C375.868 425.148 374.61 424.83 373.353 424.509C373.226 424.478 373.067 424.44 372.88 424.405C372.69 424.369 372.51 424.359 372.33 424.379C372.15 424.399 371.985 424.466 371.839 424.576C371.692 424.687 371.589 424.878 371.534 425.152C371.473 425.456 371.476 425.715 371.543 425.926C371.609 426.137 371.723 426.314 371.875 426.452C372.029 426.591 372.212 426.698 372.42 426.778C372.633 426.857 372.854 426.925 373.092 426.977L376.667 427.773C376.536 428.508 376.414 429.249 376.304 429.993C373.901 429.534 371.494 429.072 369.091 428.613L369.096 428.604Z"
              fill="white"
            />
            <path
              d="M373.971 409.005C373.666 409.091 373.392 409.274 373.144 409.552C372.899 409.831 372.714 410.114 372.594 410.407C372.524 410.581 372.471 410.764 372.435 410.963C372.398 411.161 372.389 411.359 372.403 411.555C372.417 411.751 372.474 411.926 372.563 412.08C372.655 412.235 372.797 412.351 372.983 412.43C373.284 412.554 373.559 412.525 373.812 412.355C374.063 412.183 374.321 411.94 374.577 411.626C374.837 411.314 375.107 410.978 375.391 410.619C375.676 410.259 375.999 409.952 376.358 409.696C376.717 409.439 377.119 409.28 377.564 409.217C378.009 409.154 378.512 409.259 379.078 409.536C379.619 409.798 380.039 410.127 380.35 410.519C380.661 410.911 380.872 411.338 380.986 411.799C381.101 412.259 381.128 412.741 381.071 413.242C381.01 413.746 380.88 414.245 380.685 414.742C380.434 415.367 380.115 415.909 379.717 416.375C379.32 416.84 378.818 417.23 378.207 417.546C377.805 416.807 377.411 416.062 377.034 415.305C377.42 415.194 377.758 415 378.055 414.723C378.351 414.445 378.57 414.129 378.715 413.764C378.787 413.587 378.84 413.399 378.871 413.201C378.904 413.001 378.907 412.812 378.884 412.632C378.861 412.451 378.804 412.288 378.712 412.141C378.62 411.994 378.487 411.884 378.311 411.802C378.016 411.669 377.731 411.679 377.463 411.837C377.192 411.992 376.925 412.221 376.659 412.521C376.393 412.821 376.118 413.158 375.833 413.526C375.548 413.893 375.237 414.216 374.897 414.5C374.557 414.785 374.171 414.983 373.746 415.1C373.318 415.214 372.825 415.173 372.267 414.964C371.726 414.76 371.292 414.469 370.964 414.083C370.638 413.694 370.41 413.252 370.279 412.753C370.148 412.254 370.114 411.718 370.174 411.151C370.234 410.585 370.38 410.03 370.601 409.49C370.858 408.869 371.194 408.307 371.603 407.807C372.013 407.307 372.508 406.921 373.085 406.648C373.369 407.443 373.663 408.228 373.967 409.003L373.971 409.005Z"
              fill="white"
            />
            <path
              d="M380.619 402.906C380.419 402.922 380.224 402.995 380.025 403.119C379.829 403.246 379.669 403.415 379.55 403.625C379.257 404.146 379.184 404.638 379.331 405.089C379.477 405.543 379.8 405.9 380.293 406.166C380.784 406.43 381.256 406.505 381.709 406.393C382.162 406.281 382.527 405.978 382.806 405.482C382.928 405.265 382.987 405.052 382.982 404.841C382.977 404.63 382.94 404.429 382.875 404.236C383.583 404.075 384.289 403.925 384.996 403.788C385.126 404.274 385.135 404.749 385.025 405.223C384.915 405.696 384.759 406.113 384.552 406.478C384.283 406.951 383.957 407.356 383.567 407.693C383.177 408.031 382.753 408.285 382.287 408.447C381.821 408.61 381.329 408.677 380.811 408.65C380.292 408.622 379.768 408.479 379.244 408.211C378.718 407.947 378.291 407.609 377.961 407.197C377.636 406.784 377.404 406.327 377.279 405.824C377.155 405.32 377.135 404.792 377.227 404.237C377.315 403.684 377.511 403.142 377.804 402.621C378.031 402.218 378.338 401.835 378.725 401.475C379.111 401.115 379.56 400.871 380.065 400.745C380.237 401.469 380.423 402.184 380.614 402.899L380.619 402.906Z"
              fill="white"
            />
            <path
              d="M387.86 399.318C387.86 399.318 387.849 399.336 387.845 399.343C388.022 399.774 388.063 400.202 387.967 400.63C387.875 401.06 387.704 401.46 387.451 401.826C387.263 402.099 387.048 402.338 386.797 402.539C386.547 402.741 386.279 402.89 385.988 402.984C385.696 403.078 385.395 403.107 385.083 403.076C384.767 403.042 384.452 402.926 384.13 402.727C383.768 402.499 383.503 402.243 383.339 401.952C383.174 401.661 383.083 401.352 383.068 401.019C383.053 400.686 383.095 400.346 383.202 399.995C383.308 399.644 383.446 399.299 383.618 398.958C383.789 398.617 383.981 398.293 384.191 397.976C384.403 397.663 384.604 397.374 384.793 397.114C384.463 396.877 384.114 396.811 383.742 396.914C383.373 397.018 383.066 397.24 382.832 397.576C382.608 397.893 382.474 398.232 382.424 398.584C382.374 398.936 382.396 399.294 382.49 399.65C381.913 399.77 381.332 399.896 380.756 400.029C380.66 399.443 380.695 398.845 380.859 398.238C381.027 397.633 381.295 397.06 381.665 396.536C382.074 395.957 382.484 395.536 382.89 395.267C383.297 394.998 383.714 394.856 384.139 394.835C384.563 394.814 384.998 394.907 385.438 395.107C385.879 395.308 386.344 395.598 386.839 395.973C387.837 396.727 388.837 397.487 389.835 398.242C389.421 398.772 389.015 399.311 388.618 399.857C388.365 399.674 388.114 399.497 387.862 399.315L387.86 399.318ZM385.648 398.426C385.545 398.568 385.428 398.746 385.29 398.966C385.156 399.188 385.047 399.417 384.971 399.654C384.894 399.89 384.868 400.115 384.894 400.335C384.917 400.552 385.039 400.73 385.251 400.871C385.478 401.021 385.711 401.038 385.949 400.924C386.188 400.807 386.375 400.65 386.517 400.446C386.643 400.268 386.74 400.078 386.805 399.876C386.873 399.676 386.909 399.478 386.912 399.284C386.914 399.09 386.873 398.903 386.789 398.727C386.705 398.552 386.567 398.393 386.378 398.258C386.247 398.162 386.113 398.068 385.984 397.974C385.874 398.127 385.763 398.28 385.655 398.43L385.648 398.426Z"
              fill="white"
            />
            <path
              d="M384.876 392.597C385.363 391.998 385.859 391.405 386.366 390.818C386.621 391.032 386.88 391.247 387.136 391.46C387.142 391.451 387.147 391.442 387.156 391.435C387.096 391.286 387.056 391.117 387.039 390.919C387.022 390.721 387.035 390.517 387.077 390.302C387.119 390.086 387.197 389.863 387.31 389.633C387.423 389.402 387.576 389.179 387.775 388.967C388.192 388.513 388.6 388.212 388.992 388.062C389.385 387.91 389.769 387.868 390.145 387.934C390.521 388 390.889 388.152 391.252 388.393C391.611 388.632 391.975 388.923 392.334 389.265C393.322 390.197 394.306 391.13 395.294 392.061C394.773 392.585 394.262 393.115 393.759 393.653C392.86 392.851 391.956 392.05 391.057 391.247C390.9 391.106 390.729 390.965 390.547 390.826C390.366 390.687 390.178 390.581 389.978 390.51C389.781 390.44 389.579 390.422 389.378 390.462C389.177 390.502 388.972 390.636 388.769 390.862C388.565 391.088 388.441 391.307 388.397 391.518C388.354 391.728 388.366 391.927 388.438 392.116C388.509 392.305 388.618 392.483 388.769 392.649C388.921 392.816 389.089 392.976 389.278 393.132C390.221 393.92 391.162 394.703 392.105 395.49C391.623 396.046 391.15 396.607 390.69 397.176C388.751 395.652 386.815 394.129 384.876 392.605L384.876 392.597Z"
              fill="white"
            />
            <path
              d="M388.966 383.221C389.602 382.617 390.251 382.021 390.91 381.436C393.374 384.031 395.84 386.627 398.304 389.221C397.728 389.733 397.158 390.256 396.6 390.783C394.058 388.261 391.51 385.74 388.968 383.218L388.966 383.221Z"
              fill="white"
            />
            <path
              d="M400.15 380.648C399.575 381.064 399.009 381.486 398.446 381.918C398.97 382.543 399.496 383.173 400.019 383.798C400.148 383.951 400.274 384.086 400.398 384.203C400.522 384.319 400.65 384.401 400.784 384.449C400.919 384.497 401.066 384.503 401.227 384.464C401.388 384.424 401.564 384.331 401.763 384.186C401.861 384.112 401.982 384.007 402.127 383.87C402.271 383.733 402.358 383.599 402.385 383.466C402.776 383.96 403.168 384.451 403.559 384.946C403.413 385.165 403.243 385.365 403.043 385.548C402.847 385.733 402.644 385.9 402.438 386.058C402.136 386.286 401.834 386.469 401.533 386.602C401.229 386.738 400.927 386.813 400.625 386.83C400.323 386.846 400.023 386.793 399.732 386.667C399.442 386.54 399.159 386.328 398.892 386.024C398.119 385.153 397.343 384.279 396.57 383.408C396.174 383.733 395.784 384.062 395.396 384.396C394.984 383.951 394.574 383.503 394.162 383.058C394.559 382.717 394.958 382.381 395.363 382.048C394.878 381.505 394.398 380.961 393.913 380.417C394.56 379.883 395.218 379.359 395.888 378.846C396.355 379.403 396.819 379.959 397.285 380.52C397.856 380.081 398.438 379.647 399.023 379.221C399.4 379.695 399.772 380.169 400.149 380.643L400.15 380.648Z"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip1_134_72)">
            <path
              d="M333.578 421.417C333.836 419.76 334.133 418.117 334.474 416.482C334.594 415.912 334.752 415.351 334.95 414.796C335.147 414.24 335.408 413.763 335.725 413.367C336.041 412.971 336.423 412.684 336.862 412.506C337.305 412.33 337.832 412.326 338.448 412.492C339.084 412.665 339.559 413.004 339.88 413.504C340.201 414.005 340.374 414.594 340.402 415.275C340.413 415.282 340.424 415.279 340.435 415.285C340.583 414.892 340.776 414.553 341.011 414.262C341.249 413.974 341.513 413.748 341.815 413.577C342.115 413.409 342.44 413.304 342.79 413.269C343.139 413.234 343.494 413.263 343.853 413.364C344.445 413.53 344.904 413.799 345.231 414.171C345.557 414.544 345.79 414.968 345.931 415.445C346.071 415.921 346.134 416.431 346.12 416.975C346.106 417.518 346.045 418.044 345.934 418.558C345.595 420.143 345.301 421.74 345.053 423.347C341.228 422.705 337.404 422.059 333.578 421.417ZM338.641 419.4C338.764 418.729 338.899 418.061 339.035 417.394C339.08 417.182 339.104 416.967 339.103 416.751C339.103 416.536 339.076 416.333 339.015 416.147C338.955 415.961 338.858 415.796 338.721 415.658C338.584 415.52 338.404 415.422 338.179 415.368C337.945 415.312 337.736 415.319 337.555 415.398C337.376 415.474 337.22 415.592 337.094 415.755C336.97 415.916 336.861 416.107 336.777 416.329C336.689 416.548 336.623 416.77 336.579 416.988C336.445 417.628 336.319 418.273 336.201 418.919C337.015 419.081 337.825 419.241 338.639 419.403L338.641 419.4ZM343.366 420.34C343.512 419.552 343.672 418.763 343.837 417.982C343.882 417.779 343.904 417.573 343.906 417.354C343.911 417.137 343.876 416.933 343.81 416.74C343.741 416.544 343.635 416.378 343.491 416.227C343.345 416.079 343.151 415.973 342.905 415.915C342.638 415.85 342.405 415.865 342.207 415.967C342.008 416.068 341.843 416.218 341.705 416.417C341.57 416.612 341.456 416.834 341.372 417.079C341.283 417.325 341.22 417.559 341.173 417.774C341.029 418.455 340.894 419.138 340.768 419.821C341.634 419.991 342.498 420.164 343.365 420.334L343.366 420.34Z"
              fill="white"
            />
            <path
              d="M339.621 410.814C339.869 409.942 340.126 409.07 340.397 408.207C340.805 408.338 341.211 408.473 341.619 408.604C341.625 408.593 341.628 408.581 341.631 408.568C341.246 408.143 340.999 407.701 340.888 407.247C340.778 406.792 340.819 406.275 341.016 405.704C341.066 405.556 341.125 405.409 341.188 405.264C341.251 405.119 341.318 404.99 341.395 404.877C342.1 405.134 342.809 405.392 343.516 405.645C343.401 405.806 343.301 405.966 343.213 406.129C343.125 406.292 343.053 406.469 342.988 406.659C342.824 407.142 342.761 407.544 342.792 407.867C342.824 408.19 342.932 408.464 343.109 408.687C343.284 408.913 343.523 409.097 343.822 409.243C344.121 409.388 344.459 409.524 344.835 409.645C345.895 409.987 346.958 410.33 348.016 410.675C347.767 411.468 347.53 412.266 347.301 413.066C344.74 412.316 342.183 411.569 339.622 410.819L339.621 410.814Z"
              fill="white"
            />
            <path
              d="M350.362 401.538C350.362 401.538 350.35 401.559 350.349 401.568C350.628 401.996 350.767 402.458 350.758 402.953C350.753 403.45 350.653 403.929 350.463 404.4C350.323 404.747 350.143 405.066 349.921 405.35C349.697 405.638 349.443 405.865 349.157 406.041C348.867 406.214 348.555 406.323 348.212 406.362C347.869 406.401 347.509 406.35 347.125 406.21C346.692 406.05 346.359 405.833 346.121 405.551C345.886 405.275 345.721 404.952 345.637 404.589C345.551 404.229 345.526 403.839 345.56 403.43C345.595 403.02 345.67 402.606 345.78 402.19C345.889 401.773 346.02 401.37 346.177 400.971C346.333 400.573 346.481 400.212 346.628 399.879C346.225 399.699 345.84 399.713 345.47 399.918C345.099 400.124 344.824 400.441 344.646 400.869C344.477 401.273 344.408 401.676 344.429 402.073C344.454 402.471 344.552 402.857 344.727 403.228C344.137 403.498 343.55 403.78 342.964 404.067C342.738 403.446 342.643 402.782 342.692 402.076C342.741 401.371 342.903 400.682 343.182 400.015C343.49 399.281 343.834 398.719 344.206 398.328C344.577 397.936 344.989 397.677 345.433 397.548C345.877 397.42 346.358 397.412 346.868 397.524C347.378 397.636 347.941 397.835 348.541 398.123C349.768 398.704 350.994 399.286 352.221 399.867C351.897 400.554 351.584 401.247 351.279 401.944C350.973 401.809 350.664 401.671 350.359 401.536L350.362 401.538ZM347.82 401.109C347.743 401.287 347.654 401.515 347.558 401.79C347.461 402.064 347.395 402.343 347.365 402.625C347.332 402.904 347.355 403.161 347.427 403.392C347.501 403.627 347.666 403.791 347.919 403.895C348.195 404.008 348.448 403.967 348.674 403.779C348.904 403.593 349.071 403.37 349.177 403.11C349.271 402.881 349.332 402.651 349.361 402.41C349.39 402.17 349.384 401.947 349.346 401.732C349.307 401.517 349.221 401.326 349.095 401.153C348.966 400.978 348.787 400.838 348.556 400.738C348.394 400.665 348.234 400.598 348.073 400.525C347.987 400.717 347.903 400.915 347.82 401.109Z"
              fill="white"
            />
            <path
              d="M345.718 394.911C346.098 394.136 346.491 393.365 346.893 392.603C347.213 392.77 347.534 392.943 347.854 393.11C347.86 393.1 347.866 393.089 347.872 393.079C347.774 392.932 347.692 392.757 347.631 392.547C347.57 392.338 347.534 392.11 347.531 391.86C347.525 391.614 347.557 391.352 347.625 391.07C347.691 390.791 347.801 390.511 347.961 390.227C348.296 389.631 348.658 389.201 349.036 388.935C349.413 388.669 349.809 388.526 350.221 388.505C350.631 388.482 351.059 388.555 351.492 388.724C351.929 388.895 352.378 389.117 352.838 389.4C354.098 390.165 355.355 390.928 356.613 391.696C356.184 392.4 355.767 393.115 355.356 393.829C354.222 393.181 353.084 392.531 351.95 391.883C351.751 391.769 351.537 391.656 351.314 391.551C351.092 391.443 350.864 391.377 350.643 391.348C350.417 391.321 350.206 391.354 350.002 391.448C349.799 391.542 349.614 391.74 349.448 392.035C349.284 392.334 349.203 392.605 349.204 392.844C349.205 393.083 349.265 393.301 349.382 393.488C349.5 393.675 349.657 393.843 349.854 393.984C350.049 394.129 350.265 394.262 350.5 394.388C351.675 395.008 352.851 395.634 354.025 396.255C353.639 396.983 353.266 397.72 352.901 398.462C350.508 397.279 348.116 396.092 345.723 394.91L345.718 394.911Z"
              fill="white"
            />
            <path
              d="M362.399 383.331C361.927 383.93 361.467 384.535 361.017 385.146C360.734 384.941 360.45 384.729 360.168 384.523C360.161 384.534 360.155 384.544 360.151 384.551C360.206 384.715 360.245 384.906 360.272 385.124C360.296 385.339 360.295 385.573 360.265 385.816C360.237 386.062 360.179 386.312 360.091 386.564C360.004 386.821 359.882 387.058 359.723 387.285C359.385 387.772 358.998 388.158 358.558 388.448C358.118 388.738 357.65 388.932 357.152 389.024C356.652 389.119 356.137 389.111 355.607 389.001C355.077 388.891 354.555 388.678 354.047 388.356C353.571 388.059 353.167 387.697 352.832 387.268C352.5 386.841 352.254 386.381 352.105 385.88C351.955 385.379 351.92 384.862 351.994 384.322C352.072 383.783 352.285 383.251 352.642 382.738C352.962 382.274 353.333 381.892 353.748 381.587C354.161 381.286 354.616 381.122 355.111 381.099C355.118 381.088 355.124 381.078 355.13 381.068C353.704 380.021 352.276 378.979 350.85 377.932C351.41 377.176 351.977 376.424 352.562 375.683C355.845 378.228 359.122 380.775 362.405 383.321L362.399 383.331ZM357.788 382.773C357.302 382.414 356.8 382.273 356.277 382.35C355.758 382.429 355.3 382.741 354.913 383.287C354.526 383.833 354.386 384.372 354.485 384.885C354.588 385.4 354.893 385.825 355.396 386.157C355.896 386.493 356.405 386.614 356.914 386.528C357.422 386.443 357.861 386.138 358.23 385.615C358.6 385.091 358.741 384.581 358.649 384.072C358.557 383.563 358.271 383.131 357.785 382.771L357.788 382.773Z"
              fill="white"
            />
            <path
              d="M361.522 366.838C362.099 366.269 362.681 365.708 363.274 365.154C367.371 366.637 371.348 368.314 375.188 370.172C374.431 370.802 373.687 371.443 372.956 372.093C372.15 371.711 371.336 371.333 370.519 370.968C369.357 372.03 368.223 373.118 367.125 374.232C367.481 375.043 367.842 375.852 368.213 376.654C367.551 377.34 366.902 378.038 366.263 378.743C364.49 374.903 362.907 370.928 361.526 366.84L361.522 366.838ZM364.707 368.468C365.174 369.692 365.662 370.904 366.166 372.107C366.88 371.396 367.609 370.694 368.353 370.005C367.148 369.474 365.932 368.964 364.707 368.468Z"
              fill="white"
            />
            <path
              d="M368.654 360.468C369.419 359.848 370.198 359.236 370.987 358.64C373.373 361.699 375.764 364.757 378.15 367.816C377.453 368.345 376.765 368.885 376.085 369.434C373.608 366.447 371.13 363.455 368.654 360.468Z"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip2_134_72)">
            <path
              d="M300.691 447.982C300.411 446.417 300.163 444.844 299.946 443.264C299.857 442.605 299.829 441.969 299.86 441.36C299.891 440.752 300.01 440.205 300.217 439.726C300.424 439.247 300.724 438.843 301.125 438.522C301.526 438.201 302.049 437.996 302.696 437.901C303.333 437.81 303.874 437.852 304.324 438.023C304.774 438.195 305.15 438.464 305.443 438.827C305.739 439.191 305.972 439.638 306.141 440.167C306.31 440.696 306.439 441.276 306.52 441.913C306.606 442.557 306.698 443.205 306.794 443.846C308.193 443.574 309.593 443.315 310.992 443.064C311.119 443.913 311.258 444.755 311.405 445.598C307.83 446.325 304.255 447.118 300.683 447.986L300.691 447.982ZM304.79 444.239C304.699 443.641 304.613 443.041 304.532 442.441C304.501 442.198 304.449 441.969 304.38 441.752C304.311 441.535 304.218 441.344 304.098 441.181C303.978 441.018 303.829 440.896 303.648 440.812C303.466 440.729 303.248 440.713 302.995 440.755C302.72 440.803 302.505 440.914 302.356 441.092C302.204 441.268 302.096 441.481 302.036 441.727C301.974 441.976 301.946 442.241 301.957 442.531C301.969 442.82 301.993 443.095 302.03 443.35C302.096 443.821 302.165 444.294 302.236 444.763C303.088 444.583 303.939 444.411 304.793 444.241L304.79 444.239Z"
              fill="white"
            />
            <path
              d="M298.527 437.024C298.453 436.104 298.39 435.187 298.341 434.265C302.229 433.801 306.117 433.404 309.997 433.078C310.041 433.908 310.095 434.735 310.155 435.56C306.283 435.976 302.406 436.464 298.527 437.024Z"
              fill="white"
            />
            <path
              d="M308.917 426.229C308.917 426.229 308.916 426.251 308.917 426.265C309.315 426.534 309.601 426.901 309.775 427.357C309.949 427.812 310.039 428.294 310.046 428.804C310.05 429.179 310.008 429.548 309.918 429.901C309.828 430.254 309.691 430.574 309.504 430.851C309.316 431.13 309.082 431.36 308.802 431.534C308.519 431.712 308.188 431.815 307.807 431.847C307.376 431.88 307.009 431.822 306.7 431.67C306.396 431.516 306.14 431.298 305.934 431.006C305.731 430.716 305.566 430.378 305.451 429.994C305.333 429.609 305.247 429.207 305.191 428.787C305.134 428.366 305.102 427.943 305.091 427.52C305.081 427.097 305.079 426.705 305.084 426.345C304.673 426.359 304.343 426.535 304.097 426.878C303.852 427.222 303.732 427.624 303.734 428.083C303.736 428.519 303.82 428.91 303.984 429.257C304.148 429.604 304.374 429.907 304.656 430.169C304.244 430.662 303.837 431.163 303.433 431.67C303.017 431.21 302.694 430.655 302.482 430.005C302.267 429.354 302.157 428.669 302.155 427.954C302.152 427.168 302.245 426.516 302.419 426.003C302.596 425.493 302.856 425.081 303.192 424.771C303.529 424.461 303.941 424.246 304.423 424.122C304.905 423.999 305.462 423.935 306.092 423.929C307.364 423.915 308.636 423.91 309.907 423.914C309.881 424.682 309.866 425.448 309.853 426.216C309.541 426.223 309.225 426.228 308.911 426.238L308.917 426.229ZM306.561 426.927C306.558 427.122 306.566 427.365 306.581 427.657C306.595 427.948 306.643 428.228 306.715 428.492C306.79 428.757 306.9 428.981 307.046 429.159C307.195 429.34 307.395 429.422 307.653 429.409C307.93 429.394 308.133 429.252 308.263 428.987C308.394 428.721 308.458 428.447 308.455 428.166C308.454 427.919 308.421 427.68 308.358 427.453C308.297 427.223 308.21 427.022 308.098 426.846C307.986 426.67 307.844 426.53 307.669 426.428C307.497 426.323 307.288 426.276 307.053 426.281C306.89 426.285 306.721 426.29 306.558 426.293C306.554 426.506 306.555 426.717 306.554 426.932L306.561 426.927Z"
              fill="white"
            />
            <path
              d="M304.742 416.457C304.667 417.232 304.598 418.006 304.537 418.781C305.373 418.81 306.205 418.837 307.038 418.87C307.243 418.878 307.434 418.873 307.605 418.857C307.776 418.84 307.927 418.797 308.059 418.721C308.188 418.647 308.293 418.527 308.373 418.366C308.454 418.204 308.505 417.988 308.531 417.718C308.543 417.581 308.544 417.403 308.541 417.181C308.538 416.959 308.494 416.789 308.412 416.667C309.053 416.708 309.697 416.751 310.338 416.792C310.401 417.077 310.438 417.368 310.441 417.671C310.444 417.973 310.435 418.268 310.41 418.557C310.375 418.98 310.305 419.368 310.198 419.719C310.091 420.071 309.937 420.381 309.742 420.643C309.547 420.905 309.298 421.108 309.003 421.255C308.704 421.401 308.354 421.47 307.938 421.463C306.749 421.442 305.557 421.424 304.368 421.412C304.337 421.976 304.311 422.539 304.288 423.103C303.67 423.107 303.051 423.111 302.433 423.12C302.456 422.547 302.483 421.976 302.511 421.402C301.768 421.397 301.026 421.397 300.28 421.396C300.327 420.483 300.388 419.57 300.459 418.654C301.203 418.672 301.947 418.691 302.689 418.712C302.749 417.924 302.818 417.137 302.897 416.347C303.513 416.377 304.133 416.409 304.75 416.444L304.742 416.457Z"
              fill="white"
            />
            <path
              d="M304.984 414.206C304.916 414.788 304.853 415.368 304.79 415.948C304.172 415.913 303.555 415.877 302.938 415.842C302.998 415.251 303.064 414.659 303.133 414.069C302.692 414.038 302.251 414.006 301.81 413.975C301.398 413.948 301.016 413.877 300.659 413.767C300.305 413.659 300.004 413.471 299.756 413.203C299.506 412.939 299.336 412.566 299.235 412.092C299.137 411.616 299.142 411 299.251 410.241C299.291 409.956 299.344 409.679 299.406 409.408C299.469 409.137 299.547 408.875 299.639 408.622C300.284 408.737 300.926 408.85 301.568 408.968C301.494 409.12 301.43 409.261 301.385 409.394C301.338 409.522 301.302 409.676 301.274 409.847C301.208 410.286 301.239 410.623 301.367 410.854C301.496 411.089 301.804 411.228 302.296 411.272C302.688 411.309 303.086 411.346 303.479 411.383C303.573 410.723 303.672 410.062 303.777 409.399C304.391 409.469 305.003 409.541 305.616 409.614C305.511 410.263 305.414 410.913 305.323 411.561C307.197 411.748 309.069 411.947 310.937 412.158C310.818 412.991 310.708 413.825 310.609 414.661C308.735 414.496 306.858 414.343 304.98 414.199L304.984 414.206Z"
              fill="white"
            />
            <path
              d="M307.617 409.175C307.026 409.097 306.51 408.909 306.07 408.604C305.632 408.297 305.28 407.908 305.015 407.432C304.751 406.957 304.58 406.409 304.51 405.798C304.438 405.184 304.472 404.541 304.61 403.881C304.747 403.216 304.974 402.61 305.286 402.061C305.599 401.511 305.979 401.057 306.415 400.7C306.857 400.341 307.342 400.095 307.872 399.951C308.405 399.81 308.963 399.804 309.544 399.934C310.128 400.062 310.624 400.296 311.034 400.636C311.449 400.974 311.772 401.382 312.011 401.855C312.25 402.328 312.397 402.856 312.459 403.437C312.516 404.018 312.484 404.613 312.354 405.229C312.224 405.845 312.019 406.41 311.729 406.929C311.44 407.447 311.095 407.882 310.68 408.24C310.266 408.597 309.801 408.861 309.284 409.034C308.764 409.206 308.21 409.258 307.618 409.18L307.617 409.175ZM308.077 406.595C308.637 406.68 309.122 406.573 309.536 406.269C309.95 405.97 310.218 405.501 310.353 404.861C310.488 404.222 310.425 403.695 310.159 403.273C309.897 402.853 309.488 402.586 308.934 402.476C308.379 402.367 307.888 402.462 307.465 402.76C307.042 403.059 306.755 403.543 306.618 404.208C306.481 404.873 306.553 405.415 306.826 405.832C307.102 406.252 307.518 406.501 308.078 406.586L308.077 406.595Z"
              fill="white"
            />
            <path
              d="M306.286 397.59C306.518 396.71 306.761 395.835 307.015 394.964C307.403 395.061 307.792 395.163 308.179 395.26C308.185 395.251 308.185 395.237 308.191 395.227C307.821 394.836 307.584 394.418 307.478 393.977C307.368 393.533 307.404 393.021 307.587 392.442C307.634 392.292 307.684 392.143 307.743 391.995C307.802 391.847 307.864 391.715 307.936 391.597C308.612 391.794 309.287 391.99 309.963 392.187C309.856 392.355 309.76 392.52 309.682 392.692C309.6 392.863 309.53 393.044 309.471 393.236C309.319 393.727 309.261 394.131 309.295 394.448C309.33 394.765 309.429 395.027 309.6 395.239C309.767 395.448 309.996 395.615 310.279 395.737C310.565 395.862 310.885 395.968 311.243 396.061C312.252 396.326 313.258 396.589 314.265 396.858C314.028 397.672 313.799 398.488 313.582 399.306C311.159 398.722 308.728 398.15 306.296 397.596L306.286 397.59Z"
              fill="white"
            />
            <path
              d="M308.51 390.186C308.788 389.355 309.076 388.531 309.373 387.707C309.693 387.813 310.016 387.92 310.334 388.029C310.34 388.019 310.343 388.007 310.346 387.995C310.238 387.871 310.144 387.711 310.061 387.517C309.978 387.323 309.923 387.105 309.89 386.866C309.859 386.623 309.858 386.362 309.889 386.076C309.92 385.79 309.993 385.494 310.114 385.186C310.338 384.606 310.641 384.131 311.022 383.762C311.403 383.394 311.854 383.18 312.375 383.125C312.046 382.661 311.873 382.183 311.86 381.691C311.845 381.202 311.967 380.649 312.231 380.047C312.472 379.499 312.756 379.076 313.077 378.769C313.401 378.465 313.745 378.267 314.112 378.172C314.478 378.076 314.861 378.063 315.265 378.134C315.668 378.205 316.072 378.335 316.484 378.523C317.824 379.133 319.16 379.741 320.497 380.355C320.135 381.125 319.779 381.899 319.434 382.679C318.101 382.109 316.768 381.539 315.434 380.972C315.115 380.836 314.803 380.799 314.503 380.858C314.203 380.917 313.962 381.152 313.785 381.559C313.66 381.846 313.602 382.102 313.6 382.325C313.601 382.55 313.646 382.748 313.741 382.922C313.838 383.093 313.965 383.243 314.14 383.367C314.309 383.493 314.501 383.596 314.71 383.679C315.935 384.161 317.158 384.646 318.383 385.137C318.055 385.924 317.739 386.713 317.43 387.506C316.194 387.049 314.958 386.592 313.724 386.14C313.599 386.095 313.442 386.044 313.253 385.988C313.068 385.933 312.878 385.907 312.691 385.914C312.5 385.919 312.32 385.979 312.146 386.089C311.972 386.198 311.829 386.397 311.721 386.691C311.599 387.015 311.553 387.296 311.579 387.532C311.606 387.768 311.688 387.97 311.816 388.134C311.948 388.299 312.113 388.431 312.316 388.537C312.52 388.643 312.736 388.735 312.97 388.814C314.144 389.211 315.321 389.611 316.493 390.011C316.202 390.811 315.925 391.611 315.653 392.417C313.276 391.669 310.897 390.932 308.512 390.205L308.51 390.186Z"
              fill="white"
            />
            <path
              d="M314.203 368.762C314.913 367.473 315.644 366.193 316.401 364.928C316.95 364.007 317.565 363.194 318.243 362.486C318.92 361.778 319.637 361.246 320.392 360.895C321.143 360.541 321.923 360.398 322.731 360.451C323.537 360.507 324.341 360.822 325.158 361.388C325.88 361.89 326.411 362.492 326.748 363.186C327.088 363.881 327.27 364.616 327.303 365.398C327.336 366.18 327.23 366.977 326.989 367.798C326.747 368.619 326.399 369.411 325.936 370.178C325.187 371.421 324.467 372.677 323.767 373.95C320.583 372.216 317.398 370.485 314.209 368.761L314.203 368.762ZM323.154 370.527C323.389 370.119 323.631 369.715 323.869 369.309C324.193 368.771 324.447 368.244 324.635 367.722C324.822 367.204 324.916 366.705 324.91 366.231C324.901 365.754 324.787 365.304 324.554 364.879C324.321 364.455 323.951 364.073 323.436 363.737C322.993 363.444 322.539 363.288 322.079 363.273C321.62 363.257 321.175 363.349 320.743 363.552C320.311 363.755 319.894 364.057 319.495 364.455C319.096 364.853 318.744 365.315 318.432 365.834C318.14 366.32 317.852 366.807 317.565 367.301C319.425 368.376 321.29 369.45 323.151 370.525L323.154 370.527Z"
              fill="white"
            />
            <path
              d="M332.794 358.491C332.794 358.491 332.782 358.511 332.773 358.518C332.912 358.993 332.904 359.468 332.757 359.952C332.609 360.436 332.381 360.884 332.071 361.305C331.842 361.614 331.582 361.886 331.294 362.118C331.005 362.35 330.704 362.521 330.386 362.632C330.07 362.74 329.746 362.781 329.424 362.745C329.096 362.711 328.779 362.586 328.471 362.366C328.122 362.118 327.875 361.833 327.736 361.511C327.601 361.191 327.543 360.846 327.568 360.475C327.59 360.108 327.676 359.726 327.829 359.335C327.977 358.945 328.163 358.561 328.38 358.182C328.597 357.803 328.834 357.437 329.089 357.085C329.343 356.733 329.583 356.413 329.81 356.121C329.487 355.866 329.132 355.797 328.736 355.919C328.34 356.041 327.997 356.289 327.714 356.664C327.447 357.022 327.268 357.397 327.175 357.784C327.083 358.176 327.067 358.57 327.121 358.966C326.501 359.103 325.882 359.246 325.264 359.395C325.234 358.748 325.338 358.086 325.577 357.415C325.819 356.747 326.161 356.112 326.601 355.529C327.088 354.884 327.558 354.413 328.009 354.113C328.459 353.814 328.911 353.645 329.354 353.615C329.799 353.581 330.243 353.673 330.681 353.886C331.124 354.097 331.581 354.403 332.061 354.803C333.032 355.614 334.005 356.423 334.978 357.231C334.486 357.841 334 358.455 333.52 359.073C333.276 358.879 333.029 358.684 332.785 358.49L332.794 358.491ZM330.569 357.552C330.446 357.71 330.303 357.915 330.135 358.162C329.966 358.409 329.828 358.665 329.722 358.928C329.615 359.19 329.563 359.445 329.563 359.687C329.563 359.929 329.666 360.127 329.872 360.279C330.093 360.445 330.338 360.46 330.6 360.324C330.865 360.189 331.083 360.008 331.255 359.777C331.406 359.573 331.53 359.362 331.623 359.136C331.716 358.91 331.776 358.687 331.8 358.472C331.823 358.258 331.801 358.052 331.732 357.857C331.663 357.663 331.536 357.491 331.353 357.344C331.223 357.242 331.098 357.139 330.968 357.037C330.836 357.208 330.7 357.381 330.569 357.552Z"
              fill="white"
            />
            <path
              d="M335.231 348.848C334.662 348.851 334.159 349.096 333.731 349.579C333.581 349.752 333.466 349.947 333.396 350.173C333.322 350.397 333.373 350.585 333.54 350.736C333.678 350.861 333.849 350.866 334.06 350.762C334.268 350.655 334.508 350.496 334.782 350.287C335.055 350.081 335.347 349.86 335.663 349.627C335.974 349.395 336.305 349.211 336.651 349.076C336.996 348.942 337.346 348.895 337.695 348.932C338.043 348.97 338.388 349.158 338.725 349.484C339.07 349.82 339.272 350.185 339.338 350.575C339.405 350.965 339.38 351.358 339.275 351.756C339.166 352.156 338.988 352.55 338.743 352.938C338.497 353.327 338.228 353.687 337.939 354.022C337.558 354.458 337.123 354.848 336.631 355.202C336.138 355.556 335.616 355.767 335.066 355.84C335.003 355.102 334.947 354.364 334.901 353.619C335.26 353.609 335.585 353.538 335.879 353.408C336.173 353.278 336.449 353.065 336.706 352.769C336.902 352.543 337.055 352.313 337.161 352.082C337.266 351.85 337.241 351.664 337.09 351.523C336.947 351.391 336.767 351.371 336.553 351.465C336.34 351.559 336.097 351.707 335.83 351.912C335.562 352.116 335.276 352.337 334.972 352.572C334.667 352.808 334.347 353.003 334.017 353.156C333.687 353.309 333.345 353.379 332.994 353.362C332.642 353.345 332.284 353.185 331.926 352.878C331.591 352.592 331.37 352.265 331.266 351.893C331.161 351.523 331.14 351.139 331.211 350.738C331.279 350.34 331.426 349.937 331.648 349.529C331.869 349.122 332.134 348.745 332.44 348.398C332.829 347.958 333.273 347.565 333.779 347.228C334.283 346.885 334.809 346.695 335.353 346.653C335.307 347.388 335.271 348.115 335.243 348.842L335.231 348.848Z"
              fill="white"
            />
            <path
              d="M335.047 339.837C336.221 341.099 337.398 342.359 338.581 343.613C338.587 343.603 338.599 343.598 338.605 343.588C338.548 343.441 338.525 343.265 338.527 343.056C338.533 342.849 338.57 342.634 338.64 342.403C338.71 342.177 338.82 341.938 338.971 341.69C339.123 341.442 339.316 341.205 339.556 340.979C340.058 340.501 340.525 340.189 340.954 340.034C341.382 339.882 341.788 339.847 342.17 339.931C342.548 340.014 342.911 340.195 343.252 340.465C343.59 340.739 343.927 341.065 344.251 341.451C345.145 342.498 346.036 343.543 346.934 344.584C346.307 345.152 345.687 345.729 345.068 346.311C344.247 345.408 343.431 344.497 342.617 343.589C342.473 343.429 342.317 343.267 342.151 343.108C341.984 342.948 341.805 342.825 341.61 342.742C341.414 342.659 341.211 342.633 340.992 342.67C340.778 342.706 340.542 342.844 340.297 343.085C340.048 343.324 339.886 343.561 339.805 343.79C339.727 344.02 339.705 344.24 339.75 344.451C339.792 344.661 339.88 344.862 340.011 345.05C340.14 345.241 340.29 345.421 340.461 345.602C341.315 346.503 342.17 347.401 343.026 348.299C342.425 348.897 341.832 349.504 341.243 350.118C338.496 347.381 335.768 344.629 333.057 341.856C333.713 341.176 334.376 340.499 335.046 339.831L335.047 339.837Z"
              fill="white"
            />
            <path
              d="M341.102 334.205C341.815 333.586 342.53 332.977 343.255 332.374C344.256 333.693 345.26 335.008 346.27 336.319C346.279 336.311 346.289 336.303 346.298 336.296C346.317 335.81 346.474 335.354 346.763 334.925C347.051 334.495 347.419 334.105 347.867 333.75C348.359 333.36 348.868 333.105 349.389 332.983C349.908 332.865 350.411 332.853 350.891 332.952C351.374 333.054 351.824 333.247 352.24 333.532C352.656 333.817 353.012 334.178 353.308 334.609C353.626 335.077 353.844 335.563 353.957 336.067C354.07 336.571 354.082 337.071 353.998 337.568C353.915 338.064 353.729 338.538 353.45 338.995C353.171 339.453 352.793 339.868 352.32 340.248C352.092 340.432 351.857 340.576 351.61 340.681C351.366 340.788 351.123 340.864 350.885 340.916C350.645 340.967 350.42 340.991 350.21 340.984C349.997 340.98 349.809 340.96 349.65 340.921C349.644 340.93 349.631 340.936 349.624 340.941C349.828 341.2 350.032 341.459 350.235 341.712C349.641 342.211 349.046 342.718 348.462 343.228C345.986 340.239 343.535 337.23 341.11 334.192L341.102 334.205ZM347.914 338.783C348.264 339.229 348.685 339.468 349.18 339.514C349.675 339.56 350.172 339.374 350.68 338.966C351.19 338.555 351.484 338.102 351.559 337.597C351.638 337.093 351.513 336.609 351.186 336.149C350.853 335.691 350.443 335.432 349.944 335.379C349.445 335.326 348.93 335.51 348.404 335.934C347.878 336.357 347.575 336.832 347.504 337.345C347.431 337.86 347.568 338.339 347.92 338.781L347.914 338.783Z"
              fill="white"
            />
            <path
              d="M354.255 333.846C353.924 333.349 353.724 332.827 353.659 332.281C353.596 331.732 353.646 331.188 353.815 330.646C353.985 330.104 354.266 329.584 354.659 329.093C355.052 328.602 355.531 328.168 356.092 327.797C356.653 327.426 357.235 327.161 357.825 327.006C358.418 326.847 358.984 326.806 359.519 326.881C360.056 326.953 360.546 327.139 360.99 327.437C361.434 327.734 361.797 328.143 362.084 328.668C362.372 329.194 362.528 329.728 362.563 330.274C362.595 330.822 362.522 331.352 362.343 331.866C362.167 332.382 361.889 332.867 361.518 333.322C361.145 333.781 360.701 334.179 360.178 334.523C359.655 334.868 359.118 335.119 358.561 335.278C358.005 335.436 357.462 335.491 356.936 335.444C356.408 335.395 355.914 335.238 355.451 334.978C354.988 334.719 354.588 334.341 354.257 333.843L354.255 333.846ZM356.389 332.34C356.691 332.82 357.086 333.101 357.572 333.191C358.056 333.284 358.566 333.151 359.108 332.791C359.647 332.433 359.981 332.009 360.106 331.511C360.231 331.014 360.154 330.523 359.869 330.031C359.587 329.541 359.201 329.243 358.715 329.139C358.228 329.035 357.699 329.17 357.138 329.541C356.577 329.912 356.236 330.355 356.116 330.86C355.995 331.365 356.089 331.857 356.391 332.336L356.389 332.34Z"
              fill="white"
            />
            <path
              d="M369.217 327.873C369.217 327.873 369.198 327.883 369.185 327.889C369.109 328.381 368.907 328.814 368.581 329.189C368.249 329.565 367.864 329.873 367.418 330.121C367.087 330.305 366.747 330.438 366.398 330.526C366.047 330.612 365.71 330.636 365.388 330.601C365.062 330.564 364.764 330.459 364.491 330.283C364.216 330.111 363.988 329.856 363.807 329.521C363.602 329.14 363.503 328.775 363.517 328.421C363.532 328.067 363.623 327.728 363.8 327.4C363.973 327.071 364.211 326.763 364.502 326.475C364.796 326.183 365.118 325.913 365.466 325.663C365.815 325.414 366.176 325.181 366.545 324.971C366.914 324.761 367.261 324.573 367.576 324.407C367.403 324.032 367.116 323.813 366.717 323.749C366.318 323.685 365.914 323.762 365.512 323.983C365.131 324.19 364.813 324.454 364.572 324.769C364.329 325.087 364.15 325.44 364.032 325.825C363.432 325.679 362.831 325.537 362.226 325.401C362.471 324.793 362.837 324.236 363.332 323.726C363.824 323.219 364.388 322.791 365.018 322.454C365.712 322.078 366.32 321.857 366.841 321.78C367.361 321.703 367.82 321.749 368.224 321.915C368.628 322.08 368.976 322.359 369.276 322.748C369.572 323.135 369.849 323.618 370.102 324.193C370.621 325.357 371.142 326.518 371.671 327.674C370.986 328.014 370.303 328.364 369.623 328.717C369.488 328.433 369.351 328.152 369.216 327.868L369.217 327.873ZM367.644 326.049C367.471 326.141 367.259 326.262 367.009 326.415C366.76 326.568 366.529 326.741 366.326 326.936C366.122 327.13 365.969 327.337 365.87 327.559C365.771 327.781 365.78 328.007 365.896 328.234C366.023 328.482 366.232 328.6 366.517 328.595C366.804 328.586 367.074 328.517 367.316 328.382C367.532 328.263 367.729 328.123 367.906 327.957C368.083 327.791 368.225 327.618 368.335 327.429C368.448 327.243 368.512 327.044 368.531 326.836C368.551 326.628 368.51 326.415 368.409 326.201C368.339 326.051 368.268 325.904 368.198 325.755C368.01 325.851 367.823 325.952 367.633 326.051L367.644 326.049Z"
              fill="white"
            />
            <path
              d="M370.127 320.075C370.933 319.691 371.741 319.319 372.551 318.956C372.697 319.332 372.844 319.708 372.99 320.084C373.002 320.078 373.013 320.076 373.022 320.068C373.11 319.521 373.293 319.059 373.567 318.683C373.844 318.309 374.254 318.003 374.799 317.775C374.943 317.715 375.083 317.661 375.23 317.611C375.376 317.562 375.513 317.528 375.643 317.505C375.879 318.173 376.115 318.842 376.353 319.507C376.164 319.531 375.98 319.562 375.805 319.607C375.63 319.653 375.45 319.714 375.268 319.791C374.809 319.987 374.464 320.202 374.245 320.437C374.023 320.669 373.883 320.92 373.821 321.191C373.758 321.463 373.766 321.75 373.838 322.058C373.909 322.366 374.015 322.691 374.154 323.035C374.544 324.008 374.938 324.975 375.335 325.943C374.578 326.284 373.825 326.632 373.074 326.991C372.067 324.7 371.083 322.392 370.124 320.073L370.127 320.075Z"
              fill="white"
            />
            <path
              d="M386.897 321.547C386.173 321.774 385.454 322.008 384.739 322.25C384.641 321.934 384.546 321.621 384.45 321.302C384.44 321.305 384.429 321.307 384.417 321.313C384.36 321.472 384.277 321.645 384.169 321.83C384.057 322.013 383.918 322.197 383.752 322.374C383.584 322.554 383.39 322.719 383.169 322.876C382.949 323.033 382.709 323.155 382.451 323.249C381.893 323.452 381.359 323.552 380.845 323.544C380.33 323.539 379.852 323.436 379.408 323.242C378.965 323.044 378.575 322.761 378.235 322.388C377.895 322.015 377.627 321.56 377.434 321.026C377.254 320.53 377.17 320.022 377.175 319.501C377.183 318.981 377.277 318.482 377.469 318.007C377.66 317.532 377.945 317.1 378.326 316.718C378.708 316.332 379.189 316.034 379.764 315.824C380.286 315.635 380.792 315.531 381.286 315.518C381.778 315.504 382.218 315.624 382.604 315.878C382.615 315.875 382.627 315.869 382.638 315.867C382.18 314.265 381.739 312.656 381.308 311.039C382.175 310.747 383.041 310.468 383.916 310.199C384.838 314.016 385.835 317.797 386.899 321.544L386.897 321.547ZM383.655 318.648C383.496 318.102 383.198 317.719 382.756 317.499C382.313 317.279 381.779 317.276 381.16 317.5C380.538 317.721 380.111 318.071 379.88 318.53C379.647 318.994 379.623 319.495 379.805 320.032C379.985 320.572 380.299 320.943 380.741 321.149C381.183 321.356 381.702 321.354 382.303 321.137C382.904 320.921 383.318 320.594 383.548 320.143C383.782 319.693 383.817 319.195 383.658 318.65L383.655 318.648Z"
              fill="white"
            />
          </g>
          <g clipPath="url(#clip3_134_72)">
            <path
              d="M262.32 465.754C262.032 464.292 261.763 462.825 261.517 461.355C264.097 458.599 266.746 455.983 269.453 453.507C269.45 453.494 269.449 453.478 269.45 453.468C266.496 453.951 263.544 454.467 260.589 455.013C260.452 453.916 260.329 452.822 260.214 451.723C264.477 451.026 268.742 450.397 273.006 449.825C273.141 451.146 273.29 452.463 273.46 453.779C270.696 456.253 267.99 458.877 265.353 461.642C265.356 461.654 265.357 461.67 265.36 461.683C268.36 461.006 271.359 460.366 274.36 459.759C274.531 460.755 274.714 461.747 274.905 462.734C270.71 463.671 266.515 464.676 262.322 465.75L262.32 465.754Z"
              fill="white"
            />
            <path
              d="M270.769 438.472C271.337 438.916 271.784 439.487 272.112 440.188C272.44 440.889 272.621 441.619 272.658 442.389C272.694 443.117 272.627 443.816 272.45 444.482C272.276 445.149 272.005 445.744 271.636 446.265C271.267 446.787 270.813 447.221 270.268 447.571C269.726 447.917 269.105 448.139 268.405 448.23C267.705 448.321 267.062 448.262 266.465 448.053C265.875 447.842 265.353 447.514 264.912 447.066C264.468 446.616 264.115 446.063 263.85 445.405C263.586 444.747 263.434 444.029 263.395 443.252C263.359 442.534 263.44 441.865 263.635 441.257C263.831 440.649 264.127 440.127 264.521 439.688C264.915 439.25 265.395 438.904 265.963 438.646C266.528 438.386 267.161 438.24 267.866 438.194C268.152 438.179 268.436 438.157 268.721 438.142C268.766 440.478 268.868 442.805 269.025 445.128C269.535 444.951 269.931 444.65 270.219 444.223C270.503 443.793 270.631 443.292 270.597 442.711C270.57 442.224 270.456 441.817 270.238 441.495C270.027 441.172 269.755 440.897 269.43 440.669C269.869 439.932 270.317 439.206 270.769 438.482L270.769 438.472ZM266.962 441.302C266.502 441.33 266.113 441.531 265.804 441.914C265.495 442.298 265.351 442.774 265.38 443.336C265.399 443.676 265.465 443.972 265.579 444.222C265.692 444.472 265.831 444.679 265.993 444.848C266.155 445.016 266.34 445.136 266.546 445.22C266.754 445.301 266.963 445.335 267.184 445.323C267.093 443.987 267.022 442.646 266.966 441.304L266.962 441.302Z"
              fill="white"
            />
            <path
              d="M267.532 433.699C266.151 434.84 264.781 436.019 263.421 437.23C263.404 436 263.403 434.769 263.415 433.535C264.223 432.905 265.034 432.288 265.851 431.679C265.061 431.125 264.277 430.57 263.496 430.001C263.535 428.835 263.583 427.67 263.649 426.504C264.961 427.615 266.287 428.704 267.622 429.767C269.246 428.486 270.883 427.254 272.531 426.066C272.462 427.23 272.403 428.394 272.36 429.558C271.352 430.253 270.35 430.963 269.35 431.689C270.318 432.436 271.287 433.174 272.266 433.896C272.258 435.043 272.261 436.192 272.28 437.339C270.684 436.16 269.105 434.943 267.536 433.691L267.532 433.699Z"
              fill="white"
            />
            <path
              d="M273.48 415.947C273.358 416.891 273.247 417.842 273.145 418.787C272.746 418.757 272.347 418.726 271.948 418.696C271.945 418.71 271.944 418.72 271.945 418.736C272.104 418.856 272.257 419.003 272.408 419.181C272.559 419.359 272.689 419.572 272.799 419.819C272.909 420.067 272.993 420.342 273.046 420.65C273.099 420.959 273.114 421.288 273.081 421.645C273.015 422.397 272.86 422.997 272.612 423.454C272.365 423.91 272.044 424.261 271.65 424.506C271.255 424.75 270.803 424.908 270.285 424.981C269.768 425.054 269.216 425.08 268.62 425.065C266.997 425.022 265.37 424.986 263.741 424.955C263.81 423.907 263.886 422.863 263.975 421.815C265.42 421.869 266.864 421.932 268.305 422C268.558 422.012 268.824 422.012 269.099 422.006C269.374 422 269.631 421.952 269.868 421.861C270.108 421.771 270.307 421.627 270.473 421.426C270.636 421.228 270.737 420.932 270.772 420.544C270.811 420.159 270.779 419.841 270.675 419.592C270.571 419.342 270.422 419.144 270.222 419.006C270.024 418.863 269.795 418.765 269.533 418.705C269.271 418.645 268.995 418.609 268.706 418.586C267.243 418.485 265.777 418.387 264.311 418.3C264.42 417.254 264.546 416.207 264.675 415.163C267.613 415.408 270.547 415.668 273.478 415.951L273.48 415.947Z"
              fill="white"
            />
            <path
              d="M268.464 407.567C267.891 407.947 267.543 408.513 267.418 409.256C267.374 409.518 267.388 409.778 267.455 410.037C267.521 410.296 267.691 410.443 267.955 410.472C268.173 410.501 268.352 410.392 268.494 410.15C268.636 409.909 268.78 409.601 268.923 409.225C269.066 408.848 269.219 408.446 269.389 408.017C269.555 407.591 269.773 407.195 270.033 406.84C270.296 406.482 270.618 406.21 270.991 406.01C271.367 405.813 271.831 405.761 272.379 405.848C272.942 405.938 273.377 406.147 273.689 406.477C274.001 406.806 274.23 407.198 274.376 407.65C274.521 408.102 274.59 408.598 274.589 409.132C274.588 409.665 274.545 410.19 274.459 410.7C274.348 411.368 274.157 412.03 273.883 412.691C273.609 413.352 273.216 413.896 272.702 414.327C272.176 413.661 271.658 412.99 271.138 412.313C271.496 412.068 271.776 411.787 271.994 411.47C272.208 411.151 272.351 410.764 272.427 410.316C272.483 409.972 272.493 409.654 272.451 409.362C272.409 409.07 272.269 408.909 272.027 408.877C271.802 408.844 271.607 408.944 271.45 409.176C271.292 409.408 271.146 409.71 271.001 410.08C270.86 410.453 270.71 410.851 270.553 411.277C270.396 411.704 270.195 412.099 269.96 412.463C269.725 412.828 269.421 413.115 269.052 413.333C268.684 413.551 268.225 413.632 267.668 413.576C267.151 413.524 266.719 413.364 266.379 413.081C266.042 412.804 265.778 412.454 265.593 412.032C265.409 411.609 265.301 411.137 265.267 410.609C265.233 410.082 265.258 409.552 265.349 409.024C265.46 408.356 265.662 407.694 265.95 407.036C266.241 406.381 266.647 405.85 267.166 405.447C267.59 406.163 268.022 406.873 268.453 407.576L268.464 407.567Z"
              fill="white"
            />
            <path
              d="M279.437 386.041C279.556 387.054 279.558 388.061 279.44 389.07C279.325 390.081 279.118 391.08 278.825 392.075C278.513 393.138 278.086 394.076 277.533 394.902C276.983 395.731 276.339 396.41 275.598 396.943C274.854 397.48 274.032 397.85 273.12 398.052C272.212 398.257 271.252 398.258 270.245 398.047C269.237 397.837 268.37 397.458 267.646 396.896C266.925 396.331 266.365 395.635 265.975 394.798C265.586 393.962 265.365 393.016 265.324 391.96C265.287 390.906 265.435 389.794 265.774 388.632C266.123 387.444 266.576 386.381 267.127 385.453C267.678 384.525 268.373 383.777 269.195 383.213C269.64 384.177 270.09 385.132 270.55 386.078C270.029 386.406 269.581 386.813 269.218 387.306C268.851 387.797 268.541 388.458 268.3 389.285C268.099 389.973 268.019 390.622 268.063 391.23C268.108 391.837 268.255 392.385 268.504 392.866C268.757 393.35 269.104 393.753 269.539 394.077C269.978 394.403 270.484 394.633 271.064 394.764C271.652 394.9 272.219 394.92 272.762 394.83C273.305 394.741 273.806 394.544 274.27 394.251C274.731 393.956 275.137 393.567 275.485 393.091C275.835 392.611 276.098 392.048 276.293 391.398C276.49 390.734 276.592 390.144 276.61 389.626C276.624 389.105 276.605 388.685 276.557 388.359C275.889 388.165 275.224 387.973 274.554 387.783C274.29 388.623 274.032 389.461 273.783 390.299C272.942 390.072 272.102 389.85 271.259 389.627C271.815 387.759 272.402 385.9 273.021 384.049C275.157 384.708 277.291 385.371 279.427 386.041L279.437 386.041Z"
              fill="white"
            />
            <path
              d="M272.71 381.322C273.067 380.317 273.427 379.314 273.805 378.316C274.25 378.473 274.695 378.63 275.138 378.78C275.145 378.769 275.148 378.755 275.151 378.741C274.756 378.242 274.514 377.732 274.43 377.201C274.339 376.672 274.425 376.073 274.689 375.411C274.76 375.238 274.832 375.071 274.91 374.902C274.993 374.736 275.076 374.586 275.17 374.453C275.942 374.744 276.716 375.042 277.486 375.338C277.346 375.523 277.224 375.713 277.112 375.903C276.999 376.094 276.904 376.3 276.816 376.521C276.596 377.082 276.493 377.55 276.505 377.929C276.516 378.308 276.611 378.625 276.791 378.888C276.97 379.151 277.217 379.369 277.544 379.54C277.865 379.713 278.231 379.871 278.64 380.017C279.798 380.426 280.949 380.836 282.104 381.248C281.75 382.186 281.408 383.132 281.076 384.078C278.289 383.151 275.503 382.231 272.71 381.322Z"
              fill="white"
            />
            <path
              d="M279.62 375.514C278.966 375.26 278.428 374.897 278.012 374.417C277.596 373.936 277.297 373.389 277.119 372.761C276.945 372.135 276.895 371.456 276.97 370.725C277.049 369.997 277.252 369.262 277.579 368.538C277.904 367.808 278.318 367.166 278.812 366.619C279.308 366.067 279.854 365.645 280.439 365.35C281.03 365.054 281.644 364.896 282.287 364.876C282.93 364.856 283.565 365 284.196 365.302C284.826 365.605 285.334 366.008 285.721 366.513C286.109 367.014 286.375 367.573 286.53 368.188C286.684 368.803 286.718 369.456 286.637 370.152C286.554 370.842 286.359 371.528 286.055 372.213C285.748 372.896 285.367 373.5 284.901 374.027C284.434 374.554 283.925 374.967 283.356 375.271C282.792 375.573 282.191 375.753 281.551 375.812C280.914 375.867 280.269 375.775 279.617 375.518L279.62 375.514ZM280.82 372.648C281.433 372.899 282.018 372.908 282.565 372.669C283.116 372.433 283.544 371.962 283.862 371.253C284.179 370.545 284.241 369.916 284.048 369.357C283.855 368.799 283.459 368.382 282.855 368.106C282.248 367.833 281.669 367.807 281.108 368.043C280.55 368.274 280.102 368.76 279.773 369.488C279.448 370.219 279.385 370.867 279.592 371.429C279.792 371.992 280.206 372.397 280.82 372.648Z"
              fill="white"
            />
            <path
              d="M280.517 362.824C281.023 361.803 281.538 360.786 282.064 359.776C284.03 359.989 285.982 360.226 287.928 360.49C287.935 360.478 287.941 360.467 287.948 360.455C286.593 359.088 285.252 357.707 283.921 356.307C284.487 355.283 285.06 354.264 285.645 353.252C287.56 353.613 289.465 354 291.363 354.409C291.369 354.398 291.376 354.386 291.383 354.374C290.119 352.898 288.876 351.402 287.644 349.887C288.209 348.962 288.781 348.042 289.365 347.129C291.169 349.755 293.023 352.322 294.927 354.84C294.412 355.689 293.904 356.543 293.405 357.401C291.377 357.01 289.343 356.646 287.296 356.306C287.289 356.318 287.282 356.33 287.275 356.341C288.667 357.841 290.074 359.323 291.495 360.781C291.01 361.669 290.53 362.565 290.06 363.462C286.902 363.175 283.718 362.958 280.519 362.82L280.517 362.824Z"
              fill="white"
            />
            <path
              d="M295.644 341.673C295.11 342.432 294.581 343.19 294.058 343.955C294.862 344.512 295.666 345.069 296.47 345.625C296.67 345.764 296.858 345.88 297.035 345.968C297.217 346.059 297.391 346.109 297.562 346.11C297.737 346.114 297.91 346.059 298.084 345.941C298.261 345.825 298.438 345.631 298.629 345.36C298.723 345.227 298.832 345.04 298.96 344.806C299.087 344.572 299.146 344.366 299.137 344.193C299.75 344.632 300.361 345.075 300.974 345.514C300.867 345.855 300.727 346.181 300.548 346.494C300.371 346.814 300.184 347.113 299.989 347.401C299.699 347.823 299.4 348.181 299.077 348.483C298.757 348.787 298.42 349.013 298.067 349.162C297.712 349.314 297.345 349.371 296.962 349.339C296.581 349.304 296.188 349.156 295.787 348.889C294.622 348.121 293.464 347.35 292.299 346.582C291.931 347.146 291.565 347.716 291.201 348.282C290.591 347.892 289.983 347.497 289.373 347.107C289.74 346.527 290.112 345.956 290.487 345.38C289.762 344.901 289.04 344.419 288.315 343.94C288.913 343.03 289.516 342.128 290.129 341.227C290.843 341.725 291.555 342.217 292.268 342.715C292.798 341.937 293.336 341.165 293.88 340.4C294.465 340.823 295.055 341.248 295.641 341.671L295.644 341.673Z"
              fill="white"
            />
            <path
              d="M292.865 333.504C294.464 334.749 296.07 335.991 297.672 337.231C297.679 337.22 297.685 337.208 297.696 337.199C297.606 337.041 297.542 336.841 297.512 336.598C297.48 336.36 297.486 336.101 297.527 335.827C297.569 335.553 297.658 335.26 297.791 334.945C297.923 334.63 298.108 334.325 298.345 334.02C298.845 333.382 299.335 332.931 299.808 332.676C300.282 332.42 300.75 332.307 301.207 332.336C301.668 332.36 302.122 332.502 302.572 332.756C303.018 333.008 303.472 333.327 303.921 333.706C305.157 334.753 306.392 335.793 307.63 336.835C306.995 337.614 306.368 338.397 305.746 339.188C304.629 338.285 303.51 337.386 302.395 336.48C302.196 336.321 301.988 336.161 301.764 336.009C301.541 335.857 301.309 335.747 301.063 335.686C300.819 335.621 300.576 335.635 300.327 335.714C300.081 335.794 299.831 335.998 299.585 336.319C299.338 336.641 299.186 336.944 299.133 337.221C299.079 337.499 299.099 337.757 299.184 337.995C299.27 338.229 299.408 338.446 299.59 338.642C299.776 338.841 299.981 339.024 300.211 339.201C301.367 340.09 302.527 340.982 303.683 341.871C303.081 342.674 302.484 343.486 301.894 344.302C298.22 341.602 294.549 338.888 290.886 336.169C291.534 335.272 292.187 334.383 292.851 333.502L292.865 333.504Z"
              fill="white"
            />
            <path
              d="M303.888 321.57C305.181 320.152 306.5 318.759 307.838 317.389C308.398 316.817 308.971 316.322 309.561 315.904C310.148 315.49 310.733 315.206 311.31 315.058C311.887 314.91 312.457 314.916 313.015 315.076C313.572 315.236 314.104 315.601 314.615 316.169C315.116 316.726 315.434 317.294 315.574 317.864C315.711 318.437 315.708 319 315.566 319.561C315.424 320.122 315.157 320.682 314.775 321.243C314.393 321.804 313.931 322.359 313.386 322.913C312.835 323.479 312.289 324.043 311.743 324.618C312.923 325.794 314.11 326.968 315.293 328.14C314.577 328.897 313.866 329.662 313.163 330.432C310.056 327.495 306.964 324.541 303.884 321.567L303.888 321.57ZM310.052 322.927C310.556 322.396 311.063 321.872 311.572 321.344C311.778 321.135 311.961 320.913 312.118 320.681C312.279 320.451 312.393 320.214 312.467 319.98C312.544 319.743 312.559 319.505 312.519 319.262C312.478 319.023 312.353 318.792 312.148 318.572C311.923 318.335 311.682 318.204 311.421 318.186C311.156 318.167 310.892 318.216 310.617 318.337C310.345 318.455 310.077 318.627 309.819 318.852C309.558 319.075 309.316 319.3 309.098 319.528C308.699 319.942 308.301 320.352 307.905 320.769C308.623 321.49 309.337 322.208 310.058 322.925L310.052 322.927Z"
              fill="white"
            />
            <path
              d="M312.73 311.384C313.531 310.635 314.34 309.896 315.154 309.16C318.077 312.685 321.022 316.181 323.997 319.653C323.254 320.322 322.518 320.999 321.786 321.684C318.744 318.272 315.724 314.841 312.726 311.382L312.73 311.384Z"
              fill="white"
            />
            <path
              d="M329.596 313.421C329.596 313.421 329.574 313.44 329.566 313.445C329.591 314.031 329.456 314.58 329.154 315.094C328.852 315.607 328.472 316.059 328.005 316.455C327.662 316.745 327.292 316.984 326.902 317.168C326.512 317.352 326.125 317.46 325.736 317.493C325.346 317.526 324.975 317.475 324.617 317.338C324.26 317.201 323.936 316.963 323.649 316.616C323.325 316.227 323.13 315.824 323.063 315.413C323.001 315.005 323.032 314.589 323.162 314.173C323.292 313.757 323.494 313.342 323.772 312.94C324.05 312.538 324.362 312.15 324.713 311.775C325.061 311.404 325.428 311.054 325.81 310.724C326.192 310.394 326.55 310.096 326.881 309.825C326.592 309.44 326.205 309.254 325.728 309.277C325.25 309.3 324.798 309.484 324.378 309.834C323.98 310.166 323.675 310.542 323.468 310.965C323.257 311.385 323.129 311.834 323.08 312.307C322.35 312.281 321.617 312.259 320.88 312.244C321.025 311.492 321.324 310.762 321.782 310.063C322.238 309.367 322.794 308.741 323.449 308.204C324.168 307.611 324.823 307.206 325.411 306.996C325.996 306.78 326.547 306.722 327.055 306.816C327.563 306.911 328.036 307.142 328.474 307.515C328.912 307.888 329.346 308.373 329.783 308.97C330.66 310.183 331.54 311.391 332.422 312.596C331.699 313.16 330.984 313.729 330.27 314.305C330.047 314.011 329.823 313.717 329.599 313.423L329.596 313.421ZM327.344 311.699C327.164 311.845 326.947 312.036 326.689 312.272C326.43 312.508 326.207 312.764 326.01 313.036C325.818 313.31 325.688 313.584 325.62 313.859C325.554 314.139 325.617 314.396 325.802 314.631C326.006 314.887 326.272 314.975 326.611 314.897C326.947 314.822 327.241 314.676 327.498 314.46C327.725 314.269 327.921 314.06 328.091 313.825C328.259 313.593 328.388 313.355 328.474 313.112C328.559 312.869 328.592 312.627 328.565 312.38C328.542 312.136 328.445 311.901 328.277 311.682C328.158 311.529 328.042 311.371 327.928 311.22C327.731 311.377 327.536 311.54 327.34 311.697L327.344 311.699Z"
              fill="white"
            />
            <path
              d="M335.633 301.974C334.883 302.496 334.137 303.021 333.395 303.554C333.935 304.374 334.481 305.193 335.021 306.013C335.158 306.214 335.289 306.39 335.42 306.54C335.551 306.691 335.697 306.797 335.855 306.864C336.014 306.932 336.191 306.941 336.392 306.892C336.593 306.843 336.827 306.729 337.088 306.542C337.221 306.448 337.384 306.313 337.58 306.14C337.778 305.963 337.905 305.792 337.957 305.623C338.364 306.261 338.777 306.897 339.191 307.533C338.978 307.816 338.738 308.073 338.465 308.305C338.191 308.542 337.918 308.758 337.637 308.96C337.23 309.254 336.828 309.484 336.431 309.654C336.034 309.824 335.647 309.915 335.272 309.931C334.894 309.944 334.537 309.87 334.2 309.698C333.86 309.53 333.554 309.245 333.277 308.846C332.475 307.699 331.676 306.549 330.883 305.397C330.351 305.796 329.817 306.199 329.293 306.603C328.87 306.009 328.451 305.417 328.035 304.821C328.57 304.408 329.111 304.003 329.651 303.599C329.157 302.879 328.665 302.155 328.177 301.433C329.034 300.79 329.903 300.155 330.772 299.529C331.247 300.264 331.724 300.995 332.202 301.726C332.954 301.184 333.711 300.65 334.473 300.124C334.862 300.74 335.251 301.356 335.64 301.973L335.633 301.974Z"
              fill="white"
            />
            <path
              d="M337.824 300.482C337.255 300.865 336.69 301.25 336.125 301.635C335.736 301.019 335.353 300.401 334.967 299.781C335.537 299.388 336.115 299 336.692 298.612C336.423 298.166 336.153 297.719 335.886 297.269C335.635 296.854 335.441 296.431 335.305 296.005C335.169 295.58 335.133 295.145 335.21 294.703C335.283 294.259 335.5 293.811 335.859 293.352C336.218 292.892 336.775 292.424 337.521 291.952C337.804 291.773 338.086 291.613 338.366 291.464C338.651 291.317 338.934 291.191 339.219 291.086C339.545 291.791 339.871 292.496 340.197 293.201C340.009 293.247 339.844 293.297 339.697 293.352C339.55 293.406 339.391 293.491 339.22 293.595C338.784 293.867 338.506 294.154 338.383 294.459C338.26 294.758 338.344 295.164 338.636 295.67C338.868 296.074 339.101 296.484 339.334 296.887C339.988 296.466 340.646 296.057 341.306 295.643C341.661 296.282 342.016 296.92 342.373 297.554C341.724 297.957 341.076 298.367 340.428 298.777C341.544 300.701 342.668 302.613 343.806 304.518C342.974 305.055 342.145 305.594 341.32 306.146C340.149 304.263 338.982 302.372 337.827 300.478L337.824 300.482Z"
              fill="white"
            />
            <path
              d="M344.146 299.088C343.803 298.473 343.611 297.842 343.586 297.194C343.557 296.543 343.664 295.909 343.905 295.286C344.147 294.67 344.517 294.086 345.012 293.545C345.508 293.003 346.099 292.539 346.779 292.154C347.459 291.768 348.153 291.511 348.852 291.378C349.551 291.244 350.212 291.246 350.832 291.38C351.452 291.514 352.009 291.773 352.503 292.158C353 292.544 353.392 293.057 353.69 293.694C353.987 294.334 354.135 294.977 354.131 295.623C354.126 296.27 354.002 296.888 353.753 297.48C353.504 298.072 353.145 298.62 352.674 299.129C352.203 299.637 351.647 300.069 351.006 300.431C350.366 300.793 349.713 301.043 349.048 301.18C348.383 301.318 347.743 301.334 347.133 301.227C346.523 301.12 345.958 300.892 345.434 300.542C344.916 300.191 344.484 299.711 344.141 299.096L344.146 299.088ZM346.764 297.518C347.08 298.107 347.516 298.473 348.076 298.63C348.635 298.78 349.241 298.67 349.905 298.296C350.568 297.922 350.99 297.452 351.175 296.88C351.36 296.309 351.308 295.723 351.013 295.125C350.718 294.528 350.293 294.142 349.732 293.979C349.17 293.816 348.547 293.927 347.863 294.31C347.183 294.695 346.749 295.184 346.567 295.768C346.386 296.352 346.454 296.937 346.77 297.526L346.764 297.518Z"
              fill="white"
            />
            <path
              d="M353.467 288.834C354.399 288.366 355.33 287.908 356.267 287.459C356.452 287.898 356.634 288.334 356.819 288.773C356.833 288.766 356.842 288.76 356.856 288.753C356.94 288.106 357.137 287.557 357.447 287.111C357.756 286.664 358.23 286.293 358.859 286.004C359.024 285.928 359.188 285.862 359.356 285.798C359.523 285.734 359.684 285.692 359.837 285.662C360.14 286.442 360.441 287.216 360.745 287.993C360.523 288.024 360.312 288.073 360.107 288.13C359.905 288.189 359.694 288.264 359.485 288.362C358.95 288.607 358.561 288.87 358.307 289.151C358.054 289.431 357.897 289.732 357.831 290.049C357.768 290.368 357.783 290.707 357.879 291.067C357.975 291.427 358.106 291.808 358.281 292.209C358.767 293.343 359.261 294.472 359.755 295.6C358.871 296.023 357.989 296.457 357.114 296.9C355.872 294.223 354.654 291.532 353.461 288.825L353.467 288.834Z"
              fill="white"
            />
            <path
              d="M361.468 285.087C362.379 284.691 363.299 284.305 364.215 283.927C364.348 284.304 364.483 284.687 364.616 285.064C364.628 285.06 364.643 285.053 364.651 285.048C364.683 284.852 364.752 284.636 364.861 284.407C364.97 284.178 365.116 283.955 365.303 283.735C365.49 283.515 365.716 283.307 365.984 283.109C366.252 282.91 366.558 282.745 366.908 282.61C367.563 282.356 368.185 282.256 368.775 282.31C369.364 282.363 369.863 282.604 370.283 283.034C370.447 282.366 370.73 281.831 371.142 281.424C371.551 281.02 372.106 280.697 372.81 280.457C373.449 280.24 374.013 280.159 374.502 280.205C374.992 280.258 375.409 280.409 375.751 280.662C376.096 280.917 376.383 281.254 376.612 281.671C376.845 282.091 377.028 282.56 377.17 283.073C377.629 284.757 378.101 286.438 378.581 288.113C377.657 288.407 376.735 288.708 375.816 289.021C375.311 287.378 374.815 285.73 374.327 284.077C374.211 283.679 374.017 283.371 373.748 283.145C373.478 282.923 373.105 282.892 372.633 283.055C372.298 283.172 372.04 283.319 371.846 283.498C371.652 283.677 371.519 283.876 371.439 284.101C371.361 284.323 371.33 284.561 371.343 284.815C371.362 285.067 371.408 285.324 371.49 285.576C371.962 287.069 372.446 288.553 372.936 290.034C372.021 290.366 371.11 290.71 370.198 291.064C369.681 289.592 369.169 288.113 368.667 286.634C368.615 286.483 368.549 286.303 368.461 286.089C368.373 285.875 368.261 285.684 368.12 285.516C367.978 285.349 367.799 285.228 367.58 285.157C367.361 285.086 367.089 285.115 366.761 285.246C366.394 285.392 366.121 285.572 365.935 285.782C365.753 285.994 365.634 286.223 365.586 286.472C365.539 286.722 365.543 286.981 365.595 287.247C365.647 287.513 365.727 287.785 365.827 288.059C366.327 289.442 366.84 290.823 367.355 292.199C366.454 292.57 365.552 292.951 364.654 293.344C363.564 290.603 362.502 287.842 361.461 285.073L361.468 285.087Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_b_134_72"
          x={205.129}
          y={205.137}
          width={271.517}
          height={367.991}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={20} />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_134_72" result="shape" />
        </filter>
        <filter
          id="filter1_b_134_72"
          x={243.09}
          y={243.096}
          width={233.577}
          height={311.051}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={20} />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_134_72" result="shape" />
        </filter>
        <filter
          id="filter2_b_134_72"
          x={281.214}
          y={281.217}
          width={195.533}
          height={253.868}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={20} />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_134_72" result="shape" />
        </filter>
        <filter
          id="filter3_b_134_72"
          x={313.221}
          y={313.223}
          width={163.527}
          height={205.859}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={20} />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_134_72" result="shape" />
        </filter>
        <filter
          id="filter4_dd_134_72"
          x={105}
          y={109.008}
          width={342.615}
          height={503.992}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={6} />
          <feColorMatrix type="matrix" values="0 0 0 0 0.352941 0 0 0 0 0.282353 0 0 0 0 0.14902 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix type="matrix" values="0 0 0 0 0.352941 0 0 0 0 0.282353 0 0 0 0 0.14902 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_134_72" result="effect2_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_134_72" result="shape" />
        </filter>
        <filter
          id="filter5_d_134_72"
          x={178.122}
          y={364.703}
          width={34.3564}
          height={33.7424}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_72" result="shape" />
        </filter>
        <filter
          id="filter6_d_134_72"
          x={184.555}
          y={343.093}
          width={35.8662}
          height={32.2047}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_72" result="shape" />
        </filter>
        <filter
          id="filter7_d_134_72"
          x={191.608}
          y={329.24}
          width={35.7998}
          height={27.2564}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_72" result="shape" />
        </filter>
        <filter
          id="filter8_d_134_72"
          x={199.22}
          y={312.896}
          width={33.0811}
          height={27.161}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_72" result="shape" />
        </filter>
        <filter
          id="filter9_d_134_72"
          x={204.498}
          y={291.036}
          width={35.9824}
          height={33.7021}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_72" result="shape" />
        </filter>
        <filter
          id="filter10_d_134_72"
          x={217.528}
          y={274.624}
          width={36.7188}
          height={35.0382}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_72" result="shape" />
        </filter>
        <filter
          id="filter11_d_134_72"
          x={229.871}
          y={258.684}
          width={37.9502}
          height={34.731}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_72" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_72" result="shape" />
        </filter>
        <clipPath id="clip0_134_72">
          <rect width={57.7398} height={15.5839} fill="white" transform="translate(361.656 424.095) rotate(-58.7799)" />
        </clipPath>
        <clipPath id="clip1_134_72">
          <rect width={73.0776} height={18.2694} fill="white" transform="translate(327.205 417.619) rotate(-59.209)" />
        </clipPath>
        <clipPath id="clip2_134_72">
          <rect width={160.962} height={37.7217} fill="white" transform="translate(275.236 432.469) rotate(-58.6414)" />
        </clipPath>
        <clipPath id="clip3_134_72">
          <rect width={217.053} height={51.2358} fill="white" transform="translate(226.938 444.949) rotate(-59.5436)" />
        </clipPath>
      </defs>
    </svg>
  )
}
