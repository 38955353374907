import { useMemo } from 'react'

import { DescriptionCard } from 'pages/phaseBuilder/templates/simpleCard/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/phaseBuilder/templates/simpleCard/processCard/ProcessCard'
import styles from 'pages/phaseBuilder/templates/simpleCard/SimpleCard.module.scss'
import { PhaseFormConfig } from 'types/phase/phase'
import { getPreviewImageUrl } from 'utils/builder'

export const SimpleCard = ({ config }: { config: PhaseFormConfig }) => {
  const columnWidth = useMemo(() => `calc(${100 / config.card.columns.length}% - 25px)`, [config.card.columns.length])

  return (
    <ProcessCard config={config}>
      {config.card.contentImage[0] ? (
        <img src={getPreviewImageUrl(config.card.contentImage)} alt="" className={styles.contentImage} />
      ) : (
        config.card.columns.map((column, index) => (
          <DescriptionCard
            key={index}
            config={column}
            iconBgColor={config?.card?.colors[1]}
            cardColor={config?.card?.colors[2]}
            width={columnWidth}
          />
        ))
      )}
    </ProcessCard>
  )
}
