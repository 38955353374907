// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MJLQo{position:relative;height:calc(100vh - var(--wpp-os-header-height));background:radial-gradient(circle, #0155e5 0, #012159 100%)}.On__Z{width:100%;height:100%}.On__Z *{transition:opacity .3s ease}.ysweN{cursor:pointer}.NIMhY{transform-box:fill-box;transform-origin:center}.cmiZQ,.iOYnv{transform-origin:616.073px 355.9px;animation:PmJWN 10s linear forwards infinite}.iOYnv{animation-direction:reverse}@keyframes PmJWN{from{transform:rotate(0)}to{transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/unilever/UnileverProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,gDAAA,CACA,2DAAA,CAGF,OACE,UAAA,CACA,WAAA,CAEA,SACE,2BAAA,CAIJ,OACE,cAAA,CAGF,OACE,sBAAA,CACA,uBAAA,CAGF,cACE,kCAAA,CACA,4CAAA,CAGF,OAGE,2BAAA,CAGF,iBACE,KACE,mBAAA,CAGF,GACE,wBAAA,CAAA","sourcesContent":[".root {\n  position: relative;\n  height: calc(100vh - var(--wpp-os-header-height));\n  background: radial-gradient(circle, #0155e5 0, #012159 100%);\n}\n\n.svg {\n  width: 100%;\n  height: 100%;\n\n  * {\n    transition: opacity 0.3s ease;\n  }\n}\n\n.svgPoint {\n  cursor: pointer;\n}\n\n.animationGroup {\n  transform-box: fill-box;\n  transform-origin: center;\n}\n\n.shape {\n  transform-origin: 616.073px 355.9px;\n  animation: rotate 10s linear forwards infinite;\n}\n\n.shapeLeft {\n  @extend .shape;\n\n  animation-direction: reverse;\n}\n\n@keyframes rotate {\n  from {\n    transform: rotate(0);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `MJLQo`,
	"svg": `On__Z`,
	"svgPoint": `ysweN`,
	"animationGroup": `NIMhY`,
	"shape": `cmiZQ`,
	"shapeLeft": `iOYnv`,
	"rotate": `PmJWN`
};
export default ___CSS_LOADER_EXPORT___;
