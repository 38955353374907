import { MouseEventHandler } from 'react'

import styles from 'pages/customProcess/components/henkel/HenkelProcess.module.scss'
import { Phases } from 'pages/customProcess/components/henkel/types'

interface Props {
  onPhaseEnter: (phase: Phases) => MouseEventHandler
  onPhaseLeave: () => void
}

export const DeliverPhaseSvg = ({ onPhaseEnter, onPhaseLeave }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={929} height={929} fill="none">
    <g onMouseEnter={onPhaseEnter(Phases.Deliver)} onMouseLeave={onPhaseLeave} className={styles.phaseSvg}>
      <g filter="url(#a)">
        <path
          fill="#9AACBD"
          fillOpacity={0.8}
          d="M170 634.449a340.004 340.004 0 0 1 124.402-464.422 339.99 339.99 0 0 1 169.975-45.578l.057 272a68 68 0 0 0-58.875 102L170 634.449Z"
        />
      </g>
      <path
        fill="#fff"
        d="M222.113 404.192c1.455.311 2.656.853 3.603 1.628a7.733 7.733 0 0 1 2.15 2.692c.486 1.02.774 2.12.866 3.302a12.678 12.678 0 0 1-.252 3.48l-1.323 6.196-16.617-3.546 1.282-6.009c.25-1.174.638-2.302 1.164-3.384.51-1.085 1.18-2.005 2.012-2.76.817-.759 1.815-1.298 2.996-1.619 1.181-.32 2.554-.314 4.119.02Zm-.892 4.178c-.939-.2-1.747-.209-2.424-.026-.689.163-1.269.465-1.739.905-.482.42-.872.942-1.169 1.566a8.606 8.606 0 0 0-.66 1.945l-.426 1.995 9.717 2.074.406-1.901c.154-.72.222-1.425.206-2.116a5.046 5.046 0 0 0-.446-1.935 3.954 3.954 0 0 0-1.241-1.542c-.56-.446-1.301-.768-2.224-.965ZM232.582 398.191l-16.259-4.938 3.32-10.931 3.33 1.011-2.169 7.142 3.054.928 2.051-6.752 3.146.956-2.051 6.751 3.376 1.025 2.295-7.555 3.352 1.019-3.445 11.344ZM238.702 379.221l-15.854-6.112 1.485-3.852 12.518 4.826 2.365-6.136 3.337 1.287-3.851 9.987ZM245.46 362.598l-15.462-7.046 1.712-3.756 15.462 7.046-1.712 3.756ZM254.95 343.464l-1.95 3.611-18.008-2.409 2.189-4.056 12.472 2.259.045-.085-8.744-9.164 2.154-3.992 11.842 13.836ZM261.696 331.765l-14.253-9.25 6.219-9.583 2.919 1.894-4.063 6.262 2.677 1.737 3.841-5.919 2.758 1.79-3.841 5.919 2.96 1.921 4.298-6.624 2.939 1.908-6.454 9.945ZM278.343 308.003l-7.586-1.192-.848 1.104 5.351 4.105-2.455 3.199-13.48-10.344 3.944-5.141a10.628 10.628 0 0 1 1.656-1.724 5.896 5.896 0 0 1 1.938-1.145 4.404 4.404 0 0 1 2.162-.216c.745.107 1.504.458 2.278 1.052.914.701 1.492 1.538 1.735 2.51.242.973.16 1.999-.246 3.079l8.473.905-2.922 3.808Zm-9.452-7.04c-.317-.244-.624-.369-.922-.375-.3-.029-.584.025-.851.163-.27.115-.527.292-.769.529a6.34 6.34 0 0 0-.627.699l-1.33 1.733 3.142 2.41 1.183-1.542c.205-.267.395-.554.571-.863.164-.318.276-.635.337-.951a1.742 1.742 0 0 0-.071-.932c-.099-.318-.32-.608-.663-.871Z"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={399.986}
        height={570}
        x={94.448}
        y={94.449}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={15} />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_124_65" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_124_65" result="shape" />
      </filter>
    </defs>
  </svg>
)
