import { WppButton } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular4/Circular4Process.module.scss'
import { Circular4Svg } from 'pages/processBuilder/templates/circular4/Circular4Svg'
import { ProcessFormConfig } from 'types/process/process'

export const Circular4Process = ({ config }: { config: ProcessFormConfig }) => {
  const [activePhase, setActivePhase] = useState<MayBeNull<number>>(null)

  const handlePhaseEnter = useCallback(
    (phase: number) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <div className={styles.root}>
      <Link
        to={config?.button?.url || ''}
        style={{ visibility: config?.button?.isVisible ? 'visible' : 'hidden' }}
        className={styles.projectLink}
      >
        <WppButton
          className={styles.projectButton}
          style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
        >
          {config?.button?.title}
        </WppButton>
      </Link>
      <Flex align="center" justify="center" className={styles.svgWrapper}>
        <Circular4Svg
          onPhaseEnter={handlePhaseEnter}
          onPhaseLeave={handlePhaseLeave}
          activePhase={activePhase}
          config={config}
        />
      </Flex>
    </div>
  )
}
