import { Flex } from 'components/common/flex/Flex'
import { InputsOutputsCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestle/processPhases/pages/mediaPlan/MediaPlan.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestle/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const MediaPlan = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/strategic-media-planning' : '/custom/strategic-media-planning'
  const nextLink = isDemoTenant ? '/nestle/process/media-activation' : '/custom/media-activation'

  return (
    <ProcessCard
      title="Media Plan"
      bepTitle="BEP 4"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Foundation for the Media Plan" cardStyles={styles.inputsCard} />
        <Flex direction="column" align="center" gap={32}>
          <RegularCard
            text="Create Strategic Media Plan (annual investment laydown)"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Redmill®', style: styles.redmillTagColor }]}
            bottomHighlightTextSpecial="Strategic Media Plan"
          />
          <RegularCard
            text="Create Detailed Media Channel Plans aligning to earlier strategic planning phases"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Media Activity Plan®', style: styles.redmillTagColor }]}
            bottomHighlightText="Detailed Channel Plan"
          />
        </Flex>
        <div className={styles.regularCard1Wrapper}>
          <RegularCard
            text="Devise the budget and Channel allocation"
            teamTags={[
              { text: 'Nestle Teams', tags: ['R', 'A'] },
              { text: 'Media Teams', tags: ['C'] },
            ]}
          />
        </div>
        <div className={styles.regularCard2Wrapper}>
          <RegularCard
            text="Approve Strategic Media Plan and Media Channel Plans for activation"
            teamTags={[
              { text: 'Nestle Teams', tags: ['R', 'A'] },
              { text: 'Media Teams', tags: ['I'] },
            ]}
          />
        </div>
        <InputsOutputsCard
          title="outputs"
          text="Media Plan sign-off Activation Briefing"
          cardStyles={styles.inputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default MediaPlan
