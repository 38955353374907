import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (5).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Experience = () => {
  return (
    <ProcessCard
      type="planning"
      title="Experience & Innovation"
      description="Balancing what we know from the past with what new things we want to do in the future"
      previousLink="/custom/fossil/reach"
      nextLink="/custom/fossil/outcome"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Experience
