import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ibm/processPhases/pages/adapt/Adapt.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ibm/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/ibm/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'adapt'

const Adapt = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Adapt"
      description="Measuring outcomes and optimizing in real time, while also generating insights to inform the next marketing activity and future business decisions"
      previousLink="/custom/ibm/deliver"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Live campaign feeds</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Attribution Partners</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Publisher Data Integration</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">IBM Data Feedback</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Data & Analytics',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Implementation Teams (regional)',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Investment Teams',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Strategy Teams',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Complaint Delivery</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Experimentation</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Actualizations</WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Nexus Growth Platform</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">CoPilot Optimizer</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Earned Media Analyzer</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Clean Room Modules</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Optimization agenda</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Learning Agenda</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Measured Outcomes</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Reporting Data Flow</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Adapt
