export const WBAProcessBg = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="1224"
    height="664"
    viewBox="0 0 1224 664"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1840 -1051L1840 -342.059L612 367L-616 -342.059L-616 -1051L1840 -1051Z"
      fill="var(--bg-top)"
      stroke="white"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M612 366.745L612 1784L-616 1784L-616 -342L612 366.745Z"
      fill="var(--bg-left)"
      stroke="white"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
    <path
      d="M1840 -342L1840 1784L612 1784L612 366.745L1840 -342Z"
      fill="var(--bg-right)"
      stroke="white"
      strokeWidth="6"
      strokeMiterlimit="10"
    />
  </svg>
)
