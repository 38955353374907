import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Tooltip } from 'pages/customProcess/components/ibmNew/assets/schemeTooltips/executional-details-tooltip.svg'
import { InputOutputInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/inputOutputInfo/InputOutputInfo'
import { ResponsibilitiesInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/responsibilitiesInfo/ResponsibilitiesInfo'
import { PageLayout } from 'pages/customProcess/components/ibmNew/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/ibmNew/processPhases/pages/executionalDetails/ExecutionalDetails.module.scss'
import { Scheme } from 'pages/customProcess/components/ibmNew/processPhases/pages/executionalDetails/scheme/Scheme'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

const ExecutionalDetails = () => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleTooltipTriggerEnter = useCallback(
    () => () => {
      setShowTooltip(true)
    },
    [],
  )

  const handleTooltipTriggerLeave = useCallback(() => {
    setShowTooltip(false)
  }, [])

  return (
    <PageLayout
      type={Phases.Execution}
      title="03. Executional Details"
      description="Ensure we have all the correct elements and have completed all necessary administration to enable the paid media activity to be executed. All Teams must ensure budgets are completely aligned with POs. Central teams shift all the plans into the relevant tools for visualisation and execution, Performance Optimisations ready to push go."
      prevLink="/custom/ibm/channel-activity"
      nextLink="/custom/ibm/performance"
    >
      <Scheme onTooltipTriggerEnter={handleTooltipTriggerEnter} onTooltipTriggerLeave={handleTooltipTriggerLeave} />
      <Tooltip className={clsx(styles.tooltip, showTooltip && styles.tooltipVisible)} />
      <Flex gap={16} className={styles.infoWrapper}>
        <ResponsibilitiesInfo />
        <InputOutputInfo />
      </Flex>
    </PageLayout>
  )
}

export default ExecutionalDetails
