import { AutocompleteOption } from '@platform-ui-kit/components-library'
import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { forwardRef, useRef, Ref } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Autocomplete, AutocompleteProps } from 'components/common/autocomplete/Autocomplete'
import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'
import { useProvideFieldFocus } from 'hooks/form/useProvideFieldFocus'
import { uniqBy } from 'utils/common'

export type FormAutocompleteProps<T extends AutocompleteOption> = Omit<
  AutocompleteProps<T>,
  'name' | 'value' | 'onChange' | 'onBlur'
> & {
  name: string
}

export const FormAutocomplete = forwardRef(function FormAutocomplete<T extends AutocompleteOption>(
  {
    id,
    name,
    message,
    messageType,
    multiple = false,
    onWppChange,
    onWppBlur,
    ...rest
  }: Omit<FormAutocompleteProps<T>, 'ref'>,
  ref: Ref<HTMLWppAutocompleteElement>,
) {
  const innerRef = useRef<HTMLWppAutocompleteElement>(null)

  const {
    field: { ref: fieldRef, value, onChange, onBlur },
    fieldState: { isTouched, error },
  } = useField({
    name,
  })

  useProvideFieldFocus({
    fieldRef,
    setFocus: () => innerRef.current?.setFocus(),
  })

  const identifier = id || name
  const errorText = error?.message
  const shouldShowError = isTouched && !!errorText

  return (
    <Autocomplete
      ref={mergeRefs([innerRef, ref])}
      {...rest}
      id={identifier}
      name={name}
      value={value}
      multiple={multiple}
      message={shouldShowError ? errorText : message}
      messageType={shouldShowError ? 'error' : messageType}
      onWppChange={e => {
        onChange(
          uniqBy(e.detail.value, (value: { email?: string; id: string }) => (value?.email ? value.email : value.id)),
        )
        onWppChange?.(e)
      }}
      onWppBlur={e => {
        onBlur()
        onWppBlur?.(e)
      }}
    />
  )
}) as <T extends AutocompleteOption>(props: FormAutocompleteProps<T>) => JSX.Element

export const FormAutocompleteSkeleton = () => (
  <Flex direction="column" gap={8}>
    <WppSkeleton width="30%" height={22} />
    <WppSkeleton width="100%" height={40} />
  </Flex>
)
