import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/bp/processPhases/contentCards/inputsOutputsCard/InputsOutputsCard.module.scss'

interface Props {
  title: string
  text: string
}

export const InputsOutputsCard = ({ title, text }: Props) => (
  <div className={styles.card}>
    <WppTypography className={styles.title}>{title}</WppTypography>
    <WppTypography type="m-midi" className={styles.text}>
      {text}
    </WppTypography>
  </div>
)
