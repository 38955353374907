import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ferreroNew/processPhases/pages/audience/Audience.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/ProcessCard'

const Audience = () => {
  return (
    <ProcessCard
      title="Audience"
      description={
        'Define, identify and dimensionalize current and\npotential audiences.\nCurrent Audience. Potential Audience.'
      }
      previousLink="/custom/ferrero/challenge"
      nextLink="/custom/ferrero/insight"
    >
      <DescriptionCard icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Current buying target,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Current design target,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Potential audience Sizing,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Aspirations/objectives
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Activitites
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Audience definition,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Audience sizing,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Persona development
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="tools">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                GWI,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                ATYM
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="people">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Teams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Led by strategy. Primary\n= Planning + Data\nStrategy.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Engagement Strategy\nsupporting.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Acct and creative\ninvolvement/review'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Client approved: Target\ndefinitions; current and\nfuture.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Target personas; current\nand future.'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Audience
