export enum Phases {
  Brief = 'Brief',
  Govern = 'Govern',
  Assemble = 'Assemble',
  Ideate = 'Ideate',
  Create = 'Create',
  Produce = 'Produce',
  Review = 'Review',
  Deploy = 'Deploy',
  Trace = 'Trace',
}
