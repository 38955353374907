import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (1).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Define = () => {
  return (
    <ProcessCard
      type="strategy"
      title="Define Success"
      description="To drive business growth, we must be clear on how we deliver meaningful results."
      previousLink="/custom/fossil/uncover"
      nextLink="/custom/fossil/audience"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Define
