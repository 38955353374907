import { phasesData } from 'pages/customProcess/components/mediaMarktSaturn/MediaMarktSaturnConfig'
import { PageLayout } from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pageLayout/PageLayout'
import { Phases } from 'pages/customProcess/components/mediaMarktSaturn/types'

const AssetsAndIdeas = () => {
  const { title, description, schemeSrc, previousLink, nextLink } = phasesData[Phases.AssetsAndIdeas]
  return (
    <PageLayout
      title={title}
      description={description}
      schemeSrc={schemeSrc}
      previousLink={previousLink}
      nextLink={nextLink}
    />
  )
}

export default AssetsAndIdeas
