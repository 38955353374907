import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ibm/processPhases/pages/discover/Discover.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ibm/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/ibm/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'discover'

const Discover = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Discover"
      description="Activating and modeling proprietary audience data to identify high quality markets, audiences, and
  local insights to achieve client objectives"
      nextLink="/custom/ibm/decision"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Client Briefing</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Client Audience Data</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Client Creative</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Brand Strategy',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Data & Analytics',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Consumer Sciences',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Client teams',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: true },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Source of Growth</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Audience Segmentation</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Comms Strategy</WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">Choreograph</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Synapse</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionProcessTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.processList}>
            <li>
              <WppTypography type="s-body">High Value Markets</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">High Value Audiences</WppTypography>
            </li>
            <li>
              <WppTypography type="s-body">Hero Insights & Trends</WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Discover
