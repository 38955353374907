import { Phases, PhaseDetails } from 'pages/customProcess/components/fossilNew/types'

export const links = {
  [PhaseDetails.UncoverChallenge]: '/custom/fossil/uncover',
  [PhaseDetails.DefineSuccess]: '/custom/fossil/define',
  [PhaseDetails.AudiencePlanning]: '/custom/fossil/audience',
  [PhaseDetails.ChannelsAndSignals]: '/custom/fossil/channels',
  [PhaseDetails.ReachAndEngagement]: '/custom/fossil/reach',
  [PhaseDetails.ExperienceAndInnovation]: '/custom/fossil/experience',
  [PhaseDetails.OutcomeMonitoring]: '/custom/fossil/outcome',
  [PhaseDetails.AutomationOpportunity]: '/custom/fossil/automation',
  [PhaseDetails.AttributionMeasurement]: '/custom/fossil/attribution',
}

export const phaseDescriptions = {
  [Phases.Strategy]: {
    title: 'STRATEGY',
    description:
      'What is the challenge, and how do we solve it? This is the question the Prep phase will enable us to answer. Before diving into the planning process, we think about the brand, the brief and the context of the business at this moment in time.',
  },
  [Phases.Planning]: {
    title: 'PLANNING',
    description:
      'The planning phase allows us to build a plan that will solve the challenge, delivering on the strategy identified in the Strategy phase. The steps in this phase are about weighing up two things at a time and making decisions that reflect industry knowledge and experience.',
  },
  [Phases.Activation]: {
    title: 'ACTIVATION',
    description:
      'In this phase it is the time to bring our media plans to life and start flexing to reflect consumer flow and optimise metrics and outcomes. Live outcome monitoring helps us capitalise on automation opportunities and ensure correct attribution across channels.',
  },
}
