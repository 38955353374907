import { Flex } from 'components/common/flex/Flex'
import audienceOriginImgSrc from 'pages/customProcess/components/henkel/assets/audience-origin-logo.png'
import { PageLayout } from 'pages/customProcess/components/henkel/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/henkel/processPhases/pages/approach/Approach.module.scss'

const Approach = () => {
  return (
    <PageLayout
      title="Approach"
      description={'Create integrated strategy with clear direction,\nJTBD and sacrifice.'}
      previousLink="/custom/henkel/audience"
      nextLink="/custom/henkel/assets"
      inputsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Measurement framework</li>
            <li>Audience strategy</li>
            <li>Source of Growth etc.</li>
          </ul>
        </div>
      }
      activitiesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Create media strategy</li>
          </ul>
        </div>
      }
      toolsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Multiple Videoplanner</li>
          </ul>
          <Flex align="center" gap={4} className={styles.item}>
            <img src={audienceOriginImgSrc} alt="Architect logo" className={styles.logo} />
            <span className={styles.itemText}>Architect</span>
          </Flex>
          <ul className={styles.listHenkel}>
            <li>Touchpoint Decoder</li>
          </ul>
        </div>
      }
      outcomesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Strategic direction and omni\nchannel comms architecture'}</li>
          </ul>
        </div>
      }
    />
  )
}

export default Approach
