import { discoverContent } from 'pages/customProcess/components/asml/ASMLProcessConfig'
import { PageLayout } from 'pages/customProcess/components/asml/processPhases/pageLayout/PageLayout'

export const Discover = () => {
  const { type, title, description, activities, deliverables, previousLink, nextLink } = discoverContent
  return (
    <PageLayout
      type={type}
      title={title}
      description={description}
      activities={activities}
      deliverables={deliverables}
      previousLink={previousLink}
      nextLink={nextLink}
    />
  )
}
