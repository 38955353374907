import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/loreal/processPhases/pageLayout/PageLayout.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  title: string
  steps: string[]
  children: React.ReactNode
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({ title, steps, previousLink, nextLink, children }: Props) => {
  const headerBgStyle = useMemo(() => {
    switch (title) {
      case 'Media Brief':
        return styles.mediaBriefHeaderBg
      case 'Strategic Planning':
        return styles.strategicPlanningHeaderBg
      case 'Media Planning\n& Buying\nRequirements':
        return styles.mediaPlanningHeaderBg
      case 'Campaign\nImplementation':
        return styles.campaignImplementationHeaderBg
      case 'Campaign\nTracking &\nReporting':
        return styles.campaignTrackingHeaderBg
      default:
        return styles.mediaBriefHeaderBg
    }
  }, [title])

  return (
    <div className={styles.root}>
      <Flex direction="column" justify="between" className={styles.page}>
        <div className={styles.fullWidth}>
          <div className={clsx(styles.header, headerBgStyle)}>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn}>
                <Flex gap={4} align="center">
                  <WppIconChevron
                    direction="left"
                    color={title === 'Media Brief' ? '#454545' : 'var(--wpp-grey-color-000)'}
                  />
                  <WppTypography
                    type="s-body"
                    className={clsx(
                      title === 'Media Brief' ? styles.backToProcessBtnTextDark : styles.backToProcessBtnText,
                    )}
                  >
                    Back to Process overview
                  </WppTypography>
                </Flex>
              </WppActionButton>
              <div>
                <WppTypography
                  type="5xl-display"
                  tag="h1"
                  className={clsx(title !== 'Media Brief' && styles.whiteText)}
                >
                  {title}
                </WppTypography>
              </div>
              <ul className={clsx(styles.stepsList, title !== 'Media Brief' && styles.stepsListWhite)}>
                {steps.map((step, index) => (
                  <li key={index}>
                    <WppTypography
                      type="m-body"
                      tag="p"
                      className={clsx(styles.uppercase, title !== 'Media Brief' && styles.whiteText)}
                    >
                      {step}
                    </WppTypography>
                  </li>
                ))}
              </ul>
            </Link>
          </div>
          <div className={styles.contentCard}>{children}</div>
        </div>
        <Flex align="center" justify="between">
          <Flex gap={16}>
            <Link to={previousLink ?? ''}>
              <WppActionButton disabled={!previousLink} variant="secondary">
                Previous phase <WppIconChevron slot="icon-start" direction="left" />
              </WppActionButton>
            </Link>
            <Link to={nextLink ?? ''}>
              <WppActionButton disabled={!nextLink} variant="secondary">
                Next phase <WppIconChevron slot="icon-end" />
              </WppActionButton>
            </Link>
          </Flex>
          <Link to={routesManager.systemUrls.orchestration.root.url}>
            <WppButton className={styles.goToProjectBtn}>Go to project details</WppButton>
          </Link>
        </Flex>
      </Flex>
    </div>
  )
}
