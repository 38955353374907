import strategizeSchemeImgSrc from 'pages/customProcess/components/audible/assets/processPhasesSchemes/strategize.png'
import { strategizeContent } from 'pages/customProcess/components/audible/audibleConfig'
import { PageLayout } from 'pages/customProcess/components/audible/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/audible/processPhases/pages/strategize/Strategize.module.scss'

const Strategize = () => {
  const { type, title, description, previousLink, nextLink } = strategizeContent

  return (
    <PageLayout type={type} title={title} description={description} previousLink={previousLink} nextLink={nextLink}>
      <img src={strategizeSchemeImgSrc} alt="" className={styles.schemeImg} />
    </PageLayout>
  )
}

export default Strategize
