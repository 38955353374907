import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ferreroNew/processPhases/pages/challenge/Challenge.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/ProcessCard'

const Challenge = () => {
  return (
    <ProcessCard
      title="Challenge"
      description={
        'Identify, validate and understand the core business challenge that must be solved.\nCategory + Competitive Landscape.\nClient brand and business Landscape.'
      }
      nextLink="/custom/ferrero/audience"
    >
      <DescriptionCard icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Category dynamics and\nmessaging,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Competitive and brand\nactivity and history,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Individual sales and market\ngrowth trends,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Internal and external forces\nand drivers impacting brand health,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Brand frameworks, and\nbrand health documents,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Current HHP dynamics
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Activitites
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Core business challenge\nidentification,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Opportunity exploration and\nvalidation'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="tools">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                BAV,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Quid,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Brandwatch,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Relative Insights,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                GWI
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                AYTM
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="people">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Teams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Led by strategy. Primary\n= Planning + Data\nStrategy.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Engagement Strategy\nsupporting.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Acct and creative\ninvolvement/review'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Client approved: business\nchallenge and KPIs'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Challenge
