import { Phases } from 'pages/customProcess/components/yumChina/types'

export const links = {
  [Phases.Ambition]: '/phase/f359a629-98e3-4efb-973e-8a7cd03f8769?back-to-custom',
  [Phases.Audience]: '/phase/e0187fb1-bf5d-4d01-8501-70ed889b9d26?back-to-custom',
  [Phases.Approach]: '/phase/2ba25923-52ae-47ab-9e96-ce30a8a7ac47?back-to-custom',
  [Phases.AssetsAndIdeas]: '/phase/f6fbe983-72a3-45b7-b3e6-dc739018cbf1?back-to-custom',
  [Phases.Activation]: '/phase/cea28381-89fc-46ba-a8dd-e1b0c7c3570a?back-to-custom',
  [Phases.Adapt]: '/phase/966602ba-6c9e-45b8-b89f-980baa3295d9?back-to-custom',
}
