// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KOnvx{max-width:var(--wpp-os-content-max-width);height:calc(100vh - var(--wpp-os-header-height));margin:0 auto;padding:32px 16px}.MJopM{position:relative;height:100%}._tHOY{width:40%;margin-top:-40px}.DuMIC{opacity:0;transition:opacity .25s ease}.VcsYc{opacity:1}.S1Kwj{overflow:visible}.QhStw{transform-origin:50% 50%;transition:transform .3s ease;pointer-events:none}.yeJIC{transform:scale(0.395)}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/loccitane/LoccitaneProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,yCAAA,CACA,gDAAA,CACA,aAAA,CACA,iBAAA,CAGF,OACE,iBAAA,CACA,WAAA,CAGF,OACE,SAAA,CACA,gBAAA,CAGF,OACE,SAAA,CACA,4BAAA,CAGF,OACE,SAAA,CAGF,OACE,gBAAA,CAGF,OACE,wBAAA,CACA,6BAAA,CACA,mBAAA,CAGF,OACE,sBAAA","sourcesContent":[".container {\n  max-width: var(--wpp-os-content-max-width);\n  height: calc(100vh - var(--wpp-os-header-height));\n  margin: 0 auto;\n  padding: 32px 16px;\n}\n\n.phases {\n  position: relative;\n  height: 100%;\n}\n\n.svgWrapper {\n  width: 40%;\n  margin-top: -40px;\n}\n\n.phaseDetailsHidden {\n  opacity: 0;\n  transition: opacity 0.25s ease;\n}\n\n.visibleOpacity {\n  opacity: 1;\n}\n\n.svg {\n  overflow: visible;\n}\n\n.centerImg {\n  transform-origin: 50% 50%;\n  transition: transform 0.3s ease;\n  pointer-events: none;\n}\n\n.centerImgSmall {\n  transform: scale(0.395);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `KOnvx`,
	"phases": `MJopM`,
	"svgWrapper": `_tHOY`,
	"phaseDetailsHidden": `DuMIC`,
	"visibleOpacity": `VcsYc`,
	"svg": `S1Kwj`,
	"centerImg": `QhStw`,
	"centerImgSmall": `yeJIC`
};
export default ___CSS_LOADER_EXPORT___;
