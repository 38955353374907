import { WppButton, WppGrid } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { PointPopup } from 'pages/customProcess/components/vodafone/PointPopup'
import { pointPopupsConfig } from 'pages/customProcess/components/vodafone/pointPopupsConfig'
import { Phases } from 'pages/customProcess/components/vodafone/types'
import styles from 'pages/customProcess/components/vodafone/VodafoneProcess.module.scss'
import { VodafoneSvg } from 'pages/customProcess/components/vodafone/VodafoneSvg'
import { routesManager } from 'utils/routesManager'

export const VodafoneProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const [activePoint, setActivePoint] = useState<MayBeNull<Phases>>(null)
  const pointRefs = useRef<Record<Phases, any>>({
    [Phases.Discover]: null,
    [Phases.Define]: null,
    [Phases.Design]: null,
    [Phases.Develop]: null,
    [Phases.Deliver]: null,
    [Phases.Distil]: null,
  })
  const popupRootRef = useRef(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      !activePoint && setActivePhase(phase)
    },
    [activePoint],
  )

  const handlePhaseLeave = useCallback(() => {
    !activePoint && setActivePhase(null)
  }, [activePoint])

  const handlePointClick = useCallback(
    (point: Phases) => () => {
      setActivePoint(point)
      setActivePhase(point)
    },
    [],
  )

  const handlePopupClose = useCallback(() => {
    setActivePoint(null)
    setActivePhase(null)
  }, [])

  return (
    <Flex align="center" justify="center" direction="column" className={styles.root}>
      <WppGrid container>
        <Link
          to={routesManager.systemUrls.orchestration.project.getUrl('32cddbf2-6ec0-4b07-b051-79bff5472628')}
          className={styles.projectLink}
        >
          <WppButton className={styles.projectButton}>Go to the project</WppButton>
        </Link>
      </WppGrid>

      <VodafoneSvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
        activePoint={activePoint}
        pointRefs={pointRefs}
        className={styles.svg}
        blurClassname={styles.blurBg}
        pointClassName={styles.svgPoint}
      />
      {Object.keys(Phases).map((key: string) => (
        <PointPopup
          config={pointPopupsConfig[key as Phases]}
          target={pointRefs.current[key as Phases]}
          key={key}
          onClose={handlePopupClose}
          appendTo={popupRootRef.current}
        />
      ))}
      <div className={styles.popupRoot} ref={popupRootRef} />
    </Flex>
  )
}
