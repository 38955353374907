// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QdCJd{max-width:2000px}.ybBLq{cursor:pointer}.qYAm4{opacity:1;transition:opacity .3s ease}.gujcu{opacity:0}.oHt7K{opacity:0;transition:opacity .3s ease}.eqrWl{opacity:1}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/allianzNew/AllianzSvg.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAGF,OACE,cAAA,CAGF,OACE,SAAA,CACA,2BAAA,CAGF,OACE,SAAA,CAGF,OACE,SAAA,CACA,2BAAA,CAGF,OACE,SAAA","sourcesContent":[".svg {\n  max-width: 2000px;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.centerInactiveVisible {\n  opacity: 1;\n  transition: opacity 0.3s ease;\n}\n\n.centerInactiveHidden {\n  opacity: 0;\n}\n\n.centerActiveHidden {\n  opacity: 0;\n  transition: opacity 0.3s ease;\n}\n\n.centerActiveVisible {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg": `QdCJd`,
	"pointer": `ybBLq`,
	"centerInactiveVisible": `qYAm4`,
	"centerInactiveHidden": `gujcu`,
	"centerActiveHidden": `oHt7K`,
	"centerActiveVisible": `eqrWl`
};
export default ___CSS_LOADER_EXPORT___;
