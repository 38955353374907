import { FileItemType } from '@platform-ui-kit/components-library'

import { Dimensions } from 'types/common/comon'
import { isFile } from 'utils/files'

export const getPreviewImageUrl = (value: [FileItemType?]) =>
  isFile(value?.[0]) ? URL.createObjectURL(value?.[0]) : value?.[0]?.url

export const normalizeDimensions = ({ width, height }: Dimensions) => ({
  width: width.unit === 'auto' ? width.unit : `${width.value}${width.unit}`,
  height: height.unit === 'auto' ? height.unit : `${height.value}${height.unit}`,
})
