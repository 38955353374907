import learnSchemeImgSrc from 'pages/customProcess/components/audible/assets/processPhasesSchemes/learn.png'
import { learnContent } from 'pages/customProcess/components/audible/audibleConfig'
import { PageLayout } from 'pages/customProcess/components/audible/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/audible/processPhases/pages/learn/Learn.module.scss'

const Learn = () => {
  const { type, title, description, previousLink, nextLink } = learnContent

  return (
    <PageLayout type={type} title={title} description={description} previousLink={previousLink} nextLink={nextLink}>
      <img src={learnSchemeImgSrc} alt="" className={styles.schemeImg} />
    </PageLayout>
  )
}

export default Learn
