export enum Phases {
  Discover = 'Discover',
  Design = 'Design',
  Deliver = 'Deliver',
}

export enum PhaseDetails {
  Adapt = 'Adapt',
  Ambition = 'Ambition',
  Audience = 'Audience',
  Approach = 'Approach',
  Assets = 'Assets',
  Activation = 'Activation',
}
