import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useMemo, useRef, MouseEventHandler, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from 'pages/processBuilder/templates/circular5/phase/Phase.module.scss'
import { PhasePopup } from 'pages/processBuilder/templates/circular5/phasePopup/PhasePopup'
import { getPieItemPath, getCurvedTextPath } from 'pages/processBuilder/utils'
import { FormPhase } from 'types/process/process'

export const PhasePoint = ({
  phase,
  phasesCount,
  index,
  groupRotation,
  groupSize,
  groupIndex,
  isActive,
  activePhase,
  onPhaseEnter,
  onPhaseLeave,
}: {
  phase: FormPhase
  phasesCount: number
  index: number
  groupRotation: number
  groupSize: number
  groupIndex: number
  isActive: boolean
  activePhase: MayBeNull<number>
  onPhaseEnter: MouseEventHandler
  onPhaseLeave: MouseEventHandler
}) => {
  const ref = useRef(null)
  const navigate = useNavigate()
  const url = phase.innerPageEnabled && phase?.id ? `./phase/${phase?.id}` : phase?.url || ''
  const isCustomUrl = phase?.url && !phase.innerPageEnabled
  const pointRotation = useMemo(() => {
    const rotateStep = groupSize / phasesCount

    return phasesCount > 1 ? rotateStep * index - groupSize / 2 + groupSize / phasesCount / 2 : 0
  }, [index, phasesCount, groupSize])

  const pointAngle = groupRotation + pointRotation

  const { popupPlacement, popupPlacementModifier } = useMemo(() => {
    const x = pointAngle >= 0 && pointAngle <= 180 ? 'left' : 'right'
    const y = pointAngle >= 90 && pointAngle <= 270 ? 'bottom' : 'top'

    const popupPlacementModifier =
      (pointAngle >= -30 && pointAngle <= 30) || (pointAngle >= 150 && pointAngle <= 210) || pointAngle > 330
        ? 'long'
        : (pointAngle >= 70 && pointAngle <= 110) || (pointAngle >= 250 && pointAngle <= 290)
        ? 'short'
        : ''

    return { popupPlacement: `${x}-${y}`, popupPlacementModifier }
  }, [pointAngle])

  const isTitleReverted = useMemo(() => pointAngle > 90 && pointAngle < 270, [pointAngle])

  const handleClick = useCallback(() => {
    isCustomUrl ? (window.location.href = url) : navigate(url)
  }, [navigate, isCustomUrl, url])

  const overlayLineHeight = 162 / phase.overlayDescriptions.length
  const phaseWidth = 110

  return (
    <>
      <g transform={`rotate(${pointRotation} 640 455)`} opacity={!Number.isInteger(activePhase) || isActive ? 1 : 0}>
        <circle
          cx="640"
          cy="155"
          r="8"
          fill={phase?.colors?.[0] || '#fff'}
          strokeWidth={5}
          stroke={phase?.colors?.[1] || '#001023'}
          ref={ref}
          onMouseEnter={onPhaseEnter}
          onMouseLeave={onPhaseLeave}
          className={clsx({ [styles.isInteractive]: url })}
          onClick={handleClick}
        />

        <text alignmentBaseline="middle" className={styles.title}>
          <textPath
            xlinkHref={`#phaseTextPath_${phase.localId}_${groupIndex}`}
            startOffset="50%"
            textAnchor="middle"
            alignmentBaseline="central"
            fill={phase?.colors?.[3] || '#000'}
            opacity={isActive ? 0 : 1}
          >
            {phase.title}
          </textPath>
        </text>

        <g opacity={isActive ? 1 : 0}>
          {phase?.overlayDescriptions.length ? (
            phase?.overlayDescriptions?.map((overlay, index) => {
              const overlayPathR = 234 - overlayLineHeight * index - overlayLineHeight / 2

              return (
                <g key={index}>
                  <path
                    d={getPieItemPath({ radius: 234 - index * overlayLineHeight, size: phaseWidth, x: 640, y: 455 })}
                    fill={phase?.colors?.[4] || 'rgba(0,0,255,.2)'}
                    style={{ filter: `saturate(${1 - index * 0.1}) brightness(${1 - index * 0.1})` }}
                  />
                  <path
                    d={getCurvedTextPath({ radius: overlayPathR, x: 640, y: 455, isReversed: isTitleReverted })}
                    id={`curve${index}_${phase.localId}_${groupIndex}`}
                  />

                  <text alignmentBaseline="middle" className={styles.overlayDescription}>
                    <textPath
                      xlinkHref={`#curve${index}_${phase.localId}_${groupIndex}`}
                      fill={phase?.colors?.[5] || '#fff'}
                      startOffset="50%"
                      textAnchor="middle"
                      alignmentBaseline="central"
                    >
                      {overlay.label}
                    </textPath>
                  </text>
                </g>
              )
            })
          ) : (
            <>
              <path
                d={getPieItemPath({ radius: 234, size: phaseWidth, x: 640, y: 455 })}
                fill={phase?.colors?.[4] || 'rgba(0,0,255,.2)'}
              />
            </>
          )}
        </g>
      </g>

      <foreignObject>
        <PhasePopup
          target={ref.current}
          onClose={() => {}}
          phase={phase}
          placement={popupPlacement}
          placementModifier={popupPlacementModifier}
        />
      </foreignObject>

      <defs>
        <path
          d={getCurvedTextPath({ radius: 340, x: 640, y: 455, isReversed: isTitleReverted })}
          stroke="transparent"
          id={`phaseTextPath_${phase.localId}_${groupIndex}`}
        />
      </defs>
    </>
  )
}
