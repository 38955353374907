// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mwxfT{position:relative;z-index:1;width:100%;max-width:398px;height:224px;max-height:224px;overflow:hidden}.mwxfT .JbM_t{position:relative;top:0;z-index:1;width:100%;transition:top .8s ease}.mwxfT .JbM_t.IMwDb{top:-58%}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ikeaNew/components/phaseCard/PhaseCard.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,SAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,gBAAA,CACA,eAAA,CAEA,cACE,iBAAA,CACA,KAAA,CACA,SAAA,CACA,UAAA,CACA,uBAAA,CAEA,oBACE,QAAA","sourcesContent":[".phaseCard {\n  position: relative;\n  z-index: 1;\n  width: 100%;\n  max-width: 398px;\n  height: 224px;\n  max-height: 224px;\n  overflow: hidden;\n\n  .image {\n    position: relative;\n    top: 0;\n    z-index: 1;\n    width: 100%;\n    transition: top 0.8s ease;\n\n    &.show {\n      top: -58%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phaseCard": `mwxfT`,
	"image": `JbM_t`,
	"show": `IMwDb`
};
export default ___CSS_LOADER_EXPORT___;
