import { WppTypography, WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEventHandler, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as FourStroke } from 'pages/customProcess/components/tigerBrands/assets/4.svg'
import { ReactComponent as ArrowListIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/arrow-list.svg'
import { ReactComponent as ChecklistIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/checklist.svg'
import { ReactComponent as LightIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/light.svg'
import { ReactComponent as MapIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/map.svg'
import { ReactComponent as PlantIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/plant.svg'
import { ReactComponent as TargetIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/target.svg'
import styles from 'pages/customProcess/components/tigerBrands/components/phaseCard/PhaseCard.module.scss'

interface Props {
  index: number
  isActive: boolean
  onPhaseEnter: (phaseIndex: number) => MouseEventHandler
  onPhaseLeave: () => void
  link: string
  color: string
  title: string
  description: string
}

export const PhaseCard = ({ index, isActive, onPhaseEnter, onPhaseLeave, link, color, title, description }: Props) => {
  const icon = useMemo(() => {
    switch (index) {
      case 1:
        return <MapIcon width={isActive ? 84 : 49} />
      case 2:
        return <TargetIcon width={isActive ? 90 : 50} />
      case 3:
        return <PlantIcon width={isActive ? 82 : 48} className={styles.plantIcon} />
      case 4:
        return <LightIcon width={isActive ? 122 : 65} />
      case 5:
        return <ArrowListIcon width={isActive ? 84 : 48} />
      case 6:
        return <ChecklistIcon width={isActive ? 74 : 45} />
    }
  }, [index, isActive])

  return (
    <Link
      to={link}
      className={clsx(styles.phase, isActive && styles.phaseActive)}
      style={{ backgroundColor: color }}
      onMouseEnter={onPhaseEnter(index)}
      onMouseLeave={onPhaseLeave}
    >
      {isActive ? (
        <Flex direction="column" justify="between" className={styles.activeContent}>
          <Flex justify="between" align="center">
            <WppTypography className={clsx(styles.phaseIndex, styles.whiteText)}>{index}</WppTypography>
            <div>{icon}</div>
          </Flex>
          <div>
            <Flex align="center" gap={16}>
              <WppTypography type="5xl-display" className={clsx(styles.whiteText, styles.inline)}>
                {title}
              </WppTypography>
              <Flex justify="center" align="center" className={styles.phaseButton}>
                <WppIconArrow direction="right" color={color} />
              </Flex>
            </Flex>
            <WppTypography type="2xl-heading" className={clsx(styles.whiteText, styles.description)}>
              {description}
            </WppTypography>
          </div>
        </Flex>
      ) : (
        <Flex direction="column" justify="between" className={styles.inactiveContent}>
          {index === 4 ? (
            <FourStroke className={styles.fourStroke} />
          ) : (
            <WppTypography className={clsx(styles.phaseIndex, styles.whiteTextStroke)}>{index}</WppTypography>
          )}
          <div>{icon}</div>
          <WppTypography type="2xl-heading" className={styles.inactiveTitle}>
            {title}
          </WppTypography>
          <Flex
            justify="center"
            align="center"
            className={clsx(styles.phaseButton, styles.phaseButtonInactivePosition)}
          >
            <WppIconArrow direction="right" color={color} />
          </Flex>
        </Flex>
      )}
    </Link>
  )
}
