import { WppButton } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/loccitane/LoccitaneProcess.module.scss'
import { LoccitaneSvg } from 'pages/customProcess/components/loccitane/LoccitaneSvg'
import { routesManager } from 'utils/routesManager'

export const LoccitaneProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<string>>('discover')

  const onLeavePhase = useCallback(() => {
    setActivePhase(null)
  }, [setActivePhase])

  return (
    <Flex direction="column" className={styles.container}>
      <Flex justify="end">
        <Link to={routesManager.systemUrls.orchestration.root.url}>
          <WppButton>Go to the project details</WppButton>
        </Link>
      </Flex>
      <Flex justify="center" align="center" className={styles.phases}>
        <div className={styles.svgWrapper}>
          <LoccitaneSvg activePhase={activePhase} onHover={setActivePhase} onLeave={onLeavePhase} />
        </div>
      </Flex>
    </Flex>
  )
}
