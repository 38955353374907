import schemeImg from 'pages/customProcess/components/unilever/assets/SVG-Measurement_Plan.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Measurement = () => {
  return (
    <ProcessCard
      type="delivery"
      title="Measurement Plan & Final Presentation"
      description="Sharing a sharp and elevated locally nuanced strategy & media plan & Codifying measurement solutions tied to outcomes"
      previousLink="/custom/unilever/consumer"
      nextLink="/custom/unilever/learning"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Measurement
