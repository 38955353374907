import { MayBeNull } from '@wpp-open/core'
import React, { useEffect, useCallback } from 'react'

interface Props {
  refs: React.MutableRefObject<MayBeNull<HTMLElement>>[]
  excludedRefs: React.MutableRefObject<HTMLElement | null>[]
  onOutsideClick: () => void
}

export const useDetectOutsideClick = ({ refs, excludedRefs, onOutsideClick }: Props) => {
  const handleDetectOutsideClick = useCallback(
    (event: MouseEvent) => {
      for (const ref of refs) {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          !excludedRefs.some(excludedRef => excludedRef.current && excludedRef.current.contains(event.target as Node))
        ) {
          onOutsideClick()
          return
        }
      }
    },
    [onOutsideClick, refs, excludedRefs],
  )

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      handleDetectOutsideClick(event)
    }

    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [handleDetectOutsideClick])
}
