// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wc4s1{padding:16px 16px 40px}.BCYk_{margin-top:0}.wMdwG{width:200px;padding:16px;background:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m);box-shadow:0 8px 20px rgba(3,70,119,.1)}.gc5_M{background:linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.Tt7K4{background:linear-gradient(258.94deg, #53e0ff 14.17%, #04ceaa 86.74%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.GewA2{margin-top:24px;padding:16px 16px 64px}.sYf6k{width:330px}@media(max-height: 900px){.sYf6k{width:400px}}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/strategicMediaPlanning/StrategicMediaPlanning.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,YAAA,CAGF,OACE,WAAA,CACA,YAAA,CACA,oCAAA,CACA,wCAAA,CACA,uCAAA,CAGF,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,qEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,eAAA,CACA,sBAAA,CAGF,OACE,WAAA,CAEA,0BAHF,OAII,WAAA,CAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.inputCardsWrapper {\n  margin-top: 0;\n}\n\n.inputBottomExtraCard {\n  width: 200px;\n  padding: 16px;\n  background: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: 0 8px 20px rgba(#034677, 10%);\n}\n\n.synapseTagColor {\n  background: linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.architectTagColor {\n  background: linear-gradient(258.94deg, #53e0ff 14.17%, #04ceaa 86.74%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.outputsCard {\n  margin-top: 24px;\n  padding: 16px 16px 64px;\n}\n\n.mainCard {\n  width: 330px;\n\n  @media (height <= 900px) {\n    width: 400px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `wc4s1`,
	"inputCardsWrapper": `BCYk_`,
	"inputBottomExtraCard": `wMdwG`,
	"synapseTagColor": `gc5_M`,
	"architectTagColor": `Tt7K4`,
	"outputsCard": `GewA2`,
	"mainCard": `sYf6k`
};
export default ___CSS_LOADER_EXPORT___;
