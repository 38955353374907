import { Flex } from 'components/common/flex/Flex'
import audienceOriginLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/audience-origin-logo.svg'
import copilotLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/copilot-logo.png'
import { InputCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputCard/InputCard'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/implementation/Implementation.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const Implementation = () => {
  const previousLink = '/custom/activation-planning'
  const nextLink = '/custom/outcomes-and-adapt'

  return (
    <ProcessCard
      title="Implementation"
      showRepeatIcon
      footerTitle="Nestle framework"
      footerSubtitle="brilliant basics: Golden Rules, Hygiene BPIs and Ad-Tech standards"
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputCard
            title="From Campaign Analysis"
            text="Campaign Analysis is carried out, insights are generated"
            width={230}
          />
          <svg width="6" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 48 2.887-5H.113L3 48Zm.5-4.5V0h-1v43.5h1Z" fill="#307DE4" />
          </svg>
          <InputsOutputsCard title="inputs" text="Media Activation sign-off" cardStyles={styles.inputsCard} />
        </Flex>
        <Flex direction="column" align="center" gap={78}>
          <RegularCard
            text="Campaign Set Up"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Octra®', style: styles.redmillTagColor }]}
          />
          <RegularCard
            text="Implement Nestle Golden Rules, Hygiene BPIs and Ad-Tech standards"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Media Activity Plan®', style: styles.mediaActivityTagColor }]}
          />
        </Flex>
        <div className={styles.regularCard3Wrapper}>
          <RegularCard
            text="Activate Media: Search Social Programmatic, Traditional Display, DCO, Creative, E-Commerce (RDM)"
            teamTags={[
              { text: 'Media Teams', tags: ['R'] },
              { text: 'GDS', tags: ['R'] },
            ]}
          />
        </div>
        <div className={styles.regularCard4Wrapper}>
          <RegularCard
            text="Execution & Optimisation"
            teamTags={[
              { text: 'Creative Teams', tags: ['R'] },
              { text: 'Media Teams', tags: ['R', 'A'] },
              { text: 'GDS', tags: ['C'] },
              { text: 'Nestle Teams', tags: ['I'] },
            ]}
            brandTags={[
              { text: 'Copilot®', style: styles.mediaActivityTagColor, logoImgUrl: copilotLogoImgUrl },
              {
                text: 'Choreograph Create®',
                style: styles.mediaActivityTagColor,
                logoImgUrl: audienceOriginLogoImgUrl,
              },
              {
                text: 'Accutics®',
                style: styles.mediaActivityTagColor,
              },
              {
                text: 'Clarawiine®',
                style: styles.mediaActivityTagColor,
              },
            ]}
          />
        </div>
        <InputsOutputsCard title="inputs" text="Campaign execution and Optimisation" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default Implementation
