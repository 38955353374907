export const processPhasesData = [
  {
    title: 'Define',
    color: '#cf3c33',
    description: 'Client brief and supporting documents, key KPI’s and objectives',
    link: '/custom/tiger-brands/define',
    nextLink: '/custom/tiger-brands/decide',
  },
  {
    title: 'Decide',
    color: '#c99f42',
    description: 'Strategy planning and development',
    link: '/custom/tiger-brands/decide',
    previousLink: '/custom/tiger-brands/define',
    nextLink: '/custom/tiger-brands/develop',
  },
  {
    title: 'Develop',
    color: '#4d974f',
    description: 'Media planning',
    link: '/custom/tiger-brands/develop',
    previousLink: '/custom/tiger-brands/decide',
    nextLink: '/custom/tiger-brands/deploy',
  },
  {
    title: 'Deploy',
    color: '#4caad3',
    description: 'Campaign Implementation – Media buying',
    link: '/custom/tiger-brands/deploy',
    previousLink: '/custom/tiger-brands/develop',
    nextLink: '/custom/tiger-brands/discern',
  },
  {
    title: 'Discern',
    color: '#cd3380',
    description: 'Campaign Optimisations and changes over the campaign period',
    link: '/custom/tiger-brands/discern',
    previousLink: '/custom/tiger-brands/deploy',
    nextLink: '/custom/tiger-brands/decode',
  },
  {
    title: 'Decode',
    color: '#203663',
    description: 'Post Campaign Learning and Strategy refinement',
    link: '/custom/tiger-brands/decode',
    previousLink: '/custom/tiger-brands/discern',
  },
]
