import discoverSchemeImgSrc from 'pages/customProcess/components/shell/assets/phaseSchemes/discover.png'
import measureSchemeImgSrc from 'pages/customProcess/components/shell/assets/phaseSchemes/measure.png'
import optimiseSchemeImgSrc from 'pages/customProcess/components/shell/assets/phaseSchemes/optimise.png'
import planSchemeImgSrc from 'pages/customProcess/components/shell/assets/phaseSchemes/plan.png'
import { Phases, Phase } from 'pages/customProcess/components/shell/types'

export const mediaPhases: Phase[] = [
  {
    type: Phases.Discover,
    color: '#de4832',
    title: 'Discover',
    description: 'Brand, audience and journey analysis to identify sources of growth',
    detailsDescription: 'Identify market and\naudience opportunity for growth',
    schemeImgSrc: discoverSchemeImgSrc,
    tools: ['BAV', 'Source of Growth'],
    link: '/custom/shell/discover',
    nextLink: '/custom/shell/plan',
  },
  {
    type: Phases.Plan,
    color: '#e99837',
    title: 'Plan',
    description: 'Scenario plan against audiences, channels, and platforms',
    detailsDescription: 'Scenario plan against audiences,\nchannels, and platforms',
    schemeImgSrc: planSchemeImgSrc,
    tools: ['Architect', 'Fusion', 'Nexus Growth Platform'],
    link: '/custom/shell/plan',
    previousLink: '/custom/shell/discover',
    nextLink: '/custom/shell/optimise',
  },
  {
    type: Phases.Optimise,
    color: '#357b62',
    title: 'Optimise',
    description: 'Implement, monitor, and maximize in-market activities',
    detailsDescription: 'Implement, monitor, and maximize\nin-market activities',
    schemeImgSrc: optimiseSchemeImgSrc,
    tools: ['CoPilot', 'Clarevine', 'Choreograph Create'],
    link: '/custom/shell/optimise',
    previousLink: '/custom/shell/plan',
    nextLink: '/custom/shell/measure',
  },
  {
    type: Phases.Measure,
    color: '#406999',
    title: 'Measure',
    description: 'Quantify and analyse campaign effectiveness',
    detailsDescription: 'Quantify and analyse campaign\neffectiveness',
    schemeImgSrc: measureSchemeImgSrc,
    tools: ['Smartdata - performance', 'Smartdata - test & learn'],
    link: '/custom/shell/measure',
    previousLink: '/custom/shell/optimise',
  },
]
