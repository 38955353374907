import synapseMtmOriginLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/synapse-mtm-logo.svg'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/campaignStrategicApproach/CampaignStrategicApproach.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const CampaignStrategicApproach = () => {
  const previousLink = '/custom/campaign-discovery'
  const nextLink = '/custom/contact-points-and-investment-planning'

  return (
    <ProcessCard
      title="Campaign Strategic Approach"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="Strategic Confirmation and alignment"
          cardStyles={styles.inputsOutputsCard}
        />
        <RegularCard
          text="Develop Campaign Approach"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[
            { text: 'Synapse MTM®', style: styles.synapseTagColor, logoImgUrl: synapseMtmOriginLogoImgUrl },
            { text: 'DE&I Framework®', style: styles.synapseTagColor },
          ]}
        />
        <InputsOutputsCard
          title="outputs"
          text="Campaign approach is developed"
          cardStyles={styles.inputsOutputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignStrategicApproach
