// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EytqM{position:relative;max-width:var(--wpp-os-content-max-width);height:calc(100vh - var(--wpp-os-header-height));margin:0 auto;padding:0 28px}.er1z3{position:relative;height:calc(100vh - var(--wpp-os-header-height))}.hZny7{position:relative;width:100%;height:100%}.hZny7 *{transition:opacity .3s ease}.erNxW{opacity:0}.mqyVk{opacity:1}.G4iUr{transform-origin:49% 50%;transition:transform .3s ease;pointer-events:none}.drvPE{transform:scale(0.395)}.SVobJ{position:absolute;top:40px;right:28px;z-index:1;display:block}.j5l8l::part(button){background-color:#1a467c}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/fordMotorChina/FordMotorChinaProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,yCAAA,CACA,gDAAA,CACA,aAAA,CACA,cAAA,CAGF,OACE,iBAAA,CACA,gDAAA,CAGF,OACE,iBAAA,CACA,UAAA,CACA,WAAA,CAEA,SACE,2BAAA,CAIJ,OACE,SAAA,CAGF,OACE,SAAA,CAGF,OACE,wBAAA,CACA,6BAAA,CACA,mBAAA,CAGF,OACE,sBAAA,CAGF,OACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,SAAA,CACA,aAAA,CAIA,qBACE,wBAAA","sourcesContent":[".root {\n  position: relative;\n  max-width: var(--wpp-os-content-max-width);\n  height: calc(100vh - var(--wpp-os-header-height));\n  margin: 0 auto;\n  padding: 0 28px;\n}\n\n.processSchemeContainer {\n  position: relative;\n  height: calc(100vh - var(--wpp-os-header-height));\n}\n\n.svg {\n  position: relative;\n  width: 100%;\n  height: 100%;\n\n  * {\n    transition: opacity 0.3s ease;\n  }\n}\n\n.opacityHidden {\n  opacity: 0;\n}\n\n.opacityShown {\n  opacity: 1;\n}\n\n.centerImg {\n  transform-origin: 49% 50%;\n  transition: transform 0.3s ease;\n  pointer-events: none;\n}\n\n.smallCenterImg {\n  transform: scale(0.395);\n}\n\n.projectsButtonWrapper {\n  position: absolute;\n  top: 40px;\n  right: 28px;\n  z-index: 1;\n  display: block;\n}\n\n.projectsButton {\n  &::part(button) {\n    background-color: #1a467c;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `EytqM`,
	"processSchemeContainer": `er1z3`,
	"svg": `hZny7`,
	"opacityHidden": `erNxW`,
	"opacityShown": `mqyVk`,
	"centerImg": `G4iUr`,
	"smallCenterImg": `drvPE`,
	"projectsButtonWrapper": `SVobJ`,
	"projectsButton": `j5l8l`
};
export default ___CSS_LOADER_EXPORT___;
