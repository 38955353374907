import clsx from 'clsx'
import { MouseEventHandler, useRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick'
import Overlay from 'pages/customProcess/components/ikeaNew/components/phaseCard/overlay/Overlay'
import styles from 'pages/customProcess/components/ikeaNew/components/phaseCard/PhaseCard.module.scss'
import { Phases, PhaseData } from 'pages/customProcess/components/ikeaNew/types'

interface Props {
  imagePath: string
  phaseData: PhaseData
  className?: string
  isPhaseHovered: boolean
  onPhaseEnter: (phase: Phases) => MouseEventHandler
  onPhaseLeave: () => void
  onPhaseOpen: (phase: Phases) => MouseEventHandler
  onPhaseClose: () => void
  disabledOutsideClick: boolean
}

const PhaseCard = ({
  imagePath,
  phaseData,
  className,
  isPhaseHovered,
  onPhaseEnter,
  onPhaseLeave,
  onPhaseOpen,
  onPhaseClose,
  disabledOutsideClick,
}: Props) => {
  const phaseCardRef = useRef(null)

  useDetectOutsideClick({
    refs: [phaseCardRef],
    excludedRefs: [],
    onOutsideClick: () => !disabledOutsideClick && onPhaseClose(),
  })

  return (
    <Flex
      ref={phaseCardRef}
      direction="column"
      className={clsx([styles.phaseCard, { [styles.active]: isPhaseHovered }, className])}
      onMouseEnter={onPhaseEnter(phaseData.phaseId)}
      onMouseLeave={onPhaseLeave}
      onClick={onPhaseOpen(phaseData.phaseId)}
    >
      <img src={imagePath} alt="" className={clsx(styles.image, { [styles.show]: isPhaseHovered })} />
      <Overlay backgroundColor={phaseData.color} phaseData={phaseData} isHover={isPhaseHovered} />
    </Flex>
  )
}

export default PhaseCard
