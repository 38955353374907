import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as AIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/a.svg'
import { ReactComponent as CIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/c.svg'
import { ReactComponent as IIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/i.svg'
import { ReactComponent as RIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/r.svg'
import styles from 'pages/customProcess/components/ibmNew/components/responsibilitiesInfo/ResponsibilitiesInfo.module.scss'

interface Props {
  isVisible: boolean
}

export const ResponsibilitiesInfo = ({ isVisible }: Props) => {
  return (
    <div className={clsx(styles.container, isVisible && styles.containerVisible)}>
      <Flex gap={16}>
        <div className={styles.section}>
          <Flex gap={8}>
            <RIcon className={styles.icon} />
            <div>
              <WppTypography type="xs-body" className={styles.text}>
                {'RESPONSIBLE:\nassigned to '}
                <span className={styles.bold}>do the work</span>
              </WppTypography>
            </div>
          </Flex>
        </div>
        <div className={styles.section}>
          <Flex gap={8}>
            <AIcon className={styles.icon} />
            <div>
              <WppTypography type="xs-body" className={styles.text}>
                {'ACCOUNTABLE:\nmakes the '}
                <span className={styles.bold}>final decision </span>and has the{' '}
                <span className={styles.bold}>ultimate ownership</span>
              </WppTypography>
            </div>
          </Flex>
        </div>
        <div className={styles.section}>
          <Flex gap={8}>
            <CIcon className={styles.icon} />
            <div>
              <WppTypography type="xs-body" className={styles.text}>
                {'CONSULTED:\nmust be consulted '}
                <span className={styles.bold}>before a decision </span>or action is taken
              </WppTypography>
            </div>
          </Flex>
        </div>
        <div className={styles.section}>
          <Flex gap={8}>
            <IIcon className={styles.icon} />
            <div>
              <WppTypography type="xs-body" className={styles.text}>
                {'INFORMED:\nmust be informed that a decision or action '}
                <span className={styles.bold}>has been taken</span>
              </WppTypography>
            </div>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
