import { Routes, Route } from 'react-router-dom'

import Discover from 'pages/customProcess/components/lego/processPhases/pages/Discover'
import Measure from 'pages/customProcess/components/lego/processPhases/pages/Measure'
import Optimise from 'pages/customProcess/components/lego/processPhases/pages/Optimise'
import Plan from 'pages/customProcess/components/lego/processPhases/pages/Plan'

const LegoRoutes = () => (
  <Routes>
    <Route path="discover" element={<Discover />} />
    <Route path="plan" element={<Plan />} />
    <Route path="optimise" element={<Optimise />} />
    <Route path="measure" element={<Measure />} />
  </Routes>
)

export default LegoRoutes
