import { PageLayout } from 'pages/customProcess/components/shell/processPhases/pageLayout/PageLayout'
import { mediaPhases } from 'pages/customProcess/components/shell/ShellConfig'

const Optimise = () => {
  const { type, color, title, detailsDescription, schemeImgSrc, previousLink, nextLink } = mediaPhases[2]
  return (
    <PageLayout
      type={type}
      color={color}
      title={title}
      description={detailsDescription}
      schemeImgSrc={schemeImgSrc}
      previousLink={previousLink}
      nextLink={nextLink}
    />
  )
}

export default Optimise
