import styles from 'components/svg/common.module.scss'

export const SvgNotFound = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="188" height="167" viewBox="0 0 188 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_f_209_9306)">
      <ellipse cx="93.874" cy="131.818" rx="71.8332" ry="12.9414" className={styles.fillPrimary300} />
    </g>
    <path
      d="M99 148.491C137.66 148.491 169 117.151 169 78.4911C169 39.8312 137.66 8.49109 99 8.49109C60.3401 8.49109 29 39.8312 29 78.4911C29 117.151 60.3401 148.491 99 148.491Z"
      className={styles.fillPrimary100}
    />
    <path
      d="M59.5415 36.6124V80.2184H154.159L154.159 80.2133H163.656C169.847 80.2133 175.785 77.9165 180.163 73.8281C184.541 69.7397 187 64.1947 187 58.4129C187 52.631 184.541 47.086 180.163 42.9976C175.785 38.9093 169.847 36.6124 163.656 36.6124H59.5415Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M59.5415 36.6124V80.2184H154.159L154.159 80.2133H163.656C169.847 80.2133 175.785 77.9165 180.163 73.8281C184.541 69.7397 187 64.1947 187 58.4129C187 52.631 184.541 47.086 180.163 42.9976C175.785 38.9093 169.847 36.6124 163.656 36.6124H59.5415Z"
      fill="url(#paint0_linear_209_9306)"
    />
    <path
      d="M160.302 43.0269H165.735C169.913 43.0269 173.921 44.577 176.875 47.3363C179.83 50.0956 181.49 53.838 181.49 57.7402V59.0909C181.49 62.9931 179.83 66.7355 176.875 69.4948C173.921 72.2541 169.913 73.8042 165.735 73.8042H160.302V43.0269Z"
      className={styles.fillPrimary300}
    />
    <path d="M154.159 43.9307H67.4877V80.2185H154.159V43.9307Z" className={styles.fillPrimary500} />
    <rect x="36.9352" y="87.4778" width="35.1398" height="42.0284" className={styles.fillPrimary300} />
    <rect x="80.0895" y="87.4778" width="35.1398" height="42.0284" className={styles.fillPrimary300} />
    <rect x="123.244" y="87.4778" width="22.81" height="42.0284" className={styles.fillPrimary300} />
    <path
      d="M33.667 79.9934H37.2786L37.2786 136.991H33.667C31.0457 136.991 28.9208 134.866 28.9208 132.245L28.9208 84.7397C28.9208 82.1184 31.0458 79.9934 33.667 79.9934Z"
      className={styles.fillPrimary400}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.928 79.9934H37.2786L37.2787 87.4779H72.0751L72.0751 129.506H37.2787L37.2786 136.991H149.322C151.943 136.991 154.068 134.866 154.068 132.245L154.068 79.9934H119.976C119.616 79.9934 119.265 80.0335 118.928 80.1094V79.9934ZM146.054 87.4779H123.244L123.244 129.506H146.054L146.054 87.4779ZM115.229 87.4779L115.229 129.506H80.0894L80.0894 87.4779H115.229Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M33.667 79.9934H37.2786L37.2786 136.991H33.667C31.0457 136.991 28.9208 134.866 28.9208 132.245L28.9208 84.7397C28.9208 82.1184 31.0458 79.9934 33.667 79.9934Z"
      fill="url(#paint1_linear_209_9306)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.928 79.9934H37.2786L37.2787 87.4779H72.0751L72.0751 129.506H37.2787L37.2786 136.991H149.322C151.943 136.991 154.068 134.866 154.068 132.245L154.068 79.9934H119.976C119.616 79.9934 119.265 80.0335 118.928 80.1094V79.9934ZM146.054 87.4779H123.244L123.244 129.506H146.054L146.054 87.4779ZM115.229 87.4779L115.229 129.506H80.0894L80.0894 87.4779H115.229Z"
      fill="url(#paint2_linear_209_9306)"
    />
    <path
      d="M65.8822 80.311V80.3932V82.9867V101.88H87.6278C88.152 101.88 88.577 101.455 88.577 100.931V82.9867V81.2603C88.577 80.736 88.152 80.311 87.6278 80.311H65.8822Z"
      className={styles.fillPrimary500}
    />
    <path
      d="M64.8793 80.311C64.355 80.311 63.9301 80.736 63.9301 81.2603V100.931C63.9301 101.455 64.355 101.88 64.8793 101.88H86.6249V80.311H64.8793Z"
      className={styles.fillPrimary400}
    />
    <path d="M84.0731 82.3754H66.4869V99.6411H84.0731V82.3754Z" className={styles.fillPrimary500} />
    <g filter="url(#filter1_d_209_9306)">
      <path
        d="M23.9891 65.7138L32.5238 85.3162C32.6403 85.5849 32.8125 85.8295 33.0305 86.0359C33.2485 86.2424 33.508 86.4066 33.7941 86.5192C34.0802 86.6318 34.3873 86.6906 34.6977 86.6921C35.0082 86.6936 35.3159 86.6379 35.6033 86.5282L54.7292 79.2716C55.3107 79.0506 55.7746 78.6231 56.019 78.0829C56.2633 77.5427 56.2682 76.9341 56.0325 76.3906L46.1671 53.7325C45.9304 53.1907 45.4732 52.7587 44.8958 52.5315C44.3184 52.3042 43.6681 52.3003 43.0875 52.5205L27.1951 58.5548L23.9891 65.7138Z"
        className={styles.fillPrimary100}
      />
    </g>
    <path d="M23.9891 65.7138L29.4168 63.6544L27.2006 58.5548L23.9891 65.7138Z" className={styles.fillPrimary200} />
    <g filter="url(#filter2_d_209_9306)">
      <path
        d="M159.16 54.1991L150.625 73.8015C150.509 74.0703 150.337 74.3149 150.119 74.5213C149.901 74.7277 149.641 74.892 149.355 75.0046C149.069 75.1171 148.762 75.1759 148.451 75.1774C148.141 75.179 147.833 75.1233 147.546 75.0135L128.42 67.757C127.838 67.5359 127.375 67.1084 127.13 66.5682C126.886 66.0281 126.881 65.4194 127.117 64.8759L136.982 42.2179C137.219 41.676 137.676 41.2441 138.253 41.0168C138.831 40.7896 139.481 40.7856 140.062 41.0059L155.954 47.0402L159.16 54.1991Z"
        className={styles.fillPrimary100}
      />
    </g>
    <path d="M159.16 54.199L153.732 52.1396L155.949 47.04L159.16 54.199Z" className={styles.fillPrimary200} />
    <path
      d="M77.5951 91.7323C78.2949 91.2729 78.8198 90.6172 79.0936 89.8603C79.3674 89.1035 79.3759 88.2849 79.1179 87.5232C78.8599 86.7616 78.3488 86.0965 77.6587 85.6244C76.9686 85.1523 76.1354 84.8978 75.28 84.8978C74.4246 84.8978 73.5914 85.1523 72.9013 85.6244C72.2112 86.0965 71.7001 86.7616 71.4421 87.5232C71.1841 88.2849 71.1926 89.1035 71.4664 89.8603C71.7402 90.6172 72.2651 91.2729 72.9649 91.7323L71.2382 97.2839H79.3164L77.5951 91.7323Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M127.351 33.4746C127.351 34.2824 127.222 35.0857 126.966 35.8575C126.553 37.0952 125.823 38.2199 124.838 39.134C124.549 39.4072 124.239 39.6595 123.909 39.889C123.692 40.0467 123.464 40.1908 123.227 40.3203C122.991 40.4558 122.746 40.5792 122.496 40.6901C122.122 40.8625 121.734 41.0067 121.335 41.1215C120.509 41.3597 119.649 41.4809 118.784 41.481H117.189V80.0747H72.6462V32.6376C72.6535 30.7396 73.464 28.9213 74.9011 27.5792C76.3382 26.2372 78.2852 25.4802 80.3175 25.4734H118.762C119.833 25.4696 120.895 25.6561 121.891 26.023C122.167 26.123 122.437 26.2379 122.699 26.367C122.831 26.4287 122.958 26.4954 123.084 26.5673C123.188 26.6206 123.289 26.6789 123.387 26.7419C123.906 27.0488 124.388 27.4088 124.822 27.8153C125.405 28.3609 125.901 28.9818 126.296 29.6589C126.562 30.1153 126.78 30.5952 126.944 31.0917L127.06 31.4769C127.131 31.7342 127.188 31.9948 127.23 32.2575C127.304 32.6597 127.345 33.0666 127.351 33.4746Z"
      className={styles.fillPrimary200}
    />
    <path
      d="M127.351 33.4746C127.351 34.2824 127.222 35.0857 126.967 35.8575C126.798 36.3528 126.581 36.8324 126.318 37.2904C125.919 37.9666 125.421 38.5872 124.838 39.134C124.549 39.4072 124.239 39.6595 123.909 39.889C123.689 40.043 123.464 40.1817 123.227 40.3203C122.991 40.4557 122.746 40.5792 122.496 40.6901C122.121 40.8603 121.733 41.0045 121.335 41.1215C120.509 41.3597 119.649 41.4809 118.784 41.481H117.189V32.6375C117.193 31.2234 117.643 29.8415 118.483 28.665C119.323 27.4884 120.516 26.5694 121.913 26.0229C122.188 26.1257 122.463 26.2386 122.721 26.367C122.853 26.4287 122.98 26.4954 123.106 26.5673C123.21 26.6206 123.311 26.6789 123.409 26.7419C123.928 27.0488 124.41 27.4088 124.844 27.8153C125.427 28.3609 125.923 28.9818 126.318 29.6589C126.582 30.1163 126.799 30.596 126.967 31.0917L127.082 31.4769C127.154 31.7341 127.21 31.9947 127.252 32.2575C127.319 32.6602 127.352 33.0672 127.351 33.4746Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M108.297 66.0238H80.1686C79.6979 66.0238 79.3163 66.379 79.3163 66.8172C79.3163 67.2555 79.6979 67.6107 80.1686 67.6107H108.297C108.767 67.6107 109.149 67.2555 109.149 66.8172C109.149 66.379 108.767 66.0238 108.297 66.0238Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M108.297 57.884H80.1686C79.6979 57.884 79.3163 58.2393 79.3163 58.6775C79.3163 59.1157 79.6979 59.4709 80.1686 59.4709H108.297C108.767 59.4709 109.149 59.1157 109.149 58.6775C109.149 58.2393 108.767 57.884 108.297 57.884Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M108.297 49.7389H90.1824C89.7117 49.7389 89.3301 50.0941 89.3301 50.5323C89.3301 50.9705 89.7117 51.3258 90.1824 51.3258H108.297C108.767 51.3258 109.149 50.9705 109.149 50.5323C109.149 50.0941 108.767 49.7389 108.297 49.7389Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M96.413 41.594H79.9707C79.6093 41.594 79.3163 41.8676 79.3163 42.2051V42.5697C79.3163 42.9073 79.6093 43.1809 79.9707 43.1809H96.413C96.7744 43.1809 97.0674 42.9073 97.0674 42.5697V42.2051C97.0674 41.8676 96.7744 41.594 96.413 41.594Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M86.2177 49.7389H80.1686C79.6979 49.7389 79.3163 50.0941 79.3163 50.5323C79.3163 50.9705 79.6979 51.3258 80.1686 51.3258H86.2177C86.6884 51.3258 87.07 50.9705 87.07 50.5323C87.07 50.0941 86.6884 49.7389 86.2177 49.7389Z"
      className={styles.fillPrimary400}
    />
    <path d="M37.2434 87.4778H40.0176V129.506H37.2434V87.4778Z" className={styles.fillPrimary500} />
    <path d="M80.0895 101.871H82.5554V129.506H80.0895V101.871Z" className={styles.fillPrimary500} />
    <path d="M123.244 87.4778H126.018V129.506H123.244V87.4778Z" className={styles.fillPrimary500} />
    <g filter="url(#filter3_f_209_9306)">
      <ellipse cx="48" cy="51" rx="21" ry="5" className={styles.fillDanger300} />
    </g>
    <path
      d="M25.4685 41.6213C25.9266 44.8157 27.2437 47.8496 29.2968 50.4401C31.3499 53.0306 34.0721 55.0932 37.2095 56.4356C40.3469 57.7779 43.7973 58.3563 47.2388 58.1167C50.6802 57.8772 54.0006 56.8274 56.8903 55.0655L64.7631 57.565C65.0006 57.638 65.2555 57.6461 65.4978 57.5882C65.74 57.5302 65.9594 57.4087 66.13 57.238C66.3006 57.0672 66.4153 56.8545 66.4606 56.6248C66.5058 56.3951 66.4796 56.1581 66.3852 55.9419L63.2279 48.9088C64.9071 46.2963 65.8918 43.3463 66.0995 40.3055C66.3072 37.2647 65.732 34.2217 64.4219 31.4312C63.1118 28.6407 61.1051 26.1838 58.5695 24.2663C56.034 22.3487 53.0435 21.0263 49.8484 20.4096C46.6533 19.793 43.3465 19.9001 40.2047 20.7219C37.063 21.5438 34.1778 23.0565 31.7906 25.1335C29.4034 27.2105 27.5837 29.7913 26.4838 32.6598C25.3838 35.5284 25.0357 38.6013 25.4685 41.6213Z"
      className={styles.fillDanger400}
    />
    <path
      d="M45.1795 48.8514C47.3976 48.8514 48.9776 47.4326 48.9776 45.4462C48.9776 43.4599 47.3976 42.0411 45.1795 42.0411C42.9918 42.0411 41.3814 43.4599 41.3814 45.4462C41.3814 47.4326 42.9918 48.8514 45.1795 48.8514ZM48.218 41.4736C48.218 39.6008 49.3726 38.9197 50.9527 38.2387C53.0492 37.3307 54.5988 35.4862 54.5988 33.2162C54.5988 29.6408 50.8311 27.1437 45.7265 27.1437C40.2572 27.1437 36.3983 30.0097 36.0641 34.0107L43.0526 35.2025C43.2349 33.6418 44.1161 32.5351 45.3618 32.5351C46.3038 32.5351 47.0026 33.131 47.0026 33.9539C47.0026 34.9187 45.9695 35.5146 45.1491 35.9686C42.9614 37.3023 42.1411 38.7779 42.1411 41.4736H48.218Z"
      className={styles.fillWhite}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146 28.2857C147.262 28.2857 148.286 27.2624 148.286 26C148.286 24.7376 147.262 23.7143 146 23.7143C144.738 23.7143 143.714 24.7376 143.714 26C143.714 27.2624 144.738 28.2857 146 28.2857ZM146 30C148.209 30 150 28.2091 150 26C150 23.7909 148.209 22 146 22C143.791 22 142 23.7909 142 26C142 28.2091 143.791 30 146 30Z"
      className={styles.fillPrimary300}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126 146.286C127.262 146.286 128.286 145.262 128.286 144C128.286 142.738 127.262 141.714 126 141.714C124.738 141.714 123.714 142.738 123.714 144C123.714 145.262 124.738 146.286 126 146.286ZM126 148C128.209 148 130 146.209 130 144C130 141.791 128.209 140 126 140C123.791 140 122 141.791 122 144C122 146.209 123.791 148 126 148Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M20.2985 107.031C22.0586 106.744 23.4816 108.525 22.8456 110.219L21.9838 112.515C21.3544 114.192 19.1761 114.526 18.0518 113.119L16.5283 111.211C15.4041 109.804 16.1712 107.703 17.9131 107.419L20.2985 107.031Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M164.067 103.833C163.656 102.141 165.209 100.63 166.894 101.08L170.289 101.986C172.014 102.447 172.582 104.605 171.306 105.847L168.737 108.347C167.461 109.589 165.312 108.97 164.892 107.239L164.067 103.833Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M89.0064 7.48074C89.5384 5.50642 92.4616 5.50642 92.9936 7.48074L93.2725 8.51546C93.4656 9.23214 94.0604 9.78506 94.8133 9.94785L95.3937 10.0733C97.5354 10.5364 97.5354 13.4636 95.3937 13.9267L94.8133 14.0522C94.0604 14.2149 93.4656 14.7679 93.2725 15.4845L92.9936 16.5193C92.4616 18.4936 89.5384 18.4936 89.0064 16.5193L88.7275 15.4845C88.5344 14.7679 87.9396 14.2149 87.1867 14.0521L86.6063 13.9267C84.4646 13.4636 84.4646 10.5364 86.6063 10.0733L87.1867 9.94785C87.9396 9.78506 88.5344 9.23214 88.7275 8.51546L89.0064 7.48074Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M54.3402 142.604C54.9166 140.465 58.0834 140.465 58.6598 142.604L58.9619 143.725C59.1711 144.501 59.8154 145.1 60.6311 145.277L61.2598 145.413C63.5801 145.914 63.5801 149.086 61.2598 149.587L60.6311 149.723C59.8154 149.9 59.1711 150.499 58.9619 151.275L58.6598 152.396C58.0834 154.535 54.9166 154.535 54.3402 152.396L54.0381 151.275C53.8289 150.499 53.1846 149.9 52.3689 149.723L51.7402 149.587C49.4199 149.086 49.4199 145.914 51.7402 145.413L52.3689 145.277C53.1846 145.1 53.8289 144.501 54.0381 143.725L54.3402 142.604Z"
      className={styles.fillPrimary400}
    />
    <defs>
      <filter
        id="filter0_f_209_9306"
        x="0.267776"
        y="97.1031"
        width="187.212"
        height="69.4289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_209_9306" />
      </filter>
      <filter
        id="filter1_d_209_9306"
        x="21.1414"
        y="52.3582"
        width="51.2017"
        height="54.2683"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6.64476" dy="10.4418" />
        <feGaussianBlur stdDeviation="4.74626" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.254902 0 0 0 0 0.443137 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_9306" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_9306" result="shape" />
      </filter>
      <filter
        id="filter2_d_209_9306"
        x="124.096"
        y="40.8435"
        width="51.2017"
        height="54.2683"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6.64476" dy="10.4418" />
        <feGaussianBlur stdDeviation="4.74626" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.184314 0 0 0 0 0.254902 0 0 0 0 0.443137 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_209_9306" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_209_9306" result="shape" />
      </filter>
      <filter
        id="filter3_f_209_9306"
        x="11"
        y="30"
        width="74"
        height="42"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_209_9306" />
      </filter>
      <linearGradient
        id="paint0_linear_209_9306"
        x1="83.3664"
        y1="26.8103"
        x2="100.451"
        y2="92.4043"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorWhite} stopOpacity="0.32" />
        <stop offset="1" className={styles.stopColorWhite} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_209_9306"
        x1="52.3137"
        y1="67.181"
        x2="80.651"
        y2="148.91"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorWhite} stopOpacity="0.32" />
        <stop offset="1" className={styles.stopColorWhite} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_209_9306"
        x1="52.3137"
        y1="67.181"
        x2="80.651"
        y2="148.91"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorWhite} stopOpacity="0.32" />
        <stop offset="1" className={styles.stopColorWhite} stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)
