import schemeSrc from 'pages/customProcess/components/loreal/assets/phaseDetails/campaign-tracking.png'
import { processPhasesData } from 'pages/customProcess/components/loreal/LorealConfig'
import styles from 'pages/customProcess/components/loreal/LorealProcess.module.scss'
import { PageLayout } from 'pages/customProcess/components/loreal/processPhases/pageLayout/PageLayout'

const CampaignTracking = () => {
  const { title, steps, previousLink } = processPhasesData[4]

  return (
    <PageLayout title={title} steps={steps} previousLink={previousLink}>
      <img src={schemeSrc} alt="" className={styles.schemeImg} />
    </PageLayout>
  )
}

export default CampaignTracking
