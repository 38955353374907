import { WppActionButton, WppIconChevron, WppButton } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import atomIconUrl from 'pages/customProcess/components/ikea/assets/atom-icon.svg'
import lampLightImgUrl from 'pages/customProcess/components/ikea/assets/lamp-light.svg'
import lampManImgUrl from 'pages/customProcess/components/ikea/assets/lamp-man.svg'
import questionIconUrl from 'pages/customProcess/components/ikea/assets/question-icon.svg'
import starIconUrl from 'pages/customProcess/components/ikea/assets/star-icon.svg'
import targetIconUrl from 'pages/customProcess/components/ikea/assets/target-icon.svg'
import styles from 'pages/customProcess/components/ikea/processPhases/processPhaseDetails/ProcessPhaseDetails.module.scss'

export const ProcessPhaseDetails = () => {
  return (
    <div className={styles.root}>
      <div className={styles.pageContent}>
        <div className={styles.illustrationContainer}>
          <img src={lampManImgUrl} alt="Lamp Man illustration" className={styles.lampManIllustration} />
          <img src={lampLightImgUrl} alt="Lamp Light illustration" className={styles.lampLightIllustration} />
        </div>
        <Link to="/custom">
          <WppActionButton className={styles.backButton}>
            <WppIconChevron direction="left" slot="icon-start" color="#005aa6" />
            Back to Process overview
          </WppActionButton>
        </Link>
        <h1 className={styles.title}>Strategy & Measurement Solutions</h1>
        <p className={styles.description}>
          {
            'Propose how the communication solution will work, the assets to build and the\nframework to use for evaluating the impact and effectiveness of activity'
          }
        </p>
        <Flex gap={6} className={styles.fullWidth}>
          <div className={styles.detailsSection}>
            <Flex justify="between" align="start" gap={8} className={styles.fullWidth}>
              <h2 className={styles.sectionTitle}>Considerations</h2>
              <img src={atomIconUrl} alt="Atom icon" className={styles.atomIcon} />
            </Flex>
            <ul className={styles.list}>
              <li>
                Reference back to NMM and how any proposed strategic solution works in support of the overall model.
              </li>
              <li>Work with your clients in filling in the Consumer Challenges Tool (Get/To/By).</li>
              <li>Build asset requirement and consider outlining a messaging matrix.</li>
              <li>Look through case studies and reach out to global teams for further cross-market inspiration.</li>
            </ul>
          </div>
          <div className={styles.detailsSection}>
            <Flex justify="between" align="start" gap={8} className={styles.fullWidth}>
              <h2 className={styles.sectionTitle}>{'Questions\nyou must answer'}</h2>
              <img src={questionIconUrl} alt="Question icon" className={styles.atomIcon} />
            </Flex>
            <p className={styles.sectionDescription}>See detailed questions in Annual Planning Checklist:</p>
            <ul className={styles.list}>
              <li>Key challenges and opportunities</li>
              <li>Relevant audiences and behaviours</li>
              <li>Broader cultural opportunities</li>
              <li>NMM framework and econometric insights</li>
              <li>KPI’s, tracking and measurement</li>
              <li>Scenario planning to achieve business goals</li>
              <li>Activation and optimisation details</li>
              <li>Use of appropriate activation tools</li>
              <li>Learning agenda including innovation</li>
              <li>Barriers or challenges to the implementation</li>
            </ul>
          </div>
          <div className={styles.detailsSection}>
            <Flex justify="between" align="start" gap={8} className={styles.fullWidth}>
              <div>
                <h2 className={styles.toolResourcesTitle1}>{'Tools '}</h2>
                <h2 className={styles.toolResourcesTitle1} style={{ fontWeight: '400' }}>
                  &
                </h2>
                <h2 className={styles.toolResourcesTitle2}>Resources</h2>
              </div>
              <img src={starIconUrl} alt="Star icon" className={styles.atomIcon} />
            </Flex>
            <ul className={styles.list}>
              <li>Set the Strategy (Annual Planning Checklist)</li>
              <li>Case Studies Database</li>
              <li>NMM</li>
              <li>Consumer Challenge Tool (Get/To/By)</li>
              <li>MOT (cost of exposure)</li>
              <li>Measurement Framework</li>
              <li>Measurement Best Practice Guidelines</li>
              <li>Test Designs</li>
            </ul>
          </div>
          <div className={styles.detailsSection}>
            <Flex justify="between" align="start" gap={8} className={styles.fullWidth}>
              <h2 className={styles.sectionTitle}>Outcomes</h2>
              <img src={targetIconUrl} alt="Target icon" className={styles.atomIcon} />
            </Flex>
            <ul className={styles.list}>
              <li>
                Annual Strategy Outcomes: Clear direction on what media activation and measurement is needed across the
                FY to reach the desired business goal.
              </li>
              <li>
                Campaign Strategy Outcomes: Clear direction on how will the media activation answer to the campaign
                goals, aligned with the Annual Strategy.
              </li>
            </ul>
          </div>
        </Flex>
        <Flex justify="end">
          <Link to="/orchestration">
            <WppButton className={styles.goToProjectBtn}>Go to project details</WppButton>
          </Link>
        </Flex>
      </div>
    </div>
  )
}
