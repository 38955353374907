import { Phases, IPointPopupConfig } from 'pages/customProcess/components/vodafone/types'

export const pointPopupsConfig: Record<Phases, IPointPopupConfig> = {
  [Phases.Discover]: {
    description:
      'Explore the business opportunity, defining the proposition, and developing into an agreed campaign brief',
    title: 'Discover',
    placement: 'left-top-long',
  },
  [Phases.Define]: {
    description: 'Develop our strategic response to the brief',
    title: 'Define',
    placement: 'left-top-short',
  },
  [Phases.Design]: {
    description: 'Developing the strategic response into a creative response, then refining with research',
    title: 'Design',
    placement: 'left-top',
  },
  [Phases.Develop]: {
    description: 'Approval of the master creative, 360 comms development and approval',
    title: 'Develop',
    placement: 'left-bottom-long',
  },
  [Phases.Deliver]: {
    description: 'Production and delivery of comms across all channels',
    title: 'Deliver',
    placement: 'right-top',
  },
  [Phases.Distil]: {
    description: 'Post campaign reporting and analysis',
    title: 'Distil',
    placement: 'right-top-short',
  },
}
