import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    positionBackground: true,
    logoImage: true,
    logoDimensions: true,
    widgetBackgroundImage: true,
    widgetPartBackgroundImage: true,
    headerBackground: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      image: false,
      colors: true,
      innerPageEnabled: true,
      groupTitle: false,
      url: true,
      templateOptions: false,
    },
    groups: {
      title: true,
      description: true,
      phasesLocalIds: true,
      colors: true,
    },
    templateOptions: false,
  },
  colors: {
    page: [
      { title: 'Page background', defaultValue: 'linear-gradient(180deg, #f794a4 0%, #fdd6bd 100%)' },
      { title: 'Widget background', defaultValue: '#0014CC' },
      { title: 'Widget border', defaultValue: '#fff' },
      { title: 'Widget inner background', defaultValue: '#03086B' },
      { title: 'Widget inner border', defaultValue: '#fff' },
      { title: 'Widget logo background', defaultValue: '#fff' },
      { title: 'Widget logo border', defaultValue: '#fff' },
    ],
    button: [
      { title: 'Background', defaultValue: '#0014cc' },
      { title: 'Text', defaultValue: '#fff' },
    ],
    phase: [
      { title: 'Point', defaultValue: '#03086B' },
      { title: 'Text', defaultValue: '#fff' },
      { title: 'Popup text', defaultValue: '#fff' },
      { title: 'Popup background', defaultValue: 'rgba(255, 255, 255, 0)' },
    ],
    group: [
      { title: 'Title', defaultValue: '#fff' },
      { title: 'Point', defaultValue: '#CB3E22' },
      { title: 'Overlay', defaultValue: 'rgba(203, 62, 34, .6)' },
      { title: 'Border', defaultValue: '#fff' },
      { title: 'Popup text', defaultValue: '#fff' },
      { title: 'Title hover text', defaultValue: '#6d7bf7' },
    ],
  },
}
