import { Flex } from 'components/common/flex/Flex'
import audienceOriginImgSrc from 'pages/customProcess/components/henkel/assets/audience-origin-logo.png'
import { PageLayout } from 'pages/customProcess/components/henkel/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/henkel/processPhases/pages/audience/Audience.module.scss'

const Audience = () => {
  return (
    <PageLayout
      title="Audience"
      description="Audience behavioural analysis and insights."
      previousLink="/custom/henkel/ambition"
      nextLink="/custom/henkel/approach"
      inputsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Audience brief</li>
            <li>Source of growth</li>
          </ul>
        </div>
      }
      activitiesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Understand key attributes of\ntarget audience, sizing and\nsegmentation'}</li>
          </ul>
        </div>
      }
      toolsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>TGI</li>
            <li>Statista</li>
            <li>MDS Online</li>
          </ul>
          <Flex align="center" gap={4} className={styles.item}>
            <img src={audienceOriginImgSrc} alt="Audience Origin logo" className={styles.logo} />
            <span className={styles.itemText}>Audience Origin</span>
          </Flex>
        </div>
      }
      outcomesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Audience strategy</li>
          </ul>
        </div>
      }
    />
  )
}

export default Audience
