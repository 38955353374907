import { useEffect } from 'react'

import schemeImgSrc from 'pages/customProcess/components/sein/assets/design-scheme.png'
import { ProcessPhasePageLayout } from 'pages/customProcess/components/sein/processPhases/components/processPhasePageLayout/ProcessPhasePageLayout'

const DesignSein = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <ProcessPhasePageLayout
      title="Design"
      description="Designing Omni Channel Journeys and Experience plan"
      previousLink="/custom/sein/discover"
      nextLink="/custom/sein/deliver"
    >
      <img alt="" src={schemeImgSrc} style={{ width: '100%', pointerEvents: 'none', userSelect: 'none' }} />
    </ProcessPhasePageLayout>
  )
}

export default DesignSein
