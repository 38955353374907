// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dZROE{width:76.5%;padding:16px;background-color:#fff0f1}.o3DTr{margin-bottom:8px}.usTU_{width:58px;height:32px}.DMNeq{white-space:pre-wrap}.DMNeq::part(typography){color:#000;font-size:10px;font-family:"IBM Plex Mono",monospace;line-height:16px}.x2PoL{font-weight:700}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibmNew/processPhases/components/inputOutputInfo/InputOutputInfo.module.scss"],"names":[],"mappings":"AAEA,OACE,WAAA,CACA,YAAA,CACA,wBAAA,CAGF,OACE,iBAAA,CAGF,OACE,UAAA,CACA,WAAA,CAGF,OACE,oBAAA,CAEA,yBACE,UAAA,CACA,cAAA,CACA,qCAAA,CACA,gBAAA,CAIJ,OACE,eAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');\n\n.card {\n  width: 76.5%;\n  padding: 16px;\n  background-color: #fff0f1;\n}\n\n.section {\n  margin-bottom: 8px;\n}\n\n.img {\n  width: 58px;\n  height: 32px;\n}\n\n.text {\n  white-space: pre-wrap;\n\n  &::part(typography) {\n    color: #000000;\n    font-size: 10px;\n    font-family: 'IBM Plex Mono', monospace;\n    line-height: 16px;\n  }\n}\n\n.bold {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `dZROE`,
	"section": `o3DTr`,
	"img": `usTU_`,
	"text": `DMNeq`,
	"bold": `x2PoL`
};
export default ___CSS_LOADER_EXPORT___;
