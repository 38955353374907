// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m0peY{padding:16px 16px 64px}.VJnf_{margin-top:140px}.wcDc0{margin-top:70px}.JqoRW{margin-top:40px}.WtQU3{width:200px;padding:16px;background:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m);box-shadow:0 8px 20px rgba(3,70,119,.1)}.NzRlV{background:linear-gradient(258.94deg, #53e0ff 14.17%, #04ceaa 86.74%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.CG4vg{background:linear-gradient(89deg, #ff5656 2.86%, #fdc46e 54.75%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.NENK4{margin-top:24px;padding:16px 16px 64px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/pages/contactPointsAndInvestmentPlanning/ContactPointsAndInvestmentPlanning.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,gBAAA,CAGF,OACE,eAAA,CAGF,OACE,eAAA,CAGF,OACE,WAAA,CACA,YAAA,CACA,oCAAA,CACA,wCAAA,CACA,uCAAA,CAGF,OACE,qEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,gEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,eAAA,CACA,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 64px;\n}\n\n.inputCardsWrapper {\n  margin-top: 140px;\n}\n\n.regularCardWrapper1 {\n  margin-top: 70px;\n}\n\n.regularCardWrapper2 {\n  margin-top: 40px;\n}\n\n.inputBottomExtraCard {\n  width: 200px;\n  padding: 16px;\n  background: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n  box-shadow: 0 8px 20px rgba(#034677, 10%);\n}\n\n.architectTagColor {\n  background: linear-gradient(258.94deg, #53e0ff 14.17%, #04ceaa 86.74%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.synapseTagColor {\n  background: linear-gradient(89deg, #ff5656 2.86%, #fdc46e 54.75%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.outputsCard {\n  margin-top: 24px;\n  padding: 16px 16px 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `m0peY`,
	"inputCardsWrapper": `VJnf_`,
	"regularCardWrapper1": `wcDc0`,
	"regularCardWrapper2": `JqoRW`,
	"inputBottomExtraCard": `WtQU3`,
	"architectTagColor": `NzRlV`,
	"synapseTagColor": `CG4vg`,
	"outputsCard": `NENK4`
};
export default ___CSS_LOADER_EXPORT___;
