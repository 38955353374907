import { WppTypography, WppPopover, WppButton } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/vodafone/PointPopup.module.scss'
import { IPointPopupConfig } from 'pages/customProcess/components/vodafone/types'

export const PointPopup = ({
  config,
  target,
  onClose,
  appendTo,
}: {
  config: IPointPopupConfig
  target: any
  onClose: () => void
  appendTo?: MayBeNull<Element>
}) => {
  const targetRect = target?.getBoundingClientRect()

  const getPosition = useCallback(
    (reference: { width: number; height: number; x: number; y: number }): [number, number] => {
      switch (config.placement) {
        case 'left-top':
        case 'left-top-short':
        case 'left-top-long':
          return [targetRect.x - reference.x + targetRect.width, targetRect.y - reference.y]
        case 'left-bottom':
        case 'left-bottom-long':
          return [targetRect.x - reference.x + targetRect.width, targetRect.y - reference.y - reference.height]
        case 'right-top':
        case 'right-top-short':
          return [targetRect.x - reference.x - reference.width, targetRect.y - reference.y]
        case 'right-bottom':
        case 'right-bottom-short':
          return [targetRect.x - reference.x - reference.width, targetRect.y - reference.y - reference.height]
        default:
          return [targetRect.x - reference.x, targetRect.y - reference.y]
      }
    },
    [targetRect, config.placement],
  )

  return (
    <WppPopover
      config={{
        triggerTarget: target,
        offset: ({ reference, popper }) => getPosition({ ...reference, width: popper.width, height: popper.height }),
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
        onHide: onClose,
        appendTo: appendTo ?? undefined,
      }}
      externalClass={clsx(styles.pointPopupp, {
        [styles.leftTop]: config.placement === 'left-top',
        [styles.leftBottom]: config.placement === 'left-bottom',
        [styles.leftBottomLong]: config.placement === 'left-bottom-long',
        [styles.leftTopLong]: config.placement === 'left-top-long',
        [styles.rightTop]: config.placement === 'right-top',
        [styles.rightTopShort]: config.placement === 'right-top-short',
        [styles.rightBottom]: config.placement === 'right-bottom',
        [styles.rightBottomShort]: config.placement === 'right-bottom-short',
        [styles.leftTopShort]: config.placement === 'left-top-short',
      })}
    >
      <WppButton slot="trigger-element" hidden />

      <Flex direction="column">
        {config.title && (
          <WppTypography type="2xl-heading" className={styles.title}>
            {config.title}
          </WppTypography>
        )}

        {config.description && (
          <WppTypography type="s-body" className={styles.description}>
            {config.description}
          </WppTypography>
        )}
      </Flex>
    </WppPopover>
  )
}
