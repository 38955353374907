import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/nestleChina/processPhases/contentCards/regularCard/RegularCard.module.scss'

interface TeamTag {
  text: string
  tags: string[]
}

interface BrandTags {
  text: string
  style: string
  icon?: string
}

interface Props {
  text: string
  teamTags?: TeamTag[]
  brandTags?: BrandTags[]
  topHighlightText?: string
  bottomHighlightText?: string
  bottomHighlightTextSpecial?: string
  brandsDirection?: 'column' | 'row'
}

export const RegularCard = ({
  text,
  teamTags,
  brandTags,
  topHighlightText,
  bottomHighlightText,
  bottomHighlightTextSpecial,
  brandsDirection = 'column',
}: Props) => (
  <div>
    {topHighlightText && (
      <div>
        <WppTypography type="s-midi" className={styles.highlightText}>
          {topHighlightText}
        </WppTypography>
        <div>
          <svg width="240" height="48" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.topHighlight}>
            <path fillRule="evenodd" clipRule="evenodd" d="m0 0 34.91 48h168.726L240 0H0Z" fill="url(#top-highlight)" />
            <defs>
              <linearGradient id="top-highlight" x1="112.5" y1="156" x2="114.5" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#267CEE" />
                <stop offset="1" stopColor="#267CEE" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    )}
    <div className={styles.card}>
      <WppTypography type="m-midi" className={styles.text}>
        {text}
      </WppTypography>
      {teamTags?.map((teamTag, index) => (
        <Flex gap={8} className={styles.teamInfo} key={index}>
          <WppTypography type="xs-midi">{teamTag.text}</WppTypography>
          <Flex gap={8}>
            {teamTag.tags.map((tag, index) => (
              <Flex className={styles.teamTag} justify="center" align="center" key={index}>
                <WppTypography type="2xs-strong" className={styles.teamTagText}>
                  {tag}
                </WppTypography>
              </Flex>
            ))}
          </Flex>
        </Flex>
      ))}
      {brandTags?.length && (
        <Flex gap={8} direction={brandsDirection} className={styles.brandTags}>
          {brandTags.map((brandTag, index) => (
            <WppTypography className={clsx(styles.brandTagItem, brandTag.style)} key={index}>
              {brandTag.icon && <img src={brandTag.icon} alt="" className={styles.brandIcon} />}
              {brandTag.text}
            </WppTypography>
          ))}
        </Flex>
      )}
    </div>
    {bottomHighlightText && (
      <div>
        <div>
          <svg width="240" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 48 34.91 0h168.726L240 48H0Z"
              fill="url(#bottom-highlight)"
            />
            <defs>
              <linearGradient
                id="bottom-highlight"
                x1="112.5"
                y1="-108"
                x2="114.5"
                y2="48"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#267CEE" />
                <stop offset="1" stopColor="#267CEE" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <WppTypography type="s-midi" className={styles.highlightText}>
          {bottomHighlightText}
        </WppTypography>
      </div>
    )}
    {bottomHighlightTextSpecial && (
      <div>
        <div>
          <svg width="240" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 32 34.91 0h168.726L240 32H0Z" fill="url(#a)" />
            <defs>
              <linearGradient id="a" x1="112.5" y1="-72" x2="113.389" y2="32.01" gradientUnits="userSpaceOnUse">
                <stop stopColor="#267CEE" />
                <stop offset="1" stopColor="#267CEE" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className={styles.highlightTextSpecialContainer}>
          <WppTypography type="s-midi" className={styles.highlightText}>
            {bottomHighlightTextSpecial}
          </WppTypography>
        </div>
      </div>
    )}
  </div>
)
