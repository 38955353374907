import schemeImg from 'pages/customProcess/components/unilever/assets/SVG-Learning.png'
import { ProcessCard } from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard'

const Learning = () => {
  return (
    <ProcessCard
      type="delivery"
      title="Live & Learning"
      description="Campaign set live & learnings to be captured and added to Learning Library"
      previousLink="/custom/unilever/measurement"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Learning
