import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MouseEvent } from 'react'

import styles from 'pages/customProcess/components/ikeaNew/components/button/whiteButton/WhiteButton.module.scss'

interface Props {
  title: string
  onClick?: (event: MouseEvent<HTMLWppButtonElement>) => void
}

const WhiteButton = ({ title, onClick }: Props) => {
  return (
    <WppButton onClick={onClick} className={styles.buttonWrapper}>
      <WppTypography className={styles.buttonTitle} type="s-body" tag="span">
        {title}
      </WppTypography>
    </WppButton>
  )
}

export default WhiteButton
