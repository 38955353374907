import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { facadeApi, processApi } from 'api'

const apiInstances = [facadeApi, processApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    osApi,
    osContext: { tenant },
  } = useOs()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    apiInstances.forEach(instance => {
      instance.client.interceptors.request.use(
        config => {
          config!.headers!.Authorization = `Bearer ${osApi.getAccessToken()}`
          config!.headers!['X-Tenant-Id'] = tenant.id

          return config
        },
        error => Promise.reject(error),
      )

      instance.client.interceptors.response.use(
        response => response,
        error => Promise.reject(error),
      )
    })

    setIsReady(true)
  }, [osApi, tenant.id])

  return <>{isReady && <>{children}</>}</>
}
