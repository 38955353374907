// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FO1BW{width:100%;max-width:1116px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/audible/processPhases/pages/strategize/Strategize.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,gBAAA","sourcesContent":[".schemeImg {\n  width: 100%;\n  max-width: 1116px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schemeImg": `FO1BW`
};
export default ___CSS_LOADER_EXPORT___;
