import {
  WppCard,
  WppTypography,
  WppIconChevron,
  WppIconRepeatOn,
  WppButton,
  WppActionButton,
} from '@platform-ui-kit/components-library-react'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { MroiFooterShape } from 'pages/customProcess/components/nestleChina/processPhases/processCard/mroiFooterShape/MroiFooterShape'
import styles from 'pages/customProcess/components/nestleChina/processPhases/processCard/ProcessCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  title: string
  subtitle?: string
  bepTitle?: string
  showRepeatIcon?: boolean
  footerTitle?: string
  footerSubtitle?: string
  showMroiFooter?: boolean
  isDemoTenant?: boolean
  previousLink?: string
  nextLink?: string
}

export const ProcessCard = ({
  title,
  subtitle,
  bepTitle,
  showRepeatIcon,
  children,
  footerTitle,
  footerSubtitle,
  showMroiFooter,
  isDemoTenant = false,
  previousLink,
  nextLink,
}: PropsWithChildren<Props>) => {
  return (
    <WppCard size="xl" className={styles.container}>
      <Flex direction="column" className={styles.flexContent}>
        <div className={styles.processHeader}>
          <Flex justify="between" align="center">
            <div>
              <Flex gap={2}>
                <WppTypography type="5xl-display" tag="h1" className={styles.processHeaderTitle}>
                  {title}
                </WppTypography>
                {showRepeatIcon && <WppIconRepeatOn color="#3F8EF7" />}
              </Flex>
              {subtitle && (
                <WppTypography type="s-body" tag="p" className={styles.processHeaderSubtitle}>
                  {subtitle}
                </WppTypography>
              )}
            </div>
            {isDemoTenant && (
              <Link to="/nestle/project-example">
                <WppButton className={styles.goToProjectButton}>Go to project</WppButton>
              </Link>
            )}
          </Flex>
        </div>
        {bepTitle && (
          <Flex justify="center" align="center" className={styles.bepSeparator}>
            <WppTypography type="2xs-strong" tag="span" className={styles.bepTitle}>
              {bepTitle}
            </WppTypography>
          </Flex>
        )}
        <div className={styles.processContentWrapper}>
          <div className={styles.processInnerContentWrapper}>
            <Flex justify="between" align="center" className={styles.contentContainer}>
              {children}
            </Flex>
          </div>
        </div>
        <div className={styles.previousNextLinksContainer}>
          <Flex justify="between" align="center">
            <Flex align="center" gap={20}>
              <Link to={previousLink ?? ' '}>
                <WppActionButton disabled={!previousLink} className={styles.previousNextlink}>
                  <WppIconChevron direction="left" color="var(--wpp-grey-color-900)" />
                  Previous phase
                </WppActionButton>
              </Link>
              <Link to={nextLink ?? ' '}>
                <WppActionButton disabled={!nextLink} className={styles.previousNextlink}>
                  Next phase
                  <WppIconChevron direction="right" color="var(--wpp-grey-color-900)" />
                </WppActionButton>
              </Link>
            </Flex>
            <Link to={routesManager.systemUrls.orchestration.root.url}>
              <WppButton className={styles.projectsBtn}>Go to project details</WppButton>
            </Link>
          </Flex>
          {showMroiFooter && (
            <WppTypography type="m-strong" tag="span" className={styles.mroiFooterText}>
              MROI
            </WppTypography>
          )}
        </div>
        {showMroiFooter && <MroiFooterShape />}
        <Flex justify="center" align="center" gap={8} className={styles.processFooter}>
          {footerTitle && (
            <WppTypography type="2xs-strong" tag="span" className={styles.footerFramework}>
              {footerTitle}
            </WppTypography>
          )}
          {footerSubtitle && (
            <WppTypography type="2xs-strong" tag="span" className={styles.footerFrameworkDescription}>
              {footerSubtitle}
            </WppTypography>
          )}
        </Flex>
      </Flex>
    </WppCard>
  )
}
