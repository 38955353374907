import { WppCard, WppIconTick, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/pfizer/ideasCaseProcess/IdeasCaseProcess.module.scss'
import { ProcessCard } from 'pages/customProcess/utils/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const IdeasCaseProcess = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/pfizer/process/business' : '/custom/business'
  const nextLink = isDemoTenant ? '/pfizer/process/media' : '/custom/media'

  const processConfigs: { [key: string]: any; cards: any[]; tools: string[]; connectors?: string[] }[] = [
    {
      align: 'start',
      justify: 'center',
      gap: 45,
      cards: [
        {
          title: 'Customer Insights / Universal Brief',
          content: (
            <>
              <WppTypography type="s-body">
                Allows our teams to leverage data and generate contextual insights to further differentiate our approach
              </WppTypography>
              <WppTypography className={styles.tool} type="s-strong">
                Audience tool®
              </WppTypography>
            </>
          ),
        },
      ],
      tools: ['Audience exploration', 'Growth strategy'],
    },
    {
      align: 'start',
      justify: 'center',
      gap: 45,
      cards: [
        {
          title: 'Comms Strategy',
          content: (
            <>
              <WppTypography type="s-body">
                A framework that enables our teams to GTM, positioning our strategies for success
              </WppTypography>
              <WppTypography className={styles.tool} type="s-strong">
                Audience tool®
              </WppTypography>
            </>
          ),
        },
      ],
      tools: ['Planning principles', 'Budget allocation', 'KPI Setting'],
    },
    {
      align: 'start',
      justify: 'center',
      direction: 'column',
      className: styles.shadowWrapper,
      gap: 45,
      cards: [
        {
          title: 'Creative Platfrom',
          content: (
            <>
              <WppTypography type="s-body">
                Providing to the teams a creative direction for the brand/campaign
              </WppTypography>
              <WppTypography className={styles.tool} type="s-strong">
                Audience tool®
              </WppTypography>
            </>
          ),
        },
        {
          title: 'Campaign Development',
          content: (
            <>
              <WppTypography type="s-body">
                Builds a campaign approach for each audience out teams are marketing toward
              </WppTypography>
              <WppTypography className={styles.tool} type="s-strong">
                Audience tool®
              </WppTypography>
            </>
          ),
        },
      ],
      tools: ['Audience exploration', 'Growth strategy'],
    },
    {
      align: 'start',
      justify: 'center',
      gap: 45,
      cards: [
        {
          title: 'Test & Learn Agenda',
          content: (
            <>
              <WppTypography type="s-body">
                Setting up exposed/control test to measure campaign performance
              </WppTypography>
              <Flex>
                <div className={clsx(styles.miro, styles.appIcon)} />
                <div className={clsx(styles.adobe, styles.appIcon)} />
                <div className={clsx(styles.cross, styles.appIcon)} />
                <div className={clsx(styles.office, styles.appIcon)} />
                <div className={clsx(styles.chart, styles.appIcon)} />
              </Flex>
              <WppTypography className={styles.tool} type="s-strong">
                Audience tool®
              </WppTypography>
            </>
          ),
        },
      ],
      tools: ['Creative pre-testing', 'Hypothesis'],
    },
  ]
  return (
    <ProcessCard
      headerVariant="idea"
      title="Insights and Ideas"
      description="A data-driven framework that elevates relevant, contextual insight pull through into GTM strategy, creative and tactical planning"
      projectLink={isDemoTenant ? '/pfizer/project-example' : ''}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <div className={styles.wrapper}>
        <Flex key="main" justify="between" className={styles.content}>
          {processConfigs.map(({ align, justify, direction, cards, className, gap, tools }, i) => (
            <Flex
              align={align}
              justify={justify}
              direction={direction ?? 'column'}
              gap={gap}
              key={`cards-${i}`}
              className={className}
            >
              <Flex key={`cards-with-shadow-${i}`} direction="column" gap={gap} align="center">
                {cards.map(card => (
                  <WppCard key={card.title + i} className={clsx(styles.card, card?.className)}>
                    <div slot="header" className={styles.header}>
                      <WppTypography type="m-strong">{card.title}</WppTypography>
                    </div>
                    {card.content}
                  </WppCard>
                ))}
                <div
                  key={`shadow-${i}`}
                  className={clsx(styles.shadow, cards.length > 1 ? styles.multiShadow : styles.singleShadow)}
                />
              </Flex>
              <Flex direction="column" key={`tools-${i}`} className={styles.toolsWapper}>
                {tools.map((tool, toolIdx) => (
                  <Flex key={`tool-${i}-${toolIdx}`} gap={5}>
                    <WppIconTick className={styles.toolsIcon} />
                    <WppTypography type="s-strong" className={styles.tools}>
                      {tool}
                    </WppTypography>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </div>
    </ProcessCard>
  )
}

export default IdeasCaseProcess
