import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/perrigo/PerrigoProcess.module.scss'

const processItems = ['audience', 'journey', 'build', 'report']

export const PerrigoProcess = () => {
  const [activeProcess, setActiveProcess] = useState('')

  const checkIsActive = useCallback(
    (process: string) => (activeProcess === process ? styles['process-section--active'] : ''),
    [activeProcess],
  )

  return (
    <Flex align="center" justify="center">
      <div className={styles.mainWrapper}>
        {processItems.map(process => (
          <div
            key={process}
            className={clsx(styles.process, styles[`process--${process}`])}
            onMouseOver={() => setActiveProcess(process)}
            onMouseLeave={() => setActiveProcess('')}
          />
        ))}
        <div className={styles.mainContainer}>
          {processItems.map(process => (
            <div
              key={process}
              className={clsx(styles['process-section'], styles[`process-section--${process}`], checkIsActive(process))}
            />
          ))}
        </div>
      </div>
    </Flex>
  )
}
