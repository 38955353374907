// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inV43{right:0;left:auto}.inV43::part(overlay){opacity:0}.inV43::part(side-modal){border-left:1px solid var(--wpp-grey-color-200)}.uvTaP{padding-bottom:20px}.hB3Tu::part(content){margin-left:0}.T4DJ8{width:100%}.jrkuV::part(overlay){border-radius:100%}.oD7J3 .mx4_z{display:none}.cV64N{width:100%}.cV64N::part(file-upload-container){height:60px}.cV64N::part(icon-file){display:none}.UzUwA{width:50%;max-width:calc(50% - 7.5px)}.tSWn_::part(card){overflow:visible}.GsmqN{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/processBuilder/editProcessModal/EditProcessModal.module.scss"],"names":[],"mappings":"AAAA,OACE,OAAA,CACA,SAAA,CAEA,sBACE,SAAA,CAGF,yBACE,+CAAA,CAIJ,OACE,mBAAA,CAIA,sBACE,aAAA,CAIJ,OACE,UAAA,CAIA,sBACE,kBAAA,CAKF,cACE,YAAA,CAIJ,OACE,UAAA,CAEA,oCACE,WAAA,CAGF,wBACE,YAAA,CAIJ,OACE,SAAA,CACA,2BAAA,CAIA,mBACE,gBAAA,CAIJ,OACE,UAAA","sourcesContent":[".modal {\n  right: 0;\n  left: auto;\n\n  &::part(overlay) {\n    opacity: 0;\n  }\n\n  &::part(side-modal) {\n    border-left: 1px solid var(--wpp-grey-color-200);\n  }\n}\n\n.modalBody {\n  padding-bottom: 20px;\n}\n\n.phaseAccordion {\n  &::part(content) {\n    margin-left: 0;\n  }\n}\n\n.phaseFieldsWrap {\n  width: 100%;\n}\n\n.accordionActionBtn {\n  &::part(overlay) {\n    border-radius: 100%;\n  }\n}\n\n.phaseAutocomplete {\n  .wpp-icon-search {\n    display: none;\n  }\n}\n\n.fileInput {\n  width: 100%;\n\n  &::part(file-upload-container) {\n    height: 60px;\n  }\n\n  &::part(icon-file) {\n    display: none;\n  }\n}\n\n.inputHalfRow {\n  width: 50%;\n  max-width: calc(50% - 15px / 2);\n}\n\n.groupCard {\n  &::part(card) {\n    overflow: visible;\n  }\n}\n\n.colorsInput {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `inV43`,
	"modalBody": `uvTaP`,
	"phaseAccordion": `hB3Tu`,
	"phaseFieldsWrap": `T4DJ8`,
	"accordionActionBtn": `jrkuV`,
	"phaseAutocomplete": `oD7J3`,
	"wpp-icon-search": `mx4_z`,
	"fileInput": `cV64N`,
	"inputHalfRow": `UzUwA`,
	"groupCard": `tSWn_`,
	"colorsInput": `GsmqN`
};
export default ___CSS_LOADER_EXPORT___;
