import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/spotify/processPhases/pages/adapt/Adapt.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/spotify/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/spotify/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/spotify/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'adapt'

const Adapt = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Adapt"
      description="Measuring outcomes and optimizing in real time, while also generating insights to inform the next marketing activity and future business decisions"
      previousLink="/custom/deliver"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Live campaign feeds
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Attribution Partners
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Publisher Data Integration
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Spotify Data Feedback
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Data & Analytics',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Implementation Teams (regional)',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Investment Teams',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Strategy Teams',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Addressable Delivery
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Platform Audience Translator
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                OOH Site Check
              </WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Nexus Growth Platform
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                CoPilot Optimizer
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Earned Media Analyzer
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Clean Room Modules
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                The Scout Network
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Optimization agenda
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Learning Agenda
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Measured Outcomes
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Reporting Data Flow
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Adapt
