// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MlIhR{width:250px;padding:12px 16px 16px;background:hsla(0,0%,100%,.8);border:.5px solid #fff;border-radius:10px;box-shadow:0 20px 30px rgba(65,123,177,.1);-webkit-backdrop-filter:blur(2px);backdrop-filter:blur(2px)}.NKFvF{padding-bottom:8px;color:var(--wpp-grey-color-1000);white-space:pre-wrap}.Mnzi2{margin-top:8px}.wdQhG{width:20px;height:20px;background-color:rgba(0,127,0,.9);border-radius:var(--wpp-border-radius-s)}.aFalg{color:var(--wpp-grey-color-000)}._7vyn{margin-top:8px}.yLMXt::part(typography){font-weight:700;font-size:14px;line-height:16px}.v7dOz{width:20px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/bp/processPhases/contentCards/regularCard/RegularCard.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,sBAAA,CACA,6BAAA,CACA,sBAAA,CACA,kBAAA,CACA,0CAAA,CACA,iCAAA,CAAA,yBAAA,CAGF,OACE,kBAAA,CACA,gCAAA,CACA,oBAAA,CAGF,OACE,cAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iCAAA,CACA,wCAAA,CAGF,OACE,+BAAA,CAGF,OACE,cAAA,CAIA,yBACE,eAAA,CACA,cAAA,CACA,gBAAA,CAIJ,OACE,UAAA","sourcesContent":[".card {\n  width: 250px;\n  padding: 12px 16px 16px;\n  background: rgba(#ffffff, 80%);\n  border: 0.5px solid #ffffff;\n  border-radius: 10px;\n  box-shadow: 0 20px 30px rgba(#417bb1, 10%);\n  backdrop-filter: blur(2px);\n}\n\n.text {\n  padding-bottom: 8px;\n  color: var(--wpp-grey-color-1000);\n  white-space: pre-wrap;\n}\n\n.teamInfo {\n  margin-top: 8px;\n}\n\n.teamTag {\n  width: 20px;\n  height: 20px;\n  background-color: rgba(#007f00, 90%);\n  border-radius: var(--wpp-border-radius-s);\n}\n\n.teamTagText {\n  color: var(--wpp-grey-color-000);\n}\n\n.brandTagOffset {\n  margin-top: 8px;\n}\n\n.brandTag {\n  &::part(typography) {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 16px;\n  }\n}\n\n.brandLogoImg {\n  width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `MlIhR`,
	"text": `NKFvF`,
	"teamInfo": `Mnzi2`,
	"teamTag": `wdQhG`,
	"teamTagText": `aFalg`,
	"brandTagOffset": `_7vyn`,
	"brandTag": `yLMXt`,
	"brandLogoImg": `v7dOz`
};
export default ___CSS_LOADER_EXPORT___;
