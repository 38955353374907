import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Tooltip } from 'pages/customProcess/components/ibmNew/assets/schemeTooltips/performance-tooltip.svg'
import { InputOutputInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/inputOutputInfo/InputOutputInfo'
import { ResponsibilitiesInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/responsibilitiesInfo/ResponsibilitiesInfo'
import { PageLayout } from 'pages/customProcess/components/ibmNew/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/ibmNew/processPhases/pages/performance/Performance.module.scss'
import { Scheme } from 'pages/customProcess/components/ibmNew/processPhases/pages/performance/scheme/Scheme'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

const Performance = () => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleTooltipTriggerEnter = useCallback(
    () => () => {
      setShowTooltip(true)
    },
    [],
  )

  const handleTooltipTriggerLeave = useCallback(() => {
    setShowTooltip(false)
  }, [])

  return (
    <PageLayout
      type={Phases.Performance}
      title="04. Performance"
      description="Performance data is accessible by all the teams for performance optimisation (tactical updates)"
      prevLink="/custom/ibm/executional-details"
      nextLink="/custom/ibm/reconciliation"
    >
      <Scheme onTooltipTriggerEnter={handleTooltipTriggerEnter} onTooltipTriggerLeave={handleTooltipTriggerLeave} />
      <Tooltip className={clsx(styles.tooltip, showTooltip && styles.tooltipVisible)} />
      <Flex gap={16} className={styles.infoWrapper}>
        <ResponsibilitiesInfo width="38.82%" />
        <InputOutputInfo width="61.18%" />
      </Flex>
    </PageLayout>
  )
}

export default Performance
