import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme.png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Uncover = () => {
  return (
    <ProcessCard
      type="strategy"
      title="Uncover Challenge"
      description="What is the biggest change you wish to make?"
      nextLink="/custom/fossil/define"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Uncover
