import { PageLayoutProps } from 'pages/customProcess/components/asml/processPhases/pageLayout/PageLayout'

export const discoverContent: PageLayoutProps = {
  type: 'discover',
  title: 'Discover',
  description: 'Integrated Discovery Phase\nEX+CX = BX',
  activities: [
    'Identify the key challenges with stakeholder interviews and desk research',
    'Audit the current ways of working and benchmarking through value stream mapping',
    'Learnings from Run Model Operation',
    'SWOT Analysis',
  ],
  deliverables: ['Key challenges and gaps', 'Interim conclusions'],
  previousLink: undefined,
  nextLink: '/custom/asml/define',
}

export const defineContent: PageLayoutProps = {
  type: 'define',
  title: 'Define',
  description: 'Run Project Roadmap FY24 prioritization,\nVision & Objective setting',
  activities: [
    'Strategy workshops with Global, Regional, Local',
    'Cross leadership and senior leadership alignment',
    'Define capabilities',
    'Define operating process and tooling',
    'Calculate required capacity',
    'Define KPIs',
    'Identify pilot programs to test new ways of working',
  ],
  deliverables: [
    'Vision and north star',
    'Design principles',
    'Perform and Transform roadmaps',
    'Capability and capacity roadmap',
    'Evolved Operating Model',
    'Governance Model',
    'Performance management system',
    'Technology roadmap',
  ],
  previousLink: '/custom/asml/discover',
  nextLink: '/custom/asml/pilot',
}

export const pilotContent: PageLayoutProps = {
  type: 'pilot',
  title: 'Pilot',
  description:
    'Communications roadmap, Community roadmap, Employee roadmap,\nTalent acquisition roadmap, Digital services roadmap, Operations roadmap',
  activities: [
    'Identify pilot programs to test new ways of working',
    'Design, build, deploy and iterate pilot projects',
    'Onboard and coach stakeholders through the process',
    'Evaluate learnings and refine the operating process',
  ],
  deliverables: [
    'Evaluate learnings and refine the operating process',
    'Capability building and development',
    'Optimised operating process',
  ],
  previousLink: '/custom/asml/define',
  nextLink: '/custom/asml/scale',
}

export const scaleContent: PageLayoutProps = {
  type: 'scale',
  title: 'Scale',
  description: 'Learnings / Optimisations / Implementations,\nRoadmap Development & Pilots',
  activities: ['Scoping, resourcing and setup of scaling activities', 'Incrementally roll out new ways of working'],
  deliverables: ['Deployment plan', 'Change management and capability development'],
  previousLink: '/custom/asml/pilot',
  nextLink: undefined,
}
