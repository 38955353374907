import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/tommy/TommyProcess.module.scss'
import { TommySvg } from 'pages/customProcess/components/tommy/TommySvg'
import { Phases } from 'pages/customProcess/components/tommy/types'

const phaseLinks: Record<Phases, string> = {
  [Phases.Brief]: '/phase/fd7c5bb5-ef47-4df9-ae67-9a9c40a49c6c?back-to-custom&name=PVH_Tommy',
  [Phases.Strategy_Channels]: '/phase/f209b365-9a12-4624-b43c-5b9766eb90b7?back-to-custom&name=PVH_Tommy',
  [Phases.Media_Tactical_Plan]: '/phase/4ea8be83-bce9-4270-bde6-5cc535ce86ab?back-to-custom&name=PVH_Tommy',
  [Phases.Activation]: '/phase/aae91500-fe8a-4228-9d61-8296343ebe60?back-to-custom&name=PVH_Tommy',
  [Phases.Measurement]: '/phase/6b1b22df-5057-4914-8534-e1e6d4d5f0c4?back-to-custom&name=PVH_Tommy',
}

export const TommyProcess = () => {
  const navigate = useNavigate()
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex align="center" justify="center" className={styles.root}>
      <TommySvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
      />
    </Flex>
  )
}
