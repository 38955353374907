// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yZ5dB{margin-top:0}.QVuN3{padding:16px 16px 40px}.ikAke{background:linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.NLxGX{margin-top:-100px}._CcfF{margin-top:56px}.pCrAV{padding:16px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/mediaActivation/MediaActivation.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,sBAAA,CAGF,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iBAAA,CAGF,OACE,eAAA,CAGF,OACE,YAAA","sourcesContent":[".inputCardsWrapper {\n  margin-top: 0;\n}\n\n.inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.brand {\n  background: linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard3Wrapper {\n  margin-top: -100px;\n}\n\n.regularCard4Wrapper {\n  margin-top: 56px;\n}\n\n.outputsCard {\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputCardsWrapper": `yZ5dB`,
	"inputsCard": `QVuN3`,
	"brand": `ikAke`,
	"regularCard3Wrapper": `NLxGX`,
	"regularCard4Wrapper": `_CcfF`,
	"outputsCard": `pCrAV`
};
export default ___CSS_LOADER_EXPORT___;
