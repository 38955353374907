import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    positionBackground: true,
    logoImage: true,
    logoDimensions: true,
    widgetBackgroundImage: true,
    widgetPartBackgroundImage: false,
    headerBackground: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      image: false,
      colors: true,
      innerPageEnabled: true,
      groupTitle: true,
      url: true,
      templateOptions: false,
    },
    groups: false,
    templateOptions: {
      isWidgetBackgroundAsLogo: true,
    },
  },
  colors: {
    page: [
      { title: 'Page background', defaultValue: 'linear-gradient(180deg, #f794a4 0%, #fdd6bd 100%)' },
      { title: 'Widget background', defaultValue: 'rgba(255, 255, 255, .05)' },
      { title: 'Widget logo background', defaultValue: '#fff' },
    ],
    button: [
      { title: 'Background', defaultValue: '#0014cc' },
      { title: 'Text', defaultValue: '#fff' },
    ],
    phase: [
      { title: 'Overlay background', defaultValue: '#0014CC' },
      { title: 'Point', defaultValue: '#03086B' },
      { title: 'Circle title', defaultValue: '#fff' },
      { title: 'Overlay text', defaultValue: '#fff' },
      { title: 'Description text', defaultValue: '#fff' },
      { title: 'Gradient border color', defaultValue: '#0014CC' },
      { title: 'Logo background', defaultValue: '#fafafa' },
      { title: 'Group title', defaultValue: 'red' },
      { title: 'Description background', defaultValue: 'rgba(255, 255, 255, 0)' },
    ],
    group: [],
  },
}
