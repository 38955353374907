import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { FooterNavigation } from 'pages/customProcess/components/sein/processPhases/components/footerNavigation/FooterNavigation'
import styles from 'pages/customProcess/components/sein/processPhases/components/processPhasePageLayout/ProcessPhasePageLayout.module.scss'

interface Props {
  title: string
  description: string
  previousLink?: string
  nextLink?: string
  children: ReactNode
}

export const ProcessPhasePageLayout = ({ title, description, previousLink, nextLink, children }: Props) => {
  return (
    <div className={styles.root}>
      <Flex direction="column" className={styles.card}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <Link to="/custom">
              <WppActionButton variant="inverted" className={styles.backToProcessBtn}>
                <WppIconChevron direction="left" slot="icon-start" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <h1 className={styles.titleSein}>{title}</h1>
            <p className={styles.description}>{description}</p>
          </div>
        </div>
        <div className={styles.mainContentWrapper}>
          <Flex justify="center" className={styles.mainContent}>
            {children}
          </Flex>
          <FooterNavigation previousLink={previousLink} nextLink={nextLink} />
        </div>
      </Flex>
    </div>
  )
}
