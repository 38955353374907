import {
  WppActionButton,
  WppButton,
  WppCard,
  WppIconChevron,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/panera/PaneraProcess.module.scss'
import { routesManager } from 'utils/routesManager'

export const Card = ({
  ...card
}: {
  logo: string
  title: string
  description: string
  content: { count: string; label: string }[]
}) => {
  return (
    <WppCard className={styles.hoverCard}>
      <Flex direction="column" justify="center" align="center" className={clsx(styles.logo, card.logo)}>
        <div className={styles.logoIcon} />
        <WppTypography type="2xl-heading">{card.title}</WppTypography>
      </Flex>
      <Flex direction="column" className={styles.content} gap={24}>
        <Flex gap={24} direction="column" className={styles.notes}>
          <WppTypography type="m-midi">{card.description}</WppTypography>
          <Flex direction="column" className={styles.list} gap={24}>
            {card.content.map(({ count, label }) => (
              <Flex key={count} gap={14} align="center">
                <Flex className={styles.count} justify="center" align="center">
                  <WppTypography type="l-strong">{count}</WppTypography>
                </Flex>
                <WppTypography type="m-strong">{label}</WppTypography>
              </Flex>
            ))}
          </Flex>
        </Flex>
        <WppActionButton className={styles.hoverButton}>Hover to learn more</WppActionButton>
      </Flex>
    </WppCard>
  )
}

export const PaneraProcess = () => {
  const cards = [
    {
      logo: styles.transform,
      title: 'Transform',
      description: 'Audiences for growth',
      content: [
        { count: '01', label: 'Growth Problem' },
        { count: '02', label: 'Journey Planning' },
      ],
    },
    {
      logo: styles.maximize,
      title: 'Maximize',
      description: 'Audience Distribution',
      content: [
        { count: '03', label: 'Cross-channel planning' },
        { count: '04', label: 'Platform planning' },
      ],
    },
    {
      logo: styles.unlock,
      title: 'Unlock',
      description: 'Audience Activation & Insights',
      content: [
        { count: '05', label: 'Activate & optimize' },
        { count: '06', label: 'Measurement & insights' },
      ],
    },
  ]

  return (
    <Flex direction="column" className={styles.wrapper}>
      <Link to="/">
        <WppActionButton className={styles.back}>
          <WppIconChevron color="var(--wpp-brand-color)" className={styles.chevron} /> Back to Homepage
        </WppActionButton>
      </Link>
      <Flex justify="center" align="center" gap={62} className={styles.container}>
        {cards.map(card => (
          <Card {...card} key={card.description} />
        ))}
      </Flex>
      <Flex justify="end" className={styles.project}>
        <Link to={routesManager.systemUrls.orchestration.root.url}>
          <WppButton>Go to project details</WppButton>
        </Link>
      </Flex>
    </Flex>
  )
}
