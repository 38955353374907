import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/tui/TuiProcess.module.scss'
import { TuiSVG } from 'pages/customProcess/components/tui/TuiSvg'
import { Phases } from 'pages/customProcess/components/tui/types'

const phaseLinks: Record<Phases, string> = {
  [Phases.Brief]: '/phase/1d82a965-0845-4e6a-8a7a-97708f8ade80?back-to-custom',
  [Phases.Strategy]: '/phase/431b49ad-80bb-43b1-a4b3-ee16c2f507d5?back-to-custom',
  [Phases.Media]: '/phase/9e319b1f-c202-40f7-a29f-07f656b97b80?back-to-custom',
  [Phases.Reporting]: '/phase/2e8621d3-3af1-459c-8d62-859fab68113f?back-to-custom',
  [Phases.Competitive]: '/phase/d74d83a0-dc47-4ae8-aed3-a730318c5476?back-to-custom',
}

export const TuiProcess = () => {
  const navigate = useNavigate()
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex align="center" justify="center" className={styles.root}>
      <TuiSVG
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
      />
    </Flex>
  )
}
