import { Routes, Route } from 'react-router-dom'

import Activate from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pages/Activate'
import Adapt from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pages/Adapt'
import Ambition from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pages/Ambition'
import Approach from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pages/Approach'
import AssetsAndIdeas from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pages/AssetsAndIdeas'
import Audience from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pages/Audience'

export const MediaMarktSaturnRoutes = () => (
  <Routes>
    <Route path="adapt" element={<Adapt />} />
    <Route path="ambition" element={<Ambition />} />
    <Route path="audience" element={<Audience />} />
    <Route path="approach" element={<Approach />} />
    <Route path="assets-and-ideas" element={<AssetsAndIdeas />} />
    <Route path="activate" element={<Activate />} />
  </Routes>
)
