import audienceAnalysisImgSrc from 'pages/customProcess/components/ikeaNew/assets/audience-analysis.png'
import channelAllocationImgSrc from 'pages/customProcess/components/ikeaNew/assets/channel-allocation.png'
import executionImgSrc from 'pages/customProcess/components/ikeaNew/assets/execution.png'
import opportunityImgSrc from 'pages/customProcess/components/ikeaNew/assets/opportunity.png'
import reportingImgSrc from 'pages/customProcess/components/ikeaNew/assets/reporting.png'
import strategyImgSrc from 'pages/customProcess/components/ikeaNew/assets/strategy.png'
import { Phases } from 'pages/customProcess/components/ikeaNew/types'

export const IkeaProcessData = {
  title: 'Accelerated Growth Planning Process',
  subtitle: 'Building desire through multi-platform, co-ordinated, integrated and distinctive comms experiences',
  phases: [
    [
      {
        phaseId: Phases.OpportunityDefinition,
        title: 'Opportunity Definition',
        description:
          'Clearly outlining the key challenge and opportunity for communications and the role media will play.',
        img: opportunityImgSrc,
        color: '#396321',
        pills: ['Situational Analysis Checklist', 'MOT (Media Optimisation Tool)'],
        moreInfo: {
          considerations: [
            "The client led 'Start' and 'Set The Brief' sessions will be used as input for us to generate a clear point of view on the role media should play in delivering stated business objectives.",
            'An additional key input to this stage will be a Local Situational Analysis – this should be worked on by both clients and media agency teams. Clients will be aligning and collaborating with other parts of the IKEA organization to undertake a situational analysis and they will brief media agency teams to fill gaps and provide supplementary analysis.',
            'Use the global situation analysis framework of Category, Consumer, Brand Health and Comms Insights as a foundation for identifying your opportunity definition. Focus on what is changing and how this may be an opportunity for IKEA.',
            'A clearly identified and agreed Opportunity Definition can be a key component of your overall strategy.',
          ],
          outcomes: ['Completing a Get / To / By framework may help in articulating your Opportunity Definition.'],
        },
      },
    ],
    [
      {
        phaseId: Phases.AudienceAnalysis,
        title: 'Audience Analysis',
        description: 'Define approach to audiences and key data and insight opportunities to address effectively.',
        img: audienceAnalysisImgSrc,
        color: '#0058AB',
        pills: ['Architect Audience'],
        moreInfo: {
          considerations: [
            'We should aim to clearly identify the audiences we feel can deliver against the briefed business and communication goals and the Opportunity Definition developed in the previous planning stage.',
            "A key element of this should be profiling audiences through the use of GroupM's Audience Origin data. We should look to support and supplement with appropriate data analysis from other sources and further profile target audiences. Consider broad cultural dynamics that may impact audiences and how they may be leveraged for communications impact.",
            'Audience behaviour, attitudes and preferences should be reviewed, and relevant insights be used to underpin our proposed overall communications solution.',
            'Profiling growth audiences via Audience Origin will allow for the use of Architect and therefore a data driven point of view on the optimal channel selection.',
          ],
          outcomes: [
            'A clear profile, sizing of the identified audience that will target.',
            'A rationale as to why the audience is appropriate and the insights that will power our comms response.',
          ],
        },
      },
      {
        phaseId: Phases.StrategicAndMeasurementSolutions,
        title: 'Strategy &\nMeasurement Solutions',
        link: '/custom/strategy-measurement-solutions',
        description:
          'Propose how the communication solution will work, the assets to build and the framework to use for evaluating the impact and effectiveness of activity.',
        img: strategyImgSrc,
        color: '#0058AB',
        pills: ["IKEA's Media Framework"],
        moreInfo: {
          considerations: [
            'At this stage we develop a strategic response to the brief we have been given – either at an annual level or campaign level.',
            'Build your response around the 10 key questions outlined in the "Set The Strategy" module of the annual planning process.',
            'Consider and apply both IKEA’s Media Framework and any central IKEA planning guidance / econometric output + insight.',
            'Ensured you have considered the full range of touchpoint, partner and content opportunities.',
            'A point of view on messaging + messaging/moment combinations is best practice.',
            'Strategic responses should propose a clear and quantifiable measurement approach.',
          ],
          outcomes: [
            'A strategy presentation that follows the executive summary format from the annual planning process',
          ],
        },
      },
    ],
    [
      {
        phaseId: Phases.ChannelAllocation,
        title: 'Channel Allocation',
        description: 'Define approach to audiences and key data and insight opportunities to address effectively.',
        img: channelAllocationImgSrc,
        color: '#0058AB',
        pills: ['Architect Campaign Creator'],
        moreInfo: {
          considerations: [
            'Using a combination of MOT and/or Architect we recommend an optimal investment level for each touchpoint and channel given the proposed strategy.',
            'In general, MOT is more effective for top-line channel allocation at an annual budget planning level. Architect is best used to create allocations at a campaign level.',
            'Architect allocations can be created using the Audience identified and profiled in Stage 2 (Audience Analysis).',
            'Inputs from both can be reviewed and cross-referenced before proposing a final recommendation.',
            'The full channel allocation recommendation should include a comprehensive channel and tactics laydown incorporating budgets, timings, formats and required assets.',
          ],
          outcomes: [
            'An optimised, data-driven, channel allocation in support of strategy recommendation developed at Stage 3.',
          ],
        },
      },
    ],
    [
      {
        phaseId: Phases.ExecutionPlan,
        title: 'Leverage assets and activate',
        description: 'In-channel implementation detail and tactics including ‘built for’ platform ideas',
        img: executionImgSrc,
        color: '#DEBD9C',
        pills: ['Architect Audience Translator', 'Taxonomy'],
        moreInfo: {
          considerations: [
            'In support of the optimised channel allocation recommended in the previous stage the execution plan should be a comprehensive channel and tactics laydown incorporating budgets, timings, formats, required assets and any relevant execution detail.',
            'Execution tools – such as AV planning optimiser mScreen or Audience Translator (which accurately replicates Audience Origin profiles across digital platforms) should be used and their output clearly explained.',
            'It is important to adhere with the INGKA Taxonomy and naming conventions.',
          ],
          outcomes: ['Detailed media schedules, laydown and supporting content.'],
        },
      },
    ],
    [
      {
        phaseId: Phases.Reporting,
        title: 'Reporting, Optimisation &\nMeasurement',
        description:
          'How to adapt targeting and messaging based on response and how will results inform better future planning.',
        img: reportingImgSrc,
        color: '#FBD915',
        pills: ['Measurement Frameworks & Guideline'],
        moreInfo: {
          considerations: [
            'At this final stage we ensure we have a robust reporting infrastructure in place that communicates effectively to the IKEA clients the media plan as it is executed and goes live.',
            'Where a reporting tech infrastructure is in place, we should clarify any requirements to ensure the smooth onboarding of the media plans and all media data to the reporting dashboard.',
            'We should also outline any optimisation tactics and techniques that we believe will be appropriate for our campaign. The cadence and regularity of optimisation initiatives should be made clear as should the metrics against which we believe optimisation will be most impactful given the overall strategy and individual channel roles.',
            'We should propose a clear measurement plan for any activity proposed and this plan should go beyond media outputs and investment levels to ascertain the real outcome impact of the media activity.',
          ],
          outcomes: [
            'A clear measurement and optimisation plan.',
            'Smooth alignment with reporting tech or a clear approach to reporting (what + when + how reports will be produced and distributed).',
          ],
        },
      },
    ],
  ],
}
