import decideSchemeImgUrl from 'pages/customProcess/components/tigerBrands/assets/phaseDetails/decide.png'
import { PageLayout } from 'pages/customProcess/components/tigerBrands/processPhases/pageLayout/PageLayout'
import { processPhasesData } from 'pages/customProcess/components/tigerBrands/TigerBrandsConfig'

const Decide = () => {
  const { title, description, color, previousLink, nextLink } = processPhasesData[1]

  return (
    <PageLayout
      title={title}
      description={description}
      color={color}
      schemeSrc={decideSchemeImgUrl}
      previousLink={previousLink}
      nextLink={nextLink}
    />
  )
}

export default Decide
