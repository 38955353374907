import brandLogoImgUrl from 'pages/customProcess/components/bp/assets/brand-logo.png'
import { InputsOutputsCard } from 'pages/customProcess/components/bp/processPhases/contentCards/inputsOutputsCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/bp/processPhases/contentCards/regularCard/RegularCard'
import { PageLayout } from 'pages/customProcess/components/bp/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/bp/processPhases/pages/assembleBP/AssembleBP.module.scss'

export const AssembleBP = () => {
  return (
    <PageLayout title="Assemble" bepTitle="BEP X" footerTitle="BP Framework">
      <>
        <InputsOutputsCard
          title="inputs"
          text="Brief, data, assets, tools set and the pool of the team members who can be assigned to the campaign delivery"
        />
        <div className={styles.regularCard1}>
          <RegularCard
            text="The system identifies the right combination of the data, assets, tools and people and connects them in a common workspace"
            teamTags={[
              { text: 'WPP Xbp team', tags: ['I'] },
              { text: 'Bp Marketing team', tags: ['A', 'R'] },
            ]}
            brandTags={[
              { text: 'People Matching AI', logoImgUrl: brandLogoImgUrl },
              { text: 'AI Assistant', logoImgUrl: brandLogoImgUrl },
            ]}
          />
        </div>
        <div className={styles.regularCard2}>
          <RegularCard
            text="Assign right people to the tasks and ensure the close collaboration between Xbp team and bp marketing team"
            teamTags={[
              { text: 'WPP Xbp team', tags: ['A', 'R'] },
              { text: 'Bp Marketing team', tags: ['I'] },
            ]}
            brandTags={[
              { text: 'Orchestration System', logoImgUrl: brandLogoImgUrl },
              { text: 'AI Assistant', logoImgUrl: brandLogoImgUrl },
            ]}
          />
        </div>
        <InputsOutputsCard
          title="outputs"
          text="Work break-down structure for the campaign is defined, deadlines and responsible people are asigned"
        />
      </>
    </PageLayout>
  )
}
