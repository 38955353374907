import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/ferreroNew/processPhases/pages/insight/Insight.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/ferreroNew/processPhases/processCard/ProcessCard'

const Insight = () => {
  return (
    <ProcessCard
      title="Insight"
      description={
        'Identify and validate the core insights that will drive the strategic choices needed to\ndefine the underlying problem, growth strategy and creative brief.\nCultural. Behavioral. Brand.'
      }
      previousLink="/custom/ferrero/audience"
      nextLink="/custom/ferrero/context"
    >
      <DescriptionCard icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Brand conversation versus\nconsumer WOM about the\nbrand,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Cultural forces, cultural trends\nof our current & potential\naudience,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Audience values and self-perceptions,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Current buyers demographics\nand behaviors,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Audience behaviors and drivers.
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Audience relationship with the\ncategory.'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Activitites
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Growth modeling/strategy,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Insight identification and\nvalidation.'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="tools">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                GWI,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                BAV,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Quid,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Brandwatch,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                Relative Insights
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                AYTM
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="people">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Teams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Led by strategy. Primary =\nPlanning + Data Strategy. '}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Engagement Strategy\nsupporting.'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Acct and creative\ninvolvement/review'}
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'Client approved: Underlying\ntension/problem,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                growth strategy,
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                {'meaningful solution/\nopportunity,'}
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.text}>
                creative brief
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Insight
