import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import pageLogo from 'pages/customProcess/components/unilever/assets/pageLogo.png'
import styles from 'pages/customProcess/components/unilever/processPhases/processCard/ProcessCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  type: 'challenge' | 'solution' | 'delivery'
  title: string
  description: string
  previousLink?: string
  nextLink?: string
  classes?: {
    controls?: string
  }
}

export const ProcessCard = ({ children, type, title, description, previousLink, nextLink, classes }: Props) => {
  return (
    <>
      <div className={clsx([styles.cutomHeaderr])}>
        <Flex
          direction="column"
          className={clsx(styles.headerContentWrap, {
            [styles.challenge]: type === 'challenge',
            [styles.solution]: type === 'solution',
            [styles.delivery]: type === 'delivery',
          })}
        >
          <Flex justify="between" align="start" className={styles.headerContenttt} gap={20}>
            <div className={styles.headerContentLeftt}>
              <Link to={routesManager.customProcess.url} className={styles.backBtn}>
                <WppActionButton variant="inverted">
                  <WppIconChevron direction="left" slot="icon-start" className={styles['left-chevron']} />
                  <p className={styles['back-process-text']}>Back to Process overview</p>
                </WppActionButton>
              </Link>
              <WppTypography type="4xl-display" className={styles.titleAudible}>
                {title}
              </WppTypography>
              <WppTypography type="m-body" className={styles.topDescription}>
                {description}
              </WppTypography>
            </div>
            <img src={pageLogo} alt="" className={styles.logo} />
          </Flex>
          <Flex className={styles.type} align="center" justify="center">
            <WppTypography className={styles.typeTitle} type="2xs-strong">
              {type}
            </WppTypography>
          </Flex>
        </Flex>
      </div>
      <div className={styles.mainContentWrapperr}>
        <div className={styles.mainContentAudible}>
          <div className={clsx([styles.cardAudible])}>
            <Flex direction="column" justify="between" align="start" gap={24} className={styles.cardContentAudible}>
              <div className={styles.flexGrow}>
                <Flex align="center" className={styles.descriptionCardsWrapperAudible}>
                  <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInner}>
                    {children}
                  </Flex>
                </Flex>
              </div>
              <Flex align="center" justify="between" className={clsx(classes?.controls, styles.controlsAudible)}>
                <Flex gap={16}>
                  <Link to={previousLink ?? ''}>
                    <WppActionButton disabled={!previousLink} className={styles.navBtn}>
                      <WppIconChevron color="#015FFF" direction="left" />
                      Previous phase
                    </WppActionButton>
                  </Link>
                  <Link to={nextLink ?? ''}>
                    <WppActionButton disabled={!nextLink} className={styles.navBtn}>
                      Next phase
                      <WppIconChevron color="#015FFF" />
                    </WppActionButton>
                  </Link>
                </Flex>
                <Link to={routesManager.systemUrls.orchestration.root.url}>
                  <WppButton className={styles.projectBtnAudible}>Go to project</WppButton>
                </Link>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </>
  )
}
