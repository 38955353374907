import {
  WppActionButton,
  WppIconChevron,
  WppTypography,
  WppButton,
  WppIconArrow,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as LogoSvg } from 'pages/customProcess/components/lego/assets/lego-logo.svg'
import styles from 'pages/customProcess/components/lego/processPhases/pageLayout/PageLayout.module.scss'
import { Phases } from 'pages/customProcess/components/lego/types'
import { routesManager } from 'utils/routesManager'

export interface PageLayoutProps {
  type: Phases
  title: string
  description: string
  headerIllustrationImgSrc: string
  schemeImgSrc: string
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({
  type,
  title,
  description,
  headerIllustrationImgSrc,
  schemeImgSrc,
  previousLink,
  nextLink,
}: PageLayoutProps) => {
  const headerBgStyle = useMemo(() => {
    switch (type) {
      case Phases.Discover:
        return styles.discoverHeaderBg
      case Phases.Plan:
        return styles.planHeaderBg
      case Phases.Optimise:
        return styles.optimiseHeaderBg
      case Phases.Measure:
        return styles.measureHeaderBg
      default:
        return styles.discoverHeaderBg
    }
  }, [type])

  return (
    <div className={styles.root}>
      <Flex direction="column" justify="between" className={styles.page}>
        <Flex justify="between" align="center" className={clsx(styles.header, headerBgStyle)}>
          <div className={styles.headerContent}>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn} variant="inverted">
                <WppIconChevron direction="left" slot="icon-start" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <div>
              <WppTypography type="5xl-display" className={styles.title}>
                {title}
              </WppTypography>
            </div>
            <WppTypography type="2xl-heading" className={styles.description}>
              {description}
            </WppTypography>
          </div>
          <Flex>
            <img src={headerIllustrationImgSrc} alt="" />
            <LogoSvg width={159} />
          </Flex>
        </Flex>
        <div className={styles.contentWrapper}>
          <Flex direction="column" justify="between" gap={24} className={styles.content}>
            <img src={schemeImgSrc} alt="" />
            <Flex align="center" justify="between">
              <Flex gap={16}>
                <Link to={previousLink ?? ''}>
                  <WppActionButton
                    className={clsx(styles.navButton, !previousLink && styles.navButtonDisabled)}
                    variant="secondary"
                    disabled={!previousLink}
                  >
                    <Flex align="center" gap={4}>
                      <WppIconArrow slot="icon-end" direction="left" color="#479E46" />
                      Previous phase
                    </Flex>
                  </WppActionButton>
                </Link>
                <Link to={nextLink ?? ''}>
                  <WppActionButton
                    className={clsx(styles.navButton, !nextLink && styles.navButtonDisabled)}
                    variant="secondary"
                    disabled={!nextLink}
                  >
                    <Flex align="center" gap={4}>
                      Next phase
                      <WppIconArrow slot="icon-end" color="#479E46" />
                    </Flex>
                  </WppActionButton>
                </Link>
              </Flex>
              <Link to={routesManager.systemUrls.orchestration.root.url}>
                <WppButton className={styles.projectButton}>Go to project details</WppButton>
              </Link>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </div>
  )
}
