import { PhasePoints } from 'pages/customProcess/components/allianzNew/types'

export const links = {
  [PhasePoints.Adapt]: '/phase/378e678d-08ad-4e63-b915-b423f9b24376?back-to-custom',
  [PhasePoints.Ambition]: '/phase/143ba266-3fbb-4a24-9138-6652789c5e86?back-to-custom',
  [PhasePoints.Audience]: '/phase/7930dba2-6626-4ac3-ae9e-388f1a1035ed?back-to-custom',
  [PhasePoints.Approach]: '/phase/7841e309-2307-4afd-b741-2ee820d1619f?back-to-custom',
  [PhasePoints.Assets]: '/phase/ab1239db-4bc9-4e45-8d79-c20ae13201f9?back-to-custom',
  [PhasePoints.Activate]: '/phase/8a5411f8-c309-450c-9aaa-8d55ce6af1df?back-to-custom',
}
