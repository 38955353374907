import styles from 'pages/customProcess/components/nestleChina/processPhases/processCard/mroiFooterShape/MroiFooterShape.module.scss'

export const MroiFooterShape = () => (
  <svg
    className={styles.mroiShape}
    width="1364"
    height="77"
    viewBox="0 0 1364 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 77S184.235 0 682 0c497.77 0 682 77 682 77H0Z" fill="url(#mroi-fill)" />
    <defs>
      <linearGradient id="mroi-fill" x1="274.5" y1="0" x2="647.782" y2="480.769" gradientUnits="userSpaceOnUse">
        <stop stopColor="#2DBFFC" stopOpacity=".2" />
        <stop offset="1" stopColor="#03F0CA" stopOpacity=".2" />
      </linearGradient>
    </defs>
  </svg>
)
