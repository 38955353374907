import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  type: 'strategy' | 'planning' | 'activation'
  title: string
  description: string
  previousLink?: string
  nextLink?: string
  classes?: {
    controls?: string
  }
}

export const ProcessCard = ({ children, type, title, description, previousLink, nextLink, classes }: Props) => {
  return (
    <>
      <div className={clsx([styles.cutomHeaderr])}>
        <Flex
          justify="between"
          gap={24}
          align="start"
          className={clsx(styles.headerContenFossil, {
            [styles.strategy]: type === 'strategy',
            [styles.planning]: type === 'planning',
            [styles.activation]: type === 'activation',
          })}
        >
          <div className={styles.headerContentLeftt}>
            <Link to="/custom" className={styles.backBtn}>
              <WppActionButton variant="inverted">
                <WppIconChevron direction="left" slot="icon-start" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <WppTypography type="4xl-display" className={styles.titleFossil}>
              {title}
            </WppTypography>
            <WppTypography type="s-body" className={styles.whiteText}>
              {description}
            </WppTypography>
          </div>
          <WppTypography type="2xs-strong" className={styles.type}>
            {type}
          </WppTypography>
        </Flex>
      </div>
      <div className={styles.mainContentWrapperr}>
        <div className={styles.mainContentFossil}>
          <div className={clsx([styles.cardFossil])}>
            <Flex direction="column" justify="between" align="start" gap={24} className={styles.cardContentFossil}>
              <div className={styles.flexGrow}>
                <Flex align="center" className={styles.descriptionCardsWrapperFossil}>
                  <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInner}>
                    {children}
                  </Flex>
                </Flex>
              </div>
              <Flex align="center" justify="between" className={clsx(classes?.controls, styles.controlsFossil)}>
                <Flex gap={16}>
                  <Link to={previousLink ?? ''}>
                    <WppActionButton disabled={!previousLink} className={styles.navBtn}>
                      <WppIconChevron color="#201E4F" direction="left" />
                      Previous phase
                    </WppActionButton>
                  </Link>
                  <Link to={nextLink ?? ''}>
                    <WppActionButton disabled={!nextLink} className={styles.navBtn}>
                      Next phase
                      <WppIconChevron color="#201E4F" />
                    </WppActionButton>
                  </Link>
                </Flex>
                <Link to={routesManager.systemUrls.orchestration.root.url}>
                  <WppButton className={styles.projectBtnFossil}>Go to project</WppButton>
                </Link>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </>
  )
}
