import {
  WppActionButton,
  WppIconChevron,
  WppTypography,
  WppIconLogout,
  WppIconUnorderedList,
  WppIconDesign,
  WppIconTaskList,
  WppButton,
} from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import phasePageBgImgSrc from 'pages/customProcess/components/henkel/assets/phase-page-bg.jpg'
import wppLogoImgSrc from 'pages/customProcess/components/henkel/assets/wpp-logo.png'
import styles from 'pages/customProcess/components/henkel/processPhases/pageLayout/PageLayout.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  title: string
  description: string
  inputsChildren?: React.ReactNode
  activitiesChildren?: React.ReactNode
  outcomesChildren?: React.ReactNode
  toolsChildren?: React.ReactNode
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({
  title,
  description,
  inputsChildren,
  activitiesChildren,
  outcomesChildren,
  toolsChildren,
  previousLink,
  nextLink,
}: Props) => {
  return (
    <div className={styles.root}>
      <img className={styles.bgImg} src={phasePageBgImgSrc} alt="" />
      <img className={styles.wppLogo} src={wppLogoImgSrc} alt="" />
      <Flex direction="column" className={styles.content}>
        <div className={styles.header}>
          <div>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn}>
                <Flex gap={4} align="center">
                  <WppIconChevron slot="icon-start" direction="left" color="#505050" />
                  <div>Back to Process overview</div>
                </Flex>
              </WppActionButton>
            </Link>
          </div>
          <WppTypography tag="h1" className={styles.title}>
            {title}
          </WppTypography>
          <div>
            <WppTypography className={styles.description}>{description}</WppTypography>
          </div>
        </div>
        <Flex direction="column" justify="between" gap={24} className={styles.mainContent}>
          <Flex>
            <div className={styles.column}>
              <Flex justify="center" align="center" className={styles.iconContainer}>
                <WppIconLogout width={42} color="#505050" className={styles.inputsIcon} />
              </Flex>
              <WppTypography tag="h2" className={styles.columnTitle}>
                Inputs
              </WppTypography>
              {inputsChildren}
            </div>
            <div className={styles.column}>
              <Flex justify="center" align="center" className={styles.iconContainer}>
                <WppIconUnorderedList width={42} color="#505050" />
              </Flex>
              <WppTypography tag="h2" className={styles.columnTitle}>
                Activitites
              </WppTypography>
              {activitiesChildren}
            </div>
            <div className={styles.column}>
              <Flex justify="center" align="center" className={styles.iconContainer}>
                <WppIconDesign width={42} color="#505050" />
              </Flex>
              <WppTypography tag="h2" className={styles.columnTitle}>
                Tools
              </WppTypography>
              {toolsChildren}
            </div>
            <div className={styles.column}>
              <Flex justify="center" align="center" className={styles.iconContainer}>
                <WppIconTaskList width={42} color="#505050" />
              </Flex>
              <WppTypography tag="h2" className={styles.columnTitle}>
                Outcomes
              </WppTypography>
              {outcomesChildren}
            </div>
          </Flex>
          <Flex justify="between" align="center" className={styles.footer}>
            <Flex gap={16}>
              <Link to={previousLink ?? ''}>
                <WppActionButton disabled={!previousLink} className={styles.navButton}>
                  <WppIconChevron direction="left" color="#505050" className={styles.prevNavBtnIcon} />
                  Previous phase
                </WppActionButton>
              </Link>
              <Link to={nextLink ?? ''}>
                <WppActionButton disabled={!nextLink} className={styles.navButton}>
                  Next phase <WppIconChevron color="#505050" className={styles.nextNavBtnIcon} />
                </WppActionButton>
              </Link>
            </Flex>
            <Link to={routesManager.systemUrls.orchestration.root.url}>
              <WppButton className={styles.goToBtn}>Go to project details</WppButton>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
