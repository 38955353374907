import { MayBeNull } from '@wpp-open/core'

import styles from 'pages/customProcess/components/ferreroNew/FerreroProcess.module.scss'
import { PhasePoints } from 'pages/customProcess/components/ferreroNew/types'

interface Props {
  activePoint: MayBeNull<PhasePoints>
}

export const PhasePointsChevronsSvg = ({ activePoint }: Props) => (
  <svg
    width={734}
    height={580}
    viewBox="0 0 734 580"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.phasePointsChevronsSvg}
  >
    {activePoint === PhasePoints.Engagement && (
      <path
        d="M156 539.129L161 533.564L156 528"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(-10, 8)"
      />
    )}
    {activePoint === PhasePoints.Analysis && (
      <path
        d="M1 235.129L6 229.564L1 224"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(-19, -1)"
      />
    )}
    {activePoint === PhasePoints.Context && (
      <path
        d="M596 539.129L601 533.564L596 528"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(-2, 8)"
      />
    )}
    {activePoint === PhasePoints.Occasion && (
      <path
        d="M394 579.129L399 573.564L394 568"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(-16, 9)"
      />
    )}
    {activePoint === PhasePoints.Action && (
      <path
        d="M76 11.9447L81 6.37901L76 0.815369"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(-11, -4)"
      />
    )}
    {activePoint === PhasePoints.Insight && (
      <path
        d="M728 235.129L733 229.564L728 224"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(0, 1)"
      />
    )}
    {activePoint === PhasePoints.Challenge && (
      <path
        d="M641 12.1293L646 6.56358L641 0.999939"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(0, -1)"
      />
    )}
    {activePoint === PhasePoints.Audience && (
      <path
        d="M716 108.129L721 102.564L716 96.9999"
        stroke="#936C3F"
        strokeWidth={2}
        strokeLinejoin="round"
        transform="translate(0, -1)"
      />
    )}
  </svg>
)
