import { PageLayout } from 'pages/customProcess/components/henkel/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/henkel/processPhases/pages/assets/Assets.module.scss'

const Assets = () => {
  return (
    <PageLayout
      title="Assets"
      description={'Finalising channels, their roles and inventive\nideas/tactics to bring the strategy to life.'}
      previousLink="/custom/henkel/approach"
      nextLink="/custom/henkel/activate"
      inputsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Strategic direction</li>
            <li>Brand proposition</li>
            <li>Creative route</li>
            <li>Comms architecture</li>
          </ul>
        </div>
      }
      activitiesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Media, channel plan and\ncampaign details'}</li>
          </ul>
        </div>
      }
      toolsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Empathetic execution</li>
            <li>Smartly</li>
            <li>Trendexplorer</li>
          </ul>
        </div>
      }
      outcomesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Creative meida activation,\ncommunications plan and\nchannel approaches'}</li>
          </ul>
        </div>
      }
    />
  )
}

export default Assets
