// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hCGk2{position:relative;background-repeat:no-repeat;background-position:center;background-size:cover;transition:margin .5s ease}.hCGk2.u_zPf{margin-right:600px}.YUkBe{position:absolute;z-index:-1;width:100%;height:100%}.VbYm4{position:absolute;bottom:50px;left:50px;z-index:9}.O1gLf{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}`, "",{"version":3,"sources":["webpack://./src/pages/processBuilder/ProcessBuilder.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,2BAAA,CACA,0BAAA,CACA,qBAAA,CACA,0BAAA,CAEA,aACE,kBAAA,CAIJ,OACE,iBAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CAGF,OACE,iBAAA,CACA,WAAA,CACA,SAAA,CACA,SAAA,CAGF,OACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA","sourcesContent":[".root {\n  position: relative;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  transition: margin 0.5s ease;\n\n  &.editMode {\n    margin-right: 600px;\n  }\n}\n\n.bgColor {\n  position: absolute;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n}\n\n.editBtn {\n  position: absolute;\n  bottom: 50px;\n  left: 50px;\n  z-index: 9;\n}\n\n.loader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `hCGk2`,
	"editMode": `u_zPf`,
	"bgColor": `YUkBe`,
	"editBtn": `VbYm4`,
	"loader": `O1gLf`
};
export default ___CSS_LOADER_EXPORT___;
