// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YQ7CC{width:44px;min-width:44px;height:100%;background-color:#fff;border-right:1px solid #012d9c;border-left:1px solid #012d9c}.hizVH{margin-top:30px}.iOzmg{margin-top:70px;transform:rotate(180deg)}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibmNew/components/phaseDivider/PhaseDivider.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,cAAA,CACA,WAAA,CACA,qBAAA,CACA,8BAAA,CACA,6BAAA,CAGF,OACE,eAAA,CAGF,OACE,eAAA,CACA,wBAAA","sourcesContent":[".phaseDivider {\n  width: 44px;\n  min-width: 44px;\n  height: 100%;\n  background-color: white;\n  border-right: 1px solid #012d9c;\n  border-left: 1px solid #012d9c;\n}\n\n.arrow1 {\n  margin-top: 30px;\n}\n\n.arrow2 {\n  margin-top: 70px;\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phaseDivider": `YQ7CC`,
	"arrow1": `hizVH`,
	"arrow2": `iOzmg`
};
export default ___CSS_LOADER_EXPORT___;
