import { WppButton } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/fordMotorChina/FordMotorChinaProcess.module.scss'
import { FordMotorChinaProcessSvg } from 'pages/customProcess/components/fordMotorChina/FordMotorChinaProcessSvg'
import { Phases } from 'pages/customProcess/components/fordMotorChina/types'
import { routesManager } from 'utils/routesManager'

export const FordMotorChinaProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <div className={styles.root}>
      <Link to={routesManager.systemUrls.orchestration.root.url} className={styles.projectsButtonWrapper}>
        <WppButton className={styles.projectsButton}>Go to project details</WppButton>
      </Link>
      <Flex align="center" justify="center" className={styles.processSchemeContainer}>
        <FordMotorChinaProcessSvg
          activePhase={activePhase}
          onPhaseEnter={handlePhaseEnter}
          onPhaseLeave={handlePhaseLeave}
        />
      </Flex>
    </div>
  )
}
