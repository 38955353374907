import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import architectLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/architect-logo.svg'
import audienceOriginLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/audience-origin-logo.svg'
import synapseLogoImgUrl from 'pages/customProcess/components/nestleAsia/assets/synapse-mtm-logo.svg'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/contactPointsAndInvestmentPlanning/ContactPointsAndInvestmentPlanning.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const ContactPointsAndInvestmentPlanning = () => {
  const previousLink = '/custom/campaign-strategic-approach'
  const nextLink = '/custom/activation-planning'

  return (
    <ProcessCard
      title="Contact Points and Investment Planning"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputsOutputsCard title="inputs" text="Campaign Approach is developed" cardStyles={styles.inputsCard} />
          <svg width="6" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 0 2.887 5H.113L3 0Zm.5 4.5V21h-1V4.5h1Z" fill="#307DE4" />
          </svg>
          <div className={styles.inputBottomExtraCard}>
            <WppTypography type="m-midi">Post Campaign Insights</WppTypography>
          </div>
        </Flex>
        <div className={styles.regularCardWrapper1}>
          <RegularCard
            text="Media Budget Setting"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Architect®', style: styles.architectTagColor, logoImgUrl: architectLogoImgUrl }]}
            bottomHighlightText="Media Budget is set"
          />
        </div>
        <Flex direction="column" align="center" gap={24} className={styles.regularCardWrapper2}>
          <RegularCard
            text="Scenario Planning"
            teamTags={[{ text: 'Media Teams', tags: ['R', 'A'] }]}
            brandTags={[
              { text: 'Data Ethics Compass®', style: styles.architectTagColor, logoImgUrl: audienceOriginLogoImgUrl },
            ]}
          />
          <RegularCard
            text="Responsible Investments"
            teamTags={[{ text: 'Media Teams', tags: ['R', 'A'] }]}
            brandTags={[
              { text: 'Data Ethics Compass®', style: styles.architectTagColor },
              { text: 'Carbon Calculator®', style: styles.architectTagColor },
              { text: 'Horizon®', extraText: '(AU only)', style: styles.architectTagColor },
            ]}
          />
          <RegularCard
            text="Analytics Modelling"
            brandTags={[{ text: 'Synapse MMM®', style: styles.synapseTagColor, logoImgUrl: synapseLogoImgUrl }]}
          />
        </Flex>
        <InputsOutputsCard
          title="outputs"
          text="Foundation for the Activation Planning"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default ContactPointsAndInvestmentPlanning
