import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleChina/processPhases/pages/strategicMediaPlanning/StrategicMediaPlanning.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleChina/processPhases/processCard/ProcessCard'
import ArchitectIcon from 'pages/customProcess/components/nestleChina/processScheme/assets/ArchitectIcon.svg'
import SynapseIcon from 'pages/customProcess/components/nestleChina/processScheme/assets/SynapseIcon.svg'

interface Props {
  isDemoTenant?: boolean
}

const StrategicMediaPlanning = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/campaign-budget-setting' : '/custom/ch/campaign-budget-setting'
  const nextLink = isDemoTenant ? '/nestle/process/media-plan' : '/custom/ch/media-plan'

  return (
    <ProcessCard
      title="Strategic Media Planning"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputsOutputsCard
            title="inputs"
            text="Campaign Budget allocation (top-down / bottom-up)"
            cardStyles={styles.inputsCard}
          />
          <svg width="6" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 0 2.887 5H.113L3 0Zm.5 4.5V21h-1V4.5h1Z" fill="#307DE4" />
          </svg>
          <div className={styles.inputBottomExtraCard}>
            <WppTypography type="m-midi">Post Campaign Insights</WppTypography>
          </div>
        </Flex>
        <RegularCard
          text="Define channel mix, effective reach, optimal audience archetypes reach weighted by comms tasks or sales and sufficiency of spend"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[{ text: 'Synapse®', style: styles.synapseTagColor, icon: SynapseIcon }]}
          bottomHighlightText="Channel mix is identified"
        />
        <Flex direction="column" align="center" gap={24}>
          <RegularCard
            text="Scenario Planning"
            teamTags={[{ text: 'Media Teams', tags: ['R', 'A'] }]}
            brandTags={[{ text: 'Architect®', style: styles.architectTagColor, icon: ArchitectIcon }]}
          />
          <RegularCard
            text="Optimise channel allocation and laydown using benchmark data"
            teamTags={[{ text: 'Media Teams', tags: ['R', 'A'] }]}
            brandTags={[{ text: 'Architect®', style: styles.architectTagColor, icon: ArchitectIcon }]}
            bottomHighlightText="Channel allocation is optimised"
          />
        </Flex>
        <InputsOutputsCard title="outputs" text="Foundation for the Media Plan" cardStyles={styles.outputsCard} />
      </>
    </ProcessCard>
  )
}

export default StrategicMediaPlanning
