import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as AIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/a.svg'
import { ReactComponent as CIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/c.svg'
import { ReactComponent as IIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/i.svg'
import { ReactComponent as RIcon } from 'pages/customProcess/components/ibmNew/assets/responsibilityIcons/r.svg'
import styles from 'pages/customProcess/components/ibmNew/processPhases/components/responsibilitiesInfo/ResponsibilitiesInfo.module.scss'

interface Props {
  width?: string
}

export const ResponsibilitiesInfo = ({ width }: Props) => {
  return (
    <div className={styles.card} style={{ width: width }}>
      <Flex gap={8} className={styles.section}>
        <RIcon width={16} className={styles.icon} />
        <div>
          <WppTypography type="xs-body" className={styles.text}>
            Responsible: assigned to <span className={styles.bold}>do the work</span>
          </WppTypography>
        </div>
      </Flex>
      <Flex gap={8} className={styles.section}>
        <AIcon width={16} className={styles.icon} />
        <div>
          <WppTypography type="xs-body" className={styles.text}>
            Accountable: makes the <span className={styles.bold}>final decision </span>and has the{' '}
            <span className={styles.bold}>ultimate ownership</span>
          </WppTypography>
        </div>
      </Flex>
      <Flex gap={8} className={styles.section}>
        <CIcon width={16} className={styles.icon} />
        <div>
          <WppTypography type="xs-body" className={styles.text}>
            Consulted: must be consulted <span className={styles.bold}>before a decision </span>or action is taken
          </WppTypography>
        </div>
      </Flex>
      <Flex gap={8} className={styles.section}>
        <IIcon width={16} className={styles.icon} />
        <div>
          <WppTypography type="xs-body" className={styles.text}>
            Informed: must be informed that a decision or action <span className={styles.bold}>has been taken</span>
          </WppTypography>
        </div>
      </Flex>
    </div>
  )
}
