import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (6).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Outcome = () => {
  return (
    <ProcessCard
      type="activation"
      title="Outcome Monitoring"
      description="Three elements to continually action: Key Watch outs, Real time factors, Bid management vs Custom Algorithm."
      previousLink="/custom/fossil/experience"
      nextLink="/custom/fossil/automation"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Outcome
