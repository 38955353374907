import schemeImg from 'pages/customProcess/components/procterAndGamble/assets/ambition.png'
import { ProcessPandGCard } from 'pages/customProcess/components/procterAndGamble/processPhases/processPandGCard/ProcessPandGCard'

const Ambition = () => {
  return (
    <ProcessPandGCard
      title="Ambition"
      description="Understand consumer habits and media preferences across more markets"
      nextLink="/custom/p&g/audience"
    >
      <img src={schemeImg} alt="" />
    </ProcessPandGCard>
  )
}

export default Ambition
