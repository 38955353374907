// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nQdJP{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/audible/processPhases/pages/activate/Activate.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA","sourcesContent":[".schemeImg {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schemeImg": `nQdJP`
};
export default ___CSS_LOADER_EXPORT___;
