import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler, ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as ArrowIcon } from 'pages/customProcess/components/ibmNew/assets/arrow-icon.svg'
import { ReactComponent as TeamIcon } from 'pages/customProcess/components/ibmNew/assets/team-icon.svg'
import { ReactComponent as ToolsIcon } from 'pages/customProcess/components/ibmNew/assets/tools-icon.svg'
import styles from 'pages/customProcess/components/ibmNew/components/phase/Phase.module.scss'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

enum Tooltips {
  Centralized = 'Centralized',
  GEOs = 'GEOs',
}

interface Team {
  name: string
  responsibilities: ReactNode[]
  tooltipContent?: ReactNode
}

interface Tool {
  name: string
  logoUrl?: string
}

interface Props {
  className?: string
  phase: Phases
  isHovered: boolean
  isOpen: boolean
  title: string
  img: string
  description: string
  teams: Team[]
  tools?: Tool[]
  stageInput?: ReactNode
  stageOutput?: ReactNode
  link: string
  onPhaseEnter: (phase: Phases) => MouseEventHandler
  onPhaseLeave: () => void
  onPhaseClick: (phase: Phases) => MouseEventHandler
}

export const Phase = ({
  className,
  phase,
  isHovered,
  isOpen,
  title,
  img,
  description,
  teams,
  tools,
  stageInput,
  stageOutput,
  link,
  onPhaseEnter,
  onPhaseLeave,
  onPhaseClick,
}: Props) => {
  const [shownTooltip, setShownTooltip] = useState<MayBeNull<Tooltips>>(null)

  const handleTooltipHover = (tooltip: Tooltips) => {
    setShownTooltip(tooltip)
  }

  const handleTooltipUnhover = () => {
    setShownTooltip(null)
  }

  return (
    <div
      className={className}
      onClick={onPhaseClick(phase)}
      onMouseEnter={onPhaseEnter(phase)}
      onMouseLeave={onPhaseLeave}
    >
      {isOpen && <>{stageInput}</>}
      <Flex direction="column" className={styles.flexWrapper}>
        <Flex
          direction="column"
          justify="between"
          className={clsx(
            styles.mainContent,
            (isHovered || isOpen) && styles.mainContentHovered,
            !isOpen && styles.mainContentPointer,
          )}
        >
          <div>
            <div>
              <WppTypography
                tag="h3"
                type="l-body"
                className={clsx(styles.title, (isHovered || isOpen) && styles.titleHovered)}
              >
                {title}
              </WppTypography>
            </div>
            {isOpen && (
              <>
                <WppTypography tag="p" type="xs-body" className={styles.description}>
                  {description}
                </WppTypography>
                <Flex align="center" gap={8} className={styles.section}>
                  <TeamIcon />
                  <WppTypography type="m-strong" className={styles.teamsTitle}>
                    Teams & Responsibility
                  </WppTypography>
                </Flex>
                <div>
                  {teams.map((team, index) => (
                    <Flex gap={8} align="center" className={styles.team} key={index}>
                      <WppTypography
                        type="s-body"
                        className={styles.teamName}
                        onMouseEnter={() => handleTooltipHover(team.name as Tooltips)}
                        onMouseLeave={handleTooltipUnhover}
                      >
                        {team.name}
                      </WppTypography>
                      <Flex gap={4}>
                        {team.responsibilities.map((responsibility, index) => (
                          <div key={index}>{responsibility}</div>
                        ))}
                      </Flex>
                      {shownTooltip === team.name && (
                        <div
                          className={clsx(
                            styles.tooltipCentralized,
                            team.name === Tooltips.GEOs && styles.tooltipGEOs,
                            shownTooltip && styles.tooltipCentralizedVisible,
                          )}
                        >
                          {team.tooltipContent}
                        </div>
                      )}
                    </Flex>
                  ))}
                </div>
                <Flex justify="between" align="end">
                  <div>
                    <Flex align="center" gap={8} className={styles.section}>
                      <ToolsIcon />
                      <WppTypography type="m-strong" className={styles.teamsTitle}>
                        Hero Tools
                      </WppTypography>
                    </Flex>
                    {tools?.map((tool, index) => (
                      <Flex gap={8} align="center" className={styles.tool} key={index}>
                        <WppTypography type="s-body" className={styles.teamsTitle}>
                          {tool.name}
                        </WppTypography>
                        {tool.logoUrl && <img src={tool.logoUrl} alt={tool.name} />}
                      </Flex>
                    ))}
                  </div>
                  <div className={clsx(styles.appear)}>{stageOutput}</div>
                </Flex>
              </>
            )}
          </div>
          {!isOpen && (
            <Flex justify="end">
              <ArrowIcon />
            </Flex>
          )}
        </Flex>
        <div
          className={clsx(styles.decoImg, (isHovered || isOpen) && styles.decoImgHidden)}
          style={{ backgroundImage: `url(${img})` }}
        />
      </Flex>
      {isOpen && (
        <Link to={link} className={styles.learnMoreBtn}>
          <WppTypography type="s-midi" className={styles.learnMoreText}>
            Learn more
          </WppTypography>
        </Link>
      )}
    </div>
  )
}
