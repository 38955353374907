import { WppDivider, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/easyjet/utils/ProcessNote.module.scss'

interface ProcessNoteProps {
  title: string
  description: string
  secondDescription?: string
  index: number
}

export const ProcessNote = ({ title, description, secondDescription, index }: ProcessNoteProps) => {
  return (
    <Flex direction="column" key={index} gap={8}>
      <WppTypography type="s-strong">
        {index + 1}. {title}
      </WppTypography>
      <WppDivider className={styles.divider} />
      <WppTypography type="s-body">{description}</WppTypography>
      {secondDescription && (
        <>
          <WppDivider />
          <WppTypography type="s-body">{secondDescription}</WppTypography>
        </>
      )}
    </Flex>
  )
}
