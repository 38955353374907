interface Props {
  className?: string
}

export const ChannelsAndSignalsSvg = ({ className }: Props) => (
  <svg
    width={657}
    height={657}
    viewBox="0 0 657 657"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M396.171 588.947C338.809 603.872 278.126 599.452 223.533 576.373C168.939 553.294 123.487 512.846 94.2243 461.302L187.884 408.13C205.441 439.056 232.713 463.325 265.469 477.173C298.225 491.02 334.635 493.672 369.051 484.717L396.171 588.947Z"
      fill="#E69F71"
    />
    <path
      d="M377.811 519.326C335.842 530.246 291.443 527.012 251.499 510.126C211.555 493.24 178.299 463.646 156.889 425.933L225.416 387.03C238.262 409.657 258.216 427.414 282.182 437.545C306.148 447.677 332.788 449.617 357.969 443.065L377.811 519.326Z"
      fill="#E08041"
    />
    <path
      d="M208.514 510.953L214.785 515.035L211.216 530.071L211.263 530.117L223.538 520.71L229.789 524.773L217.673 543.51L213.518 540.813L222.81 526.448L222.763 526.402L208.523 537.561L205.359 535.511L209.893 518.049L209.845 518.003L200.543 532.377L196.398 529.69L208.514 510.953Z"
      fill="white"
    />
    <path
      d="M240.229 530.107L243.872 531.945L242.632 556.308L237.652 553.8L238.039 548.7L230.254 544.781L226.469 548.169L221.602 545.716L240.229 530.107ZM239.028 536.774L233.497 541.763L238.392 544.225L239.028 536.774Z"
      fill="white"
    />
    <path
      d="M254.72 537.087L262.468 540.15C263.548 540.572 264.528 541.08 265.416 541.663C266.303 542.246 267.006 542.906 267.535 543.672C268.063 544.437 268.379 545.308 268.484 546.284C268.588 547.261 268.397 548.372 267.92 549.608C267.443 550.825 266.847 551.765 266.135 552.428C265.422 553.09 264.621 553.539 263.75 553.757C262.879 553.974 261.92 553.997 260.902 553.835C259.874 553.664 258.815 553.372 257.716 552.931L254.354 551.602L251.189 559.713L246.596 557.889L254.711 537.096L254.72 537.087ZM255.884 547.715L258.955 548.928C259.368 549.089 259.78 549.214 260.192 549.282C260.603 549.351 261.003 549.354 261.375 549.293C261.755 549.222 262.097 549.078 262.408 548.812C262.719 548.566 262.972 548.189 263.167 547.702C263.37 547.169 263.416 546.702 263.295 546.276C263.175 545.849 262.962 545.479 262.648 545.158C262.334 544.837 261.955 544.544 261.503 544.309C261.051 544.073 260.609 543.865 260.177 543.704L257.82 542.779L255.893 547.705L255.884 547.715Z"
      fill="white"
    />
    <path
      d="M226.191 433.305L232.286 439.004C233.139 439.801 233.872 440.623 234.479 441.478C235.096 442.342 235.499 443.222 235.717 444.124C235.935 445.026 235.902 445.949 235.637 446.891C235.372 447.833 234.79 448.794 233.89 449.756C232.997 450.708 232.104 451.36 231.191 451.714C230.279 452.068 229.373 452.178 228.475 452.061C227.578 451.944 226.682 451.611 225.788 451.081C224.894 450.552 224.011 449.88 223.148 449.074L220.505 446.598L214.554 452.944L210.941 449.563L226.189 433.287L226.191 433.305ZM223.349 443.572L225.766 445.822C226.086 446.121 226.43 446.389 226.788 446.618C227.145 446.847 227.51 446.981 227.896 447.066C228.272 447.142 228.654 447.124 229.033 447.003C229.412 446.883 229.785 446.641 230.149 446.259C230.538 445.846 230.756 445.417 230.805 444.989C230.843 444.553 230.789 444.128 230.613 443.708C230.438 443.288 230.18 442.888 229.849 442.497C229.509 442.115 229.183 441.761 228.842 441.446L226.989 439.718L223.37 443.588L223.349 443.572Z"
      fill="white"
    />
    <path
      d="M240.685 446.497L247.757 451.543C248.686 452.201 249.51 452.926 250.21 453.705C250.91 454.483 251.427 455.303 251.762 456.163C252.089 457.033 252.188 457.959 252.057 458.922C251.918 459.896 251.475 460.915 250.718 461.973C249.802 463.256 248.689 464.103 247.373 464.525C246.056 464.946 244.668 464.814 243.21 464.127L242.384 475.116L237.562 471.674L238.919 461.681L236.926 460.261L231.749 467.527L227.723 464.655L240.674 446.489L240.685 446.497ZM239.351 456.892L241.727 458.593C242.089 458.859 242.478 459.112 242.906 459.38C243.324 459.639 243.751 459.822 244.169 459.931C244.586 460.04 244.998 460.028 245.414 459.902C245.821 459.787 246.203 459.469 246.554 458.977C246.88 458.515 247.05 458.082 247.054 457.669C247.058 457.256 246.963 456.883 246.776 456.521C246.581 456.169 246.323 455.834 245.98 455.501C245.639 455.185 245.283 454.891 244.931 454.633L242.297 452.747L239.349 456.873L239.351 456.892Z"
      fill="white"
    />
    <path d="M257.543 457.922L261.837 460.387L250.786 479.767L246.492 477.303L257.543 457.922Z" fill="white" />
    <path
      d="M273.568 472.159C273.403 471.531 273.069 470.971 272.544 470.462C272.02 469.954 271.474 469.579 270.917 469.327C270.597 469.178 270.235 469.062 269.859 468.986C269.473 468.901 269.094 468.872 268.722 468.898C268.339 468.916 267.987 469.024 267.681 469.202C267.367 469.39 267.12 469.663 266.953 470.049C266.675 470.664 266.7 471.234 267.014 471.777C267.335 472.311 267.78 472.857 268.375 473.404C268.971 473.952 269.626 474.529 270.331 475.129C271.037 475.728 271.638 476.397 272.154 477.132C272.662 477.877 272.992 478.7 273.128 479.622C273.264 480.543 273.069 481.589 272.536 482.788C272.023 483.92 271.366 484.806 270.567 485.465C269.759 486.116 268.876 486.56 267.9 486.781C266.934 487.01 265.905 487.041 264.832 486.87C263.759 486.699 262.696 486.387 261.639 485.895C260.314 485.296 259.165 484.534 258.223 483.616C257.269 482.69 256.506 481.562 255.931 480.216L260.711 478.166C260.891 478.988 261.267 479.731 261.819 480.377C262.362 481.034 263.016 481.527 263.773 481.867C264.142 482.038 264.533 482.16 264.938 482.242C265.353 482.333 265.74 482.352 266.121 482.315C266.501 482.279 266.835 482.173 267.138 481.977C267.444 481.799 267.681 481.517 267.842 481.16C268.12 480.545 268.092 479.958 267.785 479.385C267.477 478.812 267.019 478.239 266.422 477.674C265.824 477.108 265.164 476.493 264.456 475.875C263.738 475.249 263.116 474.565 262.608 473.819C262.082 473.076 261.741 472.245 261.589 471.344C261.426 470.435 261.608 469.428 262.111 468.289C262.61 467.197 263.251 466.332 264.054 465.709C264.857 465.087 265.75 464.651 266.726 464.43C267.692 464.201 268.721 464.171 269.781 464.315C270.852 464.467 271.886 464.773 272.881 465.216C274.036 465.733 275.08 466.414 276.009 467.221C276.929 468.038 277.666 469.028 278.188 470.185L273.584 472.138L273.568 472.159Z"
      fill="white"
    />
    <path
      d="M281.668 470.018L288.877 472.008L290.063 487.445L290.121 487.457L298.925 474.763L306.103 476.729L300.286 498.292L295.529 496.981L299.987 480.45L299.929 480.439L289.782 495.414L286.143 494.409L285.143 476.371L285.085 476.359L280.627 492.889L275.87 491.578L281.686 470.016L281.668 470.018Z"
      fill="white"
    />
    <path
      d="M317.236 477.834L321.293 478.216L329.041 501.396L323.501 500.874L321.995 495.972L313.331 495.16L311.064 499.702L305.649 499.192L317.247 477.842L317.236 477.834ZM318.552 484.484L315.233 491.151L320.685 491.656L318.552 484.484Z"
      fill="white"
    />
  </svg>
)
