import { phasesData } from 'pages/customProcess/components/mediaMarktSaturn/MediaMarktSaturnConfig'
import { PageLayout } from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pageLayout/PageLayout'
import { Phases } from 'pages/customProcess/components/mediaMarktSaturn/types'

const Activate = () => {
  const { title, description, schemeSrc, previousLink } = phasesData[Phases.Activate]
  return <PageLayout title={title} description={description} schemeSrc={schemeSrc} previousLink={previousLink} />
}

export default Activate
