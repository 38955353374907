import { Routes, Route } from 'react-router-dom'

import BigIdeaDevelopment from 'pages/customProcess/components/nestleHealth/processPhases/pages/bigIdeaDevelopment/BigIdeaDevelopment'
import CampaignBrief from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaignBrief/CampaignBrief'
import CampaignBudgetSetting from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaignBudgetSetting/CampaignBudgetSetting'
import CampaignStrategy from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaignStrategy/CampaignStrategy'
import CampaignAnalysis from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaingAnalysis/CampaingAnalysis'
import MediaActivation from 'pages/customProcess/components/nestleHealth/processPhases/pages/mediaActivation/MediaActivation'
import MediaPlan from 'pages/customProcess/components/nestleHealth/processPhases/pages/mediaPlan/MediaPlan'
import PortfolioAllocationAndCommunication from 'pages/customProcess/components/nestleHealth/processPhases/pages/portfolioAllocationAndCommunication/PortfolioAllocationAndCommunication'
import StrategicMediaPlanning from 'pages/customProcess/components/nestleHealth/processPhases/pages/strategicMediaPlanning/StrategicMediaPlanning'

export const NestleHealthRoutes = () => (
  <Routes>
    <Route path="big-idea" element={<BigIdeaDevelopment />} />
    <Route path="portfolio-allocation" element={<PortfolioAllocationAndCommunication />} />
    <Route path="campaign-brief" element={<CampaignBrief />} />
    <Route path="campaign-strategy" element={<CampaignStrategy />} />
    <Route path="campaign-budget-settings" element={<CampaignBudgetSetting />} />
    <Route path="strategic-media-planning" element={<StrategicMediaPlanning />} />
    <Route path="media-plan" element={<MediaPlan />} />
    <Route path="media-activation" element={<MediaActivation />} />
    <Route path="campaign-analysis" element={<CampaignAnalysis />} />
  </Routes>
)
