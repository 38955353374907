import { WppCard, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/bp/processPhases/pageLayout/PageLayout.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  title: string
  bepTitle?: string
  footerTitle?: string
  footerSubtitle?: string
}

export const PageLayout = ({ title, bepTitle, children, footerTitle, footerSubtitle }: PropsWithChildren<Props>) => {
  return (
    <WppCard size="xl" className={styles.container}>
      <Flex direction="column" className={styles.flexContent}>
        <div className={styles.processHeader}>
          <Flex justify="between" align="center">
            <WppTypography type="5xl-display" tag="h1">
              {title}
            </WppTypography>
            <Link to={routesManager.systemUrls.orchestration.root.url}>
              <WppButton className={styles.projectsBtn}>Go to Project</WppButton>
            </Link>
          </Flex>
        </div>
        <Flex justify="center" align="center" className={styles.bepSeparator}>
          <WppTypography type="2xs-strong" tag="span" className={styles.bepTitle}>
            {bepTitle}
          </WppTypography>
        </Flex>
        <div className={styles.processContentWrapper}>
          <div className={styles.processContentWavesBg} />
          <div className={styles.processInnerContentWrapper}>
            <Flex justify="between" align="center" className={styles.contentContainer}>
              {children}
            </Flex>
          </div>
        </div>
        <Flex justify="center" align="center" gap={8} className={styles.processFooter}>
          {footerTitle && (
            <WppTypography type="2xs-strong" tag="span" className={styles.footerFramework}>
              {footerTitle}
            </WppTypography>
          )}
          {footerSubtitle && (
            <WppTypography type="2xs-strong" tag="span" className={styles.footerFrameworkDescription}>
              {footerSubtitle}
            </WppTypography>
          )}
        </Flex>
      </Flex>
    </WppCard>
  )
}
