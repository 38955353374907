import { WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customWidget/cocaColaChina/process/phaseDetails/PhaseDetails.module.scss'

interface Props {
  index: number
  title: string
  children: ReactNode
}

export const PhaseDetails = ({ index, title, children }: Props) => {
  return (
    <>
      <WppTypography type="s-strong" className={styles.step}>
        Step {index}
      </WppTypography>
      <WppTypography type="3xl-heading" className={styles.discActiveTitle}>
        {title}
      </WppTypography>
      <Flex direction="column" align="center" gap={12}>
        {children}
      </Flex>
    </>
  )
}
