import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as ArrowListIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/arrow-list.svg'
import { ReactComponent as ChecklistIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/checklist.svg'
import { ReactComponent as LightIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/light.svg'
import { ReactComponent as MapIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/map.svg'
import { ReactComponent as PlantIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/plant.svg'
import { ReactComponent as TargetIcon } from 'pages/customProcess/components/tigerBrands/assets/icons/target.svg'
import tigerLogoImgSrc from 'pages/customProcess/components/tigerBrands/assets/logo.png'
import styles from 'pages/customProcess/components/tigerBrands/processPhases/pageLayout/PageLayout.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  title: string
  description: string
  color: string
  schemeSrc: string
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({ title, description, color, schemeSrc, previousLink, nextLink }: Props) => {
  const icon = useMemo(() => {
    switch (title) {
      case 'Define':
        return <MapIcon width={31} />
      case 'Decide':
        return <TargetIcon width={32} />
      case 'Develop':
        return <PlantIcon width={32} />
      case 'Deploy':
        return <LightIcon width={55} />
      case 'Discern':
        return <ArrowListIcon width={31} />
      case 'Decode':
        return <ChecklistIcon width={31} />
    }
  }, [title])

  const buttonStyle = useMemo(() => {
    switch (title) {
      case 'Define':
        return styles.defineButton
      case 'Decide':
        return styles.decideButton
      case 'Develop':
        return styles.developButton
      case 'Deploy':
        return styles.deployButton
      case 'Discern':
        return styles.discernButton
      case 'Decode':
        return styles.decodeButton
    }
  }, [title])

  return (
    <div>
      <div className={styles.header} style={{ backgroundColor: color }}>
        <Flex justify="between" align="center" gap={16} className={styles.headerContent}>
          <div>
            <Link to="/custom">
              <WppActionButton variant="inverted" className={styles.backToProcessBtn}>
                <WppIconChevron slot="icon-start" direction="left" /> Back to Process overview
              </WppActionButton>
            </Link>
            <Flex gap={20} align="center" className={styles.titleWrapper}>
              {icon}
              <WppTypography type="4xl-display" className={styles.title}>
                {title}
              </WppTypography>
            </Flex>
            <WppTypography type="l-body" className={styles.description}>
              {description}
            </WppTypography>
          </div>
          <div>
            <img src={tigerLogoImgSrc} alt="Tiger Brands logo" className={styles.logo} />
          </div>
        </Flex>
      </div>
      <Flex direction="column" justify="between" gap={32} className={styles.content}>
        <div>
          <img src={schemeSrc} alt="" className={styles.schemeImg} />
        </div>
        <Flex align="center" justify="between">
          <Flex gap={16}>
            <Link to={previousLink ?? ''}>
              <WppActionButton disabled={!previousLink} variant="secondary">
                Previous phase <WppIconChevron slot="icon-start" direction="left" />
              </WppActionButton>
            </Link>
            <Link to={nextLink ?? ''}>
              <WppActionButton disabled={!nextLink} variant="secondary">
                Next phase <WppIconChevron slot="icon-end" />
              </WppActionButton>
            </Link>
          </Flex>
          <Link to={routesManager.systemUrls.orchestration.root.url}>
            <WppButton className={buttonStyle}>Go to project details</WppButton>
          </Link>
        </Flex>
      </Flex>
    </div>
  )
}
