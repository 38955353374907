import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as ArrowIcon } from 'pages/customProcess/components/ibmNew/assets/divider-arrow.svg'
import styles from 'pages/customProcess/components/ibmNew/components/phaseDivider/PhaseDivider.module.scss'

interface Props {
  showBackArrow?: boolean
}

export const PhaseDivider = ({ showBackArrow }: Props) => {
  return (
    <Flex direction="column" align="center" className={styles.phaseDivider}>
      <div className={styles.arrow1}>
        <ArrowIcon />
      </div>
      {showBackArrow && (
        <div className={styles.arrow2}>
          <ArrowIcon />
        </div>
      )}
    </Flex>
  )
}
