import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import lampManImgUrl from 'pages/customProcess/components/ikeaNew/assets/lamp-man.svg'
import DescriptionCard from 'pages/customProcess/components/ikeaNew/components/phaseCard/descriptionCard/DescriptionCard'
import PhaseCard from 'pages/customProcess/components/ikeaNew/components/phaseCard/PhaseCard'
import styles from 'pages/customProcess/components/ikeaNew/IkeaProcess.module.scss'
import { IkeaProcessData } from 'pages/customProcess/components/ikeaNew/IkeaProcessData'
import { Phases } from 'pages/customProcess/components/ikeaNew/types'

export const IkeaProcess = () => {
  const [hoveredPhase, setHoveredPhase] = useState<MayBeNull<Phases>>(null)
  const [openedPhase, setOpenedPhase] = useState<MayBeNull<Phases>>(null)
  const [isOpenPhaseHovered, setIsOpenPhaseHovered] = useState(false)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setHoveredPhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setHoveredPhase(null)
  }, [])

  const handlePhaseOpen = useCallback(
    (phase: Phases) => () => {
      setOpenedPhase(phase)
    },
    [],
  )

  const handlePhaseClose = useCallback(() => {
    setOpenedPhase(null)
  }, [])

  const handleOpenPhaseEnter = useCallback(() => {
    setIsOpenPhaseHovered(true)
  }, [])

  const handleOpenPhaseLeave = useCallback(() => {
    setIsOpenPhaseHovered(false)
  }, [])

  return (
    <Flex justify="center">
      <Flex className={styles.container} direction="column" gap={24}>
        <Flex direction="column" gap={16} className={styles.headlineContainer}>
          <WppTypography type="2xl-heading" tag="h1">
            {IkeaProcessData.title}
          </WppTypography>
          <WppTypography type="l-body" tag="span">
            {IkeaProcessData.subtitle}
          </WppTypography>
        </Flex>
        <Flex justify="between" wrap="wrap" className={styles.phaseCardContainer}>
          <div className={clsx(styles.overlay, { [styles.overlayActive]: !!hoveredPhase || !!openedPhase })} />
          <Flex justify="between">
            <Flex
              justify="between"
              wrap="wrap"
              className={clsx(
                styles.phaseOneContainer,
                !!hoveredPhase && [Phases.OpportunityDefinition].includes(hoveredPhase) && styles.phaseContainerActive,
              )}
            >
              {IkeaProcessData.phases[0].map((phase, index) => (
                <PhaseCard
                  key={index}
                  imagePath={phase.img}
                  phaseData={phase}
                  className={styles.phaseOneCard}
                  isPhaseHovered={hoveredPhase === phase.phaseId}
                  onPhaseEnter={handlePhaseEnter}
                  onPhaseLeave={handlePhaseLeave}
                  onPhaseOpen={handlePhaseOpen}
                  onPhaseClose={handlePhaseClose}
                  disabledOutsideClick={isOpenPhaseHovered}
                />
              ))}
              <div className={styles.phaseOne}>
                <WppTypography type="l-body" tag="span" className={styles.phaseTextBold}>
                  Discover:
                </WppTypography>
                <WppTypography type="l-body" tag="span" className={styles.phaseText}>
                  Audit and identify ambition through informed objectives
                </WppTypography>
              </div>
            </Flex>
            <Flex
              justify="between"
              wrap="wrap"
              className={clsx(
                styles.phaseTwoContainer,
                !!hoveredPhase &&
                  [Phases.AudienceAnalysis, Phases.StrategicAndMeasurementSolutions, Phases.ChannelAllocation].includes(
                    hoveredPhase,
                  ) &&
                  styles.phaseContainerActive,
              )}
            >
              {IkeaProcessData.phases[1].map((phase, index) => (
                <PhaseCard
                  key={index}
                  imagePath={phase.img}
                  phaseData={phase}
                  className={styles.phaseTwoCard}
                  isPhaseHovered={hoveredPhase === phase.phaseId}
                  onPhaseEnter={handlePhaseEnter}
                  onPhaseLeave={handlePhaseLeave}
                  onPhaseOpen={handlePhaseOpen}
                  onPhaseClose={handlePhaseClose}
                  disabledOutsideClick={isOpenPhaseHovered}
                />
              ))}
              <div className={styles.phaseTwo}>
                <WppTypography type="l-body" tag="span" className={styles.phaseTextBold}>
                  Plan:
                </WppTypography>
                <WppTypography type="l-body" tag="span" className={styles.phaseText}>
                  Design audience and strategic approach
                </WppTypography>
              </div>
            </Flex>
          </Flex>
          <Flex justify="between" gap={16}>
            <Flex
              wrap="wrap"
              justify="between"
              className={clsx(
                styles.phaseThreeContainer,
                !!hoveredPhase &&
                  [Phases.AudienceAnalysis, Phases.StrategicAndMeasurementSolutions, Phases.ChannelAllocation].includes(
                    hoveredPhase,
                  ) &&
                  styles.phaseContainerActive,
              )}
            >
              {IkeaProcessData.phases[2].map((phase, index) => (
                <PhaseCard
                  key={index}
                  imagePath={phase.img}
                  phaseData={phase}
                  className={styles.phaseThreeCard}
                  isPhaseHovered={hoveredPhase === phase.phaseId}
                  onPhaseEnter={handlePhaseEnter}
                  onPhaseLeave={handlePhaseLeave}
                  onPhaseOpen={handlePhaseOpen}
                  onPhaseClose={handlePhaseClose}
                  disabledOutsideClick={isOpenPhaseHovered}
                />
              ))}
              <div className={styles.phaseThree}>
                <WppTypography type="l-body" tag="span" className={styles.phaseTextBold}>
                  Plan:
                </WppTypography>
                <WppTypography type="l-body" tag="span" className={styles.phaseText}>
                  Design audience and strategic approach
                </WppTypography>
              </div>
            </Flex>
            <Flex
              wrap="wrap"
              justify="between"
              className={clsx(
                styles.phaseFourContainer,
                hoveredPhase === Phases.ExecutionPlan && styles.phaseContainerActive,
              )}
            >
              {IkeaProcessData.phases[3].map((phase, index) => (
                <PhaseCard
                  key={index}
                  imagePath={phase.img}
                  phaseData={phase}
                  className={styles.phaseFourCard}
                  isPhaseHovered={hoveredPhase === phase.phaseId}
                  onPhaseEnter={handlePhaseEnter}
                  onPhaseLeave={handlePhaseLeave}
                  onPhaseOpen={handlePhaseOpen}
                  onPhaseClose={handlePhaseClose}
                  disabledOutsideClick={isOpenPhaseHovered}
                />
              ))}
              <div className={styles.phaseFour}>
                <WppTypography type="l-body" tag="span" className={styles.phaseTextBold}>
                  Activate:
                </WppTypography>
                <WppTypography type="l-body" tag="span" className={styles.phaseText}>
                  Leverage assets and activate
                </WppTypography>
              </div>
            </Flex>
            <Flex
              wrap="wrap"
              justify="between"
              className={clsx(
                styles.phaseFiveContainer,
                hoveredPhase === Phases.Reporting && styles.phaseContainerActive,
              )}
            >
              {IkeaProcessData.phases[4].map((phase, index) => (
                <PhaseCard
                  key={index}
                  imagePath={phase.img}
                  phaseData={phase}
                  className={styles.phaseFiveCard}
                  isPhaseHovered={hoveredPhase === phase.phaseId}
                  onPhaseEnter={handlePhaseEnter}
                  onPhaseLeave={handlePhaseLeave}
                  onPhaseOpen={handlePhaseOpen}
                  onPhaseClose={handlePhaseClose}
                  disabledOutsideClick={isOpenPhaseHovered}
                />
              ))}
              <div className={styles.phaseFive}>
                <WppTypography type="l-body" tag="span" className={styles.phaseTextBold}>
                  Measure:
                </WppTypography>
                <WppTypography type="l-body" tag="span" className={styles.phaseText}>
                  Analyze performance and adapt
                </WppTypography>
              </div>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="row" wrap="wrap" justify="between" className={styles.descriptionCardContainer}>
          <Flex direction="row" className={styles.descriptionOneContainer}>
            {IkeaProcessData.phases[0].map((phase, index) => (
              <div className={styles.descriptionPlaceholder} key={index}>
                <DescriptionCard
                  phaseData={phase}
                  className={styles.phaseOneCard}
                  isOpen={openedPhase === phase.phaseId}
                  displayTop
                  transitionDirection="down"
                  onDescriptionCardEnter={handleOpenPhaseEnter}
                  onDescriptionCardLeave={handleOpenPhaseLeave}
                />
              </div>
            ))}
          </Flex>
          <Flex justify="between" margin-left={50} wrap="wrap" className={styles.descriptionTwoContainer}>
            {IkeaProcessData.phases[1].map((phase, index) => (
              <div className={styles.descriptionPlaceholder} key={index}>
                <DescriptionCard
                  phaseData={phase}
                  className={styles.phaseTwoCard}
                  isOpen={openedPhase === phase.phaseId}
                  displayTop={false}
                  transitionDirection="down"
                  onDescriptionCardEnter={handleOpenPhaseEnter}
                  onDescriptionCardLeave={handleOpenPhaseLeave}
                />
              </div>
            ))}
          </Flex>
          <Flex wrap="wrap" justify="end" className={styles.descriptionThreeContainer}>
            {IkeaProcessData.phases[2].map((phase, index) => (
              <div className={styles.descriptionPlaceholder} key={index}>
                <DescriptionCard
                  phaseData={phase}
                  className={styles.phaseThreeCard}
                  isOpen={openedPhase === phase.phaseId}
                  displayTop={false}
                  transitionDirection="up"
                  onDescriptionCardEnter={handleOpenPhaseEnter}
                  onDescriptionCardLeave={handleOpenPhaseLeave}
                />
              </div>
            ))}
          </Flex>
          <Flex wrap="wrap" justify="end" className={styles.descriptionFourContainer}>
            {IkeaProcessData.phases[3].map((phase, index) => (
              <div className={styles.descriptionPlaceholder} key={index}>
                <DescriptionCard
                  phaseData={phase}
                  className={styles.phaseFourCard}
                  isOpen={openedPhase === phase.phaseId}
                  displayTop={false}
                  transitionDirection="up"
                  onDescriptionCardEnter={handleOpenPhaseEnter}
                  onDescriptionCardLeave={handleOpenPhaseLeave}
                />
              </div>
            ))}
          </Flex>
          <Flex wrap="wrap" justify="end" className={styles.descriptionFiveContainer}>
            {IkeaProcessData.phases[4].map((phase, index) => (
              <div className={styles.descriptionPlaceholder} key={index}>
                <DescriptionCard
                  phaseData={phase}
                  className={styles.phaseFiveCard}
                  isOpen={openedPhase === phase.phaseId}
                  displayTop={false}
                  transitionDirection="up"
                  onDescriptionCardEnter={handleOpenPhaseEnter}
                  onDescriptionCardLeave={handleOpenPhaseLeave}
                />
              </div>
            ))}
          </Flex>
        </Flex>
        <div className={clsx(styles.background, { [styles.activeBackground]: !!hoveredPhase || !!openedPhase })}>
          <img src={lampManImgUrl} alt="lamp-man" />
        </div>
      </Flex>
    </Flex>
  )
}
