import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { MicroAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'
import { PropsWithChildren, StrictMode } from 'react'

import { ApiProvider } from 'providers/ApiProvider'
import { ToastProvider } from 'providers/toast/ToastProvider'
import 'i18n/i18n'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const Root = ({ children, ...rest }: PropsWithChildren<MicroAppCustomProps>) => {
  return (
    <StrictMode>
      <OsProvider {...rest}>
        <ApiProvider>
          <QueryClientProvider client={queryClient}>
            <ToastProvider>{children}</ToastProvider>
          </QueryClientProvider>
        </ApiProvider>
      </OsProvider>
    </StrictMode>
  )
}
