// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MNubo{width:100%;height:100%;background:var(--wpp-grey-color-1000)}.pYIpZ{cursor:pointer}.zJuHJ::part(body),.zJuHJ::part(button){color:#fff}.Vd11a::part(body),.Vd11a::part(button){color:var(--wpp-grey-color-1000)}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/netflix/pages/springboardPage/SpringboardPage.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,qCAAA,CAGF,OACE,cAAA,CAIA,wCAEE,UAAA,CAKF,wCAEE,gCAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  background: var(--wpp-grey-color-1000);\n}\n\n.cursor {\n  cursor: pointer;\n}\n\n.button {\n  &::part(body),\n  &::part(button) {\n    color: white;\n  }\n}\n\n.link {\n  &::part(body),\n  &::part(button) {\n    color: var(--wpp-grey-color-1000);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MNubo`,
	"cursor": `pYIpZ`,
	"button": `zJuHJ`,
	"link": `Vd11a`
};
export default ___CSS_LOADER_EXPORT___;
