// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bPlSp{position:relative;height:calc(100vh - var(--wpp-os-header-height));background:#dbdbdb}.c76rp{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/tommy/TommyProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,gDAAA,CACA,kBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  position: relative;\n  height: calc(100vh - var(--wpp-os-header-height));\n  background: #dbdbdb;\n}\n\n.svgPoint {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `bPlSp`,
	"svgPoint": `c76rp`
};
export default ___CSS_LOADER_EXPORT___;
