import { Routes, Route } from 'react-router-dom'

import { Define } from 'pages/customProcess/components/asml/processPhases/pages/define/Define'
import { Discover } from 'pages/customProcess/components/asml/processPhases/pages/discover/Discover'
import { Pilot } from 'pages/customProcess/components/asml/processPhases/pages/pilot/Pilot'
import { Scale } from 'pages/customProcess/components/asml/processPhases/pages/scale/Scale'

export const ASMLRoutes = () => (
  <Routes>
    <Route path="discover" element={<Discover />} />
    <Route path="define" element={<Define />} />
    <Route path="pilot" element={<Pilot />} />
    <Route path="scale" element={<Scale />} />
  </Routes>
)
