import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse } from '@wpp-open/core'
import { get } from 'lodash'

import { useUploadProcessFilesApi } from 'hooks/attachments/useUploadProcessFilesApi'
import { mockedPhases, mockedGroups } from 'pages/processBuilder/mocks'
import { templateConfigs } from 'pages/processBuilder/templates'
import { BGAlignmentOptions, DimensionUnits } from 'types/common/comon'
import {
  Process,
  Templates,
  ProcessFormConfig,
  FormPhase,
  MapPayload,
  Phase,
  FirstLinearPhaseImageSize,
  WidgetSize,
} from 'types/process/process'
import { isFile } from 'utils/files'

export const getInitialValues = (data: MayBeNull<Process>): ProcessFormConfig => {
  const getFieldValue = ({ name, size, url }: AttachmentMetadataResponse) => ({ name, size, url: url || '', type: '' })
  const initialPhases = data ? [] : mockedPhases
  const initialGroups = data ? [] : mockedGroups
  const logoDimensions = data?.logoDimensions || {
    width: {
      unit: DimensionUnits.percent,
      value: 100,
    },
    height: {
      unit: DimensionUnits.auto,
      value: 0,
    },
  }

  return {
    template: data?.template || Templates.Circular1,
    title: data?.title || '',
    description: data?.description || '',
    logoImage: (data?.logoImage ? [getFieldValue(data?.logoImage)] : []) as [FileItemType?],
    logoDimensions,
    background: (data?.background ? [getFieldValue(data?.background)] : []) as [FileItemType?],
    positionBackground: data?.positionBackground || BGAlignmentOptions.CenterCenter,
    widgetBackgroundImage: (data?.widgetBackgroundImage ? [getFieldValue(data?.widgetBackgroundImage)] : []) as [
      FileItemType?,
    ],
    widgetPartBackgroundImage: (data?.widgetPartBackgroundImage
      ? [getFieldValue(data?.widgetPartBackgroundImage)]
      : []) as [FileItemType?],
    headerBackground: (data?.headerBackground ? [getFieldValue(data?.headerBackground)] : []) as [FileItemType?],
    colors: data?.colors || [],
    button: {
      title: data?.button?.title,
      url: data?.button?.url,
      colors: data?.button?.colors || [],
      isVisible: data?.button?.isVisible,
    },
    phases:
      data?.phases
        .map(({ overlayDescriptions, ...phase }) => ({
          ...phase,
          overlayDescriptions: overlayDescriptions.map((label, id) => ({ label, id })),
          image: (phase?.image ? [getFieldValue(phase?.image)] : []) as [FileItemType?],
          templateOptions: {
            phaseImageSize: phase?.templateOptions?.phaseImageSize || FirstLinearPhaseImageSize.FULL_WIDTH,
            expandedPhaseImageVisible: phase?.templateOptions?.expandedPhaseImageVisible,
            minimizedPhaseImageVisible: phase?.templateOptions?.minimizedPhaseImageVisible,
          },
          localId: phase.id,
        }))
        .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || initialPhases,
    groups:
      data?.groups
        .map(({ phaseOrders, ...group }) => ({
          ...group,
          phasesLocalIds: getPhaseId(phaseOrders, data?.phases),
        }))
        .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || initialGroups,
    templateOptions: {
      flipGradient: data?.templateOptions?.flipGradient,
      isInactivePhaseBlured: data?.templateOptions?.isInactivePhaseBlured,
      verticalPhaseNumbers: data?.templateOptions?.verticalPhaseNumbers,
      isWidgetBackgroundAsLogo: data?.templateOptions?.isWidgetBackgroundAsLogo,
      additionalCircleAsImageIsVisible: data?.templateOptions?.additionalCircleAsImageIsVisible,
      additionalCircleImage: (data?.templateOptions?.additionalCircleImage
        ? [getFieldValue(data?.templateOptions?.additionalCircleImage)]
        : []) as [FileItemType?],
      widgetSize: data?.templateOptions?.widgetSize || WidgetSize.MD,
    },
  }
}

export const useMapFormValuesToPayload = () => {
  const handleUploadProcessFiles = useUploadProcessFilesApi()

  return async <T extends boolean>(
    {
      logoImage,
      background,
      widgetBackgroundImage,
      widgetPartBackgroundImage,
      headerBackground,
      phases,
      groups,
      templateOptions,
      ...values
    }: ProcessFormConfig,
    _isEdit: T,
    tenantId?: string,
  ): Promise<MapPayload<T>> => {
    const imagesMetadata = Object.fromEntries(
      await Promise.all(
        Object.entries({
          logoImage,
          background,
          widgetBackgroundImage,
          widgetPartBackgroundImage,
          headerBackground,
          additionalCircleImage: templateOptions?.additionalCircleImage,
        })
          .filter(([_, [value]]) => !value || isFile(value))
          .map(async ([key, [value]]) => [key, value ? (await handleUploadProcessFiles([value as File]))[0] : null]),
      ),
    )
    const phaseImagesMetadata = Object.fromEntries(
      await Promise.all(
        phases
          .filter(({ image }) => !image?.[0] || isFile(image?.[0]))
          .map(async phase => [
            phase.localId,
            phase.image?.[0] ? (await handleUploadProcessFiles([phase.image?.[0] as File]))[0] : null,
          ]),
      ),
    )

    return {
      ...values,
      ...(tenantId && { tenantId }),
      logoImage: imagesMetadata.logoImage,
      background: imagesMetadata.background,
      widgetBackgroundImage: imagesMetadata.widgetBackgroundImage,
      widgetPartBackgroundImage: imagesMetadata.widgetPartBackgroundImage,
      headerBackground: imagesMetadata.headerBackground,
      phases: phases.map(({ localId, overlayDescriptions, ...phase }, index) => ({
        ...phase,
        image: localId ? phaseImagesMetadata?.[localId] : null,
        overlayDescriptions: overlayDescriptions.map(({ label }) => label),
        order: index + 1,
      })),
      groups: groups.map(({ phasesLocalIds, ...group }, index) => ({
        ...group,
        phaseOrders: getPhaseOrder(phasesLocalIds, phases),
        order: index + 1,
      })),
      templateOptions: {
        ...templateOptions,
        additionalCircleImage: imagesMetadata.additionalCircleImage,
      },
      isConfigured: true,
    } as MapPayload<T>
  }
}

const getPhaseOrder = (phasesLocalIds: string[], phases: FormPhase[]) => {
  return phasesLocalIds?.reduce((accum: number[], id: string) => {
    const index = phases.findIndex(({ localId }) => localId === id)
    index > -1 && accum.push(index + 1)

    return accum
  }, [])
}

const getPhaseId = (phasesOrders: number[], phases: Phase[]) => {
  return phasesOrders?.reduce((accum: string[], order: number) => {
    const phase = phases.find(({ order: itemOrder }) => itemOrder === order)
    phase && accum.push(phase.id)

    return accum
  }, [])
}

export const getIsFieldHidden = (template: Templates) => {
  const config = templateConfigs[template].fields as any

  return (...fieldNames: string[]) => {
    return !get(config, fieldNames)
  }
}

export const getPieItemPath = ({ radius, size, x, y }: { radius: number; size: number; x: number; y: number }) => {
  const startAngle = -90 - size / 2
  const endAngle = -90 + size / 2

  const startX = x + radius * Math.cos((startAngle * Math.PI) / 180)
  const startY = y + radius * Math.sin((startAngle * Math.PI) / 180)

  const endX = x + radius * Math.cos((endAngle * Math.PI) / 180)
  const endY = y + radius * Math.sin((endAngle * Math.PI) / 180)

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  return `M ${x} ${y} L ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY} Z`
}

export const getCurvedTextPath = ({
  radius,
  x,
  y,
  isReversed,
}: {
  radius: number
  x: number
  y: number
  isReversed: boolean
}) => {
  const startAngle = isReversed ? 0 : Math.PI
  const endAngle = isReversed ? Math.PI : 2 * Math.PI

  const startX = x + radius * Math.cos(startAngle)
  const startY = y + radius * Math.sin(startAngle)
  const endX = x + radius * Math.cos(endAngle)
  const endY = y + radius * Math.sin(endAngle)

  const path = `M ${startX},${startY} A ${radius},${radius} 0 0,${isReversed ? 0 : 1} ${endX},${endY}`

  return path
}

export const getConicGradient = (colors: { color: string; size: number }[], rotation: number = 0) => {
  let colorsProgress = 0

  colors.push({ color: colors[0].color, size: 100 - colors[0].size / 2 })

  const normalizedColors = colors.map(({ color, size }, index) => {
    const normalizedColor = `${color} ${colorsProgress}%`
    colorsProgress += index ? size : size / 2

    return normalizedColor
  })

  return `conic-gradient(from ${rotation}deg, ${normalizedColors.join()})`
}
