import { Routes, Route } from 'react-router-dom'

import CentralStrategy from 'pages/customProcess/components/ibmNew/processPhases/pages/centralStrategy/CentralStrategy'
import ChannelActivity from 'pages/customProcess/components/ibmNew/processPhases/pages/channelActivity/ChannelActivity'
import ExecutionalDetails from 'pages/customProcess/components/ibmNew/processPhases/pages/executionalDetails/ExecutionalDetails'
import Performance from 'pages/customProcess/components/ibmNew/processPhases/pages/performance/Performance'
import Reconciliation from 'pages/customProcess/components/ibmNew/processPhases/pages/reconciliation/Reconciliation'

export const IbmRoutes = () => (
  <Routes>
    <Route path="central-strategy" element={<CentralStrategy />} />
    <Route path="executional-details" element={<ExecutionalDetails />} />
    <Route path="channel-activity" element={<ChannelActivity />} />
    <Route path="performance" element={<Performance />} />
    <Route path="reconciliation" element={<Reconciliation />} />
  </Routes>
)
