import { WppActionButton, WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { FooterNavigation } from 'pages/customProcess/components/lvmh/processPhases/components/footerNavigation/FooterNavigation'
import styles from 'pages/customProcess/components/lvmh/processPhases/components/processPhasePageLayout/ProcessPhasePageLayout.module.scss'

interface Props {
  title: string
  description: string
  headerImgSrc: string
  tag: string
  previousLink?: string
  nextLink?: string
  children: ReactNode
}

export const ProcessPhasePageLayout = ({
  title,
  description,
  headerImgSrc,
  tag,
  previousLink,
  nextLink,
  children,
}: Props) => {
  return (
    <div className={styles.root}>
      <Flex direction="column" className={styles.card}>
        <Flex justify="between" className={styles.header}>
          <div className={styles.headerContent}>
            <Link to="/custom">
              <WppActionButton variant="inverted">
                <WppIconChevron direction="left" slot="icon-start" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.description}>{description}</p>
          </div>
          <img alt="" src={headerImgSrc} className={styles.headerIllustration} />
          <Flex justify="center" align="center" className={styles.headerFooter}>
            <WppTypography type="2xs-strong" className={styles.headerFooterText}>
              {tag}
            </WppTypography>
          </Flex>
        </Flex>
        <div className={styles.mainContentWrapper}>
          <Flex justify="center" className={styles.mainContent}>
            {children}
          </Flex>
          <FooterNavigation previousLink={previousLink} nextLink={nextLink} />
        </div>
      </Flex>
    </div>
  )
}
