import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import Logo from 'pages/customProcess/components/no7Beauty/assets/logo.png'
import Scheme from 'pages/customProcess/components/no7Beauty/processPhases/evaluation/assets/evaluation.png'
import styles from 'pages/customProcess/components/no7Beauty/processPhases/evaluation/Evaluation.module.scss'
import { FooterNavigation } from 'pages/customProcess/components/no7Beauty/processPhases/footerNavigation/FooterNavigation'

const Evaluation = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Flex direction="column" className={styles.root}>
      <div className={styles.header}>
        <Flex justify="between" className={styles.headerContent}>
          <div>
            <Link to="/custom">
              <WppActionButton className={styles.backToProcessBtn}>
                <WppIconChevron direction="left" slot="icon-start" color="#05054b" />
                Back to Process overview
              </WppActionButton>
            </Link>
            <h1 className={styles.title}>Evaluation</h1>
            <h3 className={styles.subtitle}>
              {
                'A single, clear understanding and view of campaign performance with insights\nand recommendations to take forward, backed by rationale and reason'
              }
            </h3>
          </div>
          <Flex align="center" className={styles.logoWrapper}>
            <img src={Logo} alt="" />
          </Flex>
        </Flex>
      </div>
      <div className={styles.mainContentWrapper}>
        <Flex justify="center" className={styles.mainContent}>
          <img src={Scheme} alt="" />
        </Flex>
      </div>
      <FooterNavigation previousLink="/custom/n7/activation" />
    </Flex>
  )
}

export default Evaluation
