// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.npWQZ{position:absolute;top:-81px;left:50%;z-index:1;width:910px;padding:8px 16px;background-color:#fff0f1;transform:translateX(-50%);transition:top .6s ease-in-out}.ZXF3T{top:0}.ra5cE{width:25%}.qm3jH{min-width:16px;margin-top:1px}.XgHf2{white-space:pre-wrap}.XgHf2::part(typography){color:#012d9c;font-family:"IBM Plex Mono",monospace;line-height:16px}.YG1KQ{font-weight:600}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibmNew/components/responsibilitiesInfo/ResponsibilitiesInfo.module.scss"],"names":[],"mappings":"AAEA,OACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,SAAA,CACA,WAAA,CACA,gBAAA,CACA,wBAAA,CACA,0BAAA,CACA,8BAAA,CAGF,OACE,KAAA,CAGF,OACE,SAAA,CAGF,OACE,cAAA,CACA,cAAA,CAGF,OACE,oBAAA,CAEA,yBACE,aAAA,CACA,qCAAA,CACA,gBAAA,CAIJ,OACE,eAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600&display=swap');\n\n.container {\n  position: absolute;\n  top: -81px;\n  left: 50%;\n  z-index: 1;\n  width: 910px;\n  padding: 8px 16px;\n  background-color: #fff0f1;\n  transform: translateX(-50%);\n  transition: top 0.6s ease-in-out;\n}\n\n.containerVisible {\n  top: 0;\n}\n\n.section {\n  width: 25%;\n}\n\n.icon {\n  min-width: 16px;\n  margin-top: 1px;\n}\n\n.text {\n  white-space: pre-wrap;\n\n  &::part(typography) {\n    color: #012d9c;\n    font-family: 'IBM Plex Mono', monospace;\n    line-height: 16px;\n  }\n}\n\n.bold {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `npWQZ`,
	"containerVisible": `ZXF3T`,
	"section": `ra5cE`,
	"icon": `qm3jH`,
	"text": `XgHf2`,
	"bold": `YG1KQ`
};
export default ___CSS_LOADER_EXPORT___;
