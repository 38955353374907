import { MayBeNull } from '@wpp-open/core'
import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/bp/BPProcess.module.scss'
import { ProcessSvg } from 'pages/customProcess/components/bp/processSvg/ProcessSvg'
import { Phases } from 'pages/customProcess/components/bp/types'

const links = {
  [Phases.Assemble]: '/custom/bp/assemble',
}

export const BPProcess = () => {
  const navigate = useNavigate()
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePhaseClick = useCallback(
    (phase: Phases.Assemble) => () => {
      navigate(links[phase])
    },
    [navigate],
  )

  return (
    <Flex justify="center" align="center" className={styles.root}>
      <div className={styles.svgWrapper}>
        <ProcessSvg
          activePhase={activePhase}
          onPhaseEnter={handlePhaseEnter}
          onPhaseLeave={handlePhaseLeave}
          onPhaseClick={handlePhaseClick}
        />
      </div>
    </Flex>
  )
}
