// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KsvoL{margin-bottom:24px;padding-bottom:24px;border-bottom:1px solid var(--wpp-grey-color-300)}.FhYIQ{border-bottom:none}.NVwe0{margin-bottom:10px;color:var(--wpp-grey-color-800)}._xKSE{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/phaseBuilder/additionalInformation/additionalInformationSideModal/additionalInformationSection/AdditionalInformationSection.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,mBAAA,CACA,iDAAA,CAGF,OACE,kBAAA,CAGF,OACE,kBAAA,CACA,+BAAA,CAGF,OACE,UAAA","sourcesContent":[".section {\n  margin-bottom: 24px;\n  padding-bottom: 24px;\n  border-bottom: 1px solid var(--wpp-grey-color-300);\n}\n\n.lastSection {\n  border-bottom: none;\n}\n\n.title {\n  margin-bottom: 10px;\n  color: var(--wpp-grey-color-800);\n}\n\n.sectionContentImg {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `KsvoL`,
	"lastSection": `FhYIQ`,
	"title": `NVwe0`,
	"sectionContentImg": `_xKSE`
};
export default ___CSS_LOADER_EXPORT___;
