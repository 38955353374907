import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/easyjet/EasyJetProcess.module.scss'
import { Process } from 'pages/customProcess/components/easyjet/utils/process'
import { ProcessNote } from 'pages/customProcess/components/easyjet/utils/ProcessNote'

export const EasyJetProcess = () => {
  const [process, setProcess] = useState(Process.default)

  const processNote = (): {
    title: string
    description: string
    steps?: { title: string; description: string; secondDescription?: string }[]
  } => {
    switch (process) {
      case Process.InFlight:
        return {
          title: 'In Flight',
          description:
            'The In Flight phase is the time to bring our media plans to life and start flexing to reflect the consumer flow and optimise metrics and outcomes. Live outcome monitoring helps us capitalise on automation opportunities and ensure correct attribution across channels',
          steps: [
            {
              title: 'Activate & Automate',
              description: 'Consists of key learnings from platforms across addressable and non-addressable channels',
            },
            {
              title: 'Optimize',
              description:
                'Three elements to continually action: Key Watch outs, Real time factors, Bid management vs Custom Algorithm.',
            },
            {
              title: 'Attribute',
              description: 'What new learnings are we taking forward?',
            },
          ],
        }
      case Process.PreFlight:
        return {
          title: 'Pre-Flight',
          description:
            '"What is the challenge, and how do we solve it?" This is the question Pre-Flight will enable us to answer. Before diving into the planning process, we think about the brand, the brief and the context of the business at this moment in time',
          steps: [
            {
              title: 'Uncover Challenge',
              description: 'What is the biggest change you wish to make?',
              secondDescription: 'And what is the barrier to making this change?',
            },
            {
              title: 'Define Success',
              description: 'To drive business growth, we must be clear on how we deliver meaningful results.',
            },
            {
              title: 'Audience Planning',
              description: 'Where will growth come from and how will you be as relevant as possible to those people?',
            },
            {
              title: 'Strategy',
              description: 'What makes you unique/ motivating in people’s minds?',
              secondDescription: 'Create one singular statement that drives all action moving forward.',
            },
          ],
        }
      case Process.TakeOff:
        return {
          title: 'Take Off',
          description:
            'Take Off allows us to build a plan that will solve the client challenge, delivering on the strategy identified in the Pre-Flight phase. The steps in this phase are about weighing up two things at a time and making decisions that reflect industry knowledge and experience.',
          steps: [
            { title: 'Ideas', description: 'How do we translate strategy into comms?' },
            {
              title: 'Experience & Innovation',
              description: 'Balancing what we know from the past with what new things we want to do in the future',
            },
            {
              title: 'Brand & Performance',
              description: 'Balancing brand building, awareness, above the line with response / below the line',
            },
            {
              title: 'Channels & Signals',
              description: 'Balancing expertise in communications vehicles like TV, Radio, OOH with human data signals',
            },
            { title: 'Plan', description: 'How do we combine all into a brilliant plan?' },
          ],
        }

      default:
        return {
          title: 'Our unique solution that capitalizes on heightened awareness',
          description:
            'Air Flow is our planning process that is here for us to work better together, to be more consistent and to have the time and space to do better work. Air Flow is built to Pre-Flight, Take Off and In Flight. These three phases combine to produce activity that is effective and flexible',
          steps: [],
        }
    }
  }

  return (
    <Flex className={styles.container} justify="around">
      <Flex direction="column" gap={16} className={styles.note}>
        <Flex direction="column" gap={16}>
          <WppTypography type="3xl-heading">{processNote().title}</WppTypography>
          <WppTypography type="s-midi">{processNote().description}</WppTypography>
          {processNote()?.steps?.map(({ title, description, secondDescription }, index) => {
            return (
              <ProcessNote
                title={title}
                description={description}
                secondDescription={secondDescription}
                key={index}
                index={index}
              />
            )
          })}
        </Flex>
      </Flex>
      <Flex
        align="center"
        justify="center"
        onMouseLeave={() => setProcess(Process.default)}
        className={clsx(styles.process, styles[process])}
      >
        <Flex className={styles.preFlight} onMouseOver={() => setProcess(Process.PreFlight)} />
        <Flex className={styles.takeOff} onMouseOver={() => setProcess(Process.TakeOff)} />
        <Flex className={styles.inFlight} onMouseOver={() => setProcess(Process.InFlight)} />
        <Flex direction="column" justify="center" align="center" className={styles.airFlow}>
          <WppTypography className={styles.airlabel} type="4xl-display">
            Air
          </WppTypography>
          <WppTypography className={styles.airlabel} type="4xl-display">
            Flow
          </WppTypography>
        </Flex>
      </Flex>
    </Flex>
  )
}
