import { WppRadioGroup } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppRadioGroup>, 'onChange'> {
  name: string
}

export const FormRadioGroup = forwardRef<HTMLWppRadioGroupElement, Props>(
  ({ name, onWppBlur, onWppChange, children, ...rest }, ref) => {
    const {
      field: { value, onChange, onBlur },
    } = useField({ name })

    return (
      <WppRadioGroup
        {...rest}
        ref={ref}
        value={value}
        onWppChange={e => {
          onChange(e.detail.value)
          onWppChange?.(e)
        }}
        onWppBlur={e => {
          onBlur()
          onWppBlur?.(e)
        }}
      >
        {children}
      </WppRadioGroup>
    )
  },
)
