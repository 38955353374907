import schemeSrc from 'pages/customProcess/components/loreal/assets/phaseDetails/media-planning.png'
import { processPhasesData } from 'pages/customProcess/components/loreal/LorealConfig'
import styles from 'pages/customProcess/components/loreal/LorealProcess.module.scss'
import { PageLayout } from 'pages/customProcess/components/loreal/processPhases/pageLayout/PageLayout'

const MediaPlanning = () => {
  const { title, steps, previousLink, nextLink } = processPhasesData[2]

  return (
    <PageLayout title={title} steps={steps} previousLink={previousLink} nextLink={nextLink}>
      <img src={schemeSrc} alt="" className={styles.schemeImg} />
    </PageLayout>
  )
}

export default MediaPlanning
