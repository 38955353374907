import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { useOs } from '@wpp-open/react'
import { useLayoutEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'

import styles from 'app/App.module.scss'
import { CustomProcessRoutes } from 'pages/customProcess/CustomProcessRoutes'
import { CustomWidgetRoutes } from 'pages/customWidget/CustomWidgetRoutes'
import { NotFound } from 'pages/notFound/NotFound'
import { PhaseBuilder } from 'pages/phaseBuilder/PhaseBuilder'
import { ProcessBuilder } from 'pages/processBuilder/ProcessBuilder'
import { routesManager } from 'utils/routesManager'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    setTimeout(() => {
      window.scroll({ top: 0, left: 0 })
    }, 0)
  }, [pathname])

  return null
}

export const App = () => {
  const {
    osContext: { baseUrl },
  } = useOs()

  return (
    <div
      className={styles.root}
      id="app-process-builder-root"
      data-testid="app-process-builder-root"
      data-build={process.env.REACT_APP_BUILD_NUMBER}
      data-build-branch={process.env.REACT_APP_BRANCH}
      data-commit={process.env.REACT_APP_COMMIT_SHA}
    >
      <BrowserRouter basename={baseUrl}>
        <NiceModalProvider>
          <ScrollToTop />
          <Routes>
            <Route path={routesManager.process.url}>
              <Route index element={<ProcessBuilder />} />
              <Route path={routesManager.phase.path} element={<PhaseBuilder />} />
            </Route>

            <Route path={routesManager.phase.url} element={<PhaseBuilder />} />

            <Route path={routesManager.customProcess.url}>
              <Route index path="*" element={<CustomProcessRoutes />} />
            </Route>

            <Route path={routesManager.customWidget.url}>
              <Route index path="*" element={<CustomWidgetRoutes />} />
            </Route>

            <Route path={routesManager.notFound.url} element={<NotFound />} />
            <Route path="*" element={<Navigate to={routesManager.notFound.url} />} />
          </Routes>
        </NiceModalProvider>
      </BrowserRouter>
    </div>
  )
}
