import { WppTypography, WppTooltip, WppIconRepeatOn, WppIconChevron } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/nestleAsia/processScheme/processPhaseInfo/ProcessPhaseInfo.module.scss'

interface Props {
  showWhenNecessary?: boolean
  minimized?: boolean
  active: boolean
  tag?: string
  mroiTooltipText?: string
  title: string
  titleTooltipText?: string
  justifyTags?: 'start' | 'end' | 'center'
  showRepeatIcon?: boolean
  onSelectPhase: () => void
  link: string
}

export const ProcessPhaseInfo = ({
  showWhenNecessary,
  minimized,
  active,
  tag,
  mroiTooltipText,
  title,
  titleTooltipText,
  justifyTags = 'start',
  showRepeatIcon,
  onSelectPhase,
  link,
}: Props) => {
  return (
    <div>
      <Flex
        className={clsx(styles.phaseTagsContainerAsia, minimized && styles.minimizedPhaseTagsContainer)}
        gap={8}
        justify={justifyTags}
      >
        {tag && (
          <WppTypography type="2xs-strong" className={styles.tag}>
            {tag}
          </WppTypography>
        )}
        {mroiTooltipText && (
          <WppTooltip text={mroiTooltipText}>
            <WppTypography type="2xs-strong" className={clsx(styles.tag, styles.mroiTag)}>
              mroi
            </WppTypography>
          </WppTooltip>
        )}
      </Flex>
      <button onClick={onSelectPhase} className={styles.titleButton}>
        <div>
          <Link to={link} className={clsx(!active && styles.phaseInfoLinkInactive, styles.phaseInfoLink)}>
            {titleTooltipText ? (
              <WppTooltip text={titleTooltipText}>
                <Flex align="center" gap={4}>
                  {showRepeatIcon && <WppIconRepeatOn color="#3F8EF7" style={{ 'margin-right': 2 }} />}
                  <pre className={clsx(styles.title, active && styles.activeTitle, minimized && styles.minimizedTitle)}>
                    {title}
                  </pre>
                  {active && <WppIconChevron color="var(--wpp-grey-color-1000)" />}
                </Flex>
              </WppTooltip>
            ) : (
              <>
                <Flex align="center" gap={4}>
                  {showRepeatIcon && <WppIconRepeatOn color="#3F8EF7" />}
                  <pre className={clsx(styles.title, active && styles.activeTitle, minimized && styles.minimizedTitle)}>
                    {title}
                  </pre>
                  {active && <WppIconChevron color="var(--wpp-grey-color-1000)" />}
                </Flex>
                {active && showWhenNecessary && (
                  <WppTypography type="2xs-strong" className={styles.whenNecessaryText}>
                    when necessary
                  </WppTypography>
                )}
              </>
            )}
          </Link>
        </div>
      </button>
    </div>
  )
}
