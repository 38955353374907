// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T0ZXV{background-color:#fff}.qeTcY{position:relative;height:calc(100vh - var(--wpp-os-header-height));background:radial-gradient(circle, #0155e5 0, #012159 100%)}.MtaIL{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/tui/TuiProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,qBAAA,CAGF,OACE,iBAAA,CACA,gDAAA,CACA,2DAAA,CAGF,OACE,cAAA","sourcesContent":[".svg {\n  background-color: white;\n}\n\n.root {\n  position: relative;\n  height: calc(100vh - var(--wpp-os-header-height));\n  background: radial-gradient(circle, #0155e5 0, #012159 100%);\n}\n\n.svgPoint {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg": `T0ZXV`,
	"root": `qeTcY`,
	"svgPoint": `MtaIL`
};
export default ___CSS_LOADER_EXPORT___;
