import { Flex } from 'components/common/flex/Flex'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/bigIdeaDevelopment/BigIdeaDevelopment.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const BigIdeaDevelopment = ({ isDemoTenant = false }: Props) => {
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/portfolio-allocation'

  return (
    <ProcessCard
      title="The Big Brief Idea"
      subtitle="The beginning of campaign planning planning process"
      bepTitle="BEP 1-2"
      isDemoTenant={isDemoTenant}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper} gap={10}>
          <InputsOutputsCard title="inputs" text="Business Goals" cardStyles={styles.inputsCard} />
          <InputsOutputsCard title="inputs" text="Brand Goals" cardStyles={styles.inputsCard} />
        </Flex>
        <RegularCard
          text="Articulate business aspirations and the role of paid media in achieving them"
          teamTags={[{ text: 'Strategic Planning', tags: ['R'] }]}
        />
        <InputsOutputsCard
          title="outputs"
          text="Campaign brief communicated to all stakeholders"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default BigIdeaDevelopment
