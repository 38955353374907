import { WppActionButton, WppIconChevron, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/sein/processPhases/components/footerNavigation/FooterNavigation.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  previousLink?: string
  nextLink?: string
}

export const FooterNavigation = ({ previousLink, nextLink }: Props) => {
  return (
    <div className={styles.seinfooterNavWrapper}>
      <Flex align="center" justify="between" className={styles.seinfooterNav}>
        <Flex gap={16}>
          <Link to={previousLink ?? ''}>
            <WppActionButton
              className={clsx(styles.seinNavButton, !previousLink && styles.seinNavButtonDisabled)}
              variant="secondary"
              disabled={!previousLink}
            >
              <WppIconChevron color={previousLink ? '#0079cc' : 'var(--wpp-grey-color-500)'} direction="left" />
              Previous phase
            </WppActionButton>
          </Link>
          <Link to={nextLink ?? ''}>
            <WppActionButton
              className={clsx(styles.seinNavButton, !nextLink && styles.seinNavButtonDisabled)}
              variant="secondary"
              disabled={!nextLink}
            >
              Next phase <WppIconChevron color={nextLink ? '#0079cc' : 'var(--wpp-grey-color-500)'} />
            </WppActionButton>
          </Link>
        </Flex>
        <Link to={routesManager.systemUrls.orchestration.root.url}>
          <WppButton className={styles.seinProjectButton}>Go to Projects</WppButton>
        </Link>
      </Flex>
    </div>
  )
}
