import { discoverContent } from 'pages/customProcess/components/lego/legoConfig'
import { PageLayout } from 'pages/customProcess/components/lego/processPhases/pageLayout/PageLayout'

const Discover = () => {
  const { type, title, description, headerIllustrationImgSrc, schemeImgSrc, previousLink, nextLink } = discoverContent
  return (
    <PageLayout
      type={type}
      title={title}
      description={description}
      headerIllustrationImgSrc={headerIllustrationImgSrc}
      schemeImgSrc={schemeImgSrc}
      previousLink={previousLink}
      nextLink={nextLink}
    />
  )
}

export default Discover
