import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import { WppActionButton, WppIconLink, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/form/formDimensions/FormDimensions.module.scss'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { useField } from 'hooks/form/useField'
import i18n from 'i18n/i18n'
import { DimensionUnits } from 'types/common/comon'

export interface FormDimensionsProps {
  name: string
  className?: string
  variant?: 'row' | 'column'
  required?: boolean
  hidden?: boolean
  label?: string
  widthLabel?: string
  heightLabel?: string
}

export const sizeOptions = [
  { label: 'px', value: DimensionUnits.pixel },
  { label: '%', value: DimensionUnits.percent },
  { label: 'Auto', value: DimensionUnits.auto },
]

const normalizeToNumeric = (value: string) => parseInt(value?.replace(/[^\d]/g, '')) || undefined

export const FormDimensions = ({
  name,
  className,
  variant = 'row',
  required,
  hidden,
  label,
  widthLabel = i18n.t('common.width'),
  heightLabel = i18n.t('common.height'),
}: FormDimensionsProps) => {
  const {
    field: { value: width, onChange: setWidth },
  } = useField({
    name: `${name}.width`,
  })
  const {
    field: { value: height, onChange: setHeight },
  } = useField({
    name: `${name}.height`,
  })
  const [aspectRatio, setAspectRatio] = useState<number | null>()

  const handleAspectRatioClick = useCallback(() => {
    setAspectRatio(aspectRatio ? null : parseInt(width.value) / parseInt(height.value))
  }, [aspectRatio, width.value, height.value])

  const handleWidthChange = useCallback(
    ({ detail }: WppSelectCustomEvent<any>) => {
      aspectRatio && setHeight({ ...height, value: Math.floor(parseInt(detail.inputValue) / aspectRatio) || undefined })
    },
    [aspectRatio, setHeight, height],
  )

  const handleHeightChange = useCallback(
    ({ detail }: WppSelectCustomEvent<any>) => {
      aspectRatio && setWidth({ ...width, value: Math.floor(parseInt(detail.inputValue) * aspectRatio) || undefined })
    },
    [aspectRatio, setWidth, width],
  )

  if (hidden) return null

  return (
    <Flex direction="column" gap={15} className={className}>
      {label && <WppTypography type="m-strong">{label}</WppTypography>}

      <Flex justify="between" align="end" gap={5} direction={variant}>
        <FormSelect
          name={`${name}.width`}
          labelConfig={{ text: widthLabel }}
          options={sizeOptions}
          type="combined"
          selectKey="unit"
          inputKey="value"
          onWppChange={handleWidthChange}
          normalizeInputValue={normalizeToNumeric}
          required={required}
        />

        <WppActionButton
          onClick={handleAspectRatioClick}
          className={clsx(styles.ratioButton, { [styles.isActive]: aspectRatio })}
        >
          <WppIconLink />
        </WppActionButton>

        <FormSelect
          name={`${name}.height`}
          labelConfig={{ text: heightLabel }}
          options={sizeOptions}
          type="combined"
          selectKey="unit"
          inputKey="value"
          onWppChange={handleHeightChange}
          normalizeInputValue={normalizeToNumeric}
          required={required}
        />
      </Flex>
    </Flex>
  )
}
