import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (8).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Attribution = () => {
  return (
    <ProcessCard
      type="activation"
      title="Attribution/ Measurement"
      description="What new learnings are we taking forward?"
      previousLink="/custom/fossil/automation"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Attribution
