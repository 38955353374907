// import { WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
// import { useRef } from 'react'
// import { Link } from 'react-router-dom'

// import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick'
import { ProcessSchemePhase } from 'pages/customProcess/components/nestleAsia/constants'
import styles from 'pages/customProcess/components/nestleAsia/processScheme/ProcessScheme.module.scss'
import { ProcessSchemeSvgDefs } from 'pages/customProcess/components/nestleAsia/processScheme/processSchemeSvgDefs/ProcessSchemeSvgDefs'

interface Props {
  selectedPhase: ProcessSchemePhase
  onSelectPhase: (section: ProcessSchemePhase) => void
}

export const ProcessScheme = ({ selectedPhase, onSelectPhase }: Props) => {
  // const processDetailsLinkRef = useRef(null)
  // const synapsePhaseWrapperRef = useRef(null)
  // const synapsePhase2WrapperRef = useRef(null)
  // const bavAudienceDBWrapperRef = useRef(null)
  // const synapsePhase3WrapperRef = useRef(null)
  // const choreographSynapsePhaseWrapperRef = useRef(null)
  // const mediaActivityPlanPhaseWrapperRef = useRef(null)
  // const nexusPhaseWrapperRef = useRef(null)
  // const adverityPhaseWrapperRef = useRef(null)

  // useDetectOutsideClick({
  //   refs: [
  //     synapsePhaseWrapperRef,
  //     synapsePhase2WrapperRef,
  //     bavAudienceDBWrapperRef,
  //     synapsePhase3WrapperRef,
  //     choreographSynapsePhaseWrapperRef,
  //     mediaActivityPlanPhaseWrapperRef,
  //     nexusPhaseWrapperRef,
  //     adverityPhaseWrapperRef,
  //   ],
  //   excludedRefs: [processDetailsLinkRef],
  //   onOutsideClick: () => {
  //     setTimeout(() => onSelectPhase(ProcessSchemePhase.EMPTY), 200)
  //   },
  // })

  // const getProcessDetailsPath = () => {
  //   switch (selectedPhase) {
  //     case ProcessSchemePhase.WHEN_NECESSARY:
  //       return 'big-idea-development'
  //     case ProcessSchemePhase.SYNAPSE:
  //       return 'portfolio-allocation-communication-strategy'
  //     case ProcessSchemePhase.SYNAPSE_2:
  //       return 'campaign-brief'
  //     case ProcessSchemePhase.BAV_AUDIENCE_DB:
  //       return 'campaign-strategy'
  //     case ProcessSchemePhase.SYNAPSE_3:
  //       return 'campaign-budget-setting'
  //     case ProcessSchemePhase.CHOREOGRAPH_SYNAPSE:
  //       return 'strategic-media-planning'
  //     case ProcessSchemePhase.MEDIA_ACTIVITY_PLAN:
  //       return 'media-plan'
  //     case ProcessSchemePhase.NEXUS:
  //       return 'media-activation'
  //     case ProcessSchemePhase.ADVERITY:
  //       return 'campaign-analysis'
  //     default:
  //       return '#'
  //   }
  // }

  return (
    <div>
      {/* <Link
        to={getProcessDetailsPath()}
        className={clsx(selectedPhase === ProcessSchemePhase.EMPTY && styles.hiddenLink)}
        ref={processDetailsLinkRef}
      >
        <WppButton className={styles.goToProcessDetailsBtn}>Go to process details</WppButton>
      </Link> */}
      <div
        className={clsx(
          styles.processSchemeBg,
          selectedPhase !== ProcessSchemePhase.EMPTY && styles.processSchemeBgBlur,
        )}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={729}
        height={712}
        viewBox="0 0 729 712"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        className={styles.processSchemeSvg}
      >
        {/* Start Outside Circle Stroke */}
        <g filter="url(#i)">
          <circle cx={367.5} cy={355.5} r={334.5} stroke="url(#k)" strokeWidth={2} />
        </g>
        {/* End Outside Circle Stroke */}
        {/* Star Outside Circle Arrows */}
        <path
          d="M493.89 44.6904C494.346 45.3106 493.96 46.1924 493.194 46.2772L481.721 47.5488C480.956 47.6336 480.386 46.8586 480.695 46.1536L485.325 35.586C485.633 34.881 486.589 34.7746 487.046 35.3944L493.89 44.6904Z"
          fill="url(#paint22_linear_292_32254)"
        />
        <path
          d="M661.672 194.673C661.681 195.443 660.853 195.935 660.182 195.557L650.118 189.904C649.447 189.527 649.435 188.565 650.097 188.172L660.018 182.283C660.68 181.89 661.519 182.361 661.529 183.13L661.672 194.673Z"
          fill="url(#paint23_linear_292_32254)"
        />
        <path
          d="M704.095 407.739C704.854 407.865 705.192 408.767 704.702 409.361L697.362 418.271C696.873 418.865 695.924 418.706 695.654 417.985L691.607 407.181C691.337 406.461 691.949 405.718 692.708 405.844L704.095 407.739Z"
          fill="url(#paint24_linear_292_32254)"
        />
        <path
          d="M599.126 603.122C599.686 603.651 599.462 604.588 598.724 604.807L587.659 608.097C586.922 608.316 586.223 607.655 586.402 606.906L589.08 595.684C589.259 594.935 590.181 594.661 590.74 595.19L599.126 603.122Z"
          fill="url(#paint25_linear_292_32254)"
        />
        <path
          d="M373.97 695.275C373.967 696.045 373.132 696.523 372.467 696.135L362.496 690.318C361.831 689.93 361.835 688.968 362.503 688.586L372.519 682.859C373.187 682.477 374.018 682.962 374.015 683.731L373.97 695.275Z"
          fill="url(#paint26_linear_292_32254)"
        />
        <path
          d="M146.088 615.932C145.513 616.445 144.599 616.143 144.442 615.389L142.094 604.087C141.938 603.333 142.656 602.693 143.387 602.934L154.344 606.546C155.075 606.787 155.271 607.729 154.697 608.242L146.088 615.932Z"
          fill="url(#paint27_linear_292_32254)"
        />
        <path
          d="M32.7698 420.985C32.0054 421.078 31.4265 420.309 31.728 419.601L36.2477 408.979C36.549 408.27 37.504 408.154 37.9668 408.769L44.905 417.987C45.3678 418.601 44.9916 419.487 44.2277 419.58L32.7698 420.985Z"
          fill="url(#paint28_linear_292_32254)"
        />
        <path
          d="M66.7708 195.219C66.1135 194.818 66.1373 193.855 66.8136 193.487L76.952 187.967C77.628 187.599 78.4489 188.101 78.4299 188.87L78.1448 200.404C78.1257 201.173 77.2811 201.634 76.6241 201.233L66.7708 195.219Z"
          fill="url(#paint29_linear_292_32254)"
        />
        <path
          d="M243.804 39.0301C243.521 38.3141 244.119 37.5598 244.881 37.6727L256.3 39.3657C257.061 39.4786 257.415 40.3732 256.936 40.9763L249.768 50.016C249.29 50.619 248.338 50.4784 248.055 49.7628L243.804 39.0301Z"
          fill="url(#paint30_linear_292_32254)"
        />
        {/* End Outside Circle Arrows */}
        {/* Start SYNAPSE Phase */}
        {selectedPhase === ProcessSchemePhase.SYNAPSE && (
          <g
            transform="translate(1, -1)"
            // ref={synapsePhaseWrapperRef}
          >
            <g filter="url(#filter0_d_195_820)">
              <path
                d="M655.979 191.636C616.868 122.786 554.536 70.0988 480.134 43L404 251.862C428.335 260.725 448.053 279.965 460.845 302.484L655.979 191.636Z"
                fill="url(#paint0_linear_195_820)"
              />
            </g>
            <path
              d="M655.587 191.209C615.988 122.842 554.42 69.8908 480 43.3618L447.377 132.886C500.793 151.928 545.714 189.315 574.136 238.386L655.587 191.209Z"
              fill="url(#paint1_linear_195_820)"
            />
            <path
              d="M501.634 110.972L506.76 102.152L510.129 104.1C510.762 104.463 511.237 104.876 511.543 105.317C511.849 105.759 512.009 106.221 512.019 106.688C512.03 107.155 511.911 107.606 511.657 108.046C511.446 108.405 511.206 108.675 510.915 108.849C510.624 109.023 510.328 109.127 510.003 109.142C509.679 109.17 509.349 109.128 509.028 109.028L508.979 109.117C509.28 109.318 509.528 109.58 509.713 109.919C509.897 110.258 509.993 110.64 509.992 111.058C509.992 111.477 509.858 111.914 509.597 112.363C509.33 112.819 508.983 113.168 508.555 113.397C508.121 113.634 507.625 113.727 507.061 113.684C506.497 113.641 505.875 113.419 505.203 113.023L501.64 110.964L501.634 110.972ZM506.17 106.83L507.75 107.742C508.026 107.895 508.304 107.999 508.583 108.024C508.862 108.049 509.121 108.005 509.359 107.892C509.598 107.779 509.796 107.592 509.937 107.331C510.135 106.987 510.178 106.643 510.069 106.276C509.961 105.91 509.672 105.601 509.196 105.331L507.585 104.393L506.17 106.83ZM504.005 110.559L505.719 111.545C506.3 111.877 506.779 112.006 507.163 111.937C507.548 111.868 507.849 111.65 508.047 111.306C508.203 111.044 508.273 110.775 508.27 110.485C508.261 110.202 508.181 109.925 508.01 109.663C507.839 109.401 507.594 109.182 507.265 108.983L505.513 107.965L504.011 110.552L504.005 110.559Z"
              fill="white"
            />
            <path
              d="M511.816 116.996L515.886 110.527L517.361 111.456L516.685 112.536L516.746 112.587C517.11 112.279 517.494 112.118 517.904 112.096C518.32 112.067 518.712 112.175 519.079 112.405C519.161 112.462 519.258 112.517 519.35 112.594C519.441 112.672 519.532 112.735 519.586 112.794L518.703 114.197C518.655 114.13 518.564 114.053 518.434 113.944C518.304 113.835 518.183 113.746 518.056 113.665C517.78 113.497 517.487 113.395 517.202 113.377C516.903 113.361 516.631 113.42 516.372 113.542C516.114 113.678 515.896 113.874 515.728 114.152L513.336 117.963L511.817 117.01L511.816 116.996Z"
              fill="white"
            />
            <path
              d="M520.421 122.734C520.023 122.464 519.713 122.15 519.484 121.787C519.262 121.43 519.159 121.042 519.161 120.637C519.169 120.225 519.305 119.816 519.59 119.401C519.829 119.039 520.1 118.796 520.385 118.643C520.678 118.497 520.978 118.435 521.306 118.449C521.633 118.463 521.958 118.527 522.295 118.654C522.624 118.774 522.962 118.915 523.296 119.092C523.69 119.306 524.017 119.476 524.268 119.596C524.519 119.716 524.724 119.783 524.873 119.791C525.023 119.8 525.152 119.725 525.246 119.582L525.265 119.559C525.474 119.249 525.548 118.945 525.473 118.646C525.399 118.348 525.2 118.088 524.847 117.857C524.479 117.612 524.135 117.493 523.818 117.514C523.5 117.534 523.231 117.621 523.01 117.79L521.752 116.651C522.123 116.334 522.525 116.136 522.95 116.049C523.376 115.963 523.824 115.973 524.274 116.09C524.723 116.207 525.174 116.416 525.617 116.711C525.925 116.918 526.2 117.15 526.458 117.432C526.708 117.709 526.91 118.01 527.045 118.346C527.186 118.675 527.237 119.032 527.198 119.418C527.159 119.805 527.001 120.209 526.717 120.624L523.857 124.868L522.421 123.908L523.009 123.041L522.963 123.002C522.746 123.12 522.511 123.198 522.245 123.249C521.979 123.287 521.687 123.276 521.383 123.204C521.079 123.131 520.761 122.981 520.424 122.762L520.421 122.734ZM521.544 121.898C521.844 122.099 522.138 122.215 522.439 122.246C522.739 122.277 523.012 122.231 523.272 122.109C523.525 121.995 523.729 121.814 523.887 121.58L524.386 120.834C524.315 120.841 524.214 120.821 524.076 120.783C523.945 120.738 523.806 120.686 523.644 120.615C523.482 120.544 523.341 120.478 523.193 120.406C523.045 120.334 522.913 120.275 522.816 120.219C522.579 120.098 522.337 120.013 522.12 119.961C521.903 119.909 521.69 119.913 521.511 119.971C521.323 120.023 521.164 120.15 521.038 120.346C520.856 120.624 520.808 120.898 520.916 121.172C521.024 121.447 521.228 121.685 521.544 121.898Z"
              fill="white"
            />
            <path
              d="M531.641 124.896L528.998 128.511L527.547 127.446L532.07 121.275L533.46 122.289L532.696 123.335L532.765 123.393C533.163 123.152 533.586 123.038 534.05 123.061C534.513 123.084 534.975 123.264 535.436 123.6C535.866 123.91 536.166 124.267 536.349 124.684C536.533 125.101 536.584 125.537 536.51 126.011C536.436 126.486 536.213 126.967 535.85 127.46L532.97 131.386L531.519 130.321L534.233 126.621C534.54 126.211 534.669 125.81 534.622 125.417C534.583 125.03 534.37 124.693 534 124.421C533.75 124.237 533.479 124.125 533.206 124.092C532.934 124.059 532.661 124.105 532.395 124.234C532.129 124.364 531.891 124.569 531.675 124.872L531.641 124.896Z"
              fill="white"
            />
            <path
              d="M538.698 136.022C538.227 135.652 537.906 135.197 537.733 134.659C537.554 134.128 537.541 133.541 537.694 132.91C537.847 132.28 538.183 131.633 538.696 130.978C539.23 130.314 539.779 129.834 540.366 129.556C540.945 129.271 541.524 129.156 542.083 129.221C542.642 129.286 543.156 129.496 543.611 129.854C543.961 130.136 544.205 130.419 544.335 130.713C544.466 131.007 544.533 131.299 544.534 131.561C544.536 131.838 544.512 132.067 544.472 132.269L544.526 132.314L546.901 129.325L548.307 130.444L541.964 138.433L540.581 137.333L541.325 136.389L541.241 136.318C541.05 136.406 540.822 136.475 540.556 136.527C540.291 136.578 539.993 136.576 539.682 136.511C539.372 136.446 539.039 136.283 538.697 136.008L538.698 136.022ZM540.011 135.177C540.307 135.413 540.623 135.535 540.963 135.534C541.297 135.54 541.643 135.439 541.988 135.231C542.332 135.024 542.656 134.733 542.957 134.345C543.266 133.963 543.474 133.576 543.6 133.21C543.726 132.845 543.739 132.489 543.655 132.17C543.572 131.851 543.385 131.562 543.074 131.327C542.763 131.077 542.433 130.957 542.099 130.951C541.759 130.952 541.419 131.046 541.089 131.252C540.751 131.452 540.441 131.741 540.139 132.115C539.837 132.489 539.634 132.869 539.502 133.242C539.37 133.615 539.351 133.979 539.435 134.312C539.513 134.653 539.707 134.934 540.019 135.183L540.011 135.177Z"
              fill="white"
            />
            <path
              d="M551.308 146.376L549.85 145.056L559.339 139.884L561.028 141.412L556.849 151.384L555.39 150.064L558.811 142.23L558.75 142.178L551.308 146.376ZM554.037 143.448L558.019 147.039L557.025 148.14L553.043 144.55L554.037 143.448Z"
              fill="white"
            />
            <path
              d="M567.884 154.234L566.586 153.219C566.673 153.07 566.723 152.902 566.736 152.717C566.756 152.538 566.726 152.342 566.659 152.142C566.593 151.942 566.449 151.741 566.256 151.552C565.993 151.292 565.71 151.132 565.413 151.065C565.124 151.005 564.881 151.062 564.705 151.255C564.548 151.41 564.479 151.601 564.504 151.819C564.529 152.036 564.645 152.317 564.864 152.646L565.602 153.752C566.008 154.37 566.224 154.918 566.23 155.408C566.249 155.895 566.059 156.338 565.687 156.732C565.347 157.074 564.952 157.279 564.497 157.354C564.049 157.421 563.568 157.357 563.077 157.165C562.585 156.974 562.112 156.653 561.655 156.204C560.991 155.552 560.587 154.885 560.453 154.208C560.318 153.532 560.457 152.902 560.87 152.32L562.239 153.421C562.051 153.721 561.992 154.024 562.053 154.338C562.114 154.638 562.288 154.942 562.589 155.235C562.898 155.535 563.204 155.714 563.513 155.765C563.823 155.816 564.066 155.759 564.249 155.573C564.407 155.417 564.47 155.235 564.451 155.009C564.426 154.791 564.319 154.531 564.124 154.236L563.386 153.129C562.972 152.506 562.762 151.949 562.77 151.445C562.777 150.94 562.974 150.49 563.36 150.095C563.686 149.754 564.066 149.551 564.487 149.486C564.913 149.413 565.366 149.48 565.829 149.674C566.292 149.868 566.743 150.169 567.183 150.592C567.817 151.218 568.189 151.845 568.291 152.482C568.394 153.119 568.259 153.712 567.893 154.255L567.884 154.234Z"
              fill="white"
            />
            <path
              d="M575.255 161.859L573.996 160.798C574.083 160.649 574.147 160.48 574.167 160.301C574.194 160.114 574.171 159.924 574.105 159.724C574.039 159.524 573.908 159.323 573.714 159.12C573.457 158.851 573.187 158.676 572.891 158.61C572.601 158.536 572.357 158.592 572.174 158.779C572.009 158.928 571.932 159.112 571.951 159.337C571.97 159.563 572.07 159.831 572.276 160.175L572.967 161.307C573.353 161.933 573.542 162.498 573.539 162.981C573.537 163.464 573.339 163.899 572.946 164.289C572.59 164.618 572.195 164.808 571.731 164.863C571.274 164.91 570.799 164.838 570.313 164.624C569.827 164.411 569.366 164.076 568.931 163.617C568.285 162.942 567.909 162.258 567.803 161.579C567.696 160.9 567.857 160.276 568.299 159.705L569.63 160.852C569.434 161.146 569.36 161.451 569.414 161.758C569.468 162.066 569.628 162.371 569.916 162.665C570.211 162.98 570.511 163.167 570.814 163.226C571.124 163.291 571.368 163.234 571.552 163.062C571.71 162.906 571.787 162.722 571.775 162.503C571.764 162.284 571.664 162.016 571.475 161.713L570.778 160.589C570.385 159.956 570.202 159.383 570.224 158.878C570.246 158.372 570.464 157.927 570.865 157.545C571.206 157.217 571.588 157.027 572.024 156.975C572.459 156.923 572.899 157.005 573.356 157.207C573.812 157.408 574.252 157.739 574.671 158.171C575.285 158.82 575.637 159.456 575.711 160.095C575.786 160.734 575.636 161.315 575.248 161.853L575.255 161.859Z"
              fill="white"
            />
            <path
              d="M576.262 171.6C575.753 171.027 575.44 170.422 575.316 169.794C575.193 169.167 575.247 168.544 575.494 167.927C575.74 167.309 576.162 166.748 576.756 166.213C577.351 165.693 577.961 165.342 578.607 165.179C579.253 165.016 579.878 165.019 580.482 165.2C581.086 165.382 581.635 165.752 582.12 166.292C582.434 166.648 582.664 167.025 582.834 167.443C582.996 167.855 583.068 168.296 583.048 168.737C583.028 169.179 582.897 169.644 582.651 170.106C582.406 170.567 582.039 171.018 581.537 171.459L581.12 171.829L576.99 167.144L577.902 166.34L580.896 169.741C581.154 169.513 581.338 169.248 581.441 168.955C581.552 168.669 581.577 168.376 581.517 168.076C581.456 167.776 581.32 167.504 581.093 167.247C580.85 166.977 580.565 166.789 580.252 166.696C579.939 166.603 579.619 166.595 579.306 166.672C578.993 166.749 578.7 166.895 578.443 167.123L577.643 167.825C577.305 168.117 577.076 168.435 576.947 168.758C576.818 169.082 576.782 169.418 576.853 169.753C576.915 170.081 577.083 170.393 577.342 170.689C577.515 170.887 577.698 171.041 577.892 171.152C578.086 171.263 578.29 171.33 578.504 171.34C578.719 171.364 578.929 171.332 579.142 171.249L580.108 172.627C579.707 172.839 579.276 172.948 578.836 172.943C578.396 172.939 577.954 172.829 577.509 172.598C577.071 172.373 576.653 172.034 576.262 171.6Z"
              fill="white"
            />
            <path
              d="M589.664 175.349L588.589 176.237L585.804 172.856L586.878 171.968L589.664 175.349ZM588.979 171.643L590.122 173.025L584.589 177.593C584.404 177.751 584.281 177.896 584.229 178.035C584.177 178.175 584.173 178.303 584.212 178.427C584.251 178.551 584.324 178.665 584.419 178.785C584.49 178.871 584.56 178.943 584.636 179.007C584.712 179.071 584.766 179.116 584.804 179.148L583.913 180.283C583.824 180.234 583.71 180.151 583.573 180.036C583.436 179.92 583.268 179.779 583.11 179.58C582.811 179.237 582.612 178.886 582.496 178.513C582.379 178.14 582.384 177.764 582.495 177.399C582.606 177.035 582.848 176.702 583.22 176.4L588.985 171.635L588.979 171.643Z"
              fill="white"
            />
            <path
              d="M511.383 125.94L509.197 134.107L509.273 134.171L515.893 128.889L517.582 129.997L508.998 136.572L507.099 135.326L509.688 124.839L511.377 125.948L511.383 125.94Z"
              fill="white"
            />
            <path
              d="M516.294 141.751C515.902 141.473 515.599 141.151 515.39 140.779C515.175 140.415 515.086 140.025 515.102 139.619C515.118 139.214 515.268 138.803 515.56 138.394C515.815 138.046 516.086 137.802 516.372 137.664C516.666 137.532 516.972 137.462 517.294 137.484C517.615 137.506 517.941 137.584 518.278 137.711C518.615 137.837 518.94 137.993 519.267 138.178C519.655 138.399 519.975 138.577 520.227 138.711C520.472 138.839 520.676 138.906 520.826 138.915C520.976 138.923 521.098 138.855 521.2 138.719L521.219 138.696C521.435 138.393 521.509 138.089 521.456 137.795C521.404 137.502 521.197 137.236 520.857 136.989C520.495 136.737 520.158 136.61 519.84 136.616C519.521 136.623 519.244 136.704 519.024 136.872L517.798 135.709C518.17 135.407 518.58 135.215 519.007 135.142C519.44 135.062 519.875 135.088 520.326 135.219C520.777 135.35 521.221 135.567 521.651 135.877C521.953 136.092 522.229 136.337 522.472 136.621C522.716 136.905 522.905 137.208 523.039 137.544C523.173 137.88 523.211 138.238 523.164 138.618C523.118 138.999 522.946 139.404 522.654 139.812L519.68 143.974L518.277 142.976L518.894 142.12L518.848 142.081C518.631 142.2 518.395 142.263 518.128 142.3C517.862 142.338 517.569 142.313 517.271 142.233C516.966 142.146 516.648 141.996 516.331 141.769L516.294 141.751ZM517.447 140.94C517.741 141.149 518.043 141.272 518.337 141.31C518.631 141.348 518.911 141.31 519.172 141.202C519.425 141.087 519.639 140.927 519.803 140.685L520.331 139.951C520.26 139.957 520.159 139.938 520.028 139.892C519.896 139.847 519.749 139.789 519.602 139.717C519.454 139.644 519.306 139.572 519.158 139.5C519.01 139.428 518.884 139.361 518.779 139.299C518.542 139.178 518.307 139.085 518.09 139.032C517.873 138.98 517.667 138.977 517.486 139.021C517.298 139.073 517.138 139.186 517.004 139.375C516.814 139.647 516.766 139.921 516.86 140.197C516.954 140.473 517.16 140.724 517.461 140.939L517.447 140.94Z"
              fill="white"
            />
            <path d="M530.913 139.686L524.728 147.782L523.297 146.694L529.483 138.598L530.913 139.686Z" fill="white" />
            <path
              d="M534.145 151.164L536.946 147.734L538.339 148.868L533.498 154.796L532.15 153.7L533.013 152.646L532.952 152.595C532.545 152.815 532.106 152.917 531.634 152.873C531.156 152.836 530.699 152.635 530.257 152.276C529.876 151.955 529.605 151.595 529.442 151.183C529.287 150.764 529.265 150.326 529.368 149.863C529.478 149.392 529.715 148.925 530.101 148.451L533.178 144.677L534.572 145.812L531.668 149.365C531.358 149.732 531.221 150.127 531.246 150.515C531.271 150.903 531.448 151.236 531.783 151.505C531.989 151.678 532.225 151.785 532.506 151.839C532.781 151.9 533.062 151.875 533.345 151.773C533.635 151.677 533.895 151.476 534.147 151.178L534.145 151.164Z"
              fill="white"
            />
            <path
              d="M538.516 159.314C538.157 158.998 537.885 158.639 537.711 158.256C537.538 157.874 537.483 157.475 537.535 157.073C537.593 156.663 537.788 156.277 538.118 155.901C538.403 155.578 538.704 155.36 539.001 155.256C539.297 155.152 539.621 155.124 539.938 155.182C540.255 155.239 540.569 155.346 540.888 155.51C541.207 155.674 541.514 155.867 541.822 156.075C542.192 156.333 542.486 156.542 542.719 156.699C542.952 156.856 543.143 156.939 543.288 156.969C543.438 156.991 543.569 156.944 543.686 156.806L543.705 156.783C543.944 156.5 544.069 156.212 544.037 155.91C544.004 155.608 543.839 155.324 543.525 155.046C543.195 154.756 542.87 154.6 542.555 154.57C542.234 154.549 541.955 154.601 541.711 154.736L540.617 153.448C541.021 153.186 541.442 153.042 541.878 153.004C542.315 152.966 542.747 153.042 543.187 153.217C543.62 153.385 544.033 153.654 544.438 154.009C544.721 154.247 544.957 154.524 545.175 154.839C545.391 155.139 545.546 155.466 545.64 155.82C545.74 156.166 545.734 156.528 545.652 156.904C545.57 157.28 545.354 157.661 545.023 158.038L541.632 161.874L540.338 160.731L541.038 159.946L540.993 159.907C540.773 159.998 540.528 160.04 540.259 160.049C539.991 160.059 539.709 160.005 539.416 159.889C539.122 159.773 538.823 159.586 538.524 159.321L538.516 159.314ZM539.744 158.633C540.019 158.864 540.302 159.024 540.584 159.092C540.866 159.159 541.157 159.155 541.426 159.068C541.696 158.981 541.918 158.841 542.106 158.618L542.702 157.942C542.63 157.934 542.529 157.914 542.403 157.847C542.27 157.788 542.137 157.715 542.001 157.627C541.852 157.541 541.717 157.453 541.582 157.366C541.447 157.278 541.328 157.203 541.229 157.134C541.004 156.983 540.789 156.867 540.569 156.787C540.349 156.707 540.156 156.688 539.967 156.712C539.777 156.735 539.609 156.842 539.453 157.012C539.239 157.265 539.162 157.527 539.221 157.813C539.281 158.098 539.459 158.367 539.743 158.619L539.744 158.633Z"
              fill="white"
            />
            <path
              d="M552.987 162.028L552.029 163.04L548.856 160.026L549.814 159.014L552.987 162.028ZM551.857 158.431L553.159 159.672L548.219 164.883C548.056 165.06 547.949 165.219 547.918 165.363C547.888 165.508 547.899 165.634 547.951 165.758C548.004 165.881 548.09 165.979 548.198 166.084C548.282 166.154 548.366 166.225 548.435 166.283C548.518 166.339 548.577 166.377 548.622 166.401L547.873 167.63C547.778 167.588 547.652 167.521 547.502 167.421C547.345 167.328 547.171 167.194 546.986 167.026C546.649 166.729 546.409 166.396 546.252 166.04C546.094 165.685 546.05 165.321 546.118 164.946C546.186 164.571 546.383 164.214 546.715 163.865L551.859 158.446L551.857 158.431Z"
              fill="white"
            />
            <path
              d="M552.927 172.938C552.407 172.409 552.069 171.835 551.925 171.216C551.781 170.597 551.814 169.97 552.017 169.342C552.234 168.713 552.617 168.118 553.182 167.573C553.745 167.012 554.346 166.641 554.975 166.437C555.604 166.233 556.233 166.214 556.849 166.366C557.473 166.524 558.034 166.866 558.561 167.402C559.08 167.931 559.419 168.505 559.563 169.124C559.707 169.743 559.674 170.37 559.465 171.006C559.248 171.635 558.864 172.229 558.293 172.783C557.73 173.343 557.129 173.714 556.5 173.918C555.872 174.122 555.242 174.141 554.632 173.982C554.009 173.823 553.448 173.482 552.921 172.946L552.927 172.938ZM553.965 171.939C554.244 172.227 554.563 172.391 554.914 172.424C555.264 172.458 555.621 172.391 555.983 172.225C556.345 172.058 556.7 171.807 557.028 171.481C557.362 171.146 557.618 170.812 557.793 170.449C557.969 170.086 558.046 169.731 558.016 169.379C557.987 169.027 557.825 168.708 557.546 168.42C557.259 168.125 556.932 167.955 556.582 167.922C556.231 167.889 555.875 167.955 555.506 168.129C555.138 168.303 554.789 168.547 554.455 168.881C554.127 169.207 553.865 169.55 553.689 169.913C553.514 170.276 553.437 170.63 553.466 170.982C553.496 171.334 553.657 171.654 553.952 171.955L553.965 171.939Z"
              fill="white"
            />
            <path
              d="M558.5 178.561L564.142 173.402L565.314 174.69L564.371 175.545L564.432 175.597C564.849 175.411 565.263 175.354 565.668 175.446C566.073 175.539 566.423 175.742 566.719 176.057C566.789 176.129 566.86 176.215 566.932 176.315C567.011 176.408 567.068 176.495 567.109 176.569L565.883 177.684C565.855 177.608 565.79 177.5 565.693 177.367C565.597 177.233 565.494 177.107 565.393 176.996C565.167 176.753 564.918 176.583 564.641 176.494C564.357 176.398 564.086 176.379 563.8 176.439C563.514 176.5 563.256 176.636 563.019 176.855L559.703 179.89L558.494 178.569L558.5 178.561Z"
              fill="white"
            />
            <path
              d="M470.492 171.496L475.744 162.758L479.015 164.714C479.68 165.117 480.172 165.571 480.479 166.105C480.784 166.624 480.922 167.173 480.892 167.75C480.863 168.327 480.683 168.896 480.352 169.443C480.009 170.005 479.598 170.431 479.104 170.723C478.61 171.014 478.054 171.148 477.442 171.13C476.837 171.105 476.195 170.891 475.516 170.49L473.35 169.189L474.136 167.886L476.085 169.056C476.473 169.292 476.838 169.416 477.172 169.423C477.506 169.429 477.799 169.361 478.069 169.195C478.331 169.023 478.553 168.791 478.747 168.483C478.934 168.169 479.036 167.862 479.06 167.555C479.084 167.248 479.01 166.949 478.83 166.667C478.65 166.385 478.373 166.125 477.985 165.89L476.536 165.023L472.082 172.442L470.506 171.494L470.492 171.496Z"
              fill="white"
            />
            <path
              d="M482.959 179.489C482.342 179.074 481.897 178.581 481.628 178.001C481.36 177.421 481.266 176.805 481.346 176.152C481.427 175.499 481.684 174.838 482.133 174.182C482.582 173.525 483.087 173.034 483.663 172.707C484.239 172.38 484.858 172.234 485.485 172.264C486.126 172.294 486.748 172.516 487.365 172.931C487.981 173.345 488.427 173.839 488.695 174.419C488.964 174.998 489.058 175.615 488.971 176.275C488.884 176.935 488.627 177.597 488.184 178.245C487.749 178.901 487.23 179.393 486.66 179.712C486.084 180.039 485.472 180.178 484.838 180.155C484.197 180.126 483.575 179.903 482.959 179.489ZM483.768 178.297C484.099 178.524 484.447 178.621 484.792 178.584C485.144 178.553 485.481 178.417 485.801 178.176C486.122 177.936 486.415 177.619 486.674 177.235C486.932 176.85 487.12 176.458 487.215 176.066C487.317 175.681 487.315 175.312 487.223 174.973C487.124 174.641 486.909 174.354 486.578 174.128C486.239 173.895 485.89 173.797 485.531 173.836C485.179 173.867 484.836 174.01 484.516 174.251C484.195 174.492 483.902 174.808 483.643 175.193C483.384 175.577 483.204 175.962 483.109 176.353C483.006 176.738 483.008 177.107 483.115 177.446C483.221 177.784 483.436 178.07 483.782 178.296L483.768 178.297Z"
              fill="white"
            />
            <path
              d="M489.52 183.878L494.064 177.729L495.462 178.763L494.704 179.787L494.765 179.838C495.139 179.565 495.539 179.43 495.951 179.437C496.363 179.444 496.75 179.573 497.098 179.841C497.175 179.905 497.259 179.976 497.358 180.059C497.449 180.136 497.533 180.207 497.581 180.274L496.6 181.608C496.552 181.541 496.474 181.448 496.358 181.338C496.235 181.221 496.12 181.125 495.992 181.029C495.727 180.833 495.448 180.715 495.154 180.677C494.86 180.639 494.586 180.67 494.311 180.779C494.044 180.894 493.811 181.078 493.62 181.336L490.95 184.952L489.505 183.879L489.52 183.878Z"
              fill="white"
            />
            <path
              d="M504.186 185.516L503.32 186.606L499.9 183.869L500.774 182.785L504.194 185.523L504.186 185.516ZM502.76 182.024L504.159 183.15L499.669 188.754C499.514 188.938 499.43 189.116 499.413 189.259C499.39 189.41 499.415 189.535 499.481 189.643C499.547 189.751 499.64 189.856 499.755 189.952C499.839 190.023 499.929 190.086 500.011 190.129C500.092 190.171 500.166 190.207 500.211 190.232L499.567 191.522C499.465 191.488 499.34 191.435 499.176 191.35C499.013 191.265 498.834 191.153 498.623 191.002C498.266 190.728 497.986 190.426 497.808 190.08C497.624 189.741 497.543 189.372 497.576 188.994C497.608 188.615 497.782 188.238 498.078 187.872L502.753 182.031L502.76 182.024Z"
              fill="white"
            />
            <path
              d="M510.589 190.953L509.677 192.004L506.282 189.052L507.194 188.001L510.589 190.953ZM503.02 194.5L508.522 188.19C508.851 187.8 509.22 187.548 509.607 187.429C509.994 187.31 510.377 187.305 510.755 187.414C511.132 187.523 511.497 187.725 511.826 188.016C512.062 188.215 512.257 188.418 512.401 188.618C512.552 188.811 512.657 188.965 512.715 189.066L511.528 189.886C511.48 189.819 511.431 189.738 511.359 189.638C511.286 189.538 511.201 189.453 511.087 189.357C510.835 189.145 510.607 189.044 510.397 189.077C510.187 189.109 509.985 189.24 509.784 189.463L504.367 195.687L503.014 194.507L503.02 194.5Z"
              fill="white"
            />
            <path
              d="M510.76 201.637C510.221 201.13 509.862 200.565 509.683 199.957C509.511 199.34 509.516 198.715 509.697 198.082C509.878 197.449 510.246 196.842 510.787 196.263C511.328 195.683 511.912 195.285 512.538 195.053C513.165 194.821 513.786 194.781 514.407 194.911C515.028 195.041 515.616 195.366 516.155 195.873C516.694 196.379 517.053 196.944 517.232 197.553C517.41 198.162 517.399 198.794 517.211 199.435C517.024 200.076 516.656 200.683 516.121 201.254C515.587 201.826 514.997 202.232 514.377 202.457C513.757 202.681 513.129 202.729 512.508 202.598C511.887 202.468 511.299 202.143 510.76 201.637ZM511.759 200.592C512.051 200.864 512.376 201.02 512.726 201.04C513.075 201.059 513.43 200.978 513.791 200.798C514.145 200.611 514.484 200.347 514.804 200.014C515.123 199.667 515.364 199.319 515.532 198.95C515.7 198.581 515.755 198.221 515.711 197.87C515.668 197.519 515.5 197.207 515.2 196.928C514.9 196.649 514.574 196.493 514.218 196.467C513.861 196.442 513.513 196.529 513.152 196.709C512.799 196.896 512.459 197.16 512.132 197.501C511.82 197.84 511.573 198.195 511.411 198.557C511.25 198.919 511.188 199.286 511.238 199.629C511.282 199.98 511.457 200.299 511.757 200.578L511.759 200.592Z"
              fill="white"
            />
            <path d="M525.038 201.154L517.781 208.309L516.526 207.029L523.783 199.874L525.038 201.154Z" fill="white" />
            <path
              d="M520.955 211.612L526.553 206.409L527.776 207.728L522.178 212.931L520.955 211.612ZM527.965 206.329C527.77 206.126 527.679 205.879 527.684 205.595C527.688 205.311 527.79 205.082 527.98 204.903C528.171 204.723 528.412 204.638 528.697 204.656C528.975 204.681 529.211 204.788 529.407 205.005C529.602 205.222 529.692 205.455 529.688 205.739C529.683 206.023 529.581 206.252 529.391 206.431C529.2 206.611 528.959 206.696 528.674 206.678C528.396 206.653 528.16 206.546 527.965 206.329Z"
              fill="white"
            />
            <path
              d="M527.296 218.934C526.809 218.366 526.517 217.767 526.416 217.144C526.314 216.522 526.383 215.898 526.637 215.287C526.891 214.676 527.319 214.107 527.915 213.6C528.518 213.087 529.143 212.748 529.783 212.593C530.429 212.43 531.048 212.454 531.661 212.656C532.274 212.858 532.81 213.245 533.29 213.806C533.77 214.367 534.069 214.973 534.17 215.595C534.272 216.218 534.197 216.849 533.943 217.46C533.689 218.071 533.261 218.641 532.658 219.155C532.055 219.669 531.437 219.999 530.79 220.162C530.144 220.325 529.525 220.301 528.918 220.091C528.312 219.881 527.769 219.503 527.289 218.942L527.296 218.934ZM528.396 218.001C528.656 218.312 528.968 218.483 529.315 218.552C529.661 218.621 530.019 218.569 530.397 218.429C530.768 218.283 531.14 218.06 531.484 217.76C531.842 217.459 532.12 217.13 532.317 216.772C532.522 216.42 532.621 216.071 532.613 215.724C532.612 215.369 532.471 215.041 532.211 214.73C531.943 214.413 531.629 214.227 531.284 214.172C530.931 214.111 530.573 214.149 530.195 214.303C529.824 214.449 529.452 214.673 529.102 214.98C528.751 215.287 528.473 215.603 528.275 215.96C528.077 216.304 527.978 216.653 527.987 217.014C527.989 217.369 528.129 217.698 528.397 218.015L528.396 218.001Z"
              fill="white"
            />
            <path
              d="M457.631 186.07L455.918 185.098L464.084 178.014L466.058 179.14L464.112 189.761L462.399 188.79L464.063 180.407L464.002 180.355L457.609 186.065L457.631 186.07ZM459.672 182.628L464.327 185.28L463.591 186.571L458.935 183.92L459.672 182.628Z"
              fill="white"
            />
            <path d="M474.453 184.259L468.981 192.854L467.469 191.894L472.941 183.299L474.453 184.259Z" fill="white" />
            <path d="M480.027 187.963L474.278 196.377L472.797 195.364L478.546 186.951L480.027 187.963Z" fill="white" />
            <path
              d="M480.593 201.101C480.002 200.656 479.576 200.139 479.342 199.55C479.108 198.96 479.043 198.341 479.151 197.686C479.26 197.031 479.561 196.394 480.033 195.757C480.505 195.12 481.041 194.654 481.641 194.361C482.234 194.061 482.849 193.95 483.486 194.015C484.123 194.08 484.733 194.332 485.331 194.784C485.922 195.229 486.348 195.745 486.582 196.335C486.816 196.925 486.881 197.544 486.766 198.207C486.644 198.863 486.35 199.506 485.884 200.136C485.412 200.773 484.883 201.231 484.283 201.524C483.683 201.818 483.075 201.935 482.438 201.87C481.801 201.805 481.191 201.553 480.593 201.101ZM481.469 199.953C481.788 200.195 482.123 200.308 482.477 200.291C482.83 200.274 483.168 200.153 483.511 199.931C483.841 199.711 484.149 199.407 484.43 199.042C484.711 198.663 484.914 198.283 485.038 197.903C485.162 197.524 485.174 197.154 485.096 196.813C485.018 196.472 484.809 196.178 484.491 195.936C484.165 195.687 483.815 195.576 483.461 195.593C483.108 195.609 482.763 195.725 482.427 195.953C482.091 196.181 481.789 196.476 481.501 196.849C481.227 197.221 481.025 197.6 480.9 197.98C480.783 198.367 480.764 198.73 480.842 199.071C480.92 199.412 481.137 199.712 481.463 199.961L481.469 199.953Z"
              fill="white"
            />
            <path
              d="M489.369 208.052C488.782 207.571 488.375 207.032 488.169 206.426C487.962 205.819 487.924 205.198 488.076 204.553C488.228 203.909 488.545 203.285 489.035 202.689C489.531 202.085 490.077 201.654 490.678 201.375C491.279 201.095 491.903 201.005 492.535 201.092C493.166 201.179 493.771 201.453 494.35 201.927C494.823 202.326 495.185 202.749 495.409 203.226C495.634 203.703 495.731 204.191 495.688 204.692C495.644 205.192 495.464 205.669 495.147 206.122L493.815 205.039C493.993 204.705 494.057 204.366 494.013 204.015C493.97 203.664 493.78 203.347 493.438 203.071C493.155 202.833 492.84 202.712 492.505 202.691C492.17 202.671 491.815 202.752 491.461 202.939C491.108 203.125 490.77 203.417 490.442 203.822C490.106 204.22 489.885 204.623 489.768 205.009C489.651 205.395 489.638 205.751 489.723 206.084C489.807 206.418 490.001 206.699 490.29 206.929C490.488 207.096 490.71 207.205 490.935 207.263C491.16 207.322 491.387 207.316 491.623 207.253C491.852 207.197 492.076 207.071 492.295 206.889L493.627 207.972C493.255 208.366 492.823 208.631 492.354 208.786C491.884 208.94 491.381 208.949 490.867 208.83C490.353 208.712 489.842 208.451 489.355 208.054L489.369 208.052Z"
              fill="white"
            />
            <path
              d="M496.56 214.374C496.206 214.05 495.949 213.689 495.782 213.299C495.614 212.91 495.574 212.509 495.639 212.106C495.705 211.702 495.914 211.315 496.26 210.952C496.553 210.635 496.855 210.431 497.165 210.326C497.47 210.229 497.787 210.208 498.098 210.273C498.415 210.331 498.73 210.452 499.042 210.624C499.355 210.795 499.662 210.989 499.957 211.211C500.313 211.485 500.607 211.694 500.834 211.858C501.061 212.023 501.253 212.12 501.404 212.142C501.555 212.165 501.686 212.118 501.803 211.994L501.823 211.971C502.077 211.7 502.194 211.406 502.176 211.103C502.158 210.799 501.985 210.509 501.685 210.23C501.362 209.932 501.043 209.768 500.721 209.733C500.398 209.697 500.119 209.75 499.881 209.877L498.813 208.558C499.232 208.308 499.647 208.173 500.085 208.149C500.523 208.125 500.948 208.208 501.382 208.391C501.816 208.573 502.222 208.849 502.607 209.213C502.877 209.466 503.113 209.744 503.323 210.052C503.533 210.36 503.682 210.695 503.767 211.042C503.853 211.389 503.848 211.752 503.752 212.129C503.656 212.506 503.438 212.873 503.086 213.244L499.602 216.99L498.333 215.816L499.049 215.044L499.003 215.005C498.783 215.081 498.538 215.124 498.261 215.127C497.985 215.13 497.71 215.068 497.417 214.952C497.123 214.836 496.837 214.634 496.538 214.369L496.56 214.374ZM497.803 213.719C498.065 213.966 498.348 214.126 498.638 214.2C498.928 214.274 499.212 214.277 499.475 214.198C499.746 214.125 499.975 213.977 500.163 213.769L500.775 213.105C500.703 213.097 500.601 213.064 500.476 213.011C500.351 212.958 500.216 212.87 500.081 212.783C499.946 212.695 499.803 212.601 499.661 212.507C499.518 212.413 499.412 212.337 499.314 212.268C499.095 212.109 498.88 211.993 498.667 211.905C498.454 211.818 498.253 211.793 498.063 211.816C497.874 211.84 497.698 211.94 497.541 212.11C497.313 212.35 497.229 212.62 497.296 212.912C497.355 213.198 497.527 213.474 497.797 213.727L497.803 213.719Z"
              fill="white"
            />
            <path
              d="M510.958 217.562L509.962 218.543L506.899 215.413L507.895 214.432L510.958 217.562ZM509.959 213.919L511.215 215.2L506.081 220.222C505.911 220.392 505.803 220.551 505.765 220.689C505.727 220.827 505.731 220.961 505.784 221.084C505.837 221.207 505.917 221.314 506.018 221.426C506.096 221.504 506.18 221.575 506.255 221.625C506.33 221.675 506.397 221.719 506.435 221.751L505.642 222.955C505.553 222.906 505.427 222.839 505.277 222.738C505.127 222.638 504.959 222.497 504.781 222.321C504.457 222.008 504.223 221.667 504.08 221.311C503.936 220.955 503.905 220.574 503.98 220.206C504.062 219.83 504.275 219.485 504.623 219.15L509.967 213.926L509.959 213.919Z"
              fill="white"
            />
            <path
              d="M510.409 228.479C509.915 227.919 509.611 227.335 509.494 226.699C509.378 226.078 509.439 225.448 509.679 224.838C509.926 224.221 510.34 223.652 510.927 223.126C511.515 222.599 512.132 222.254 512.777 222.077C513.416 221.908 514.048 221.917 514.66 222.105C515.272 222.293 515.821 222.664 516.314 223.224C516.808 223.784 517.112 224.368 517.229 225.003C517.345 225.625 517.284 226.254 517.038 226.872C516.791 227.489 516.371 228.065 515.783 228.592C515.195 229.119 514.578 229.464 513.94 229.633C513.301 229.802 512.675 229.786 512.071 229.604C511.459 229.416 510.91 229.045 510.416 228.485L510.409 228.479ZM511.5 227.525C511.767 227.828 512.073 228.007 512.424 228.055C512.769 228.11 513.127 228.057 513.505 227.904C513.876 227.758 514.232 227.521 514.582 227.214C514.933 226.906 515.203 226.571 515.4 226.213C515.597 225.855 515.682 225.507 515.681 225.152C515.665 224.799 515.524 224.471 515.258 224.167C514.984 223.858 514.67 223.672 514.318 223.625C513.973 223.57 513.608 223.616 513.23 223.769C512.853 223.923 512.497 224.16 512.146 224.467C511.796 224.774 511.526 225.11 511.335 225.46C511.144 225.81 511.053 226.166 511.069 226.519C511.084 226.873 511.225 227.201 511.499 227.511L511.5 227.525Z"
              fill="white"
            />
            <path
              d="M515.649 234.372L521.599 229.575L522.69 230.927L521.7 231.73L521.748 231.797C522.181 231.624 522.591 231.603 522.997 231.709C523.397 231.823 523.728 232.05 524.004 232.387C524.068 232.467 524.139 232.553 524.204 232.661C524.277 232.761 524.334 232.848 524.369 232.93L523.079 233.965C523.051 233.889 522.999 233.78 522.91 233.639C522.82 233.498 522.731 233.371 522.636 233.252C522.431 233 522.195 232.815 521.916 232.697C521.644 232.586 521.364 232.547 521.077 232.593C520.79 232.639 520.523 232.755 520.271 232.961L516.767 235.785L515.636 234.387L515.649 234.372Z"
              fill="white"
            />
          </g>
        )}
        <g filter="url(#C)" onClick={() => onSelectPhase(ProcessSchemePhase.SYNAPSE)}>
          <circle
            cx={588}
            cy={105}
            r={selectedPhase === ProcessSchemePhase.SYNAPSE ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.SYNAPSE ? 'url(#D)' : '#fff'}
            transform="rotate(-90 588 105)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End SYNAPSE Phase */}
        {/* Start SYNAPSE_2 Phase */}
        {selectedPhase === ProcessSchemePhase.SYNAPSE_2 && (
          <g
            transform="translate(35, 22)"
            // ref={synapsePhase2WrapperRef}
          >
            <g filter="url(#filter0_b_195_817)">
              <mask id="path-1-inside-1_195_817" fill="white">
                <path d="M664.131 377.15C673.66 304.972 659.308 231.659 623.267 168.402L428.113 279.593C439.881 300.247 444.567 324.185 441.456 347.752L664.131 377.15Z" />
              </mask>
              <path
                d="M664.131 377.15C673.66 304.972 659.308 231.659 623.267 168.402L428.113 279.593C439.881 300.247 444.567 324.185 441.456 347.752L664.131 377.15Z"
                fill="url(#paint0_radial_195_817)"
              />
              <path
                d="M664.131 377.15C673.66 304.972 659.308 231.659 623.267 168.402L428.113 279.593C439.881 300.247 444.567 324.185 441.456 347.752L664.131 377.15Z"
                stroke="url(#paint1_linear_195_817)"
                strokeWidth="4"
                mask="url(#path-1-inside-1_195_817)"
              />
            </g>
          </g>
        )}
        <g filter="url(#F)" onClick={() => onSelectPhase(ProcessSchemePhase.SYNAPSE_2)}>
          <circle
            cx={696.75}
            cy={298.002}
            r={selectedPhase === ProcessSchemePhase.SYNAPSE_2 ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.SYNAPSE_2 ? 'url(#paint24_linear_295_32794)' : '#fff'}
            transform="rotate(80 696.75 298.002)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End SYNAPSE_2 Phase */}
        {/* Start BAV_AUDIENCE_DB Phase */}
        {selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB && (
          <g
            transform="translate(3, -13)"
            // ref={bavAudienceDBWrapperRef}
          >
            <g filter="url(#filter0_d_195_795)">
              <path
                d="M581.162 625.034C643.962 571.367 684.61 496.303 695.232 414.381L472.603 385.516C469.132 412.293 456.027 437.458 435.5 455L581.162 625.034Z"
                fill="url(#paint0_linear_195_795)"
              />
            </g>
            <path
              d="M545.268 509.292L544.052 510.825L538.237 501.974L539.635 500.202L549.863 503.474L548.646 505.007L540.604 502.301L540.565 502.37L545.254 509.296L545.268 509.292ZM542.161 506.866L545.467 502.688L546.63 503.566L543.324 507.744L542.161 506.866Z"
              fill="white"
            />
            <path
              d="M552.554 494.324L548.934 491.879L549.959 490.422L556.213 494.644L555.213 496.058L554.101 495.309L554.062 495.378C554.308 495.747 554.43 496.172 554.424 496.642C554.419 497.111 554.257 497.574 553.926 498.035C553.637 498.441 553.296 498.739 552.893 498.926C552.491 499.112 552.054 499.179 551.579 499.113C551.103 499.046 550.613 498.848 550.12 498.513L546.136 495.825L547.161 494.369L550.91 496.907C551.304 497.176 551.701 497.279 552.084 497.228C552.471 497.168 552.789 496.97 553.035 496.619C553.188 496.41 553.286 496.16 553.317 495.884C553.349 495.609 553.309 495.34 553.193 495.064C553.077 494.787 552.861 494.547 552.536 494.337L552.554 494.324Z"
              fill="white"
            />
            <path
              d="M560.517 488.309C560.197 488.81 559.78 489.181 559.267 489.42C558.754 489.66 558.182 489.743 557.537 489.675C556.892 489.606 556.216 489.352 555.513 488.926C554.792 488.49 554.257 488.009 553.912 487.474C553.567 486.938 553.389 486.391 553.394 485.843C553.39 485.29 553.55 484.769 553.857 484.272C554.095 483.895 554.349 483.628 554.635 483.468C554.92 483.307 555.193 483.208 555.455 483.17C555.725 483.136 555.958 483.128 556.162 483.149L556.197 483.088L552.975 481.14L553.927 479.631L562.55 484.845L561.613 486.328L560.597 485.71L560.543 485.804C560.651 485.975 560.748 486.185 560.82 486.438C560.901 486.695 560.923 486.977 560.903 487.292C560.874 487.603 560.745 487.949 560.512 488.318L560.517 488.309ZM559.533 487.135C559.737 486.818 559.82 486.492 559.792 486.162C559.763 485.831 559.622 485.519 559.378 485.207C559.135 484.894 558.813 484.619 558.387 484.366C557.961 484.112 557.571 483.957 557.181 483.881C556.8 483.809 556.455 483.836 556.136 483.955C555.825 484.079 555.564 484.297 555.355 484.623C555.142 484.957 555.054 485.292 555.091 485.627C555.128 485.962 555.26 486.27 555.504 486.582C555.744 486.881 556.066 487.156 556.465 487.395C556.874 487.639 557.264 487.794 557.654 487.871C558.043 487.947 558.398 487.926 558.726 487.811C559.053 487.696 559.32 487.469 559.528 487.143L559.533 487.135Z"
              fill="white"
            />
            <path
              d="M565.015 480.704L558.413 476.985L559.323 475.447L565.925 479.166L565.015 480.704ZM557.93 475.685C557.793 475.926 557.583 476.094 557.31 476.172C557.037 476.25 556.787 476.227 556.557 476.09C556.326 475.954 556.174 475.759 556.12 475.492C556.066 475.226 556.102 474.963 556.248 474.726C556.385 474.485 556.595 474.317 556.868 474.239C557.141 474.161 557.391 474.184 557.622 474.321C557.852 474.457 558.004 474.652 558.058 474.919C558.121 475.19 558.082 475.439 557.93 475.685Z"
              fill="white"
            />
            <path
              d="M569.961 472.088C569.602 472.759 569.156 473.259 568.621 473.599C568.086 473.938 567.487 474.108 566.825 474.109C566.164 474.11 565.48 473.931 564.774 473.57C564.076 473.215 563.534 472.786 563.154 472.254C562.774 471.722 562.552 471.152 562.506 470.532C562.461 469.912 562.605 469.281 562.945 468.645C563.166 468.237 563.439 467.879 563.766 467.584C564.094 467.29 564.471 467.067 564.894 466.925C565.317 466.783 565.797 466.74 566.313 466.795C566.829 466.85 567.379 467.024 567.972 467.323L568.461 467.566L565.56 473.03L564.487 472.494L566.584 468.538C566.283 468.386 565.969 468.317 565.661 468.319C565.352 468.32 565.075 468.406 564.813 468.567C564.55 468.728 564.342 468.953 564.184 469.249C564.012 469.572 563.942 469.894 563.975 470.215C564.008 470.537 564.106 470.825 564.285 471.091C564.464 471.357 564.713 471.559 565.009 471.72L565.946 472.194C566.334 472.393 566.714 472.486 567.067 472.487C567.42 472.487 567.747 472.394 568.028 472.221C568.314 472.039 568.542 471.78 568.724 471.44C568.851 471.217 568.925 470.987 568.959 470.769C568.992 470.551 568.975 470.34 568.918 470.14C568.861 469.941 568.758 469.761 568.606 469.589L569.556 468.204C569.903 468.494 570.15 468.841 570.304 469.25C570.458 469.66 570.511 470.105 570.457 470.581C570.402 471.057 570.238 471.564 569.966 472.08L569.961 472.088Z"
              fill="white"
            />
            <path
              d="M570.095 461.605L574.154 463.4L573.399 465.003L566.476 461.936L567.191 460.402L568.363 460.925L568.407 460.847C568.084 460.515 567.893 460.13 567.83 459.68C567.763 459.239 567.845 458.756 568.09 458.247C568.314 457.773 568.601 457.411 568.975 457.154C569.35 456.896 569.773 456.754 570.244 456.728C570.723 456.707 571.241 456.819 571.791 457.072L576.204 459.026L575.45 460.629L571.295 458.782C570.831 458.575 570.413 458.529 570.045 458.655C569.677 458.781 569.391 459.043 569.201 459.456C569.075 459.737 569.016 460.02 569.034 460.288C569.052 460.556 569.155 460.814 569.337 461.036C569.518 461.258 569.772 461.452 570.1 461.596L570.095 461.605Z"
              fill="white"
            />
            <path
              d="M579.492 451.621C579.207 452.321 578.81 452.858 578.29 453.229C577.78 453.604 577.188 453.823 576.528 453.881C575.868 453.939 575.181 453.825 574.47 453.552C573.751 453.275 573.171 452.893 572.73 452.407C572.289 451.921 572.022 451.371 571.918 450.753C571.815 450.135 571.901 449.485 572.185 448.807C572.414 448.245 572.723 447.784 573.121 447.426C573.519 447.068 573.946 446.839 574.435 446.721C574.923 446.604 575.431 446.632 575.955 446.793L575.309 448.365C574.937 448.298 574.596 448.337 574.271 448.487C573.946 448.637 573.7 448.909 573.542 449.306C573.409 449.639 573.379 449.971 573.457 450.294C573.534 450.617 573.716 450.918 573.998 451.183C574.28 451.448 574.657 451.687 575.135 451.867C575.622 452.052 576.06 452.144 576.463 452.137C576.865 452.13 577.214 452.038 577.5 451.856C577.787 451.674 578.005 451.411 578.143 451.069C578.244 450.832 578.287 450.597 578.272 450.364C578.257 450.131 578.182 449.922 578.067 449.724C577.952 449.527 577.763 449.357 577.529 449.208L578.175 447.636C578.66 447.865 579.047 448.187 579.322 448.583C579.606 448.985 579.766 449.443 579.803 449.957C579.84 450.472 579.741 451.025 579.502 451.604L579.492 451.621Z"
              fill="white"
            />
            <path
              d="M583.383 441.315C583.129 442.02 582.761 442.585 582.285 443.001C581.799 443.412 581.232 443.667 580.575 443.761C579.918 443.854 579.22 443.779 578.464 443.527C577.725 443.283 577.121 442.923 576.657 442.458C576.193 441.993 575.894 441.459 575.763 440.849C575.628 440.247 575.678 439.6 575.917 438.921C576.073 438.488 576.284 438.097 576.57 437.757C576.846 437.412 577.195 437.14 577.593 436.941C577.992 436.741 578.454 436.631 578.978 436.612C579.502 436.592 580.073 436.689 580.707 436.897L581.226 437.066L579.167 442.874L578.028 442.492L579.516 438.286C579.19 438.177 578.874 438.152 578.572 438.202C578.27 438.252 578.005 438.377 577.772 438.566C577.539 438.754 577.365 439.02 577.255 439.331C577.131 439.669 577.115 439.997 577.184 440.315C577.258 440.625 577.41 440.899 577.626 441.139C577.847 441.371 578.116 441.54 578.436 441.657L579.434 441.985C579.857 442.124 580.243 442.165 580.59 442.116C580.941 442.06 581.243 441.931 581.495 441.708C581.748 441.485 581.945 441.199 582.07 440.839C582.154 440.593 582.201 440.371 582.195 440.142C582.197 439.919 582.153 439.715 582.064 439.532C581.98 439.34 581.845 439.177 581.664 439.035L582.396 437.532C582.782 437.774 583.075 438.08 583.293 438.456C583.511 438.833 583.628 439.267 583.64 439.746C583.652 440.224 583.573 440.743 583.38 441.302L583.383 441.315Z"
              fill="white"
            />
            <path
              d="M572.197 497.422C573.107 498.005 573.771 498.68 574.198 499.429C574.621 500.186 574.806 500.961 574.762 501.757C574.718 502.554 574.452 503.32 573.955 504.051C573.458 504.783 572.85 505.33 572.112 505.683C571.375 506.037 570.568 506.173 569.692 506.091C568.815 506.009 567.924 505.673 567.015 505.09C566.105 504.507 565.441 503.832 565.013 503.083C564.591 502.326 564.406 501.551 564.45 500.754C564.494 499.958 564.76 499.192 565.257 498.46C565.754 497.729 566.362 497.182 567.099 496.829C567.837 496.475 568.635 496.335 569.511 496.416C570.388 496.498 571.279 496.835 572.189 497.418L572.197 497.422ZM571.169 498.945C570.528 498.53 569.919 498.279 569.343 498.191C568.766 498.103 568.245 498.158 567.788 498.36C567.331 498.561 566.939 498.889 566.635 499.342C566.33 499.795 566.173 500.272 566.166 500.763C566.159 501.254 566.309 501.751 566.621 502.223C566.928 502.704 567.402 503.152 568.042 503.567C568.683 503.982 569.292 504.233 569.869 504.321C570.446 504.409 570.958 504.349 571.424 504.152C571.89 503.955 572.273 503.623 572.577 503.17C572.881 502.716 573.039 502.24 573.046 501.749C573.052 501.258 572.903 500.761 572.596 500.28C572.289 499.799 571.815 499.351 571.174 498.936L571.169 498.945Z"
              fill="white"
            />
            <path
              d="M578.684 496.534L572.158 492.715L573.063 491.248L574.15 491.882L574.189 491.813C573.888 491.481 573.714 491.106 573.686 490.696C573.657 490.287 573.752 489.9 573.985 489.531C574.034 489.445 574.097 489.355 574.169 489.248C574.237 489.15 574.308 489.065 574.365 489.005L575.778 489.828C575.713 489.882 575.633 489.963 575.532 490.099C575.427 490.223 575.34 490.355 575.258 490.48C575.088 490.759 574.998 491.036 574.979 491.33C574.964 491.615 575.022 491.894 575.158 492.136C575.288 492.387 575.502 492.592 575.776 492.752L579.615 495.002L578.68 496.521L578.684 496.534Z"
              fill="white"
            />
            <path
              d="M582.888 489.517L576.286 485.798L577.196 484.26L583.798 487.979L582.888 489.517ZM575.804 484.498C575.666 484.739 575.456 484.907 575.183 484.985C574.91 485.063 574.66 485.04 574.43 484.903C574.199 484.767 574.047 484.572 573.993 484.305C573.939 484.039 573.975 483.776 574.121 483.539C574.263 483.289 574.468 483.13 574.741 483.052C575.014 482.974 575.264 482.997 575.495 483.134C575.726 483.27 575.877 483.465 575.931 483.732C575.994 484.003 575.955 484.252 575.804 484.498Z"
              fill="white"
            />
            <path
              d="M590.312 482.314C590.01 482.882 589.68 483.321 589.31 483.65C588.94 483.978 588.563 484.201 588.176 484.318C587.79 484.435 587.409 484.464 587.045 484.402L587.451 482.811C587.621 482.813 587.796 482.784 587.987 482.73C588.178 482.676 588.38 482.561 588.576 482.397C588.772 482.233 588.954 481.994 589.122 481.68C589.354 481.233 589.447 480.81 589.382 480.404C589.317 479.998 589.034 479.675 588.536 479.428L587.248 478.786L587.204 478.864C587.326 479.031 587.436 479.237 587.527 479.477C587.618 479.718 587.653 479.996 587.638 480.302C587.618 480.618 587.511 480.965 587.309 481.339C587.038 481.855 586.673 482.254 586.207 482.552C585.745 482.842 585.2 482.997 584.579 482.986C583.962 482.989 583.282 482.801 582.544 482.435C581.798 482.064 581.238 481.626 580.852 481.124C580.465 480.621 580.254 480.091 580.21 479.549C580.161 478.994 580.277 478.472 580.548 477.956C580.76 477.564 580.994 477.275 581.258 477.092C581.522 476.908 581.788 476.782 582.059 476.727C582.33 476.671 582.564 476.641 582.765 476.649L582.814 476.563L581.688 475.997L582.503 474.448L589.386 477.898C589.962 478.187 590.373 478.567 590.6 479.028C590.837 479.494 590.923 480.002 590.859 480.574C590.795 481.147 590.605 481.719 590.293 482.326L590.312 482.314ZM586.293 480.282C586.47 479.951 586.54 479.628 586.498 479.302C586.456 478.975 586.314 478.685 586.073 478.408C585.832 478.131 585.49 477.889 585.058 477.667C584.63 477.457 584.225 477.328 583.838 477.287C583.451 477.245 583.113 477.298 582.815 477.441C582.517 477.583 582.277 477.824 582.095 478.164C581.907 478.512 581.85 478.853 581.906 479.175C581.97 479.502 582.13 479.803 582.388 480.089C582.647 480.375 582.979 480.612 583.39 480.812C583.804 481.025 584.197 481.137 584.575 481.173C584.945 481.205 585.283 481.152 585.577 480.996C585.871 480.841 586.111 480.6 586.299 480.251L586.293 480.282Z"
              fill="white"
            />
            <path
              d="M583.76 469.4C583.64 469.651 583.447 469.828 583.182 469.933C582.917 470.037 582.671 470.027 582.425 469.917C582.18 469.806 582.023 469.62 581.933 469.357C581.852 469.098 581.865 468.834 581.999 468.58C582.119 468.329 582.311 468.151 582.577 468.047C582.842 467.943 583.088 467.952 583.333 468.063C583.579 468.173 583.735 468.36 583.825 468.623C583.906 468.882 583.893 469.145 583.76 469.4ZM591.248 473.824L584.347 470.655L585.126 469.047L592.027 472.216L591.248 473.824Z"
              fill="white"
            />
            <path
              d="M590.54 464.845L594.634 466.558L593.915 468.18L586.922 465.255L587.611 463.707L588.798 464.204L588.842 464.127C588.514 463.803 588.314 463.414 588.238 462.968C588.162 462.522 588.239 462.047 588.466 461.529C588.677 461.059 588.959 460.684 589.33 460.413C589.688 460.146 590.116 459.995 590.591 459.961C591.067 459.926 591.589 460.03 592.149 460.266L596.603 462.129L595.883 463.75L591.688 461.994C591.219 461.796 590.805 461.763 590.437 461.889C590.069 462.016 589.787 462.29 589.605 462.708C589.479 462.989 589.434 463.268 589.461 463.541C589.488 463.814 589.596 464.063 589.777 464.285C589.958 464.507 590.217 464.692 590.559 464.833L590.54 464.845Z"
              fill="white"
            />
          </g>
        )}
        <g filter="url(#G)" onClick={() => onSelectPhase(ProcessSchemePhase.BAV_AUDIENCE_DB)}>
          <circle
            cx={657.079}
            cy={523}
            r={selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB ? 'url(#paint26_linear_295_33363)' : '#fff'}
            transform="rotate(120 657.079 523)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End BAV_AUDIENCE_DB Phase */}
        {/* Start SYNAPSE_3 Phase */}
        {selectedPhase === ProcessSchemePhase.SYNAPSE_3 && (
          <g
            transform="translate(32, 23)"
            filter="url(#filter22_d_295_33899)"
            // ref={synapsePhase3WrapperRef}
          >
            <path
              d="M347.277 666.715C421.853 663.632 493.239 635.62 550.011 587.164L404.869 417.116C386.155 433.089 362.624 442.323 338.041 443.339L347.277 666.715Z"
              fill="url(#paint0_linear_195_867)"
            />
            <g transform="translate(13, 12)">
              <path
                d="M379.739 542.031C379.595 541.622 379.33 541.34 378.952 541.181C378.562 541.017 378.11 541 377.578 541.106C377.206 541.186 376.892 541.308 376.658 541.475C376.415 541.645 376.246 541.841 376.146 542.054C376.05 542.276 376.021 542.507 376.083 542.756C376.128 542.96 376.222 543.125 376.347 543.258C376.481 543.388 376.636 543.49 376.818 543.551C377.003 543.621 377.209 543.662 377.422 543.692C377.634 543.721 377.852 543.737 378.066 543.744L379.042 543.774C379.436 543.78 379.822 543.82 380.2 543.895C380.577 543.97 380.928 544.085 381.256 544.251C381.583 544.417 381.869 544.64 382.099 544.913C382.33 545.187 382.497 545.545 382.594 545.971C382.725 546.541 382.693 547.075 382.5 547.573C382.307 548.07 381.95 548.5 381.449 548.856C380.948 549.212 380.299 549.485 379.504 549.653C378.728 549.814 378.039 549.84 377.423 549.724C376.807 549.609 376.278 549.358 375.858 548.974C375.437 548.59 375.139 548.08 374.958 547.433L376.722 547.056C376.825 547.385 376.999 547.648 377.239 547.834C377.479 548.021 377.757 548.141 378.09 548.18C378.416 548.231 378.768 548.21 379.141 548.13C379.532 548.043 379.855 547.918 380.126 547.738C380.397 547.558 380.582 547.346 380.702 547.104C380.823 546.862 380.851 546.601 380.788 546.321C380.725 546.071 380.598 545.876 380.42 545.741C380.233 545.609 379.992 545.506 379.707 545.451C379.412 545.399 379.079 545.36 378.704 545.347L377.511 545.301C376.649 545.263 375.936 545.088 375.361 544.77C374.785 544.452 374.423 543.943 374.266 543.246C374.135 542.675 374.176 542.138 374.397 541.631C374.613 541.136 374.969 540.706 375.461 540.353C375.953 540 376.546 539.747 377.24 539.595C377.947 539.448 378.587 539.429 379.168 539.567C379.756 539.693 380.25 539.935 380.642 540.298C381.042 540.657 381.307 541.107 381.44 541.655L379.713 542.019L379.739 542.031Z"
                fill="white"
              />
              <path
                d="M389.611 549.981C389.38 550.043 389.151 550.082 388.947 550.102C388.74 550.114 388.573 550.121 388.44 550.105L388.461 548.676C388.73 548.685 388.962 548.655 389.165 548.603C389.364 548.543 389.515 548.437 389.635 548.279C389.756 548.121 389.83 547.896 389.854 547.595L389.892 547.163L385.137 540.492L386.966 540.009L390.202 545.014L390.277 544.988L390.422 539.093L392.261 538.606L391.633 547.485C391.601 547.904 391.52 548.278 391.371 548.613C391.222 548.948 391.012 549.232 390.718 549.462C390.433 549.689 390.067 549.871 389.615 549.99L389.611 549.981Z"
                fill="white"
              />
              <path
                d="M398.812 539.844L400.22 544.019L398.517 544.562L396.112 537.437L397.739 536.921L398.145 538.136L398.229 538.106C398.261 537.656 398.417 537.255 398.707 536.901C398.987 536.55 399.407 536.286 399.946 536.115C400.448 535.957 400.914 535.927 401.362 536.019C401.801 536.114 402.199 536.328 402.536 536.658C402.872 536.988 403.141 537.447 403.335 538.016L404.869 542.553L403.167 543.096L401.721 538.82C401.562 538.343 401.31 538.014 400.972 537.821C400.634 537.627 400.246 537.608 399.8 537.746C399.507 537.84 399.257 537.992 399.078 538.191C398.899 538.391 398.769 538.636 398.725 538.913C398.672 539.193 398.705 539.506 398.825 539.85L398.812 539.844Z"
                fill="white"
              />
              <path
                d="M411.622 540.298C411.174 540.458 410.738 540.539 410.315 540.512C409.891 540.485 409.515 540.358 409.181 540.121C408.848 539.884 408.59 539.536 408.406 539.079C408.256 538.682 408.196 538.327 408.239 538.019C408.282 537.711 408.407 537.426 408.596 537.17C408.789 536.924 409.03 536.692 409.326 536.492C409.612 536.296 409.924 536.112 410.252 535.943C410.655 535.748 410.974 535.582 411.221 535.452C411.469 535.322 411.632 535.191 411.725 535.074C411.819 534.957 411.829 534.817 411.77 534.661L411.76 534.633C411.631 534.292 411.418 534.064 411.133 533.956C410.849 533.848 410.515 533.863 410.136 534.008C409.725 534.154 409.436 534.372 409.272 534.639C409.108 534.907 409.03 535.175 409.036 535.445L407.394 535.81C407.342 535.337 407.412 534.904 407.581 534.509C407.749 534.114 408.011 533.77 408.367 533.477C408.722 533.183 409.139 532.94 409.64 532.752C409.985 532.629 410.332 532.537 410.706 532.488C411.079 532.438 411.436 532.458 411.792 532.53C412.138 532.605 412.466 532.771 412.758 533.012C413.05 533.253 413.294 533.617 413.477 534.074L415.332 538.759L413.726 539.361L413.342 538.4L413.286 538.42C413.256 538.65 413.189 538.893 413.074 539.122C412.963 539.361 412.782 539.582 412.549 539.78C412.32 539.987 412.014 540.159 411.641 540.291L411.622 540.298ZM411.579 538.922C411.915 538.803 412.177 538.626 412.377 538.398C412.567 538.173 412.688 537.932 412.735 537.664C412.782 537.396 412.757 537.133 412.65 536.878L412.337 536.049C412.289 536.118 412.219 536.185 412.102 536.268C411.985 536.352 411.868 536.435 411.72 536.519C411.572 536.603 411.433 536.684 411.294 536.765C411.155 536.846 411.031 536.911 410.926 536.969C410.688 537.096 410.484 537.231 410.319 537.383C410.155 537.536 410.034 537.694 409.974 537.872C409.914 538.05 409.925 538.246 410.014 538.454C410.129 538.759 410.337 538.946 410.63 539.019C410.923 539.093 411.228 539.058 411.582 538.932L411.579 538.922Z"
                fill="white"
              />
              <path
                d="M421.025 539.498L416.771 530.058L418.385 529.368L418.898 530.503L418.992 530.469C418.997 530.259 419.039 530.034 419.112 529.779C419.185 529.523 419.311 529.269 419.517 529.028C419.719 528.779 420.02 528.567 420.433 528.399C420.97 528.166 421.516 528.098 422.067 528.184C422.618 528.27 423.147 528.521 423.631 528.934C424.115 529.347 424.54 529.938 424.882 530.704C425.221 531.462 425.388 532.155 425.373 532.787C425.358 533.42 425.204 533.966 424.893 534.432C424.591 534.895 424.159 535.236 423.613 535.473C423.213 535.646 422.859 535.72 422.534 535.7C422.209 535.68 421.929 535.612 421.692 535.487C421.454 535.363 421.251 535.247 421.086 535.117L421.021 535.141L422.667 538.798L421.025 539.498ZM419.926 532.802C420.124 533.244 420.367 533.607 420.642 533.885C420.916 534.164 421.219 534.349 421.547 534.431C421.874 534.513 422.21 534.477 422.558 534.333C422.924 534.181 423.178 533.955 423.34 533.657C423.493 533.362 423.563 533.013 423.533 532.627C423.504 532.24 423.401 531.827 423.206 531.395C423.014 530.972 422.784 530.615 422.509 530.336C422.235 530.058 421.935 529.882 421.604 529.791C421.277 529.709 420.928 529.739 420.565 529.899C420.218 530.044 419.961 530.26 419.795 530.55C419.629 530.839 419.562 531.166 419.582 531.556C419.608 531.933 419.714 532.355 419.913 532.796L419.926 532.802Z"
                fill="white"
              />
              <path
                d="M433.503 524.617L432.124 525.484C432.012 525.357 431.867 525.252 431.7 525.175C431.533 525.098 431.34 525.063 431.129 525.064C430.917 525.066 430.686 525.128 430.435 525.248C430.103 525.408 429.856 525.622 429.707 525.873C429.558 526.125 429.532 526.364 429.66 526.591C429.755 526.787 429.908 526.91 430.118 526.961C430.328 527.012 430.627 526.989 431.018 526.903L432.305 526.581C433.024 526.408 433.61 526.388 434.079 526.535C434.548 526.682 434.899 526.996 435.153 527.471C435.367 527.897 435.436 528.332 435.348 528.772C435.269 529.208 435.057 529.628 434.708 530.024C434.369 530.417 433.911 530.747 433.338 531.024C432.504 531.425 431.75 531.589 431.062 531.478C430.377 531.377 429.829 531.049 429.421 530.472L430.911 529.566C431.138 529.83 431.399 529.988 431.713 530.033C432.027 530.079 432.362 530.012 432.727 529.829C433.102 529.644 433.378 529.42 433.529 529.147C433.684 528.883 433.697 528.637 433.588 528.404C433.492 528.208 433.33 528.088 433.12 528.037C432.911 527.986 432.63 528.002 432.28 528.085L431.002 528.404C430.286 528.585 429.684 528.59 429.209 528.425C428.733 528.259 428.374 527.927 428.127 527.44C427.907 527.026 427.845 526.609 427.921 526.195C427.998 525.781 428.198 525.385 428.526 525.018C428.853 524.65 429.296 524.336 429.832 524.072C430.628 523.684 431.339 523.546 431.974 523.655C432.608 523.764 433.121 524.084 433.518 524.601L433.503 524.617Z"
                fill="white"
              />
              <path
                d="M443.27 525.902C442.602 526.266 441.958 526.442 441.318 526.43C440.677 526.417 440.087 526.229 439.537 525.87C438.988 525.511 438.521 524.976 438.128 524.3C437.744 523.621 437.538 522.962 437.502 522.305C437.466 521.649 437.602 521.056 437.917 520.515C438.232 519.974 438.689 519.529 439.324 519.188C439.729 518.971 440.159 518.818 440.589 518.749C441.018 518.68 441.46 518.7 441.897 518.817C442.333 518.934 442.748 519.163 443.148 519.491C443.548 519.82 443.911 520.276 444.244 520.848L444.516 521.316L439.086 524.284L438.491 523.24L442.429 521.096C442.255 520.803 442.042 520.575 441.777 520.408C441.512 520.24 441.238 520.16 440.935 520.142C440.635 520.134 440.335 520.21 440.035 520.369C439.719 520.544 439.478 520.776 439.321 521.062C439.164 521.348 439.096 521.644 439.101 521.966C439.107 522.289 439.19 522.594 439.365 522.888L439.886 523.79C440.104 524.173 440.361 524.458 440.658 524.656C440.955 524.853 441.268 524.951 441.609 524.956C441.949 524.96 442.278 524.875 442.621 524.69C442.85 524.566 443.037 524.416 443.18 524.261C443.328 524.093 443.432 523.92 443.495 523.72C443.557 523.52 443.571 523.305 443.532 523.089L445.083 522.454C445.215 522.888 445.228 523.312 445.132 523.733C445.037 524.154 444.837 524.55 444.525 524.933C444.214 525.315 443.803 525.629 443.286 525.917L443.27 525.902Z"
                fill="white"
              />
              <path
                d="M397.729 554.81L399.867 554.154L405.005 560.176L405.117 560.136L405.716 552.346L407.854 551.69L410.987 561.218L409.305 561.733L407.149 555.185L407.065 555.215L406.538 562.55L405.284 562.933L400.466 557.227L400.382 557.257L402.542 563.815L400.859 564.33L397.726 554.801L397.729 554.81Z"
                fill="white"
              />
              <path
                d="M412.154 551.801L411.596 550.379L419.134 547.561L419.701 548.98L416.777 550.072L419.913 557.981L418.215 558.616L415.079 550.708L412.154 551.801Z"
                fill="white"
              />
              <path
                d="M422.668 546.172L424.719 545.286L430.48 550.74L430.592 550.7L430.328 542.872L432.382 541.994L436.54 551.148L434.924 551.838L432.071 545.548L431.987 545.578L432.269 552.95L431.067 553.472L425.653 548.292L425.569 548.322L428.429 554.63L426.813 555.32L422.655 546.166L422.668 546.172Z"
                fill="white"
              />
            </g>
          </g>
        )}
        <g filter="url(#H)" onClick={() => onSelectPhase(ProcessSchemePhase.SYNAPSE_3)}>
          <circle
            cx={482.06}
            cy={669.857}
            r={selectedPhase === ProcessSchemePhase.SYNAPSE_3 ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.SYNAPSE_3 ? 'url(#paint33_linear_295_33899)' : '#fff'}
            transform="rotate(160 482.06 669.857)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End SYNAPSE_3 Phase */}
        {/* Start CHOREOGRAPH_SYNAPSE Phase */}
        {selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && (
          <g
            transform="translate(136, 416)"
            // ref={choreographSynapsePhaseWrapperRef}
          >
            <g filter="url(#filter0_d_195_884)">
              <path
                d="M16.1445 195.823C79.9963 248.971 161.386 276.375 244.38 272.668L234 49.5C206.481 50.7291 179.927 42.1164 158.755 24.4932L16.1445 195.823Z"
                fill="url(#paint0_linear_195_884)"
              />
            </g>
            <path
              d="M119.013 158.219L117.479 156.985L126.654 151.298L128.428 152.725L124.844 162.908L123.31 161.674L126.263 153.663L126.201 153.613L119.013 158.219ZM121.566 155.149L125.75 158.514L124.821 159.668L120.637 156.303L121.566 155.149Z"
              fill="white"
            />
            <path
              d="M126.449 163.756L130.64 157.372L132.099 158.33L131.4 159.394L131.467 159.437C131.825 159.145 132.211 158.992 132.625 158.978C133.043 158.963 133.435 159.076 133.8 159.316C133.883 159.371 133.974 159.436 134.071 159.511C134.172 159.586 134.253 159.653 134.313 159.712L133.405 161.096C133.356 161.032 133.267 160.946 133.14 160.839C133.017 160.73 132.891 160.634 132.764 160.551C132.49 160.371 132.204 160.268 131.907 160.244C131.614 160.218 131.337 160.268 131.076 160.394C130.815 160.52 130.596 160.719 130.417 160.991L127.953 164.743L126.449 163.756Z"
              fill="white"
            />
            <path
              d="M135.679 169.39C135.007 169.031 134.509 168.574 134.185 168.021C133.864 167.469 133.713 166.864 133.731 166.204C133.754 165.543 133.948 164.873 134.313 164.192C134.679 163.508 135.133 162.975 135.676 162.593C136.221 162.208 136.811 161.998 137.447 161.962C138.087 161.925 138.736 162.083 139.393 162.435C139.939 162.727 140.369 163.087 140.682 163.514C140.999 163.94 141.185 164.402 141.241 164.902C141.298 165.399 141.215 165.899 140.99 166.403L139.474 165.591C139.586 165.234 139.585 164.885 139.47 164.546C139.359 164.204 139.112 163.931 138.73 163.726C138.405 163.553 138.073 163.488 137.734 163.532C137.396 163.573 137.072 163.718 136.761 163.969C136.453 164.222 136.178 164.574 135.935 165.027C135.689 165.486 135.545 165.916 135.503 166.318C135.462 166.718 135.515 167.071 135.662 167.379C135.814 167.686 136.055 167.928 136.385 168.105C136.619 168.23 136.851 168.298 137.082 168.309C137.318 168.318 137.544 168.272 137.76 168.17C137.976 168.068 138.173 167.908 138.349 167.69L139.866 168.502C139.573 168.958 139.206 169.305 138.767 169.544C138.329 169.779 137.845 169.889 137.316 169.872C136.789 169.853 136.243 169.692 135.679 169.39Z"
              fill="white"
            />
            <path
              d="M144.624 168.439L142.916 172.575L141.253 171.888L145.139 162.477L146.766 163.149L145.299 166.701L145.382 166.735C145.712 166.405 146.097 166.196 146.538 166.11C146.984 166.021 147.476 166.088 148.015 166.311C148.505 166.513 148.89 166.792 149.17 167.148C149.45 167.504 149.608 167.921 149.644 168.398C149.682 168.877 149.584 169.401 149.349 169.971L147.493 174.465L145.83 173.778L147.579 169.541C147.775 169.066 147.805 168.646 147.669 168.282C147.537 167.915 147.252 167.642 146.814 167.461C146.52 167.339 146.23 167.295 145.944 167.327C145.662 167.358 145.406 167.466 145.176 167.65C144.949 167.836 144.765 168.099 144.624 168.439Z"
              fill="white"
            />
            <path
              d="M149.968 175.171L152.294 167.898L154.008 168.446L151.682 175.719L149.968 175.171ZM153.486 167.141C153.214 167.054 153.01 166.889 152.871 166.647C152.734 166.401 152.707 166.15 152.788 165.895C152.871 165.636 153.039 165.448 153.292 165.33C153.547 165.21 153.81 165.193 154.081 165.28C154.356 165.368 154.56 165.534 154.694 165.779C154.832 166.021 154.86 166.272 154.777 166.531C154.695 166.786 154.527 166.975 154.273 167.095C154.023 167.213 153.761 167.229 153.486 167.141Z"
              fill="white"
            />
            <path
              d="M161.342 170.345L161.013 171.698L156.747 170.662L157.076 169.31L161.342 170.345ZM158.56 167.787L160.309 168.212L158.619 175.179C158.562 175.414 158.553 175.603 158.594 175.745C158.638 175.886 158.714 175.993 158.822 176.067C158.931 176.141 159.057 176.196 159.202 176.231C159.312 176.257 159.414 176.274 159.508 176.279C159.605 176.286 159.679 176.289 159.731 176.288L159.694 177.726C159.592 177.736 159.45 177.739 159.267 177.735C159.087 177.732 158.872 177.704 158.622 177.65C158.181 177.557 157.803 177.395 157.487 177.165C157.172 176.932 156.949 176.635 156.817 176.276C156.69 175.918 156.683 175.507 156.799 175.044L158.56 167.787Z"
              fill="white"
            />
            <path
              d="M166.471 179.078C165.714 178.964 165.083 178.709 164.58 178.312C164.08 177.912 163.726 177.4 163.518 176.775C163.311 176.148 163.266 175.439 163.384 174.649C163.501 173.872 163.751 173.212 164.134 172.67C164.52 172.125 165.005 171.728 165.589 171.48C166.173 171.229 166.825 171.158 167.546 171.266C168.012 171.336 168.44 171.477 168.83 171.69C169.224 171.9 169.557 172.184 169.828 172.543C170.103 172.903 170.296 173.337 170.407 173.846C170.519 174.353 170.525 174.937 170.426 175.599L170.344 176.145L164.154 175.217L164.334 174.017L168.818 174.689C168.866 174.348 168.837 174.033 168.733 173.746C168.629 173.456 168.459 173.214 168.223 173.021C167.991 172.829 167.704 172.707 167.363 172.656C166.999 172.602 166.666 172.642 166.364 172.778C166.063 172.91 165.814 173.108 165.617 173.37C165.424 173.629 165.301 173.927 165.247 174.264L165.09 175.312C165.024 175.751 165.048 176.14 165.161 176.479C165.275 176.814 165.464 177.087 165.727 177.297C165.991 177.505 166.316 177.637 166.703 177.695C166.962 177.734 167.202 177.733 167.422 177.692C167.644 177.648 167.842 177.566 168.018 177.445C168.193 177.324 168.336 177.162 168.447 176.961L170.081 177.397C169.91 177.821 169.653 178.174 169.309 178.458C168.968 178.738 168.557 178.935 168.074 179.047C167.592 179.155 167.057 179.166 166.471 179.078Z"
              fill="white"
            />
            <path
              d="M175.832 179.859C175.071 179.822 174.425 179.623 173.895 179.262C173.368 178.901 172.972 178.419 172.706 177.815C172.444 177.209 172.332 176.519 172.369 175.748C172.407 174.973 172.589 174.297 172.916 173.719C173.243 173.138 173.686 172.695 174.246 172.39C174.808 172.083 175.462 171.947 176.207 171.983C176.826 172.014 177.368 172.155 177.834 172.406C178.303 172.655 178.669 172.993 178.934 173.421C179.198 173.845 179.337 174.333 179.35 174.884L177.632 174.801C177.58 174.43 177.43 174.115 177.18 173.858C176.934 173.597 176.594 173.456 176.161 173.435C175.793 173.417 175.466 173.5 175.178 173.685C174.89 173.867 174.66 174.138 174.486 174.498C174.316 174.858 174.218 175.294 174.193 175.807C174.168 176.327 174.222 176.778 174.356 177.159C174.49 177.537 174.69 177.834 174.955 178.05C175.223 178.262 175.544 178.377 175.918 178.395C176.183 178.408 176.422 178.37 176.636 178.281C176.853 178.189 177.037 178.05 177.189 177.865C177.34 177.68 177.449 177.451 177.516 177.179L179.234 177.263C179.165 177.801 178.982 178.271 178.687 178.675C178.392 179.075 178.002 179.381 177.517 179.593C177.033 179.802 176.471 179.891 175.832 179.859Z"
              fill="white"
            />
            <path
              d="M185.369 171.929L185.425 173.32L181.039 173.498L180.982 172.107L185.369 171.929ZM181.991 170.235L183.789 170.162L184.08 177.325C184.089 177.567 184.133 177.751 184.211 177.877C184.293 178 184.395 178.082 184.52 178.124C184.644 178.165 184.781 178.183 184.93 178.177C185.043 178.172 185.145 178.16 185.237 178.139C185.332 178.119 185.405 178.101 185.454 178.086L185.814 179.479C185.719 179.516 185.583 179.558 185.406 179.605C185.232 179.652 185.018 179.684 184.763 179.701C184.313 179.732 183.905 179.681 183.538 179.547C183.17 179.409 182.874 179.185 182.65 178.876C182.428 178.567 182.309 178.173 182.293 177.696L181.991 170.235Z"
              fill="white"
            />
          </g>
        )}
        <g filter="url(#I)" onClick={() => onSelectPhase(ProcessSchemePhase.CHOREOGRAPH_SYNAPSE)}>
          <circle
            cx={253.593}
            cy={669.857}
            r={selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE ? 'url(#paint35_linear_295_34492)' : '#fff'}
            transform="rotate(-160 253.593 669.857)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End CHOREOGRAPH_SYNAPSE Phase */}
        {/* Start MEDIA_ACTIVITY_PLAN Phase */}
        {selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN && (
          <g
            transform="translate(0, -18)"
            // ref={mediaActivityPlanPhaseWrapperRef}
          >
            <g filter="url(#filter0_d_195_897)">
              <path
                d="M35.0987 423.625C47.7598 503.636 89.1096 576.303 151.427 628.058L293.865 456.551C273.206 439.394 259.499 415.304 255.301 388.78L35.0987 423.625Z"
                fill="url(#paint0_linear_195_897)"
              />
            </g>
            <path
              d="M35.0987 423.625C47.7598 503.636 89.1096 576.303 151.427 628.058L193.919 576.894C144.029 535.46 110.926 477.285 100.79 413.23L35.0987 423.625Z"
              fill="url(#paint1_linear_195_897)"
            />
            <path
              d="M151.221 434.628L150.554 432.809L161.337 432.818L162.111 434.927L153.768 441.552L153.101 439.733L159.785 434.597L159.746 434.528L151.226 434.636L151.221 434.628ZM154.994 433.393L156.819 438.36L155.429 438.839L153.604 433.872L154.994 433.393Z"
              fill="white"
            />
            <path
              d="M160.198 448.724L164.269 447.071L164.975 448.7L157.953 451.549L157.275 449.972L158.525 449.467L158.486 449.399C158.03 449.397 157.604 449.267 157.199 449.014C156.794 448.761 156.485 448.377 156.259 447.859C156.059 447.405 155.994 446.969 156.054 446.533C156.105 446.101 156.293 445.708 156.602 445.351C156.912 444.993 157.349 444.701 157.907 444.48L162.377 442.668L163.084 444.297L158.864 446.008C158.421 446.19 158.116 446.455 157.957 446.799C157.799 447.142 157.803 447.51 157.969 447.904C158.07 448.141 158.227 448.359 158.45 448.531C158.664 448.708 158.924 448.826 159.221 448.868C159.523 448.919 159.847 448.868 160.198 448.724Z"
              fill="white"
            />
            <path
              d="M161.051 458.499C160.785 457.969 160.692 457.423 160.761 456.881C160.829 456.339 161.069 455.817 161.484 455.324C161.899 454.831 162.469 454.411 163.227 454.06C163.992 453.704 164.691 453.53 165.339 453.529C165.988 453.528 166.554 453.683 167.028 453.978C167.503 454.273 167.871 454.681 168.132 455.203C168.325 455.604 168.423 455.956 168.411 456.277C168.399 456.598 168.34 456.876 168.222 457.109C168.108 457.35 167.983 457.552 167.856 457.71L167.891 457.77L171.313 456.174L172.094 457.775L162.944 462.039L162.168 460.469L163.249 459.966L163.195 459.871C162.998 459.865 162.759 459.825 162.492 459.756C162.225 459.686 161.969 459.555 161.709 459.358C161.449 459.161 161.23 458.874 161.033 458.486L161.051 458.499ZM162.595 458.33C162.763 458.667 162.994 458.913 163.296 459.065C163.598 459.216 163.95 459.274 164.342 459.243C164.734 459.211 165.153 459.087 165.595 458.882C166.037 458.678 166.397 458.428 166.666 458.16C166.93 457.883 167.104 457.587 167.16 457.265C167.23 456.946 167.174 456.606 167.006 456.27C166.829 455.916 166.593 455.661 166.291 455.509C165.989 455.358 165.637 455.3 165.245 455.331C164.853 455.363 164.443 455.483 164.018 455.678C163.585 455.877 163.243 456.118 162.956 456.395C162.683 456.677 162.509 456.973 162.435 457.305C162.361 457.636 162.413 457.968 162.582 458.326L162.595 458.33Z"
              fill="white"
            />
            <path
              d="M165.202 466.356L171.941 462.88L172.794 464.448L166.055 467.924L165.202 466.356ZM173.326 463.17C173.184 462.919 173.163 462.66 173.232 462.398C173.309 462.131 173.461 461.936 173.702 461.818C173.943 461.699 174.193 461.677 174.453 461.773C174.713 461.87 174.923 462.039 175.051 462.285C175.187 462.527 175.213 462.795 175.145 463.057C175.068 463.324 174.916 463.519 174.675 463.637C174.439 463.765 174.184 463.778 173.924 463.682C173.655 463.59 173.454 463.416 173.326 463.17Z"
              fill="white"
            />
            <path
              d="M169.926 475.021C169.533 474.368 169.342 473.73 169.34 473.103C169.337 472.476 169.506 471.891 169.869 471.348C170.224 470.809 170.748 470.347 171.436 469.955C172.115 469.567 172.784 469.363 173.449 469.331C174.113 469.299 174.723 469.431 175.286 469.722C175.841 470.018 176.31 470.484 176.678 471.094C176.907 471.498 177.074 471.913 177.157 472.34C177.24 472.767 177.224 473.202 177.115 473.631C177.005 474.06 176.792 474.467 176.462 474.869C176.141 475.266 175.684 475.625 175.105 475.948L174.625 476.206L171.475 470.888L172.52 470.302L174.806 474.156C175.098 473.987 175.329 473.773 175.491 473.517C175.654 473.262 175.734 472.982 175.739 472.688C175.743 472.394 175.653 472.095 175.478 471.807C175.298 471.489 175.055 471.261 174.757 471.118C174.469 470.97 174.152 470.894 173.827 470.901C173.501 470.908 173.187 470.998 172.891 471.158L171.977 471.672C171.593 471.879 171.31 472.143 171.114 472.44C170.918 472.736 170.83 473.042 170.836 473.375C170.838 473.699 170.939 474.038 171.138 474.369C171.264 474.592 171.425 474.775 171.597 474.918C171.769 475.061 171.953 475.164 172.162 475.232C172.365 475.29 172.581 475.309 172.807 475.266L173.486 476.786C173.045 476.911 172.612 476.931 172.183 476.836C171.745 476.746 171.338 476.55 170.949 476.244C170.559 475.937 170.218 475.537 169.917 475.026L169.926 475.021Z"
              fill="white"
            />
            <path
              d="M178.882 480.794L175.144 483.154L174.162 481.662L180.548 477.63L181.491 479.052L180.41 479.735L180.454 479.813C180.919 479.731 181.346 479.781 181.77 479.968C182.195 480.155 182.563 480.484 182.871 480.947C183.164 481.384 183.319 481.817 183.344 482.263C183.37 482.71 183.264 483.126 183.028 483.533C182.793 483.941 182.416 484.3 181.904 484.621L177.841 487.19L176.859 485.699L180.688 483.278C181.113 483.004 181.38 482.692 181.474 482.316C181.573 481.949 181.492 481.566 181.242 481.184C181.073 480.927 180.859 480.728 180.616 480.601C180.373 480.474 180.102 480.417 179.808 480.441C179.514 480.464 179.22 480.567 178.912 480.766L178.882 480.794Z"
              fill="white"
            />
            <path
              d="M182.414 493.896C181.965 493.285 181.717 492.667 181.67 492.041C181.622 491.416 181.751 490.819 182.055 490.251C182.359 489.683 182.822 489.176 183.45 488.737C184.077 488.298 184.722 488.03 185.37 487.928C186.017 487.826 186.651 487.9 187.235 488.147C187.818 488.394 188.339 488.81 188.769 489.409C189.132 489.909 189.357 490.405 189.466 490.919C189.561 491.429 189.53 491.917 189.36 492.379C189.189 492.841 188.904 493.242 188.473 493.586L187.466 492.209C187.726 491.945 187.873 491.642 187.923 491.289C187.965 490.941 187.868 490.59 187.615 490.243C187.4 489.944 187.131 489.752 186.804 489.636C186.482 489.529 186.124 489.52 185.739 489.603C185.354 489.687 184.952 489.881 184.536 490.172C184.112 490.468 183.801 490.782 183.593 491.118C183.385 491.454 183.275 491.783 183.273 492.12C183.271 492.458 183.38 492.77 183.609 493.073C183.762 493.283 183.943 493.443 184.154 493.554C184.356 493.67 184.578 493.719 184.819 493.724C185.061 493.728 185.304 493.676 185.558 493.562L186.566 494.938C186.105 495.209 185.62 495.358 185.129 495.375C184.63 495.397 184.15 495.274 183.683 495.031C183.215 494.789 182.795 494.409 182.422 493.892L182.414 493.896Z"
              fill="white"
            />
            <path
              d="M189.21 502.673C188.726 502.08 188.443 501.481 188.342 500.862C188.242 500.242 188.329 499.634 188.601 499.05C188.873 498.466 189.322 497.932 189.943 497.441C190.551 496.968 191.184 496.661 191.84 496.532C192.495 496.404 193.109 496.443 193.709 496.659C194.309 496.875 194.837 497.264 195.288 497.818C195.582 498.176 195.806 498.571 195.946 498.979C196.09 499.395 196.137 499.818 196.099 500.254C196.053 500.694 195.904 501.134 195.638 501.568C195.373 502.003 194.986 502.425 194.464 502.829L194.037 503.16L190.124 498.343L191.072 497.607L193.904 501.099C194.172 500.888 194.361 500.64 194.483 500.372C194.604 500.105 194.644 499.814 194.599 499.513C194.553 499.212 194.433 498.94 194.215 498.676C193.988 498.395 193.714 498.194 193.406 498.091C193.092 497.979 192.774 497.959 192.463 498.014C192.144 498.074 191.851 498.198 191.579 498.401L190.76 499.044C190.41 499.31 190.17 499.608 190.028 499.92C189.877 500.237 189.834 500.563 189.89 500.881C189.941 501.213 190.094 501.523 190.332 501.822C190.493 502.027 190.675 502.187 190.863 502.299C191.057 502.419 191.257 502.491 191.468 502.523C191.68 502.555 191.898 502.538 192.11 502.469L193.012 503.88C192.596 504.07 192.17 504.143 191.727 504.123C191.287 504.09 190.847 503.957 190.425 503.713C190.002 503.469 189.597 503.115 189.227 502.663L189.21 502.673Z"
              fill="white"
            />
            <path
              d="M137.547 437.072L139.001 436.566L141.792 444.074L140.338 444.58L139.253 441.674L131.156 444.509L130.526 442.817L138.623 439.983L137.538 437.077L137.547 437.072Z"
              fill="white"
            />
            <path
              d="M133.461 450.489L140.521 447.725L141.183 449.311L140.009 449.768L140.048 449.837C140.498 449.81 140.905 449.907 141.254 450.125C141.608 450.351 141.862 450.663 142.027 451.058C142.066 451.149 142.102 451.253 142.143 451.366C142.183 451.479 142.205 451.579 142.227 451.657L140.7 452.257C140.701 452.178 140.67 452.061 140.627 451.904C140.585 451.747 140.534 451.595 140.478 451.457C140.354 451.153 140.174 450.913 139.947 450.731C139.72 450.55 139.46 450.431 139.172 450.382C138.883 450.334 138.599 450.374 138.291 450.493L134.138 452.122L133.456 450.48L133.461 450.489Z"
              fill="white"
            />
            <path
              d="M137.315 459.864C137.118 459.431 137.015 459.005 137.019 458.588C137.024 458.171 137.133 457.799 137.343 457.464C137.552 457.128 137.903 456.863 138.359 456.665C138.749 456.49 139.109 456.422 139.432 456.452C139.755 456.481 140.054 456.59 140.312 456.767C140.583 456.947 140.826 457.176 141.047 457.45C141.269 457.724 141.474 458.029 141.662 458.343C141.888 458.727 142.071 459.032 142.229 459.273C142.386 459.514 142.518 459.668 142.642 459.747C142.766 459.827 142.91 459.84 143.063 459.769L143.089 459.755C143.427 459.609 143.651 459.4 143.742 459.117C143.832 458.833 143.796 458.505 143.624 458.138C143.446 457.74 143.216 457.471 142.932 457.308C142.648 457.146 142.377 457.089 142.097 457.115L141.618 455.511C142.093 455.448 142.537 455.492 142.95 455.641C143.364 455.791 143.727 456.034 144.049 456.366C144.372 456.698 144.644 457.102 144.87 457.586C145.023 457.919 145.135 458.262 145.2 458.62C145.269 458.986 145.284 459.336 145.227 459.68C145.17 460.024 145.028 460.357 144.794 460.65C144.561 460.942 144.214 461.194 143.749 461.397L139.101 463.414L138.392 461.865L139.34 461.45L139.311 461.398C139.073 461.379 138.838 461.325 138.583 461.215C138.333 461.113 138.103 460.945 137.879 460.728C137.655 460.511 137.464 460.21 137.301 459.86L137.315 459.864ZM138.709 459.759C138.857 460.083 139.056 460.336 139.291 460.513C139.531 460.699 139.795 460.804 140.065 460.84C140.334 460.875 140.61 460.841 140.865 460.728L141.685 460.369C141.621 460.336 141.538 460.268 141.444 460.161C141.35 460.055 141.26 459.935 141.16 459.797C141.061 459.66 140.974 459.527 140.884 459.385C140.797 459.252 140.716 459.127 140.649 459.028C140.51 458.8 140.359 458.612 140.191 458.455C140.024 458.297 139.854 458.197 139.673 458.137C139.488 458.09 139.292 458.105 139.081 458.195C138.778 458.323 138.599 458.53 138.544 458.817C138.489 459.104 138.538 459.414 138.691 459.747L138.709 459.759Z"
              fill="white"
            />
            <path
              d="M145.817 467.027L141.827 468.961L141.018 467.377L147.823 464.066L148.602 465.576L147.442 466.139L147.485 466.216C147.944 466.185 148.373 466.281 148.767 466.519C149.162 466.756 149.49 467.119 149.748 467.62C149.987 468.086 150.091 468.535 150.069 468.983C150.048 469.431 149.891 469.839 149.612 470.212C149.333 470.584 148.913 470.909 148.365 471.167L144.03 473.274L143.221 471.69L147.307 469.704C147.762 469.484 148.054 469.194 148.195 468.839C148.337 468.484 148.302 468.099 148.089 467.697C147.948 467.426 147.755 467.203 147.534 467.052C147.299 466.897 147.037 466.813 146.75 466.809C146.459 466.796 146.15 466.872 145.822 467.035L145.817 467.027Z"
              fill="white"
            />
            <path
              d="M154.089 479.867L153.112 478.55C153.237 478.428 153.329 478.289 153.393 478.121C153.458 477.952 153.49 477.767 153.463 477.557C153.45 477.351 153.369 477.126 153.225 476.889C153.046 476.57 152.812 476.336 152.543 476.199C152.273 476.063 152.032 476.057 151.809 476.187C151.617 476.289 151.503 476.451 151.458 476.654C151.418 476.866 151.457 477.159 151.576 477.532L151.982 478.783C152.208 479.469 152.264 480.055 152.145 480.51C152.03 480.974 151.733 481.334 151.262 481.608C150.842 481.831 150.403 481.919 149.949 481.859C149.495 481.798 149.058 481.606 148.629 481.286C148.208 480.961 147.832 480.534 147.514 479.987C147.056 479.189 146.846 478.45 146.897 477.773C146.949 477.096 147.266 476.547 147.815 476.109L148.836 477.525C148.582 477.761 148.437 478.028 148.414 478.332C148.391 478.635 148.486 478.965 148.684 479.319C148.892 479.69 149.144 479.936 149.427 480.077C149.71 480.217 149.96 480.218 150.187 480.097C150.38 479.995 150.494 479.833 150.534 479.621C150.574 479.41 150.544 479.134 150.436 478.8L150.021 477.554C149.791 476.859 149.743 476.268 149.884 475.79C150.025 475.312 150.331 474.948 150.821 474.687C151.227 474.459 151.643 474.372 152.075 474.433C152.502 474.486 152.912 474.67 153.314 474.983C153.711 475.286 154.06 475.705 154.363 476.226C154.802 476.989 154.988 477.685 154.916 478.306C154.844 478.927 154.558 479.449 154.053 479.863L154.089 479.867Z"
              fill="white"
            />
            <path d="M161.066 482.714L152.342 487.806L151.408 486.281L160.132 481.189L161.066 482.714Z" fill="white" />
            <path
              d="M155.761 493.484C155.494 493.089 155.334 492.682 155.272 492.267C155.209 491.852 155.256 491.469 155.423 491.1C155.591 490.731 155.882 490.419 156.299 490.152C156.661 489.926 157 489.801 157.321 489.787C157.642 489.773 157.949 489.833 158.24 489.969C158.532 490.105 158.808 490.294 159.069 490.536C159.329 490.778 159.577 491.037 159.817 491.324C160.1 491.666 160.335 491.944 160.523 492.157C160.706 492.362 160.864 492.502 161.001 492.564C161.138 492.625 161.28 492.616 161.419 492.52L161.445 492.506C161.754 492.308 161.945 492.061 161.986 491.77C162.036 491.475 161.951 491.161 161.722 490.813C161.483 490.448 161.214 490.21 160.914 490.101C160.615 489.992 160.327 489.966 160.058 490.031L159.345 488.518C159.805 488.386 160.255 488.359 160.679 488.446C161.103 488.534 161.513 488.719 161.878 489.006C162.252 489.288 162.58 489.651 162.871 490.089C163.077 490.394 163.229 490.727 163.36 491.061C163.486 491.408 163.546 491.757 163.552 492.112C163.544 492.463 163.465 492.808 163.273 493.134C163.095 493.464 162.786 493.763 162.359 494.035L158.08 496.729L157.139 495.293L158.018 494.735L157.989 494.683C157.753 494.708 157.502 494.685 157.235 494.614C156.973 494.552 156.717 494.42 156.463 494.23C156.21 494.041 155.975 493.785 155.756 493.454L155.761 493.484ZM157.12 493.175C157.321 493.471 157.549 493.697 157.81 493.838C158.071 493.979 158.346 494.045 158.628 494.041C158.909 494.036 159.16 493.958 159.396 493.811L160.153 493.34C160.085 493.32 159.998 493.266 159.887 493.168C159.78 493.079 159.673 492.969 159.552 492.854C159.439 492.735 159.327 492.615 159.219 492.483C159.106 492.363 159.012 492.257 158.937 492.162C158.754 491.958 158.581 491.792 158.392 491.657C158.203 491.523 158.026 491.449 157.828 491.42C157.635 491.4 157.445 491.445 157.253 491.57C156.975 491.741 156.833 491.973 156.827 492.267C156.822 492.561 156.919 492.857 157.116 493.166L157.12 493.175Z"
              fill="white"
            />
            <path
              d="M169.062 499.209L167.927 499.982L165.397 496.441L166.532 495.669L169.062 499.209ZM168.65 495.515L169.692 496.967L163.83 500.965C163.635 501.102 163.502 501.23 163.44 501.363C163.379 501.497 163.362 501.629 163.394 501.747C163.431 501.873 163.485 501.99 163.566 502.115C163.628 502.205 163.694 502.282 163.769 502.354C163.843 502.427 163.89 502.469 163.931 502.503L162.951 503.53C162.872 503.471 162.765 503.382 162.626 503.255C162.486 503.127 162.345 502.978 162.193 502.767C161.923 502.407 161.742 502.044 161.659 501.673C161.576 501.302 161.597 500.933 161.743 500.586C161.881 500.243 162.149 499.932 162.536 499.67L168.637 495.511L168.65 495.515Z"
              fill="white"
            />
            <path
              d="M167.112 509.426C166.658 508.839 166.4 508.238 166.325 507.617C166.25 506.997 166.367 506.397 166.65 505.81C166.934 505.222 167.395 504.708 168.018 504.241C168.647 503.783 169.287 503.487 169.939 503.375C170.591 503.263 171.214 503.323 171.801 503.559C172.387 503.795 172.914 504.208 173.368 504.795C173.822 505.382 174.08 505.983 174.155 506.604C174.231 507.224 174.113 507.824 173.83 508.411C173.542 508.99 173.077 509.518 172.453 509.984C171.83 510.451 171.194 510.734 170.542 510.846C169.89 510.958 169.275 510.894 168.675 510.654C168.079 510.422 167.561 510.004 167.107 509.417L167.112 509.426ZM168.265 508.588C168.511 508.905 168.806 509.106 169.144 509.183C169.487 509.269 169.848 509.245 170.228 509.132C170.607 509.02 170.983 508.82 171.351 508.545C171.719 508.271 172.015 507.968 172.242 507.645C172.465 507.314 172.576 506.986 172.592 506.63C172.609 506.274 172.497 505.953 172.251 505.636C172 505.311 171.706 505.11 171.354 505.028C171.011 504.942 170.645 504.958 170.261 505.084C169.873 505.201 169.506 505.397 169.129 505.676C168.761 505.95 168.466 506.253 168.247 506.571C168.033 506.898 167.913 507.231 167.905 507.582C167.898 507.933 168.015 508.262 168.265 508.588Z"
              fill="white"
            />
            <path
              d="M172.077 515.445L177.966 510.727L179.079 512.062L178.096 512.844L178.135 512.913C178.575 512.746 178.99 512.715 179.393 512.826C179.796 512.936 180.136 513.158 180.415 513.492C180.48 513.569 180.542 513.66 180.618 513.754C180.694 513.848 180.747 513.943 180.786 514.012L179.504 515.031C179.474 514.957 179.416 514.854 179.325 514.712C179.234 514.57 179.144 514.449 179.045 514.334C178.838 514.086 178.597 513.9 178.32 513.79C178.042 513.68 177.759 513.64 177.474 513.68C177.189 513.719 176.917 513.842 176.666 514.043L173.209 516.816L172.063 515.441L172.077 515.445Z"
              fill="white"
            />
            <path
              d="M85.9631 473.247L92.9734 470.392L93.6492 471.946L92.4574 472.43L92.5012 472.508C92.9557 472.487 93.3718 472.599 93.7495 472.845C94.1222 473.081 94.4023 473.435 94.607 473.897C94.8031 474.363 94.8595 474.803 94.7762 475.218C94.6843 475.638 94.473 475.987 94.1297 476.284L94.1687 476.353C94.6106 476.35 95.0364 476.48 95.4287 476.751C95.8209 477.022 96.1205 477.41 96.336 477.91C96.6071 478.549 96.631 479.153 96.394 479.718C96.1571 480.283 95.6514 480.735 94.8711 481.043L90.1611 482.962L89.4551 481.335L93.9066 479.521C94.3403 479.344 94.6101 479.098 94.7207 478.791C94.8265 478.477 94.8072 478.162 94.6668 477.834C94.501 477.441 94.2435 477.187 93.903 477.068C93.5625 476.948 93.1885 476.97 92.7896 477.129L88.4126 478.914L87.7261 477.321L92.2435 475.483C92.6027 475.335 92.8512 475.111 92.9754 474.809C93.0996 474.506 93.0842 474.178 92.9389 473.842C92.8385 473.604 92.6868 473.417 92.4839 473.279C92.2761 473.133 92.0491 473.054 91.7757 473.033C91.5111 473.007 91.2321 473.056 90.9426 473.167L86.675 474.904L85.969 473.277L85.9631 473.247Z"
              fill="white"
            />
            <path
              d="M101.086 488.87C101.46 488.646 101.682 488.336 101.748 487.929C101.814 487.523 101.735 487.083 101.493 486.596C101.325 486.259 101.133 485.981 100.91 485.787C100.686 485.593 100.457 485.47 100.213 485.421C99.9691 485.373 99.7333 485.399 99.4968 485.504C99.3087 485.594 99.1576 485.709 99.0619 485.861C98.9662 486.014 98.9026 486.183 98.8808 486.385C98.8542 486.579 98.8547 486.781 98.8737 486.996C98.9014 487.205 98.9292 487.415 98.9793 487.624L99.1758 488.573C99.2652 488.952 99.3098 489.332 99.3322 489.713C99.3545 490.094 99.3089 490.456 99.2312 490.801C99.1535 491.146 98.9942 491.467 98.7659 491.747C98.5424 492.036 98.2266 492.262 97.8331 492.452C97.2902 492.699 96.7576 492.784 96.2216 492.702C95.6857 492.62 95.1735 492.379 94.6939 491.975C94.2144 491.57 93.8033 491.006 93.452 490.285C93.1105 489.582 92.9185 488.922 92.8897 488.309C92.8561 487.687 92.9866 487.134 93.2726 486.654C93.5538 486.166 93.9914 485.773 94.5943 485.471L95.3809 487.08C95.0668 487.249 94.8539 487.476 94.7274 487.735C94.5971 488.007 94.5484 488.303 94.5813 488.622C94.6141 488.941 94.715 489.28 94.8741 489.621C95.0517 489.974 95.2534 490.27 95.5005 490.485C95.7388 490.705 96.0003 490.845 96.2625 490.906C96.5383 490.97 96.8052 490.939 97.0679 490.82C97.2995 490.706 97.4709 490.547 97.5596 490.342C97.6484 490.137 97.6904 489.89 97.6809 489.592C97.6627 489.298 97.625 488.97 97.5454 488.609L97.3123 487.454C97.148 486.622 97.15 485.902 97.3357 485.286C97.5214 484.669 97.9472 484.216 98.5948 483.912C99.1376 483.664 99.6752 483.588 100.226 483.696C100.772 483.795 101.28 484.049 101.754 484.445C102.22 484.846 102.611 485.354 102.922 485.984C103.238 486.622 103.397 487.244 103.407 487.823C103.421 488.41 103.289 488.941 103.024 489.398C102.76 489.855 102.369 490.211 101.863 490.462L101.095 488.887L101.086 488.87Z"
              fill="white"
            />
            <path
              d="M98.5804 500.345C98.2274 499.682 98.0675 499.038 98.1096 498.41C98.1516 497.781 98.3616 497.208 98.7532 496.694C99.1399 496.171 99.6694 495.74 100.359 495.391C101.04 495.047 101.72 494.861 102.382 494.864C103.043 494.867 103.654 495.021 104.201 495.343C104.748 495.666 105.196 496.155 105.544 496.809C105.837 497.347 105.992 497.881 106.012 498.398C106.032 498.915 105.929 499.396 105.694 499.826C105.46 500.255 105.111 500.623 104.641 500.898L103.837 499.4C104.131 499.175 104.323 498.892 104.419 498.559C104.515 498.227 104.458 497.865 104.257 497.491C104.077 497.172 103.851 496.935 103.544 496.775C103.242 496.623 102.886 496.557 102.494 496.588C102.102 496.619 101.675 496.749 101.216 496.984C100.758 497.22 100.395 497.482 100.145 497.785C99.8822 498.084 99.7317 498.401 99.6802 498.731C99.6287 499.062 99.6887 499.389 99.8739 499.716C100.001 499.939 100.152 500.127 100.338 500.274C100.528 500.408 100.738 500.497 100.976 500.537C101.215 500.576 101.464 500.554 101.729 500.479L102.532 501.977C102.032 502.179 101.54 502.254 101.046 502.205C100.547 502.148 100.083 501.972 99.6568 501.662C99.2311 501.353 98.8622 500.923 98.5678 500.363L98.5804 500.345Z"
              fill="white"
            />
            <path
              d="M102.349 506.931L108.959 503.236L109.834 504.72L108.735 505.335L108.774 505.403C109.222 505.309 109.644 505.351 110.015 505.522C110.399 505.697 110.689 505.968 110.913 506.342C110.961 506.429 111.015 506.523 111.079 506.635C111.137 506.739 111.173 506.843 111.204 506.916L109.769 507.722C109.756 507.639 109.715 507.527 109.655 507.379C109.594 507.232 109.525 507.09 109.438 506.957C109.277 506.673 109.066 506.46 108.808 506.307C108.559 506.149 108.287 506.071 108 506.068C107.714 506.065 107.421 506.132 107.147 506.291L103.262 508.462L102.358 506.926L102.349 506.931Z"
              fill="white"
            />
            <path
              d="M107.953 516.613C107.556 515.973 107.343 515.336 107.327 514.705C107.311 514.074 107.471 513.494 107.821 512.947C108.171 512.399 108.676 511.925 109.359 511.524C110.033 511.127 110.693 510.906 111.361 510.861C112.03 510.815 112.643 510.934 113.202 511.217C113.765 511.508 114.242 511.948 114.624 512.561C114.87 512.956 115.037 513.371 115.128 513.793C115.22 514.216 115.213 514.646 115.117 515.079C115.021 515.512 114.813 515.927 114.492 516.325C114.171 516.722 113.732 517.093 113.163 517.433L112.693 517.708L109.429 512.451L110.46 511.84L112.824 515.651C113.111 515.474 113.342 515.26 113.491 515C113.64 514.741 113.721 514.462 113.716 514.172C113.711 513.883 113.621 513.584 113.442 513.287C113.253 512.974 113.001 512.75 112.713 512.603C112.424 512.455 112.104 512.392 111.783 512.407C111.462 512.423 111.148 512.513 110.857 512.681L109.952 513.213C109.578 513.437 109.291 513.693 109.108 513.993C108.926 514.293 108.837 514.599 108.844 514.932C108.851 515.265 108.955 515.591 109.163 515.917C109.298 516.136 109.459 516.318 109.631 516.461C109.803 516.604 109.995 516.703 110.199 516.762C110.403 516.82 110.622 516.825 110.848 516.782L111.567 518.292C111.131 518.426 110.703 518.454 110.266 518.364C109.833 518.283 109.417 518.092 109.023 517.799C108.63 517.506 108.275 517.101 107.97 516.603L107.953 516.613Z"
              fill="white"
            />
            <path
              d="M113.845 525.732C113.423 525.106 113.192 524.479 113.154 523.848C113.115 523.218 113.257 522.626 113.58 522.07C113.902 521.515 114.398 521.024 115.066 520.597C115.717 520.179 116.376 519.936 117.038 519.86C117.7 519.784 118.318 519.89 118.889 520.155C119.46 520.419 119.954 520.849 120.353 521.454C120.617 521.839 120.802 522.245 120.907 522.671C121.012 523.097 121.027 523.526 120.94 523.955C120.853 524.383 120.663 524.811 120.36 525.221C120.058 525.631 119.629 526.019 119.069 526.376L118.609 526.669L115.15 521.528L116.161 520.883L118.669 524.605C118.951 524.42 119.163 524.193 119.312 523.933C119.456 523.665 119.528 523.391 119.505 523.089C119.495 522.791 119.388 522.501 119.196 522.223C118.99 521.918 118.743 521.704 118.437 521.565C118.136 521.436 117.82 521.381 117.504 521.405C117.179 521.434 116.871 521.533 116.589 521.718L115.704 522.285C115.335 522.517 115.057 522.79 114.888 523.094C114.719 523.398 114.636 523.713 114.66 524.036C114.684 524.36 114.802 524.689 115.018 525.011C115.162 525.225 115.327 525.394 115.508 525.533C115.688 525.671 115.876 525.761 116.084 525.806C116.291 525.852 116.511 525.857 116.732 525.805L117.503 527.287C117.077 527.438 116.645 527.479 116.208 527.411C115.771 527.344 115.352 527.165 114.946 526.89C114.535 526.607 114.173 526.229 113.845 525.732Z"
              fill="white"
            />
            <path
              d="M122.884 530.743L119.253 533.27L118.206 531.825L124.399 527.514L125.407 528.891L124.356 529.625L124.399 529.702C124.855 529.603 125.295 529.636 125.727 529.796C126.15 529.961 126.536 530.28 126.87 530.729C127.176 531.148 127.352 531.58 127.4 532.026C127.448 532.472 127.361 532.9 127.139 533.311C126.917 533.723 126.564 534.104 126.066 534.45L122.123 537.19L121.076 535.745L124.79 533.162C125.205 532.871 125.454 532.546 125.534 532.166C125.619 531.795 125.521 531.421 125.254 531.049C125.068 530.801 124.849 530.616 124.597 530.494C124.346 530.371 124.076 530.337 123.778 530.373C123.489 530.406 123.196 530.53 122.893 530.738L122.884 530.743Z"
              fill="white"
            />
          </g>
        )}
        <g filter="url(#J)" onClick={() => onSelectPhase(ProcessSchemePhase.MEDIA_ACTIVITY_PLAN)}>
          <circle
            cx={78.575}
            cy={523}
            r={selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN ? 'url(#paint24_linear_295_35042)' : '#fff'}
            transform="rotate(-120 78.575 523)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End MEDIA_ACTIVITY_PLAN Phase */}
        {/* Start NEXUS Phase */}
        {selectedPhase === ProcessSchemePhase.NEXUS && (
          <g
            transform="translate(3, -18)"
            // ref={nexusPhaseWrapperRef}
          >
            <g filter="url(#filter0_d_195_775)">
              <mask id="path-1-inside-1_195_775" fill="white">
                <path d="M75.6795 204.752C37.4491 270.97 23.1336 348.314 35.1309 423.828L254.744 388.937C250.747 363.774 255.517 338 268.256 315.935L75.6795 204.752Z" />
              </mask>
              <path
                d="M75.6795 204.752C37.4491 270.97 23.1336 348.314 35.1309 423.828L254.744 388.937C250.747 363.774 255.517 338 268.256 315.935L75.6795 204.752Z"
                fill="url(#paint0_linear_195_775)"
              />
              <path
                d="M75.6795 204.752C37.4491 270.97 23.1336 348.314 35.1309 423.828L254.744 388.937C250.747 363.774 255.517 338 268.256 315.935L75.6795 204.752Z"
                stroke="url(#paint1_linear_195_775)"
                strokeWidth="2"
                mask="url(#path-1-inside-1_195_775)"
              />
            </g>
            <path
              d="M75.6795 204.752C37.4491 270.97 23.1336 348.314 35.1309 423.828L101.005 413.362C91.4069 352.951 102.859 291.076 133.444 238.101L75.6795 204.752Z"
              fill="url(#paint2_linear_195_775)"
            />
            <path
              d="M75.5406 332.467C76.6224 332.589 77.5281 332.89 78.258 333.368C78.986 333.857 79.5098 334.459 79.8357 335.195C80.1633 335.922 80.2728 336.729 80.1678 337.599C80.0627 338.468 79.7665 339.224 79.2692 339.865C78.772 340.505 78.1123 340.987 77.2922 341.3C76.472 341.614 75.5202 341.714 74.4385 341.592C73.3567 341.471 72.4411 341.168 71.7211 340.691C70.9931 340.203 70.4693 339.601 70.1434 338.865C69.8157 338.138 69.7063 337.33 69.8113 336.461C69.9164 335.591 70.2126 334.836 70.7099 334.195C71.2071 333.555 71.8569 333.072 72.6869 332.76C73.507 332.446 74.4589 332.345 75.5504 332.469L75.5406 332.467ZM75.3176 334.274C74.5534 334.188 73.8945 334.241 73.3349 334.411C72.7752 334.582 72.3461 334.865 72.0181 335.256C71.7017 335.639 71.5018 336.102 71.4397 336.64C71.3777 337.178 71.456 337.671 71.6747 338.119C71.8933 338.567 72.2598 338.931 72.7524 339.218C73.2451 339.505 73.8874 339.699 74.6517 339.784C75.4159 339.87 76.0748 339.817 76.6344 339.647C77.1861 339.465 77.6232 339.193 77.9512 338.803C78.2792 338.412 78.4675 337.956 78.5295 337.418C78.5916 336.88 78.5133 336.387 78.2946 335.94C78.0759 335.492 77.7095 335.127 77.2168 334.84C76.7242 334.553 76.0819 334.36 75.3176 334.274Z"
              fill="white"
            />
            <path
              d="M81.878 325.609C81.7577 326.346 81.489 326.957 81.0641 327.431C80.6491 327.906 80.1286 328.243 79.4929 328.44C78.8671 328.638 78.1708 328.674 77.412 328.56C76.6532 328.445 76.0005 328.2 75.4558 327.814C74.9189 327.439 74.5238 326.96 74.2898 326.379C74.046 325.797 73.9874 325.148 74.104 324.43C74.2084 323.83 74.4077 323.316 74.7039 322.88C75.008 322.455 75.3839 322.132 75.8314 321.912C76.2868 321.704 76.7809 321.612 77.3294 321.659L77.0519 323.326C76.6743 323.339 76.3488 323.451 76.0638 323.67C75.7787 323.889 75.5966 324.206 75.5272 324.623C75.459 324.98 75.5178 325.31 75.6642 325.605C75.8106 325.9 76.0563 326.154 76.3915 326.363C76.7267 326.572 77.1472 326.706 77.6589 326.787C78.1707 326.868 78.6187 326.858 79.011 326.767C79.4033 326.677 79.7184 326.514 79.9563 326.276C80.1942 326.039 80.353 325.738 80.4114 325.379C80.4611 325.118 80.4446 324.886 80.3754 324.664C80.3062 324.442 80.1904 324.252 80.0299 324.084C79.8596 323.914 79.6506 323.787 79.3852 323.69L79.6627 322.023C80.1868 322.146 80.6298 322.374 81.0036 322.7C81.3658 323.033 81.6329 323.44 81.7817 323.936C81.9403 324.433 81.9665 324.986 81.8584 325.606L81.878 325.609Z"
              fill="white"
            />
            <path
              d="M76.2666 313.297L77.6277 313.559L76.7495 317.794L75.3883 317.532L76.2666 313.297ZM73.8158 316.143L74.1772 314.407L81.1952 315.773C81.4309 315.814 81.623 315.818 81.7635 315.773C81.9041 315.728 82.0109 315.647 82.0821 315.54C82.1533 315.432 82.1988 315.3 82.2363 315.157C82.2565 315.051 82.2768 314.944 82.2738 314.854C82.2707 314.763 82.2757 314.684 82.2751 314.634L83.7112 314.609C83.7222 314.711 83.7375 314.843 83.7338 315.023C83.7398 315.204 83.7208 315.411 83.6728 315.662C83.5898 316.098 83.4455 316.482 83.2239 316.793C83.0004 317.113 82.7131 317.342 82.3602 317.49C82.0072 317.637 81.5979 317.655 81.1284 317.561L73.8158 316.143Z"
              fill="white"
            />
            <path
              d="M84.5898 310.687L77.1549 309.078L77.5443 307.409L78.7815 307.678L78.7963 307.601C78.4037 307.371 78.1259 307.072 77.9647 306.694C77.8035 306.316 77.7772 305.921 77.8681 305.498C77.8866 305.401 77.9167 305.296 77.9486 305.182C77.9805 305.068 78.0204 304.965 78.0548 304.891L79.6631 305.237C79.6189 305.309 79.5692 305.41 79.5084 305.569C79.4495 305.718 79.4102 305.871 79.3727 306.014C79.3021 306.332 79.3075 306.622 79.3854 306.906C79.4632 307.19 79.611 307.426 79.817 307.622C80.0231 307.818 80.2929 307.946 80.6033 308.021L84.9786 308.967L84.5782 310.695L84.5898 310.687Z"
              fill="white"
            />
            <path
              d="M87.1147 300.881C86.9968 301.339 86.8 301.733 86.5357 302.055C86.2714 302.378 85.9434 302.609 85.5535 302.739C85.1636 302.87 84.737 302.874 84.254 302.749C83.8378 302.645 83.5149 302.478 83.2814 302.267C83.0497 302.046 82.8862 301.787 82.7925 301.481C82.6988 301.175 82.6596 300.849 82.6554 300.499C82.6611 300.151 82.6881 299.796 82.7483 299.428C82.8214 298.991 82.8681 298.64 82.9019 298.357C82.9338 298.083 82.9333 297.873 82.8886 297.736C82.8438 297.598 82.7409 297.5 82.5662 297.459L82.5368 297.454C82.1795 297.36 81.8687 297.395 81.6142 297.56C81.3598 297.724 81.1752 298.001 81.0703 298.391C80.9599 298.811 80.964 299.161 81.081 299.451C81.198 299.741 81.3788 299.963 81.6037 300.113L80.9449 301.653C80.5349 301.41 80.2065 301.113 79.9828 300.744C79.7592 300.375 79.6251 299.962 79.5903 299.506C79.5555 299.051 79.5967 298.569 79.7335 298.064C79.8311 297.712 79.966 297.377 80.1402 297.049C80.3144 296.72 80.54 296.441 80.8092 296.199C81.0784 295.958 81.3935 295.794 81.7662 295.7C82.1389 295.607 82.5716 295.624 83.0546 295.749L87.988 296.993L87.5517 298.643L86.5367 298.383L86.5257 298.441C86.6997 298.591 86.8548 298.788 86.9828 299.021C87.1108 299.253 87.1905 299.527 87.2235 299.832C87.2565 300.137 87.2214 300.48 87.1183 300.861L87.1147 300.881ZM85.9527 300.116C86.0484 299.773 86.0583 299.456 85.9806 299.172C85.9126 298.891 85.7766 298.647 85.5805 298.453C85.3844 298.258 85.1558 298.128 84.8849 298.06L84.0133 297.845C84.0415 297.91 84.0623 298.014 84.0678 298.144C84.0714 298.285 84.0634 298.433 84.0517 298.601C84.04 298.768 84.0204 298.925 83.9989 299.09C83.9792 299.247 83.9614 299.393 83.9491 299.511C83.919 299.775 83.914 300.014 83.9421 300.238C83.9703 300.463 84.037 300.644 84.1485 300.804C84.2601 300.963 84.4237 301.062 84.6474 301.112C84.9673 301.188 85.2408 301.137 85.4596 300.946C85.6883 300.757 85.8416 300.485 85.9392 300.133L85.9527 300.116Z"
              fill="white"
            />
            <path
              d="M135.091 352.236L134.978 354.052C134.667 354.087 134.398 354.169 134.151 354.295C133.904 354.421 133.7 354.584 133.521 354.782C133.341 354.98 133.205 355.215 133.104 355.477C133.004 355.738 132.95 356.018 132.923 356.323C132.89 356.866 132.999 357.354 133.251 357.788C133.503 358.222 133.883 358.569 134.401 358.83C134.919 359.092 135.559 359.245 136.322 359.281C137.096 359.318 137.76 359.236 138.298 359.022C138.845 358.81 139.258 358.504 139.559 358.098C139.85 357.691 140.02 357.222 140.051 356.689C140.067 356.392 140.04 356.108 139.98 355.838C139.918 355.577 139.813 355.329 139.662 355.113C139.521 354.898 139.334 354.705 139.109 354.556C138.886 354.396 138.633 354.291 138.332 354.218L138.447 352.392C138.928 352.478 139.37 352.656 139.78 352.898C140.19 353.141 140.545 353.463 140.848 353.836C141.151 354.219 141.371 354.657 141.52 355.153C141.669 355.648 141.726 356.197 141.694 356.79C141.638 357.669 141.379 358.441 140.937 359.111C140.485 359.78 139.851 360.286 139.053 360.643C138.255 361.001 137.304 361.151 136.219 361.099C135.124 361.044 134.207 360.802 133.45 360.358C132.694 359.915 132.133 359.346 131.762 358.632C131.39 357.927 131.228 357.13 131.282 356.261C131.316 355.708 131.424 355.199 131.616 354.724C131.806 354.258 132.057 353.844 132.39 353.484C132.722 353.124 133.112 352.833 133.569 352.615C134.026 352.397 134.538 352.268 135.105 352.219L135.091 352.236Z"
              fill="white"
            />
            <path
              d="M142.707 345.204C142.62 345.926 142.381 346.543 142.001 347.044C141.612 347.544 141.108 347.903 140.496 348.134C139.874 348.363 139.181 348.44 138.397 348.351C137.611 348.271 136.945 348.043 136.393 347.696C135.843 347.339 135.434 346.877 135.167 346.311C134.9 345.745 134.822 345.092 134.899 344.368C134.977 343.643 135.226 343.029 135.605 342.527C135.995 342.027 136.499 341.668 137.12 341.439C137.742 341.21 138.446 341.135 139.229 341.224C140.015 341.304 140.671 341.53 141.223 341.877C141.774 342.234 142.182 342.696 142.439 343.26C142.697 343.825 142.785 344.479 142.707 345.204ZM141.279 345.04C141.323 344.649 141.256 344.308 141.059 344.013C140.862 343.719 140.591 343.491 140.237 343.329C139.875 343.155 139.468 343.053 139.012 343.002C138.546 342.949 138.125 342.974 137.733 343.064C137.341 343.154 137.016 343.316 136.766 343.561C136.507 343.805 136.366 344.119 136.31 344.518C136.264 344.919 136.339 345.272 136.526 345.564C136.713 345.857 136.994 346.086 137.356 346.26C137.718 346.434 138.125 346.536 138.591 346.589C139.048 346.64 139.478 346.617 139.87 346.526C140.262 346.436 140.577 346.273 140.837 346.029C141.086 345.784 141.239 345.462 141.285 345.061L141.279 345.04Z"
              fill="white"
            />
            <path
              d="M146.36 338.049L136.023 336.405L136.319 334.69L137.559 334.89L137.578 334.793C137.408 334.673 137.236 334.513 137.059 334.322C136.884 334.121 136.739 333.875 136.645 333.569C136.541 333.261 136.529 332.899 136.602 332.463C136.701 331.891 136.938 331.394 137.311 330.981C137.684 330.567 138.188 330.267 138.81 330.088C139.433 329.909 140.157 329.887 140.995 330.016C141.823 330.143 142.495 330.392 143.036 330.748C143.567 331.101 143.95 331.539 144.168 332.047C144.385 332.555 144.455 333.097 144.354 333.678C144.273 334.103 144.148 334.44 143.957 334.696C143.766 334.952 143.555 335.154 143.317 335.282C143.088 335.421 142.866 335.522 142.671 335.587L142.658 335.654L146.665 336.295L146.362 338.039L146.36 338.049ZM140.079 335.297C140.56 335.382 141.008 335.372 141.387 335.299C141.778 335.219 142.091 335.064 142.347 334.84C142.593 334.614 142.752 334.313 142.812 333.944C142.886 333.557 142.829 333.218 142.661 332.928C142.493 332.639 142.235 332.393 141.896 332.203C141.547 332.012 141.146 331.881 140.673 331.807C140.212 331.725 139.785 331.729 139.395 331.81C139.006 331.88 138.692 332.035 138.446 332.261C138.2 332.487 138.04 332.798 137.976 333.186C137.906 333.553 137.953 333.891 138.111 334.179C138.269 334.466 138.507 334.708 138.846 334.898C139.185 335.088 139.596 335.221 140.079 335.297Z"
              fill="white"
            />
            <path
              d="M145.569 326.558L138.102 325.058L138.473 323.316L145.94 324.817L145.569 326.558ZM137.222 323.967C137.168 324.249 137.016 324.463 136.792 324.624C136.559 324.783 136.314 324.84 136.051 324.783C135.787 324.726 135.58 324.589 135.441 324.353C135.294 324.116 135.251 323.857 135.313 323.587C135.366 323.305 135.519 323.091 135.752 322.932C135.986 322.773 136.23 322.716 136.494 322.773C136.759 322.82 136.965 322.967 137.103 323.203C137.251 323.44 137.293 323.699 137.232 323.969L137.222 323.967Z"
              fill="white"
            />
            <path d="M137.45 316.443L147.346 318.736L146.925 320.468L137.029 318.175L137.45 316.443Z" fill="white" />
            <path
              d="M149.482 311.331C149.277 312.033 148.946 312.603 148.477 313.039C148.011 313.465 147.46 313.747 146.807 313.88C146.154 314.004 145.452 313.969 144.7 313.766C143.938 313.561 143.322 313.232 142.837 312.797C142.352 312.362 142.027 311.835 141.857 311.236C141.688 310.637 141.711 309.983 141.918 309.271C142.123 308.569 142.455 308 142.923 307.564C143.389 307.138 143.95 306.858 144.594 306.723C145.238 306.587 145.949 306.634 146.711 306.838C147.472 307.043 148.089 307.372 148.573 307.807C149.058 308.243 149.383 308.769 149.543 309.366C149.713 309.965 149.689 310.62 149.482 311.331ZM148.106 310.947C148.219 310.568 148.205 310.217 148.062 309.902C147.919 309.587 147.687 309.317 147.366 309.09C147.044 308.863 146.651 308.694 146.209 308.565C145.755 308.445 145.34 308.391 144.939 308.42C144.538 308.449 144.193 308.557 143.902 308.755C143.611 308.953 143.413 309.247 143.3 309.626C143.185 310.015 143.197 310.376 143.34 310.691C143.483 311.006 143.715 311.276 144.046 311.505C144.378 311.733 144.761 311.901 145.213 312.031C145.657 312.15 146.082 312.205 146.483 312.176C146.884 312.148 147.229 312.039 147.522 311.832C147.813 311.634 148.011 311.34 148.127 310.941L148.106 310.947Z"
              fill="white"
            />
            <path
              d="M145.574 298.029L146.894 298.454L145.503 302.558L144.183 302.134L145.574 298.029ZM142.788 300.556L143.362 298.877L150.152 301.063C150.383 301.134 150.571 301.157 150.708 301.131C150.845 301.106 150.969 301.038 151.05 300.932C151.131 300.826 151.195 300.708 151.24 300.576C151.28 300.473 151.3 300.366 151.317 300.279C151.334 300.192 151.348 300.115 151.348 300.065L152.782 300.209C152.783 300.309 152.777 300.448 152.754 300.624C152.74 300.802 152.68 301.011 152.614 301.25C152.482 301.676 152.292 302.033 152.033 302.327C151.774 302.621 151.463 302.816 151.1 302.911C150.736 303.007 150.324 302.984 149.876 302.834L142.798 300.557L142.788 300.556Z"
              fill="white"
            />
          </g>
        )}
        <g filter="url(#K)" onClick={() => onSelectPhase(ProcessSchemePhase.NEXUS)}>
          <circle
            cx={38.901}
            cy={298.001}
            r={selectedPhase === ProcessSchemePhase.NEXUS ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.NEXUS ? 'url(#paint24_linear_317_37283)' : '#fff'}
            transform="rotate(-80 38.901 298.001)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End NEXUS Phase */}
        {/* Start ADVERITY Phase */}
        {selectedPhase === ProcessSchemePhase.ADVERITY && (
          <g
            transform="translate(32, 20)"
            // ref={adverityPhaseWrapperRef}
          >
            <g filter="url(#filter0_d_195_931)">
              <path
                d="M227.701 17.2267C150.364 43.0974 85.1855 96.4082 44.491 167.079L236.801 277.817C250.417 254.172 272.224 236.334 298.101 227.678L227.701 17.2267Z"
                fill="url(#paint0_linear_195_931)"
              />
            </g>
            <path
              d="M157.379 151.708L154.854 154.003L147.914 146.696L150.492 144.357C151.229 143.683 152.01 143.255 152.813 143.065C153.624 142.869 154.428 142.922 155.235 143.204C156.041 143.486 156.799 144.014 157.517 144.767C158.241 145.528 158.714 146.317 158.953 147.121C159.183 147.931 159.174 148.721 158.91 149.504C158.647 150.287 158.146 151.023 157.379 151.708ZM155.115 151.631L156.221 150.627C156.742 150.157 157.08 149.671 157.25 149.172C157.419 148.673 157.413 148.152 157.229 147.623C157.046 147.094 156.694 146.552 156.167 145.987C155.639 145.423 155.103 145.046 154.581 144.836C154.059 144.626 153.538 144.582 153.017 144.706C152.502 144.836 151.989 145.12 151.49 145.585L150.339 146.627L155.1 151.644L155.115 151.631Z"
              fill="white"
            />
            <path
              d="M164.281 145.533L157.823 137.816L160.772 135.452C161.372 134.968 161.979 134.672 162.569 134.568C163.167 134.458 163.729 134.519 164.265 134.73C164.801 134.941 165.268 135.299 165.681 135.791C166.094 136.283 166.355 136.811 166.465 137.362C166.575 137.912 166.508 138.468 166.284 139.026C166.054 139.575 165.642 140.096 165.027 140.579L163.074 142.144L162.105 140.995L163.868 139.588C164.218 139.298 164.455 139.012 164.586 138.711C164.71 138.416 164.736 138.113 164.669 137.809C164.602 137.505 164.454 137.229 164.223 136.946C163.99 136.677 163.737 136.476 163.449 136.355C163.161 136.234 162.859 136.202 162.541 136.272C162.223 136.343 161.875 136.515 161.518 136.798L160.211 137.841L165.696 144.388L164.275 145.526L164.281 145.533Z"
              fill="white"
            />
            <path
              d="M173.359 138.249L167.15 130.338L170.169 128.07C170.789 127.608 171.395 127.326 171.978 127.215C172.569 127.111 173.117 127.171 173.631 127.387C174.145 127.603 174.604 127.967 175.002 128.472C175.4 128.977 175.633 129.503 175.708 130.043C175.778 130.576 175.684 131.103 175.42 131.629C175.157 132.155 174.712 132.638 174.085 133.106L171.928 134.724L170.991 133.536L172.951 132.069C173.314 131.794 173.57 131.53 173.721 131.252C173.878 130.98 173.929 130.707 173.896 130.427C173.855 130.153 173.729 129.872 173.512 129.59C173.294 129.308 173.048 129.114 172.774 128.994C172.507 128.882 172.211 128.857 171.9 128.922C171.59 128.986 171.249 129.166 170.879 129.434L169.54 130.439L174.805 137.155L173.345 138.248L173.359 138.249ZM174.718 131.543L179.531 133.629L177.897 134.853L173.122 132.735L174.718 131.543Z"
              fill="white"
            />
          </g>
        )}
        <g filter="url(#L)" onClick={() => onSelectPhase(ProcessSchemePhase.ADVERITY)}>
          <circle
            cx={153.136}
            cy={100.141}
            r={selectedPhase === ProcessSchemePhase.ADVERITY ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.ADVERITY ? 'url(#paint38_linear_317_37283)' : '#fff'}
            transform="rotate(-40 153.136 100.141)"
            className={styles.phaseCircleTrigger}
          />
        </g>
        {/* End ADVERITY Phase */}
        {/* Small circle (when necessary phase) */}
        {selectedPhase === ProcessSchemePhase.WHEN_NECESSARY && (
          <g transform="translate(0, -8)">
            <path
              d="M480.32 48.3888C408.819 22.1688 330.497 21.2105 258.375 45.6732L329.657 255.828C353.788 247.643 379.994 247.963 403.918 256.736L480.32 48.3888Z"
              fill="url(#paint0_linear_195_763)"
            />
            <path d="M332.83 112.591L334.16 122.431L332.33 122.666L331 112.816L332.83 112.582V112.591Z" fill="white" />
            <path
              d="M338.76 121.856L338.1 114.435L339.81 114.288L339.92 115.546H340.01C340.13 115.098 340.36 114.747 340.71 114.474C341.06 114.201 341.48 114.045 341.99 114.006C342.51 113.957 342.95 114.045 343.33 114.259C343.71 114.474 343.99 114.786 344.17 115.186H344.25C344.38 114.757 344.64 114.396 345.02 114.123C345.4 113.84 345.86 113.674 346.42 113.635C347.12 113.576 347.71 113.742 348.19 114.132C348.67 114.522 348.95 115.137 349.02 115.966L349.47 120.949L347.67 121.105L347.25 116.395C347.21 115.937 347.05 115.605 346.78 115.42C346.51 115.225 346.19 115.147 345.83 115.176C345.4 115.215 345.07 115.371 344.85 115.654C344.63 115.937 344.54 116.288 344.58 116.707L344.99 121.33L343.23 121.476L342.8 116.697C342.77 116.317 342.62 116.015 342.36 115.81C342.1 115.605 341.79 115.517 341.41 115.546C341.15 115.566 340.92 115.654 340.72 115.8C340.52 115.946 340.37 116.141 340.26 116.375C340.15 116.609 340.12 116.892 340.14 117.195L340.54 121.71L338.75 121.866L338.76 121.856Z"
              fill="white"
            />
            <path
              d="M356.151 120.803C355.671 120.822 355.231 120.754 354.841 120.598C354.451 120.442 354.131 120.198 353.901 119.876C353.671 119.555 353.541 119.145 353.521 118.657C353.501 118.238 353.571 117.887 353.721 117.604C353.871 117.321 354.081 117.097 354.351 116.921C354.621 116.746 354.921 116.609 355.271 116.512C355.621 116.414 355.971 116.336 356.341 116.288C356.791 116.229 357.151 116.17 357.421 116.122C357.701 116.073 357.901 116.014 358.021 115.927C358.141 115.839 358.201 115.722 358.201 115.546V115.517C358.191 115.156 358.061 114.873 357.831 114.678C357.601 114.483 357.281 114.396 356.861 114.415C356.421 114.435 356.071 114.532 355.821 114.737C355.571 114.932 355.411 115.156 355.331 115.42L353.641 115.244C353.761 114.786 353.961 114.405 354.251 114.083C354.541 113.771 354.911 113.528 355.341 113.352C355.771 113.186 356.261 113.089 356.791 113.069C357.161 113.059 357.531 113.089 357.891 113.157C358.261 113.225 358.601 113.352 358.901 113.537C359.211 113.723 359.461 113.966 359.661 114.298C359.861 114.62 359.961 115.029 359.981 115.527L360.161 120.51L358.431 120.569L358.391 119.545H358.331C358.231 119.759 358.081 119.954 357.891 120.14C357.701 120.325 357.461 120.481 357.171 120.598C356.881 120.715 356.531 120.783 356.141 120.793L356.151 120.803ZM356.571 119.496C356.931 119.486 357.241 119.399 357.501 119.252C357.761 119.106 357.961 118.911 358.091 118.667C358.231 118.433 358.291 118.17 358.281 117.897L358.251 117.019C358.201 117.068 358.101 117.107 357.971 117.155C357.841 117.204 357.691 117.233 357.521 117.272C357.351 117.312 357.191 117.341 357.031 117.37C356.871 117.399 356.731 117.419 356.611 117.438C356.341 117.487 356.101 117.546 355.901 117.633C355.691 117.721 355.531 117.838 355.411 117.984C355.291 118.131 355.241 118.316 355.251 118.55C355.261 118.872 355.391 119.116 355.641 119.272C355.891 119.428 356.201 119.496 356.571 119.486V119.496Z"
              fill="white"
            />
            <path
              d="M367.95 123.416C367.3 123.407 366.75 123.309 366.29 123.134C365.83 122.958 365.46 122.724 365.18 122.431C364.9 122.139 364.71 121.827 364.61 121.476L366.24 121.115C366.31 121.261 366.41 121.407 366.55 121.554C366.69 121.7 366.87 121.827 367.11 121.924C367.35 122.022 367.64 122.08 368 122.08C368.51 122.08 368.93 121.973 369.27 121.739C369.61 121.505 369.78 121.115 369.79 120.559L369.81 119.145H369.72C369.62 119.32 369.49 119.506 369.31 119.691C369.13 119.876 368.89 120.032 368.6 120.159C368.31 120.286 367.94 120.344 367.51 120.335C366.92 120.335 366.39 120.179 365.92 119.906C365.45 119.632 365.08 119.213 364.81 118.677C364.54 118.131 364.41 117.458 364.42 116.648C364.43 115.829 364.59 115.146 364.87 114.581C365.15 114.015 365.55 113.596 366.03 113.313C366.51 113.03 367.05 112.894 367.63 112.903C368.08 112.903 368.44 112.991 368.73 113.137C369.02 113.293 369.25 113.469 369.41 113.674C369.58 113.879 369.71 114.074 369.79 114.259H369.89L369.91 113.03L371.68 113.059L371.55 120.627C371.54 121.261 371.37 121.788 371.06 122.197C370.74 122.607 370.31 122.919 369.77 123.114C369.23 123.309 368.61 123.407 367.93 123.397L367.95 123.416ZM368.04 118.989C368.42 118.989 368.75 118.911 369.02 118.735C369.29 118.56 369.5 118.306 369.64 117.965C369.78 117.623 369.86 117.224 369.87 116.746C369.87 116.278 369.82 115.868 369.68 115.507C369.55 115.156 369.35 114.873 369.09 114.678C368.83 114.473 368.5 114.376 368.11 114.366C367.71 114.366 367.37 114.454 367.1 114.659C366.83 114.854 366.62 115.137 366.48 115.488C366.34 115.839 366.26 116.239 366.26 116.687C366.26 117.146 366.31 117.545 366.45 117.887C366.58 118.228 366.78 118.501 367.05 118.696C367.32 118.891 367.65 118.989 368.04 118.999V118.989Z"
              fill="white"
            />
            <path
              d="M376.15 120.647L376.48 113.206L378.28 113.284L377.95 120.725L376.15 120.647ZM377.42 112.192C377.14 112.182 376.9 112.074 376.7 111.879C376.5 111.684 376.41 111.45 376.42 111.197C376.43 110.933 376.54 110.719 376.76 110.543C376.98 110.368 377.22 110.28 377.51 110.3C377.79 110.309 378.03 110.417 378.23 110.612C378.43 110.807 378.52 111.041 378.51 111.294C378.5 111.558 378.39 111.772 378.18 111.948C377.97 112.123 377.72 112.211 377.43 112.192H377.42Z"
              fill="white"
            />
            <path
              d="M384.65 116.736L384.3 121.086L382.51 120.949L383.1 113.528L384.81 113.655L384.71 114.913H384.8C385.01 114.513 385.31 114.211 385.72 113.996C386.12 113.781 386.61 113.694 387.18 113.733C387.71 113.772 388.16 113.918 388.53 114.162C388.9 114.415 389.18 114.747 389.37 115.186C389.55 115.615 389.62 116.132 389.57 116.727L389.19 121.457L387.4 121.32L387.76 116.863C387.8 116.366 387.7 115.966 387.46 115.664C387.22 115.361 386.87 115.195 386.41 115.156C386.1 115.137 385.81 115.176 385.56 115.293C385.31 115.41 385.1 115.586 384.94 115.829C384.78 116.073 384.69 116.366 384.66 116.727L384.65 116.736Z"
              fill="white"
            />
            <path
              d="M397.04 122.451C396.28 122.363 395.64 122.129 395.13 121.749C394.62 121.378 394.25 120.881 394.03 120.276C393.81 119.671 393.74 118.979 393.84 118.209C393.94 117.448 394.17 116.794 394.54 116.258C394.91 115.722 395.38 115.322 395.96 115.059C396.54 114.795 397.19 114.717 397.91 114.805C398.38 114.864 398.81 114.99 399.21 115.185C399.61 115.38 399.95 115.653 400.23 115.995C400.51 116.336 400.72 116.755 400.84 117.243C400.97 117.731 400.99 118.306 400.91 118.95L400.84 119.486L394.63 118.745L394.78 117.575L399.28 118.111C399.32 117.779 399.28 117.467 399.17 117.194C399.06 116.912 398.88 116.687 398.64 116.502C398.4 116.317 398.11 116.209 397.77 116.17C397.4 116.131 397.07 116.17 396.78 116.317C396.48 116.453 396.24 116.658 396.05 116.912C395.86 117.175 395.75 117.467 395.7 117.799L395.57 118.823C395.52 119.252 395.55 119.632 395.67 119.954C395.79 120.276 395.99 120.539 396.26 120.734C396.53 120.93 396.86 121.056 397.25 121.095C397.51 121.125 397.75 121.115 397.97 121.076C398.19 121.027 398.39 120.949 398.56 120.822C398.73 120.695 398.87 120.539 398.98 120.335L400.62 120.715C400.46 121.134 400.21 121.485 399.88 121.768C399.55 122.051 399.14 122.256 398.66 122.373C398.18 122.49 397.65 122.519 397.06 122.451H397.04Z"
              fill="white"
            />
          </g>
        )}
        <g
          transform="translate(38, -7)"
          filter="url(#filter0_d_196_1302)"
          onClick={() => onSelectPhase(ProcessSchemePhase.WHEN_NECESSARY)}
        >
          <circle
            cx="329"
            cy="28.7963"
            r={selectedPhase === ProcessSchemePhase.WHEN_NECESSARY ? 18 : 12}
            fill={selectedPhase === ProcessSchemePhase.WHEN_NECESSARY ? 'url(#paint1_linear_196_1302)' : '#fff'}
            className={styles.phaseCircleTrigger}
          />
        </g>
        <ProcessSchemeSvgDefs />
      </svg>
    </div>
  )
}
