// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.A93jo{position:relative;height:calc(100vh - var(--wpp-os-header-height));background:radial-gradient(circle, #13291f 0, #121619 100%)}.X4G5d{width:100%;max-height:100%}.Q1h8r{position:absolute;top:50%;left:50%;width:310px;height:310px;transform:translate(-50%, -53%);pointer-events:none}.dykdS{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/spotify/SpotifyProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,gDAAA,CACA,2DAAA,CAGF,OACE,UAAA,CACA,eAAA,CAGF,OACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,YAAA,CACA,+BAAA,CACA,mBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  position: relative;\n  height: calc(100vh - var(--wpp-os-header-height));\n  background: radial-gradient(circle, #13291f 0, #121619 100%);\n}\n\n.svg {\n  width: 100%;\n  max-height: 100%;\n}\n\n.sphereGif {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 310px;\n  height: 310px;\n  transform: translate(-50%, -53%);\n  pointer-events: none;\n}\n\n.svgPoint {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `A93jo`,
	"svg": `X4G5d`,
	"sphereGif": `Q1h8r`,
	"svgPoint": `dykdS`
};
export default ___CSS_LOADER_EXPORT___;
