import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as Tooltip1 } from 'pages/customProcess/components/ibmNew/assets/schemeTooltips/reconciliation-tooltip1.svg'
import { ReactComponent as Tooltip2 } from 'pages/customProcess/components/ibmNew/assets/schemeTooltips/reconciliation-tooltip2.svg'
import { InputOutputInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/inputOutputInfo/InputOutputInfo'
import { ResponsibilitiesInfo } from 'pages/customProcess/components/ibmNew/processPhases/components/responsibilitiesInfo/ResponsibilitiesInfo'
import { PageLayout } from 'pages/customProcess/components/ibmNew/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/ibmNew/processPhases/pages/reconciliation/Reconciliation.module.scss'
import { Scheme } from 'pages/customProcess/components/ibmNew/processPhases/pages/reconciliation/scheme/Scheme'
import { Phases } from 'pages/customProcess/components/ibmNew/types'

const Reconciliation = () => {
  const [showTooltip, setShowTooltip] = useState<MayBeNull<number>>(null)

  const handleTooltipTriggerEnter = useCallback(
    (index: number) => () => {
      setShowTooltip(index)
    },
    [],
  )

  const handleTooltipTriggerLeave = useCallback(() => {
    setShowTooltip(null)
  }, [])

  return (
    <PageLayout
      type={Phases.Reconciliation}
      title="05. Reconciliation"
      description="Reconcile planned vs actual"
      prevLink="/custom/ibm/performance"
    >
      <Scheme onTooltipTriggerEnter={handleTooltipTriggerEnter} onTooltipTriggerLeave={handleTooltipTriggerLeave} />
      <Tooltip1 className={clsx(styles.tooltip, showTooltip === 1 && styles.tooltipVisible)} />
      <Tooltip2 className={clsx(styles.tooltip, showTooltip === 2 && styles.tooltipVisible)} />
      <Flex gap={16} className={styles.infoWrapper}>
        <ResponsibilitiesInfo width="38.82%" />
        <InputOutputInfo width="61.18%" />
      </Flex>
    </PageLayout>
  )
}

export default Reconciliation
