import { useMemo } from 'react'

import { useGenerateProcessUploadUrlsApi } from 'api/attachments/mutation/useGenerateProcessUploadUrlsApi'
import { useUploadProcessFileApi } from 'api/attachments/mutation/useUploadProcessFileApi'
import { createUploadCallback } from 'hooks/attachments/createUploadCallback'

export const useUploadProcessFilesApi = () => {
  const { mutateAsync: handleGenerateProcessUploadUrls } = useGenerateProcessUploadUrlsApi()
  const { mutateAsync: handleUploadProcessFile } = useUploadProcessFileApi()

  return useMemo(
    () =>
      createUploadCallback({
        generateUploadUrls: handleGenerateProcessUploadUrls,
        uploadFile: handleUploadProcessFile,
      }),
    [handleGenerateProcessUploadUrls, handleUploadProcessFile],
  )
}
