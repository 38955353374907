import { ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/cocaCola/components/phaseDetails/PhaseDetails.module.scss'

interface Props {
  title: string
  children: ReactNode
}

export const PhaseDetails = ({ title, children }: Props) => {
  return (
    <>
      <h3 className={styles.discActiveTitle}>{title}</h3>
      <Flex direction="column" gap={2}>
        {children}
      </Flex>
    </>
  )
}
