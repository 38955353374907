import schemeImg from 'pages/customProcess/components/fossilNew/assets/Scheme (7).png'
import { ProcessCard } from 'pages/customProcess/components/fossilNew/processPhases/processCard/ProcessCard'

const Automation = () => {
  return (
    <ProcessCard
      type="activation"
      title="Automation Opportunity"
      description="Consists of key learnings from platforms across addressable and non-addressable channels"
      previousLink="/custom/fossil/outcome"
      nextLink="/custom/fossil/attribution"
    >
      <img src={schemeImg} alt="" />
    </ProcessCard>
  )
}

export default Automation
