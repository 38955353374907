import { Flex } from 'components/common/flex/Flex'
import { InputCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputCard/InputCard'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleAsia/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleAsia/processPhases/pages/integratedCommunicationsBrief/IntegratedCommunicationsBrief.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleAsia/processPhases/processCard/ProcessCard'

const IntegratedCommunicationsBrief = () => {
  const previousLink = '/custom/prerequisites-to-bep-3'
  const nextLink = '/custom/campaign-discovery'

  return (
    <ProcessCard
      title="Integrated Communications Brief"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="Media principles"
      showMroiFooter
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputCard
            title="Input from outcomes and adapt:"
            text="Campaign Analysis is carried out, insights are generated"
            width={233}
          />
          <svg width="6" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 48 2.887-5H.113L3 48Zm.5-4.5V0h-1v43.5h1Z" fill="#307DE4" />
          </svg>
          <InputsOutputsCard
            title="inputs"
            text="Portfolio Country/Cluster allocation and communication Strategy agreement"
            cardStyles={styles.inputsCardAsia}
          />
        </Flex>
        <div className={styles.regularCard1Wrapper}>
          <RegularCard
            text="Receive and work on Campaign brief to help inform the campaign strategy"
            teamTags={[
              { text: 'Media Teams', tags: ['C'] },
              { text: 'Nestle Teams', tags: ['R', 'A'] },
              { text: 'Creative Teams', tags: ['C'] },
            ]}
          />
        </div>
        <div className={styles.regularCard2Wrapper}>
          <RegularCard
            text="Define business KPIs and performance metrics for core elements of the brief to measure against the campaign objectives"
            teamTags={[
              { text: 'Media Teams', tags: ['C'] },
              { text: 'Nestle Teams', tags: ['R', 'A'] },
              { text: 'Creative Teams', tags: ['C'] },
            ]}
          />
        </div>
        <InputsOutputsCard
          title="outputs"
          text="A fully aligned Brief with KPIs, CEF, SCT."
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default IntegratedCommunicationsBrief
