// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UWQdj{opacity:1;transition:opacity .4s ease}.Eps7w{opacity:0}.nmNxU{opacity:0;transition:opacity .4s ease}.auUnH{cursor:pointer;opacity:1}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/lego/processSvg/ProcessSvg.module.scss"],"names":[],"mappings":"AAAA,OACE,SAAA,CACA,2BAAA,CAGF,OACE,SAAA,CAGF,OACE,SAAA,CACA,2BAAA,CAGF,OACE,cAAA,CACA,SAAA","sourcesContent":[".visible {\n  opacity: 1;\n  transition: opacity 0.4s ease;\n}\n\n.hidden {\n  opacity: 0;\n}\n\n.fullPhaseHidden {\n  opacity: 0;\n  transition: opacity 0.4s ease;\n}\n\n.fullPhase {\n  cursor: pointer;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visible": `UWQdj`,
	"hidden": `Eps7w`,
	"fullPhaseHidden": `nmNxU`,
	"fullPhase": `auUnH`
};
export default ___CSS_LOADER_EXPORT___;
