import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/procterAndGamble/ProcterAndGambleProcess.module.scss'
import { ProcterAndGambleSvg } from 'pages/customProcess/components/procterAndGamble/ProcterAndGambleSvg'
import { Phases } from 'pages/customProcess/components/procterAndGamble/types'

const phaseLinks: Record<Phases, string> = {
  [Phases.Audience]: '/custom/p&g/audience',
  [Phases.Activation]: '/custom/p&g/activation',
  [Phases.Attribution]: '/custom/p&g/attribution',
  [Phases.Ambition]: '/custom/p&g/ambition',
}

export const ProcterAndGambleProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const navigate = useNavigate()

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePointClick = useCallback(
    (phase: Phases) => () => {
      navigate(phaseLinks[phase])
    },
    [navigate],
  )

  return (
    <Flex align="center" justify="center" className={styles.root}>
      <ProcterAndGambleSvg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        onPointClick={handlePointClick}
        activePhase={activePhase}
        className={styles.svg}
        pointClassName={styles.svgPoint}
      />
    </Flex>
  )
}
