import { WppActionButton, WppIconChevron, WppButton } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/boots/processPhases/footerNavigation/FooterNavigation.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  previousLink?: string
  nextLink?: string
}

export const FooterNavigation = ({ previousLink, nextLink }: Props) => {
  return (
    <div className={styles.footerNavWrapper}>
      <Flex align="center" justify="between" className={styles.footerNav}>
        <Flex gap={16}>
          <Link to={previousLink ?? ''}>
            <WppActionButton className={styles.navButton} variant="secondary" disabled={!previousLink}>
              Previous phase <WppIconChevron slot="icon-start" direction="left" />
            </WppActionButton>
          </Link>
          <Link to={nextLink ?? ''}>
            <WppActionButton className={styles.navButton} variant="secondary" disabled={!nextLink}>
              Next phase <WppIconChevron slot="icon-end" />
            </WppActionButton>
          </Link>
        </Flex>
        <Link to={routesManager.systemUrls.orchestration.root.url}>
          <WppButton className={styles.projectButton}>Go to Projects</WppButton>
        </Link>
      </Flex>
    </div>
  )
}
