import { InputsOutputsCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleChina/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleChina/processPhases/pages/campaignBudgetSetting/CampaignBudgetSetting.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleChina/processPhases/processCard/ProcessCard'
import SynapseIcon from 'pages/customProcess/components/nestleChina/processScheme/assets/SynapseIcon.svg'

interface Props {
  isDemoTenant?: boolean
}

const CampaignBudgetSetting = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/campaign-strategy' : '/custom/ch/campaign-strategy'
  const nextLink = isDemoTenant ? '/nestle/process/strategic-media-planning' : '/custom/ch/strategic-media-planning'

  return (
    <ProcessCard
      title="Campaign Budget Setting"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard
          title="inputs"
          text="Strategic Confirmation and alignment"
          cardStyles={styles.inputsOutputsCard}
        />
        <RegularCard
          text="Allocate budget (top-down or bottom-up approach), aligning to the media principles framework with spend sufficiency of campaign and platform"
          teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
          brandTags={[{ text: 'Synapse®', style: styles.synapseTagColor, icon: SynapseIcon }]}
        />
        <InputsOutputsCard
          title="outputs"
          text="Campaign Budget allocation (top-down / bottom-up)"
          cardStyles={styles.inputsOutputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignBudgetSetting
