import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/bp/processPhases/contentCards/regularCard/RegularCard.module.scss'

interface TeamTag {
  text: string
  tags: string[]
}

interface BrandTag {
  logoImgUrl?: string
  text: string
}

interface Props {
  text: string
  teamTags?: TeamTag[]
  brandTags?: BrandTag[]
}

export const RegularCard = ({ text, teamTags, brandTags }: Props) => (
  <div>
    <div className={styles.card}>
      <WppTypography type="m-midi" className={styles.text}>
        {text}
      </WppTypography>
      {teamTags?.map((teamTag, index) => (
        <Flex gap={8} className={styles.teamInfo} key={index}>
          <WppTypography type="xs-midi">{teamTag.text}</WppTypography>
          <Flex gap={8}>
            {teamTag.tags.map((tag, index) => (
              <Flex className={styles.teamTag} justify="center" align="center" key={index}>
                <WppTypography type="2xs-strong" className={styles.teamTagText}>
                  {tag}
                </WppTypography>
              </Flex>
            ))}
          </Flex>
        </Flex>
      ))}
      {brandTags && brandTags.length > 0 && (
        <div style={{ marginTop: 18 }}>
          {brandTags.map((brandTag, index) => (
            <Flex gap={8} align="center" key={index} className={styles.brandTagOffset}>
              <WppTypography className={styles.brandTag}>{brandTag.text}</WppTypography>
              {brandTag.logoImgUrl && <img className={styles.brandLogoImg} alt="" src={brandTag.logoImgUrl} />}
            </Flex>
          ))}
        </div>
      )}
    </div>
  </div>
)
