import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/spotify/processPhases/pages/deliver/Deliver.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/spotify/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/spotify/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/spotify/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'deliver'

const Deliver = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Deliver"
      description="Closing the gap between strategy and execution with a media plan that delivers on client objectives and channel priorities"
      previousLink="/custom/design"
      nextLink="/custom/adapt"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Campaign Architecture
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Budget by Channel
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Reach & Frequency Projections
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Outcome Projections
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Implementation Teams (regional)',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Implementation Teams (local)',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Regional Client Lead',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Regional Client Team',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Addressable Delivery
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Platform Audience Translator
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                OOH Site Check
              </WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Choreograph Create
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Nexus Growth Platform
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Media Plan
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Asset Resize
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Asset Delivery
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Closed Platform site test
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Creative Adaptation signoff
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Deliver
