import { createApi } from 'api/common/createApi'
import { environment } from 'environment'

export const facadeApi = createApi({
  baseURL: '/api',
})

export const processApi = createApi({
  baseURL: `${environment.PROCESS_API_URL}api`,
})
