// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nfoXV{padding:16px 16px 40px}.CgUF9{background:linear-gradient(319.48deg, #be47e8 42.56%, #6d39da 69.5%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.mS080{margin-top:-90px}.hZUlG{margin-top:60px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestle/processPhases/pages/mediaPlan/MediaPlan.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,oEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,gBAAA,CAGF,OACE,eAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.redmillTagColor {\n  background: linear-gradient(319.48deg, #be47e8 42.56%, #6d39da 69.5%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard1Wrapper {\n  margin-top: -90px;\n}\n\n.regularCard2Wrapper {\n  margin-top: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `nfoXV`,
	"redmillTagColor": `CgUF9`,
	"regularCard1Wrapper": `mS080`,
	"regularCard2Wrapper": `hZUlG`
};
export default ___CSS_LOADER_EXPORT___;
