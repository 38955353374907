import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useCallback, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import logoImgSrc from 'pages/customProcess/components/fossilNew/assets/logo.png'
import processCenterImgSrc from 'pages/customProcess/components/fossilNew/assets/process-center.png'
import { ActivationPhaseSvg } from 'pages/customProcess/components/fossilNew/components/activation/ActivationPhaseSvg'
import { AttributionMeasurementSvg } from 'pages/customProcess/components/fossilNew/components/activation/AttributionMeasurementSvg'
import { AutomationOpportunitySvg } from 'pages/customProcess/components/fossilNew/components/activation/AutomationOpportunitySvg'
import { OutcomeMonitoringSvg } from 'pages/customProcess/components/fossilNew/components/activation/OutcomeMonitoringSvg'
import { ChannelsAndSignalsSvg } from 'pages/customProcess/components/fossilNew/components/planning/ChannelsAndSignalsSvg'
import { ExperienceAndInnovationSvg } from 'pages/customProcess/components/fossilNew/components/planning/ExperienceAndInnovationSvg'
import { PlanningPhaseSvg } from 'pages/customProcess/components/fossilNew/components/planning/PlanningPhaseSvg'
import { ReachAndEngagementSvg } from 'pages/customProcess/components/fossilNew/components/planning/ReachAndEngagementSvg'
import { AudiencePlanningSvg } from 'pages/customProcess/components/fossilNew/components/strategy/AudiencePlanningSvg'
import { DefineSuccessSvg } from 'pages/customProcess/components/fossilNew/components/strategy/DefineSuccessSvg'
import { StrategyPhaseSvg } from 'pages/customProcess/components/fossilNew/components/strategy/StrategyPhaseSvg'
import { UncoverChallengeSvg } from 'pages/customProcess/components/fossilNew/components/strategy/UncoverChallengeSvg'
import { phaseDescriptions } from 'pages/customProcess/components/fossilNew/fossilConfig'
import styles from 'pages/customProcess/components/fossilNew/FossilProcess.module.scss'
import { Phases, PhaseDetails } from 'pages/customProcess/components/fossilNew/types'

export const FossilProcess = () => {
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)
  const [activePhaseDetail, setActivePhaseDetail] = useState<MayBeNull<PhaseDetails>>(null)

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  const handlePhaseDetailEnter = useCallback(
    (phase: PhaseDetails) => () => {
      setActivePhaseDetail(phase)
    },
    [],
  )

  const handlePhaseDetailLeave = useCallback(() => {
    setActivePhaseDetail(null)
  }, [])

  return (
    <div className={styles.root}>
      <Flex align="center" justify="center" className={styles.pageContent}>
        <div
          className={clsx(
            styles.phaseDescriptionLeftContainer,
            activePhase === Phases.Strategy && !activePhaseDetail && styles.visible,
          )}
        >
          <div>
            <WppTypography tag="h1" className={styles.title}>
              {phaseDescriptions[Phases.Strategy].title}
            </WppTypography>
          </div>
          <div>
            <WppTypography type="m-midi" className={styles.description}>
              {phaseDescriptions[Phases.Strategy].description}
            </WppTypography>
          </div>
        </div>
        <div
          className={clsx(
            styles.phaseDescriptionLeftContainer,
            activePhase === Phases.Planning && !activePhaseDetail && styles.visible,
          )}
        >
          <div>
            <WppTypography tag="h1" className={styles.title}>
              {phaseDescriptions[Phases.Planning].title}
            </WppTypography>
          </div>
          <div>
            <WppTypography type="m-midi" className={styles.description}>
              {phaseDescriptions[Phases.Planning].description}
            </WppTypography>
          </div>
        </div>
        <div
          className={clsx(
            styles.phaseDescriptionRightContainer,
            activePhase === Phases.Activation && !activePhaseDetail && styles.visible,
          )}
        >
          <div>
            <WppTypography tag="h1" className={styles.title}>
              {phaseDescriptions[Phases.Activation].title}
            </WppTypography>
          </div>
          <div>
            <WppTypography type="m-midi" className={styles.description}>
              {phaseDescriptions[Phases.Activation].description}
            </WppTypography>
          </div>
        </div>
        <div className={styles.processContainer}>
          <div className={styles.schemeBg} />
          <div
            className={clsx(
              styles.strategy,
              activePhase === Phases.Strategy && !activePhaseDetail && styles.phaseActive,
            )}
          >
            <StrategyPhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseDetailEnter={handlePhaseDetailEnter}
              onPhaseDetailLeave={handlePhaseDetailLeave}
            />
          </div>
          <div
            className={clsx(
              styles.activation,
              activePhase === Phases.Activation && !activePhaseDetail && styles.phaseActive,
            )}
          >
            <ActivationPhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseDetailEnter={handlePhaseDetailEnter}
              onPhaseDetailLeave={handlePhaseDetailLeave}
            />
          </div>
          <div
            className={clsx(
              styles.planning,
              activePhase === Phases.Planning && !activePhaseDetail && styles.phaseActive,
            )}
          >
            <PlanningPhaseSvg
              activePhase={activePhase}
              onPhaseEnter={handlePhaseEnter}
              onPhaseDetailEnter={handlePhaseDetailEnter}
              onPhaseDetailLeave={handlePhaseDetailLeave}
            />
          </div>
          <div className={clsx(styles.schemeBlurOverly, !!activePhase && styles.schemeBlurOverlyActive)} />
          <div
            className={clsx(
              styles.processCenterImgContainer,
              !!activePhaseDetail && styles.processCenterImgContainerMinimized,
            )}
            onMouseEnter={handlePhaseLeave}
          >
            <img src={processCenterImgSrc} alt="" className={styles.processCenterImg} />
          </div>
          <UncoverChallengeSvg
            className={clsx(
              styles.strategyUncoverChallenge,
              activePhaseDetail === PhaseDetails.UncoverChallenge && styles.visible,
            )}
          />
          <DefineSuccessSvg
            className={clsx(
              styles.strategydefineSuccess,
              activePhaseDetail === PhaseDetails.DefineSuccess && styles.visible,
            )}
          />
          <AudiencePlanningSvg
            className={clsx(
              styles.strategyAudiencePlanning,
              activePhaseDetail === PhaseDetails.AudiencePlanning && styles.visible,
            )}
          />
          <OutcomeMonitoringSvg
            className={clsx(
              styles.activationOutcomeMonitoring,
              activePhaseDetail === PhaseDetails.OutcomeMonitoring && styles.visible,
            )}
          />
          <AutomationOpportunitySvg
            className={clsx(
              styles.activationAutomationOpportunity,
              activePhaseDetail === PhaseDetails.AutomationOpportunity && styles.visible,
            )}
          />
          <AttributionMeasurementSvg
            className={clsx(
              styles.activationAttributionMeasurement,
              activePhaseDetail === PhaseDetails.AttributionMeasurement && styles.visible,
            )}
          />
          <ChannelsAndSignalsSvg
            className={clsx(
              styles.planningChannelsAndSignals,
              activePhaseDetail === PhaseDetails.ChannelsAndSignals && styles.visible,
            )}
          />
          <ReachAndEngagementSvg
            className={clsx(
              styles.planningReachAndEngagement,
              activePhaseDetail === PhaseDetails.ReachAndEngagement && styles.visible,
            )}
          />
          <ExperienceAndInnovationSvg
            className={clsx(
              styles.planningExperienceAndInnovation,
              activePhaseDetail === PhaseDetails.ExperienceAndInnovation && styles.visible,
            )}
          />
        </div>
        <img src={logoImgSrc} alt="logo" className={styles.logo} />
      </Flex>
    </div>
  )
}
