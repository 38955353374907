// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UmZJP::part(button){padding:4px 16px;color:#000;background-color:var(--wpp-grey-color-000);border:1px solid #000;border-radius:24px}.kIVtO::part(typography){font-weight:400;font-size:12px;font-family:NotoSans,serif;line-height:16px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ikeaNew/components/button/whiteButton/WhiteButton.module.scss"],"names":[],"mappings":"AACE,qBACE,gBAAA,CACA,UAAA,CACA,0CAAA,CACA,qBAAA,CACA,kBAAA,CAKF,yBACE,eAAA,CACA,cAAA,CACA,0BAAA,CACA,gBAAA","sourcesContent":[".buttonWrapper {\n  &::part(button) {\n    padding: 4px 16px;\n    color: #000000;\n    background-color: var(--wpp-grey-color-000);\n    border: 1px solid #000000;\n    border-radius: 24px;\n  }\n}\n\n.buttonTitle {\n  &::part(typography) {\n    font-weight: 400;\n    font-size: 12px;\n    font-family: NotoSans, serif;\n    line-height: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `UmZJP`,
	"buttonTitle": `kIVtO`
};
export default ___CSS_LOADER_EXPORT___;
