import activateImgSrc from 'pages/customWidget/ikea/assets/activate.png'
import discoverImgSrc from 'pages/customWidget/ikea/assets/discover.png'
import measureImgSrc from 'pages/customWidget/ikea/assets/measure.png'
import planImgSrc from 'pages/customWidget/ikea/assets/plan.png'
import { PhaseData, Phases } from 'pages/customWidget/ikea/types'

interface IkeaProcessWidgetDataInterface {
  title: string
  phases: PhaseData[]
}

export const IkeaProcessWidgetData: IkeaProcessWidgetDataInterface = {
  title: 'Accelerated Growth Planning Process',
  phases: [
    {
      phaseId: Phases.Discover,
      title: 'Discover',
      bgColor: '#396321',
      color: '#FFFFFF',
      description: 'Audit and indentify ambition through informed objectives',
      img: discoverImgSrc,
      moreInfo: {
        considerations: ['Opportunity Definition'],
      },
    },
    {
      phaseId: Phases.Plan,
      title: 'Plan',
      bgColor: '#0058AB',
      color: '#FFFFFF',
      description: 'Design audience and strategic approach',
      img: planImgSrc,
      moreInfo: {
        considerations: ['Audience Analysis', 'Strategy & Measurement Solution', 'Channel Allocation'],
      },
    },
    {
      phaseId: Phases.Activate,
      title: 'Activate',
      bgColor: '#DEBD9C',
      color: '#000000',
      description: 'Leverage assets and activate',
      img: activateImgSrc,
      moreInfo: {
        considerations: ['Execution Plan'],
        details: ['- Assets', '- Platforms'],
      },
    },
    {
      phaseId: Phases.Measure,
      title: 'Measure',
      bgColor: '#FBD915',
      color: '#000000',
      description: 'Analyze performance and adapt',
      img: measureImgSrc,
      moreInfo: {
        considerations: ['Reporting, Optimisation & Measurement'],
      },
    },
  ],
}
