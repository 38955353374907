import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { CocaColaProcessWidget } from 'pages/customWidget/cocaCola/CocaColaProcessWidget'
import { BrandHubWidget } from 'pages/customWidget/cocaColaChina/brandHub/BrandHubWidget'
import { CocaColaChinaProcessWidget } from 'pages/customWidget/cocaColaChina/process/CocaColaChinaProcessWidget'
import styles from 'pages/customWidget/CustomWidgetRoutes.module.scss'
import IkeaProcessWidget from 'pages/customWidget/ikea/IkeaProcessWidget'

export const CustomWidgetRoutes = () => {
  useEffect(() => {
    document.body.classList.add(styles.root)

    return () => document.body.classList.remove(styles.root)
  }, [])

  return (
    <Routes>
      <Route path="tccc">
        <Route path="process" element={<CocaColaProcessWidget />} />
      </Route>
      <Route path="tccc-china">
        <Route path="process" element={<CocaColaChinaProcessWidget />} />
        <Route path="brand-hub" element={<BrandHubWidget />} />
      </Route>
      <Route path="ikea">
        <Route path="ikea-process-widget" element={<IkeaProcessWidget />} />
      </Route>
    </Routes>
  )
}
