// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YHgQ1{margin-bottom:8px;opacity:1;transition:opacity .3s ease-in-out}.q8BgW{opacity:0}.wmour{padding:2px 8px;color:var(--wpp-grey-color-800);text-transform:uppercase;background-color:var(--wpp-grey-color-300);border-radius:var(--wpp-border-radius-s)}.Jmnbi{color:var(--wpp-grey-color-000);background:linear-gradient(241.45deg, #3f8ef7 31.79%, #300e7b 275.71%)}.XYvm8{color:var(--wpp-grey-color-1000);font-weight:600;font-size:14px;line-height:22px;opacity:1;transition:all .3s ease-in-out}.Aaogv{font-size:18px;line-height:28px}.DQx49{opacity:.5}.Un0jI:hover .XYvm8{color:var(--wpp-grey-color-800);opacity:1}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestle/processScheme/processPhaseInfo/ProcessPhaseInfo.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,SAAA,CACA,kCAAA,CAGF,OACE,SAAA,CAGF,OACE,eAAA,CACA,+BAAA,CACA,wBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,+BAAA,CACA,sEAAA,CAGF,OACE,gCAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,SAAA,CACA,8BAAA,CAGF,OACE,cAAA,CACA,gBAAA,CAGF,OACE,UAAA,CAGF,oBACE,+BAAA,CACA,SAAA","sourcesContent":[".phaseTagsContainer {\n  margin-bottom: 8px;\n  opacity: 1;\n  transition: opacity 0.3s ease-in-out;\n}\n\n.minimizedPhaseTagsContainer {\n  opacity: 0;\n}\n\n.tag {\n  padding: 2px 8px;\n  color: var(--wpp-grey-color-800);\n  text-transform: uppercase;\n  background-color: var(--wpp-grey-color-300);\n  border-radius: var(--wpp-border-radius-s);\n}\n\n.mroiTag {\n  color: var(--wpp-grey-color-000);\n  background: linear-gradient(241.45deg, #3f8ef7 31.79%, #300e7b 275.71%);\n}\n\n.title {\n  color: var(--wpp-grey-color-1000);\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  opacity: 1;\n  transition: all 0.3s ease-in-out;\n}\n\n.activeTitle {\n  font-size: 18px;\n  line-height: 28px;\n}\n\n.minimizedTitle {\n  opacity: 0.5;\n}\n\n.titleButton:hover .title {\n  color: var(--wpp-grey-color-800);\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phaseTagsContainer": `YHgQ1`,
	"minimizedPhaseTagsContainer": `q8BgW`,
	"tag": `wmour`,
	"mroiTag": `Jmnbi`,
	"title": `XYvm8`,
	"activeTitle": `Aaogv`,
	"minimizedTitle": `DQx49`,
	"titleButton": `Un0jI`
};
export default ___CSS_LOADER_EXPORT___;
