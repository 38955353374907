// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dIDiC{padding:16px 16px 64px}.pJ7G9{background:linear-gradient(90deg, #33bad8 0%, #78e820 111.09%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}._ppRf{background:linear-gradient(93.89deg, #33bad8 4.05%, #78e820 81.02%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.NUuHV{padding:16px 16px 40px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/pages/campaignDiscovery/CampaignDiscovery.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,8DAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,mEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 64px;\n}\n\n.audienceOriginTagColor {\n  background: linear-gradient(90deg, #33bad8 0%, #78e820 111.09%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.commerceDBTagColor {\n  background: linear-gradient(93.89deg, #33bad8 4.05%, #78e820 81.02%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.outputsCard {\n  padding: 16px 16px 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": `dIDiC`,
	"audienceOriginTagColor": `pJ7G9`,
	"commerceDBTagColor": `_ppRf`,
	"outputsCard": `NUuHV`
};
export default ___CSS_LOADER_EXPORT___;
