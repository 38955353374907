import {
  WppActionButton,
  WppIconChevron,
  WppTypography,
  WppButton,
  WppIconDirections,
} from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/mediaMarktSaturn/processPhases/pageLayout/PageLayout.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  title: string
  description: string
  schemeSrc: string
  previousLink?: string
  nextLink?: string
}

export const PageLayout = ({ title, description, schemeSrc, previousLink, nextLink }: Props) => {
  return (
    <Flex className={styles.page} direction="column">
      <div className={styles.header}>
        <Link to="/custom">
          <WppActionButton variant="inverted">
            <WppIconChevron direction="left" color="white" /> Back to process overview
          </WppActionButton>
        </Link>
        <div>
          <WppTypography type="5xl-display" className={styles.title}>
            {title}
          </WppTypography>
        </div>
        <div>
          <WppTypography type="s-body" className={styles.description}>
            {description}
          </WppTypography>
        </div>
      </div>
      <Flex className={styles.content} direction="column" justify="between" gap={32}>
        <div>
          <img src={schemeSrc} alt="" className={styles.schemeImg} />
        </div>
        <Flex align="center" justify="between">
          <Flex gap={16}>
            <Link to={previousLink ?? ''}>
              <WppActionButton disabled={!previousLink} className={styles.previousNextLink}>
                <Flex gap={4} align="center">
                  <WppIconDirections direction="left" color="#0080ff" />
                  <WppTypography type="s-strong" className={styles.navButtonText}>
                    Previous phase
                  </WppTypography>
                </Flex>
              </WppActionButton>
            </Link>
            <Link to={nextLink ?? ''}>
              <WppActionButton disabled={!nextLink} className={styles.previousNextLink}>
                <Flex gap={4} align="center">
                  <WppTypography type="s-strong" className={styles.navButtonText}>
                    Next phase
                  </WppTypography>
                  <WppIconDirections color="#0080ff" />
                </Flex>
              </WppActionButton>
            </Link>
          </Flex>
          <Link to={routesManager.systemUrls.orchestration.root.url}>
            <WppButton className={styles.goToProjectBtn}>Go to project details</WppButton>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
