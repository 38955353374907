import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/nestleChina/processPhases/contentCards/inputCard/InputCard.module.scss'

interface Props {
  title: string
  text: string
  width?: number
}

export const InputCard = ({ title, text, width }: Props) => {
  return (
    <div className={styles.card} style={{ width: width }}>
      <WppTypography className={styles.title}>{title}</WppTypography>
      <WppTypography type="m-midi">{text}</WppTypography>
    </div>
  )
}
