// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GmhKg{height:calc(100vh - var(--wpp-os-header-height));padding:32px}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/allianzNew/AllianzProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,gDAAA,CACA,YAAA","sourcesContent":[".root {\n  height: calc(100vh - var(--wpp-os-header-height));\n  padding: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GmhKg`
};
export default ___CSS_LOADER_EXPORT___;
