import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useState, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as ArrowSvg } from 'pages/customProcess/components/shell/assets/arrow.svg'
import { PhaseCard } from 'pages/customProcess/components/shell/components/phaseCard/PhaseCard'
import { ShellSvgStep1 } from 'pages/customProcess/components/shell/components/shellSvgStep1/ShellSvgStep1'
import { mediaPhases } from 'pages/customProcess/components/shell/ShellConfig'
import styles from 'pages/customProcess/components/shell/ShellProcess.module.scss'
import { Groups, Phases } from 'pages/customProcess/components/shell/types'

export const ShellProcess = () => {
  const [activeGroup, setActiveGroup] = useState<MayBeNull<Groups>>(null)
  const [activePhase, setActivePhase] = useState<MayBeNull<Phases>>(null)

  const handleGroupClick = useCallback(
    (group: Groups) => () => {
      setActiveGroup(group)
    },
    [],
  )

  const handlePhaseEnter = useCallback(
    (phase: Phases) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <div className={styles.root}>
      <div className={styles.svgWrapper}>
        <ShellSvgStep1 activeGroup={activeGroup} onGroupClick={handleGroupClick} />
      </div>
      <div className={styles.mediaPage}>
        <WppTypography type="5xl-display" className={clsx(styles.title, !!activeGroup && styles.titleVisible)}>
          Media
        </WppTypography>
        <Flex
          justify="center"
          align="center"
          className={clsx(styles.mainContent, !!activeGroup && styles.mainContentActive)}
        >
          <Flex direction="column" align="center" className={styles.phases}>
            <Flex justify="center" className={styles.arrowWrapper}>
              <ArrowSvg className={styles.arrow} />
            </Flex>
            <Flex gap={10} className={styles.mediaPhasesWrapper}>
              {mediaPhases.map((phase, index) => (
                <PhaseCard
                  key={index}
                  phase={phase}
                  activePhase={activePhase}
                  onPhaseEnter={handlePhaseEnter}
                  onPhaseLeave={handlePhaseLeave}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </div>
    </div>
  )
}
