import { Routes, Route } from 'react-router-dom'

import Decide from 'pages/customProcess/components/tigerBrands/processPhases/pages/Decide'
import Decode from 'pages/customProcess/components/tigerBrands/processPhases/pages/Decode'
import Define from 'pages/customProcess/components/tigerBrands/processPhases/pages/Define'
import Deploy from 'pages/customProcess/components/tigerBrands/processPhases/pages/Deploy'
import Develop from 'pages/customProcess/components/tigerBrands/processPhases/pages/Develop'
import Discern from 'pages/customProcess/components/tigerBrands/processPhases/pages/Discern'

export const TigerBrandsRoutes = () => (
  <Routes>
    <Route path="define" element={<Define />} />
    <Route path="decide" element={<Decide />} />
    <Route path="develop" element={<Develop />} />
    <Route path="deploy" element={<Deploy />} />
    <Route path="discern" element={<Discern />} />
    <Route path="decode" element={<Decode />} />
  </Routes>
)
