import { MayBeNull } from '@wpp-open/core'
import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { links } from 'pages/customProcess/components/allianzNew/allianzConfig'
import styles from 'pages/customProcess/components/allianzNew/AllianzProcess.module.scss'
import { AllianzSvg } from 'pages/customProcess/components/allianzNew/AllianzSvg'
import { PhasePoints } from 'pages/customProcess/components/allianzNew/types'

export const AllianzProcess = () => {
  const navigate = useNavigate()

  const [activePhasePoint, setActivePhasePoint] = useState<MayBeNull<PhasePoints>>(null)

  const handlePhaseEnter = useCallback(
    (phasePoint: PhasePoints) => () => {
      setActivePhasePoint(phasePoint)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhasePoint(null)
  }, [])

  const handlePhaseClick = useCallback(
    (point: PhasePoints) => () => {
      navigate(links[point])
    },
    [navigate],
  )

  return (
    <Flex justify="center" align="center" className={styles.root}>
      <AllianzSvg
        activePhasePoint={activePhasePoint}
        onPhasePointEnter={handlePhaseEnter}
        onPhasePointLeave={handlePhaseLeave}
        onPhasePointClick={handlePhaseClick}
      />
    </Flex>
  )
}
