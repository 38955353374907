// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R9qLr{display:grid;grid-gap:0;grid-template-rows:repeat(3, 1fr);grid-template-columns:repeat(3, 1fr);width:60px;height:60px;background:var(--wpp-grey-color-200);border-radius:5px}.Yatnv::part(button){align-items:center;justify-content:center;width:20px;height:20px;padding:0}.Yatnv.yt0OC::part(button){background:var(--wpp-white-color);border:1px solid var(--wpp-grey-color-300)}.Zh1UQ{transform:rotate(45deg)}`, "",{"version":3,"sources":["webpack://./src/components/form/formAlignment/FormAlignment.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,UAAA,CACA,iCAAA,CACA,oCAAA,CACA,UAAA,CACA,WAAA,CACA,oCAAA,CACA,iBAAA,CAIA,qBACE,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CAGF,2BACE,iCAAA,CACA,0CAAA,CAIJ,OACE,uBAAA","sourcesContent":[".root {\n  display: grid;\n  grid-gap: 0;\n  grid-template-rows: repeat(3, 1fr);\n  grid-template-columns: repeat(3, 1fr);\n  width: 60px;\n  height: 60px;\n  background: var(--wpp-grey-color-200);\n  border-radius: 5px;\n}\n\n.control {\n  &::part(button) {\n    align-items: center;\n    justify-content: center;\n    width: 20px;\n    height: 20px;\n    padding: 0;\n  }\n\n  &.isActive::part(button) {\n    background: var(--wpp-white-color);\n    border: 1px solid var(--wpp-grey-color-300);\n  }\n}\n\n.iconRotate45 {\n  transform: rotate(45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `R9qLr`,
	"control": `Yatnv`,
	"isActive": `yt0OC`,
	"iconRotate45": `Zh1UQ`
};
export default ___CSS_LOADER_EXPORT___;
