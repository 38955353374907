// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L4WEB{position:absolute;bottom:24px;left:50%;z-index:2;width:100%;transform:translateX(-50%);pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleChina/processPhases/processCard/mroiFooterShape/MroiFooterShape.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,UAAA,CACA,0BAAA,CACA,mBAAA","sourcesContent":[".mroiShape {\n  position: absolute;\n  bottom: 24px;\n  left: 50%;\n  z-index: 2;\n  width: 100%;\n  transform: translateX(-50%);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mroiShape": `L4WEB`
};
export default ___CSS_LOADER_EXPORT___;
