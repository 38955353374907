import { Flex } from 'components/common/flex/Flex'
import { InputCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/inputCard/InputCard'
import { InputsOutputsCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestle/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestle/processPhases/pages/mediaActivation/MediaActivation.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestle/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const MediaActivation = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '/nestle/process/media-plan' : '/custom/media-plan'
  const nextLink = isDemoTenant ? '/nestle/process/campaign-analysis' : '/custom/campaign-analysis'

  return (
    <ProcessCard
      title="Media Activation"
      showRepeatIcon
      footerTitle="Nestle framework"
      footerSubtitle="brilliant basics: Golden Rules, Hygiene BPIs and Ad-Tech standards"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <Flex direction="column" align="center" className={styles.inputCardsWrapper}>
          <InputCard title="From Campaign Analysis" text="Campaign Analysis is carried out, insights are generated" />
          <svg width="6" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="m3 48 2.887-5H.113L3 48Zm.5-4.5V0h-1v43.5h1Z" fill="#307DE4" />
          </svg>
          <InputsOutputsCard
            title="inputs"
            text="Media plan sign off Activation briefing"
            cardStyles={styles.inputsCard}
          />
        </Flex>
        <Flex direction="column" align="center" gap={48}>
          <RegularCard
            text="Provide channel briefing for activation (budget, start and end dates)"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Redmill®', style: styles.redmillTagColor }]}
          />
          <RegularCard
            text="Implement Nestle Golden Rules, Hygiene BPIs and Ad-Tech standards"
            teamTags={[{ text: 'Media Teams', tags: ['R'] }]}
            brandTags={[{ text: 'Media Activity Plan®', style: styles.redmillTagColor }]}
          />
        </Flex>
        <div className={styles.regularCard3Wrapper}>
          <RegularCard
            text="Activate Media: Search Social Programmatic, Traditional Display, DCO, Creative, E-Commerce (RDM)"
            teamTags={[
              { text: 'Media Teams', tags: ['R'] },
              { text: 'GDS', tags: ['R'] },
            ]}
          />
        </div>
        <div className={styles.regularCard4Wrapper}>
          <RegularCard
            text="Provide regular review to maintain governance"
            teamTags={[
              { text: 'Creative Teams', tags: ['R'] },
              { text: 'Media Teams', tags: ['R', 'A'] },
              { text: 'GDS', tags: ['C'] },
              { text: 'Nestle Teams', tags: ['I'] },
            ]}
          />
        </div>
        <InputsOutputsCard
          title="inputs"
          text="Campaign Performance and Optimisation"
          cardStyles={styles.outputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default MediaActivation
