import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler, useCallback, useMemo } from 'react'

import styles from 'pages/processBuilder/templates/circular4/Circular4Process.module.scss'
import { Phase } from 'pages/processBuilder/templates/circular4/phase/Phase'
import { ProcessFormConfig, WidgetSize } from 'types/process/process'
import { getPreviewImageUrl, normalizeDimensions } from 'utils/builder'

interface Props {
  activePhase: MayBeNull<number>
  onPhaseEnter: (phase: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  config: ProcessFormConfig
}

export const Circular4Svg = ({ activePhase, onPhaseEnter, onPhaseLeave, config }: Props) => {
  const isActivePhase = useCallback((phase: number) => phase === activePhase, [activePhase])
  const phaseSize = config.phases.length > 2 ? 360 / config.phases.length : 120
  const logo = useMemo(() => getPreviewImageUrl(config?.logoImage), [config?.logoImage])
  const { width: logoWidth, height: logoHeight } = useMemo(
    () => normalizeDimensions(config.logoDimensions),
    [config.logoDimensions, config.logoDimensions.width, config.logoDimensions.height], //eslint-disable-line
  )
  const isLgWidget = useMemo(
    () => config.templateOptions?.widgetSize === WidgetSize.LG,
    [config.templateOptions?.widgetSize],
  )

  const getPhaseRotation = useCallback(
    (index: number) => {
      const isShiffted = config.phases.length > 2
      const rotateStep = 360 / config.phases.length

      return isShiffted ? rotateStep * index + rotateStep / 2 : rotateStep * index
    },
    [config.phases.length],
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isLgWidget ? '98%' : '76%'}
      height={isLgWidget ? '98%' : '76%'}
      viewBox="0 0 1368 720"
      fill="none"
      className={styles.svg}
    >
      {config.templateOptions?.additionalCircleAsImageIsVisible &&
      config.templateOptions?.additionalCircleImage?.[0] ? (
        <rect x="211.15" y="-107.85" width="935.7" height="935.7" rx="467.35" stroke={config?.colors?.[1] || 'black'} />
      ) : (
        <rect x="319.5" y="0.5" width="719" height="719" rx="359.5" stroke={config?.colors?.[1] || 'black'} />
      )}
      <g transform="translate(6, -46) scale(1.05)">
        {config.templateOptions?.additionalCircleAsImageIsVisible &&
          config.templateOptions?.additionalCircleImage?.[0] && (
            <image
              href={getPreviewImageUrl(config?.templateOptions?.additionalCircleImage)}
              width="100%"
              height="100%"
              transform-origin="center"
              x="-43"
              y="30"
            />
          )}
        {config?.phases?.map((phase, index) => (
          <Phase
            key={index}
            id={phase.localId ?? index.toString()}
            onMouseEnter={onPhaseEnter(index)}
            onMouseLeave={onPhaseLeave}
            isActive={isActivePhase(index)}
            rotation={getPhaseRotation(index)}
            phase={phase}
            size={phaseSize}
            isLgWidget={isLgWidget}
          />
        ))}
      </g>
      <foreignObject
        width={400}
        height={400}
        x={478}
        y={160}
        rx={200}
        className={clsx(styles.logoWrap, { [styles.logoWrapActive]: activePhase !== null })}
        style={{
          background: config?.colors?.[2],
        }}
      >
        <img
          src={logo}
          alt=""
          className={styles.logo}
          style={{ visibility: logo ? 'visible' : 'hidden', width: logoWidth, height: logoHeight }}
        />
      </foreignObject>
    </svg>
  )
}
