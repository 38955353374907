export enum Phases {
  Inspire = 'Inspire',
  Engage = 'Engage',
  Evaluate = 'Evaluate',
}

export enum PhasePoints {
  Challenge = 'Challenge',
  Audience = 'Audience',
  Insight = 'Insight',
  Context = 'Context',
  Occasion = 'Occasion',
  Engagement = 'Engagement',
  Analysis = 'Analysis',
  Action = 'Action',
}
