import { Flex } from 'components/common/flex/Flex'
import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaingAnalysis/CampaingAnalysis.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const CampaignAnalysis = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/media-activation'

  return (
    <ProcessCard
      title="Campaign Analysis"
      subtitle="Measure and optimize performance to achieve or exceed campaign and business goals"
      footerTitle="Nestle framework"
      showRepeatIcon
      footerSubtitle="measurement framework mtm"
      showMroiFooter
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Reporting Dashboards" cardStyles={styles.inputsCard} />
        <div className={styles.regularCard2Wrapper}>
          <RegularCard
            text="Collect creative and media insights to inform further strategic decisioning"
            teamTags={[
              { text: 'DS&A', tags: ['R'] },
              { text: 'Performance', tags: ['R'] },
              { text: 'Investment', tags: ['A'] },
              { text: 'Strategic Planning', tags: ['C'] },
            ]}
            brandTags={[
              { text: 'Brand to Demand', style: styles.adverityTagColor },
              { text: 'Baseline', style: styles.adverityTagColor },
              { text: 'CoPilot', style: styles.adverityTagColor },
              { text: 'SIA', style: styles.adverityTagColor },
              { text: 'Performance Model', style: styles.adverityTagColor },
              { text: '(Synapse)', style: styles.adverityTagColor },
              { text: 'Creative Analytics (Synapse)', style: styles.adverityTagColor },
            ]}
            className={styles.mainCard}
          />
        </div>
        <Flex direction="column" align="center" className={styles.outputCardsWrapper} gap={10}>
          <InputsOutputsCard
            title="outputs"
            text="Continued improvement in campaign performance"
            cardStyles={styles.outputsCard}
          />
          <InputsOutputsCard
            title="outputs"
            text="Learnings to apply to future campaigns and briefs"
            cardStyles={styles.outputsCard}
          />
        </Flex>
      </>
    </ProcessCard>
  )
}

export default CampaignAnalysis
