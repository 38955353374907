import { SortOrder } from 'api/common/types'

export const isString = (value: any): value is string => typeof value === 'string'

export const sortComparator =
  (order: SortOrder) =>
  <T extends string | number>(a: T, b: T) => {
    const value = isString(a) ? a.toLocaleLowerCase() : a
    const valueToCompare = isString(b) ? b.toLocaleLowerCase() : b

    if (value > valueToCompare) {
      return order === 'asc' ? 1 : -1
    }

    if (valueToCompare > value) {
      return order === 'asc' ? -1 : 1
    }

    return 0
  }

export const sortBy = <T, X extends string | number>(arr: T[], fn: (x: T) => X, order: SortOrder = 'asc') =>
  [...arr].sort((a, b) => sortComparator(order)(fn(a), fn(b)))

export const uniqBy = <T, X extends string | number>(array: T[], fn: (x: T) => X) =>
  Array.from(
    array
      .reduce((map, item) => {
        const key = fn(item)

        if (!map.has(key)) {
          map.set(key, item)
        }

        return map
      }, new Map<X, T>())
      .values(),
  )

export const join = (value: (string | null | number | undefined)[], separator: string) =>
  value.filter(v => v !== 0 && excludeFalsy(v)).join(separator)

export const isFunction = (value: unknown): value is Function => typeof value === 'function'

export function excludeFalsy<T>(value: T): value is Exclude<T, false | null | undefined | '' | 0> {
  return Boolean(value)
}

export const isDevelop = process.env.NODE_ENV === 'development'
