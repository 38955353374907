import {
  WppButton,
  WppTypography,
  WppActionButton,
  WppIconPlus,
  WppCard,
  WppDivider,
  WppInlineMessage,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import { useOs } from '@wpp-open/react'
import { useMemo, useEffect, useCallback } from 'react'
import { FormProvider, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateProcessApi } from 'api/process/mutations/useCreateProcessApi'
import { useCreateProcessByIdApi } from 'api/process/mutations/useCreateProcessByIdApi'
import { useEditProcessApi } from 'api/process/mutations/useEditProcessApi'
import { useProcessApi } from 'api/process/queries/useFetchProcessApi'
import { Flex } from 'components/common/flex/Flex'
import { FormAlignment } from 'components/form/formAlignment/FormAlignment'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormDimensions } from 'components/form/formDimensions/FormDimensions'
import { FormFileUpload } from 'components/form/formFileUpload/FormFileUpload'
import { FormPalette } from 'components/form/formPalette/FormPalette'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import styles from 'pages/processBuilder/editProcessModal/EditProcessModal.module.scss'
import { GroupFields } from 'pages/processBuilder/editProcessModal/GroupFields'
import { PhaseFields } from 'pages/processBuilder/editProcessModal/PhaseFields'
import { processSchema } from 'pages/processBuilder/editProcessModal/schema'
import { templateConfigs } from 'pages/processBuilder/templates'
import { useMapFormValuesToPayload, getInitialValues, getIsFieldHidden } from 'pages/processBuilder/utils'
import { Templates, FormPhase, FirstLinearPhaseImageSize, WidgetSize } from 'types/process/process'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  onChange: Function
  resetEditState: () => void
  processId?: string
}

const widgetSizeOptions = [
  { value: WidgetSize.MD, label: 'Md' },
  { value: WidgetSize.LG, label: 'Lg' },
]

export const EditProcessModal = ({ isOpen, processId, onClose, onCloseComplete, onChange, resetEditState }: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const {
    osContext: { tenant },
  } = useOs()
  const queryClient = useQueryClient()
  const mapFormValuesToPayload = useMapFormValuesToPayload()

  const { data } = useProcessApi({
    params: {
      processId: processId,
      tenantId: tenant.id,
    },
  })
  const isEditMode = Boolean(data)

  const { mutateAsync: handleCreateProcess } = useCreateProcessApi()
  const { mutateAsync: handleCreateProcessById } = useCreateProcessByIdApi()
  const { mutateAsync: handleEditProcess } = useEditProcessApi()

  const form = useForm({
    defaultValues: getInitialValues(data),
    validationSchema: processSchema,
  })

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    watch,
    control,
    setValue,
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      isEditMode
        ? await handleEditProcess({
            process: await mapFormValuesToPayload(values, true),
            processId: data!.id,
          })
        : processId
        ? await handleCreateProcessById({
            process: await mapFormValuesToPayload(values, false, tenant.id),
            processId,
          })
        : await handleCreateProcess({
            process: await mapFormValuesToPayload(values, false, tenant.id),
          })

      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.PROCESS] })

      enqueueToast({
        message: t('process.edit_modal.configured_successfully'),
        type: 'success',
      })
      onClose()
    } catch (e) {
      enqueueToast({
        message: t('process.edit_modal.not_configured'),
        type: 'error',
      })
    }
  })

  const handleCloseComplete = useCallback(() => {
    onCloseComplete()
    resetEditState()
  }, [onCloseComplete, resetEditState])

  const templateOptions = useMemo(() => Object.values(Templates).map(value => ({ value, label: value })), [])
  const formData = watch()
  const isFieldHidden = getIsFieldHidden(formData.template as Templates)
  const colorsConfig = templateConfigs[formData.template as Templates].colors

  const {
    fields: phases,
    append: appendPhase,
    remove: removePhase,
  } = useFieldArray({
    control,
    name: 'phases',
    keyName: 'formId',
  })

  const {
    fields: groups,
    append: appendGroup,
    remove: removeGroup,
  } = useFieldArray({
    control,
    name: 'groups',
    keyName: 'formId',
  })

  useEffect(() => {
    onChange(formData)
  }, [formData, onChange])

  const handleAddPhase = useCallback(() => {
    appendPhase({
      title: 'Phase title',
      description: 'Description',
      overlayDescriptions: [{ label: 'Overlay 1', id: 0 }],
      image: [],
      colors: [],
      groupTitle: '',
      url: '',
      innerPageEnabled: false,
      templateOptions: {
        phaseImageSize: FirstLinearPhaseImageSize.FULL_WIDTH,
        expandedPhaseImageVisible: true,
        minimizedPhaseImageVisible: false,
      },
    })
  }, [appendPhase])

  const handleRemovePhase = useCallback(
    (index: number, formId: string) => () => {
      removePhase(index)

      formData.groups?.forEach(({ phasesLocalIds }, index) => {
        const updatedIds = phasesLocalIds?.filter(id => id !== formId) || []
        setValue(`groups.${index}.phasesLocalIds`, updatedIds)
      })
    },
    [removePhase, formData.groups, setValue],
  )

  const handleCopyPhaseColors = useCallback(
    (index: number) => () => {
      const phase1Colors = formData?.phases?.[0]?.colors

      if (phase1Colors) setValue(`phases.${index}.colors`, phase1Colors, { shouldDirty: true })
    },
    [formData?.phases, setValue],
  )

  const handleCopyGroupColors = useCallback(
    (index: number) => () => {
      const group1Colors = formData?.groups?.[0]?.colors

      if (group1Colors) setValue(`groups.${index}.colors`, group1Colors, { shouldDirty: true })
    },
    [formData?.groups, setValue],
  )

  const addNewOverlayTitle = useCallback(
    (index: number) => (event: any) => {
      const phase = formData?.phases?.[index] as FormPhase

      setValue(
        `phases.${index}.overlayDescriptions`,
        [...phase.overlayDescriptions, { label: event.detail, id: phase.overlayDescriptions.length }],
        { shouldDirty: true },
      )
      event.target?.hideDropdown()
      event.target?.blurInput()
    },
    [formData?.phases, setValue],
  )

  const handleAddGroup = useCallback(() => {
    appendGroup({
      phasesLocalIds: [],
      title: 'Group title',
      description: '',
      colors: [],
    })
  }, [appendGroup])

  const handleRemoveGroup = useCallback(
    (index: number) => () => {
      removeGroup(index)
    },
    [removeGroup],
  )

  return (
    <FormProvider {...form}>
      <SideModal
        formConfig={{ onSubmit }}
        size="m"
        disableOutsideClick
        open={isOpen}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={handleCloseComplete}
        className={styles.modal}
      >
        <WppTypography slot="header" type="2xl-heading">
          {t('process.edit_modal.edit_process')}
        </WppTypography>

        <Flex slot="body" direction="column" gap={28} className={styles.modalBody}>
          <FormSelect
            labelConfig={{ text: t('process.edit_modal.template') }}
            required
            withSearch
            placeholder={t('process.edit_modal.choose_template')}
            name="template"
            options={templateOptions}
            defaultValue={Templates.Circular1}
            hidden={isFieldHidden('template')}
          />

          {!isFieldHidden('title') || !isFieldHidden('description') ? <WppDivider /> : null}
          <FormTextInput
            name="title"
            labelConfig={{ text: t('process.edit_modal.title') }}
            placeholder={t('process.edit_modal.title')}
            hidden={isFieldHidden('title')}
          />
          <FormTextInput
            name="description"
            labelConfig={{ text: t('process.edit_modal.description') }}
            placeholder={t('process.edit_modal.description')}
            hidden={isFieldHidden('description')}
          />
          {!isFieldHidden('title') || !isFieldHidden('description') ? <WppDivider /> : null}

          <Flex gap={15} align="start">
            <FormFileUpload
              name="background"
              size={1}
              acceptConfig={{
                'image/svg+xml': ['.svg'],
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg'],
                'image/gif': ['.gif'],
              }}
              maxLabelLength={45}
              maxFiles={1}
              className={styles.fileInput}
              label={t('process.edit_modal.choose_background')}
              hidden={isFieldHidden('background')}
            />
            <FormAlignment name="positionBackground" hidden={isFieldHidden('positionBackground')} />
          </Flex>

          <FormFileUpload
            name="logoImage"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            disabled={formData.template === Templates.Circular3 && formData.templateOptions?.isWidgetBackgroundAsLogo}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_logo')}
            hidden={isFieldHidden('logoImage')}
          />
          <FormDimensions
            name="logoDimensions"
            required
            hidden={isFieldHidden('logoDimensions')}
            widthLabel={t('process.edit_modal.logo_width')}
            heightLabel={t('process.edit_modal.logo_height')}
          />

          <FormFileUpload
            name="templateOptions.additionalCircleImage"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.coose_circle')}
            hidden={isFieldHidden('templateOptions', 'additionalCircleAsImageIsVisible')}
          />
          <FormCheckbox
            name="templateOptions.additionalCircleAsImageIsVisible"
            labelConfig={{
              text: t('process.edit_modal.enable_additional_circle'),
            }}
            hidden={isFieldHidden('templateOptions', 'additionalCircleAsImageIsVisible')}
          />

          <FormFileUpload
            name="widgetBackgroundImage"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_widget_background')}
            hidden={isFieldHidden('widgetBackgroundImage')}
          />
          <FormCheckbox
            name="templateOptions.isWidgetBackgroundAsLogo"
            labelConfig={{
              text: t('process.edit_modal.widget_background_as_logo'),
            }}
            hidden={isFieldHidden('templateOptions', 'isWidgetBackgroundAsLogo')}
          />
          {!isFieldHidden('widgetPartBackgroundImage') && (
            <Flex direction="column" gap={5} align="center">
              <FormFileUpload
                name="widgetPartBackgroundImage"
                size={1}
                acceptConfig={{
                  'image/svg+xml': ['.svg'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpg', '.jpeg'],
                }}
                maxLabelLength={45}
                maxFiles={1}
                className={styles.fileInput}
                label={t('process.edit_modal.choose_widget_hover_background')}
              />
              <WppInlineMessage type="information" message={t('process.edit_modal.widget_hover_background_info')} />
            </Flex>
          )}

          <FormFileUpload
            name="headerBackground"
            size={1}
            acceptConfig={{
              'image/svg+xml': ['.svg'],
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/gif': ['.gif'],
            }}
            maxLabelLength={45}
            maxFiles={1}
            className={styles.fileInput}
            label={t('process.edit_modal.choose_header_background')}
            hidden={isFieldHidden('headerBackground')}
          />

          <FormCheckbox
            name="templateOptions.verticalPhaseNumbers"
            labelConfig={{
              text: t('process.edit_modal.vertical_phase_numbers'),
            }}
            hidden={isFieldHidden('templateOptions', 'verticalPhaseNumbers')}
          />

          <FormCheckbox
            name="templateOptions.isInactivePhaseBlured"
            labelConfig={{
              text: t('process.edit_modal.blur_inactive_phases'),
            }}
            hidden={isFieldHidden('templateOptions', 'isInactivePhaseBlured')}
          />

          <FormSelect
            required
            withSearch
            labelConfig={{
              text: t('process.edit_modal.widget_size'),
            }}
            name="templateOptions.widgetSize"
            options={widgetSizeOptions}
            hidden={isFieldHidden('templateOptions', 'widgetSize')}
          />

          <FormPalette
            useDefaultColors={!isEditMode}
            name="colors"
            title={t('process.edit_modal.color_themes')}
            colorsConfig={colorsConfig.page}
            className={styles.colorsInput}
          />

          <FormCheckbox
            name="templateOptions.flipGradient"
            labelConfig={{
              text: t('process.edit_modal.flip_gradient'),
            }}
            hidden={isFieldHidden('templateOptions', 'flipGradient')}
          />

          {!isFieldHidden('button') && (
            <WppCard>
              <Flex direction="column" gap={10}>
                <WppTypography type="s-strong" className={styles.cardTitle}>
                  {t('process.edit_modal.button')}
                </WppTypography>
                <Flex direction="row" justify="between" align="end" gap={15}>
                  <FormTextInput
                    name="button.title"
                    placeholder={t('process.edit_modal.title')}
                    className={styles.inputHalfRow}
                    hidden={isFieldHidden('button', 'title')}
                  />
                  <FormTextInput
                    name="button.url"
                    placeholder={t('process.edit_modal.link')}
                    className={styles.inputHalfRow}
                    hidden={isFieldHidden('button', 'url')}
                  />
                </Flex>
                <FormPalette
                  useDefaultColors={!isEditMode}
                  name="button.colors"
                  title={t('common.colors')}
                  colorsConfig={colorsConfig.button}
                  hidden={isFieldHidden('button', 'colors')}
                  disableExpanding
                />
                <FormCheckbox
                  name="button.isVisible"
                  labelConfig={{
                    text: t('process.edit_modal.visibility'),
                  }}
                  hidden={isFieldHidden('button', 'isVisible')}
                />
              </Flex>
            </WppCard>
          )}

          <WppDivider />

          <Flex direction="column" gap={20}>
            {!isFieldHidden('phases') && (
              <>
                <WppTypography type="m-strong">{t('process.edit_modal.phases')}</WppTypography>

                {phases.map((item, index) => (
                  <PhaseFields
                    key={item.formId}
                    index={index}
                    phase={item}
                    isEditMode={isEditMode}
                    onRemove={handleRemovePhase(index, item.formId)}
                    onAddOverlay={addNewOverlayTitle(index)}
                    onCopyColors={handleCopyPhaseColors(index)}
                  />
                ))}

                <WppActionButton onClick={handleAddPhase}>
                  <WppIconPlus slot="icon-start" />
                  {t('process.edit_modal.add_phase')}
                </WppActionButton>
              </>
            )}

            {!isFieldHidden('groups') && (
              <>
                <WppDivider />

                <WppTypography type="m-strong">{t('process.edit_modal.groups')}</WppTypography>

                {groups.map((item: any, index: number) => (
                  <GroupFields
                    key={item.formId}
                    isEditMode={isEditMode}
                    index={index}
                    onRemove={handleRemoveGroup(index)}
                    onCopy={handleCopyGroupColors(index)}
                  />
                ))}

                <WppActionButton onClick={handleAddGroup}>
                  <WppIconPlus slot="icon-start" />
                  {t('process.edit_modal.add_group')}
                </WppActionButton>
              </>
            )}
          </Flex>
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose}>
            {t('common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" disabled={(data && !isDirty) || !isValid} loading={isSubmitting}>
            {t('common.save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showEditProcessModal, useModal: useEditProcessModal } = createNiceModal<Props>(
  EditProcessModal,
  'edit-process-modal',
)
