import { WppTypography, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import centralIllustrationSrc from 'pages/customProcess/components/nestleAsia/assets/central-illustration.svg'
import { ProcessSchemePhase } from 'pages/customProcess/components/nestleAsia/constants'
import styles from 'pages/customProcess/components/nestleAsia/NestleProcess.module.scss'
import hintArrow1_1Src from 'pages/customProcess/components/nestleAsia/processScheme/assets/hint-arrow1-1.svg'
import hintArrow1_2Src from 'pages/customProcess/components/nestleAsia/processScheme/assets/hint-arrow1-2.svg'
import hintArrow1Src from 'pages/customProcess/components/nestleAsia/processScheme/assets/hint-arrow1.svg'
import hintArrow2Src from 'pages/customProcess/components/nestleAsia/processScheme/assets/hint-arrow2.svg'
import hintArrow3_1Src from 'pages/customProcess/components/nestleAsia/processScheme/assets/hint-arrow3-1.svg'
import hintArrow3Src from 'pages/customProcess/components/nestleAsia/processScheme/assets/hint-arrow3.svg'
import { ProcessPhaseInfo } from 'pages/customProcess/components/nestleAsia/processScheme/processPhaseInfo/ProcessPhaseInfo'
import { ProcessScheme } from 'pages/customProcess/components/nestleAsia/processScheme/ProcessScheme'

const mroiTooltipText = 'MROI:\n•  Virtuous Insights\n•  Budget Allocation and Simulation\n• Strategic Learnings'

export const NestleAsiaProcess = () => {
  const [selectedPhase, setSelectedPhase] = useState<ProcessSchemePhase>(ProcessSchemePhase.EMPTY)

  return (
    <Flex align="center" justify="center" className={styles.container}>
      <div className={styles.scaleDown}>
        <div>
          <div
            className={clsx(
              styles.centralRadialGradient,
              selectedPhase !== ProcessSchemePhase.EMPTY && styles.activeCentralRadialGradient,
            )}
          />
          <img
            alt=""
            src={centralIllustrationSrc}
            className={clsx(
              styles.centralIllustration,
              selectedPhase !== ProcessSchemePhase.EMPTY && styles.centralIllustrationExpanded,
            )}
          />
          <ProcessScheme selectedPhase={selectedPhase} onSelectPhase={section => setSelectedPhase(section)} />
          <div>
            <WppTypography type="l-strong" className={styles.schemeCenterTitle}>
              OPEN DATA SPINE
            </WppTypography>
          </div>
          <div className={clsx(styles.phaseInfo, styles.phase1InfoAsia)}>
            <ProcessPhaseInfo
              showWhenNecessary
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.WHEN_NECESSARY
              }
              active={selectedPhase === ProcessSchemePhase.WHEN_NECESSARY}
              tag="BEP 1-2"
              justifyTags="center"
              title="The Big Brief Idea"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.WHEN_NECESSARY)}
              link="big-brief-idea"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase2InfoAsia,
              selectedPhase === ProcessSchemePhase.SYNAPSE && styles.activePhase2InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE}
              active={selectedPhase === ProcessSchemePhase.SYNAPSE}
              title="Prerequisites to BEP 3"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE)}
              link="prerequisites-to-bep-3"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase3InfoAsia,
              selectedPhase === ProcessSchemePhase.SYNAPSE_2 && styles.activePhase3InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE_2}
              active={selectedPhase === ProcessSchemePhase.SYNAPSE_2}
              tag="BEP 3"
              mroiTooltipText={mroiTooltipText}
              title="Integrated Communications Brief"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE_2)}
              link="integrated-communications-brief"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase4InfoAsia,
              selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB && styles.activePhase4InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.BAV_AUDIENCE_DB
              }
              active={selectedPhase === ProcessSchemePhase.BAV_AUDIENCE_DB}
              tag="BEP 3"
              title="Campaign Discovery"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.BAV_AUDIENCE_DB)}
              link="campaign-discovery"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase5InfoAsia,
              selectedPhase === ProcessSchemePhase.SYNAPSE_3 && styles.activePhase5InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.SYNAPSE_3}
              active={selectedPhase === ProcessSchemePhase.SYNAPSE_3}
              tag="BEP 3"
              title="Campaign Strategic Approach"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.SYNAPSE_3)}
              link="campaign-strategic-approach"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase6InfoAsia,
              selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && styles.activePhase6InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.CHOREOGRAPH_SYNAPSE
              }
              active={selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE}
              tag="BEP 3"
              title="Contact Points and Investment Planning"
              justifyTags="end"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.CHOREOGRAPH_SYNAPSE)}
              link="contact-points-and-investment-planning"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase7InfoAsia,
              selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN && styles.activePhase7InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={
                selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.MEDIA_ACTIVITY_PLAN
              }
              active={selectedPhase === ProcessSchemePhase.MEDIA_ACTIVITY_PLAN}
              tag="BEP 4"
              title="Activation Planning"
              justifyTags="end"
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.MEDIA_ACTIVITY_PLAN)}
              link="activation-planning"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase8InfoAsia,
              selectedPhase === ProcessSchemePhase.NEXUS && styles.activePhase8InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.NEXUS}
              active={selectedPhase === ProcessSchemePhase.NEXUS}
              title="Implementation"
              titleTooltipText={'This phase it iterative, it is\nenriched by outcomes from\nCampaign analysis phase'}
              justifyTags="end"
              showRepeatIcon
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.NEXUS)}
              link="implementation"
            />
          </div>
          <div
            className={clsx(
              styles.phaseInfo,
              styles.phase9InfoAsia,
              selectedPhase === ProcessSchemePhase.ADVERITY && styles.activePhase9InfoAsia,
            )}
          >
            <ProcessPhaseInfo
              minimized={selectedPhase !== ProcessSchemePhase.EMPTY && selectedPhase !== ProcessSchemePhase.ADVERITY}
              active={selectedPhase === ProcessSchemePhase.ADVERITY}
              mroiTooltipText={mroiTooltipText}
              title="Outcomes and Adapt"
              titleTooltipText={
                'This phase it iterative, its outcomes\nbecome inputs of Media Activation\nand Campaign Brief phases'
              }
              justifyTags="end"
              showRepeatIcon
              onSelectPhase={() => setSelectedPhase(ProcessSchemePhase.ADVERITY)}
              link="outcomes-and-adapt"
            />
          </div>
        </div>
        {selectedPhase === ProcessSchemePhase.EMPTY && (
          <img alt="" src={hintArrow1Src} className={clsx(styles.hintArrow, styles.hintArrow1Asia)} />
        )}
        {selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE && (
          <img alt="" src={hintArrow1_1Src} className={clsx(styles.hintArrow, styles.hintArrow1_1Asia)} />
        )}
        {selectedPhase === ProcessSchemePhase.ADVERITY && (
          <img alt="" src={hintArrow1_2Src} className={clsx(styles.hintArrow, styles.hintArrow1_2Asia)} />
        )}
        {(selectedPhase === ProcessSchemePhase.EMPTY ||
          selectedPhase === ProcessSchemePhase.CHOREOGRAPH_SYNAPSE ||
          selectedPhase === ProcessSchemePhase.ADVERITY) && (
          <div className={styles.postCampaignInsightHint}>
            <WppTooltip text="Post-campaign insights are...">
              <WppTypography type="xs-midi" className={styles.postCampaignInsightHintText}>
                Post-campaign insights
              </WppTypography>
            </WppTooltip>
          </div>
        )}
        {(selectedPhase === ProcessSchemePhase.NEXUS || selectedPhase === ProcessSchemePhase.ADVERITY) && (
          <>
            <img alt="" src={hintArrow2Src} className={clsx(styles.hintArrow, styles.hintArrow2)} />
            <WppTypography type="xs-midi" className={styles.insightsGeneratedHintText}>
              Campaign Analysis: Insights are generated
            </WppTypography>
          </>
        )}
        {selectedPhase === ProcessSchemePhase.ADVERITY ||
          (selectedPhase === ProcessSchemePhase.SYNAPSE_2 && (
            <>
              <WppTypography type="xs-midi" className={styles.insightsGeneratedHintText2}>
                Campaign Analysis: Insights are generated
              </WppTypography>
            </>
          ))}
        {selectedPhase === ProcessSchemePhase.ADVERITY && (
          <img alt="" src={hintArrow3Src} className={clsx(styles.hintArrow, styles.hintArrow3)} />
        )}
        {selectedPhase === ProcessSchemePhase.SYNAPSE_2 && (
          <img alt="" src={hintArrow3_1Src} className={clsx(styles.hintArrow, styles.hintArrow3_1)} />
        )}
      </div>
    </Flex>
  )
}
