import { Flex } from 'components/common/flex/Flex'
import googleLookerStudioLogoImgSrc from 'pages/customProcess/components/henkel/assets/google-looker-studio-logo.png'
import synapseLogoImgSrc from 'pages/customProcess/components/henkel/assets/synapse-logo.png'
import { PageLayout } from 'pages/customProcess/components/henkel/processPhases/pageLayout/PageLayout'
import styles from 'pages/customProcess/components/henkel/processPhases/pages/adapt/Adapt.module.scss'

const Adapt = () => {
  return (
    <PageLayout
      title="Adapt"
      description="Campaign measurement and optimization."
      nextLink="/custom/henkel/ambition"
      inputsChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>Ex-Post analysis</li>
            <li>Reporting</li>
            <li>BLS</li>
            <li>Measurement framework</li>
          </ul>
        </div>
      }
      activitiesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Measure, optimize and\nimprove performance'}</li>
          </ul>
        </div>
      }
      toolsChildren={
        <div>
          <Flex align="center" gap={4} className={styles.item}>
            <img src={googleLookerStudioLogoImgSrc} alt="Google Looker Studio logo" className={styles.logo} />
            <span className={styles.itemText}>Google Looker Studio</span>
          </Flex>
          <ul className={styles.listHenkel}>
            <li>Mindmonitor</li>
          </ul>
          <Flex align="center" gap={4} className={styles.item}>
            <img src={synapseLogoImgSrc} alt="Google Looker Studio logo" className={styles.logo} />
            <span className={styles.itemText}>Synapse</span>
          </Flex>
        </div>
      }
      outcomesChildren={
        <div>
          <ul className={styles.listHenkel}>
            <li>{'Actionable insights from\nresearch, reporting and\nanalytics'}</li>
          </ul>
        </div>
      }
    />
  )
}

export default Adapt
