import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import stageInputExampleImgSrc from 'pages/customProcess/components/ibmNew/assets/stage-input-example.png'
import stepInputExampleImgSrc from 'pages/customProcess/components/ibmNew/assets/step-input-example.png'
import styles from 'pages/customProcess/components/ibmNew/processPhases/components/inputOutputInfo/InputOutputInfo.module.scss'

interface Props {
  width?: string
}

export const InputOutputInfo = ({ width }: Props) => {
  return (
    <div className={styles.card} style={{ width: width }}>
      <Flex gap={8} align="center" className={styles.section}>
        <img src={stageInputExampleImgSrc} alt="Stage input example" className={styles.img} />
        <Flex direction="column" gap={4}>
          <WppTypography type="s-body" className={styles.text}>
            <span className={styles.bold}>“STAGE INPUT”</span> is the trigger or condition needed to start a phase in a
            process. It could be completing a previous phase, getting approval, resource availability, time-based
            milestones, or specific events achieved within the project. It ensures smooth progression and adherence to
            the project plan.
          </WppTypography>
          <WppTypography type="s-body" className={styles.text}>
            <span className={styles.bold}>“STAGE OUTPUT”</span> refers to the result or outcome generated at the
            completion of a particular stage within a process or workflow. It represents the deliverables, products, or
            achievements that are produced as a result of completing the activities and tasks associated with that
            stage. These outputs often serve as inputs for subsequent stages or may be final deliverables for the
            overall process.
          </WppTypography>
        </Flex>
      </Flex>
      <Flex gap={8} align="center">
        <img src={stepInputExampleImgSrc} alt="Stage input example" className={styles.img} />
        <Flex direction="column" gap={4}>
          <WppTypography type="s-body" className={styles.text}>
            <span className={styles.bold}>“STEP INPUT”</span> is the information or action critical for the relevant
            step to be started/to be taken into consideration.
          </WppTypography>
          <WppTypography type="s-body" className={styles.text}>
            <span className={styles.bold}>“STEP OUTPUT”</span>-specific milestone/certain deliverable achieved within
            the step which is critical to be highlighted as it influence the overall performance
          </WppTypography>
        </Flex>
      </Flex>
    </div>
  )
}
