import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/procterAndGamble/processPhases/processPandGCard/ProcessPandGCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  title: string
  description: string
  previousLink?: string
  nextLink?: string
  classes?: {
    controls?: string
  }
}

export const ProcessPandGCard = ({ children, title, description, previousLink, nextLink, classes }: Props) => {
  return (
    <>
      <Flex className={clsx([styles.cutomHeaderr])}>
        <Flex justify="between" gap={24} align="center" className={styles.headerContenttt}>
          <Flex gap={39} direction="column" className={styles.headerContentLeftt}>
            <Link to="/custom">
              <WppActionButton>
                <WppIconChevron color="var(--wpp-grey-color-000)" direction="left" />
                <WppTypography type="s-strong" className={styles.titleAudible}>
                  Back to Process overview
                </WppTypography>
              </WppActionButton>
            </Link>
            <Flex direction="column">
              <WppTypography type="4xl-display" className={styles.titleAudible}>
                {title}
              </WppTypography>
              <WppTypography type="s-body" className={styles.whiteText}>
                {description}
              </WppTypography>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <div className={styles.mainContentWrapperr}>
        <div className={styles.mainContentAudible}>
          <div className={clsx([styles.cardAudible])}>
            <Flex direction="column" justify="between" align="start" gap={24} className={styles.cardContentAudible}>
              <div className={styles.flexGrow}>
                <Flex align="center" className={styles.descriptionCardsWrapperAudible}>
                  <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInner}>
                    {children}
                  </Flex>
                </Flex>
              </div>
              <Flex align="center" justify="between" className={clsx(classes?.controls, styles.controlsAudible)}>
                <Flex gap={16}>
                  <Link to={previousLink ?? ''}>
                    <WppActionButton disabled={!previousLink} className={styles.navBtn}>
                      <WppIconChevron color="#0036B0" direction="left" />
                      Previous phase
                    </WppActionButton>
                  </Link>
                  <Link to={nextLink ?? ''}>
                    <WppActionButton disabled={!nextLink} className={styles.navBtn}>
                      Next phase
                      <WppIconChevron color="#0036B0" />
                    </WppActionButton>
                  </Link>
                </Flex>
                <Link to={routesManager.systemUrls.orchestration.root.url}>
                  <WppButton className={styles.projectBtnAudible}>Go to project</WppButton>
                </Link>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </>
  )
}
