import { WppActionButton, WppIconChevron, WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import logoSrc from 'pages/customProcess/components/ferreroNew/processPhases/processCard/assets/WPP Logo.svg'
import styles from 'pages/customProcess/components/ferreroNew/processPhases/processCard/ProcessCard.module.scss'
import { routesManager } from 'utils/routesManager'

interface Props {
  children: ReactNode
  title: string
  description: string
  previousLink?: string
  nextLink?: string
  classes?: {
    controls?: string
  }
}

export const ProcessCard = ({ children, title, description, previousLink, nextLink, classes }: Props) => {
  return (
    <div className={styles.processCardRoot}>
      <div className={styles.headerFerrero}>
        <div className={styles.headerContentFerrero}>
          <Flex justify="between" gap={24}>
            <div className={styles.headerContentLeftFerrero}>
              <Link to="/custom">
                <WppActionButton variant="inverted" className={styles.backButton}>
                  <WppIconChevron direction="left" color="#5c3327" />
                  Back to Process overview
                </WppActionButton>
              </Link>
              <div>
                <WppTypography type="4xl-display" className={styles.headerTitlee}>
                  {title}
                </WppTypography>
                <WppTypography type="s-body" className={styles.descriptionFerrero}>
                  {description}
                </WppTypography>
              </div>
            </div>
            <img src={logoSrc} alt="" />
          </Flex>
        </div>
      </div>
      <div>
        <div className={styles.mainContentt}>
          <div className={styles.cardd}>
            <div className={styles.footerOverlay} />
            <Flex direction="column" justify="between" gap={24} className={styles.cardContent}>
              <div className={styles.flexGrow}>
                <Flex align="start" className={styles.descriptionCardsWrapperr}>
                  <Flex justify="between" gap={24} className={styles.descriptionCardsWrapperInnerFerrero}>
                    {children}
                  </Flex>
                </Flex>
              </div>
              <Flex align="center" justify="between" className={clsx(classes?.controls, styles.controlss)}>
                <Flex gap={16}>
                  <Link to={previousLink ?? ''}>
                    <WppActionButton variant="inverted" disabled={!previousLink} className={styles.navButton}>
                      <WppIconChevron direction="left" color="#5c3327" />
                      Previous phase
                    </WppActionButton>
                  </Link>
                  <Link to={nextLink ?? ''}>
                    <WppActionButton variant="inverted" disabled={!nextLink} className={styles.navButton}>
                      Next phase <WppIconChevron color="#5c3327" />
                    </WppActionButton>
                  </Link>
                </Flex>
                <Link to={routesManager.systemUrls.orchestration.root.url}>
                  <WppButton className={styles.goToBtn}>Go to the project</WppButton>
                </Link>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  )
}
