import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: true,
    description: true,
    background: true,
    positionBackground: true,
    logoImage: true,
    logoDimensions: true,
    widgetBackgroundImage: false,
    widgetPartBackgroundImage: false,
    headerBackground: true,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: false,
      image: true,
      colors: true,
      innerPageEnabled: true,
      groupTitle: false,
      url: true,
      templateOptions: {
        phaseImageSize: true,
        expandedPhaseImageVisible: true,
        minimizedPhaseImageVisible: true,
      },
    },
    groups: {
      title: true,
      description: true,
      phasesLocalIds: true,
      colors: true,
    },
    templateOptions: {
      flipGradient: false,
      isInactivePhaseBlured: true,
      verticalPhaseNumbers: true,
      widgetSize: false,
    },
  },
  colors: {
    page: [
      { title: 'Page background', defaultValue: 'linear-gradient(180deg, #f794a4 0%, #fdd6bd 100%)' },
      { title: 'Title', defaultValue: '#fff' },
      { title: 'Description', defaultValue: '#fff' },
      { title: 'Header background', defaultValue: 'rgba(0, 0, 0, 0)' },
    ],
    button: [
      { title: 'Background', defaultValue: '#0014cc' },
      { title: 'Text', defaultValue: '#fff' },
    ],
    phase: [
      { title: 'Background', defaultValue: '#0014CC' },
      { title: 'Active background', defaultValue: '#03086B' },
      { title: 'Index text', defaultValue: '#fff' },
      { title: 'Active index text', defaultValue: '#fff' },
      { title: 'Title', defaultValue: '#fff' },
      { title: 'Active title', defaultValue: '#fff' },
      { title: 'Description', defaultValue: '#fff' },
      { title: 'Button background', defaultValue: '#fff' },
      { title: 'Button icon', defaultValue: '#fff' },
      { title: 'Button border', defaultValue: '#fff' },
    ],
    group: [
      { title: 'Border', defaultValue: '#fff' },
      { title: 'Title', defaultValue: '#fff' },
      { title: 'Description', defaultValue: '#fff' },
    ],
  },
}
