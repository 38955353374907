export enum Phases {
  OpportunityDefinition = 'Opportunity Definition',
  AudienceAnalysis = 'Audience Analysis',
  StrategicAndMeasurementSolutions = 'Strategic & Measurement Solutions',
  ChannelAllocation = 'Channel Allocation',
  ExecutionPlan = 'Execution Plan',
  Reporting = 'Reporting',
}

export interface PhaseData {
  phaseId: Phases
  title: string
  description: string
  img: string
  color: string
  pills: string[]
  moreInfo: {
    considerations: string[]
    outcomes: string[]
  }
}
