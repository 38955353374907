import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: false,
    description: false,
    background: true,
    positionBackground: true,
    logoImage: true,
    logoDimensions: true,
    widgetBackgroundImage: false,
    widgetPartBackgroundImage: false,
    headerBackground: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      image: false,
      colors: true,
      innerPageEnabled: true,
      groupTitle: false,
      url: true,
      templateOptions: false,
    },
    groups: false,
    templateOptions: {
      additionalCircleAsImageIsVisible: true,
      isInactivePhaseBlured: false,
      flipGradient: false,
      verticalPhaseNumbers: false,
      widgetSize: true,
    },
  },
  colors: {
    page: [
      { title: 'Page background', defaultValue: 'linear-gradient(180deg, #f794a4 0%, #fdd6bd 100%)' },
      { title: 'SVG Border', defaultValue: '#fff' },
      { title: 'Logo background', defaultValue: '#fff' },
    ],
    button: [
      { title: 'Background', defaultValue: '#0014cc' },
      { title: 'Text', defaultValue: '#fff' },
    ],
    phase: [
      { title: 'Description text', defaultValue: '#fff' },
      { title: 'Phase title', defaultValue: '#fff' },
      { title: 'Tools text', defaultValue: '#fff' },
      { title: 'Phase background', defaultValue: '#0014CC' },
      { title: 'Subphase 1 background', defaultValue: '#3646d7' },
      { title: 'Subphase 2 background', defaultValue: '#2f37ae' },
      { title: 'Subphase 3 background', defaultValue: '#33398b' },
      { title: 'Description background', defaultValue: 'rgba(255, 255, 255, 0)' },
    ],
    group: [],
  },
}
