import { WppTypography } from '@platform-ui-kit/components-library-react'

import styles from 'pages/customProcess/components/spotify/processPhases/pages/decision/Decision.module.scss'
import { DescriptionCard } from 'pages/customProcess/components/spotify/processPhases/processCard/descriptionCard/DescriptionCard'
import { ProcessCard } from 'pages/customProcess/components/spotify/processPhases/processCard/ProcessCard'
import { RolesResponsibilitiesCard } from 'pages/customProcess/components/spotify/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard'

const pageType = 'decision'

const Decision = () => {
  return (
    <ProcessCard
      type={pageType}
      title="Decision"
      description="Maximize outcomes across the funnel, and across short- and long-term investments"
      previousLink="/custom/discover"
      nextLink="/custom/design"
    >
      <DescriptionCard type={pageType} icon="input">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Inputs
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Source of Growth
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Historical Benchmarks
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Audience Origin Cohorts
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <RolesResponsibilitiesCard
        type={pageType}
        roles={[
          {
            name: 'Analytics Team',
            responsibilities: [
              { label: 'R', active: true },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Consumer Sciences',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: true },
              { label: 'C', active: false },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Strategy Lead',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: true },
              { label: 'I', active: false },
            ],
          },
          {
            name: 'Client Teams',
            responsibilities: [
              { label: 'R', active: false },
              { label: 'A', active: false },
              { label: 'C', active: false },
              { label: 'I', active: true },
            ],
          },
        ]}
      />
      <DescriptionCard type={pageType} icon="bullet-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Active Workstreams
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Budget Modeling
              </WppTypography>
            </li>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Outcomes Modeling
              </WppTypography>
            </li>
          </ul>
        </div>
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Supporting Tools
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Synapse
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
      <DescriptionCard type={pageType} icon="task-list">
        <div>
          <WppTypography type="m-strong" className={styles.descriptionCardTitle}>
            Outcomes
          </WppTypography>
          <ul className={styles.list}>
            <li>
              <WppTypography type="s-body" className={styles.whiteText}>
                Optimized Budget by market & audience(s)
              </WppTypography>
            </li>
          </ul>
        </div>
      </DescriptionCard>
    </ProcessCard>
  )
}

export default Decision
