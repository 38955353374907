import { InputsOutputsCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard'
import { RegularCard } from 'pages/customProcess/components/nestleHealth/processPhases/contentCards/regularCard/RegularCard'
import styles from 'pages/customProcess/components/nestleHealth/processPhases/pages/campaignBudgetSetting/CampaignBudgetSetting.module.scss'
import { ProcessCard } from 'pages/customProcess/components/nestleHealth/processPhases/processCard/ProcessCard'

interface Props {
  isDemoTenant?: boolean
}

const CampaignBudgetSetting = ({ isDemoTenant = false }: Props) => {
  const previousLink = isDemoTenant ? '' : '/custom/nestle-health/campaign-strategy'
  const nextLink = isDemoTenant ? '' : '/custom/nestle-health/strategic-media-planning'

  return (
    <ProcessCard
      title="Campaign Budget Setting"
      subtitle="Determine proper budgets to achieve success of campaign"
      bepTitle="BEP 3"
      footerTitle="Nestle framework"
      footerSubtitle="budget and channel sufficiency"
      isDemoTenant={isDemoTenant}
      previousLink={previousLink}
      nextLink={nextLink}
    >
      <>
        <InputsOutputsCard title="inputs" text="Details from campaign strategy" cardStyles={styles.inputsOutputsCard} />
        <RegularCard
          text="Allocate budget (top-down or bottom-up approach), aligning to the media principles framework with spend sufficiency of campaign and platform"
          teamTags={[
            { text: 'Strategic Planning', tags: ['R'] },
            { text: 'Investment', tags: ['C'] },
            { text: 'DS&A', tags: ['I'] },
          ]}
          brandTags={[
            { text: 'Architect', style: styles.synapseTagColor },
            { text: 'Brand to Demand', style: styles.synapseTagColor },
            { text: 'Commerce Allocate', style: styles.synapseTagColor },
          ]}
          className={styles.mainCard}
        />
        <InputsOutputsCard
          title="outputs"
          text="Campaign Budget allocation (top-down / bottom-up)"
          cardStyles={styles.inputsOutputsCard}
        />
      </>
    </ProcessCard>
  )
}

export default CampaignBudgetSetting
