import discernSchemeImgUrl from 'pages/customProcess/components/tigerBrands/assets/phaseDetails/discern.png'
import { PageLayout } from 'pages/customProcess/components/tigerBrands/processPhases/pageLayout/PageLayout'
import { processPhasesData } from 'pages/customProcess/components/tigerBrands/TigerBrandsConfig'

const Discern = () => {
  const { title, description, color, previousLink, nextLink } = processPhasesData[4]

  return (
    <PageLayout
      title={title}
      description={description}
      color={color}
      schemeSrc={discernSchemeImgUrl}
      previousLink={previousLink}
      nextLink={nextLink}
    />
  )
}

export default Discern
